import { Colors, Metrics } from '../../../Themes/'

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'visibility 0.5s, opacity 0.5s linear',
    position: 'absolute',
    visibility: 'visible',
    opacity: 1,
    zIndex: Metrics.zIndexes.splashContainer,
    top: 0,
    left: 0,
    backgroundColor: Colors.mainBackground
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0,
  },
  logo: {
    width: 170,
    height: 53,
    marginBottom: Metrics.cuadrupleBaseMargin
  },
  text: {
    marginTop: 0,
    marginBottom: 0
  }
}
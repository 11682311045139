import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form/lib'
import moment from 'moment'
import FormCard from '../../../UI/ContentCards/FormCard'
import FullScreenLoader from '../../../UI/Loaders/FullScreenLoader'
import BaseSwitch from '../../../UI/Switchs/BaseSwitch'
import BaseForm from '../../../UI/Forms/BaseForm'
import SimpleTextButton from '../../../UI/Buttons/SimpleTextButton'
import AppText from '../../../UI/Texts/AppText'
import RaixerConfig from '../../../../Config/RaixerConfig'
import styles from '../../../Styles/Screens/Forms'
import I18n from '../../../../i18n/i18n'
// import { Metrics } from '../../../../Themes'
import { validateCifNif, emailValidation } from '../../../../Lib/FormUtils'
import { getDatetimes, getTimezone, inputTimeFormat } from '../../../../Lib/DateTimeUtils' 

export default class LocationForm extends Component {

  static propTypes = {
    activeLang: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    selectConditionsDocument: PropTypes.func.isRequired,
    openConditionsFile: PropTypes.func.isRequired,
    user: PropTypes.object,
    location: PropTypes.object,
    connectionHasDevice: PropTypes.bool,
    selectedConditionsDocumentName: PropTypes.string
  }

  static defaultProps = {
    activeLang: null,
    fetching: null,
    onSubmit: null,
    onDelete: null,
    selectConditionsDocument: null,
    openConditionsFile: null,
    user: null,
    location: null,
    connectionHasDevice: null,
    selectedConditionsDocumentName: null
  }

  constructor(props) {
    super(props)
    const { now, to } = getDatetimes(null, null, RaixerConfig.connections.maxTimeMinutesApiUser)
    this.locationTypeOptions = this.constructOptions(RaixerConfig.checkIn['locationTypes'])
    this.policeTypeOptions = this.constructOptions(RaixerConfig.checkIn['policeTypes'])
    this.provinceTypeOptions = this.constructOptions(Object.keys(RaixerConfig.checkIn['provinces']))
    this.paymentTypeOptions = this.constructOptions(RaixerConfig.checkIn['paymentTypes'])
    this.comunitiesProvinces = RaixerConfig.checkIn['provinces']
    this.state = {
      value: {
        locationType: null,
        locationName: '',
        nifcif: '',
        locationTown: '',
        locationProvince: null,
        locationAddress: '',
        locationCategory: '',
        andaluciaCode: '',
        landlordPhoneNumber: null,
        landlordName: '',
        police: null,
        userPolice: '',
        passPolice: '',
        sequenceNumber: 0,
        notificationEmail: '',
        postalCode: '',
        numberOfBedrooms: '',
        paymentType: null,
        _id: null,
      },
      startTime: now,
      endTime: to,
      autoAccess: false,
      phoneNumbers: false,
      signConditionsDocument: false,
      conditionsFileName: null,
      conditionsFileUri: null,
      hasInternet: false,
      isProfesional: false
    }
  }

  componentDidUpdate = (prevProps) => {
    const { location: prevLocation } = prevProps
    const { location, user, connectionHasDevice } = this.props
    const { email } = user
    if (!prevLocation && location) {
      const {
        _id = '', locationType = null, locationName = '', nifcif = '', locationTown = '', locationProvince = null, locationAddress = '', 
        locationCategory = '', andaluciaCode = '', landlordPhoneNumber = null, landlordName = '', police = null, userPolice = '', 
        passPolice = '', sequenceNumber = 0, notificationEmail = email, startTime = null, endTime = null, 
        autoAccess = true, phoneNumbers = true, signConditionsDocument = false, conditionsFileName = null, conditionsFileUri = null,
        landlordCode = '', establishmentCode = '', postalCode = '', numberOfBedrooms = '', hasInternet = false, isProfesional = false,
        paymentType = null
      } = location
      const { now, to } = getDatetimes(this.constructDatetime(startTime), this.constructDatetime(endTime), RaixerConfig.connections.maxTimeMinutesApiUser)
      this.setState({ 
        value: {
          locationType,
          locationName,
          nifcif,
          locationTown, 
          locationProvince, 
          locationAddress, 
          locationCategory, 
          andaluciaCode, 
          landlordPhoneNumber, 
          landlordName, 
          police, 
          userPolice, 
          passPolice, 
          sequenceNumber,
          notificationEmail,
          _id,
          landlordCode,
          establishmentCode,
          postalCode,
          numberOfBedrooms,
          paymentType
        },
        startTime: now,
        endTime: to,
        autoAccess: connectionHasDevice ? autoAccess : false,
        phoneNumbers: connectionHasDevice ? phoneNumbers : false,
        signConditionsDocument,
        conditionsFileName,
        conditionsFileUri,
        hasInternet: this.parseStringToBoolean(hasInternet),
        isProfesional: this.parseStringToBoolean(isProfesional)
      })
    }
  }

  parseStringToBoolean = (value) => {
    if (typeof value === 'boolean') { return value }
    return value === 'true'
  }
  
  constructOptions = (array) => {
    let options = {}
    for (let a of array) { options[a] = a.split('_').join(' ') }
    return options
  }

  constructDatetime = (datetime) => {
    if (!datetime) { return null }
    return moment().hours(datetime.split(':')[0]).minutes(datetime.split(':')[1]).toDate()
  }

  getFields = () => {
    return {
      locationType: t.enums(this.locationTypeOptions),
      locationName: t.String,
      nifcif: t.refinement(t.String, validateCifNif),
      locationTown: t.String,
      locationProvince: t.enums(this.provinceTypeOptions),
      locationAddress: t.maybe(t.String),
      locationCategory: t.maybe(t.String),
      andaluciaCode: t.maybe(t.String),
      landlordPhoneNumber: t.maybe(t.Number),
      landlordName: t.maybe(t.String),
      police: t.enums(this.policeTypeOptions),
      userPolice: t.String,
      passPolice: t.String,
      landlordCode: t.maybe(t.String),
      establishmentCode: t.maybe(t.String),
      sequenceNumber: t.Number,
      notificationEmail: t.refinement(t.String, emailValidation),
      _id: t.maybe(t.String),
      postalCode: t.String,
      numberOfBedrooms: t.Number,
      paymentType: t.enums(this.paymentTypeOptions)
    }
  }

  getFieldsAutoAccess = () => {
    return {
      startTime: t.String,
      endTime: t.String,
    }
  }

  getFieldsOptions = () => {
    const { value = {} } = this.state
    const { sequenceNumber = 0, locationProvince = null } = value
    const nextSequence = !isNaN(parseInt(sequenceNumber)) ? parseInt(sequenceNumber) + 1 : 1
    let isCataluna = false
    let isPaisVasco = false
    let isAndalucia = false
    if (locationProvince) { 
      isCataluna = this.comunitiesProvinces[locationProvince] === 'CATALUÑA' 
      isPaisVasco = this.comunitiesProvinces[locationProvince] === 'PAIS_VASCO'
      isAndalucia = this.comunitiesProvinces[locationProvince] === 'ANDALUCIA'
    }

    const policeIsMinistry = value.police === 'Ministerio'
    
    return {
      locationType: {
        label: I18n.t('screens.locationForm.labels.locationType'),
        help: I18n.t('screens.locationForm.helps.locationType')
      },
      locationName: {
        label: I18n.t('screens.locationForm.labels.locationName'),
        help: I18n.t('screens.locationForm.helps.locationName'),
        placeholder: I18n.t('screens.locationForm.labels.locationName')
      },
      nifcif: {
        label: I18n.t('screens.locationForm.labels.cifnif'),
        help: I18n.t('screens.locationForm.helps.cifnif'),
        placeholder: '-'
      },
      locationTown: {
        label: I18n.t('screens.locationForm.labels.locationTown'),
        help: I18n.t('screens.locationForm.helps.locationTown'),
        placeholder: I18n.t('screens.locationForm.labels.locationTown')
      },
      locationProvince: {
        label: I18n.t('screens.locationForm.labels.locationProvince'),
        help: I18n.t('screens.locationForm.helps.locationProvince')
      },
      locationAddress: {
        label: I18n.t('screens.locationForm.labels.locationAddress'),
        hidden: !(!isCataluna || !policeIsMinistry),
        placeholder: I18n.t('screens.locationForm.labels.locationAddress')
      },
      police: {
        label: I18n.t('screens.locationForm.labels.police'),
        help: I18n.t('screens.locationForm.helps.police')
      },
      userPolice: {
        label: I18n.t('screens.locationForm.labels.userPolice'),
        help: I18n.t('screens.locationForm.helps.userPolice'),
        placeholder: I18n.t('screens.locationForm.labels.userPolice')
      },
      passPolice: {
        label: I18n.t('screens.locationForm.labels.passPolice'),
        help: I18n.t('screens.locationForm.helps.passPolice'),
        placeholder: I18n.t('screens.locationForm.labels.passPolice'),
        password: true,
        secureTextEntry: true
      },
      landlordCode: {
        label: I18n.t('screens.locationForm.labels.landlordCode'),
        hidden: !policeIsMinistry,
        placeholder: I18n.t('screens.locationForm.labels.landlordCode')
      },
      establishmentCode: {
        label: I18n.t('screens.locationForm.labels.establishmentCode'),
        hidden: !policeIsMinistry,
        placeholder: I18n.t('screens.locationForm.labels.establishmentCode')
      },
      sequenceNumber: {
        label: I18n.t('screens.locationForm.labels.sequenceNumber'),
        help: I18n.t('screens.locationForm.helps.sequenceNumber') + nextSequence,
        placeholder: I18n.t('screens.locationForm.labels.sequenceNumber'),
        keyboardType: 'number-pad'
      },
      notificationEmail: {
        label: I18n.t('screens.locationForm.labels.notificationEmail'),
        help: I18n.t('screens.locationForm.helps.notificationEmail'),
        placeholder: I18n.t('screens.locationForm.labels.notificationEmail'),
        autoCapitalize: 'none',
        keyboardType: 'email-address'
      },
      postalCode: {
        label: I18n.t('screens.locationForm.labels.postalCode'),
        help: I18n.t('screens.locationForm.helps.postalCode'),
        placeholder: I18n.t('screens.locationForm.labels.postalCode'),
        keyboardType: 'number-pad'
      },
      numberOfBedrooms: {
        label: I18n.t('screens.locationForm.labels.numberOfBedrooms'),
        help: I18n.t('screens.locationForm.helps.numberOfBedrooms'),
        placeholder: I18n.t('screens.locationForm.labels.numberOfBedrooms'),
        keyboardType: 'number-pad'
      },
      paymentType: {
        label: I18n.t('screens.locationForm.labels.paymentType'),
        help: I18n.t('screens.locationForm.helps.paymentType'),
        hidden: !policeIsMinistry,
      },
      locationCategory:{
        label: I18n.t('screens.locationForm.labels.locationCategory'),
        help: I18n.t('screens.locationForm.helps.locationCategory'),
        placeholder: I18n.t('screens.locationForm.labels.locationCategory'),
        hidden: !isPaisVasco
      },
      andaluciaCode: {
        label: I18n.t('screens.locationForm.labels.andaluciaCode'),
        help: I18n.t('screens.locationForm.helps.andaluciaCode'),
        placeholder: I18n.t('screens.locationForm.labels.andaluciaCode'),
        hidden: !isAndalucia
      },
      landlordPhoneNumber: {
        label: I18n.t('screens.locationForm.labels.landlordPhoneNumber'),
        help: I18n.t('screens.locationForm.helps.landlordPhoneNumber'),
        placeholder: I18n.t('screens.locationForm.labels.landlordPhoneNumber'),
        hidden: !isAndalucia,
        keyboardType: 'number-pad'
      },
      landlordName: {
        label: I18n.t('screens.locationForm.labels.landlordName'),
        help: I18n.t('screens.locationForm.helps.landlordName'),
        placeholder: I18n.t('screens.locationForm.labels.landlordName'),
        hidden: !isAndalucia
      },
      _id: {
        hidden: true
      }
    }
  }

  getFieldsOptionsAutoAccess = () => {
    const { autoAccess = true } = this.state
    return {
      startTime: {
        label: I18n.t('screens.locationForm.labels.startTime'),
        mode: 'time',
        minuteInterval: 1,
        config: { format: (time) => inputTimeFormat(time) },
        hidden: !autoAccess
      },
      endTime: {
        label: I18n.t('screens.locationForm.labels.endTime'),
        mode: 'time',
        minuteInterval: 1,
        config: { format: (time) => inputTimeFormat(time) },
        hidden: !autoAccess
      }
    }
  }

  renderSignConditionsDocument = () => {
    const { conditionsFileName, conditionsFileUri } = this.state
    return (
      <>
        {
          conditionsFileName && conditionsFileUri &&
          <SimpleTextButton
            buttonText={I18n.t('screens.locationForm.buttons.viewConditionsDocument')}
            onPress={() => { this.props.openConditionsFile(conditionsFileUri) }}
            disabled={false}
            customButtonStyle={styles.textButton}
            customTextStyle={styles.textButtonText}
          />
        }
        <SimpleTextButton
          buttonText={
            conditionsFileName && conditionsFileUri
            ? I18n.t('screens.locationForm.buttons.changeConditionsDocument')
            : I18n.t('screens.locationForm.buttons.selectConditionsDocument')
          }
          onPress={this.props.selectConditionsDocument}
          disabled={false}
          customButtonStyle={
            conditionsFileName && conditionsFileUri
            ? {}
            : styles.textButton
          }
          customTextStyle={styles.textButtonText}
        />
        {
          this.props.selectedConditionsDocumentName &&
          <AppText
            text={this.props.selectedConditionsDocumentName}
            customStyle={{ textAlign: 'center' }}
          />
        }
      </>
    )
  }

  renderExtraContent = () => {
    return (
      <>
        <BaseSwitch
          label={null}
          help={I18n.t('screens.locationForm.helps.internet')}
          value={this.state.hasInternet}
          disabled={false}
          onChange={(value) => { this.setState({ hasInternet: value }) }}
          customSwitchRowStyle={styles.switchRow}
          customHelpStyle={styles.switchHelpSmall}
          customSwitchStyle={styles.switch}
        />
        <BaseSwitch
          label={null}
          help={I18n.t('screens.locationForm.helps.professional')}
          value={this.state.isProfesional}
          disabled={false}
          onChange={(value) => { this.setState({ isProfesional: value }) }}
          customSwitchRowStyle={styles.switchRow}
          customHelpStyle={styles.switchHelpSmall}
          customSwitchStyle={styles.switch}
        />
        
        { 
          this.props.connectionHasDevice &&
          <>
            <BaseSwitch
              label={null}
              help={I18n.t('screens.locationForm.helps.phoneNumbers')}
              value={this.state.phoneNumbers}
              disabled={false}
              onChange={(value) => { this.setState({ phoneNumbers: value }) }}
              customSwitchRowStyle={styles.switchRow}
              customHelpStyle={styles.switchHelpSmall}
              customSwitchStyle={styles.switch}
            />
            <BaseSwitch
              label={null}
              help={I18n.t('screens.locationForm.helps.autoAccess')}
              value={this.state.autoAccess}
              disabled={false}
              onChange={(value) => { this.setState({ autoAccess: value }) }}
              customSwitchRowStyle={styles.switchRow}
              customHelpStyle={styles.switchHelpSmall}
              customSwitchStyle={styles.switch}
            />
            {
              this.state.autoAccess && 
              <div style={styles.complementaryForm}>
                <BaseForm
                  name={'formAutoAccess'}
                  onSubmit={() => {}}
                  fields={this.getFieldsAutoAccess()}
                  fieldsOptions={this.getFieldsOptionsAutoAccess()}
                  onChange={(value) => {
                    const { startTime, endTime } = value
                    this.setState({ startTime, endTime })
                  }}
                  value={{ startTime: this.state.startTime, endTime: this.state.endTime }}
                  fetching={this.props.fetching}
                  withoutSubmit={true}
                />
              </div>
            }
          </>
        }
        <BaseSwitch
          label={null}
          help={I18n.t('screens.locationForm.helps.signConditionsDocument')}
          value={this.state.signConditionsDocument}
          disabled={false}
          onChange={(value) => { this.setState({ signConditionsDocument: value }) }}
          customSwitchRowStyle={styles.switchRow}
          customHelpStyle={styles.switchHelpSmall}
          customSwitchStyle={styles.switch}
        />
        {
          this.state.signConditionsDocument &&
          this.renderSignConditionsDocument()
        }
      </>
    )
  }

  onSubmit = () => {
    const { startTime, endTime, autoAccess, phoneNumbers, signConditionsDocument, value } = this.state
    const startHour = moment(startTime).hours()
    const endHour = moment(endTime).hours()
    const startMinutes = moment(startTime).minutes()
    const endMinutes = moment(endTime).minutes()
    let completeValue = Object.assign({}, value)
    completeValue.autoAccess = autoAccess
    completeValue.phoneNumbers = phoneNumbers
    completeValue.signConditionsDocument = signConditionsDocument
    completeValue.endTime = endHour + ':' + (endMinutes < 10 ? '0' + endMinutes : endMinutes)
    completeValue.startTime = startHour + ':' + (startMinutes < 10 ? '0' + startMinutes : startMinutes)
    completeValue.timezone = getTimezone()
    completeValue.hasInternet = this.parseStringToBoolean(this.state.hasInternet)
    completeValue.isProfesional = this.parseStringToBoolean(this.state.isProfesional)
    
    this.props.onSubmit(completeValue)
  }

  render = () => {
    return (
      <>
        <FormCard
          name={'locationForm'}
          title={this.props.location ? I18n.t('screens.locationForm.titleEdit') : I18n.t('screens.locationForm.title')}
          onSubmit={this.onSubmit}
          onChange={(value) => { this.setState({ value }) }}
          deleteButton={this.props.location ? true : false}
          onDelete={() => { this.props.onDelete(this.props.location) }}
          fields={this.getFields()}
          fieldsOptions={this.getFieldsOptions()}
          value={this.state.value}
          fetching={this.props.fetching}
          renderExtraContentBeforeButtons={() => this.renderExtraContent()}
        />
        <FullScreenLoader visible={this.props.fetching}/>
      </>
    )
  }

}
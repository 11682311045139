import React, { Component } from 'react'
import { Provider } from 'react-redux'
import store from '../Redux'
import RootContainer from './RootContainer'
import { ModalService } from '../Services/Modal'
import { FormModalService } from '../Services/FormModal'

class App extends Component {

  render = () => {
    ModalService.setStore(store)
    FormModalService.setStore(store)
    return (
      <React.StrictMode>
        <Provider store={store}>
          <RootContainer />
        </Provider>
      </React.StrictMode>
    )
  }

}

export default App
import { Colors, Fonts, Metrics } from '../../../../Themes/'

export default {
  wrapper: {
    display: 'flex', 
    justifyContent: 'center',
    zIndex: Metrics.zIndexes.tabbar,
    backgroundColor: Colors.mainBackground,
    marginBottom: Metrics.doubleBaseMargin,
    // ...Shadows.tabbar
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 1,
    // height: 60,
    padding: 0,
    // backgroundColor: Colors.mainBackground,
    // borderTop: Metrics.listItemBorderWidth + ' solid ' + Colors.lightGray,
    // ...Shadows.tabbar
  },
  item: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    paddingTop: Metrics.baseMarginPlus,
    paddingBottom: Metrics.baseMargin
  },
  icon: {
    marginRight: 0,
    marginBottom: Metrics.smallMargin
  },
  text: {
    fontSize: Fonts.size.extraSmall,
    fontWeight: Fonts.weight.regular,
    color: Colors.mediumGray
  },
  textSelected: {
    color: Colors.theme1
  }
}
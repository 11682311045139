import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ContentCard from '../ContentCards/ContentCard'
import AppText from '../Texts/AppText'
import MainButton from '../Buttons/MainButton'
import GhostButton from '../Buttons/GhostButton'
import styles from '../../Styles/UI/Modal'
import { Metrics } from '../../../Themes'

export default class BaseModal extends Component {

  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    confirmAction: PropTypes.func,
    cancelAction: PropTypes.func,
    withoutCancelButton: PropTypes.bool,
    confirmAsGhost: PropTypes.bool,
    fetching: PropTypes.bool,
    submitOnEnter: PropTypes.bool
  }

  static defaultProps = {
    title: null,
    text: null,
    confirmText: null,
    cancelText: null,
    confirmAction: null,
    cancelAction: null,
    withoutCancelButton: null,
    confirmAsGhost: null,
    fetching: false,
    submitOnEnter: false
  }

  render = () => {
    return (
      <ContentCard 
        title={this.props.title}
        titleNumberOfLines={null}
        withEditButton={false}
        renderContent={
          () => this.props.text && <AppText text={this.props.text} customStyle={styles.text} />
        }
        renderButtons={
          () => (
            <>
              {
                this.props.confirmAction && !this.props.confirmAsGhost &&
                <MainButton 
                  buttonText={this.props.confirmText}
                  onPress={this.props.confirmAction}
                  disabled={false}
                  icon={this.props.submitOnEnter ? 'keyboard-return' : null}
                  customButtonStyle={{ margin: 0 }}
                  withIndicator={true}
                  fetching={this.props.fetching}
                />
              }
              {
                this.props.confirmAction && this.props.confirmAsGhost &&
                <GhostButton 
                  buttonText={this.props.confirmText}
                  onPress={this.props.confirmAction}
                  disabled={false}
                  icon={this.props.submitOnEnter ? 'keyboard-return' : null}
                  customButtonStyle={{ margin: 0 }}
                  withIndicator={true}
                  fetching={this.props.fetching}
                />
              }
              {
                !this.props.withoutCancelButton &&
                <GhostButton
                  buttonText={this.props.cancelText}
                  onPress={this.props.cancelAction}
                  disabled={false}
                  icon={null}
                  customButtonStyle={{ margin: 0, marginTop: Metrics.baseMargin }}
                />
              }
            </>
          )
        }
        customStyle={styles.modalCard}
        customTitleStyle={styles.title}
      />
    )
  }

}
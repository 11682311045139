const type = {
  regular: 'LatoRegular',
  bold: 'LatoBold',
  light: 'LatoLight'
}

const size = {
  extraSmall: 12 + 'px',
  small: 14 + 'px',
  regular: 16 + 'px',
  menu: 18 + 'px',
  title: 20 + 'px'
}

const weight = {
  bold: '600',
  regular: '400',
  light: '300'
}

export default {
  type,
  size,
  weight
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CssLoader from './CssLoader'
import styles from '../../Styles/UI/Loader'
import { Colors } from '../../../Themes'

export default class SmallLoader extends Component {

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    topRight: PropTypes.bool,
    color: PropTypes.string,
    smaller: PropTypes.bool
  }

  static defaultProps = {
    visible: null,
    topRight: false,
    color: Colors.theme1,
    smaller: false
  }

  render = () => {
    if (!this.props.visible) { return null }
    return (
      <div style={this.props.topRight ? styles.topRightContainer : styles.container}>
        <CssLoader color={this.props.color} size={this.props.smaller ? 0.25 : 0.4} />
      </div>
    )
  }

}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Metrics } from '../../../Themes'
import BaseButton from './BaseButton'

export default class FacebookButton extends Component {

  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    icon: PropTypes.string,
    iconSize: PropTypes.string,
    buttonStyle: PropTypes.object,
    customButtonStyle: PropTypes.object
  }

  static defaultProps = {
    buttonText: null,
    onPress: null,
    disabled: null,
    icon: null,
    iconSize: Metrics.icons.regular,
    buttonStyle: { margin: Metrics.baseMargin + ' 0' },
    customButtonStyle: {}
  }

  render = () => {
    return (
      <BaseButton
        themeColor={'secondary'}
        buttonText={this.props.buttonText}
        onPress={this.props.onPress}
        disabled={this.props.disabled}
        icon={this.props.icon}
        iconSize={this.props.iconSize}
        buttonStyle={Object.assign({}, this.props.buttonStyle, this.props.customButtonStyle)}
      />
    )
  }

}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from '../../../i18n/i18n'
import AppText from '../Texts/AppText'
import ContentCard from './ContentCard'
import GhostButton from '../Buttons/GhostButton'
import BaseForm from '../Forms/BaseForm'
import { Colors, Metrics } from '../../../Themes/'

export default class FormCard extends Component {

  static propTypes = {
    onSubmit: PropTypes.func,
    fields: PropTypes.object.isRequired,
    fieldsOptions: PropTypes.object.isRequired,
    deleteButton: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    renderExtraContentBeforeForm: PropTypes.func,
    renderExtraContentAfterButtons: PropTypes.func,
    renderExtraContentBeforeButtons: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    submitText: PropTypes.string,
    deleteText: PropTypes.string,
    submitIcon: PropTypes.string,
    value: PropTypes.object,
    fetching: PropTypes.bool,
    editing: PropTypes.bool,
    withEditButton: PropTypes.bool,
    toggleEdit: PropTypes.func,
    customEditIcon: PropTypes.string,
    withoutSubmit: PropTypes.bool,
    submitWithIndicator: PropTypes.bool,
    submitFetchingIndicator: PropTypes.bool,
    submitButtonWithoutMargin: PropTypes.bool,
    submitButtonAsGhostButton: PropTypes.bool,
    submitButtonDisabled: PropTypes.bool,
    descriptionWithoutMargin: PropTypes.bool,
    withoutCardStyles: PropTypes.bool,
    submitOnEnter: PropTypes.bool
  }

  static defaultProps = {
    onSubmit: null,
    fields: null,
    fieldsOptions: null,
    deleteButton: null,
    onChange: null,
    onDelete: null,
    renderExtraContentBeforeForm: null,
    renderExtraContentAfterButtons: null,
    renderExtraContentBeforeButtons: null,
    title: null,
    description: null,
    submitText: I18n.t('shared.save'),
    deleteText: I18n.t('shared.delete'),
    submitIcon: 'save',
    value: null,
    fetching: false,
    editing: false,
    withEditButton: false,
    toggleEdit: null,
    customEditIcon: 'sound-mix',
    withoutSubmit: false,
    submitWithIndicator: false,
    submitFetchingIndicator: false,
    submitButtonWithoutMargin: false,
    submitButtonAsGhostButton: false,
    submitButtonDisabled: false,
    descriptionWithoutMargin: false,
    withoutCardStyles: false,
    submitOnEnter: false
  }

  render = () => {
    return (
      <ContentCard
        title={this.props.title}
        customButtonsContainerStyles={{ marginTop: 0 }}
        renderContent={
          () => (
            <div>
              { 
                this.props.description && 
                <AppText 
                  text={this.props.description} 
                  customStyle={this.props.descriptionWithoutMargin ? { marginBottom: 0 } : { marginBottom: Metrics.doubleBaseMargin }}
                />
              }
              {
                this.props.renderExtraContentBeforeForm &&
                this.props.renderExtraContentBeforeForm()
              }
              <BaseForm
                onSubmit={this.props.onSubmit}
                onChange={this.props.onChange}
                fields={this.props.fields}
                fieldsOptions={this.props.fieldsOptions}
                renderExtraContentAfterButtons={this.props.renderExtraContentAfterButtons}
                renderExtraContentBeforeButtons={this.props.renderExtraContentBeforeButtons}
                submitText={this.props.submitText}
                submitIcon={this.props.submitIcon}
                value={this.props.value}
                fetching={this.props.fetching}
                withoutSubmit={this.props.withoutSubmit}
                submitWithIndicator={this.props.submitWithIndicator}
                submitFetchingIndicator={this.props.submitFetchingIndicator}
                submitButtonWithoutMargin={this.props.submitButtonWithoutMargin}
                submitButtonAsGhostButton={this.props.submitButtonAsGhostButton}
                submitButtonDisabled={this.props.submitButtonDisabled}
                submitOnEnter={this.props.submitOnEnter}
              />
            </div>
          )
        }
        renderButtons={
          () => {
            if (!this.props.deleteButton) { return null }
            return (
              <GhostButton
                buttonText={this.props.deleteText}
                onPress={this.props.onDelete}
                disabled={this.props.fetching}
                customButtonStyle={{ margin: 0 }}
              />
            )
          }
        }
        editing={this.props.editing}
        customEditIcon={this.props.customEditIcon}
        withEditButton={this.props.withEditButton}
        toggleEdit={this.props.toggleEdit}
        customStyle={this.props.withoutCardStyles ? { marginTop: Metrics.doubleBaseMargin, borderColor: Colors.transparent } : {}}
        customContentStyle={this.props.withoutCardStyles ? { padding: 0 } : {}}
      />
    )
  }

}

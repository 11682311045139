import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form/lib'
import RaixerConfig from '../../../../Config/RaixerConfig'
import I18n from '../../../../i18n/i18n'
import { getConnectionAutoAccessDoorPort } from '../../../../Lib/ConnectionsUtils'
import { getConnectionDoorsAsFormStruct } from '../../../../Lib/ConnectionFormsUtils'
import { getDatetimes, getRecalculatedDatetimes } from '../../../../Lib/DateTimeUtils'
import FullScreenLoader from '../../../UI/Loaders/FullScreenLoader'
import FormCard from '../../../UI/ContentCards/FormCard'

export default class ScheduleForm extends Component {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    hasMultipleAutoAccessDoors: PropTypes.bool.isRequired,
    activeLang: PropTypes.string.isRequired,
    schedule: PropTypes.object,
    connection: PropTypes.object,
    fetching: PropTypes.bool.isRequired
  }

  static defaultProps = {
    onSubmit: null,
    onDelete: null,
    hasMultipleAutoAccessDoors: null,
    activeLang: null,
    schedule: null,
    connection: null,
    fetching: false
  }

  constructor(props) {
    super(props)
    const time = RaixerConfig.connections.maxTimeMinutesApiUser
    const { now, to } = getDatetimes(null, null, time)
    this.state = {
      value: {
        startDate: now,
        endDate: to,
        startTime: now,
        endTime: to,
        doorPort: '1',
        createdBy: ''
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const { schedule: prevSchedule, connection: prevConnection } = prevProps
    const { schedule, connection } = this.props
    if (!prevSchedule && schedule) {
      const { _id, doorPort, dateFrom, dateTo, campaignId, createdBy } = schedule
      const { now, to } = getDatetimes(dateFrom, dateTo)
      this.setState({ 
        value: {
          _id,
          campaignId,
          startDate: now,
          endDate: to,
          startTime: now,
          endTime: to,
          doorPort: doorPort,
          createdBy
        } 
      })
    }
    if (!prevConnection && connection && !schedule && !prevSchedule) {
      const time = RaixerConfig.connections.maxTimeMinutesApiUser
      const { now, to } = getDatetimes(null, null, time)
      let value = {
        _id: null,
        campaignId: this.props.connection.device.campaignId,
        startDate: now,
        endDate: to,
        startTime: now,
        endTime: to,
        doorPort: '1',
        createdBy: ''
      }
      if (!this.props.hasMultipleAutoAccessDoors) { value.doorPort = '' + getConnectionAutoAccessDoorPort(this.props.connection) }
      this.setState({ value })
    }
  }

  onChange = (value) => {
    const { _id, campaignId, doorPort, startDate: sDate, startTime: sTime, endDate: eDate, endTime: eTime, createdBy } = value
    const time = this.props.isSubscribed ? RaixerConfig.connections.maxTimeMinutesApiUser : RaixerConfig.connections.maxTimeMinutes
    const { startDate, endDate, startTime, endTime } = getRecalculatedDatetimes(sDate, sTime, eDate, eTime, time)
    this.setState({ 
      value: {
        _id,
        campaignId,
        doorPort,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        createdBy
      } 
    })
  }

  getFields = () => {
    const doors = getConnectionDoorsAsFormStruct(this.props.connection)
    return {
      _id: t.maybe(t.String),
      campaignId: t.maybe(t.String),
      startDate: t.String,
      endDate: t.String,
      startTime: t.String,
      endTime: t.String,
      doorPort: t.enums(doors, 'Port'),
      createdBy: t.maybe(t.String)
    }
  }

  getFieldsOptions = () => {
    let fieldsOptions = {
      _id: { hidden: true },
      campaignId: { label: I18n.t('shared.campaign'), hidden: true },
      startDate: {
        label: I18n.t('screens.scheduleForm.labels.startDate'),
        mode: 'date',
        minimumDate: new Date()
      },
      endDate: {
        label: I18n.t('screens.scheduleForm.labels.endDate'),
        mode: 'date',
        minimumDate: new Date()
      },
      startTime: {
        label: I18n.t('screens.scheduleForm.labels.startTime'),
        mode: 'time',
        minuteInterval: 1
      },
      endTime: {
        label: I18n.t('screens.scheduleForm.labels.endTime'),
        mode: 'time',
        minuteInterval: 1
      },
      doorPort: {
        label: I18n.t('shared.door'),
        nullOption: false
      },
      createdBy: {
        hidden: true,
      }
    }
    if (!this.props.hasMultipleAutoAccessDoors) { fieldsOptions.doorPort.hidden = true }
    return fieldsOptions
  }

  render = () => {
    return (
      <>
        <FormCard
          name={'scheduleForm'}
          title={this.props.schedule ? I18n.t('screens.scheduleForm.titleFormEdit') : I18n.t('screens.scheduleForm.titleForm')}
          onSubmit={this.props.onSubmit}
          onChange={this.onChange}
          deleteButton={this.props.schedule ? true : false}
          onDelete={() => { this.props.onDelete(this.props.schedule) }}
          fields={this.getFields()}
          fieldsOptions={this.getFieldsOptions()}
          value={this.state.value}
          fetching={this.props.fetching}
        />
        <FullScreenLoader visible={this.props.fetching}/>
      </>
    )
  }

}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RaixerConfig from '../../../Config/RaixerConfig'
import I18n from '../../../i18n/i18n'
import AppText from '../Texts/AppText'
import LineItemEditButton from '../Buttons/LineItemEditButton'
import WeekdaySelector from '../Selectors/WeekdaysSelector'
import { Metrics } from '../../../Themes'
import { timestampToDate, timestampToTime } from '../../../Lib/DateTimeUtils'
import styles from '../../Styles/UI/ListItem'

export default class UserListItem extends Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    profile: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    enabledForUse: PropTypes.bool.isRequired,
    authorizedFrom: PropTypes.number,
    authorizedTo: PropTypes.number,
    repeatDaysOfWeek: PropTypes.string,
    repeatStartHour: PropTypes.string,
    repeatEndHour: PropTypes.string,
    withEditButton: PropTypes.bool.isRequired,
    lastOfList: PropTypes.bool,
    onPressEdit: PropTypes.func,
    editButtonFlex: PropTypes.number
  }

  static defaultProps = {
    name: null,
    profile: null,
    email: null,
    status: null,
    active: null,
    enabledForUse: null,
    authorizedFrom: null,
    authorizedTo: null,
    repeatDaysOfWeek: null,
    repeatStartHour: null,
    repeatEndHour: null,
    withEditButton: null,
    lastOfList: false,
    onPressEdit: () => { console.log() },
    editButtonFlex: 0
  }

  render = () => {
    return (
      <div style={!this.props.lastOfList ? styles.listItem : Object.assign({}, styles.listItem, styles.lastListItem)}>
        <div style={styles.listItemInner}>
          <div style={Object.assign({}, styles.listItemInner, { flexDirection: 'column', flex: RaixerConfig.animations.lists.itemFlex })}>
            <div style={Object.assign({}, styles.listItemInner, { marginBottom: Metrics.baseMargin, width: '100%' })}>
              <div style={Object.assign({}, styles.listItemLeft, styles.listItemImportant)}>
                <AppText 
                  text={this.props.name} 
                  customStyle={Object.assign({}, styles.listItemTitle, { textAlign: 'left' })}
                  numberOfLines={1}
                />
              </div>
              <div style={styles.listItemRight}>
                <AppText text={this.props.profile} customStyle={Object.assign({}, styles.listItemTitle, { textAlign: 'right' })} />
              </div>
            </div>
            <div style={Object.assign({}, styles.listItemInner, { marginBottom: Metrics.baseMargin, width: '100%' })}>
              <div style={Object.assign({}, styles.listItemLeft, styles.listItemImportant)}>
                <AppText 
                  text={this.props.email} 
                  customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'left' })}
                  numberOfLines={1}
                />
              </div>
              <div style={Object.assign({}, styles.listItemRight, styles.listItemRightRow)}>
                <AppText text={this.props.status} customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'right' })} />
              </div>
            </div>
            {
              this.props.authorizedFrom && this.props.authorizedTo && this.props.enabledForUse &&
              <>
                <div style={Object.assign({}, styles.listItemInner, { marginBottom: Metrics.baseMargin, width: '100%' })}>
                  <div style={styles.listItemLeft}>
                    <AppText 
                      text={I18n.t('shared.from')} 
                      customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'left' })}
                      numberOfLines={1}
                    />
                  </div>
                  <div style={Object.assign({}, styles.listItemRight, styles.listItemRightRow)}>
                    <AppText text={timestampToTime(this.props.authorizedFrom)} customStyle={Object.assign({}, styles.listItemContent, { marginRight: Metrics.smallMargin })} numberOfLines={1} />
                    <AppText text={timestampToDate(this.props.authorizedFrom)} customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'right' })} numberOfLines={1} />
                  </div>
                </div>
                <div style={Object.assign({}, styles.listItemInner, { width: '100%' })}>
                  <div style={styles.listItemLeft}>
                    <AppText 
                      text={I18n.t('shared.to')} 
                      customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'left' })}
                      numberOfLines={1}
                    />
                  </div>
                  <div style={Object.assign({}, styles.listItemRight, styles.listItemRightRow)}>
                    <AppText text={timestampToTime(this.props.authorizedTo)} customStyle={Object.assign({}, styles.listItemContent, { marginRight: Metrics.smallMargin })} numberOfLines={1} />
                    <AppText text={timestampToDate(this.props.authorizedTo)} customStyle={styles.listItemContent} numberOfLines={1} />
                  </div>
                </div>
              </>
            }
            {
              this.props.repeatStartHour && this.props.repeatEndHour && this.props.enabledForUse &&
              <div style={Object.assign({}, styles.listItemInner, { marginBottom: Metrics.smallMargin, width: '100%' })}>
                <div style={Object.assign({}, styles.listItemLeft, this.props.customLeftStyle)}>
                  <AppText text={I18n.t('shared.hours')} customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'left' })} numberOfLines={1} />
                </div>
                <div style={Object.assign({}, styles.listItemRight, this.props.customRightStyle)}>
                  <AppText text={this.props.repeatStartHour + ' - ' + this.props.repeatEndHour} customStyle={styles.listItemContent} numberOfLines={1} />
                </div>
              </div>
            }
            {
              this.props.repeatDaysOfWeek && this.props.enabledForUse &&
              <WeekdaySelector 
                value={this.props.repeatDaysOfWeek} 
                editable={false} 
                customContainerStyle={{ marginTop: Metrics.baseMargin }}
              />
            }
          </div>
          {
            this.props.withEditButton &&
            <div style={Object.assign({}, styles.listItemEditButtonContainer, { flex: this.props.editButtonFlex })}>
              <LineItemEditButton
                buttonText={I18n.t('shared.edit')}
                onPress={this.props.onPressEdit}
                disabled={false}
                customButtonStyle={{ marginBottom: 0, marginLeft: Metrics.doubleBaseMargin }}
              />
            </div>
          }
        </div>
      </div>
    )
  }

}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form/lib'
import { TextField } from '@material-ui/core'
import I18n from '../../../../i18n/i18n'
import { objectDeepCopy } from '../../../../Lib/ObjectsUtils'
import { removeWhiteSpaces } from '../../../../Lib/StringsUtils'
import { getConnectionSelectedDoorPort } from '../../../../Lib/ConnectionsUtils'
import { getConnectionDoorsAsFormStruct } from '../../../../Lib/ConnectionFormsUtils'
import { 
  inputDateFormat, 
  inputTimeFormat, 
  getDatetimes, 
  getTimes,
  getRecalculatedDatetimes, 
  getDatetimeAsUTC, 
  getDatetimeIsBeforeNow,
  getTimezone,
  getTimeInCurrentTimezoneAsString } from '../../../../Lib/DateTimeUtils'
import { phoneValidation } from '../../../../Lib/FormUtils'
import FullScreenLoader from '../../../UI/Loaders/FullScreenLoader'
import BaseSwitch from '../../../UI/Switchs/BaseSwitch'
import WeekdaysSelector from '../../../UI/Selectors/WeekdaysSelector'
import BaseForm from '../../../UI/Forms/BaseForm'
import FormCard from '../../../UI/ContentCards/FormCard'
import styles from '../../../Styles/Screens/Forms'
import { Metrics } from '../../../../Themes'

export default class AuthorizedPhoneForm extends Component {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    hasMultiplePhoneDoors: PropTypes.bool.isRequired,
    activeLang: PropTypes.string.isRequired,
    doorId: PropTypes.string,
    phone: PropTypes.object,
    connection: PropTypes.object,
    fetching: PropTypes.bool.isRequired,
    submitButtonDisabled: PropTypes.bool.isRequired
  }

  static defaultProps = {
    onSubmit: null,
    onDelete: null,
    hasMultiplePhoneDoors: null,
    activeLang: null,
    doorId: null,
    phone: null,
    connection: null,
    fetching: false,
    submitButtonDisabled: false
  }

  constructor(props) {
    super(props)
    const { now, to } = getDatetimes(null, null)
    this.state = {
      value: {
        _id: null,
        phone: '+',
        alias: '',
        doorPort: null
      },
      valueDates: {
        startDate: now,
        endDate: to,
        startTime: now,
        endTime: to
      },
      unlimitedAccess: true,
      deleteOnEnd: false,
      deleteOnCall: false,
      deleteOnCallAfter: 1,
      repeatDays: false,
      repeatDaysOfWeek: ''
    }
  }

  componentDidUpdate = (prevProps) => {
    const { phone: prevPhone, connection: prevConnection } = prevProps
    const { phone, connection } = this.props
    if (!prevPhone && phone) {
      const { 
        _id, phoneNumber, alias = '', authorizedFrom, authorizedTo, deleteOnEnd = false, deleteOnCall = false, deleteOnCallAfter = 1,
        repeatDaysOfWeek, repeatEndHour, repeatStartHour, repeatTimezone 
      } = phone
      const value = {
        _id,
        phone: '+' + phoneNumber,
        alias,
        doorPort: '' + getConnectionSelectedDoorPort(this.props.connection, this.props.doorId)
      }
      if (authorizedFrom && authorizedTo) {
        const { now, to } = getDatetimes(authorizedFrom, authorizedTo)
        const valueDates = {
          startDate: now,
          endDate: to,
          startTime: now,
          endTime: to
        }
        this.setState({ 
          value,
          valueDates,
          unlimitedAccess: false,
          deleteOnEnd,
          deleteOnCall,
          deleteOnCallAfter
        })
      } else if (repeatDaysOfWeek && repeatEndHour && repeatStartHour && repeatTimezone) {
        const { now, to } = getTimes(repeatStartHour, repeatEndHour)
        const valueDates = {
          startDate: new Date(),
          endDate: new Date(),
          startTime: now,
          endTime: to
        }
        this.setState({ 
          value,
          valueDates,
          unlimitedAccess: false,
          deleteOnEnd: false,
          deleteOnCall: false,
          deleteOnCallAfter: 1,
          repeatDays: true,
          repeatDaysOfWeek
        })
      } else {
        this.setState({ value })
      }
    }
    if (!prevConnection && connection && !phone && !prevPhone) {
      let value = {
        _id: null,
        phone: '+',
        alias: '',
        doorPort: '' + getConnectionSelectedDoorPort(this.props.connection, this.props.doorId)
      }
      this.setState({ value })
    }
  }

  onChange = (value) => {
    const { _id, phone = '+', alias, doorPort } = value
    let newPhone = removeWhiteSpaces(phone)
    if (newPhone.indexOf('+') === -1) { newPhone = '+' + newPhone }
    this.setState({ 
      value: {
        _id,
        phone: newPhone,
        alias,
        doorPort
      } 
    })
  }

  onChangeDates = (value) => {
    const { startDate: sDate, startTime: sTime, endDate: eDate, endTime: eTime } = value
    const { startDate, endDate, startTime, endTime } = getRecalculatedDatetimes(sDate, sTime, eDate, eTime, null, this.state.repeatDays ? true : false)
    this.setState({ 
      valueDates: {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime
      } 
    })
  }

  onUnlimitedAccessSwitchChange = (value) => {
    this.setState({ unlimitedAccess: value })
  }

  onDeleteOnEndSwitchChange = (value) => {
    this.setState({ deleteOnEnd: value, repeatDays: value ? false : this.state.repeatDays })
  }

  onRepeatDaysSwitchChange = (value) => {
    this.setState({ repeatDays: value, deleteOnEnd: value ? false : this.state.deleteOnEnd })
  }

  onDeleteOnCallSwitchChange = (value) => {
    this.setState({ deleteOnCall: value })
  }

  onSubmit = (value) => {
    const { deleteOnEnd, deleteOnCall, deleteOnCallAfter, repeatDays, repeatDaysOfWeek, unlimitedAccess, valueDates } = this.state
    let completeValue = objectDeepCopy(value)
    completeValue.authorized = true
    completeValue.deleteOnEnd = deleteOnEnd
    completeValue.deleteOnCall = deleteOnCall
    completeValue.deleteOnCallAfter = deleteOnCallAfter
    // If unlimitedAccess is unchecked, complete value with valueDates or with repeat values
    if (!unlimitedAccess) { 
      const { startDate, startTime, endDate, endTime } = valueDates
      if (repeatDays) {
        // If repeatDays is checked, complete value with repeat values
        completeValue.deleteOnEnd = false
        completeValue.authorized = true
        completeValue.authorizedFrom = null
        completeValue.authorizedTo = null
        completeValue.repeatDaysOfWeek = repeatDaysOfWeek
        completeValue.repeatStartHour = getTimeInCurrentTimezoneAsString(startDate,startTime)
        completeValue.repeatEndHour = getTimeInCurrentTimezoneAsString(endDate, endTime)
        completeValue.repeatTimezone = getTimezone()
      } else {
        // If repeatDays is not checked, complete value with valueDates
        const startUTC = getDatetimeAsUTC(startDate, startTime)
        const endUTC = getDatetimeAsUTC(endDate, endTime)
        completeValue.authorizedFrom = startUTC
        completeValue.authorizedTo = endUTC
        completeValue.authorized = getDatetimeIsBeforeNow(startDate, startTime)
        completeValue.repeatDaysOfWeek = null
        completeValue.repeatStartHour = null
        completeValue.repeatEndHour = null
        completeValue.repeatTimezone = null
      }
    }
    // If alias is not present, complete value with an empty alias
    if (!completeValue.alias) { completeValue.alias = '' }
    this.props.onSubmit(completeValue)
  }

  getFields = () => {
    const doors = getConnectionDoorsAsFormStruct(this.props.connection, true, true, true)
    const fieldsPhone = {
      _id: t.maybe(t.String),
      phone: t.refinement(t.String, phoneValidation),
      alias: t.maybe(t.String),
      doorPort: t.enums(doors, 'Port')
    }
    const fieldsDates = {
      startDate: t.String,
      endDate: t.String,
      startTime: t.String,
      endTime: t.String,
    }
    return { fieldsPhone, fieldsDates }
  }

  getFieldsOptions = () => {
    const editing = this.state.value._id ? true : false
    let fieldsOptionsPhone = {
      _id: { hidden: true },
      phone: { 
        label: I18n.t('screens.authorizedPhoneForm.labels.phone'),
        help: I18n.t('screens.authorizedPhoneForm.helps.phone'),
        autoCapitalize: 'none',
        autoCorrect: false,
        keyboardType: 'phone-pad'
      },
      alias: {
        label: I18n.t('screens.authorizedPhoneForm.labels.alias'),
      },
      doorPort: {
        label: I18n.t('shared.door')
      }
    }
    let fieldsOptionsDates = {
      startDate: {
        label: I18n.t('screens.scheduleForm.labels.startDate'),
        mode: 'date',
        minimumDate: new Date(),
        hidden: this.state.repeatDays ? true : false,
        config: {
          format: (date) => inputDateFormat(date),
        }
      },
      endDate: {
        label: I18n.t('screens.scheduleForm.labels.endDate'),
        mode: 'date',
        minimumDate: new Date(),
        hidden: this.state.repeatDays ? true : false,
        config: {
          format: (date) => inputDateFormat(date),
        }
      },
      startTime: {
        label: I18n.t('screens.scheduleForm.labels.startTime'),
        mode: 'time',
        minuteInterval: 1,
        minimumDate: !this.state.repeatDays ? new Date() : null,
        config: {
          format: (time) => inputTimeFormat(time),
        }
      },
      endTime: {
        label: I18n.t('screens.scheduleForm.labels.endTime'),
        mode: 'time',
        minuteInterval: 1,
        minimumDate: !this.state.repeatDays ? new Date() : null,
        config: {
          format: (time) => inputTimeFormat(time),
        }
      }
    }
    // if (this.state.repeatDays) {
    //   let customStylesheet = objectDeepCopy(stylesForms)
    //   customStylesheet.controlLabel.normal.marginTop = 0
    //   customStylesheet.controlLabel.error.marginTop = 0
    //   fieldsOptionsDates['startTime']['stylesheet'] = customStylesheet
    // } else {
    //   let customStylesheet = objectDeepCopy(stylesForms)
    //   customStylesheet.controlLabel.normal.marginTop = Metrics.doubleBaseMargin
    //   customStylesheet.controlLabel.error.marginTop = Metrics.doubleBaseMargin
    //   fieldsOptionsDates['startTime']['stylesheet'] = customStylesheet
    // }
    if (!this.props.hasMultiplePhoneDoors || editing) { fieldsOptionsPhone.doorPort.hidden = true }
    return { fieldsOptionsPhone, fieldsOptionsDates }
  }

  renderExtraContent = (fields, fieldsOptions) => {
    const { device = {} } = this.props.connection || {}
    const { trialEnded = false } = device
    return (
      <>
        <BaseSwitch
          label={null}
          help={I18n.t('screens.authorizedPhoneForm.labels.unlimitedAccess')}
          value={this.state.unlimitedAccess}
          disabled={trialEnded}
          onChange={(value) => { this.onUnlimitedAccessSwitchChange(value) }}
          customSwitchRowStyle={styles.switchRow}
        />
        {
          !this.state.unlimitedAccess &&
          <div style={styles.complementaryForm}>
            <BaseForm
              name={'authorizedPhoneAccessDatesForm'}
              onSubmit={() => {}}
              fields={fields}
              fieldsOptions={fieldsOptions}
              onChange={this.onChangeDates}
              value={this.state.valueDates}
              fetching={this.props.fetching}
              withoutSubmit={true}
            />
            <BaseSwitch
              label={null}
              help={I18n.t('screens.authorizedPhoneForm.labels.deleteOnEnd')}
              value={this.state.deleteOnEnd}
              disabled={false}
              onChange={(value) => { this.onDeleteOnEndSwitchChange(value) }}
              customSwitchRowStyle={styles.switchRow}
            />
            <BaseSwitch
              label={null}
              help={I18n.t('screens.authorizedPhoneForm.labels.repeatDays')}
              value={this.state.repeatDays}
              disabled={false}
              onChange={(value) => { this.onRepeatDaysSwitchChange(value) }}
              customSwitchRowStyle={styles.switchRow}
            />
            {
              this.state.repeatDays &&
              <WeekdaysSelector
                onChange={(repeatDaysOfWeek) => { this.setState({ repeatDaysOfWeek }) }}
                value={this.state.repeatDaysOfWeek}
              />
            }
            <BaseSwitch
              label={null}
              help={I18n.t('screens.authorizedPhoneForm.labels.deleteOnCall')}
              value={this.state.deleteOnCall}
              disabled={false}
              onChange={(value) => { this.onDeleteOnCallSwitchChange(value) }}
              customSwitchRowStyle={styles.switchRow}
            />
            {
              this.state.deleteOnCall &&
              <div style={{ marginTop: Metrics.doubleBaseMargin, marginBottom: Metrics.baseMargin }}>
                <TextField
                  type={'text'}
                  value={this.state.deleteOnCallAfter}
                  fullWidth={true}
                  label={I18n.t('screens.authorizedPhoneForm.labels.deleteOnCallAfter')}
                  onChange={(event) => { this.setState({ deleteOnCallAfter: event.target.value }) }}
                />
              </div>
            }
          </div>
        }
      </>
    )
  }

  render = () => {
    const { fieldsPhone, fieldsDates } = this.getFields()
    const { fieldsOptionsPhone, fieldsOptionsDates } = this.getFieldsOptions()
    return (
        <>
          <FormCard
            name={'authorizedPhoneForm'}
            title={this.props.phone ? I18n.t('screens.authorizedPhoneForm.titleFormEdit') : I18n.t('screens.authorizedPhoneForm.titleForm')}
            onSubmit={this.onSubmit}
            onChange={this.onChange}
            deleteButton={this.props.phone ? true : false}
            onDelete={() => { this.props.onDelete(this.props.phone) }}
            fields={fieldsPhone}
            fieldsOptions={fieldsOptionsPhone}
            value={this.state.value}
            fetching={this.props.fetching}
            renderExtraContentBeforeButtons={() => this.renderExtraContent(fieldsDates, fieldsOptionsDates)}
            submitButtonDisabled={this.props.submitButtonDisabled}
          />
          <FullScreenLoader visible={this.props.fetching}/>
        </>
    )
  }

}
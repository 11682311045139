import { Colors, Fonts, Metrics } from '../../../Themes/'

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '25%',
    minWidth: '392px',
    zIndex: Metrics.zIndexes.authContainer
  },
  logo: {
    width: 170,
    height: 53,
    display: 'flex',
    margin: Metrics.doubleBaseMargin + ' auto'
  },
  navigationLinksContainerFirst: {
    marginTop: Metrics.baseMargin
  },
  navigationLinksContainerLast: {
    marginBottom: 0
  },
  navigationLinksText: {
    color: Colors.darkGray,
    fontWeight: Fonts.weight.regular
  },
  termsText: {
    marginTop: Metrics.doubleBaseMargin,
    textAlign: 'center'
  },
  termsLinksContainer: {
    padding: Metrics.baseMargin + ' ' + Metrics.baseMargin
  },
  termsLinks: {
    fontWeight: Fonts.weight.regular,
    color: Colors.theme1,
    textAlign: 'center'
  }
}
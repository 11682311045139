import { call, put, select } from 'redux-saga/effects'
import { goBack, pop } from '../Navigation/RootNavigation'
import RaixerConfig from '../Config/RaixerConfig'
import I18n from '../i18n/i18n'
import { objectDeepCopy, json2Urlencoded, upsertOrDeleteInArrayById } from '../Lib/ObjectsUtils'
import { getAddedConnectionIdWithConnectionId, getActualConnection, updateConnectionsWithConnection } from '../Lib/ConnectionsUtils'
import { delay } from '../Lib/DateTimeUtils'
import StripeActions, { StripeSelectors } from '../Redux/StripeRedux'
import ConnectionsActions, { ConnectionsSelectors } from '../Redux/ConnectionsRedux'
// import GuestyActions from '../Redux/GuestyRedux'
import { UserSelectors } from '../Redux/UserRedux'
import { AlertService } from '../Services/Alert'
import { ModalService } from '../Services/Modal'
import { navigate, popToTop } from '../Navigation/RootNavigation'
import moment from 'moment'

const checkUserShowBillingModal = (user) => {
  try {
    const { address } = user || {}
    if (!address) {
      ModalService.update(
        I18n.t('modals.upgradesBillingAddress.title'),
        I18n.t('modals.upgradesBillingAddress.text'),
        () => { 
          ModalService.dismiss()
          popToTop()
          navigate('ProfileScreen')
          navigate('BillingInfoFormScreen')
        },
        I18n.t('modals.upgradesBillingAddress.buttons.ok'),
        I18n.t('modals.buttons.defaultOk')
      )
    } else {
      ModalService.dismiss()
    }
  } catch {
    ModalService.dismiss()
  }
}

export function * getCustomer (api, action) {
  const { customerId } = action
  yield call(api.resetFormHeader)
  const response = yield call(api.getCustomer, customerId)
  const { ok, data: customer } = response
  if (ok) {
    yield put(StripeActions.finishedRequest(customer, null))
  } else {
    yield put(StripeActions.finishedRequest(null, true))
    AlertService.error(I18n.t('snacks.errors.getStripeCustomer'))
  }
}

export function * addCreditCard (api, action) {
  const { customerId, creditCard } = action
  const encodedParams = json2Urlencoded(creditCard)
  const customer = yield select(StripeSelectors.selectCustomer)
  yield call(api.resetFormHeader)
  const response = yield call(api.addCreditCard, customerId, encodedParams)
  const { ok, data: newCreditCard } = response
  if (ok) {
    let newCustomer = objectDeepCopy(customer)
    newCustomer.sources.data = [newCreditCard]
    yield put(StripeActions.finishedRequest(newCustomer, null))
    AlertService.success(I18n.t('snacks.success.addStripeCard'))
  } else {
    yield put(StripeActions.finishedRequest(customer, true))
    AlertService.error(I18n.t('snacks.errors.addStripeCard'))
  }
}

export function * editCreditCard (api, action) {
  const { customerId, creditCardId, creditCard } = action
  const encodedParams = json2Urlencoded(creditCard)
  const customer = yield select(StripeSelectors.selectCustomer)
  yield call(api.resetFormHeader)
  const response = yield call(api.updateCreditCard, customerId, creditCardId, encodedParams)
  const { ok, data: newCreditCard } = response
  if (ok) {
    let newCustomer = objectDeepCopy(customer)
    newCustomer.sources.data = [newCreditCard]
    yield put(StripeActions.finishedRequest(newCustomer, null))
    AlertService.success(I18n.t('snacks.success.updateStripeCard'))
  } else {
    yield put(StripeActions.finishedRequest(customer, true))
    AlertService.error(I18n.t('snacks.errors.updateStripeCard'))
  }
}

export function * deleteCreditCard (api, action) {
  const { customerId, creditCardId } = action
  const customer = yield select(StripeSelectors.selectCustomer)
  yield call(api.resetFormHeader)
  const response = yield call(api.deleteCreditCard, customerId, creditCardId)
  const { ok } = response
  if (ok) {
    let newCustomer = objectDeepCopy(customer)
    newCustomer.sources.data = []
    yield put(StripeActions.finishedRequest(newCustomer, null))
    AlertService.success(I18n.t('snacks.success.deleteStripeCard'))
  } else {
    yield put(StripeActions.finishedRequest(customer, true))
    AlertService.error(I18n.t('snacks.errors.deleteStripeCard'))
  }
}

export function * addSubscription (api, action) {
  const { connectionId, stripePlanId, activeLang } = action
  const customer = yield select(StripeSelectors.selectCustomer)
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { device } = connection
  const { campaignId, asUnsubscribedDate } = device
  const response = yield call(api.addSubscription, campaignId, stripePlanId, activeLang)
  const { ok, data: subscription } = response
  if (ok) {
    const user = yield select(UserSelectors.selectUser)
    const { _id } = subscription
    connection.device.trialEnded = false
    connection.device.trialEndedAccount = false
    connection.device.asUnsubscribedDate = null
    connection.device.campaign.subscriptionId = _id
    connection.device.campaign.subscription = subscription
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    yield put(StripeActions.finishedRequest(customer, null))
    // AlertService.success(I18n.t('snacks.success.addStripeSubscription'))
    goBack()
    ModalService.show(
      I18n.t('modals.addSubscription.title'),
      asUnsubscribedDate ? I18n.t('modals.addSubscription.textReactivation') : I18n.t('modals.addSubscription.text'),
      () => { checkUserShowBillingModal(user) },
      null,
      null,
      false,
      true,
      true
    )
  } else {
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.error(I18n.t('snacks.errors.addStripeSubscription'))
  }
}

export function * updateSubscription  (api, action) {
  const { connectionId, stripePlanId, activeLang } = action
  const customer = yield select(StripeSelectors.selectCustomer)
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { device } = connection
  const { campaign, campaignId } = device
  const { subscriptionId } = campaign
  const response = yield call(api.updateSubscription, subscriptionId, campaignId, stripePlanId, activeLang)
  const { ok, data: subscription } = response
  if (ok) {
    const { _id } = subscription
    connection.device.trialEnded = false
    connection.device.trialEndedAccount = false
    connection.device.asUnsubscribedDate = null
    connection.device.campaign.subscriptionId = _id
    connection.device.campaign.subscription = subscription
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.success(I18n.t('snacks.success.updateStripeSubscription'))
    goBack()
  } else {
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.error(I18n.t('snacks.errors.updateStripeSubscription'))
  }
}

export function * deleteSubscription (api, action) {
  const { connectionId, activeLang } = action
  const customer = yield select(StripeSelectors.selectCustomer)
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { device } = connection
  const { campaign, campaignId } = device
  const { subscriptionId } = campaign
  const response = yield call(api.deleteSubscription, subscriptionId, campaignId, activeLang)
  const { ok, data } = response
  if (ok) {
    const { asUnsubscribedDate } = data
    const modalTitle = I18n.t('modals.deleteSubscription.title')
    let modalText = I18n.t('modals.deleteSubscription.withoutDate.text')
    connection.device.trialEnded = true
    connection.device.trialEndedAccount = true
    connection.device.campaign.subscriptionId = null
    connection.device.campaign.subscription = null
    if (asUnsubscribedDate) { 
      connection.device.trialEnded = false
      connection.device.asUnsubscribedDate = asUnsubscribedDate 
      modalText = I18n.t('modals.deleteSubscription.withDate.textFirst') + moment(asUnsubscribedDate, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY')
      modalText += I18n.t('modals.deleteSubscription.withDate.textSecond')
    }
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    yield put(StripeActions.finishedRequest(customer, null))
    // AlertService.success(I18n.t('snacks.success.deleteStripeSubscription'))
    goBack()
    ModalService.show(
      modalTitle,
      modalText,
      null,
      null,
      I18n.t('modals.buttons.defaultOk')
    )
  } else {
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.error(I18n.t('snacks.errors.deleteStripeSubscription'))
  }
}

export function * addPhoneSubscription (api, action) {
  const { connectionId, doorId, stripePlanId, anyCallerPhone, newAuthorizedPhone, cameFromAuthorizedPhoneDirectly = false, activeLang } = action
  const customer = yield select(StripeSelectors.selectCustomer)
  if (doorId) {
    let connections = yield select(ConnectionsSelectors.selectConnections)
    let connection = objectDeepCopy(getActualConnection(connections, connectionId))
    const { deviceId } = connection
    if (anyCallerPhone && !newAuthorizedPhone) {
      const response = yield call(api.addPhoneSubscription, doorId, stripePlanId, activeLang)
      const { ok, data: newDoor } = response
      if (ok) {
        const responseAC = yield call(api.enablePhoneAnyCallerAccess, deviceId, doorId, { phoneNumber: anyCallerPhone })
        const { ok: okAC } = responseAC
        if (okAC) {
          const { device } = connection
          const { doors } = device
          let newerDoor = objectDeepCopy(newDoor)
          newerDoor.anyCallerEnabled = true
          let newDoors = upsertOrDeleteInArrayById(doors, doorId, newerDoor)
          connection.device.doors = newDoors
          const newConnections = updateConnectionsWithConnection(connections, connection)
          yield put(ConnectionsActions.connectionSuccess(newConnections))
          yield put(StripeActions.finishedRequest(customer, null))
          AlertService.success(I18n.t('snacks.success.addStripePhoneSubscription'))
          goBack()
        } else {
          AlertService.error(I18n.t('snacks.errors.connectionsDetailAnyCallerDoorPhone'))
          yield put(ConnectionsActions.connectionSuccess(connections))
        }
      } else {
        yield put(StripeActions.finishedRequest(customer, null))
        AlertService.error(I18n.t('snacks.errors.addStripePhoneSubscription'))
      }
    } else if (newAuthorizedPhone) {
      const response = yield call(api.claimPhoneOwnership, deviceId, doorId, { phoneNumber: anyCallerPhone, newAuthorizedPhone })
      const { ok } = response
      if (ok) {
        const { device } = connection
        const { doors } = device
        const responseAPS = yield call(api.addPhoneSubscription, doorId, stripePlanId, activeLang)
        const { ok: okAPS, data: newDoor } = responseAPS
        if (okAPS) {
          let newDoors = upsertOrDeleteInArrayById(doors, doorId, newDoor)
          connection.device.doors = newDoors
          const newConnections = updateConnectionsWithConnection(connections, connection)
          yield put(ConnectionsActions.connectionSuccess(newConnections))
          yield put(StripeActions.finishedRequest(customer, null))
          AlertService.success(I18n.t('snacks.success.addStripePhoneSubscription'))
          if (cameFromAuthorizedPhoneDirectly) { pop(2) }
          else { pop(3) }
        } else {
          yield put(StripeActions.finishedRequest(customer, null))
          AlertService.error(I18n.t('snacks.errors.addStripePhoneSubscription'))
        }
      } else {
        yield put(StripeActions.finishedRequest(customer, null))
        AlertService.error(I18n.t('snacks.errors.connectionsDetailAnyCallerDoorPhone'))
      }
    } else {
      const { device } = connection
      const { doors } = device
      const response = yield call(api.addPhoneSubscription, doorId, stripePlanId, activeLang)
      const { ok, data: newDoor } = response
      if (ok) {
        const { _id } = newDoor
        let newDoors = upsertOrDeleteInArrayById(doors, _id, newDoor)
        connection.device.doors = newDoors
        const newConnections = updateConnectionsWithConnection(connections, connection)
        yield put(ConnectionsActions.connectionSuccess(newConnections))
        yield put(StripeActions.finishedRequest(customer, null))
        AlertService.success(I18n.t('snacks.success.addStripePhoneSubscription'))
        goBack()
      } else {
        yield put(StripeActions.finishedRequest(customer, null))
        AlertService.error(I18n.t('snacks.errors.addStripePhoneSubscription'))
      }
    }
  } else {
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.error(I18n.t('snacks.errors.connectionsDetailAnyCallerDoorPhone'))
  }
}

export function * updatePhoneSubscription (api, action) {
  const { connectionId, doorId, stripePlanId, activeLang } = action
  const customer = yield select(StripeSelectors.selectCustomer)
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { device } = connection
  const { doors } = device
  const door = doors.filter(d => d._id === doorId)[0]
  const { phone } = door
  const { phoneSubscription } = phone
  const response = yield call(api.updatePhoneSubscription, phoneSubscription, doorId, stripePlanId, activeLang)
  const { ok, data: newDoor } = response
  if (ok) {
    const { _id } = newDoor
    let newDoors = upsertOrDeleteInArrayById(doors, _id, newDoor)
    connection.device.doors = newDoors
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.success(I18n.t('snacks.success.updateStripePhoneSubscription'))
    goBack()
  } else {
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.error(I18n.t('snacks.errors.updateStripePhoneSubscription'))
  }
}

export function * deletePhoneSubscription (api, action) {
  const { connectionId, doorId, activeLang } = action
  const customer = yield select(StripeSelectors.selectCustomer)
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { device } = connection
  const { doors } = device
  const door = doors.filter(d => d._id === doorId)[0]
  const { phone } = door
  const { phoneSubscription } = phone
  const response = yield call(api.deletePhoneSubscription, phoneSubscription, doorId, activeLang)
  const { ok, data: newDoor } = response
  if (ok) {
    const { _id } = newDoor
    let newDoors = upsertOrDeleteInArrayById(doors, _id, newDoor)
    connection.device.doors = newDoors
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.success(I18n.t('snacks.success.deleteStripePhoneSubscription'))
    goBack()
  } else {
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.error(I18n.t('snacks.errors.deleteStripePhoneSubscription'))
  }
}

// TODO:
// export function * addGuestySubscription (api, action) {
//   const { connectionId, listingId, stripePlanId, activeLang } = action
//   const customer = yield select(StripeSelectors.selectCustomer)
//   let connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { deviceId } = connection
//   const response = yield call(api.addGuestySubscription, deviceId, listingId, stripePlanId, activeLang)
//   const { ok, data } = response
//   if (ok) {
//     const { connections: newConnections, listings } = data
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//     yield put(GuestyActions.listingsRequestFinished(listings))
//     yield put(StripeActions.finishedRequest(customer, null))
//     AlertService.success(I18n.t('snacks.success.pairGuestyListing'))
//     pop(2)
//   } else {
//     yield put(StripeActions.finishedRequest(customer, null))
//     AlertService.error(I18n.t('snacks.errors.addStripeSubscription'))
//   }
// }

// export function * updateGuestySubscription  (api, action) {
//   const { connectionId, stripePlanId, activeLang } = action
//   const customer = yield select(StripeSelectors.selectCustomer)
//   let connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { device, deviceId } = connection
//   const { guestyListingId, guestySubscriptionId } = device
//   const response = yield call(api.updateGuestySubscription, guestySubscriptionId, deviceId, guestyListingId, stripePlanId, activeLang)
//   const { ok, data } = response
//   if (ok) {
//     const { connections: newConnections, listings } = data
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//     yield put(GuestyActions.listingsRequestFinished(listings))
//     yield put(StripeActions.finishedRequest(customer, null))
//     AlertService.success(I18n.t('snacks.success.updateStripeSubscription'))
//     goBack()
//   } else {
//     yield put(StripeActions.finishedRequest(customer, null))
//     AlertService.error(I18n.t('snacks.errors.updateStripeSubscription'))
//   }
// }

// export function * deleteGuestySubscription (api, action) {
//   const { connectionId, activeLang } = action
//   const customer = yield select(StripeSelectors.selectCustomer)
//   let connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { device, deviceId } = connection
//   const { guestySubscriptionId } = device
//   const response = yield call(api.deleteGuestySubscription, guestySubscriptionId, deviceId, activeLang)
//   const { ok, data } = response
//   if (ok) {
//     const { connections: newConnections, listings } = data
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//     yield put(GuestyActions.listingsRequestFinished(listings))
//     yield put(StripeActions.finishedRequest(customer, null))
//     AlertService.success(I18n.t('snacks.success.deleteGuestyListingPair'))
//     goBack()
//   } else {
//     yield put(StripeActions.finishedRequest(customer, null))
//     AlertService.error(I18n.t('snacks.errors.deleteStripeSubscription'))
//   }
// }

export function * addCheckinSubscription (api, action) {
  const { connectionId, stripePlanId, activeLang, newConn, numberOfSubLocations } = action
  const customer = yield select(StripeSelectors.selectCustomer)
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { deviceId } = connection || {}
  const response = yield call(api.addCheckinSubscription, deviceId ? null : newConn, deviceId, stripePlanId, activeLang, numberOfSubLocations)
  const { ok, data: newConnections } = response
  if (ok) {
    popToTop()
    yield call(delay, RaixerConfig.delays.afterFormTask / 2)
    let newConnectionId
    if (deviceId) {
      for (let p of newConnections) {
        for (let c of p) {
          if (c.deviceId === deviceId) { 
            newConnectionId = c._id
            break
          }
        }
        if (newConnectionId) { break }
      }
    } else {
      newConnectionId = getAddedConnectionIdWithConnectionId(connections, newConnections)
    }
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.success(I18n.t('snacks.success.addCheckinSubscription'))
    if (newConnectionId) { 
      if (deviceId) { navigate('ConnectionScreen', { connectionId: newConnectionId, fetch: true }) }
      else { navigate('CheckInScreen', { connectionId: newConnectionId, deviceId: null }) }
    }
  } else {
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.error(I18n.t('snacks.errors.addCheckinSubscription'))
  }
}

export function * updateCheckinSubscription  (api, action) {
  const { connectionId, stripePlanId, activeLang, numberOfSubLocations } = action
  const customer = yield select(StripeSelectors.selectCustomer)
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { _id, device, deviceId, type } = connection || {}
  const checkinSubscriptionId = !deviceId && type === 'checkin' ? connection.checkinSubscriptionId : device.checkinSubscriptionId
  const updateConnectionId = !deviceId && type === 'checkin' ? _id : null
  const response = yield call(api.updateCheckinSubscription, checkinSubscriptionId, deviceId, updateConnectionId, stripePlanId, activeLang, numberOfSubLocations)
  const { ok, data: newConnections } = response
  if (ok) {
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.success(I18n.t('snacks.success.updateCheckinSubscription'))
    goBack()
  } else {
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.error(I18n.t('snacks.errors.updateCheckinSubscription'))
  }
}

export function * deleteCheckinSubscription (api, action) {
  const { connectionId, activeLang } = action
  const customer = yield select(StripeSelectors.selectCustomer)
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { device, deviceId, type } = connection
  const checkinSubscriptionId = !deviceId && type === 'checkin' ? connection.checkinSubscriptionId : device.checkinSubscriptionId
  const response = yield call(api.deleteCheckinSubscription, checkinSubscriptionId, deviceId, connectionId, activeLang)
  const { ok, data: newConnections } = response
  if (ok) {
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.success(I18n.t('snacks.success.deleteCheckinSubscription'))
    popToTop()
  } else {
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.error(I18n.t('snacks.errors.deleteCheckinSubscription'))
  }
}

export function * retryPayment (api, action) {
  const { activeLang } = action
  const customer = yield select(StripeSelectors.selectCustomer)
  const response = yield call(api.retryPayment, activeLang)
  const { ok, data: newConnections } = response
  if (ok) {
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.success(I18n.t('snacks.success.retryPayment'))
    goBack()
  } else {
    yield put(StripeActions.finishedRequest(customer, null))
    AlertService.error(I18n.t('snacks.errors.retryPayment'))
  }
}
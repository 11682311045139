import ModalActions from '../Redux/ModalRedux'

class FormModal {

  constructor() {
    this.store = null
  }

  setStore = (store) => {
    if (store && !this.store) { this.store = store }
  }

  open = (type, params) => {
    this.store.dispatch(ModalActions.formShowRequest(type, params))
  }

  replaceWithHistory = (type, params) => {
    this.store.dispatch(ModalActions.formReplaceWithHistoryRequest(type, params))
  }

  close = () => {
    this.store.dispatch(ModalActions.formHideRequest())
    // setTimeout(() => { this.store.dispatch(ModalActions.clearRequest()) }, 500)
  }

}

export const FormModalService = new FormModal()
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form'
import { emailValidation } from '../../../Lib/FormUtils'
import I18n from '../../../i18n/i18n'
import FullScreenLoader from '../../UI/Loaders/FullScreenLoader'
import FormCard from '../../UI/ContentCards/FormCard'
import FacebookButton from '../../UI/Buttons/FacebookButton'
import SimpleTextButton from '../../UI/Buttons/SimpleTextButton'
import AppleLoginButton from '../../UI/Buttons/AppleLoginButton'
import Stores from '../../UI/Stores/Stores'
import { Images, Metrics } from '../../../Themes'
import styles from '../../Styles/Screens/Auth'

export default class Login extends Component {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onFacebookPress: PropTypes.func.isRequired,
    goToSignup: PropTypes.func.isRequired,
    goToRecover: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    activeLang: PropTypes.string.isRequired
  }

  static defaultProps = {
    onSubmit: null,
    onFacebookPress: null,
    goToSignup: null,
    goToRecover: null,
    fetching: null,
    activeLang: null
  }

  render = () => {
    return (
      <div style={styles.container}>
        <img src={Images.logo} alt={''} style={styles.logo} />
        <FormCard
          onSubmit={this.props.onSubmit}
          deleteButton={false}
          submitText={I18n.t('screens.auth.login')}
          submitIcon={null}
          submitOnEnter={true}
          fields={{
            email: t.refinement(t.String, emailValidation),
            password: t.String
          }}
          fieldsOptions={{
            email: {
              label: I18n.t('shared.email'),
              autoCapitalize: 'none',
              autoCorrect: false,
              customTemplate: true
            },
            password: {
              label: I18n.t('shared.password'),
              password: true,
              secureTextEntry: true,
              customTemplate: true,
              type: 'password'
            }
          }}
          renderExtraContentAfterButtons={() => (
            <div>
              <FacebookButton
                buttonText={I18n.t('screens.auth.facebookLogin')}
                onPress={this.props.onFacebookPress}
                disabled={false}
                customButtonStyle={{ marginTop: Metrics.baseMargin }}
              />
              <AppleLoginButton
                buttonText={I18n.t('screens.auth.appleLogin')}
              />
              <SimpleTextButton
                buttonText={I18n.t('screens.auth.linkSignup')}
                onPress={this.props.goToSignup}
                disabled={false}
                customButtonStyle={styles.navigationLinksContainerFirst}
                customTextStyle={styles.navigationLinksText}
              />
              <SimpleTextButton
                buttonText={I18n.t('screens.auth.linkRecover')}
                onPress={this.props.goToRecover}
                disabled={false}
                customButtonStyle={styles.navigationLinksContainerLast}
                customTextStyle={styles.navigationLinksText}
              />
            </div>
          )}
        />
        <Stores activeLang={this.props.activeLang} />
        <FullScreenLoader visible={this.props.fetching}/>
      </div>
    )
  }

}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContentCard from '../../Components/UI/ContentCards/ContentCard'
import { Images, Metrics } from '../../Themes'

class OnboardingImageScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedImage: null
    }
  }

  componentDidMount = () => {
    const { selectedImage } = this.props
    this.setState({ selectedImage })
  }

  render = () => {
    const { selectedImage } = this.state || {}
    if (!selectedImage) { return null }
    return (
      <ContentCard
        title={null}
        renderContent={() => (
          <img
            src={Images['onboarding'][this.props.activeLang][selectedImage]}
            alt={''}
            style={{ width: '100%', borderRadius: Metrics.buttonRadius }}
          />
        )}
        withEditButton={false}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    activeLang: state.i18n.activeLang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingImageScreen)
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  customerRequest: ['customerId'],
  finishedRequest: ['customer', 'error'],
  addCreditCardRequest: ['customerId', 'creditCard'],
  updateCreditCardRequest: ['customerId', 'creditCardId', 'creditCard'],
  deleteCreditCardRequest: ['customerId', 'creditCardId'],
  addSubscriptionRequest: ['connectionId', 'stripePlanId', 'activeLang'],
  updateSubscriptionRequest: ['connectionId', 'stripePlanId', 'activeLang'],
  deleteSubscriptionRequest: ['connectionId', 'activeLang'],
  addPhoneSubscriptionRequest: ['connectionId', 'doorId', 'stripePlanId', 'anyCallerPhone', 'newAuthorizedPhone', 'cameFromAuthorizedPhoneDirectly', 'activeLang'],
  updatePhoneSubscriptionRequest: ['connectionId', 'doorId', 'stripePlanId', 'activeLang'],
  deletePhoneSubscriptionRequest: ['connectionId', 'doorId', 'activeLang'],
  addGuestySubscriptionRequest: ['connectionId', 'listingId', 'stripePlanId', 'activeLang'],
  updateGuestySubscriptionRequest: ['connectionId', 'stripePlanId', 'activeLang'],
  deleteGuestySubscriptionRequest: ['connectionId', 'activeLang'],
  addCheckinSubscriptionRequest: ['connectionId', 'stripePlanId', 'activeLang', 'newConn', 'numberOfSubLocations'],
  updateCheckinSubscriptionRequest: ['connectionId', 'stripePlanId', 'activeLang', 'numberOfSubLocations'],
  deleteCheckinSubscriptionRequest: ['connectionId', 'activeLang'],
  retryPaymentRequest: ['activeLang']
  // addCreditRequest: ['user', 'customerId', 'credit']
})

export const StripeTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  customer: null,
  fetching: false,
  fetchingPay: false,
  error: null
})

/* ------------- Selectors ------------- */
export const StripeSelectors = {
  selectCustomer: state => state.stripe.customer
}

/* ------------- Reducers ------------- */

export const customerRequest = (state) => {
  return state.merge({
    fetching: true,
    error: null
  })
}

export const finishedRequest = (state, action) => {
  const { customer, error } = action
  return state.merge({ 
    customer,
    fetching: false,
    fetchingPay: false,
    error
  })
}

export const creditCardRequest = (state) => {
  return state.merge({
    fetching: true,
    error: null
  })
}

export const subscriptionRequest = (state) => {
  return state.merge({
    fetchingPay: true,
    error: null
  })
}

// export const addCreditRequest = (state) => {
//   return state.merge({
//     fetching: true
//   })
// }

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CUSTOMER_REQUEST]: customerRequest,
  [Types.FINISHED_REQUEST]: finishedRequest,
  [Types.ADD_CREDIT_CARD_REQUEST]: creditCardRequest,
  [Types.UPDATE_CREDIT_CARD_REQUEST]: creditCardRequest,
  [Types.DELETE_CREDIT_CARD_REQUEST]: creditCardRequest,
  [Types.ADD_SUBSCRIPTION_REQUEST]: subscriptionRequest,
  [Types.UPDATE_SUBSCRIPTION_REQUEST]: subscriptionRequest,
  [Types.DELETE_SUBSCRIPTION_REQUEST]: subscriptionRequest,
  [Types.ADD_PHONE_SUBSCRIPTION_REQUEST]: subscriptionRequest,
  [Types.UPDATE_PHONE_SUBSCRIPTION_REQUEST]: subscriptionRequest,
  [Types.DELETE_PHONE_SUBSCRIPTION_REQUEST]: subscriptionRequest,
  [Types.ADD_GUESTY_SUBSCRIPTION_REQUEST]: subscriptionRequest,
  [Types.UPDATE_GUESTY_SUBSCRIPTION_REQUEST]: subscriptionRequest,
  [Types.DELETE_GUESTY_SUBSCRIPTION_REQUEST]: subscriptionRequest,
  [Types.ADD_CHECKIN_SUBSCRIPTION_REQUEST]: subscriptionRequest,
  [Types.UPDATE_CHECKIN_SUBSCRIPTION_REQUEST]: subscriptionRequest,
  [Types.DELETE_CHECKIN_SUBSCRIPTION_REQUEST]: subscriptionRequest,
  [Types.RETRY_PAYMENT_REQUEST]: subscriptionRequest
  // [Types.ADD_CREDIT_REQUEST]: addCreditRequest
})

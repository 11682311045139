import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from '../../../../i18n/i18n'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import AppTitle from '../../../UI/Texts/AppTitle'
import AppText from '../../../UI/Texts/AppText'
import MainButton from '../../../UI/Buttons/MainButton'
import FullScreenLoader from '../../../UI/Loaders/FullScreenLoader'
import styles from '../../../Styles/Screens/Forms'

export default class DoorPhoneChangeConfirm extends Component {

  static propTypes = {
    connection: PropTypes.object,
    phone: PropTypes.string,
    savingAuthorizedPhone: PropTypes.bool.isRequired,
    fetching: PropTypes.bool.isRequired,
    onPressConfirm: PropTypes.func.isRequired
  }

  static defaultProps = {
    connection: null,
    phone: null,
    savingAuthorizedPhone: false,
    fetching: false,
    onPressConfirm: null
  }

  constructor(props) {
    super(props)
    this.state = {
      
    }
  }

  renderContent = () => {
    let text = I18n.t('screens.phoneDoorChangeConfirm.textAccessToAnyCaller')
    let buttonText = I18n.t('shared.enable')
    let buttonIcon = 'save'
    if (this.props.savingAuthorizedPhone) {
      text = I18n.t('screens.phoneDoorChangeConfirm.textAuthorizedPhone')
      buttonText = I18n.t('screens.phoneDoorChangeConfirm.buttons.authorizedPhone')
      buttonIcon = 'forward'
    }
    return (
      <>
        <AppText text={text} />
        <div style={styles.emptySpace} />
        <AppTitle text={this.props.phone || ''} customStyle={{ textAlign: 'center' }} />
        <div style={styles.emptySpace} />
        <MainButton
          buttonText={buttonText}
          onPress={this.props.onPressConfirm}
          disabled={false}
          icon={buttonIcon}
          customButtonStyle={{ marginVertical: 0, marginBottom: 0 }}
        />
      </>
    )
  }

  render = () => {
    let title = I18n.t('screens.phoneDoorChangeConfirm.titleCardAccessToAnyCaller')
    if (this.props.savingAuthorizedPhone) {
      title = I18n.t('screens.phoneDoorChangeConfirm.titleCardAuthorizedPhone')
    }
    return (
      <>
        <ContentCard
          title={title}
          renderContent={this.renderContent}
        />
        <FullScreenLoader visible={this.props.fetching}/>
      </>
    )
  }

}
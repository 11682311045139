import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { isArrayValid } from '../Lib/ObjectsUtils'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  connectionsRequest: null,
  connectionsSuccess: ['connections', 'startup'],
  connectionsFailure: ['error', 'platformError'],
  connectionsTaskRequest: ['connection', 'task', 'fromList', 'activeConnectionButton', 'params', 'dontAlert'],
  connectionsTaskFinished: ['connections', 'errorTask'],
  connectionsTaskClean: null,
  connectionRequest: ['connectionId'],
  connectionSuccess: ['connections', 'startup'],
  connectionFailure: ['errorConnection', 'errorQrs'],
  connectionHistoryRequest: ['connectionId', 'page', 'pageSize'],
  connectionHistoryClearRequest: ['connectionId'],
  connectionVitalsRequest: ['connectionId', 'vitalsType'],
  connectionUnblockBehavioursRequest: ['connectionId', 'params'],
  connectionDeviceUpdateFirmwareRequest: ['connectionId'],
  connectionScheduleSaveRequest: ['connectionId', 'schedule'],
  connectionScheduleDeleteRequest: ['connectionId', 'schedule'],
  connectionUserSaveRequest: ['connectionId', 'user'],
  connectionUserDeleteRequest: ['connectionId', 'user'],
  // connectionUserEnableRequest: ['connectionId', 'user'],
  // connectionUserDisableRequest: ['connectionId', 'user'],
  connectionQrsRequest: ['connectionId', 'transferQRs'],
  connectionAuthorizedPhoneSaveRequest: ['connectionId', 'phones'],
  connectionAuthorizedPhoneDeleteRequest: ['connectionId', 'phone'],
  connectionDoorPhoneStatusRequest: ['connectionId', 'doorId', 'status'],
  connectionDoorPhoneInformationRequest: ['connectionId', 'doorId', 'phoneId'],
  connectionDoorPhoneCountriesRequest: ['connectionId', 'doorId', 'change'],
  connectionDoorPhoneRequest: ['connectionId', 'doorId', 'country', 'phoneId'],
  connectionDoorPhoneDeleteRequest: ['connectionId', 'doorId'],
  connectionDoorPhoneAnyCallerStatusRequest: ['connectionId', 'doorId', 'status'],
  connectionDoorPhoneClaimOwnershipRequest: ['connectionId', 'doorId', 'anyCallerPhone', 'newAuthorizedPhone'],
  connectionAutoAccessConfigurationSaveRequest: ['connectionId', 'autoAccessAlwaysActive', 'offlineAutoAccess'],
  connectionAuthorizedCodeSaveRequest: ['connectionId', 'code'],
  connectionAuthorizedCodeDeleteRequest: ['connectionId', 'code'],
  connectionDoorCodeStatusRequest: ['connectionId', 'doorId', 'status'],
  connectionDoorCodeRequest: ['connectionId', 'doorId', 'alias', 'url'],
  connectionDoorCodeDeleteRequest: ['connectionId', 'doorId'],
  connectionDoorCodeAnyCallerStatusRequest: ['connectionId', 'doorId', 'status'],
  // connectionDeviceConfigurationWizardSaveRequest: ['connectionId', 'wizardDiscarded'],
  // connectionDeleteRequest: ['connectionId'],
  // connectionGeneralFormSaveRequest: ['connectionId', 'values'],
  // connectionDeviceUpdateLedBrightnessRequest: ['connectionId', 'brightness'],
  // connectionDeviceUpdateEnergySaveModeRequest: ['connectionId', 'energySaveMode', 'testing'],
  // connectionDeviceVariableFormRequest: ['connectionId', 'variable', 'port', 'alternateDeviceId'],
  // connectionSensorSaveRequest: ['connectionId', 'sensor'],
  // connectionSensorDeleteRequest: ['connectionId', 'sensor'],
  // connectionDoorSaveRequest: ['connectionId', 'door', 'stopAutoAccess'],
  // connectionDoorDeleteRequest: ['connectionId', 'door'],
  // connectionAutoCalibrationRequest: ['connectionId', 'port'],
  // connectionAutoCalibrationFinish: [
  //   'errorAutoCalibration', 'fetchingTask', 'autoCalibrationStep', 'autoCalibrationWaitTime', 
  //   'autoCalibrationCancelled', 'autoCalibrationResults'
  // ],
  connectionSaveLocationRequest: ['connectionId', 'location'],
  connectionDeleteLocationRequest: ['connectionId', 'location'],
  connectionSaveBookingRequest: ['connectionId', 'booking'],
  connectionDeleteBookingRequest: ['connectionId', 'booking'],
  connectionGetGuestBook: ['connectionId'],
  connectionSendGuestBook: ['connectionId'],
  connectionGetBookingsRequest: ['connectionId', 'locationId', 'page', 'beforeToday'],
  // connectionPairLocationRequest: ['connectionId', 'deviceId'],
  connectionNukiTaskRequest: ['connectionId', 'task', 'activeConnectionButton'],
  // connectionServoCalibrateRequest: ['connectionId', 'task', 'dontWaitExtra'],
  connectionInternetTaskRequest: ['connection', 'task', 'activeConnectionButton', 'params'],
  connectionInternetAndBleTaskRequest: ['connection', 'task', 'bleTask', 'activeConnectionButton', 'params'],
})

export const ConnectionsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  connections: [],
  fetching: false,
  fetchingTask: false,
  error: null,
  platformError: null,
  activeConnection: null,
  fetchingConnection: false,
  fetchingConnectionTab: false,
  fetchingConnectionForm: false,
  fetchingConnectionEmail: false,
  fetchingConnectionOther: false,
  errorConnection: null,
  errorTask: null,
  errorQrs: null,
  activeConnectionButton: null,
  // autoCalibrationStep: null, 
  // autoCalibrationWaitTime: null,
  // autoCalibrationCancelled: false,
  // autoCalibrationResults: null,
  // errorAutoCalibration: null
})

/* ------------- Selectors ------------- */
export const ConnectionsSelectors = {
  selectConnections: state => state.connections.connections,
  // selectAutoCalibrationCancelled: state => state.connections.autoCalibrationCancelled
}

/* ------------- Reducers ------------- */

export const connectionsRequest = (state) => {
  return state.merge({ fetching: true })
}

export const connectionsSuccess = (state, action) => {
  const { connections } = action
  return state.merge({ fetching: false, error: null, connections: isArrayValid(connections) ? connections : [] })
}

export const connectionsFailure = (state, action) => {
  const { error, platformError } = action
  return state.merge({ fetching: false, error, platformError, connections: [] })
}

export const connectionsTaskRequest = (state, action) => {
  const { connection, activeConnectionButton } = action
  const { _id } = connection
  return state.merge({ fetchingTask: true, errorTask: null, activeConnection: _id, activeConnectionButton })
}

export const connectionsTaskFinished = (state, action) => {
  const { connections, errorTask } = action
  return state.merge({ fetchingTask: false, connections: isArrayValid(connections) ? connections : [], activeConnection: null, errorTask })
}

export const connectionsTaskClean = (state) => {
  return state.merge({ activeConnectionButton: null })
}

export const connectionRequest = (state) => {
  return state.merge({ 
    fetchingConnection: true, 
    fetchingConnectionTab: true, 
    fetchingConnectionOther: true, 
    errorConnection: null, 
    activeConnectionButton: null
  })
}

export const connectionSuccess = (state, action) => {
  const { connections } = action
  return state.merge({ 
    fetchingConnection: false, 
    fetchingConnectionTab: false, 
    fetchingConnectionOther: false, 
    fetchingConnectionForm: false,
    errorConnection: null, 
    errorQrs: null,
    connections: isArrayValid(connections) ? connections : []
  })
}

export const connectionFailure = (state, action) => {
  const { errorConnection, errorQrs } = action
  return state.merge({ 
    fetchingConnection: false, 
    fetchingConnectionTab: false, 
    fetchingConnectionOther: false, 
    fetchingConnectionForm: false,
    errorConnection, 
    errorQrs 
  })
}

export const connectionTabRequest = (state) => {
  return state.merge({ fetchingConnectionTab: true })
}

export const connectionFormRequest = (state) => {
  return state.merge({ fetchingConnectionForm: true })
}

export const connectionOtherRequest = (state) => {
  return state.merge({ fetchingConnectionOther: true })
}

// export const connectionUserRequest = (state) => {
//   return state.merge({ fetchingConnectionForm: true })
// }

export const connectionQrsRequest = (state) => {
  return state.merge({ fetchingConnectionForm: true, errorQrs: null })
}

// export const connectionDeviceConfigurationWizardSaveRequest = (state) => {
//   return state.merge({ fetchingConnectionForm: true })
// }

// export const connectionDeleteRequest = (state) => {
//   return state.merge({ fetchingConnection: true })
// }

// export const connectionGeneralFormSaveRequest = (state) => {
//   return state.merge({ fetchingConnectionForm: true })
// }

// export const connectionDeviceUpdateLedBrightnessRequest = (state) => {
//   return state.merge({ fetchingConnectionOther: true })
// }

// export const connectionDeviceUpdateEnergySaveModeRequest = (state) => {
//   return state.merge({ fetchingConnectionForm: true })
// }

// export const connectionDeviceVariableFormRequest = (state) => {
//   return state.merge({ fetchingConnectionOther: true })
// }

// export const connectionSensorRequest = (state) => {
//   return state.merge({ fetchingConnectionForm: true })
// }

// export const connectionDoorRequest = (state) => {
//   return state.merge({ fetchingConnectionForm: true })
// }

// export const connectionAutoCalibrationRequest = (state) => {
//   return state.merge({ errorAutoCalibration: null, fetchingTask: true, autoCalibrationCancelled: false, autoCalibrationIntervalId: null })
// }

// export const connectionAutoCalibrationFinish = (state, action) => {
//   const { 
//     errorAutoCalibration, fetchingTask, autoCalibrationStep, autoCalibrationWaitTime, autoCalibrationCancelled,
//     autoCalibrationResults
//   } = action
//   return state.merge({ 
//     errorAutoCalibration, fetchingTask, autoCalibrationStep, autoCalibrationWaitTime, autoCalibrationCancelled, autoCalibrationResults
//   })
// }

export const connectionLocationRequest = (state) => {
  return state.merge({ fetchingConnectionForm: true })
}

// export const connectionEmailSuccess = (state) => {
//   return state.merge({ fetchingConnectionEmail: false })
// }

export const connectionNukiTaskRequest = (state, action) => {
  const { connectionId, activeConnectionButton } = action
  return state.merge({ fetchingTask: true, errorTask: null, activeConnection: connectionId, activeConnectionButton })
}

// export const connectionServoCalibrateRequest = (state, action) => {
//   const { connectionId } = action
//   return state.merge({ fetchingTask: true, errorTask: null, activeConnection: connectionId })
// }

export const connectionInternetTaskRequest = (state, action) => {
  const { connectionId, activeConnectionButton } = action
  return state.merge({ fetchingTask: true, errorTask: null, activeConnection: connectionId, activeConnectionButton })
}

export const connectionInternetAndBleTaskRequest = (state, action) => {
  const { connectionId, activeConnectionButton } = action
  return state.merge({ fetchingTask: true, errorTask: null, activeConnection: connectionId, activeConnectionButton })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CONNECTIONS_REQUEST]: connectionsRequest,
  [Types.CONNECTIONS_SUCCESS]: connectionsSuccess,
  [Types.CONNECTIONS_FAILURE]: connectionsFailure,
  [Types.CONNECTIONS_TASK_REQUEST]: connectionsTaskRequest,
  [Types.CONNECTIONS_TASK_FINISHED]: connectionsTaskFinished,
  [Types.CONNECTIONS_TASK_CLEAN]: connectionsTaskClean,
  [Types.CONNECTION_REQUEST]: connectionRequest,
  [Types.CONNECTION_SUCCESS]: connectionSuccess,
  [Types.CONNECTION_FAILURE]: connectionFailure,
  [Types.CONNECTION_HISTORY_REQUEST]: connectionTabRequest,
  [Types.CONNECTION_HISTORY_CLEAR_REQUEST]: connectionTabRequest,
  [Types.CONNECTION_VITALS_REQUEST]: connectionOtherRequest,
  [Types.CONNECTION_UNBLOCK_BEHAVIOURS_REQUEST]: connectionOtherRequest,
  [Types.CONNECTION_DEVICE_UPDATE_FIRMWARE_REQUEST]: connectionOtherRequest,
  [Types.CONNECTION_SCHEDULE_SAVE_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_SCHEDULE_DELETE_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_USER_SAVE_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_USER_DELETE_REQUEST]: connectionFormRequest,
  // [Types.CONNECTION_USER_ENABLE_REQUEST]: connectionUserRequest,
  // [Types.CONNECTION_USER_DISABLE_REQUEST]: connectionUserRequest,
  [Types.CONNECTION_QRS_REQUEST]: connectionQrsRequest,
  [Types.CONNECTION_AUTHORIZED_PHONE_SAVE_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_AUTHORIZED_PHONE_DELETE_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_DOOR_PHONE_STATUS_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_DOOR_PHONE_INFORMATION_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_DOOR_PHONE_COUNTRIES_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_DOOR_PHONE_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_DOOR_PHONE_DELETE_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_DOOR_PHONE_ANY_CALLER_STATUS_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_DOOR_PHONE_CLAIM_OWNERSHIP_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_AUTO_ACCESS_CONFIGURATION_SAVE_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_AUTHORIZED_CODE_SAVE_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_AUTHORIZED_CODE_DELETE_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_DOOR_CODE_STATUS_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_DOOR_CODE_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_DOOR_CODE_DELETE_REQUEST]: connectionFormRequest,
  [Types.CONNECTION_DOOR_CODE_ANY_CALLER_STATUS_REQUEST]: connectionFormRequest,
  // [Types.CONNECTION_DEVICE_CONFIGURATION_WIZARD_SAVE_REQUEST]: connectionDeviceConfigurationWizardSaveRequest,
  // [Types.CONNECTION_DELETE_REQUEST]: connectionDeleteRequest,
  // [Types.CONNECTION_GENERAL_FORM_SAVE_REQUEST]: connectionGeneralFormSaveRequest,
  // [Types.CONNECTION_DEVICE_UPDATE_LED_BRIGHTNESS_REQUEST]: connectionDeviceUpdateLedBrightnessRequest,
  // [Types.CONNECTION_DEVICE_UPDATE_ENERGY_SAVE_MODE_REQUEST]: connectionDeviceUpdateEnergySaveModeRequest,
  // [Types.CONNECTION_DEVICE_VARIABLE_FORM_REQUEST]: connectionDeviceVariableFormRequest,
  // [Types.CONNECTION_SENSOR_SAVE_REQUEST]: connectionSensorRequest,
  // [Types.CONNECTION_SENSOR_DELETE_REQUEST]: connectionSensorRequest,
  // [Types.CONNECTION_DOOR_SAVE_REQUEST]: connectionDoorRequest,
  // [Types.CONNECTION_DOOR_DELETE_REQUEST]: connectionDoorRequest,
  // [Types.CONNECTION_AUTO_CALIBRATION_REQUEST]: connectionAutoCalibrationRequest,
  // [Types.CONNECTION_AUTO_CALIBRATION_FINISH]: connectionAutoCalibrationFinish,
  [Types.CONNECTION_SAVE_LOCATION_REQUEST]: connectionLocationRequest,
  [Types.CONNECTION_DELETE_LOCATION_REQUEST]: connectionLocationRequest,
  [Types.CONNECTION_SAVE_BOOKING_REQUEST]: connectionLocationRequest,
  [Types.CONNECTION_DELETE_BOOKING_REQUEST]: connectionLocationRequest,
  [Types.CONNECTION_GET_BOOKINGS_REQUEST]: connectionLocationRequest,
  [Types.CONNECTION_GET_GUEST_BOOK]: connectionLocationRequest,
  [Types.CONNECTION_SEND_GUEST_BOOK]: connectionLocationRequest,
  // [Types.CONNECTION_PAIR_LOCATION_REQUEST]: connectionLocationRequest,
  [Types.CONNECTION_NUKI_TASK_REQUEST]: connectionNukiTaskRequest,
  // [Types.CONNECTION_SERVO_CALIBRATE_REQUEST]: connectionServoCalibrateRequest,
  [Types.CONNECTION_INTERNET_TASK_REQUEST]: connectionInternetTaskRequest,
  [Types.CONNECTION_INTERNET_AND_BLE_TASK_REQUEST]: connectionInternetAndBleTaskRequest,
})

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, SvgIcon } from '@material-ui/core'
import BellPath from '../../../Images/Icons/bell'
import PhoneIcon from '@material-ui/icons/Phone'
import ManPath from '../../../Images/Icons/man'
import LinkPath from '../../../Images/Icons/link'
import RestoreIcon from '@material-ui/icons/Restore'
import { Colors, Metrics } from '../../../Themes'
import styles from '../../Styles/UI/Button'
// import menuStyles from '../../Styles/UI/Menu'

export default class TabbarButton extends Component {

  static propTypes = {
    active: PropTypes.bool.isRequired, 
    onPress: PropTypes.func.isRequired,
    text: PropTypes.string,
    icon: PropTypes.oneOf(
      [
        'bell', 'phone', 'man', 'back-in-time', 'link'
      ]
    ).isRequired,
    buttonStyle: PropTypes.object,
    customButtonStyle: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]),
    customTextStyle: PropTypes.object,
    customIconStyle: PropTypes.object
  }

  static defaultProps = {
    active: false,
    onPress: null,
    text: null,
    icon: null,
    buttonStyle: { margin: 0, padding: 0, borderRadius: 0 },
    customButtonStyle: {},
    customTextStyle: {},
    customIconStyle: {}
  }

  render = () => {
    const buttonStyle = Object.assign(
      {}, 
      this.props.buttonStyle, 
      this.props.active
        ? { borderBottom: Metrics.menuBorderWidth + ' solid ' + Colors.theme1 }
        : { borderBottom: Metrics.menuBorderWidth + ' solid ' + Colors.transparent },
      this.props.customButtonStyle,
      this.props.customTextStyle,
    )
    const iconStyle = Object.assign(
      {}, 
      styles.buttonIcon, 
      { 
        marginRight: 0,
        color: this.props.active ? Colors.theme1 : Colors.mediumGray
      }, 
      this.props.customIconStyle
    )
    const svgIconStyle = Object.assign(
      {}, 
      iconStyle, 
      { position: 'relative', top: Metrics.svgIconPositionTop, left: Metrics.svgIconPositionLeft }
    )
    return (
      <Button
        onClick={this.props.onPress}
        disabled={false}
        style={buttonStyle}
        color={this.props.active ? 'primary' : 'default'}
      >
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          { this.props.icon === 'bell' && <SvgIcon style={svgIconStyle}>{BellPath}</SvgIcon> }
          { this.props.icon === 'phone' && <PhoneIcon style={iconStyle} /> }
          { this.props.icon === 'man' && <SvgIcon style={svgIconStyle}>{ManPath}</SvgIcon> }
          { this.props.icon === 'back-in-time' && <RestoreIcon style={iconStyle} /> }
          { this.props.icon === 'link' && <SvgIcon style={svgIconStyle}>{LinkPath}</SvgIcon> }
          <div>
            { this.props.text }
          </div>
        </div>
      </Button>
    )
  }

}

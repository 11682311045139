import { call, put } from 'redux-saga/effects'
import { is } from 'ramda'
import RaixerConfig from '../Config/RaixerConfig'
import I18n from '../i18n/i18n'
import StartupActions from '../Redux/StartupRedux'
import AuthActions from '../Redux/AuthRedux'
import ConnectionsActions from '../Redux/ConnectionsRedux'
import ConfigurationActions from '../Redux/ConfigurationRedux'
import UserActions from '../Redux/UserRedux'
import { setActiveLang } from './I18nSagas'
import { AlertService } from '../Services/Alert'
import { isDefined, isStringJSONValid } from '../Lib/ObjectsUtils'
import { delay } from '../Lib/DateTimeUtils'
import { navigate } from '../Navigation/RootNavigation'

export function * startup (authApi, mainApi, action) {
  yield call(setActiveLang)
  const userId = yield call([localStorage, 'getItem'], '@RaixerLocalStorage:userId')
  const token = yield call([localStorage, 'getItem'], '@RaixerLocalStorage:token')
  const refreshToken = yield call([localStorage, 'getItem'], '@RaixerLocalStorage:refreshToken')
  const connectionsString = yield call([localStorage, 'getItem'], '@RaixerLocalStorage:connections')
  if (is(String, userId) && is(String, token) && is(String, refreshToken)) {
    const validToken = yield call(validateTokens, authApi, userId, token, refreshToken)
    if (validToken) {
      let storageError = false
      const { userId, token, refreshToken, refreshedToken } = validToken 
      if (refreshedToken) {
        try {
          yield call([localStorage, 'setItem'], '@RaixerLocalStorage:userId', userId)
          yield call([localStorage, 'setItem'], '@RaixerLocalStorage:token', token)
          yield call([localStorage, 'setItem'], '@RaixerLocalStorage:refreshToken', refreshToken)
        } catch (err) {
          storageError = true
          yield call(startupGoToLogin, I18n.t('snacks.errors.asyncStorage'))
        }
      }
      if (!storageError) {
        yield call(startupGoToDashboard, mainApi, userId, token, refreshToken, connectionsString)
      }
    } else {
      yield call(startupGoToLogin, I18n.t('snacks.errors.sessionExpired'))
    }
  } else {
    yield call(startupGoToLogin)
  }
  yield call(delay, RaixerConfig.delays.startup)
  yield put(StartupActions.startupFinished())
  const tokenAfterVerification = yield call([localStorage, 'getItem'], '@RaixerLocalStorage:token')
  if (tokenAfterVerification) { navigate('dashboard') }
  else { navigate('') }
}

function * validateTokens (api, userId, token, refreshToken) {
  yield call(api.setTokenHeader, token)
  const responseValidate = yield call(api.validateToken, userId)
  if (responseValidate.ok) {    
    return { userId, token, refreshToken }
  } else {
    // Token is not valid, try to refresh it
    yield call(api.removeTokenHeader)
    yield call(api.setRefreshTokenHeader, refreshToken)
    const responseRefresh = yield call(api.refreshToken, userId)
    yield call(api.removeRefreshTokenHeader)
    if (responseRefresh.ok) {
      // Refresh token was valid
      const { data } = responseRefresh
      const { _id, token, refreshToken } = data
      return { userId: _id, token, refreshToken, refreshedToken: true }
    } else {
      // Refresh token was not valid
      return null
    }
  }
}

function * getIntegrationsConfiguration (api) {
  const response = yield call(api.getConfiguration)
  const { ok, data } = response
  if (ok) {
    for (let d of data) { yield put(ConfigurationActions.configurationFinish(d, null)) }
    return true
  } else {
    return false
  }
}

function * getUser (api, userId) {
  const response = yield call(api.getUser, userId)
  const { ok, data: user } = response
  if (ok) {
    yield put(UserActions.userRequestFinished(user, null))
    return true
  } else {
    return false
  }
}

function * startupGoToLogin (error) {
  if (error) { 
    AlertService.error(error)
    try {
      yield call([localStorage, 'removeItem'], '@RaixerLocalStorage:userId')
      yield call([localStorage, 'removeItem'], '@RaixerLocalStorage:token')
      yield call([localStorage, 'removeItem'], '@RaixerLocalStorage:refreshToken')
      yield call([localStorage, 'removeItem'], '@RaixerLocalStorage:connections')
    } catch (err) {
      // Do nothing, it does not matter at this point
    }
  }
  yield call(setActiveLang)
}

function * startupGoToDashboard (api, userId, token, refreshToken, connectionsString) {
  yield call(api.setTokenHeader, token)
  const readedConfiguration = yield call(getIntegrationsConfiguration, api)
  const readedUser = yield call(getUser, api, userId)
  if (readedConfiguration) {
    if (readedUser) {
      if (isDefined(connectionsString)) {
        if (isStringJSONValid(connectionsString)) {
          // Restore connections from local storage
          const connections = JSON.parse(connectionsString)
          if (connections.length > 0) { yield put(ConnectionsActions.connectionsSuccess(connections, true)) }
        }
      }
      yield put(AuthActions.loginSuccess(userId, token, refreshToken))
      yield call(setActiveLang)
    } else {
      yield call(setActiveLang)
      yield call(startupGoToLogin, I18n.t('snacks.errors.startupConfiguration'))
    }
  } else {
    yield call(setActiveLang)
    yield call(startupGoToLogin, I18n.t('snacks.errors.startupConfiguration'))
  }
}

import RaixerConfig from '../Config/RaixerConfig'
import { Colors } from '../Themes'
import I18n from '../i18n/i18n'

const doorsMsgs = [
  'phoneCall',
  'openDoor',
  'startJob',
  'stopJob',
  'deleteJob',
  'doorOpened',
  'doorIsOpen',
  'doorIsClosed',
  'accessByCode',
  'bellRing'
]

const { connections: RaixerConfigConnections } = RaixerConfig
const { portPositions } = RaixerConfigConnections

export const getBorderColor = (action, error) => {
  if (error) { return Colors.history.error }
  if (action.indexOf('phoneCall') > -1) { return Colors.history.callText }
  else if (action.indexOf('openDoor') > -1 || action.indexOf('doorOpened') > -1 || action.indexOf('accessByCode') > -1) { return Colors.history.openDoorText }
  else if (action.indexOf('startJob') > -1) { return Colors.history.startJobText }
  else if (action.indexOf('stopJob') > -1 || action.indexOf('deleteJob') > -1) { return Colors.history.stopJobText }
  else if (action.indexOf('doorIsOpen') > -1) { return Colors.history.doorIsOpenText }
  else if (action.indexOf('doorIsClosed') > -1) { return Colors.history.doorIsClosedText }
  else if (action.indexOf('deviceOffline') > -1) { return Colors.history.deviceOfflineText }
  else if (action.indexOf('deviceOnline') > -1) { return Colors.history.deviceOnlineText }
  else if (action.indexOf('movementDetected') > -1) { return Colors.history.movementDetected }
  else if (action.indexOf('physicalButton') > -1) { return Colors.history.physicalButton }
  else if (action.indexOf('powerIn') > -1) { return Colors.history.power }
  else if (action.indexOf('powerOut') > -1) { return Colors.history.power }
  else if (action.indexOf('offlinePiston') > -1) { return Colors.history.offlinePiston }
  else if (action.indexOf('sleep') > -1 || action.indexOf('wokeup') > -1) { return Colors.history.sleep }
  else if (action.indexOf('energySaveActivated') > -1 || action.indexOf('energySaveDeactivated') > -1) { return Colors.history.sleep }
  else if (action.indexOf('nuki') > -1) { return Colors.history.nuki }
  else if (action.indexOf('bellRing') > -1) { return Colors.history.bellRing }
  else if (action.indexOf('gyro') > -1) { return Colors.history.nuki }
  return Colors.mediumGray
}

export const getNamesAndActionMsg = (action, error, connection, lang) => {
  const { device } = connection
  const { doors, sensors } = device
  let message = I18n.t(getActionTranslationKey(action, error))
  if (message.indexOf('missing') !== -1) { return { message: action } }
  if (action.indexOf('gyro') > -1) {
    if (doors.length > 1) {
      const door = doors.filter(d => d.sensorMode === 'gyro')[0]
      const { name } = door || {}
      if (name) { return { message: message.replace(/\[doorName\]/gi, ' ' + name.toLowerCase() + ' ') } }
      else { return { message: message.replace(/\[doorName\]/gi, ' ') } }
    } else {
      return { message: message.replace(/\[doorName\]/gi, ' ') }
    }
  } else {
    const lastPartAction = action.substring(action.lastIndexOf('.') + 1)
    let name = null
    if (actionInDoorsMsgs(lastPartAction)) {
      if (doors.length > 1) {
        const port = getPortOfMessage(message)
        if (port) {
          name = getDoorNameOfPort(port, doors, lang)
          if (name) {
            message = removePartBeforeHyphen(message)
          }
        }
      } else {
        message = removePartBeforeHyphen(message)
      }
    } else if (action.indexOf('soundDetected') > -1) {
      if (sensors && sensors.length > 0) {
        name = getSensorName(sensors, 'noise')
        if (name) { message = removePartBeforeHyphen(message) }
      }
    }
    return { message, name }
  }
}

export const getActionOfBleTask = (bleTask) => {
  const lastDashIndex = bleTask.lastIndexOf('-')
  const bleAction = bleTask.substring(lastDashIndex + 1)
  return bleAction.charAt(0).toUpperCase() + bleAction.slice(1)
}

const getActionTranslationKey = (action, error) => {
  action = action.replace('connections.detail.history.action.', 'screens.connection.history.')
  return error ? action + '.error' : action + '.success'
}

const actionInDoorsMsgs = (action) => {
  let result = false
  for (let msg of doorsMsgs) {
    if (action.indexOf(msg) > -1) { 
      result = true 
      break
    }
  }
  return result
}

const getPortOfMessage = (message) => {
  const replacePart = getPartBeforeHyphen(message)
  if (replacePart && replacePart.length > 0) {
    const splitted = replacePart.split(' ')
    if (splitted && splitted.length > 1) { return parseInt(splitted[1]) }
  }
  return null
}

const getPartBeforeHyphen = (msg) => {
  if (msg.indexOf('-') > -1) { return msg.substring(0, msg.indexOf('-')).trim() }
  return msg
}

const removePartBeforeHyphen = (msg) => {
  return msg.substring(msg.indexOf('-') + 1).trim()
}

const getDoorNameOfPort = (port, doors, lang) => {
  try {
    const door = getDoorOfPort(doors, port)
    const { name } = door
    if (name) {
      if (lang === 'es') { return I18n.t('shared.door') + ' ' + name } 
      else { return name + ' ' + I18n.t('shared.door').toLowerCase() }
    }
    return null
  } catch {
    return null
  }
}

const getDoorOfPort = (doors, searchPort) => {
  let door
  for (let d of doors) {
    const { port } = d
    if (port) {
      if (portPositions[port] === searchPort) { 
        door = d 
        break
      }
    }
  }
  return door
}

const getSensorName = (sensors, use) => {
  try {
    const sensor = getSensorOfUse(sensors, use)
    const { name } = sensor
    return name
  } catch {
    return null
  }
}

const getSensorOfUse = (sensors, searchUse) => {
  let sensor
  for (let s of sensors) {
    const { use } = s
    if (use === searchUse) {
      sensor = s
      break
    }
  }
  return sensor
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form'
import { Button } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'

import I18n from '../../../../i18n/i18n'
import ConnectionTabTitle from '../../../UI/Connections/ConnectionTabTitle'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import ListCard from '../../../UI/ContentCards/ListCard'
import ConnectionsStatus from '../../../UI/Connections/ConnectionsStatus'
import { Colors, Fonts, Metrics } from '../../../../Themes'
import styles from '../../../Styles/Screens/Connection'
import stylesConnections from '../../../Styles/Screens/Connections'
import stylesPhones from '../../../Styles/UI/Connections/Phones'
import stylesButton from '../../../Styles/UI/Button'
import AppText from '../../../UI/Texts/AppText'
import SimpleIconButton from '../../../UI/Buttons/SimpleIconButton'
import BaseForm from '../../../UI/Forms/BaseForm'
import AnimatedComponent from '../../../UI/Animated/AnimatedComponent'

export default class Users extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired,
    isUserAdmin: PropTypes.bool.isRequired,
    trialEnded: PropTypes.bool.isRequired,
    goToUserForm: PropTypes.func.isRequired
  }

  static defaultProps = {
    connection: null,
    isUserAdmin: PropTypes.bool.isRequired,
    trialEnded: PropTypes.bool.isRequired,
    goToUserForm: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      pageSize: 10,
      totalPages: 0,
      users: [],
      filteredUsers: [],
      withEditButton: false,
      searchFormFocused: false,
      value: {
        name: ''
      }
    }
  }

  componentDidMount = () => {
    const { connection } = this.props
    const { users, withEditButton } = this.getRequiredFields(connection.users)
    let newState = { withEditButton }
    if (users.length > 0) { newState = { ...newState, users, filteredUsers: users, totalPages: Math.ceil(users.length / this.state.pageSize)  } }
    this.setState(newState)
  }

  _getUser = (user, isOwner, isMe) => {
    const { 
      _id, disabled, owner, ownerEmail: email, ownerName: name, profile,
      deleteOnEnd, authorizedFrom, authorizedTo, repeatDaysOfWeek, repeatStartHour, 
      repeatEndHour, repeatTimezone, enabledForUse = true
    } = user
    let profileText = profile.charAt(0).toUpperCase() + profile.slice(1)
    let status = I18n.t('shared.enabled')
    if (isOwner) { profileText = I18n.t('shared.owner') }
    if (disabled) { status = I18n.t('shared.disabled') }
    return { 
      _id, 
      owner,
      name: isMe ? I18n.t('shared.me') : name, 
      profile: profileText, 
      email, 
      status,
      active: disabled ? false : true,
      withEditButton: !isOwner && !isMe ? true : false,
      deleteOnEnd,
      authorizedFrom,
      authorizedTo,
      repeatDaysOfWeek,
      repeatStartHour,
      repeatEndHour,
      repeatTimezone,
      enabledForUse
    }
  }

  getRequiredFields = (connectionUsers) => {
    let users = []
    let withEditButton = false
    try {
      const owner = connectionUsers
        .filter(u => this.props.connection.device.owner === u.owner)
        .map(u => this._getUser(u, true, this.props.connection.owner === u.owner))
      const me = connectionUsers
        .filter(u => this.props.connection.owner === u.owner && this.props.connection.device.owner !== u.owner)
        .map(u => this._getUser(u, false, true))
      const filteredUsers = connectionUsers
        .filter(u => this.props.connection.device.owner !== u.owner && this.props.connection.owner !== u.owner)
        .map(u => this._getUser(u, false, false))
      users = owner.concat(me)
      if (this.props.isUserAdmin && filteredUsers.length > 0) {
        withEditButton = true
        users = users.concat(filteredUsers) 
      }
    } catch {
      users = []
      withEditButton = false
    }
    return { users, withEditButton }
  }

  changePage = (increment) => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage + increment,
    }))
  }

  onChangeSearchForm = (value) => {
    const { name = '' } = value || {}
    const { users = [] } = this.state || {}
    const filteredUsers = this.getFilteredUsersByNameOrEmail(users, name)
    const orderedUsersByName = this.sortArrayByName(filteredUsers)

    this.setState({ filteredUsers: orderedUsersByName, value, currentPage: 1, totalPages: Math.ceil(orderedUsersByName.length / this.state.pageSize) })
  }

  getFilteredUsersByNameOrEmail = (users, search) => {
    try {
      if (search !== '') {
        let filteredUsers = []
        for (let i = 0; i < users.length; i++) {
          const user = users[i]
          const { email, name } = user
          if (name.toLowerCase().indexOf(search.toLowerCase()) > -1 || email.toLowerCase().indexOf(search.toLowerCase()) > -1) {
            filteredUsers.push(user)
          }
        }
        return filteredUsers
      } else {
        return users
      }
    } catch {
      return users
    }
  }

  sortArrayByName = (arr = []) => {
    const items = [...arr]

    return items.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
  }
  
  renderSearchForm = () => {
    const { value = {}, filteredUsers } = this.state || {}
    const { name: filter = '' } = value
    const rightAccessory = filter !== '' ? (
      <SimpleIconButton
        onPress={() => { 
          this.setState({ value: { name: '' }, filteredUsers: this.state.users, currentPage: 1, totalPages: Math.ceil(this.state.users.length / this.state.pageSize) }) 
        }}
        icon={'clear'}
        customButtonStyle={{ padding: 0 }}
        customIconStyle={{ color: Colors.mediumGray }}
      />
    ) : (
      <SearchIcon style={stylesConnections.searchFormIcon} />
    )
    return (
      <div>
        <BaseForm
          name={'usersSearchForm'}
          onSubmit={() => {}}
          fields={{ name: t.String }}
          fieldsOptions={{
            name: {
              label: I18n.t('screens.connections.search'),
              autoCapitalize: 'none',
              customTemplate: true,
              customTemplateRenderRightAccessory: () => (
                <div style={stylesConnections.formRightAccessory}>
                  { rightAccessory }
                </div>
              ),
              written: filter !== '' // Hack for forcing the update when changing the right accesory
            }
          }}
          onChange={this.onChangeSearchForm}
          value={this.state.value}
          withoutSubmit={true}
          debounced={true}
        />

        {
          filteredUsers.length === 0 && filter !== '' &&
          <div>
            <AnimatedComponent source={'searchEmpty'} width={150} height={150} autoPlay={false} loop={false} progress={0.2} />
            <AppText text={I18n.t('screens.connection.users.emptySearch')} customStyle={stylesConnections.emptySearchText} />
          </div>
        }
      </div>
    )
  }
  
  getContent = (users, withEditButton) => {
    const { value } = this.state
    const { name = '' } = value || {}
    
    if (users.length === 0) { 
      return (
        <ContentCard
          title={I18n.t('shared.userList')}
          renderContent={() => (
            <ConnectionsStatus
              text={I18n.t('screens.connection.unexpectedError')} 
              icon={'warning'}
               withoutMargin={true}
            />
          )}
        />
      ) 
    }
    const filteredUsers = this.state.filteredUsers
    const startIndex = (this.state.currentPage - 1) * this.state.pageSize
    const paginatedUsers = filteredUsers.slice(startIndex, startIndex + this.state.pageSize)
    const showPagination = filteredUsers.length > this.state.pageSize

    const customStyle = {
      border: 0,
      borderRadius: 0,
      margin: 0,
      marginBottom: 0,
      fontWeight: Fonts.weight.regular,
      padding: '8px',
      height: '30px',
      width: '30px',
      minHeight: '30px',
      minWidth: '30px',
    }
    let style = Object.assign({}, stylesButton.buttonContainer, customStyle)

    return (
      <ListCard
        title={I18n.t('shared.userList')}
        list={paginatedUsers}
        listItemType={'users'}
        withEditButton={withEditButton}
        addButton={this.props.isUserAdmin && name === ''}
        addButtonText={I18n.t('screens.connection.usersAddButton')}
        onAdd={() => { this.props.goToUserForm() }}
        onEditItem={(item) => { this.props.goToUserForm(item) }}
        renderExtraContent={() => (
          <>
            { users.length > 5 && this.renderSearchForm() }

            { showPagination &&
              <div style={stylesPhones.paginationContainer}>
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  disabled={this.state.currentPage === 1}
                  startIcon={null}
                  endIcon={<ArrowBackIos />}
                  style={style}
                  onClick={() => this.changePage(-1)}
                />
                <AppText text={`${this.state.currentPage} ${I18n.t('screens.connection.users.of')} ${this.state.totalPages}`} />
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  disabled={this.state.currentPage >= this.state.totalPages}
                  startIcon={null}
                  endIcon={<ArrowForwardIos />}
                  style={style}
                  onClick={() => this.changePage(1)}
                />
              </div>
            }
          </>
        )}
      />
    )
  }

  render = () => {
    const { device = {}, users: connectionUsers = [] } = this.props.connection || {}
    const { guestyListingId } = device
    const { users, withEditButton } = this.getRequiredFields(connectionUsers)
    return (
      <div style={styles.mainBodyScroll}>
        <ConnectionTabTitle 
          title={I18n.t('shared.users')} 
          integrationImage={guestyListingId ? 'guesty' : null} 
          onboardingImage={'users'}
        />
        <div style={{ marginTop: Metrics.baseMargin }}>
          { this.getContent(users, withEditButton) }
        </div>
      </div>
    )
  }

}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getActualConnection } from '../../../Lib/ConnectionsUtils'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import I18n from '../../../i18n/i18n'
import BookingForm from '../../../Components/Screens/CheckIn/Forms/BookingForm'
import { ModalService } from '../../../Services/Modal'

class BookingFormScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      booking: null
    }
  }

  componentDidMount = () => {
    const { connectionId, booking } = this.props
    this.setState({ connectionId, booking })
  }

  saveBooking = (booking) => {
    this.props.saveBooking(this.state.connectionId, booking)
  }

  deleteBooking = (booking) => {
    ModalService.show(
      I18n.t('modals.deleteBooking.title'), 
      I18n.t('modals.deleteBooking.text'), 
      () => { this.props.deleteBooking(this.state.connectionId, booking) }, 
      I18n.t('modals.deleteBooking.buttons.ok'),
      null,
      true
    )
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    return (
      <BookingForm
        activeLang={this.props.activeLang}
        connection={connection}
        booking={this.state.booking}
        fetching={this.props.fetching}
        onSubmit={this.saveBooking}
        onDelete={this.deleteBooking}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    activeLang: state.i18n.activeLang,
    fetching: state.connections.fetchingConnectionForm
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveBooking: (connectionId, booking) => dispatch(ConnectionsActions.connectionSaveBookingRequest(connectionId, booking)),
    deleteBooking: (connectionId, booking) => dispatch(ConnectionsActions.connectionDeleteBookingRequest(connectionId, booking)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingFormScreen)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { connectionHasMultipleAutoAccessDoors, connectionIsWifiDevice } from '../../../Lib/ConnectionsUtils'
import I18n from '../../../i18n/i18n'
import AutoAccess from '../../../Components/Screens/Connection/Tabs/AutoAccess'
import { ModalService } from '../../../Services/Modal'
import { FormModalService } from '../../../Services/FormModal'

class AutoAccessTab extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired
  }

  static defaultProps = {
    connection: null
  }

  goToAutoAccessConfiguration = () => {
    const { connection } = this.props
    const { _id } = connection
    FormModalService.open('auto-access', { connectionId: _id })
  }

  goToScheduleForm = (schedule) => {
    const { connection } = this.props
    const { _id } = connection
    FormModalService.open('schedule', { connectionId: _id, schedule })
  }

  showModalUpgrade = () => {
    const { connection } = this.props
    const { _id, device = {}, owner: connectionOwner } = connection
    const { owner: deviceOwner } = device
    if (deviceOwner === connectionOwner) {
      const isWifiDevice = connectionIsWifiDevice(connection)
      const plan = { type: isWifiDevice ? 'intercom' : 'access' }
      ModalService.show(
        I18n.t('modals.upgradeFeatureOwner.title'), 
        I18n.t('modals.upgradeFeatureOwner.text'),
        () => {
          FormModalService.open('upgrade', { connectionId: _id, plan })
        },
        I18n.t('modals.upgradeFeatureOwner.buttons.ok')
      )
    } else {
      ModalService.show(
        I18n.t('modals.upgradeNotOwner.title'), 
        I18n.t('modals.upgradeNotOwner.text')
      )
    }
  }

  render = () => {
    const { connection } = this.props
    const hasMultipleAutoAccessDoors = connectionHasMultipleAutoAccessDoors(connection)
    return (
      <AutoAccess
        connection={connection}
        goToAutoAccessConfiguration={this.goToAutoAccessConfiguration}
        goToScheduleForm={this.goToScheduleForm}
        showModalUpgrade={this.showModalUpgrade}
        activeLang={this.props.activeLang}
        hasMultipleAutoAccessDoors={hasMultipleAutoAccessDoors}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    activeLang: state.i18n.activeLang
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoAccessTab)

class Alert {

  constructor() {
    this.snackbar = null
  }

  setTopLevelSnackbar = (alert) => { 
    if (alert) {
      this.snackbar = alert
    }
  }
  
  success = (msg, action) => {
    this.snackbar.show(msg, true, action)
  }

  error = (msg, action) => {
    this.snackbar.show(msg, false, action)
  }

}

export const AlertService = new Alert()
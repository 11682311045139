import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RaixerConfig from '../../../Config/RaixerConfig'
import I18n from '../../../i18n/i18n'
import ContentCard from './ContentCard'
import MainButton from '../Buttons/MainButton'
import GhostButton from '../Buttons/GhostButton'
import SimpleListItem from '../Lists/SimpleListItem'
import LocationListItem from '../Lists/LocationListItem'
import BookingListItem from '../Lists/BookingListItem'
import UserListItem from '../Lists/UserListItem'
import DatesListItem from '../Lists/DatesListItem'
import RepeatListItem from '../Lists/RepeatListItem'
import { Colors, Metrics, Fonts } from '../../../Themes'

export default class ListCard extends Component {

  static propTypes = {
    list: PropTypes.array.isRequired,
    listItemType: PropTypes.string.isRequired,
    withEditButton: PropTypes.bool.isRequired,
    addButton: PropTypes.bool.isRequired,
    addButtonText: PropTypes.string,
    addButtonIcon: PropTypes.string,
    addButtonDisable: PropTypes.bool,
    customOnPressEdit: PropTypes.func,
    onAdd: PropTypes.func,
    onEditItem: PropTypes.func,
    title: PropTypes.string,
    customDateFromText: PropTypes.string,
    customDateToText: PropTypes.string,
    renderExtraContent: PropTypes.func,
    publishListItemEditFlexValue: PropTypes.func,
    editing: PropTypes.bool,
    customEditIcon: PropTypes.string,
    customEditText: PropTypes.string,
    addButtonAsGhost: PropTypes.bool,
    renderExtraContentBeforeButtons: PropTypes.func,
    renderExtraContentAfterButtons: PropTypes.func,
    onListItemMain: PropTypes.func,
    onListItemSecondary: PropTypes.func,
    onListItemIcon: PropTypes.func
  }

  static defaultProps = {
    list: null,
    listItemType: null,
    withEditButton: null,
    addButton: null,
    addButtonText: null,
    addButtonIcon: 'add-circle',
    addButtonDisable: false,
    customOnPressEdit: null,
    onAdd: null,
    onEditItem: null,
    title: null,
    customDateFromText: null,
    customDateToText: null,
    renderExtraContent: null,
    publishListItemEditFlexValue: null,
    editing: false,
    customEditIcon: null,
    customEditText: null,
    addButtonAsGhost: false,
    renderExtraContentBeforeButtons: null,
    renderExtraContentAfterButtons: null,
    onListItemMain: null,
    onListItemSecondary: null,
    onListItemIcon: null
  }

  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      listItemEditFlex: 0
    }
  }

  componentDidUpdate = (prevProps) => {
    const { editing: prevEditing } = prevProps
    const { editing } = this.props
    const { editing: stateEditing } = this.state
    if (!editing && prevEditing && stateEditing) { this.toggleEdit() }
  }

  toggleEdit = () => {
    const editing = !this.state.editing
    const toValue = editing ? RaixerConfig.animations.lists.editButtonFlex : 0
    this.setState({ editing, listItemEditFlex: toValue })
    if (this.props.publishListItemEditFlexValue) { this.props.publishListItemEditFlexValue(toValue) }
  }

  onAdd = () => {
    if (this.state.editing) { setTimeout(() => { this.toggleEdit() }, RaixerConfig.delays.toggleEdit) }
    this.props.onAdd()
  }

  onEditItem = (item) => {
    if (this.state.editing) { 
      const list = this.props.list || []
      const listLength = list.length
      if (listLength <= RaixerConfig.edits.toggleMaxListLength) { setTimeout(() => { this.toggleEdit() }, RaixerConfig.delays.toggleEdit)  }
    }
    if (this.props.onEditItem) {
      this.props.onEditItem(item)
    }
  }

  renderContent = () => {
    if ((!this.props.list || this.props.list.length === 0) && !this.props.renderExtraContent) { return null }
    if ((!this.props.list || this.props.list.length === 0) && this.props.renderExtraContent) { return this.props.renderExtraContent() }
    if (this.props.renderExtraContent) {
      return (
        <>
          { this.props.renderExtraContent() }
          { this.props.list.map((item, i) => this.renderItem(item, i)) }
        </>
      )
    }
    return this.props.list.map((item, i) => this.renderItem(item, i))
  }

  renderSimpleItem = (item, index) => {
    return (
      <SimpleListItem 
        key={'lis-' + index}
        leftText={item.left}
        rightText={item.right}
        withEditButton={this.props.withEditButton}
        lastOfList={(index + 1) === this.props.list.length}
        editButtonFlex={this.state.listItemEditFlex}
        onPressEdit={() => { this.onEditItem(item) }}
        customRightStyle={item.importantRight ? { flex: 2 } : {}}
      />
    )
  }

  renderUserItem = (item, index) => {
    return (
      <UserListItem
        key={'liu-' + index}
        name={item.name}
        profile={item.profile}
        email={item.email}
        status={item.status}
        active={item.active}
        enabledForUse={item.enabledForUse}
        authorizedFrom={item.authorizedFrom}
        authorizedTo={item.authorizedTo}
        repeatDaysOfWeek={item.repeatDaysOfWeek}
        repeatStartHour={item.repeatStartHour}
        repeatEndHour={item.repeatEndHour}
        withEditButton={item.withEditButton}
        lastOfList={(index + 1) === this.props.list.length}
        editButtonFlex={this.state.listItemEditFlex}
        onPressEdit={() => { this.onEditItem(item) }}
      />
    )
  }

  renderDatesItem = (item, index) => {
    let customAdditionalLeftTextStyle = {}
    if (item.createdBy === 'Guesty') {
      customAdditionalLeftTextStyle = { color: Colors.integrations.guesty, fontFamily: Fonts.type.bold, /* fontWeight: Fonts.weight.bold */ }
      if (!item.left) { customAdditionalLeftTextStyle.marginBottom = 0 }
    } else if(item.createdBy === 'Raixer check-in') {
      customAdditionalLeftTextStyle = { color: Colors.integrations.raixer, fontFamily: Fonts.type.bold, /* fontWeight: Fonts.weight.bold */ }
    }
    return (
      <DatesListItem
        key={'lid-' + index}
        leftText={item.left}
        additionalLeftText={item.createdBy}
        rightText={item.right}
        dateFrom={item.dateFrom}
        dateTo={item.dateTo}
        withEditButton={this.props.withEditButton}
        lastOfList={(index + 1) === this.props.list.length}
        editButtonFlex={this.state.listItemEditFlex}
        onPressEdit={() => { this.onEditItem(item) }}
        customDateFromText={this.props.customDateFromText}
        customDateToText={this.props.customDateToText}
        customLeftTextStyle={{ color: Colors.mediumGray, fontWeight: Fonts.weight.regular }}
        customAdditionalLeftTextStyle={customAdditionalLeftTextStyle}
      />
    )
  }

  renderPhoneItem = (item, index) => {
    const { enabledForUse = true } = item || {}
    let rightTextStyle = { color: Colors.success, opacity: 0.8 }
    let customAdditionalLeftTextStyle = {}
    if (!item.authorized) { rightTextStyle = { color: Colors.error, opacity: 0.8 } }
    if (item.createdBy === 'Guesty') {
      customAdditionalLeftTextStyle = { color: Colors.integrations.guesty, fontFamily: Fonts.type.bold, /* fontWeight: Fonts.weight.bold */ }
    } else if(item.createdBy === 'Raixer check-in') {
      customAdditionalLeftTextStyle = { color: Colors.integrations.raixer, fontFamily: Fonts.type.bold, /* fontWeight: Fonts.weight.bold */ }
    }
    if (item.type === 'simple') {
      return (
        <SimpleListItem 
          key={'lis-' + index}
          leftText={item.left}
          additionalLeftText={item.createdBy}
          rightText={item.right}
          withEditButton={this.props.withEditButton}
          lastOfList={(index + 1) === this.props.list.length}
          editButtonFlex={this.state.listItemEditFlex}
          onPressEdit={() => { this.onEditItem(item) }}
          customLeftStyle={{ flex: 2 }}
          customLeftTextStyle={{ color: Colors.mediumGray, fontWeight: Fonts.weight.regular }}
          customRightTextStyle={rightTextStyle}
          customAdditionalLeftTextStyle={customAdditionalLeftTextStyle}
          onListItemIcon={enabledForUse && this.props.onListItemIcon ? () => this.props.onListItemIcon(item) : null}
        />
      )
    } else if (item.type === 'dates') {
      return (
        <DatesListItem
          key={'lid-' + index}
          leftText={item.left}
          additionalLeftText={item.createdBy}
          rightText={item.right}
          dateFrom={item.dateFrom}
          dateTo={item.dateTo}
          withEditButton={this.props.withEditButton}
          lastOfList={(index + 1) === this.props.list.length}
          editButtonFlex={this.state.listItemEditFlex}
          onPressEdit={() => { this.onEditItem(item) }}
          customDateFromText={this.props.customDateFromText}
          customDateToText={this.props.customDateToText}
          customLeftTextStyle={{ color: Colors.mediumGray, fontWeight: Fonts.weight.regular }}
          customRightTextStyle={rightTextStyle}
          customAdditionalLeftTextStyle={customAdditionalLeftTextStyle}
          onListItemIcon={this.props.onListItemIcon ? () => this.props.onListItemIcon(item) : null}
        />
      )
    } else if (item.type === 'repeat') {
      return (
        <RepeatListItem
          key={'lid-' + index}
          leftText={item.left}
          additionalLeftText={item.createdBy}
          rightText={item.right}
          repeatDaysOfWeek={item.repeatDaysOfWeek}
          repeatStartHour={item.repeatStartHour}
          repeatEndHour={item.repeatEndHour}
          dateFrom={item.dateFrom}
          dateTo={item.dateTo}
          withEditButton={this.props.withEditButton}
          lastOfList={(index + 1) === this.props.list.length}
          editButtonFlex={this.state.listItemEditFlex}
          onPressEdit={() => { this.onEditItem(item) }}
          customDateFromText={this.props.customDateFromText}
          customDateToText={this.props.customDateToText}
          customLeftTextStyle={{ color: Colors.mediumGray, fontWeight: Fonts.weight.regular }}
          customRightTextStyle={rightTextStyle}
          customAdditionalLeftTextStyle={customAdditionalLeftTextStyle}
          onListItemIcon={this.props.onListItemIcon ? () => this.props.onListItemIcon(item) : null}
        />
      )
    }
  }

  renderLocationItem = (item, index) => {
    return (
      <LocationListItem 
        key={'lis-' + index}
        leftText1={item.left1}
        rightText1={item.right1}
        leftText2={item.left2}
        rightText2={item.right2}
        leftText3={item.left3}
        rightText3={item.right3}
        leftText4={item.left4}
        rightText4={item.right4}
        withEditButton={this.props.withEditButton}
        lastOfList={(index + 1) === this.props.list.length}
        editButtonFlex={this.state.listItemEditFlex}
        onPressEdit={() => { this.onEditItem(item) }}
        customRightStyle={item.importantRight ? { flex: 2 } : {}}
      />
    )
  }

  renderBookingItem = (item, index) => {
    return (
      <BookingListItem 
        key={'lib-' + index}
        bookingFrom={item.startDate}
        residencyDays={Number(item.residencyDays)}
        checkedIn={item.checkedIn}
        guests={Number(item.guests)}
        withEditButton={this.props.withEditButton}
        lastOfList={(index + 1) === this.props.list.length}
        editButtonFlex={this.state.listItemEditFlex}
        onPressEdit={() => { this.onEditItem(item) }}
        onListItemMain={this.props.onListItemMain ? () => { this.props.onListItemMain(item._id) } : null}
        onListItemSecondary={this.props.onListItemSecondary ? () => { this.props.onListItemSecondary(item._id) } : null}
      />
    )
  }

  renderGuestItem = (item, index) => {
    return (
      <LocationListItem 
        key={'lis-' + index}
        leftText1={item.left1}
        rightText1={item.right1}
        leftText2={item.left2}
        rightText2={item.right2}
        leftText3={item.left3}
        rightText3={item.right3}
        onListItemSecondary={this.props.onListItemSecondary ? () => { this.props.onListItemSecondary(item._id) } : null}
        lastOfList={(index + 1) === this.props.list.length}
        withEditButton={false}
        customRightStyle={item.importantRight ? { flex: 2 } : {}}
      />
    )
  }

  renderItem = (item, index) => {
    if (this.props.listItemType === 'simple') {
      return this.renderSimpleItem(item, index)
    } else if (this.props.listItemType === 'users') {
      return this.renderUserItem(item, index)
    } else if (this.props.listItemType === 'dates') {
      return this.renderDatesItem(item, index)
    } else if (this.props.listItemType === 'authorized-phones' || this.props.listItemType === 'authorized-codes') {
      return this.renderPhoneItem(item, index)
    } else if (this.props.listItemType === 'location') {
      return this.renderLocationItem(item, index)
    } else if (this.props.listItemType === 'booking') {
      return this.renderBookingItem(item, index)
    } else if (this.props.listItemType === 'guest') {
      return this.renderGuestItem(item, index)
    }
    return null
  }

  render = () => {
    return (
      <ContentCard
        title={this.props.title}
        withEditButton={this.props.withEditButton}
        toggleEdit={this.props.customOnPressEdit ? this.props.customOnPressEdit : this.toggleEdit}
        editing={this.state.editing}
        renderContent={this.renderContent}
        renderButtons={
          () => { 
            return(
              <>
                { this.props.renderExtraContentBeforeButtons && this.props.renderExtraContentBeforeButtons()}
                {
                  this.props.addButton && !this.props.addButtonAsGhost &&
                  <MainButton
                    buttonText={this.props.addButtonText ? this.props.addButtonText : I18n.t('shared.add')}
                    onPress={() => { this.onAdd() }}
                    disabled={false}
                    icon={this.props.addButtonIcon}
                    customButtonStyle={{ margin: 0, marginTop: Metrics.doubleBaseMargin }}
                  />
                }
                {
                  this.props.addButton && this.props.addButtonAsGhost &&
                  <GhostButton
                    buttonText={this.props.addButtonText ? this.props.addButtonText : I18n.t('shared.add')}
                    onPress={() => { this.onAdd() }}
                    disabled={false}
                    icon={this.props.addButtonIcon}
                    customButtonStyle={{ margin: 0, marginTop: Metrics.doubleBaseMargin }}
                  />
                }
                { this.props.renderExtraContentAfterButtons && this.props.renderExtraContentAfterButtons() }
              </>
            )
          }
        }
        customButtonsContainerStyles={{ marginTop: 0 }}
        customEditIcon={this.props.customEditIcon}
        customEditText={this.props.customEditText}
      />
    )
  }

}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { connectionIsWifiDevice, getActualConnection } from '../../../Lib/ConnectionsUtils'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import I18n from '../../../i18n/i18n'
import UserForm from '../../../Components/Screens/Connection/Forms/UserForm'
import { ModalService } from '../../../Services/Modal'
import { FormModalService } from '../../../Services/FormModal'

class UserFormScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      user: null
    }
  }

  componentDidMount = () => {
    const { connectionId, user } = this.props
    this.setState({ connectionId, user })
    if (!user) { this.props.getQrs(connectionId) }
  }

  shareQR = (deviceId, connectionName, qrUrl) => {
    let text = I18n.t('modals.shareUserQr.text') + '\n\n' + I18n.t('shares.newUser.message')
    if (deviceId) { text += '\n' + I18n.t('shares.newUser.deviceId') + deviceId }
    if (connectionName) { text += '\n' + I18n.t('shares.newUser.name') + connectionName }
    if (deviceId || connectionName) { text += '\n' + qrUrl }
    ModalService.show(
      I18n.t('modals.shareUserQr.title'),
      text,
      null,
      null,
      I18n.t('modals.buttons.defaultOk')
    )
  }

  saveUser = (user) => {
    const { profile } = user
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    const { device = {} } = connection
    const { trialEnded = false } = device
    if (!trialEnded) {
      if (profile === 'owner') { this.updateUserOwner(user) }
      else { this.props.saveUser(this.state.connectionId, user) }
    } else {
      this.showModalUpgrade()
    }
  }

  deleteUser = (user) => {
    ModalService.show(
      I18n.t('modals.deleteUser.title'), 
      I18n.t('modals.deleteUser.text'), 
      () => { this.props.deleteUser(this.state.connectionId, user) }, 
      I18n.t('modals.deleteUser.buttons.ok'),
      null,
      true
    )
  }

  updateUserOwner = (user) => {
    ModalService.show(
      I18n.t('modals.updateUserOwner.title'), 
      I18n.t('modals.updateUserOwner.text'), 
      () => {
        ModalService.update(
          I18n.t('modals.updateUserOwnerConfirm.title'), 
          I18n.t('modals.updateUserOwnerConfirm.text'),
          () => { this.props.saveUser(this.state.connectionId, user) }, 
          I18n.t('modals.updateUserOwnerConfirm.buttons.ok'),
          null,
          true
        )
      }, 
      I18n.t('modals.updateUserOwner.buttons.ok')
    )
  }

  showModalUpgrade = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    const { _id, device = {}, owner: connectionOwner } = connection
    const { owner: deviceOwner } = device
    if (deviceOwner === connectionOwner) {
      const isWifiDevice = connectionIsWifiDevice(connection)
      const plan = { type: isWifiDevice ? 'intercom' : 'access' }
      ModalService.show(
        I18n.t('modals.upgradeFeatureOwner.title'), 
        I18n.t('modals.upgradeFeatureOwner.text'),
        () => { FormModalService.open('upgrade', { connectionId: _id, plan }) },
        I18n.t('modals.upgradeFeatureOwner.buttons.ok')
      )
    } else {
      ModalService.show(
        I18n.t('modals.upgradeNotOwner.title'), 
        I18n.t('modals.upgradeNotOwner.text')
      )
    }
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    return (
      <UserForm
        activeLang={this.props.activeLang}
        connection={connection}
        fetching={this.props.fetching}
        error={this.props.error}
        user={this.state.user}
        shareQR={this.shareQR}
        onSubmit={this.saveUser}
        onDelete={this.deleteUser}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    activeLang: state.i18n.activeLang,
    fetching: state.connections.fetchingConnectionForm,
    error: state.connections.errorQrs
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getQrs: (connectionId) => dispatch(ConnectionsActions.connectionQrsRequest(connectionId)),
    saveUser: (connectionId, user) => dispatch(ConnectionsActions.connectionUserSaveRequest(connectionId, user)),
    deleteUser: (connectionId, user) => dispatch(ConnectionsActions.connectionUserDeleteRequest(connectionId, user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserFormScreen)
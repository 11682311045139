import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form/lib'
import I18n from '../../../../i18n/i18n'
import { objectDeepCopy } from '../../../../Lib/ObjectsUtils'
import { getConnectionSelectedDoorPort } from '../../../../Lib/ConnectionsUtils'
import { getConnectionDoorsAsFormStruct } from '../../../../Lib/ConnectionFormsUtils'
import {
  inputDateFormat,
  inputTimeFormat,
  getDatetimes,
  getTimes,
  getRecalculatedDatetimes,
  getDatetimeAsUTC,
  getDatetimeIsBeforeNow,
  getTimezone,
  getTimeInCurrentTimezoneAsString
} from '../../../../Lib/DateTimeUtils'
import { codeValidation } from '../../../../Lib/FormUtils'
import FullScreenLoader from '../../../UI/Loaders/FullScreenLoader'
import BaseSwitch from '../../../UI/Switchs/BaseSwitch'
import WeekdaysSelector from '../../../UI/Selectors/WeekdaysSelector'
import BaseForm from '../../../UI/Forms/BaseForm'
import FormCard from '../../../UI/ContentCards/FormCard'
import styles from '../../../Styles/Screens/Forms'

export default class AuthorizedCodeForm extends Component {

  static propTypes = {
    code: PropTypes.object,
    connection: PropTypes.object,
    doorId: PropTypes.string,
    hasMultiplePhoneDoors: PropTypes.bool.isRequired,
    fetching: PropTypes.bool.isRequired,
    submitButtonDisabled: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
  }

  static defaultProps = {
    code: null,
    connection: null,
    doorId: null,
    hasMultiplePhoneDoors: null,
    fetching: false,
    submitButtonDisabled: false,
    onSubmit: null,
    onDelete: null
  }

  constructor(props) {
    super(props)
    const { now, to } = getDatetimes(null, null)
    this.state = {
      value: {
        _id: null,
        code: '',
        alias: '',
        doorPort: '1'
      },
      valueDates: {
        startDate: now,
        endDate: to,
        startTime: now,
        endTime: to
      },
      enabled: true,
      unlimitedAccess: true,
      deleteOnEnd: false,
      repeatDays: false,
      repeatDaysOfWeek: ''
    }
  }

  componentDidUpdate = (prevProps) => {
    const { code: prevCode, connection: prevConnection } = prevProps
    const { code, connection, doorId } = this.props
    // EDIT
    if (!prevCode && code) {
      const {
        _id, codeNumber, alias = '', authorizedFrom, authorizedTo, deleteOnEnd = false,
        repeatDaysOfWeek, repeatEndHour, repeatStartHour, repeatTimezone, enabledForUse = true
      } = code
      const value = {
        _id,
        code: codeNumber,
        alias,
        doorPort: '' + getConnectionSelectedDoorPort(connection, doorId)
      }
      if (authorizedFrom && authorizedTo) {
        const { now, to } = getDatetimes(authorizedFrom, authorizedTo)
        const valueDates = {
          startDate: now,
          endDate: to,
          startTime: now,
          endTime: to
        }
        this.setState({
          value,
          valueDates,
          enabled: enabledForUse,
          unlimitedAccess: false,
          deleteOnEnd
        })
      } else if (repeatDaysOfWeek && repeatEndHour && repeatStartHour && repeatTimezone) {
        const { now, to } = getTimes(repeatStartHour, repeatEndHour)
        const valueDates = {
          startDate: new Date(),
          endDate: new Date(),
          startTime: now,
          endTime: to
        }
        this.setState({
          value,
          valueDates,
          enabled: enabledForUse,
          unlimitedAccess: false,
          deleteOnEnd: false,
          repeatDays: true,
          repeatDaysOfWeek
        })
      } else {
        this.setState({ value, enabled: enabledForUse })
      }
    }
    // CREATE
    if (!prevConnection && connection && !code && !prevCode) {
      let value = {
        _id: null,
        code: `${Math.floor(100000 + Math.random() * 900000)}`,
        alias: '',
        doorPort: '' + getConnectionSelectedDoorPort(connection, doorId)
      }
      this.setState({ value })
    }
  }

  onEnabledSwitchChange = (value) => {
    this.setState({ enabled: value })
  }

  onChange = (value) => {
    const { _id, code = '', alias, doorPort } = value
    let newCode = code.trim()
    this.setState({
      value: {
        _id,
        code: newCode,
        alias,
        doorPort
      }
    })
  }

  onChangeDates = (value) => {
    const { startDate: sDate, startTime: sTime, endDate: eDate, endTime: eTime } = value
    const { startDate, endDate, startTime, endTime } = getRecalculatedDatetimes(sDate, sTime, eDate, eTime, null, this.state.repeatDays ? true : false)
    this.setState({
      valueDates: {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime
      }
    })
  }

  onUnlimitedAccessSwitchChange = (value) => {
    this.setState({ unlimitedAccess: value })
  }

  onDeleteOnEndSwitchChange = (value) => {
    this.setState({ deleteOnEnd: value, repeatDays: value ? false : this.state.repeatDays })
  }

  onRepeatDaysSwitchChange = (value) => {
    this.setState({ repeatDays: value, deleteOnEnd: value ? false : this.state.deleteOnEnd })
  }

  onSubmit = (value) => {
    const { enabled, deleteOnEnd, repeatDays, repeatDaysOfWeek, unlimitedAccess, valueDates } = this.state
    let completeValue = objectDeepCopy(value)
    if (enabled) {
      completeValue.enabledForUse = true
      completeValue.authorized = true
      completeValue.deleteOnEnd = deleteOnEnd
      // // If unlimitedAccess is unchecked, complete value with valueDates or with repeat values
      if (!unlimitedAccess) {
        const { startDate, startTime, endDate, endTime } = valueDates
        if (repeatDays) {
          // If repeatDays is checked, complete value with repeat values
          completeValue.deleteOnEnd = false
          completeValue.authorized = true
          completeValue.authorizedFrom = null
          completeValue.authorizedTo = null
          completeValue.repeatDaysOfWeek = repeatDaysOfWeek
          completeValue.repeatStartHour = getTimeInCurrentTimezoneAsString(startDate, startTime)
          completeValue.repeatEndHour = getTimeInCurrentTimezoneAsString(endDate, endTime)
          completeValue.repeatTimezone = getTimezone()
        } else {
          // If repeatDays is not checked, complete value with valueDates
          const startUTC = getDatetimeAsUTC(startDate, startTime)
          const endUTC = getDatetimeAsUTC(endDate, endTime)
          completeValue.authorizedFrom = startUTC
          completeValue.authorizedTo = endUTC
          completeValue.authorized = getDatetimeIsBeforeNow(startDate, startTime)
          completeValue.repeatDaysOfWeek = null
          completeValue.repeatStartHour = null
          completeValue.repeatEndHour = null
          completeValue.repeatTimezone = null
        }
      }
    } else {
      completeValue.enabledForUse = false
      completeValue.authorized = false
    }
    // If alias is not present, complete value with an empty alias
    if (!completeValue.alias) { completeValue.alias = '' }
    this.props.onSubmit(completeValue)
  }

  getFields = () => {
    const doors = getConnectionDoorsAsFormStruct(this.props.connection, false, true, true)
    const fieldsPhone = {
      _id: t.maybe(t.String),
      code: t.refinement(t.String, codeValidation),
      alias: t.maybe(t.String),
      doorPort: t.enums(doors, 'Port')
    }
    const fieldsDates = {
      startDate: t.String,
      endDate: t.String,
      startTime: t.String,
      endTime: t.String,
    }
    return { fieldsPhone, fieldsDates }
  }

  getFieldsOptions = () => {
    const editing = this.state.value._id ? true : false
    let fieldsOptionsPhone = {
      _id: { hidden: true },
      code: {
        label: I18n.t('screens.authorizedCodeForm.labels.code'),
        help: I18n.t('screens.authorizedCodeForm.helps.code'),
        autoCapitalize: 'none',
        autoCorrect: false,
        keyboardType: 'phone-pad'
      },
      alias: {
        label: I18n.t('screens.authorizedCodeForm.labels.alias'),
      },
      doorPort: {
        label: I18n.t('shared.door'),
        nullOption: false
      }
    }
    let fieldsOptionsDates = {
      startDate: {
        label: I18n.t('screens.scheduleForm.labels.startDate'),
        mode: 'date',
        minimumDate: new Date(),
        hidden: this.state.repeatDays ? true : false,
        config: {
          format: (date) => inputDateFormat(date),
        }
      },
      endDate: {
        label: I18n.t('screens.scheduleForm.labels.endDate'),
        mode: 'date',
        minimumDate: new Date(),
        hidden: this.state.repeatDays ? true : false,
        config: {
          format: (date) => inputDateFormat(date),
        }
      },
      startTime: {
        label: I18n.t('screens.scheduleForm.labels.startTime'),
        mode: 'time',
        minuteInterval: 1,
        minimumDate: !this.state.repeatDays ? new Date() : null,
        config: {
          format: (time) => inputTimeFormat(time),
        }
      },
      endTime: {
        label: I18n.t('screens.scheduleForm.labels.endTime'),
        mode: 'time',
        minuteInterval: 1,
        minimumDate: !this.state.repeatDays ? new Date() : null,
        config: {
          format: (time) => inputTimeFormat(time),
        }
      }
    }
    // if (this.state.repeatDays) {
    //   let customStylesheet = objectDeepCopy(stylesForms)
    //   customStylesheet.controlLabel.normal.marginTop = 0
    //   customStylesheet.controlLabel.error.marginTop = 0
    //   fieldsOptionsDates['startTime']['stylesheet'] = customStylesheet
    // } else {
    //   let customStylesheet = objectDeepCopy(stylesForms)
    //   customStylesheet.controlLabel.normal.marginTop = Metrics.doubleBaseMargin
    //   customStylesheet.controlLabel.error.marginTop = Metrics.doubleBaseMargin
    //   fieldsOptionsDates['startTime']['stylesheet'] = customStylesheet
    // }
    if (!this.props.hasMultiplePhoneDoors || editing) { fieldsOptionsPhone.doorPort.hidden = true }
    return { fieldsOptionsPhone, fieldsOptionsDates }
  }

  renderExtraContent = (fields, fieldsOptions) => {
    const { device = {} } = this.props.connection || {}
    const { trialEnded = false } = device
    return (
      <>
        <BaseSwitch
          label={null}
          help={I18n.t('shared.enabled')}
          value={this.state.enabled}
          disabled={trialEnded}
          onChange={(value) => { this.onEnabledSwitchChange(value) }}
          customSwitchRowStyle={styles.switchRow}
        />
        {
          this.state.enabled &&
          <>
            <BaseSwitch
              label={null}
              help={I18n.t('screens.authorizedCodeForm.labels.unlimitedAccess')}
              value={this.state.unlimitedAccess}
              disabled={trialEnded}
              onChange={(value) => { this.onUnlimitedAccessSwitchChange(value) }}
              customSwitchRowStyle={styles.switchRow}
            />
            {
              !this.state.unlimitedAccess &&
              <div style={styles.complementaryForm}>
                <BaseForm
                  name={'authorizedCodeAccessDatesForm'}
                  onSubmit={() => { }}
                  fields={fields}
                  fieldsOptions={fieldsOptions}
                  onChange={this.onChangeDates}
                  value={this.state.valueDates}
                  fetching={this.props.fetching}
                  withoutSubmit={true}
                />
                <BaseSwitch
                  label={null}
                  help={I18n.t('screens.authorizedCodeForm.labels.deleteOnEnd')}
                  value={this.state.deleteOnEnd}
                  disabled={false}
                  onChange={(value) => { this.onDeleteOnEndSwitchChange(value) }}
                  customSwitchRowStyle={styles.switchRow}
                />
                <BaseSwitch
                  label={null}
                  help={I18n.t('screens.authorizedCodeForm.labels.repeatDays')}
                  value={this.state.repeatDays}
                  disabled={false}
                  onChange={(value) => { this.onRepeatDaysSwitchChange(value) }}
                  customSwitchRowStyle={styles.switchRow}
                />
                {
                  this.state.repeatDays &&
                  <WeekdaysSelector
                    onChange={(repeatDaysOfWeek) => { this.setState({ repeatDaysOfWeek }) }}
                    value={this.state.repeatDaysOfWeek}
                  />
                }
              </div>
            }
          </>
        }
      </>
    )
  }

  render = () => {
    const { fieldsPhone, fieldsDates } = this.getFields()
    const { fieldsOptionsPhone, fieldsOptionsDates } = this.getFieldsOptions()
    return (
      <>
        <FormCard
          name={'authorizedCodeForm'}
          title={this.props.code ? I18n.t('screens.authorizedCodeForm.titleFormEdit') : I18n.t('screens.authorizedCodeForm.titleForm')}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          deleteButton={this.props.code ? true : false}
          onDelete={() => { this.props.onDelete(this.props.code) }}
          fields={fieldsPhone}
          fieldsOptions={fieldsOptionsPhone}
          value={this.state.value}
          fetching={this.props.fetching}
          renderExtraContentBeforeButtons={() => this.renderExtraContent(fieldsDates, fieldsOptionsDates)}
          submitButtonDisabled={this.props.submitButtonDisabled}
        />
        <FullScreenLoader visible={this.props.fetching} />
      </>
    )
  }

}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import I18n from '../../../i18n/i18n'
import SimpleIconButton from '../Buttons/SimpleIconButton'
import FullScreenLoader from '../Loaders/FullScreenLoader'
import styles from '../../Styles/UI/Menu'
import { Colors, Metrics, Fonts } from '../../../Themes'

const StyledDiv = styled.div `
  .menuButton {
    width: ${Metrics.baseMenuWidth};
    margin-bottom: ${Metrics.baseMarginPlus};
    text-align: center;
  }
  .menuButton .buttonName {
    transition: opacity 0.25s linear, visibility 0.25s;
    visibility: hidden;
    opacity: 0;
    text-align: center;
    font-family: ${Fonts.type.regular};
    font-size: ${Fonts.size.small};
    color: ${Colors.white};
    cursor: pointer;
  }
  .menuButton:hover .buttonName {
    visibility: visible;
    opacity: 1;
  }
  .menuButton.last {
    margin-top: auto;
    margin-bottom: ${Metrics.cuadrupleBaseMargin};
  }
`

export default class Menu extends Component {

  static propTypes = {
    authFetching: PropTypes.bool.isRequired,
    // onPressDashboard: PropTypes.func.isRequired,
    onPressAccount: PropTypes.func.isRequired,
    onPressUpgrades: PropTypes.func.isRequired,
    onPressSettings: PropTypes.func.isRequired,
    // onPressCredit: PropTypes.func.isRequired,
    onPressIntegrations: PropTypes.func.isRequired,
    onPressCheckin: PropTypes.func.isRequired,
    onPressSupport: PropTypes.func.isRequired,
    onPressTerms: PropTypes.func.isRequired,
    onPressLogout: PropTypes.func.isRequired
  }

  static defaultProps = {
    authFetching: null,
    // onPressDashboard: null,
    onPressAccount: null,
    onPressUpgrades: null,
    onPressSettings: null,
    // onPressCredit: null,
    onPressIntegrations: null,
    onPressCheckin: null,
    onPressSupport: null,
    onPressTerms: null,
    onPressLogout: null
  }

  renderButton = (icon, text, onPress, isFirst = false, isLast = false) => {
    return (
      <div className={`menuButton ${isLast ? 'last' : ''}`}>
        <SimpleIconButton
          onPress={onPress}
          icon={icon}
          firstItem={isFirst}
          customButtonStyle={{ marginBottom: 0, padding: (Metrics.baseMeasure - 2) + 'px' }}
        />
        <div className='buttonName' onClick={onPress}>
          { text }
        </div>
      </div>
    )
  }

  render = () => {
    return (
      <StyledDiv>
        <div style={styles.menuContainer}>
          <div style={styles.menu}>
            { /* this.renderButton('dashboard', I18n.t('shared.homeScreen'), this.props.onPressDashboard, true) */ }
            { this.renderButton('person', I18n.t('shared.profile'), this.props.onPressAccount, true) }
            { this.renderButton('rocket', I18n.t('shared.upgrades'), this.props.onPressUpgrades) }
            { this.renderButton('settings', I18n.t('shared.settings'), this.props.onPressSettings) }
            { /* this.renderButton('wallet', I18n.t('shared.credit'), this.props.onPressCredit) */ }
            { this.renderButton('world', I18n.t('shared.integrations'), this.props.onPressIntegrations) }
            { this.renderButton('identification', I18n.t('shared.checkin'), this.props.onPressCheckin) }
            { this.renderButton('phone', I18n.t('shared.support'), this.props.onPressSupport) }
            { this.renderButton('terms', I18n.t('shared.termsAbbr'), this.props.onPressTerms) }
            { this.renderButton('logout', I18n.t('shared.logout'), this.props.onPressLogout, false, window.innerWidth < 992 ? false : true) }
          </div>
        </div>
        <FullScreenLoader visible={this.props.authFetching} />
      </StyledDiv>
    )
  }

}

import React, { Component } from 'react'
import styled from 'styled-components'
import { Colors, Metrics } from '../../../Themes'

const StyledDiv = styled.div `
  .authBackground {
    min-height: 692px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
    padding-top: ${Metrics.baseMeasure * 10}px
  }
  .gridContainer {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: [start] 1fr [left-gutter] (86.6px)[16] [left-gutter] 1fr [end];
    grid-template-columns: [start] 1fr [left-gutter] repeat(16,86.6px) [left-gutter] 1fr [end];
    -ms-grid-rows: [top] 1fr [top-gutter] (64px)[8] [bottom-gutter] 1fr [bottom];
    grid-template-rows: [top] 1fr [top-gutter] repeat(8,64px) [bottom-gutter] 1fr [bottom];
    justify-content: center;
    margin: 0 -2%;
    transform: rotate(-12deg) skew(-12deg);
  }
  .flex {
    display: -ms-flexbox;
    display: flex;
  }
  .boxRoot {
    box-sizing: border-box;
  }
  .boxBorder {
    box-shadow: inset 0 0 0 2px ${Colors.lightGrayBackground};
  }
  .boxTheme {
    background-color: ${Colors.theme1Background};
  }
  .boxGray {
    background-color: ${Colors.lightGrayBackground};
  }
`

export default class AuthBackground extends Component {

  render = () => {
    return (
      <StyledDiv>
        <div className='authBackground boxRoot Box-background--white'>
          <div className='gridContainer'>
            <div className='boxRoot flex' style={{ gridArea: 'top / start / 8 / end' }}>
              <div className='boxRoot' style={{ flexGrow: 1 }}>
              </div>
            </div>
            <div className='boxRoot flex' style={{ gridArea: '4 / 2 / auto / 5' }}>
              <div className='boxRoot boxBorder' style={{ flexGrow: 1 }}>
              </div>
            </div>
            <div className='boxRoot flex' style={{ gridArea: '6 / start / auto / 2' }}>
              <div className='boxRoot boxTheme' style={{ flexGrow: 1 }}>
              </div>
            </div>
            <div className='boxRoot flex' style={{ gridArea: '7 / start / auto / 4' }}>
              <div className='boxRoot boxGray' style={{ flexGrow: 1 }}>
              </div>
            </div>
            <div className='boxRoot flex' style={{ gridArea: '8 / 4 / auto / 6' }}>
              <div className='boxRoot boxTheme' style={{ flexGrow: 1 }}>
              </div>
            </div>
            <div className='boxRoot flex' style={{ gridArea: '2 / 15 / auto / end' }}>
              <div className='boxRoot boxTheme' style={{ flexGrow: 1 }}>
              </div>
            </div>
            <div className='boxRoot flex' style={{ gridArea: '3 / 14 / auto / end' }}>
              <div className='boxRoot boxGray' style={{ flexGrow: 1 }}>
              </div>
            </div>
            <div className='boxRoot flex' style={{ gridArea: '4 / 17 / auto / 20' }}>
              <div className='boxRoot boxTheme' style={{ flexGrow: 1 }}>
              </div>
            </div>
            <div className='boxRoot flex' style={{ gridArea: '5 / 14 / auto / 17' }}>
              <div className='boxRoot boxBorder' style={{ flexGrow: 1 }}>
              </div>
            </div>
          </div>
        </div>
      </StyledDiv>
    )
  }

}
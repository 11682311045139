import React, { Component } from 'react'
import { connect } from 'react-redux'

import I18n from '../../i18n/i18n'
import ContentCard from '../../Components/UI/ContentCards/ContentCard'
import BaseSwitch from '../../Components/UI/Switchs/BaseSwitch'
import styles from '../../Components/Styles/Screens/Forms'

import { Metrics } from '../../Themes'
import UserActions from '../../Redux/UserRedux'

class NotificationsScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmDeviceOpenClose: true
    }
  }

  componentDidMount() {
    const { userSettings } = this.props
    this.setState({ ...userSettings })
  }

  onConfirmDeviceOpenCloseChange = (value) => {
    this.setState({ confirmDeviceOpenClose: value })
    const type = 'confirmDeviceOpenClose'
    const { user } = this.props
    const newSettings = { ...user.settings, [type]: value }
    this.props.updateUserValue(user, { settings: newSettings })
  }
  
  renderSwitch = (type, value, onChangeFunction, first = false, last = false) => {
    return (
      <>
        <BaseSwitch
          value={value}
          disabled={false}
          label={I18n.t('screens.notifications.labels.' + type)}
          help={I18n.t('screens.notifications.helps.' + type)}
          onChange={onChangeFunction}
          customSwitchRowStyle={styles.switchRow}
          customHelpStyle={styles.switchHelpSmall}
          customLabelStyle={first ? { marginTop: 0 } : null}
          customSwitchStyle={styles.switch}
          withoutMargin={true}
        />
        <div style={last ? styles.listItem : Object.assign({}, styles.listItem, styles.lastListItem)} />
      </>
    )
  }
  
  renderSettings = () => {
    return (
      <div style={{paddingTop: Metrics.smallMargin, paddingBottom: Metrics.smallMargin}}>
        { this.renderSwitch('confirmDeviceOpenClose', this.state.confirmDeviceOpenClose, this.onConfirmDeviceOpenCloseChange, true, true) }
      </div>
    )
  }
  
  render = () => {
    return (
      <ContentCard
        renderContent={() => (
          <ContentCard
            title={I18n.t('shared.generalSettings')}
            renderContent={() => (
              <>
                { this.renderSettings() }
              </>
            )}
          />
        )}
      />
    )
  }
}


const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    userSettings: state.user.userSettings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserValue: (user, fields) => dispatch(UserActions.userSaveRequest(user, fields))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsScreen)
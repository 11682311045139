import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form/lib'
import I18n from '../../../../i18n/i18n'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import AppText from '../../../UI/Texts/AppText'
import FullScreenLoader from '../../../UI/Loaders/FullScreenLoader'
import GhostButton from '../../../UI/Buttons/GhostButton'
import { Metrics, Fonts } from '../../../../Themes'
import BaseForm from '../../../UI/Forms/BaseForm'

export default class DoorCode extends Component {

  static propTypes = {
    fetching: PropTypes.bool.isRequired,
    getDoorCode: PropTypes.func.isRequired,
    deleteDoorCode: PropTypes.func.isRequired,
    generateLink: PropTypes.func.isRequired,
    isEdit: PropTypes.bool,
    doorName: PropTypes.string,
    alias: PropTypes.string,
    urlText: PropTypes.string
  }

  static defaultProps = {
    fetching: false,
    getDoorCode: null,
    deleteDoorCode: null,
    generateLink: null,
    isEdit: false,
    doorName: '',
    alias: '',
    urlText: ''
  }

  constructor(props) {
    super(props)
    this.state = {
      value: {
        alias: ''
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const { alias: prevAlias } = prevProps
    const { alias } = this.props
    if (!prevAlias && alias) {
      this.setState({
        value: { alias }
      })
    }
  }

  onSubmit = (value) => {
    const { alias } = value || {}
    if (alias) {
      this.props.getDoorCode(alias, this.props.urlText)
    } else {
      this.props.getDoorCode(this.props.alias, this.props.urlText)
    }
  }

  renderContent = () => {
    return (
      <>
        <AppText
          text={this.props.urlText}
          customStyle={{ marginTop: Metrics.baseMargin, marginBottom: Metrics.tripleBaseMargin, fontSize: Fonts.size.title }}
        />
        <BaseForm
          name={'door-code-form'}
          onSubmit={this.onSubmit}
          fields={{ alias: t.maybe(t.String) }}
          fieldsOptions={{ 
            alias: {
              label: I18n.t('screens.codeDoor.optionalAlias'),
              autoCapitalize: 'none',
              autoCorrect: false,
              customTemplate: false 
            }
          }}
          value={this.state.value}
          onChange={(value) => { this.setState({ value }) }}
          submitText={I18n.t('shared.save')}
          submitIcon={'save'}
        />
        <GhostButton
          buttonText={I18n.t('screens.codeDoor.buttons.generateLink')}
          onPress={this.props.generateLink}
          icon={'link'}
          disabled={false}
          customButtonStyle={{ marginBottom: this.props.isEdit ? Metrics.baseMargin : 0 }}
        />
        {
          this.props.isEdit &&
          <GhostButton
            buttonText={I18n.t('screens.codeDoor.buttons.delete')}
            onPress={this.props.deleteDoorCode}
            disabled={false}
            customButtonStyle={{ marginVertical: 0, marginBottom: 0 }}
          />
        }
      </>
    )
  }

  render = () => {
    let title = this.props.isEdit
      ? I18n.t('screens.codeDoor.titleEdit')
      : I18n.t('screens.codeDoor.titleNew')
    title += ' ' + this.props.doorName
    return (
      <>
        <ContentCard
          title={title}
          renderContent={this.renderContent}
        />
        <FullScreenLoader visible={this.props.fetching} />
      </>
    )
  }

}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getConnectionHeaderMessages } from '../../../Lib/ConnectionsUtils'
import AppTitle from '../Texts/AppTitle'
import AppText from '../Texts/AppText'
import SimpleIconButton from '../Buttons/SimpleIconButton'
import styles from '../../Styles/UI/Connections/Header'
import { Metrics } from '../../../Themes'

export default class ConnectionHeader extends Component {

  static propTypes = {
    notifications: PropTypes.node.isRequired,
    buttons: PropTypes.node.isRequired,
    connection: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.bool,
    hasUsableDoors: PropTypes.bool,
    getConnection: PropTypes.func.isRequired,
    customStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  }

  static defaultProps = {
    notifications: null,
    buttons: null,
    connection: null,
    title: null,
    fetching: null,
    error: null,
    hasUsableDoors: null,
    getConnection: null,
    customStyle: {}
  }

  constructor(props) {
    super(props)
    this.state = {
      firstLoad: true,
      showNotifications: false
    }
  }

  componentDidUpdate = (prevProps) => {
    const { connection: prevConnection } = prevProps
    const { connection } = this.props
    if (connection._id !== prevConnection._id) {
      const { device } = connection || {}
      const { online } = device || {}
      if (!online) { this.setState({ showNotifications: true }) }
      else { this.setState({ showNotifications: false }) }
    }
    if (connection._id === prevConnection._id && this.state.firstLoad) {
      const { device } = connection || {}
      const { online } = device || {}
      if (!online) { this.setState({ showNotifications: true, firstLoad: false }) }
      else { this.setState({ showNotifications: false, firstLoad: false }) }
    }
  }

  componentDidMount = () => {
    this.setState({
      showNotifications: false
    })
  }

  toggleSummaries = () => {
    this.setState({ showNotifications: !this.state.showNotifications })
  }

  renderSummaries = () => {
    if (this.props.error) { return null }
    const messages = getConnectionHeaderMessages(this.props.connection, this.props.fetching)
    if (messages.length === 0) { return null }
    return (
      <div style={Object.assign({}, styles.headerSummariesContainer, this.state.showNotifications ? { marginTop: Metrics.doubleBaseMargin } : {})}>
        {
          messages.map((m, i) => {
            let containerCustomStyle = {}
            if (i === messages.length - 1) { containerCustomStyle = { marginBottom: 0 } }
            return (
              <AppText 
                key={'summary-' + i}
                text={m}
                customStyle={Object.assign({}, styles.headerSummaryText, containerCustomStyle)}
              />
            )
          })
        }
      </div>
    )
  }

  render = () => {
    return (
      <div style={Object.assign({}, styles.headerContainer, this.props.customStyle)}>
        <div style={Object.assign({}, styles.header)}>
          <div style={{ maxWidth: '550px', flex: 1 }}>
            <div style={styles.titleAndRefreshContainer}>
              <div style={styles.titleContainer}>
                <AppTitle
                  text={this.props.title}
                  customStyle={styles.headerTitleText}
                />
              </div>
              <div style={styles.refreshContainer}>
                <SimpleIconButton
                  onPress={this.toggleSummaries}
                  icon={'notifications'}
                  customIconStyle={styles.refreshButtonIcon}
                  customButtonStyle={styles.refreshButton}
                />
                <SimpleIconButton
                  onPress={this.props.getConnection}
                  icon={'refresh'}
                  customIconStyle={styles.refreshButtonIcon}
                  customButtonStyle={styles.refreshButton}
                />
              </div>
            </div>
            {
              <div
                id={'notificationsContainer'}
                style={Object.assign(
                  {},
                  styles.headerNotificationsAnimatedContainer,
                  // TODO: check why this was implemented?
                  // !this.state.showNotifications ? styles.headerNotificationsHidden : {}
                )}
              >
                { this.props.notifications }
              </div>
            }
            { this.renderSummaries() }
            { this.props.buttons }
            { !this.props.hasUsableDoors && <div style={styles.headerEmptySpace} /> }
          </div>
        </div>
      </div>
    )
  }

}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, SvgIcon } from '@material-ui/core'
import LockPath from '../../../Images/Icons/lock'
import LockOpenPath from '../../../Images/Icons/lock-open'
import BellPath from '../../../Images/Icons/bell'
import KeyPath from '../../../Images/Icons/key'
import SmallLoader from '../../UI/Loaders/SmallLoader'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import AppText from '../Texts/AppText'
import styles from '../../Styles/UI/Connections/Header'
import stylesButton from '../../Styles/UI/Button'
import { Colors, Metrics } from '../../../Themes'

export default class ConnectionHeaderButton extends Component {

  static propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
    onPress: PropTypes.func.isRequired,
    error: PropTypes.bool,
    indicatorText: PropTypes.string,
    lastOfList: PropTypes.bool
  }

  static defaultProps = {
    text: null,
    icon: null,
    fetching: null,
    disabled: null,
    isActive: null,
    onPress: null,
    error: null,
    indicatorText: null,
    lastOfList: false
  }

  getStatusIndicator = () => {
    if (this.props.isActive) {
      if (this.props.fetching) {
        return <SmallLoader visible={true} topRight={true} color={Colors.mediumGray} smaller={true} />
      } else if (!this.props.fetching && !this.props.error) {
        return (
          <CheckCircleIcon 
            style={
              Object.assign(
                {},
                styles.headerQuickActionStatusIndicator,
                { color: Colors.success, fontSize: Metrics.icons.big }
              )
            }
          />
        )
      } else if (!this.props.fetching && this.props.error) {
        return (
          <CancelIcon 
            style={
              Object.assign(
                {},
                styles.headerQuickActionStatusIndicator,
                { color: Colors.error, fontSize: Metrics.icons.big }
              )
            }
          />
        )
      }
    }
    if (!this.props.indicatorText) { return null }
    return <AppText text={this.props.indicatorText} customStyle={styles.headerQuickActionIndicatorText} />
  }

  render = () => {
    let buttonStyles = [stylesButton.buttonContainer, styles.headerQuickActionContainer]
    let customButtonStyles = [stylesButton.buttonContainer, { padding: 0, margin: 0, marginRight: 0 }]
    if (this.props.disabled) { buttonStyles.push(stylesButton.disabled) }
    if (this.props.lastOfList) { customButtonStyles.push({ marginRight: Metrics.tripleBaseMargin }) }
    const svgIconStyle = Object.assign(
      {},
      stylesButton.buttonIcon,
      styles.headerQuickActionIcon,
      { fontSize: Metrics.icons.bigger, color: Colors.theme1 },
      { position: 'relative', top: Metrics.svgIconPositionTop, left: Metrics.svgIconPositionLeft }
    )
    return (
      <Button 
        onClick={this.props.onPress}
        disabled={this.props.disabled}
        style={Object.assign({}, ...customButtonStyles)}
      >
        <div style={Object.assign({}, ...buttonStyles)}>
          <div style={styles.headerQuickActionIconIndicatorRow}>
            { this.props.icon === 'lock' && <SvgIcon style={svgIconStyle}>{LockPath}</SvgIcon> }
            { this.props.icon === 'lock-open' && <SvgIcon style={svgIconStyle}>{LockOpenPath}</SvgIcon> }
            { this.props.icon === 'key' && <SvgIcon style={svgIconStyle}>{KeyPath}</SvgIcon> }
            { this.props.icon === 'bell' && <SvgIcon style={svgIconStyle}>{BellPath}</SvgIcon> }
            { this.getStatusIndicator() }
          </div>
          <AppText 
            text={this.props.text} 
            numberOfLines={2}
            customStyle={Object.assign({}, stylesButton.buttonText, styles.headerQuickActionText)}
          />
        </div>
      </Button>
    )
  }

}
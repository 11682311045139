import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  connectionIsWifiDevice,
  connectionIsSubscribed,
  getNumberOfPhonesOfConnectionDeviceDoor
} from '../../../Lib/ConnectionsUtils'
import RaixerConfig from '../../../Config/RaixerConfig'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import I18n from '../../../i18n/i18n'
import Phones from '../../../Components/Screens/Connection/Tabs/Phones'
import { ModalService } from '../../../Services/Modal'
import { FormModalService } from '../../../Services/FormModal'

class PhonesTab extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired
  }

  static defaultProps = {
    connection: null
  }

  goToDoorPhoneNumber = (doorId, phone) => {
    const { connection } = this.props || {}
    const { _id, device = {} } = connection || {} 
    const { trialEnded = false } = device
    const isSubscribed = connectionIsSubscribed(connection)
    if (!trialEnded || isSubscribed) { FormModalService.open('door-phone', { connectionId: _id, doorId, phone }) }
    else { this.showModalPhoneUpgrade(connection) }
  }

  goToDoorAuthorizedPhone = (doorId, phone) => {
    const { connection } = this.props || {}
    const { _id } = connection || {}
    if (phone) {
      FormModalService.open('authorized-phone', { connectionId: _id, doorId, phone })
    } else {
      const { device = {} } = connection
      const { trialEnded = false, exhibitor = false } = device
      const isSubscribed = connectionIsSubscribed(connection)
      const numberOfPhonesOfDoor = getNumberOfPhonesOfConnectionDeviceDoor(connection, doorId)
      if (!trialEnded) {
        if (isSubscribed && !exhibitor) {
          FormModalService.open('authorized-phone', { connectionId: _id, doorId, phone })
        } else {
          if (numberOfPhonesOfDoor < RaixerConfig.connections.trialMaxDoorAuthorizedPhones) {
            FormModalService.open('authorized-phone', { connectionId: _id, doorId, phone })
          } else {
            this.showModalPhoneUpgrade(connection)
          }
        }
      } else {
        if (numberOfPhonesOfDoor < RaixerConfig.connections.freeMaxDoorAuthorizedPhones) {
          FormModalService.open('authorized-phone', { connectionId: _id, doorId, phone })
        } else {
          this.showModalPhoneUpgrade(connection)
        }
      }
    }
  }

  goToDoorPhoneNumberInformation = (doorId, phone) => {
    const { connection } = this.props || {}
    const { _id } = connection || {} 
    const { _id: phoneId } = phone
    FormModalService.open('information', { connectionId: _id, doorId, phoneId })
  }

  onPressSharePhone = (phone) => {
    const text = I18n.t('modals.shareDoorPhone.text') + '\n\n' + 
      `${I18n.t('shares.doorPhone.message')}\n${phone}`
    ModalService.show(
      I18n.t('modals.shareDoorPhone.title'),
      text,
      null,
      null,
      I18n.t('modals.buttons.defaultOk')
    )
  }

  showModalPhoneUpgrade = (connection) => {
    const { _id, device = {}, owner: connectionOwner } = connection
    const { owner: deviceOwner, exhibitor } = device
    if (exhibitor) {
      ModalService.show(
        I18n.t('modals.upgradeFeatureExhibitor.title'), 
        I18n.t('modals.upgradeFeatureExhibitor.text')
      )
    } else {
      if (deviceOwner === connectionOwner) {
        const isWifiDevice = connectionIsWifiDevice(connection)
        const plan = { type: isWifiDevice ? 'intercom' : 'access' }
        ModalService.show(
          I18n.t('modals.upgradePhoneFeatureOwner.title'), 
          I18n.t('modals.upgradePhoneFeatureOwner.text'),
          () => { FormModalService.open('upgrade', { connectionId: _id, plan }) },
          I18n.t('modals.upgradePhoneFeatureOwner.buttons.ok')
        )
      } else {
        ModalService.show(
          I18n.t('modals.upgradePhoneFeatureNotOwner.title'), 
          I18n.t('modals.upgradePhoneFeatureNotOwner.text')
        )
      }
    }
  }

  onCallingEnabledChange = (doorId, newValue) => {
    const { connection } = this.props || {}
    const { _id } = connection
    if (newValue) { this.props.setDoorPhoneStatus(_id, doorId, newValue) }
    else {
      ModalService.show(
        I18n.t('modals.disableDoorPhoneAccess.title'),
        I18n.t('modals.disableDoorPhoneAccess.text'),
        () => { this.props.setDoorPhoneStatus(_id, doorId, newValue) },
        I18n.t('modals.disableDoorPhoneAccess.buttons.ok'),
        null,
        true
      )
    }
  }

  onAnyCallerEnabledChange = (doorId, newValue) => {
    const { connection } = this.props || {}
    const { _id, device = {} } = connection
    const { trialEnded = false, exhibitor = false } = device
    const isSubscribed = connectionIsSubscribed(connection)
    if (exhibitor) {
      this.showModalPhoneUpgrade(connection)
    } else {
      if (!trialEnded) {
        if (isSubscribed) {
          this.props.setDoorPhoneAnyCallerStatus(_id, doorId, newValue)
        } else {
          this.showModalPhoneUpgrade(connection)
        }
      } else {
        this.showModalPhoneUpgrade(connection)
      }
    }
  }

  render = () => {
    const { connection } = this.props
    return (
      <Phones
        connection={connection}
        isUserAdmin={connection ? connection.profile === 'admin' : false}
        activeLang={this.props.activeLang}
        fetchingTask={this.props.fetchingConnectionForm}
        goToDoorPhoneNumber={this.goToDoorPhoneNumber}
        goToDoorAuthorizedPhone={this.goToDoorAuthorizedPhone}
        goToDoorPhoneNumberInformation={this.goToDoorPhoneNumberInformation}
        onPressSharePhone={this.onPressSharePhone}
        onCallingEnabledChange={this.onCallingEnabledChange}
        onAnyCallerEnabledChange={this.onAnyCallerEnabledChange}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    activeLang: state.i18n.activeLang,
    fetchingConnectionForm: state.connections.fetchingConnectionForm,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDoorPhoneStatus: (connectionId, doorId, status) => 
      dispatch(ConnectionsActions.connectionDoorPhoneStatusRequest(connectionId, doorId, status)),
    setDoorPhoneAnyCallerStatus: (connectionId, doorId, status) => 
      dispatch(ConnectionsActions.connectionDoorPhoneAnyCallerStatusRequest(connectionId, doorId, status))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhonesTab)

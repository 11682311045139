import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FullScreenLoader from '../../UI/Loaders/FullScreenLoader'
import styles from '../../Styles/Screens/Connection'

export default class Connection extends Component {

  static propTypes = {
    activeConnection: PropTypes.string,
    connection: PropTypes.object,
    header: PropTypes.node.isRequired,
    activeTab: PropTypes.node.isRequired,
    loaderVisible: PropTypes.bool.isRequired,
    onScroll: PropTypes.func.isRequired
  }

  static defaultProps = {
    activeConnection: null,
    connection: null,
    header: null,
    activeTab: null,
    loaderVisible: null,
    onScroll: null
  }

  constructor(props) {
    super(props)
    this.state = {
      visible: true
    }
  }

  componentDidUpdate = (prevProps) => {
    const { activeConnection: prevActiveConnection } = prevProps
    const { activeConnection } = this.props
    if (activeConnection !== prevActiveConnection) {
      this.setState({ visible: false })
      setTimeout(() => this.setState({ visible: true }), 500)
    }
  }

  renderConnection = () => {
    return (
      <>
        { this.props.header }
        { this.props.tabbar }
        <div style={styles.mainBody} onScroll={this.props.onScroll}>
          <div style={{ maxWidth: '550px', flex: 1, overflow: 'visible' }}>
            { this.props.activeTab }
          </div>
        </div>
      </>
    )
  }

  render = () => {
    return (
      <div style={styles.wrapper}>
        {
          this.props.connection &&
          <div style={styles.container}>
            { this.renderConnection() }
            { <FullScreenLoader visible={this.props.loaderVisible}/> }
          </div>
        }
      </div>
    )
  }

}
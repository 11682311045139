import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { capitalizeFirstLetter } from '../../../Lib/StringsUtils'
import { getAllSwitchsValue } from '../../../Lib/ConnectionsUtils'
import RaixerConfig from '../../../Config/RaixerConfig'
import I18n from '../../../i18n/i18n'
import AppText from '../Texts/AppText'
import ListCard from '../ContentCards/ListCard'
import GhostButton from '../Buttons/GhostButton'
import BaseSwitch from '../Switchs/BaseSwitch'
import styles from '../../Styles/UI/Connections/Phones'
import listStyles from '../../Styles/UI/ListItem'
import { Colors, Metrics } from '../../../Themes'

export default class ConnectionDoorPhoneCard extends Component {

  static propTypes = {
    activeLang: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    door: PropTypes.object.isRequired,
    doorName: PropTypes.string.isRequired,
    isUserAdmin: PropTypes.bool.isRequired,
    trialEnded: PropTypes.bool.isRequired,
    goToDoorPhoneNumber: PropTypes.func.isRequired,
    goToDoorAuthorizedPhone: PropTypes.func.isRequired,
    goToDoorPhoneNumberInformation: PropTypes.func.isRequired,
    onPressSharePhone: PropTypes.func.isRequired,
    onCallingEnabledChange: PropTypes.func.isRequired,
    onAnyCallerEnabledChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    activeLang: null,
    fetching: null,
    door: null,
    doorName: null,
    isUserAdmin: null,
    trialEnded: null,
    goToDoorPhoneNumber: null,
    goToDoorAuthorizedPhone: null,
    goToDoorPhoneNumberInformation: null,
    onPressSharePhone: null,
    onCallingEnabledChange: null,
    onAnyCallerEnabledChange: null
  }

  constructor(props) {
    super(props)
    this.state = {
      editing: false
    }
  }

  _getAuthorizedPhone = (phone) => {
    const { 
      _id, authorized, deviceId, doorId, phoneNumber, authorizedFrom, authorizedTo, deleteOnEnd, deleteOnCall, deleteOnCallAfter, alias = '', createdBy,
      repeatDaysOfWeek, repeatEndHour, repeatStartHour, repeatTimezone 
    } = phone
    let type = 'simple'
    if (authorizedFrom && authorizedTo) { type = 'dates' }
    else if (repeatDaysOfWeek !== undefined && repeatDaysOfWeek !== null && repeatEndHour && repeatStartHour && repeatTimezone) { type = 'repeat' }
    return {
      _id,
      deviceId,
      doorId,
      authorized,
      phoneNumber,
      authorizedFrom,
      authorizedTo,
      deleteOnEnd,
      deleteOnCall,
      deleteOnCallAfter,
      alias,
      createdBy: createdBy ? capitalizeFirstLetter(createdBy) : null,
      left: alias !== '' ? alias : '+' + phoneNumber,
      right: authorized ? I18n.t('shared.active') : I18n.t('shared.inactive'),
      dateFrom: authorizedFrom,
      dateTo: authorizedTo,
      type,
      repeatDaysOfWeek, 
      repeatEndHour, 
      repeatStartHour, 
      repeatTimezone 
    }
  }

  getRequiredFields = (callingEnabled, authorizedPhones, anyCallerEnabled) => {
    let phones = []
    try {
      if (callingEnabled && !anyCallerEnabled) { phones = authorizedPhones.map(a => this._getAuthorizedPhone(a)) }
    } catch {
      phones = []
    }
    return { phones }
  }

  toggleEditing = (value) => {
    if (value > 0) { this.setState({ editing: true }) }
    else { this.setState({ editing: false }) }
  }

  render = () => {
    const { _id, anyCallerEnabled, authorizedPhones, callingEnabled, intercomCallFirst, sensorMode, phone } = this.props.door
    const { phoneNumber } = phone
    const { phones } = this.getRequiredFields(callingEnabled, authorizedPhones, anyCallerEnabled)
    const topButtonStyle = this.props.activeLang === 'en' 
      ? Object.assign({}, styles.topButton, styles.topButtonFixedWidth)
      : Object.assign({}, styles.topButton)
    const callingEnabledValue = getAllSwitchsValue(callingEnabled, this.props.fetching)
    let firstItemStyles = [
      listStyles.listItem,
      { 
        paddingTop: Metrics.doubleBaseMargin, 
        marginTop: Metrics.baseMargin, 
        borderTopWidth: Metrics.listItemBorderWidth 
      }
    ]
    if (!callingEnabled) { 
      firstItemStyles.push({
        marginBottom: 0,
        paddingBottom: Metrics.baseMargin,
        borderBottomColor: Colors.transparent
      }) 
    }
    return (
      <ListCard
        title={this.props.doorName}
        list={phones}
        listItemType={'authorized-phones'}
        withEditButton={this.props.isUserAdmin ? true : false}
        onEditItem={(phone) => { this.props.goToDoorAuthorizedPhone(_id, phone) }}
        addButton={callingEnabled && !anyCallerEnabled && this.props.isUserAdmin ? true : false}
        addButtonText={I18n.t('screens.connection.phones.addAuthorizedPhone')}
        onAdd={() => { this.props.goToDoorAuthorizedPhone(_id) }}
        renderExtraContent={
          () => {
            return (
              <div>
                <div style={styles.topTextContainer}>
                  {
                    <div>
                      <AppText
                        text={
                          sensorMode === 'bell' && intercomCallFirst ? 
                          I18n.t('shared.phoneCallActionCallBell') 
                          : 
                          I18n.t('shared.phoneCallAction')
                        }
                        customStyle={styles.topDescriptionText}
                      />
                      <AppText
                        text={'+' + phoneNumber}
                        customStyle={styles.topPhoneText}
                      />
                    </div>
                  }
                </div>
                {
                  !this.state.editing ?
                  <div style={Object.assign({}, styles.topButtonsContainer, { justifyContent: 'center' })}>
                    <GhostButton
                      buttonText={I18n.t('shared.info')}
                      onPress={() => { this.props.goToDoorPhoneNumberInformation(_id, phone) }}
                      disabled={false}
                      icon={'info-with-circle'}
                      customButtonStyle={topButtonStyle}
                    />
                    <GhostButton
                      buttonText={I18n.t('shared.share')}
                      onPress={() => { this.props.onPressSharePhone(phoneNumber) }}
                      disabled={false}
                      icon={'share'}
                      customButtonStyle={Object.assign({}, topButtonStyle, { marginRight: 0 })}
                    />
                  </div>
                  :
                  <div style={styles.topButtonsContainerOnlyMargin}>
                    <GhostButton
                      buttonText={I18n.t('shared.edit')}
                      onPress={() => { 
                        if (this.state.editing) { setTimeout(() => { this.setState({ editing: false }) }, RaixerConfig.delays.toggleEdit) }
                        this.props.goToDoorPhoneNumber(_id, phone) 
                      }}
                      disabled={false}
                      icon={'edit'}
                      customButtonStyle={Object.assign({}, styles.topButton, { marginRight: 0 })}
                    />
                  </div>
                }
                <div style={Object.assign({}, ...firstItemStyles)}>
                  <BaseSwitch
                    label={null}
                    help={I18n.t('screens.connection.phones.callingEnabledLabel')}
                    value={callingEnabledValue}
                    disabled={this.props.fetching || !this.props.isUserAdmin}
                    onChange={(value) => { this.props.onCallingEnabledChange(_id, value) }}
                    withoutMargin={true}
                    withoutVerticalPadding={true}
                    customSwitchRowStyle={styles.switchRow}
                    customLabelStyle={{ marginLeft: 0 }}
                    customHelpStyle={styles.switchHelp}
                  />
                </div>
                {
                  callingEnabled &&
                  <div>
                    <div style={anyCallerEnabled ? Object.assign({}, listStyles.listItem, listStyles.lastListItem) : listStyles.listItem}>
                      <BaseSwitch
                        label={null}
                        help={I18n.t('screens.connection.phones.anyCallerEnabledLabel')}
                        value={anyCallerEnabled ? true : false}
                        disabled={!this.props.isUserAdmin}
                        onChange={(value) => { this.props.onAnyCallerEnabledChange(_id, value) }}
                        withoutMargin={true}
                        withoutVerticalPadding={true}
                        customSwitchRowStyle={styles.switchRow}
                        customHelpStyle={styles.switchHelp}
                      />
                    </div>
                    {
                      !anyCallerEnabled &&
                      <AppText
                        text={I18n.t('screens.connection.phones.authorizedPhonesTitle')}
                        customStyle={styles.independantListItemTitle}
                      />
                    }
                    {
                      phones.length === 0 && !anyCallerEnabled &&
                      <AppText
                        text={I18n.t('screens.connection.phones.authorizedPhonesEmpty')}
                        customStyle={styles.independantListItemContent}
                      />
                    }
                  </div>
                }
              </div>
            )
          }
        }
        publishListItemEditFlexValue={this.toggleEditing}
        editing={this.state.editing}
      />
    )
  }

}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RaixerConfig from '../../../Config/RaixerConfig'
import I18n from '../../../i18n/i18n'
import AppText from '../Texts/AppText'
import LineItemEditButton from '../Buttons/LineItemEditButton'
import SimpleTextButton from '../Buttons/SimpleTextButton'
import { Metrics } from '../../../Themes'
import styles from '../../Styles/UI/ListItem'

export default class LocationListItem extends Component {

  static propTypes = {
    leftText1: PropTypes.string.isRequired,
    leftText2: PropTypes.string.isRequired,
    leftText3: PropTypes.string.isRequired,
    leftText4: PropTypes.string,
    rightText1: PropTypes.string.isRequired,
    rightText2: PropTypes.string.isRequired,
    rightText3: PropTypes.string.isRequired,
    rightText4: PropTypes.string,
    withEditButton: PropTypes.bool.isRequired,
    lastOfList: PropTypes.bool,
    onPressEdit: PropTypes.func,
    editButtonFlex: PropTypes.number,
    onListItemSecondary: PropTypes.func,
    customLeftStyle: PropTypes.object,
    customRightStyle: PropTypes.object,
    customLeftTextStyle: PropTypes.object,
    customRightTextStyle: PropTypes.object
  }

  static defaultProps = {
    leftText1: null,
    leftText2: null,
    leftText3: null,
    leftText4: null,
    rightText1: null,
    rightText2: null,
    rightText3: null,
    rightText4: null,
    withEditButton: null,
    lastOfList: false,
    onPressEdit: () => { console.log() },
    editButtonFlex: 0,
    onListItemSecondary: null,
    customLeftStyle: {},
    customRightStyle: {},
    customLeftTextStyle: {},
    customRightTextStyle: {}
  }

  render = () => {
    const editStyles = Object.assign({}, styles.listItemEditButtonContainer, { flex: this.props.editButtonFlex })
    return (
      <div style={!this.props.lastOfList ? styles.listItem : Object.assign({}, styles.listItem, styles.lastListItem)}>
        <div style={styles.listItemInner}>
          <div style={Object.assign({}, styles.listItemInner, { flex: RaixerConfig.animations.lists.itemFlex })}>
            <div style={Object.assign({}, styles.listItemLeft, this.props.customLeftStyle)}>
              <AppText
                text={this.props.leftText1}
                customStyle={Object.assign({}, styles.listItemTitle, styles.marginBottom, this.props.customLeftTextStyle)}
                numberOfLines={1}
              />
              <AppText
                text={this.props.leftText2}
                customStyle={Object.assign({}, styles.listItemTitle, styles.marginBottom, this.props.customLeftTextStyle)}
                numberOfLines={1}
              />
              {
                this.props.leftText3 !== null &&
                <AppText
                  text={this.props.leftText3}
                  customStyle={Object.assign({}, styles.listItemTitle, styles.marginBottom, this.props.customLeftTextStyle)}
                  numberOfLines={1}
                />
              }
              {
                this.props.leftText4 !== null && 
                <AppText
                  text={this.props.leftText4}
                  customStyle={Object.assign({}, styles.listItemTitle, styles.marginBottom, this.props.customLeftTextStyle)}
                  numberOfLines={1}
                />
              }
            </div>
            <div style={Object.assign({}, styles.listItemRight, this.props.customRightStyle)}>
              <AppText
                text={this.props.rightText1}
                customStyle={Object.assign({}, styles.listItemContent, styles.marginBottom, this.props.customRightTextStyle)}
                numberOfLines={1}
              />
              <AppText
                text={this.props.rightText2}
                customStyle={Object.assign({}, styles.listItemContent, styles.marginBottom, this.props.customRightTextStyle)}
                numberOfLines={1}
              />
              {
                this.props.rightText3 && this.props.onListItemSecondary ?
                <SimpleTextButton
                  buttonText={this.props.rightText3}
                  onPress={this.props.onListItemSecondary}
                  disabled={false}
                  customButtonStyle={{ padding: 0, margin: 0, justifyContent: 'flex-end' }}
                  customTextStyle={Object.assign({}, styles.listItemContent, styles.marginBottom, this.props.customRightTextStyle, styles.listBookingLink)}
                />
                :
                this.props.rightText3 &&
                <AppText
                  text={this.props.rightText3}
                  customStyle={Object.assign({}, styles.listItemContent, styles.marginBottom, this.props.customRightTextStyle)}
                  numberOfLines={1}
                />
              }
              {
                this.props.rightText4 !== null && 
                <AppText
                  text={this.props.rightText4}
                  customStyle={Object.assign({}, styles.listItemContent, styles.marginBottom, this.props.customRightTextStyle)}
                  numberOfLines={1}
                />
              }
            </div>
          </div>
          <div style={editStyles}>
            <LineItemEditButton
              buttonText={I18n.t('shared.edit')}
              onPress={this.props.onPressEdit}
              disabled={false}
              customButtonStyle={{ marginBottom: 0, marginLeft: Metrics.doubleBaseMargin }}
            />
          </div>
        </div>
      </div>
    )
  }

}

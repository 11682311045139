import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from '../../../../i18n/i18n'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import AppTitle from '../../../UI/Texts/AppTitle'
import AppText from '../../../UI/Texts/AppText'
import ConnectionDoorPhoneInformation from '../../../UI/Connections/ConnectionDoorPhoneInformation'
import SmallLoader from '../../../UI/Loaders/SmallLoader'
import styles from '../../../Styles/Screens/Forms'
import { Metrics } from '../../../../Themes'

export default class Information extends Component {

  static propTypes = {
    activeLang: PropTypes.string.isRequired,
    information: PropTypes.array,
    fetching: PropTypes.bool.isRequired
  }

  static defaultProps = {
    activeLang: null,
    information: null,
    fetching: false
  }

  renderContent = () => {
    if (this.props.fetching) { 
      return <SmallLoader visible={true} />
    }
    return (
      <>
        <AppText text={I18n.t('screens.phonesInformation.textFirst')} />
        <div style={styles.emptySpace} />
        {
          this.props.information.length > 0 &&
          <>
            <AppTitle text={I18n.t('screens.phonesInformation.titleSecond')} customStyle={{ marginTop: Metrics.doubleBaseMargin, marginBottom: Metrics.doubleBaseMargin }} />
            <AppText text={I18n.t('screens.phonesInformation.textSecond')} />
            <ConnectionDoorPhoneInformation information={this.props.information} />
          </>
        }
      </>
    )
  }

  render = () => {
    return (
      <ContentCard
        title={I18n.t('screens.phonesInformation.title')}
        renderContent={this.renderContent}
      />
    )
  }

}
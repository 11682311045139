import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ConnectionHeaderNotification from './ConnectionHeaderNotification'
import styles from '../../Styles/UI/Connections/Header'

export default class ConnectionHeaderNotifications extends Component {

  static propTypes = {
    notifications: PropTypes.array.isRequired
  }

  static defaultProps = {
    notifications: null
  }

  render = () => {
    return (
      this.props.notifications.length > 0 &&
      <div style={styles.headerNotificationsContainer}>
        {
          this.props.notifications.map((n, i) => (
            <ConnectionHeaderNotification
              key={'notification-' + i}
              text={n.text}
              action={n.action}
              icon={n.icon}
              iconColor={n.color}
              isLast={(i + 1) === this.props.notifications.length}
            />
          ))
        }
      </div>
    )
  }

}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ConnectionHeaderButton from './ConnectionHeaderButton'
import styles from '../../Styles/UI/Connections/Header'

export default class ConnectionHeaderButtons extends Component {

  static propTypes = {
    buttons: PropTypes.array.isRequired,
    executeQuickActionTask: PropTypes.func.isRequired,
    fetching: PropTypes.bool,
    fetchingTask: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    activeConnectionButton: PropTypes.number
  }

  static defaultProps = {
    buttons: null,
    executeQuickActionTask: null,
    fetching: null,
    fetchingTask: null,
    error: null,
    disabled: null,
    activeConnectionButton: null
  }

  render = () => {
    return (
      this.props.buttons.length > 0 &&
      <div style={styles.headerQuickActionsContainer}>
        {
          this.props.buttons.map((b, i) => (
            <ConnectionHeaderButton
              key={'button-' + i}
              text={b.title}
              icon={b.icon}
              fetching={this.props.fetchingTask}
              error={this.props.error}
              disabled={this.props.fetchingTask || this.props.disabled}
              isActive={this.props.activeConnectionButton === i}
              onPress={() => this.props.executeQuickActionTask(b, i) }
              indicatorText={b.description}
              lastOfList={(i + 1) === this.props.buttons.length}
            />
          ))
        }
      </div>
    )
  }

}
import LocalizedStrings from 'react-localization'
import _ from 'lodash'
import en from './locales/en'
import es from './locales/es'

const I18n = new LocalizedStrings({
  en,
  es, 
 })

export default { 
  ...I18n, 
  currentLocale: () => { return I18n.getLanguage() },
  t: (key) => { return _.get(I18n, key.split('.')) || 'Missing key ' + key }
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AnimatedComponent from '../Animated/AnimatedComponent'
import styles from '../../Styles/UI/Loader'

export default class FullScreenLoader extends Component {

  static propTypes = {
    visible: PropTypes.bool.isRequired
  }

  static defaultProps = {
    visible: null
  }

  render = () => {
    return (
      <div style={Object.assign({}, styles.fullScreenContainer, !this.props.visible ? styles.hidden : {})}>
        <AnimatedComponent source={'loader'} width={150} height={150}/>
      </div>
    )
  }

}

import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loginRequest: ['email', 'password'],
  loginSuccess: ['userId', 'token', 'refreshToken'],
  logoutRequest: null,
  logoutSuccess: null,
  registerRequest: ['displayName', 'email', 'password'],
  facebookRequest: null,
  facebookUpdate: ['fetching'],
  recoverRequest: ['email', 'password'],
  recoverSuccess: null,
  authFinished: null,
  appleLoginCallbackRequest: ['user']
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  userId: null,
  token: null,
  refreshToken: null
})

/* ------------- Selectors ------------- */
export const AuthSelectors = {
  selectUserId: state => state.auth.userId,
  selectToken: state => state.auth.token,
  selectRefreshToken: state => state.auth.refreshToken
}

/* ------------- Reducers ------------- */

export const loginRequest = (state) => {
  return state.merge({ fetching: true })
}

export const loginSuccess = (state, action) => {
  const { userId, token, refreshToken } = action
  return state.merge({ 
    fetching: false, 
    userId, 
    token, 
    refreshToken
  })
}

export const logoutRequest = (state) => {
  return state.merge({ fetching: true })
}

export const logoutSuccess = (state) => {
  return state.merge({ 
    fetching: false, 
    userId: null, 
    token: null, 
    refreshToken: null
  })
}

export const registerRequest = (state) => {
  return state.merge({ fetching: true })
}

export const facebookRequest = (state) => {
  return state.merge({ fetching: false })
}

export const facebookUpdate = (state, action) => {
  const { fetching } = action
  return state.merge({ fetching })
}

export const recoverRequest = (state) => {
  return state.merge({ fetching: true })
}

export const recoverSuccess = (state) => {
  return state.merge({ fetching: false })
}

export const authFinished = (state) => {
  return state.merge({ fetching: false })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGOUT_REQUEST]: logoutRequest,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.REGISTER_REQUEST]: registerRequest,
  [Types.FACEBOOK_REQUEST]: facebookRequest,
  [Types.FACEBOOK_UPDATE]: facebookUpdate,
  [Types.RECOVER_REQUEST]: recoverRequest,
  [Types.RECOVER_SUCCESS]: recoverSuccess,
  [Types.AUTH_FINISHED]: authFinished,
  [Types.APPLE_LOGIN_CALLBACK_REQUEST]: loginRequest
})

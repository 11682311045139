import React, { Component } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { navigationRef/* , navigate, goBack, openDrawer, pop, popToTop */ } from './RootNavigation'

// import TestScreen from '../Containers/Test/TestScreen'
import LoginScreen from '../Containers/Auth/LoginScreen'
import SignupScreen from '../Containers/Auth/SignupScreen'
import RecoverScreen from '../Containers/Auth/RecoverScreen'
import DashboardScreen from '../Containers/Dashboard/DashboardScreen'
import AppleLoginCallbackScreen from '../Containers/Auth/AppleLoginCallbackScreen'

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      { ...rest }
      render={({ location }) => (
        rest.user ?
          children
          :
          <Redirect to={{ pathname: '/', state: { from: location } }} />
      )}
    />
  );
}

class AppNavigation extends Component {

  render = () => {
    return (
      <BrowserRouter ref={navigationRef}>
        <Switch>
          {/* <Route path={'/'} component={TestScreen} /> */}
          <PrivateRoute path='/dashboard' {...this.props}>
            <DashboardScreen />
          </PrivateRoute>
          <Route path='/apple/login/callback' component={AppleLoginCallbackScreen} />
          <Route path='/signup' component={SignupScreen} />
          <Route path='/recover' component={RecoverScreen} />
          <Route exact path='/' component={LoginScreen} />
        </Switch>
      </BrowserRouter>
    )
  }

}

export default AppNavigation
import { combineReducers } from 'redux'
import configureStore from './CreateStore'
import rootSaga from '../Sagas/'

/* ------------- Assemble The Reducers ------------- */
const appReducers = combineReducers({
  startup: require('./StartupRedux').reducer,
  auth: require('./AuthRedux').reducer,
  connections: require('./ConnectionsRedux').reducer,
  user: require('./UserRedux').reducer,
  stripe: require('./StripeRedux').reducer,
  // wizard: require('./WizardRedux').reducer,
  // movements: require('./MovementsRedux').reducer,
  // platformIntegrations: require('./PlatformIntegrationsRedux').reducer,
  // guesty: require('./GuestyRedux').reducer,
  configuration: require('./ConfigurationRedux').reducer,
  i18n: require('./I18nRedux').reducer,
  modal: require('./ModalRedux').reducer
})

export const reducers = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') { state = undefined }
  return appReducers(state, action)
}

const createReduxStore = () => {
  let { store, sagasManager, sagaMiddleware } = configureStore(reducers, rootSaga)

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./').reducers
      store.replaceReducer(nextRootReducer)
      const newYieldedSagas = require('../Sagas').default
      sagasManager.cancel()
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas)
      })
    })
  }

  return store
}

// create our store
const store = createReduxStore()

export default store

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form'
import { emailValidation } from '../../../Lib/FormUtils'
import I18n from '../../../i18n/i18n'
import FullScreenLoader from '../../UI/Loaders/FullScreenLoader'
import FormCard from '../../UI/ContentCards/FormCard'
import SimpleTextButton from '../../UI/Buttons/SimpleTextButton'
import { Images } from '../../../Themes'
import styles from '../../Styles/Screens/Auth'

export default class Recover extends Component {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    goToLogin: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired
  }

  static defaultProps = {
    onSubmit: null,
    goToLogin: null,
    fetching: null
  }

  render = () => {
    return (
      <div style={styles.container}>
        <img src={Images.logo} alt={''} style={styles.logo} />
        <FormCard
          onSubmit={this.props.onSubmit}
          deleteButton={false}
          submitText={I18n.t('screens.auth.recoverPassword')}
          submitIcon={null}
          submitOnEnter={true}
          fields={{
            email: t.refinement(t.String, emailValidation),
            password: t.String
          }}
          fieldsOptions={{
            email: {
              label: I18n.t('shared.email'),
              autoCapitalize: 'none',
              autoCorrect: false,
              keyboardType: 'email-address',
              customTemplate: true
            },
            password: {
              label: I18n.t('shared.newPassword'),
              password: true,
              secureTextEntry: true,
              customTemplate: true,
              type: 'password'
            }
          }}
          renderExtraContentAfterButtons={() => (
            <SimpleTextButton
              buttonText={I18n.t('screens.auth.linkLoginRecover')}
              onPress={this.props.goToLogin}
              disabled={false}
              customButtonStyle={Object.assign(styles.navigationLinksContainerFirst, styles.navigationLinksContainerLast)}
              customTextStyle={styles.navigationLinksText}
            />
          )}
        />
        <FullScreenLoader visible={this.props.fetching}/>
      </div>
    )
  }

}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AppText from '../Texts/AppText'
import styles from '../../Styles/UI/ListItem'
import stylesPhones from '../../Styles/UI/Connections/Phones'

export default class ConnectionDoorPhoneInformationListItem extends Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    phones: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    lastOfList: PropTypes.bool
  }

  static defaultProps = {
    name: null,
    phones: null,
    index: null,
    lastOfList: false
  }

  render = () => {
    let nameStyle = [stylesPhones.phoneInformationNameText, stylesPhones.phoneInformationText]
    if (!this.props.phones || this.props.phones.length === 0) { nameStyle.push({ marginBottom: 0 }) }
    return (
      <div style={!this.props.lastOfList ? styles.listItem : Object.assign({}, styles.listItem, styles.lastListItem)}>
        <AppText text={this.props.name} customStyle={Object.assign({}, ...nameStyle)}/>
        {
          this.props.phones.map(
            (a, i) => (
              <AppText 
                key={'authorized-phone-' + this.props.index + '-' + i} 
                text={'+' + a} 
                customStyle={stylesPhones.phoneInformationText} 
              />
            )
          )
        }
      </div>
    )
  }

}

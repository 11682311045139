import React, { Component } from 'react'
import styles from '../../Styles/Screens/Dashboard'

export default class Dashboard extends Component {

  render = () => {
    return (
      <div style={styles.container}>
        { this.props.children }
      </div>
    )
  }

}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import I18n from '../../../i18n/i18n'
import { ModalService } from '../../../Services/Modal'
import { randomDigits } from '../../../Lib/MathUtils'
import Config from '../../../Config/RaixerConfig'
import DoorCode from '../../../Components/Screens/Connection/Codes/DoorCode'

class DoorCodeScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      doorId: null,
      isEdit: false,
      alias: '',
      url: null
    }
  }

  componentDidMount = () => {
    const { connectionId, doorId, isEdit, doorName, alias, url } = this.props || {}
    this.setState({ connectionId, doorId, isEdit, doorName, alias, url, initialUrl: url })
  }

  generateLink = () => {
    this.setState({ url: `${Config.api.baseUrl.replace('https://', '')}${randomDigits(8)}` })
  }

  getDoorCode = (alias, url) => {
    if (!this.state.isEdit) {
      ModalService.show(
        I18n.t('modals.doorCodePossibleAnswers.title'),
        `${I18n.t('modals.doorCodePossibleAnswers.text')} ${this.state.doorName}`,
        () => {
          this.setState({ url })
          this.props.getDoorCode(this.state.connectionId, this.state.doorId, alias, url)
        },
        null,
        null,
        true
      )
    } else {
      if (this.state.initialUrl !== url) {
        ModalService.show(
          I18n.t('modals.doorCodePossibleAnswers.title'),
          `${I18n.t('modals.doorCodePossibleAnswers.textEdit')} ${this.state.doorName}`,
          () => {
            this.setState({ url })
            this.props.getDoorCode(this.state.connectionId, this.state.doorId, alias, url)
          },
          null,
          null,
          true
        )
      } else {
        this.setState({ url })
        this.props.getDoorCode(this.state.connectionId, this.state.doorId, alias, url)
      }
    }
  }

  deleteDoorCode = () => {
    ModalService.show(
      I18n.t('modals.doorCodeRemove.title'),
      I18n.t('modals.doorCodeRemove.text'),
      () => { this.props.deleteDoorCode(this.state.connectionId, this.state.doorId) },
      I18n.t('modals.doorCodeRemove.buttons.ok'),
      null,
      true
    )
  }

  render = () => {
    return (
      <DoorCode
        isEdit={this.state.isEdit}
        doorName={this.state.doorName}
        alias={this.state.alias}
        fetching={this.props.fetching}
        getDoorCode={this.getDoorCode}
        deleteDoorCode={this.deleteDoorCode}
        generateLink={this.generateLink}
        urlText={this.state.url || `${Config.api.baseUrl.replace('https://', '')}${randomDigits(8)}`}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    fetching: state.connections.fetchingConnectionForm
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDoorCode: (connectionId, doorId, alias, url) =>
      dispatch(ConnectionsActions.connectionDoorCodeRequest(connectionId, doorId, alias, url)),
    deleteDoorCode: (connectionId, doorId) =>
      dispatch(ConnectionsActions.connectionDoorCodeDeleteRequest(connectionId, doorId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoorCodeScreen)

export const roundTo2Decimals = (num, places = 2) => {
  const multiplicator = Math.pow(10, places)
  let n = parseFloat((num * multiplicator).toFixed(11))
  n = (Math.round(n) / multiplicator).toFixed(places)
  return n
}

export const randomDigits = (length) => {
  return Math.random().toString(36).substr(2, length)
}

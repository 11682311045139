import React, { Component } from 'react'
import { connect } from 'react-redux'
import BaseModal from '../../Components/UI/Modals/BaseModal'
import styles from '../../Components/Styles/UI/Modal'
import { ModalService } from '../../Services/Modal'

class ModalScreen extends Component {

  constructor(props) {
    super(props)
    this.modalContainer = React.createRef()
  }

  componentDidUpdate = (prevProps) => {
    const { visible: prevVisible } = prevProps
    const { visible } = this.props
    if (!prevVisible && visible) {
      setTimeout(() => { this.modalContainer.current.focus() }, 100)
    }
  }

  cancelAction = () => { 
    if (this.props.cancelAction) { this.props.cancelAction() }
    else { ModalService.hide() }
  }

  confirmAction = () => {
    if (this.props.confirmAction) { this.props.confirmAction() }
    if (this.props.dismissOnConfirm) { ModalService.hide() }
  }

  onKeyPressed = (event) => {
    if (this.props.submitOnEnter) {
      if (event.keyCode === 13) {
        this.confirmAction()
      } else if (event.keyCode === 27) {
        this.cancelAction()
      }
    }
  }

  render = () => {
    return (
      <div
        tabIndex={'0'}
        ref={this.modalContainer}
        style={Object.assign({}, styles.modalContainer, this.props.visible ? {} : styles.hidden)}
        onKeyDown={this.onKeyPressed}
      >
        <BaseModal
          title={this.props.title}
          text={this.props.text}
          confirmText={this.props.confirmText}
          cancelText={this.props.cancelText}
          cancelAction={this.cancelAction}
          confirmAction={this.props.confirmAction ? this.confirmAction : null}
          withoutCancelButton={this.props.withoutCancelButton}
          confirmAsGhost={this.props.confirmAsGhost}
          fetching={this.props.fetchingConnectionOther && !this.props.withoutFetching}
          submitOnEnter={this.props.submitOnEnter}
        />
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    visible: state.modal.visible,
    title: state.modal.title,
    text: state.modal.text,
    confirmText: state.modal.confirmText,
    cancelText: state.modal.cancelText,
    cancelAction: state.modal.cancelAction,
    confirmAction: state.modal.confirmAction,
    withoutCancelButton: state.modal.withoutCancelButton,
    confirmAsGhost: state.modal.confirmAsGhost,
    dismissOnConfirm: state.modal.dismissOnConfirm,
    withoutFetching: state.modal.withoutFetching,
    submitOnEnter: state.modal.submitOnEnter,
    fetchingConnectionOther: state.connections.fetchingConnectionOther
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalScreen)
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from '../../../i18n/i18n'
import TabbarButton from '../Buttons/TabbarButton'
import { Colors, Metrics } from '../../../Themes'
import styles from '../../Styles/UI/Connections/Tabbar'

export default class ConnectionTabbar extends Component {

  static propTypes = {
    connection: PropTypes.object,
    isUserAdmin: PropTypes.bool.isRequired,
    selectedTab: PropTypes.string.isRequired,
    onItemPress: PropTypes.func.isRequired,
    hasAutoAccessDoor: PropTypes.bool,
    hasUsableDoors: PropTypes.bool
  }

  static defaultProps = {
    connection: null,
    isUserAdmin: null,
    selectedTab: null,
    onItemPress: null,
    hasAutoAccessDoor: null,
    hasUsableDoors: null
  }

  render() {
    const { disabled = false } = this.props.connection || {}
    const tabs = [
      { text: I18n.t('shared.autoAccess'), icon: 'bell', key: 'auto-access' },
      { text: I18n.t('shared.phone'), icon: 'phone', key: 'phone' },
      { text: I18n.t('shared.codes'), icon: 'link', key: 'codes' },
      { text: I18n.t('shared.users'), icon: 'man', key: 'users' },
      { text: I18n.t('shared.history'), icon: 'back-in-time', key: 'history' }
    ]
    let containerStyle = [styles.container]
    if (!this.props.hasUsableDoors || !this.props.isUserAdmin) { containerStyle.push({ justifyContent: 'space-around' }) }
    return (
      <div style={styles.wrapper}>
        <div style={{ maxWidth: '550px', flex: 1, overflow: 'visible' }}>
          <div style={Object.assign({}, ...containerStyle)}>
            {
              tabs.map((t) => {
                if (t.key === 'auto-access' && (!this.props.hasAutoAccessDoor || !this.props.isUserAdmin)) { return null }
                if (t.key === 'phone' && (!this.props.hasUsableDoors || !this.props.isUserAdmin)) { return null }
                if (t.key === 'codes' && (!this.props.hasUsableDoors || !this.props.isUserAdmin)) { return null }
                return (
                  <TabbarButton
                    key={'tab-' + t.key}
                    active={this.props.selectedTab === t.key}
                    buttonText={t.text}
                    onPress={() => { this.props.onItemPress(t.key) }}
                    disabled={disabled}
                    text={t.text}
                    icon={t.icon}
                    iconSize={Metrics.icons.big}
                    iconColor={this.props.selectedTab === t.key ? Colors.theme1 : Colors.mediumGray}
                    customButtonStyle={styles.item}
                    customIconStyle={styles.icon}
                    customTextStyle={this.props.selectedTab === t.key
                      ? Object.assign({}, styles.text, styles.textSelected)
                      : Object.assign({}, styles.text)}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, SvgIcon } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import Wifi from '@material-ui/icons/Wifi'
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt'
import BatteryCharging50Icon from '@material-ui/icons/BatteryCharging50'
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull'
import CellWifiIcon from '@material-ui/icons/CellWifi'
import WarningIcon from '@material-ui/icons/Warning'
import LoopIcon from '@material-ui/icons/Loop'
import RocketPath from '../../../Images/Icons/rocket'
import LifeBuoyPath from '../../../Images/Icons/life-bouy'
import AppText from '../Texts/AppText'
import styles from '../../Styles/UI/Connections/Header'
import stylesButton from '../../Styles/UI/Button'
import { Colors, Metrics } from '../../../Themes'

export default class ConnectionHeaderNotification extends Component {

  static propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    iconColor: PropTypes.string.isRequired,
    action: PropTypes.func,
    isLast: PropTypes.bool
  }

  static defaultProps = {
    text: null,
    icon: null,
    iconColor: null,
    action: null,
    isLast: false
  }

  render = () => {
    const iconStyle = { color: this.props.iconColor, fontSize: Metrics.icons.bigMedium }
    const svgIconStyle = Object.assign(
      {},
      iconStyle,
      { position: 'relative', top: Metrics.svgIconPositionTop, left: Metrics.svgIconPositionLeft }
    )
    return (
      <Button
        onClick={this.props.action ? this.props.action : () => {}}
        disabled={false}
        variant={'contained'}
        color={'default'}
        style={Object.assign({}, stylesButton.buttonContainer, styles.headerNotificationContainer, this.props.isLast ? { marginBottom: 0 } : {})}
      >
        <div style={Object.assign({}, styles.headerNotificationSection, { flex: 1, justifyContent: 'flex-start' })}>
          { this.props.icon === 'signal' && <Wifi style={iconStyle} /> }
          { this.props.icon === 'landline' && <CellWifiIcon style={iconStyle} /> }
          { this.props.icon === 'bar-graph' && <SignalCellularAltIcon style={iconStyle} /> }
          { this.props.icon === 'progress-two' && <BatteryCharging50Icon style={iconStyle} /> }
          { this.props.icon === 'battery' && <BatteryChargingFullIcon style={iconStyle} /> }
          { this.props.icon === 'rocket' && <SvgIcon style={svgIconStyle}>{RocketPath}</SvgIcon> }
          { this.props.icon === 'warning' && <WarningIcon style={iconStyle} /> }
          { this.props.icon === 'cycle' && <LoopIcon style={iconStyle} /> }
          { this.props.icon === 'lifebuoy' && <SvgIcon style={svgIconStyle}>{LifeBuoyPath}</SvgIcon> }
        </div>
        <div style={Object.assign({}, styles.headerNotificationSection, { flex: 23, justifyContent: 'flex-start' })}>
          <AppText 
            text={this.props.text} 
            numberOfLines={3}
            customStyle={styles.headerNotificationText}
          />
        </div>
        <div style={Object.assign({}, styles.headerNotificationSection, { flex: 1 })}>
          {
            this.props.action &&
            <InfoIcon style={{ color: Colors.facebook, fontSize: Metrics.icons.bigMedium }} />
          }
        </div>
      </Button>
    )
  }

}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AppleLogin from 'react-apple-login'
import { Colors, Metrics, Shadows } from '../../../Themes'

export default class AppleLoginButton extends Component {

  static propTypes = {
    buttonText: PropTypes.string,
    customButtonStyle: PropTypes.object
  }

  static defaultProps = {
    buttonText: null,
    customButtonStyle: {}
  }

  render = () => {
    return (
      <AppleLogin
        clientId={'app.raixer.com'}
        redirectURI={'https://api.raixer.com/auth/apple/web'}
        scope={'email name'}
        responseMode={'form_post'}
        responseType={'id_token code'}
        designProp={{
          height: 36,
          width: 200,
          color: 'white'
        }}
        render={renderProps => (
          <button style={{
            fontSize: "16px",
            width: '100%',
            minHeight: 44,
            margin: Metrics.baseMargin + ' 0',
            background: Colors.white,
            border: '1px solid #000',
            cursor: 'pointer',
            borderRadius: Metrics.buttonRadius,
            ...Shadows.appleButton,
            padding: '8px 16px',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
            onClick={renderProps.onClick}
          >
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="18px" height="18px" style={{marginRight: 4}}>    <path d="M 15.904297 1.078125 C 15.843359 1.06875 15.774219 1.0746094 15.699219 1.0996094 C 14.699219 1.2996094 13.600391 1.8996094 12.900391 2.5996094 C 12.300391 3.1996094 11.800781 4.1996094 11.800781 5.0996094 C 11.800781 5.2996094 11.999219 5.5 12.199219 5.5 C 13.299219 5.4 14.399609 4.7996094 15.099609 4.0996094 C 15.699609 3.2996094 16.199219 2.4 16.199219 1.5 C 16.199219 1.275 16.087109 1.10625 15.904297 1.078125 z M 16.199219 5.4003906 C 14.399219 5.4003906 13.600391 6.5 12.400391 6.5 C 11.100391 6.5 9.9003906 5.5 8.4003906 5.5 C 6.3003906 5.5 3.0996094 7.4996094 3.0996094 12.099609 C 2.9996094 16.299609 6.8 21 9 21 C 10.3 21 10.600391 20.199219 12.400391 20.199219 C 14.200391 20.199219 14.600391 21 15.900391 21 C 17.400391 21 18.500391 19.399609 19.400391 18.099609 C 19.800391 17.399609 20.100391 17.000391 20.400391 16.400391 C 20.600391 16.000391 20.4 15.600391 20 15.400391 C 17.4 14.100391 16.900781 9.9003906 19.800781 8.4003906 C 20.300781 8.1003906 20.4 7.4992188 20 7.1992188 C 18.9 6.1992187 17.299219 5.4003906 16.199219 5.4003906 z"/></svg>
            {this.props.buttonText}
          </button>
        )}
      />
    )
  }

}

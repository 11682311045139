import React, { Component } from 'react'
import { connect } from 'react-redux'
import { connectionHasMultipleAutoAccessDoors, getActualConnection } from '../../../Lib/ConnectionsUtils'
import { getMinutesBetweenDatetimes, getUTCFromDatetime } from '../../../Lib/DateTimeUtils'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import I18n from '../../../i18n/i18n'
import ScheduleForm from '../../../Components/Screens/Connection/Forms/ScheduleForm'
import { ModalService } from '../../../Services/Modal'

class ScheduleFormScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      schedule: null
    }
  }

  componentDidMount = () => {
    const { connectionId, schedule } = this.props
    this.setState({ connectionId, schedule })
  }

  saveSchedule = (schedule) => {
    const { _id, campaignId, doorPort, startDate, startTime, endDate, endTime, createdBy = null } = schedule
    const s = {
      _id,
      campaignId,
      doorPort,
      schedule: getUTCFromDatetime(startDate, startTime), 
      time: getMinutesBetweenDatetimes(startDate, startTime, endDate, endTime)
    }
    createdBy !== null && (s.createdBy = createdBy)
    this.props.saveSchedule(this.state.connectionId, s)
  }

  deleteSchedule = (schedule) => {
    ModalService.show(
      I18n.t('modals.deleteSchedule.title'), 
      I18n.t('modals.deleteSchedule.text'), 
      () => { this.props.deleteSchedule(this.state.connectionId, schedule) }, 
      I18n.t('modals.deleteSchedule.buttons.ok'),
      null,
      true
    )
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    const hasMultipleAutoAccessDoors = connectionHasMultipleAutoAccessDoors(connection)
    return (
      <ScheduleForm
        activeLang={this.props.activeLang}
        connection={connection}
        fetching={this.props.fetching}
        schedule={this.state.schedule}
        onSubmit={this.saveSchedule}
        onDelete={this.deleteSchedule}
        hasMultipleAutoAccessDoors={hasMultipleAutoAccessDoors}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    activeLang: state.i18n.activeLang,
    fetching: state.connections.fetchingConnectionForm,
    error: state.connections.errorConnection
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveSchedule: (connectionId, schedule) => dispatch(ConnectionsActions.connectionScheduleSaveRequest(connectionId, schedule)),
    deleteSchedule: (connectionId, schedule) => dispatch(ConnectionsActions.connectionScheduleDeleteRequest(connectionId, schedule))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleFormScreen)
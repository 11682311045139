import React, { Component } from 'react'
import { connect } from 'react-redux'
import { 
  connectionHasMultiplePhoneDoors,
  getActualConnection, 
  getConnectionDoorByPort } from '../../../Lib/ConnectionsUtils'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import I18n from '../../../i18n/i18n'
import AuthorizedPhoneForm from '../../../Components/Screens/Connection/Forms/AuthorizedPhoneForm'
import { ModalService } from '../../../Services/Modal'

class AuthorizedPhoneFormScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      doorId: null,
      phone: null,
      submitButtonDisabled: false
    }
  }

  componentDidMount = () => {
    const { connectionId, doorId, phone } = this.props || {}
    const connection = getActualConnection(this.props.connections, connectionId)
    let submitButtonDisabled = false
    if (phone) {
      try {
        const { device = {} } = connection
        const { doors = [], trialEnded = false } = device
        const door = doors.filter(d => d._id === doorId)[0]
        const { authorizedPhones = [] } = door
        const phonePosition = authorizedPhones.findIndex(d => d._id === phone._id)
        if (trialEnded && phonePosition > 0) { submitButtonDisabled = true }
      } catch {
        submitButtonDisabled = false
      }
    }
    this.setState({ connectionId, doorId, phone, submitButtonDisabled })
  }

  savePhone = (phone) => {
    const { 
      _id, phone: phoneNumber, doorPort, authorizedFrom, authorizedTo, authorized, deleteOnEnd, deleteOnCall, deleteOnCallAfter, alias,
      repeatDaysOfWeek, repeatEndHour, repeatStartHour, repeatTimezone
    } = phone
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    const { device } = connection
    const { _id: deviceId } = device
    let phones = []
    for (let port of doorPort.split(',')) {
      const door = getConnectionDoorByPort(connection, port)
      const { _id: doorId } = door
      let p = {
        phoneNumber: parseInt(phoneNumber),
        deviceId,
        doorId,
        authorized,
        deleteOnEnd,
        deleteOnCall,
        deleteOnCallAfter,
        alias
      }
      if (_id) { p._id = _id }
      if (authorizedFrom && authorizedTo) {
        p.authorizedFrom = authorizedFrom
        p.authorizedTo = authorizedTo
        p.repeatDaysOfWeek = null
        p.repeatEndHour = null
        p.repeatStartHour = null
        p.repeatTimezone = null
      }
      if (repeatDaysOfWeek !== undefined && repeatDaysOfWeek !== null && repeatEndHour && repeatStartHour && repeatTimezone) {
        p.authorizedFrom = null
        p.authorizedTo = null
        p.repeatDaysOfWeek = repeatDaysOfWeek
        p.repeatEndHour = repeatEndHour
        p.repeatStartHour = repeatStartHour
        p.repeatTimezone = repeatTimezone
      }
      phones.push(p)
    }
    this.props.savePhone(this.state.connectionId, phones)
  }

  deletePhone = (phone) => {
    ModalService.show(
      I18n.t('modals.deleteAuthorizedPhone.title'), 
      I18n.t('modals.deleteAuthorizedPhone.text'), 
      () => { this.props.deletePhone(this.state.connectionId, phone) }, 
      I18n.t('modals.deleteAuthorizedPhone.buttons.ok'),
      null,
      true
    )
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    const hasMultiplePhoneDoors = connectionHasMultiplePhoneDoors(connection)
    return (
      <AuthorizedPhoneForm
        activeLang={this.props.activeLang}
        connection={connection}
        phone={this.state.phone}
        doorId={this.state.doorId}
        hasMultiplePhoneDoors={hasMultiplePhoneDoors}
        fetching={this.props.fetching}
        submitButtonDisabled={this.state.submitButtonDisabled}
        onSubmit={this.savePhone}
        onDelete={this.deletePhone}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    activeLang: state.i18n.activeLang,
    fetching: state.connections.fetchingConnectionForm,
    error: state.connections.errorConnection
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    savePhone: (connectionId, phones) => dispatch(ConnectionsActions.connectionAuthorizedPhoneSaveRequest(connectionId, phones)),
    deletePhone: (connectionId, phone) => dispatch(ConnectionsActions.connectionAuthorizedPhoneDeleteRequest(connectionId, phone))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedPhoneFormScreen)
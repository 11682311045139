import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import I18n from '../i18n/i18n'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  showRequest: [
    'title',
    'text',
    'confirmAction',
    'confirmText',
    'cancelText',
    'dismissOnConfirm',
    'withoutCancelButton',
    'confirmAsGhost',
    'withoutFetching',
    'cancelAction',
    'submitOnEnter'
  ],
  hideRequest: null,
  clearRequest: null,
  formShowRequest: ['formType', 'formParams'],
  formReplaceWithHistoryRequest: ['formType', 'formParams'],
  formHideRequest: null
})

export const ModalTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  visible: false,
  title: null,
  text: null,
  confirmText: I18n.t('modals.buttons.defaultOk'),
  cancelText: I18n.t('modals.buttons.defaultCancel'),
  cancelAction: null,
  confirmAction: null,
  withoutCancelButton: null,
  confirmAsGhost: null,
  dismissOnConfirm: null,
  withoutFetching: null,
  submitOnEnter: false,
  formVisible: null,
  formType: null,
  formParams: null,
  formHistoryParams: null
})

/* ------------- Selectors ------------- */
export const ModalSelectors = {
  selectVisible: state => state.modal.visible
}

/* ------------- Reducers ------------- */

export const showRequest = (state, action) => {
  const { 
    title = null,
    text = null,
    confirmText = null,
    cancelText = null,
    cancelAction = null,
    confirmAction = null,
    withoutCancelButton = null,
    confirmAsGhost = null,
    dismissOnConfirm = null,
    withoutFetching = null,
    submitOnEnter = false
  } = action
  return state.merge({
    visible: true,
    title,
    text,
    confirmText: confirmText || I18n.t('modals.buttons.defaultOk'),
    cancelText: cancelText || I18n.t('modals.buttons.defaultCancel'),
    cancelAction,
    confirmAction,
    withoutCancelButton,
    confirmAsGhost,
    dismissOnConfirm: dismissOnConfirm === false ? dismissOnConfirm : true,
    withoutFetching,
    submitOnEnter
  })
}

export const hideRequest = (state) => {
  return state.merge({ 
    visible: false
  })
}

export const clearRequest = (state) => {
  return state.merge({ 
    visible: false,
    title: null,
    text: null,
    confirmText: I18n.t('modals.buttons.defaultOk'),
    cancelText: I18n.t('modals.buttons.defaultCancel'),
    cancelAction: null,
    confirmAction: null,
    withoutCancelButton: null,
    confirmAsGhost: null,
    dismissOnConfirm: null,
    withoutFetching: null,
    submitOnEnter: false
  })
}

export const formShowRequest = (state, action) => {
  const { 
    formType,
    formParams
  } = action
  return state.merge({
    formVisible: true,
    formType,
    formParams,
    formHistoryParams: null
  })
}

export const formReplaceWithHistoryRequest = (state, action) => {
  const { 
    formType,
    formParams
  } = action
  return state.merge({
    formType,
    formParams,
    formHistoryParams: state.formParams
  })
}

export const formHideRequest = (state) => {
  return state.merge({ 
    formVisible: false,
    formType: null,
    formParams: null,
    formHistoryParams: null
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_REQUEST]: showRequest,
  [Types.HIDE_REQUEST]: hideRequest,
  [Types.CLEAR_REQUEST]: clearRequest,
  [Types.FORM_SHOW_REQUEST]: formShowRequest,
  [Types.FORM_REPLACE_WITH_HISTORY_REQUEST]: formReplaceWithHistoryRequest,
  [Types.FORM_HIDE_REQUEST]: formHideRequest
})

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  connectionHasAutoAccessDoor,
  connectionIsWifiDevice,
  connectionIsFreeVersionDevice,
  getConnectionMaxOpenings,
  // getConnectionNotifications,
  getConnectionSignal,
  getConnectionBattery,
  getConnectionDeviceFirmwareDescription,
  userHasConnectionsAvailableForCheckin,
  connectionIsMiniWithGyroDoor,
  connectionIsProWithGyroDoor, getConnectionMiniGyroBattery
} from '../../../Lib/ConnectionsUtils'
import { getConnectionNotifications } from '../../../Lib/Connections/HeaderNotifications'
import {
  getRemainingDays,
  getRemainingDaysUnpaidSince,
  timestampToDateTime,
  getDatetimeInCurrentTimezoneAsString
} from '../../../Lib/DateTimeUtils'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import I18n from '../../../i18n/i18n'
import RaixerConfig from '../../../Config/RaixerConfig'
import ConnectionHeaderNotifications from '../../../Components/UI/Connections/ConnectionHeaderNotifications'
import { ModalService } from '../../../Services/Modal'
import { FormModalService } from '../../../Services/FormModal'

class HeaderNotifications extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired
  }

  static defaultProps = {
    connection: null
  }

  getConnection = () => {
    const { _id } = this.props.connection
    this.props.getConnection(_id)
  }

  goToConnectionUpgrade = (paymentFailing = false) => {
    const { _id, device } = this.props.connection
    const { campaign } = device || {}
    const { subscription } = campaign || {}
    const isWifiDevice = connectionIsWifiDevice(this.props.connection)
    let plan = { type: isWifiDevice ? 'intercom' : 'access' }
    if (paymentFailing && subscription) { 
      const { _id: subscriptionId, subscriptionPlan, stripeSubscription } = subscription || {}
      const { _id: subscriptionPlanId, billingCycle, name, price, stripePlanId } = subscriptionPlan || {}
      plan = {
        _id: subscriptionId,
        billingCycle,
        name,
        price,
        stripeSubscription,
        subscriptionPlanId,
        selected: stripePlanId,
        type: isWifiDevice ? 'intercom' : 'access'
      }
    }
    FormModalService.open('upgrade', { connectionId: _id, plan })
  }

  getDeviceVitals = (type) => {
    const { _id } = this.props.connection
    this.props.getConnectionVitals(_id, type)
  }

  showModalUnlockFeatures = () => {
    ModalService.show(
      I18n.t('modals.unlockFeatures.title'), 
      I18n.t('modals.unlockFeatures.text'),
      this.goToConnectionUpgrade,
      I18n.t('modals.unlockFeatures.buttons.ok')
    )
  }

  showModalCantUse = () => {
    ModalService.show(
      I18n.t('modals.cantUse.title'), 
      I18n.t('modals.cantUse.text'),
      this.goToConnectionUpgrade,
      I18n.t('modals.cantUse.buttons.ok')
    )
  }

  showModalTrial = () => {
    const isFreeVersionDevice = connectionIsFreeVersionDevice(this.props.connection)
    const { appShowFreeProfile = false } = this.props.configuration || {}
    const text = appShowFreeProfile && isFreeVersionDevice ? I18n.t('modals.trialRunning.textFreeVersion') : I18n.t('modals.trialRunning.text')
    ModalService.show(
      I18n.t('modals.trialRunning.title'), 
      text,
      this.goToConnectionUpgrade,
      I18n.t('modals.trialRunning.buttons.ok')
    )
  }

  showModalTrialNotOwner = () => {
    const isFreeVersionDevice = connectionIsFreeVersionDevice(this.props.connection)
    const { appShowFreeProfile = false } = this.props.configuration || {}
    const text = appShowFreeProfile && isFreeVersionDevice ? I18n.t('modals.trialRunningNotOwner.textFreeVersion') : I18n.t('modals.trialRunningNotOwner.text')
    ModalService.show(
      I18n.t('modals.trialRunningNotOwner.title'), 
      text
    )
  }

  showModalDeviceOffline = () => {
    const { device = {} } = this.props.connection || {}
    const { offlineDate } = device
    let title = I18n.t('modals.deviceOfflineIntercom.title')
    let text = I18n.t('modals.deviceOfflineIntercom.text')
    let action = () => {
      FormModalService.open('setup-device-wifi')
    }
    let okText = I18n.t('modals.deviceOfflineIntercom.buttons.ok')
    if (!connectionIsWifiDevice(this.props.connection)) {
      title = I18n.t('modals.deviceOfflineAccess.title') 
      text = I18n.t('modals.deviceOfflineAccess.text')
      action = null
      okText = null
    }
    if (offlineDate) {
      text += '\n\n' + I18n.t('shared.wentOffline') + '\n' + timestampToDateTime(offlineDate)
    }
    ModalService.show(title, text, action, okText)
  }

  showModalOfflineAutoAccess = () => {
    const maxOpenings = getConnectionMaxOpenings(this.props.connection)
    ModalService.show(
      I18n.t('modals.offlineAutoAccess.title'), 
      I18n.t('modals.offlineAutoAccess.textFirst') + maxOpenings + I18n.t('modals.offlineAutoAccess.textSecond')
    )
  }

  showModalUpgradeNotOwner = () => {
    ModalService.show(
      I18n.t('modals.unlockFeaturesNotOwner.title'), 
      I18n.t('modals.unlockFeaturesNotOwner.text')
    )
  }

  showModalDeviceVitalsSignal = () => {
    const { strength, quality } = getConnectionSignal(this.props.connection)
    let title = I18n.t('modals.deviceVitals.title')
    let text = I18n.t('modals.deviceVitals.textFirst') + quality + '%'
    if (!connectionIsWifiDevice(this.props.connection)) { title = I18n.t('modals.deviceVitals.titleAlt') }
    if (strength) { text += '\n' + I18n.t('modals.deviceVitals.textSecond') + strength + '%' }
    ModalService.show(
      title, 
      text,
      () => { this.getDeviceVitals('signal') }, 
      I18n.t('modals.deviceVitals.buttons.ok'),
      null,
      false
    )
  }

  showModalDeviceVitalsBattery = () => {
    const battery = getConnectionBattery(this.props.connection)
    const { charge, state, updatedAt } = battery
    const parsedCharge = parseInt(charge)
    const title = I18n.t('modals.deviceVitalsBattery.title')
    let textDate = I18n.t('screens.connection.warnings.battery.lastUpdatedAt') + ' ' + getDatetimeInCurrentTimezoneAsString(updatedAt, true)
    let textCharge = !isNaN(parsedCharge) ? parsedCharge + '%' : '-'
    let textState = I18n.t('screens.connection.warnings.battery.disconnected').toLowerCase()
    if (state === 'discharging') { textState = I18n.t('screens.connection.warnings.battery.discharging').toLowerCase() }
    else if (state === 'charging') { textState = I18n.t('screens.connection.warnings.battery.charging').toLowerCase() }
    else if (state === 'charged') { textState = I18n.t('screens.connection.warnings.battery.charged').toLowerCase() }
    ModalService.show(
      title, 
      I18n.t('modals.deviceVitalsBattery.textFirst') + textCharge + '\n' + I18n.t('modals.deviceVitalsBattery.textSecond') + textState + '\n' + textDate,
      () => { this.getDeviceVitals('battery') }, 
      I18n.t('modals.deviceVitalsBattery.buttons.ok'),
      null,
      false
    )
  }

  showModalOpenClosedBlocked = () => {
    const { _id } = this.props.connection
    ModalService.show(
      I18n.t('modals.openClosedBlockedByDevice.title'), 
      I18n.t('modals.openClosedBlockedByDevice.text'),
      () => { this.props.connectionUnblockBehaviours(_id, { openClosed: true }) }, 
      I18n.t('modals.openClosedBlockedByDevice.buttons.ok'),
      null,
      true
    )
  }

  showModalAutoAccessBlocked = () => {
    const { _id } = this.props.connection
    ModalService.show(
      I18n.t('modals.autoAccessBlockedByDevice.title'), 
      I18n.t('modals.autoAccessBlockedByDevice.text'),
      () => { this.props.connectionUnblockBehaviours(_id, { autoAccess: true }) }, 
      I18n.t('modals.autoAccessBlockedByDevice.buttons.ok'),
      null,
      true
    )
  }

  showModalFirmwareUpdate = () => {
    const { _id, device } = this.props.connection || {}
    const { firmwareDescription = '' } = device
    let text = ''
    if (firmwareDescription !== '') { text += getConnectionDeviceFirmwareDescription(firmwareDescription, this.props.activeLang) }
    text += I18n.t('modals.firmwareUpdateAvailable.text')
    ModalService.show(
      I18n.t('modals.firmwareUpdateAvailable.title'), 
      text,
      () => { this.props.connectionDeviceUpdateFirmware(_id) }, 
      I18n.t('modals.firmwareUpdateAvailable.buttons.ok'),
      I18n.t('modals.firmwareUpdateAvailable.buttons.cancel'),
      false
    )
  }

  showModalFirmwareUpdateNotOwner = () => {
    const { device, users = [] } = this.props.connection || {}
    const { owner } = device
    const usersFiltered = users.filter(u => u.owner !== this.props.userId && u.profile === 'admin')
    let text = I18n.t('modals.firmwareUpdateAvailableNotOwner.text')
    let i = 0
    for (let u of usersFiltered) {
      const { profile, ownerName, owner: userId } = u
      text += ownerName + ' - '
      if (userId === owner) { text += I18n.t('shared.owner') }
      else if (profile === 'admin') { text += I18n.t('shared.admin') }
      if (i < usersFiltered.length - 1) { text += '\n' }
      i++
    }
    ModalService.show(
      I18n.t('modals.firmwareUpdateAvailableNotOwner.title'), 
      text,
      null,
      null,
      I18n.t('modals.buttons.defaultOk')
    )
  }

  showModalNukiBatteryCritical = () => {
    ModalService.show(
      I18n.t('modals.nukiBatteryCritical.title'), 
      I18n.t('modals.nukiBatteryCritical.text'),
      null,
      null,
      I18n.t('modals.buttons.defaultOk')
    )
  }

  showModalNoDevice = () => {
    ModalService.show(
      I18n.t('modals.noRaixerDevice.title'), 
      I18n.t('modals.noRaixerDevice.text'),
      () => { window.open(RaixerConfig.webviews.storeUri, '_blank') },
      I18n.t('modals.noRaixerDevice.buttons.ok'),
      I18n.t('modals.noRaixerDevice.buttons.cancel')
    )
  }

  goToLocationLink = () => {
    const { connection } = this.props
    const { _id } = connection
    FormModalService.open('pair-device', { connectionId: _id })
  }

  showUpgradeRemainingModal = () => {
    const { device, owner } = this.props.connection || {}
    const { owner: deviceOwner, asUnsubscribedDate } = device
    const remainingDays = getRemainingDays(asUnsubscribedDate, false)
    let modalText = I18n.t('modals.remainingUpgrade.textFirst')
    if (remainingDays === 1) { modalText += I18n.t('shared.in').toLowerCase() + ' ' + remainingDays + ' ' + I18n.t('shared.day') } 
    else if (remainingDays > 1) { modalText += I18n.t('shared.in').toLowerCase() + ' ' + remainingDays + ' ' + I18n.t('shared.days') }
    else { modalText += I18n.t('shared.today').toLowerCase() }
    if (owner === deviceOwner) { modalText += I18n.t('modals.remainingUpgrade.textOwner') }
    else { modalText += I18n.t('modals.remainingUpgrade.textNotOwner') }
    ModalService.show(
      I18n.t('modals.remainingUpgrade.title'), 
      modalText,
      owner === deviceOwner ? this.goToConnectionUpgrade : null,
      owner === deviceOwner ? I18n.t('modals.remainingUpgrade.buttons.ok') : null
    )
  }

  goToConnectionUpgradePaymentFailed = () => {
    this.goToConnectionUpgrade(true)
  }

  showModalPaymentFailedNotOwner = () => {
    const { device, users = [] } = this.props.connection || {}
    const { owner, campaign } = device
    const { subscription } = campaign || {}
    const { unpaidSince } = subscription || {}
    const usersFiltered = users.filter(u => u.owner !== this.props.userId && u.profile === 'admin')
    let text = I18n.t('modals.paymentFailedNotOwner.textFirst')
    const remainingDays = getRemainingDaysUnpaidSince(unpaidSince)
    if (remainingDays === 0) { text += I18n.t('shared.restOfDay').toLowerCase() }
    else if (remainingDays === 1) { text += remainingDays + ' ' + I18n.t('shared.day') } 
    else if (remainingDays > 1) { text += remainingDays + ' ' + I18n.t('shared.days') }
    text += I18n.t('modals.paymentFailedNotOwner.textSecond')
    if (usersFiltered.length > 0) { text += '\n\n' }
    let i = 0
    for (let u of usersFiltered) {
      const { ownerEmail, ownerName, owner: userId } = u
      if (userId === owner) { text += ownerEmail + '\n' + ownerName }
      if (i < usersFiltered.length - 1) { text += '\n' }
      i++
    }
    ModalService.show(
      I18n.t('modals.paymentFailedNotOwner.title'), 
      text,
      null,
      null,
      I18n.t('modals.buttons.defaultOk')
    )
  }

  goToConnectionUpgradePaymentFailedBlocked = () => {
    this.goToConnectionUpgrade(true)
  }

  showModalPaymentFailedBlockedNotOwner = () => {
    const { device, users = [] } = this.props.connection || {}
    const { owner } = device
    const usersFiltered = users.filter(u => u.owner !== this.props.userId && u.profile === 'admin')
    let text = I18n.t('modals.paymentFailedBlockedNotOwner.text')
    if (usersFiltered.length > 0) { text += '\n\n' }
    let i = 0
    for (let u of usersFiltered) {
      const { ownerEmail, ownerName, owner: userId } = u
      if (userId === owner) { text += ownerEmail + '\n' + ownerName }
      if (i < usersFiltered.length - 1) { text += '\n' }
      i++
    }
    ModalService.show(
      I18n.t('modals.paymentFailedBlockedNotOwner.title'), 
      text,
      null,
      null,
      I18n.t('modals.buttons.defaultOk')
    )
  }

  showModalMiniGyroBattery = () => {
    const battery = getConnectionMiniGyroBattery(this.props.connection)
    const { charge, updatedAt } = battery
    const parsedCharge = parseInt(charge)
    const title = I18n.t('modals.deviceMiniGyroVitalsBattery.title')
    const textDate = I18n.t('screens.connection.warnings.battery.lastUpdatedAt') + ' ' + getDatetimeInCurrentTimezoneAsString(updatedAt, true)
    const textCharge = !isNaN(parsedCharge) ? parsedCharge + '%' : '-'
    ModalService.show(
      title,
      I18n.t('modals.deviceMiniGyroVitalsBattery.textFirst') + textCharge + '\n' + textDate,
      null,
      null,
      I18n.t('modals.deviceMiniGyroVitalsBattery.buttons.cancel')
    )
  }
  

  getNotifications = () => {
    const { appShowFreeProfile = false } = this.props.configuration || {}
    const actions = RaixerConfig.connections.warningsActions
    const hasAutoAccessDoor = connectionHasAutoAccessDoor(this.props.connection)
    const isMiniWithGyroDoor = connectionIsMiniWithGyroDoor(this.props.connection)
    const isProWithGyroDoor = connectionIsProWithGyroDoor(this.props.connection)
    const isFreeVersionDevice = connectionIsFreeVersionDevice(this.props.connection)
    const connectionsAvailableForCheckin = userHasConnectionsAvailableForCheckin(this.props.userId, this.props.connections)
    let notifications = getConnectionNotifications(this.props.connection, hasAutoAccessDoor, appShowFreeProfile, isFreeVersionDevice, connectionsAvailableForCheckin, isProWithGyroDoor, isMiniWithGyroDoor)
    for (let n of notifications) {
      const { actionName } = n
      if (actionName === actions.deviceTrial) { n.action = this.showModalTrial }
      else if (actionName === actions.deviceTrialNotOwner) { n.action = this.showModalTrialNotOwner }
      else if (actionName === actions.deviceTrialEnded) { n.action = this.showModalUnlockFeatures }
      else if (actionName === actions.deviceTrialAccountEnded) { n.action = this.showModalCantUse }
      else if (actionName === actions.deviceTrialAccountEndedNotOwner) { n.action = this.showModalUpgradeNotOwner }
      else if (actionName === actions.deviceUpdating) { n.action = this.getConnection }
      else if (actionName === actions.deviceOffline) { n.action = this.showModalDeviceOffline }
      else if (actionName === actions.offlineAutoAccess) { n.action = this.showModalOfflineAutoAccess }
      else if (actionName === actions.refreshVitals) { n.action = this.showModalDeviceVitalsSignal }
      else if (actionName === actions.refreshVitalsBattery) { n.action = this.showModalDeviceVitalsBattery }
      else if (actionName === actions.deviceTrialEndedNotOwner) { n.action = this.showModalUpgradeNotOwner }
      else if (actionName === actions.openClosedBlocked) { n.action = this.showModalOpenClosedBlocked }
      else if (actionName === actions.autoAccessBlocked) { n.action = this.showModalAutoAccessBlocked }
      else if (actionName === actions.firmwareUpdate) { n.action = this.showModalFirmwareUpdate }
      else if (actionName === actions.firmwareUpdateNotOwner) { n.action = this.showModalFirmwareUpdateNotOwner }
      else if (actionName === actions.nukiBatteryCritical) { n.action = this.showModalNukiBatteryCritical }
      else if (actionName === actions.noRaixerDevice) { n.action = this.showModalNoDevice }
      else if (actionName === actions.linkRaixerDevice) { n.action = this.goToLocationLink }
      else if (actionName === actions.deviceUpgradeRemaining) { n.action = this.showUpgradeRemainingModal}
      else if (actionName === actions.paymentFailed) { n.action = this.goToConnectionUpgradePaymentFailed }
      else if (actionName === actions.paymentFailedNotOwner) { n.action = this.showModalPaymentFailedNotOwner }
      else if (actionName === actions.paymentFailedBlocked) { n.action = this.goToConnectionUpgradePaymentFailedBlocked }
      else if (actionName === actions.paymentFailedBlockedNotOwner) { n.action = this.showModalPaymentFailedBlockedNotOwner }
      // TODO: missing actions
      // else if (actionName === actions.particleDisabled) { n.action = this.showModalParticleDisabled }
      // else if (actionName === actions.gyroConnectWifi) { n.action = this.showModalGyroConnectWifi }
      // else if (actionName === actions.gyroControlIntercom) { n.action = this.showModalGyroControlIntercom }
      // else if (actionName === actions.gyroNoInternet) { n.action = this.showModalGyroNoInternet }
      // else if (actionName === actions.gyroTrial) { n.action = this.showModalGyroTrial }
      // else if (actionName === actions.gyroElectricity) { n.action = this.showModalGyroElectricity }
      // else if (actionName === actions.gyroBattery) { n.action = this.showModalGyroBattery }
      else if (actionName === actions.miniGyroBattery) { n.action = this.showModalMiniGyroBattery }
      else { n.action = null }
    }
    return notifications.filter(n => n.actionName !== actions.gyroControlIntercom)
  }

  render = () => {
    return (
      this.props.connection &&
      <ConnectionHeaderNotifications
        notifications={this.getNotifications()}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    activeLang: state.i18n.activeLang,
    userId: state.auth.userId,
    configuration: state.configuration.configuration,
    connections: state.connections.connections,
    fetchingConnectionOther: state.connections.fetchingConnectionOther
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConnection: (connectionId) => dispatch(ConnectionsActions.connectionRequest(connectionId)),
    getConnectionVitals: (connectionId, type) => dispatch(ConnectionsActions.connectionVitalsRequest(connectionId, type)),
    connectionUnblockBehaviours: (connectionId, params) => 
      dispatch(ConnectionsActions.connectionUnblockBehavioursRequest(connectionId, params)),
    connectionDeviceUpdateFirmware: (connectionId) => 
      dispatch(ConnectionsActions.connectionDeviceUpdateFirmwareRequest(connectionId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNotifications)
import { Colors, Metrics } from '../../../Themes'

export default {
  wrapper: {
    display: 'flex',
    flex: 2,
    height: '100%',
    alignSelf: 'flex-start',
    overflow: 'visible'
  },
  animatedContainer: {
    display: 'flex',
    flex: 1,
    transition: 'margin-left 0.5s linear, visibility 0.5s, opacity 0.5s linear',
    marginLeft: 0,
    visibility: 'visible',
    opacity: 1
  },
  hidden: {
    marginLeft: '100%',
    visibility: 'hidden',
    opacity: 0
  },
  container: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    borderStyle: 'solid',
    borderWidth: 0
  },
  mainBody: {
    display: 'flex',
    flex: 1,
    height: '0vh',
    padding: 0,
    backgroundColor: Colors.mainBackground,
    justifyContent: 'center',
    overflow: 'auto'
  },
  mainBodyScroll: { 
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'visible',
    padding: '0 ' + Metrics.doubleBaseMargin
  }
}
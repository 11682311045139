import moment from 'moment'
import 'moment-timezone'

export const dateFormat = 'DD-MM-YYYY'
export const dateShortFormat = 'DD-MM-YY'
export const timeFormat = 'HH:mm'
export const datetimeFormat = dateFormat + ' ' + timeFormat
export const serverDateFormat = 'YYYY-MM-DD'
export const serverTimeFormat = 'HH:mm:ss'
export const serverDatetimeFormat = 'YYYY-MM-DDTHH:mm:ss'

export const timestampToDate = (d, shortFormat) => {
  const format = shortFormat ? 'DD/MM/YY' : 'DD MMM YYYY'
  return moment(d).format(format)
}

export const timestampToTime = (d) => {
  return moment(d).format('HH:mm').toUpperCase()
}

export const inputDateFormat = (d) => {
  return String(moment(d).format('DD-MM-YYYY'))
}

export const inputTimeFormat = (d) => {
  return String(moment(d).format('HH:mm'))
}

export const timestampToDateTime = (d) => {
  let m = moment.utc(d)
  let today = moment()
  if (m.isSame(today, 'days')) { return m.local().format('HH:mm') }
  return m.local().format('DD/MM/YYYY HH:mm')
}

export const getRemainingDays = (targetDate, forTrial = true) => {
  if (forTrial) {
    const createdDaysAgo = moment().diff(moment(targetDate), 'days')
    return 7 - createdDaysAgo
  } else { return moment(targetDate).diff(moment(), 'days') }
}

export const getRemainingDaysUnpaidSince = (targetDate) => {
  const momentTarget = moment(targetDate, 'YYYY-MM-DDTHH:mm:ss').add(5, 'days')
  const duration = moment.duration(momentTarget.diff(moment()))
  return Math.ceil(duration.asDays())
}

export const getDaysBetweenDates = (targetEndDate, targetStartDate = null) => {
  const startDate = targetStartDate ? moment(targetStartDate, 'YYYY-MM-DDTHH:mm:ss') : moment()
  const endDate = moment(targetEndDate, 'YYYY-MM-DDTHH:mm:ss')
  return startDate.diff(endDate, 'days')
}

export const getRemainingTimeMessage = (minInUse, maxTime, isOldDevice = false) => {
  let result
  const now = moment()
  const finishTime = moment.unix(minInUse)
  const finishFromNow = finishTime.diff(now)
  if (finishFromNow > 0) {
    const d = moment.duration(finishFromNow);
    const dMoment = moment.utc(d.asMilliseconds())
    const days = parseInt(d.asDays())
    const hours = parseInt(dMoment.format('HH'))
    const mins = parseInt(dMoment.format('mm'))
    const secs = parseInt(dMoment.format('ss'))
    result = hours + 'h ' + mins + 'm ' + secs + 's'
    if (days >= 1) { result = days + 'd ' + result }
  } else {
    if (isOldDevice) {
      // Device with old firmware
      const startedTime = moment.unix(minInUse)
      const maxConnectionTime = moment.duration(maxTime, 'm').asMilliseconds()
      const consumedTime = moment.duration(now.diff(startedTime)).asMilliseconds()
      const diff2 = moment.duration(maxConnectionTime - consumedTime).asMilliseconds()
      if (diff2 > 0) {
        const utcDiff = moment.utc(diff2)
        const hours = utcDiff.format('h') + 'h'
        const mins = utcDiff.format('m') + 'm'
        const secs = utcDiff.format('s') + 's'
        result =  hours + ' ' + mins + ' ' + secs
      }
    }
  }
  return result
}

export const getMinutesBetweenDatetimes = (startDate, startTime, endDate, endTime) => {
  let dateStart = moment(startDate).format('YYYY-MM-DD')
  let timeStart = moment(startTime).format('HH:mm:ss')
  let dtStart = moment(dateStart + timeStart, 'YYYY-MM-DDTHH:mm:ss')
  let dateEnd = moment(endDate).format('YYYY-MM-DD')
  let timeEnd = moment(endTime).format('HH:mm:ss')
  let dtEnd = moment(dateEnd + timeEnd, 'YYYY-MM-DDTHH:mm:ss')
  return dtEnd.diff(dtStart, 'minutes')
}

export const getUTCFromDatetime = (startDate, startTime) => {
  let dateStart = moment(startDate).format('YYYY-MM-DD')
  let timeStart = moment(startTime).format('HH:mm:ss')
  let dtStart = moment(dateStart + timeStart, 'YYYY-MM-DDTHH:mm:ss')
  return dtStart.utc().toDate().getTime()
}

export const delay = (time) => {
  return new Promise(resolve => 
    setTimeout(() => resolve(time), time)
  )
}

export const getDatetimes = (start, end, time) => {
  let now = moment()
  let to = moment()
  if (start) { now = moment(start) }
  if (end) { to = moment(end) }
  if (!start && time) { now.minute(60) }
  if (!end && time) { to.minute(60).add(time, 'minutes') }
  return { now: now.toDate(), to: to.toDate() }
}

export const getTimes = (start, end) => {
  let now = moment(start, 'HH:mm')
  let to = moment(end, 'HH:mm')
  return { now: now.toDate(), to: to.toDate() }
}

export const getRecalculatedDatetimes = (sDate, sTime, eDate, eTime, time, justHours) => {
  let startDate = moment(sDate)
  let endDate = !justHours ? moment(eDate) : moment(sDate)
  let startTime = moment(sTime)
  let endTime = moment(eTime)
  startTime.date(startDate.date())
  startTime.month(startDate.month())
  startTime.year(startDate.year())
  endTime.date(endDate.date())
  endTime.month(endDate.month())
  endTime.year(endDate.year())
  let dateDiff = endDate.diff(startDate, 'days')
  let timeDiff = endTime.diff(startTime, 'minutes')
  if (dateDiff < 0 || timeDiff < 0) {
    if (time) {
      let newEnd = moment(startTime).add(time, 'minutes')
      endDate = newEnd
      endTime = newEnd
    } else {
      let newEnd = moment(startTime)
      endDate = newEnd
      endTime = newEnd
    }
  }
  return { 
    startDate: startDate.toDate(), 
    endDate: endDate.toDate(), 
    startTime: startTime.toDate(), 
    endTime: endTime.toDate() 
  }
}

export const getDatetimeAsUTC = (d, t) => {
  let date = moment(d).format('YYYY-MM-DD')
  let time = moment(t).format('HH:mm:ss')
  let datetime = moment(date + time, 'YYYY-MM-DDTHH:mm:ss')
  return datetime.utc().toDate().getTime()
}

export const getDatetimeIsBeforeNow = (d, t) => {
  const now = moment.utc()
  let date = moment(d).format('YYYY-MM-DD')
  let time = moment(t).format('HH:mm:ss')
  let datetime = moment(date + time, 'YYYY-MM-DDTHH:mm:ss')
  return datetime.isBefore(now)
}

export const isNowBetweenDatetimes = (startDate, startTime, endDate, endTime) => {
  const now = moment.utc()
  let dateStart = moment(startDate).format('YYYY-MM-DD')
  let timeStart = moment(startTime).format('HH:mm:ss')
  let startDatetime = moment(dateStart + timeStart, 'YYYY-MM-DDTHH:mm:ss')
  let dateEnd = moment(endDate).format('YYYY-MM-DD')
  let timeEnd = moment(endTime).format('HH:mm:ss')
  let endDatetime = moment(dateEnd + timeEnd, 'YYYY-MM-DDTHH:mm:ss')
  return now.isBetween(startDatetime, endDatetime)
}

export const isNowInRepeatTimes = (startHour, endHour, timezone, daysOfWeek) => {
  const now = moment()
  const startTime = moment(startHour, 'HH:mm')
  const endTime = moment(endHour, 'HH:mm')
  return now.isBetween(startTime, endTime) && daysOfWeek.indexOf(now.day()) > -1
}

export const getTimeInCurrentTimezoneAsString = (d, t) => {
  let date = moment(d).format('YYYY-MM-DD')
  let time = moment(t).format('HH:mm:ss')
  let datetime = moment(date + time, 'YYYY-MM-DDTHH:mm:ss')
  let datetimeInTimezone = moment.tz(datetime, getTimezone())
  return datetimeInTimezone.format('HH:mm')
}

export const getDatetimeInCurrentTimezoneAsString = (d, manageDSTOffset = false) => {
  const tz = getTimezone()
  let date = moment.tz(d, 'YYYY-MM-DDTHH:mm:ss', 'Europe/London')
  let dateInTimezone = moment.tz(date, tz)
  if (dateInTimezone.isDST() && manageDSTOffset) {
    const offset = getDSTOffset(tz)
    if (offset > 0) { dateInTimezone.add(offset, 'minutes') }
    else { dateInTimezone.substract(offset, 'minutes') }
  }
  return dateInTimezone.format('DD/MM/YYYY HH:mm')
}

export const getTimezone = () => {
  const tz = moment.tz.guess(true)
  return tz
}

export const getFinishTimeAsUnix = (minutesToAdd) => {
  return moment().add(minutesToAdd, 'minutes').unix()
}

const getDSTOffset = (tz) => {
  var z = moment.tz.zone(tz)
  var i = z._index(moment())
  return i < z.offsets.length-1 ? Math.abs(z.offsets[i] - z.offsets[i+1]) : 0
}
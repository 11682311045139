import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import I18n from '../../i18n/i18n'
import RaixerConfig from '../../Config/RaixerConfig'
import ConnectionsActions from '../../Redux/ConnectionsRedux'
import Connections from '../../Components/Screens/Connections/Connections'
import { ModalService } from '../../Services/Modal'

class ConnectionsScreen extends Component {

  static propTypes = {
    activeConnection: PropTypes.string,
    goToConnection: PropTypes.func.isRequired
  }

  static defaultProps = {
    activeConnection: null,
    goToConnection: null
  }

  componentDidMount = () => {
    this.props.getConnections()
    const { connections = [] } = this.props
    if (connections.length > 0) { this.props.goToConnection(connections[0]._id) }
  }

  componentDidUpdate = (prevProps) => {
    const { connections: prevConnections = [], fetching: prevFetching } = prevProps
    const { connections = [], fetching } = this.props
    if (prevConnections.length === 0 && connections.length > 0 && prevFetching && !fetching) {
      this.props.goToConnection(connections[0]._id)
    }
  }

  postDeviceTask = (connection, value) => {
    const { device } = connection
    if (device.autoAccessAlwaysActive) {
      ModalService.show(
        I18n.t('modals.autoAccessBlocked.title'), 
        I18n.t('modals.autoAccessBlocked.text'),
        null,
        null,
        I18n.t('modals.buttons.defaultOk')
      )
    } else {
      const task = value ? RaixerConfig.connections.actionsNames.startJob : RaixerConfig.connections.actionsNames.stopJob
      this.props.postDeviceTask(connection, task)
    }
  }

  goToAddDevice = () => {
    window.open(RaixerConfig.webviews.storeUri, '_blank')
  }

  // goToCheckinUpgrade = () => {
  //   this.props.navigation.navigate('CheckinUpgradeScreen')
  // }

  render = () => {
    const { appShowFreeProfile = false, appShowCheckin = false } = this.props.configuration || {}
    const { appShowCheckin: userShowCheckin = false } = this.props.user || {}
    return (
      <Connections
        showFreeProfile={appShowFreeProfile}
        showCheckin={appShowCheckin || userShowCheckin}
        activeConnection={this.props.activeConnection}
        connections={this.props.connections}
        fetching={this.props.fetching}
        fetchingTask={this.props.fetchingTask}
        error={this.props.error}
        platformError={this.props.platformError}
        activeConnectionTask={this.props.activeConnectionTask}
        getConnections={this.props.getConnections}
        postDeviceTask={this.postDeviceTask}
        goToConnection={this.props.goToConnection}
        goToAddDevice={this.goToAddDevice}
        // goToCheckinUpgrade={this.goToCheckinUpgrade}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    fetching: state.connections.fetching,
    fetchingTask: state.connections.fetchingTask,
    error: state.connections.error,
    platformError: state.connections.platformError,
    activeConnectionTask: state.connections.activeConnection,
    configuration: state.configuration.configuration,
    user: state.user.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConnections: () => dispatch(ConnectionsActions.connectionsRequest()),
    postDeviceTask: (connection, task) => dispatch(ConnectionsActions.connectionsTaskRequest(connection, task, true))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionsScreen)
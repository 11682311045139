import Colors from './Colors'
import Fonts from './Fonts'
import Metrics from './Metrics'
import Shadows from './Shadows'

const HeaderStyles = {
  headerRed: {
    backgroundColor: Colors.theme1,
    shadowOpacity: 0,
    shadowOffset: {
      height: 0
    },
    shadowRadius: 0,
    elevation: 0,
    borderBottomWidth: 0
  },
  title: {
    color: Colors.darkGray, 
    fontSize: Fonts.size.regular, 
    fontWeight: Fonts.weight.regular
  },
  backTitle: {
    color: Colors.theme1, 
    fontSize: Fonts.size.regular
  },
  leftButton: {
    marginLeft: Metrics.doubleBaseMargin,
    backgroundColor: 'transparent'
  },
  rightCancelButton: {
    marginRight: Metrics.smallMargin
  },
  rightButton: {
    marginTop: 0,
    marginBottom: 0,
    padding: Metrics.smallMargin + ' 0',
    backgroundColor: Colors.theme1,
    borderRadius: Metrics.doubleBaseMargin,
    borderColor: Colors.theme1,
    marginRight: Metrics.doubleBaseMargin,
    justifyContent: 'flex-end',
    ...Shadows.none
  },
  rightButtonText: {
    color: Colors.theme1,
    fontSize: Fonts.size.headerRightButtonText,
    fontWeight: Fonts.weight.regular,
    marginTop: Metrics.buttonBorderWidth
  }
}

export default HeaderStyles
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from '../../../../i18n/i18n'
import FullScreenLoader from '../../../UI/Loaders/FullScreenLoader'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import BaseSwitch from '../../../UI/Switchs/BaseSwitch'
import MainButton from '../../../UI/Buttons/MainButton'
import styles from '../../../Styles/Screens/Forms'
import { Metrics } from '../../../../Themes'

export default class AutoAccessForm extends Component {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    alwaysActiveTurnedOn: PropTypes.func.isRequired,
    contingencyModeTurnedOn: PropTypes.func.isRequired,
    contingencyModeBlocked: PropTypes.func.isRequired,
    activeLang: PropTypes.string.isRequired,
    connection: PropTypes.object,
    fetching: PropTypes.bool.isRequired,
    fetchingConnection: PropTypes.bool.isRequired
  }

  static defaultProps = {
    onSubmit: null,
    alwaysActiveTurnedOn: null,
    contingencyModeTurnedOn: null,
    contingencyModeBlocked: null,
    activeLang: null,
    connection: null,
    fetching: false,
    fetchingConnection: false
  }

  constructor(props) {
    super(props)
    this.state = {
      autoAccessAlwaysActive: false,
      offlineAutoAccess: false
    }
  }

  componentDidUpdate = (prevProps) => {
    const { connection: prevConnection } = prevProps
    const { connection } = this.props
    if (!prevConnection && connection) {
      const { device } = connection
      const { autoAccessAlwaysActive = false, offlineAutoAccess = false } = device
      this.setState({ autoAccessAlwaysActive, offlineAutoAccess })
    }
  }

  onChangeAlwaysActive = (value) => {
    if (value) { this.props.alwaysActiveTurnedOn() }
    let offlineAutoAccess = this.state.offlineAutoAccess
    if (offlineAutoAccess) { offlineAutoAccess = false }
    this.setState({ autoAccessAlwaysActive: value, offlineAutoAccess })
  }

  onChangeOfflineAutoAccess = (value) => {
    if (this.state.autoAccessAlwaysActive) { this.props.contingencyModeBlocked() }
    else { 
      if (value) { this.props.contingencyModeTurnedOn(this.props.connection) }
      this.setState({ offlineAutoAccess: value }) 
    }
  }

  onSubmit = () => {
    this.props.onSubmit(this.state.autoAccessAlwaysActive, this.state.offlineAutoAccess)
  }

  render = () => {
    return (
      <>
        <ContentCard
          title={I18n.t('screens.autoAccessForm.titleEdit')}
          renderContent={() => (
            <div>
              <BaseSwitch
                value={this.state.autoAccessAlwaysActive}
                disabled={this.props.fetching}
                label={I18n.t('screens.autoAccessForm.labels.alwaysActive')}
                help={I18n.t('screens.autoAccessForm.helps.alwaysActive')}
                onChange={this.onChangeAlwaysActive}
                withoutMargin={true}
                customSwitchRowStyle={styles.switchRow}
                customHelpStyle={styles.switchHelp}
                customSwitchStyle={styles.switch}
              />
              <BaseSwitch
                value={this.state.offlineAutoAccess}
                disabled={this.props.fetching}
                label={I18n.t('screens.autoAccessForm.labels.contingencyMode')}
                help={I18n.t('screens.autoAccessForm.helps.contingencyMode')}
                onChange={this.onChangeOfflineAutoAccess}
                customSwitchRowStyle={styles.switchRow}
                customHelpStyle={styles.switchHelp}
                customSwitchStyle={styles.switch}
              />
            </div>
          )}
          renderButtons={() => (
            <MainButton
              buttonText={I18n.t('shared.save')}
              icon={'save'}
              onPress={this.onSubmit}
              disabled={this.props.fetching || this.props.fetchingConnection}
              customButtonStyle={{ marginTop: Metrics.doubleBaseMargin, marginBottom: 0 }}
            />
          )}
        />
        <FullScreenLoader visible={this.props.fetching}/>
      </>
    )
  }

}
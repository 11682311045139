import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthActions from '../../Redux/AuthRedux'
import Splash from '../../Components/Screens/Splash/Splash'

class AppleLoginCallbackScreen extends Component {

  componentDidMount = () => {
    const { location } = this.props || {}
    const { search } = location || {}

    // remove ?user= from url
    const maxLengthUrl = 2048

    let jsonAsString = ''

    if (search.length > maxLengthUrl) {
      jsonAsString = decodeURIComponent(search.split('?user=')[1])
    } else {
      jsonAsString = this.getQueryVariable(search, 'user')
    }

    if (jsonAsString) {
      const user = JSON.parse(jsonAsString)
      if (user) {
        this.props.appleLoginCallback(user)
      }
    }
  }

  getQueryVariable = (query, variable) => {
    query = query.substring(1)
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1])
      }
    }
    return null
}

  render = () => {
    return (
      <Splash visible={true} />
    )
  }

}

const mapStateToProps = () => {
  return {
    
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    appleLoginCallback: (user) => dispatch(AuthActions.appleLoginCallbackRequest(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppleLoginCallbackScreen)

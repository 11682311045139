import { call, put, select } from 'redux-saga/effects'
import I18n from '../i18n/i18n'
import { AuthSelectors } from '../Redux/AuthRedux'
import UserActions from '../Redux/UserRedux'
import { AlertService } from '../Services/Alert'

export function * getUser (api) {
  const userId = yield select(AuthSelectors.selectUserId)
  const response = yield call(api.getUser, userId)
  const { ok, data: user } = response
  if (ok) {
    yield put(UserActions.userRequestFinished(user, null))
  } else {
    yield put(UserActions.userRequestFinished(null, true))
    AlertService.error(I18n.t('snacks.errors.getUser'))
  }
}

export function * updateUser (api, action) {
  const { user, fields } = action
  const { _id } = user
  const response = yield call(api.updateUser, _id, fields)
  const { ok, data: newUser } = response
  if (ok) {
    yield put(UserActions.userRequestFinished(newUser, null))
    AlertService.success(I18n.t('snacks.success.updateUser'))
  } else {
    yield put(UserActions.userRequestFinished(user, null))
    AlertService.error(I18n.t('snacks.errors.updateUser'))
  }
}
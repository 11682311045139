import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Colors, Metrics } from '../../../Themes'
import BaseButton from './BaseButton'

export default class MainButton extends Component {

  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    icon: PropTypes.string,
    iconSize: PropTypes.string,
    buttonStyle: PropTypes.object,
    customButtonStyle: PropTypes.object,
    customIconStyle: PropTypes.object,
    withIndicator: PropTypes.bool,
    indicatorColor: PropTypes.string,
    fetching: PropTypes.bool
  }

  static defaultProps = {
    buttonText: null,
    onPress: null,
    disabled: null,
    icon: null,
    iconSize: Metrics.icons.regular,
    buttonStyle: { margin: Metrics.baseMargin + ' 0' },
    customButtonStyle: {},
    customIconStyle: {},
    withIndicator: false,
    indicatorColor: Colors.white,
    fetching: false
  }

  render = () => {
    return (
      <BaseButton
        buttonText={this.props.buttonText}
        onPress={this.props.onPress}
        disabled={this.props.disabled}
        icon={this.props.icon}
        iconSize={this.props.iconSize}
        iconStyle={this.props.customIconStyle}
        buttonStyle={Object.assign({}, this.props.buttonStyle, this.props.customButtonStyle, {})}
        withIndicator={this.props.withIndicator}
        indicatorColor={this.props.indicatorColor}
        fetching={this.props.fetching}
      />
    )
  }

}

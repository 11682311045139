import { Colors, Fonts, Metrics, Shadows } from '../../../../Themes/'

export default {
  headerContainer: {
    
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    padding: Metrics.doubleBaseMargin + ' ' + Metrics.doubleBaseMargin + ' 0 ' + Metrics.doubleBaseMargin,
    backgroundColor: Colors.theme1
  },
  headerNotificationsAnimatedContainer: {
    transition: 'max-height 0.5s ease-in, visibility 0.5s',
    transformOrigin: 'top',
    visibility: 'visible',
    maxHeight: '240px',
    overflow: 'hidden'
  },
  headerNotificationsHidden: {
    transition: 'max-height 0.5s ease-out, visibility 0.5s',
    maxHeight: 0,
    visibility: 'hidden'
  },
  headerNotificationsContainer: {
    marginTop: Metrics.baseMargin,
    display: 'flex',
    flexDirection: 'column'
  },
  headerNotificationContainer: {
    backgroundColor: Colors.cardBackground,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    shadowColor: Colors.shadow, 
    borderColor: Colors.transparent
  },
  headerNotificationSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerNotificationText: {
    marginLeft: Metrics.baseMarginPlus,
    fontSize: Fonts.size.small,
    color: Colors.mediumGray,
    textAlign: 'left',
    flexWrap: 'wrap',
    marginTop: 0,
    marginBottom: 0,
    fontFamily: Fonts.type.bold
  },
  headerTitleText: {
    fontSize: Fonts.size.title,
    color: Colors.white,
    margin: 0,
    fontFamily: Fonts.type.bold
  },
  headerSummariesContainer: {
    marginTop: Metrics.baseMargin
  },
  headerSummaryText: {
    marginBottom: Metrics.smallMargin,
    fontSize: Fonts.size.regular,
    color: Colors.white,
    fontFamily: Fonts.type.bold
  },
  headerQuickActionsContainer: {
    padding: Metrics.doubleBaseMargin + ' 0 ' + Metrics.doubleBaseMargin + ' 0',
    whiteSpace: 'nowrap',
    overflowX: 'auto'
  },
  headerQuickActionContainer: {
    display: 'flex',
    backgroundColor: Colors.cardBackground,
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    shadowColor: Colors.shadow, 
    borderColor: Colors.transparent,
    padding: Metrics.baseMargin,
    width: 96,
    minWidth: 96,
    height: 96,
    margin: 0,
    marginRight: Metrics.baseMargin,
    borderRadius: Metrics.doubleBaseMargin
  },
  headerQuickActionIconIndicatorRow: { 
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between', 
    flex: 1,
    width: '100%'
  },
  headerQuickActionIcon: {
    width: 36,
    height: 36,
    marginRight: 0,
    alignSelf: 'flex-start'
  },
  headerQuickActionIndicator: { 
    width: Metrics.doubleBaseMargin, 
    height: Metrics.doubleBaseMargin, 
    borderWidth: Metrics.buttonBorderWidth * 2,
    borderRadius: Metrics.doubleBaseMargin, 
    marginBottom: 0, 
    padding: 0, 
    shadowOpacity: 0.5
  },
  headerQuickActionIndicatorOn: {
    borderColor: Colors.successLight,
    backgroundColor: Colors.success,
    shadowColor: Colors.success  
  },
  headerQuickActionIndicatorOff: {
    borderColor: Colors.lightGray,
    backgroundColor: Colors.mediumGray,
    shadowColor: Colors.mediumGray,
    shadowOpacity: 0.2
  },
  headerQuickActionIndicatorText: { 
    color: Colors.mediumGray, 
    fontSize: Fonts.size.small, 
    margin: 0,
    fontFamily: Fonts.type.bold
  },
  headerQuickActionStatusIndicator: {
    width: 20,
    height: 20
  },
  headerQuickActionText: {
    fontSize: Fonts.size.small,
    color: Colors.theme1,
    textAlign: 'left',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    marginTop: Metrics.baseMargin,
    marginBottom: 0,
    lineHeight: Metrics.doubleBaseMargin,
    fontFamily: Fonts.type.bold
  },
  headerEmptySpace: {
    padding: Metrics.baseMargin + ' 0'
  },
  headerContainerAccess: {
    backgroundColor: Colors.mainBackground,
    paddingBottom: Metrics.tripleBaseMargin,
    flex: 1,
  },
  headerAccess: {
    height: Metrics.baseMargin * 6,
    padding: '0 ' + Metrics.doubleBaseMargin,
    backgroundColor: Colors.mainBackground,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: Metrics.listItemBorderWidth,
    borderBottomColor: Colors.lightGray,
    ...Shadows.tabbar,
    shadowOpacity: 0.125,
  },
  titleAndRefreshContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleContainer: {
    flex: 3
  },
  refreshContainer: {
    flex: 1,
    display: 'flex',
    alignSelf: 'flex-start',
    justifyContent: 'flex-end'
  },
  refreshButton: {
    margin: 0,
    padding: Metrics.baseMargin,
    marginRight: Metrics.smallMargin,
    borderRadius: Metrics.baseMargin
  },
  refreshButtonIcon: {
    color: Colors.white
  }
}
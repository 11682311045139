import { Colors, Metrics } from '../../../Themes/'

export default {
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'visibility 0.5s, opacity 0.5s linear',
    position: 'fixed',
    visibility: 'visible',
    opacity: 1,
    zIndex: Metrics.zIndexes.modalContainer,
    top: 0,
    left: 0,
    backgroundColor: Colors.blackTransparentLight
  },
  hidden: {
    opacity: 0,
    visibility: 'hidden'
  },
  modalCard: {
    flex: 'none',
    display: 'inherit',
    width: '80%',
    maxWidth: '610px'
  },
  title: {
    textAlign: 'center',
    marginBottom: Metrics.baseMargin
  },
  text: {
    textAlign: 'center',
    marginBottom: Metrics.baseMargin,
    overflowWrap: 'break-word'
  }
}
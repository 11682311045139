import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getActualConnection } from '../../../Lib/ConnectionsUtils'
// import I18n from '../../../i18n/i18n'
import GuestForm from '../../../Components/Screens/CheckIn/Forms/GuestForm'
// import { AlertService } from '../../../Services/Alert'
import { FormModalService } from '../../../Services/FormModal'

class GuestFormScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      bookingId: null
    }
  }

  componentDidMount = () => {
    const { connectionId, bookingId } = this.props
    this.setState({ connectionId, bookingId })
  }

  goBack = () => {
    const { navigationParams } = this.props
    const { connectionId, locationId } = navigationParams || {}
    if (connectionId && locationId) {
      FormModalService.open('booking-history', { connectionId, locationId })
    }
  }

  onPressCallPhone = (guestId) => {
    // TODO: what can we do in a webapp about this? copy the phone?
    // const connection = getActualConnection(this.props.connections, this.state.connectionId)
    // const { location } = connection || {}
    // const { bookings = [], bookingsHistory = [] } = location || {}
    // const booking = bookings.filter(b => b._id === this.state.bookingId)[0] || bookingsHistory.filter(b => b._id === this.state.bookingId)[0] || {}
    // const { people = [] } = booking
    // const guest = people.filter(g => g._id === guestId)[0] || {}
    // const { phoneNumber } = guest
    // Linking.openURL('tel:' + phoneNumber)
    //   .catch(() => { AlertService.error(I18n.t('snacks.errors.connectionsDetailCallError')) })
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    const { location } = connection || {}
    const { bookings = [], bookingsHistory = [] } = location || {}
    const booking = bookings.filter(b => b._id === this.state.bookingId)[0] || bookingsHistory.filter(b => b._id === this.state.bookingId)[0] || {}
    return (
      <GuestForm
        booking={booking}
        goBack={this.props.navigationParams ? this.goBack : null}
        // onPressPhone={this.onPressCallPhone}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
  }
}

export default connect(mapStateToProps, null)(GuestFormScreen)
import { Fonts, Metrics } from '../../../Themes/'

export default {
  qrCodeContainer: {
    padding: Metrics.cuadrupleBaseMargin
  },
  qrCode: {
    textAlign: 'center'
  },
  qrCodeDescription: {
    fontSize: Fonts.size.small,
    fontWeight: Fonts.weight.regular,
    textAlign: 'center',
    marginTop: Metrics.baseMarginPlus
  },
  indicatorContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  }
}
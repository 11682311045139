import { Colors, Fonts, Metrics } from '../../../../Themes/'

export default {
  cardContainer: {
    marginBottom: Metrics.doubleBaseMargin,
    backgroundColor: Colors.cardBackground,
    borderRadius: Metrics.baseMargin,
    padding: 0,
    minHeight: '88px'
  },
  cardContainerActive: {
    backgroundColor: Colors.lighterGray
  },
  card: {
    padding: Metrics.baseMarginPlus,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    height: '64px',
    minHeight: '64px'
  },
  disabled: {
    opacity: 0.6
  },
  contentContainer: {
    flex: 1,
    marginLeft: Metrics.doubleBaseMarginPlus,
    flexDirection: 'column'
  },
  titleContainer: { 
    display: 'flex',
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'space-between',
    minHeight: '40px'
  },
  title: {
    color: Colors.darkGray,
    fontSize: Fonts.size.regular,
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'left',
    fontFamily: Fonts.type.bold
    // fontWeight: Fonts.weight.bold
  },
  deviceImage: {
    marginLeft: Metrics.smallMargin + 2,
    width: 52,
    height: 52
  },
  integrationLogo: {
    width: 24,
    height: 24,
    marginRight: Metrics.smallMargin
  },
  switchHelp: {
    color: Colors.mediumGray, 
    marginLeft: 0
  }
}
import { Colors, Fonts, Metrics } from '../../../Themes/'

export default {
  mainBody: {
    paddingTop: Metrics.baseMargin,
    paddingBottom: Metrics.baseMargin,
    paddingLeft: Metrics.doubleBaseMargin,
    paddingRight: Metrics.doubleBaseMargin
  },
  switchRow: { 
    padding: 0,
    justifyContent: 'space-between'
  },
  switchHelp: {
    flex: 4
  },
  switchHelpSmall: {
    flex: 4,
    fontSize: Fonts.size.small,
    marginTop: Metrics.baseMargin,
    paddingRight: Metrics.doubleBaseMargin
  },
  switchHelpAsLabel: { 
    color: Colors.mediumGray,
    fontSize: Fonts.size.small 
  },
  switch: {
    flex: 1
  },
  complementaryForm: {
    marginTop: Metrics.tripleBaseMargin,
    marginBottom: Metrics.baseMargin
  },
  emptySpace: {
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin
  },
  emptySpaceSmall: {
    marginTop: Metrics.smallMargin,
    marginBottom: Metrics.smallMargin
  },
  deviceImage: {
    marginTop: Metrics.tripleBaseMargin,
    marginBottom: Metrics.cuadrupleBaseMargin,
    width: '100%',
    height: 240
  },
  deviceImageSmall: {
    height: 120
  },
  secondaryTitle: {
    marginTop: Metrics.doubleBaseMargin,
    marginBottom: Metrics.tripleBaseMargin
  },
  refreshInputContainer: {
    marginBottom: Metrics.tripleBaseMargin
  },
  switchsContainer: {
    marginBottom: Metrics.doubleBaseMargin
  },
  infoBlock: {
    marginTop: Metrics.tripleBaseMargin,
    marginBottom: Metrics.tripleBaseMargin
  },
  infoSwitchsBlock: {
    marginTop: Metrics.tripleBaseMargin,
    marginBottom: Metrics.cuadrupleBaseMargin
  },
  infoBlockTitle: {
    color: Colors.darkGray,
    marginBottom: Metrics.baseMargin
  },
  infoBlockValue: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Metrics.baseMargin
  },
  infoBlockValueLast: {
    marginBottom: 0,
    borderColor: Colors.transparent
  },
  infoBlockValueLeft: {
    flex: 3
  },
  infoBlockValueRight: {
    flex: 2, 
    paddingRight: 16
  },
  infoBlockValueRightText: {
    textAlign: 'right'
  },
  progressMsg: { 
    marginTop: Metrics.baseMargin, 
    textAlign: 'center' 
  },
  creditText: {
    textAlign: 'center',
    color: Colors.theme1
  },
  creditNumberText: {
    textAlign: 'center',
    color: Colors.theme1,
    fontSize: Fonts.size.title,
    fontFamily: Fonts.type.bold
    // fontWeight: Fonts.weight.bold
  },
  additionalHelpButton: { 
    marginBottom: Metrics.baseMargin 
  },
  additionalHelpButtonText: {
    color: Colors.theme1,
    fontFamily: Fonts.type.bold
    // fontWeight: Fonts.weight.bold
  },
  textBoldCentered: {
    textAlign: 'center',
    fontFamily: Fonts.type.bold
    // fontWeight: Fonts.weight.bold
  }
}
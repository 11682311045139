import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-activity/lib/Spinner'
import 'react-activity/lib/Spinner/Spinner.css'
import { Colors, Metrics } from '../../../Themes'

export default class SpinnerIndicator extends Component {

  static propTypes = {
    color: PropTypes.string,
    size: PropTypes.string,
    containerStyle: PropTypes.object,
    speed: PropTypes.number,
    animating: PropTypes.bool
  }

  static defaultProps = {
    color: Colors.theme1,
    size: Metrics.baseMargin,
    containerStyle: {},
    speed: 1,
    animating: true
  }

  render = () => {
    return (
      <div style={this.props.containerStyle} >
        <Spinner 
          size={this.props.size}
          color={this.props.color}
          speed={this.props.speed}
          animating={this.props.animating}
        />
      </div>
    )
  }

}
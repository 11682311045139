import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import I18n from '../../../../i18n/i18n'
import SmallLoader from '../../../UI/Loaders/SmallLoader'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import ListCard from '../../../UI/ContentCards/ListCard'
import AppText from '../../../UI/Texts/AppText'
import MainButton from '../../../UI/Buttons/MainButton'
import GhostButton from '../../../UI/Buttons/GhostButton'
import connectionsStyles from '../../../Styles/Screens/Connection'
import { Metrics } from '../../../../Themes'

export default class CheckIn extends Component {
  
  static propTypes = {
    connection: PropTypes.object,
    fetching: PropTypes.bool.isRequired,
    fetchingForm: PropTypes.bool.isRequired,
    onScroll: PropTypes.func.isRequired,
    goToLocationForm: PropTypes.func.isRequired,
    goToBookingForm: PropTypes.func.isRequired,
    openGuestBook: PropTypes.func.isRequired,
    openGuestForm: PropTypes.func.isRequired,
    goToBookingsHistory: PropTypes.func.isRequired,
    shareBookingUrl: PropTypes.func.isRequired
  }

  static defaultProps = {
    connection: null,
    fetching: null,
    fetchingForm: null,
    onScroll: null,
    goToLocationForm: null,
    goToBookingForm: null,
    openGuestBook: null,
    openGuestForm: null,
    goToBookingsHistory: null,
    shareBookingUrl: null
  }

  getLocationList = (location) => {
    const { locationName, locationTown, locationProvince, locationAddress } = location
    let locationList = [
      { 
        left1: I18n.t('shared.name'), 
        left2: I18n.t('shared.city'), 
        left3: I18n.t('shared.province'), 
        right1: locationName, 
        right2: locationTown, 
        right3: locationProvince.split('_').join(' ')}
    ]
    if (locationAddress) {
      locationList[0].left4 = I18n.t('shared.address')
      locationList[0].right4 = locationAddress
    }
    return locationList
  }

  getContent = (location) => {
    if (!location) {
      return (
        <div style={{ marginTop: Metrics.doubleBaseMargin }}>
          <ContentCard
            title={I18n.t('shared.location')}
            renderContent={() => <AppText text={I18n.t('screens.checkin.locationEmpty')} />}
            renderButtons={() => (
              <MainButton
                buttonText={I18n.t('screens.checkin.locationAddButton')}
                onPress={() => { this.props.goToLocationForm() }}
                disabled={false}
                icon={'add-circle'}
                customButtonStyle={{ margin: 0, marginTop: 0, marginBottom: 0 }}
              />
            )}
          />
        </div>
      )
    }
    return (
      <div style={{ marginTop: Metrics.doubleBaseMargin }}>
        <ListCard
          title={I18n.t('shared.location')}
          list={this.getLocationList(location)}
          listItemType={'location'}
          withEditButton={true}
          customOnPressEdit={() => { this.props.goToLocationForm(location) }}
          addButton={true}
          addButtonAsGhost={true}
          addButtonText={I18n.t('screens.checkin.locationGuestBook')}
          addButtonIcon={'assignment'}
          onAdd={() => { this.props.openGuestBook(location) }}
        />
      </div>
    )
  }

  getBookingList = (bookings, location) => {
    let { locationProvince, deviceId } = location
    return bookings.map((b) => {
      const { _id, startDate, endDate, residencyDays, guests, checkedIn, locationId } = b || {}
      return { 
        deviceId, locationProvince, _id, startDate, endDate: moment(endDate).toISOString(), residencyDays, guests, 
        checkedIn, locationId
      }
    })
  }

  getBookingContent = (fetching, bookings, location) => {
    const { _id: locationId } = location
    if (fetching) {
      return (
        <ContentCard
          title={I18n.t('shared.booking')}
          renderContent={() => <SmallLoader visible={true} />}
        />
      )
    }
    if (!location) {
      return (
        <ContentCard
          title={I18n.t('shared.booking')}
          renderContent={() => <AppText text={I18n.t('screens.checkin.bookingEmpty')} />}
          renderButtons={() => (
            <MainButton
              buttonText={I18n.t('screens.checkin.bookingAddButton')}
              onPress={() => { this.props.goToBookingForm(null) }}
              disabled={false}
              icon={'add-circle'}
              customButtonStyle={{ marginVertical: 0, marginBottom: 0 }}
            />
          )}
        />
      )
    }
    return (
      <ListCard
        title={I18n.t('shared.booking')}
        list={this.getBookingList(bookings, location)}
        listItemType={'booking'}
        withEditButton={bookings.length > 0}
        onEditItem={this.props.goToBookingForm}
        addButton={true}
        addButtonText={I18n.t('screens.checkin.bookingAddButton')}
        addButtonIcon='add-circle'
        onAdd={this.props.goToBookingForm}
        renderExtraContentBeforeButtons={() => {
          if (!this.props.fetchingForm) { return null }
          return <SmallLoader visible={true} />
        }}
        renderExtraContentAfterButtons={() => (
          <GhostButton
            buttonText={I18n.t('screens.checkin.bookingPast')}
            onPress={() => { this.props.goToBookingsHistory(locationId) }}
            disabled={false}
            icon={'history'}
            customButtonStyle={{ margin: 0, marginBottom: 0, marginTop: Metrics.baseMargin }}
          />
        )}
        onListItemMain={(bookingId) => { this.props.shareBookingUrl(bookingId) }}
        onListItemSecondary={(bookingId) => { this.props.openGuestForm(bookingId) }}
      />
    )
  }

  renderConnection = () => {
    const { connection, fetching } = this.props || {}
    const { location/* , deviceId */ } = connection || {}
    const { bookings = [] } = location || {}
    return (
      <>
        <div style={connectionsStyles.mainBody} onScroll={this.props.onScroll}>
          <div style={{ maxWidth: '550px', flex: 1, overflow: 'visible' }}>
            <div style={connectionsStyles.mainBodyScroll}>
              { this.getContent(location) }
              { 
                location && 
                this.getBookingContent(fetching, bookings, location)
              }
            </div>
          </div>
        </div>
      </>
    )
  }

  render = () => {
    return (
      <div style={connectionsStyles.wrapper}>
        {
          this.props.connection &&
          <div style={connectionsStyles.container}>
            { this.renderConnection() }
          </div>
        }
      </div>
    )
  }
}
// import { navigate, goBack, setParams } from '../Navigation/RootNavigation'
import ModalActions from '../Redux/ModalRedux'

class Modal {

  constructor() {
    this.store = null
  }

  setStore = (store) => {
    if (store && !this.store) { this.store = store }
  }

  show = (
    title, text, confirmAction, confirmText, cancelText, dismissOnConfirm, withoutCancelButton, confirmAsGhost,
    withoutFetching, cancelAction, submitOnEnter
  ) => {
    this.store.dispatch(ModalActions.showRequest(
      title,
      text,
      confirmAction,
      confirmText,
      cancelText,
      dismissOnConfirm,
      withoutCancelButton,
      confirmAsGhost,
      withoutFetching,
      cancelAction,
      submitOnEnter
    ))
  }

  update = (
    title, text, confirmAction, confirmText, cancelText, dismissOnConfirm, withoutCancelButton, confirmAsGhost,
    withoutFetching, cancelAction, submitOnEnter
  ) => {
    const state = this.store.getState()
    const stateConfirmAction = state.modal.confirmAction
    const stateConfirmText = state.modal.confirmText
    this.store.dispatch(ModalActions.showRequest(
      title,
      text,
      stateConfirmAction && !confirmAction ? stateConfirmAction : confirmAction,
      stateConfirmText && !confirmText ? stateConfirmText : confirmText,
      cancelText,
      dismissOnConfirm,
      withoutCancelButton,
      confirmAsGhost,
      withoutFetching,
      cancelAction,
      submitOnEnter
    ))
  }

  dismiss = () => {
    this.store.dispatch(ModalActions.hideRequest())
    setTimeout(() => { this.store.dispatch(ModalActions.clearRequest()) }, 500)
  }

  hide = () => {
    this.store.dispatch(ModalActions.hideRequest())
    setTimeout(() => { this.store.dispatch(ModalActions.clearRequest()) }, 500)
  }

  isVisible = () => {
    const state = this.store.getState()
    return state.modal.visible
  }

  // /*
  //  * Function to replace the normal prop navigation because of problems when navigating to screens from
  //  * the modal
  //  */
  // navigateOnConfirm = (name, params) => {
  //   // Little hack to navigate just after 10 millisecond, so the ModalScreen is no longer visible and the
  //   // navigation stack is back where it should be, not in the Modal stack
  //   setTimeout(() => { navigate(name, params) }, 10)
  // }

}

export const ModalService = new Modal()
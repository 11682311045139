import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  configurationRequest: null,
  configurationFinish: ['configuration', 'error']
})

export const ConfigurationTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  configuration: {},
  fetching: false,
  error: null
})

/* ------------- Selectors ------------- */
export const ConfigurationSelectors = {
  selectConfiguration: state => state.configuration.configuration
}

/* ------------- Reducers ------------- */

export const configurationRequest = (state, action) => {
  return state.merge({
    fetching: true
  })
}

export const configurationFinish = (state, action) => {
  const { configuration, error } = action
  const { name, value } = configuration
  let newConfiguration = Object.assign({}, state.configuration)
  if (name) { newConfiguration[name] = value }
  return state.merge({ 
    fetching: false,
    configuration: newConfiguration,
    error
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CONFIGURATION_REQUEST]: configurationRequest,
  [Types.CONFIGURATION_FINISH]: configurationFinish
})

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getActualConnection, getConnectionMaxOpenings } from '../../../Lib/ConnectionsUtils'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import I18n from '../../../i18n/i18n'
import AutoAccessForm from '../../../Components/Screens/Connection/Forms/AutoAccessForm'
import { ModalService } from '../../../Services/Modal'

class AutoAccessFormScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      schedule: null
    }
  }

  componentDidMount = () => {
    const { connectionId } = this.props
    this.setState({ connectionId })
  }

  saveConfiguration = (autoAccessAlwaysActive, offlineAutoAccess) => {
    this.props.saveAutoAccessConfiguration(this.state.connectionId, autoAccessAlwaysActive, offlineAutoAccess)
  }

  alwaysActiveTurnedOn = () => {
    ModalService.show(
      I18n.t('modals.alwaysActiveTurnedOn.title'), 
      I18n.t('modals.alwaysActiveTurnedOn.text'), 
      null,
      null,
      I18n.t('modals.alwaysActiveTurnedOn.buttons.cancel')
    )
  }

  contingencyModeTurnedOn = (connection) => {
    const openings = getConnectionMaxOpenings(connection)
    ModalService.show(
      I18n.t('modals.contingencyModeTurnedOn.title'), 
      I18n.t('modals.contingencyModeTurnedOn.textFirst') + openings + I18n.t('modals.contingencyModeTurnedOn.textSecond'), 
      null,
      null,
      I18n.t('modals.contingencyModeTurnedOn.buttons.cancel')
    )
  }

  contingencyModeBlocked = () => {
    ModalService.show(
      I18n.t('modals.contingencyModeBlocked.title'), 
      I18n.t('modals.contingencyModeBlocked.text'), 
      null,
      null,
      I18n.t('modals.contingencyModeBlocked.buttons.cancel')
    )
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    return (
      <AutoAccessForm
        activeLang={this.props.activeLang}
        connection={connection}
        fetching={this.props.fetching}
        fetchingConnection={this.props.fetchingConnection || this.props.fetchingConnectionTab}
        onSubmit={this.saveConfiguration}
        alwaysActiveTurnedOn={this.alwaysActiveTurnedOn}
        contingencyModeTurnedOn={this.contingencyModeTurnedOn}
        contingencyModeBlocked={this.contingencyModeBlocked}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    activeLang: state.i18n.activeLang,
    fetching: state.connections.fetchingConnectionForm,
    fetchingConnection: state.connections.fetchingConnection,
    fetchingConnectionTab: state.connections.fetchingConnectionTab,
    error: state.connections.errorConnection
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveAutoAccessConfiguration: (connectionId, autoAccessAlwaysActive, offlineAutoAccess) => 
      dispatch(ConnectionsActions.connectionAutoAccessConfigurationSaveRequest(connectionId, autoAccessAlwaysActive, offlineAutoAccess))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoAccessFormScreen)
import { Colors, Metrics } from '../../../Themes/'

export default {
  fullScreenContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'visibility 0.5s, opacity 0.5s linear',
    position: 'fixed',
    visibility: 'visible',
    opacity: 1,
    zIndex: Metrics.zIndexes.fullScreenLoader,
    top: 0,
    left: 0,
    backgroundColor: Colors.whiteTransparent
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  topRightContainer: {
    position: 'absolute',
    top: '-' + Metrics.doubleBaseMarginPlus,
    right: '-' + Metrics.baseMarginPlus
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0,
  }
}
const colors = {
  mainBackground: '#FFFCFD',
  cardBackground: '#FFFFFF',
  themeShadow1: 'rgba(189, 96, 119, 0.4)',
  themeShadow2: 'rgba(229, 19, 70, 0.4)',
  shadowFirst: 'rgba(60, 66, 87, 0.12)',
  shadowSecond: 'rgba(0, 0, 0, 0.12)',
  white: '#FFFFFF',
  whiteTransparent: 'rgba(255, 255, 255, 0.8)',
  blackTransparent: 'rgba(51, 51, 51, 0.9)',
  blackTransparentLight: 'rgba(51, 51, 51, 0.5)',
  theme1: '#E51346',
  theme2: '#9E0961',
  theme1Background: '#FFE6EC',
  darkGray: '#333333',
  helpsGray: '#ADADAD',
  mediumGray: '#777777',
  lightGray: '#CCCCCC',
  lighterGray: '#E2E2E2',
  lightGrayBackground: '#EFEFEF',
  transparent: 'rgba(0,0,0,0)',
  facebook: '#3B5998',
  facebookLight: '#E9F0FF',
  success: '#00C056',
  successLight: '#6DD19A',
  error: '#E51326',
  info: '#2374E8',
  yellow: '#F39C12',
  darkYellow: '#D5A400',
  lightYellow: '#FFFDF6',
  history: {
    error: '#E51326',
    callText: '#00C056',
    openDoorText: '#00C056',
    startJobText: '#F39C12',
    stopJobText: '#F39C12',
    doorIsOpenText: '#2374E8',
    doorIsClosedText: '#2374E8',
    deviceOnlineText: '#6DD19A',
    deviceOfflineText: '#E51326',
    movementDetected: '#9E0961',
    power: '#3B5998',
    physicalButton: '#333333',
    offlinePiston: '#FF7000',
    sleep: '#00C2C2',
    nuki: '#00B7C0',
    bellRing: '#006FC0'
  },
  integrations: {
    guesty: '#0372AD',
    raixer: '#9E0961'
  }
}

export default colors

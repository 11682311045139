import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import RaixerConfig from '../../../Config/RaixerConfig'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import HeaderAccess from './HeaderAccess'
import HeaderNotifications from './HeaderNotifications'
import HeaderButtons from './HeaderButtons'
import ConnectionHeader from '../../../Components/UI/Connections/ConnectionHeader'
import { objectDeepCopy } from '../../../Lib/ObjectsUtils'
import {
  connectionHasUsableDoors,
  getConnectionMinInUse,
  getConnectionRemainingTime,
  updateConnectionsWithConnection
} from '../../../Lib/ConnectionsUtils'
import { FormModalService } from '../../../Services/FormModal'

class Header extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired,
    inCheckin: PropTypes.bool,
    toggleCheckin: PropTypes.func
  }

  static defaultProps = {
    connection: null,
    inCheckin: null,
    toggleCheckin: null
  }

  constructor(props) {
    super(props)
    this.state = {
      interval: null
    }
  }

  componentDidUpdate = (prevProps) => {
    const { connection: connectionPrev } = prevProps
    const { connection } = this.props
    const { interval: intervalState } = this.state
    if (connectionPrev && connection) {
      const connectionPrevInUse = getConnectionMinInUse(connectionPrev)
      const connectionInUse = getConnectionMinInUse(connection)
      const remainingTime = getConnectionRemainingTime(connection.device)
      if ((!connectionPrevInUse && connectionInUse) || (connectionPrevInUse && connectionInUse)) {
        // Start interval because press of button or because connection had auto access started from the beginning
        if (!intervalState) { 
          const interval = setInterval(() => { this.forceUpdate() }, RaixerConfig.intervals.connectionTimer)
          this.setState({ interval })
        }
      } else if (connectionPrevInUse && !connectionInUse) {
        // Stop interval
        if (intervalState) {
          clearInterval(intervalState)
          this.setState({ interval: null })
        }
      }
      if (connectionInUse && !remainingTime) {
        let newConnection = objectDeepCopy(connection)
        try { newConnection.device.inUseComplete = connection.device.inUseComplete.map(() => false) }
        catch {}
        const newConnections = updateConnectionsWithConnection(this.props.connections, newConnection)
        this.props.updateConnections(newConnections)
      }
    }
    if (connectionPrev._id !== connection._id && intervalState) {
      clearInterval(intervalState)
      this.setState({ interval: null })
    }
  }

  componentWillUnmount = () => {
    if (this.state.interval) { clearInterval(this.state.interval) }
  }

  getConnection = () => {
    this.props.getConnection(this.props.connection._id)
  }

  goToCheckIn = () => {
    const { connection } = this.props
    const { _id, location } = connection
    if (location) { this.props.toggleCheckin() }
    else { FormModalService.open('location', { connectionId: _id, location }) }
  }

  showCheckIn = () => {
    try {
      const { connection } = this.props

      const userId = this.props.user._id

      const isOwner = connection.device.owner === userId
      if (isOwner) { return true }

      const isAdmin = connection.users.find((user) => user.owner === userId && user.profile === 'admin')
      if (isAdmin) { return true }
    } catch (e) {
      return false
    }
  }

  render = () => {
    const { connection, error, fetching } = this.props
    const { device = null, name } = connection || {}
    const { checkinSubscriptionId = null } = device || {}
    const hasUsableDoors = connectionHasUsableDoors(connection)
    return (
      <>
        <ConnectionHeader
          notifications={<HeaderNotifications connection={connection} />}
          buttons={<HeaderButtons connection={connection} />}
          connection={connection}
          title={name || device.campaign.name }
          fetching={fetching}
          error={error}
          hasUsableDoors={hasUsableDoors}
          getConnection={this.getConnection}
        />
        {
          checkinSubscriptionId !== null && this.showCheckIn()
            ? <HeaderAccess connection={connection} goToCheckIn={this.goToCheckIn} inCheckin={this.props.inCheckin} />
            : null
        }
      </>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    connections: state.connections.connections,
    fetching: state.connections.fetchingConnection,
    error: state.connections.errorConnection
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConnection: (connectionId) => dispatch(ConnectionsActions.connectionRequest(connectionId)),
    updateConnections: (connections) => dispatch(ConnectionsActions.connectionsTaskFinished(connections))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { timestampToDate, timestampToTime } from '../../../Lib/DateTimeUtils'
import AppText from '../Texts/AppText'
import styles from '../../Styles/UI/ListItem'
import stylesHistory from '../../Styles/UI/Connections/History'

export default class HistoryListItem extends Component {

  static propTypes = {
    message: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    dateEnd: PropTypes.string,
    borderColor: PropTypes.string.isRequired,
    name: PropTypes.string,
    details: PropTypes.string,
    lastOfList: PropTypes.bool
  }

  static defaultProps = {
    message: null,
    date: null,
    dateEnd: null,
    borderColor: null,
    name: null,
    details: null,
    lastOfList: false
  }

  render = () => {
    let timeString = timestampToTime(this.props.date)
    if (this.props.dateEnd) { timeString += ' - ' + timestampToTime(this.props.dateEnd) }
    return (
      <div style={!this.props.lastOfList ? styles.listItem : Object.assign({}, styles.listItem, styles.lastListItem)}>
        <div style={stylesHistory.container}>
          <div style={Object.assign({}, stylesHistory.dateContainer, { borderColor: this.props.borderColor })}>
            <AppText 
              text={timestampToDate(this.props.date, true)} 
              numberOfLines={1}
            />
            <AppText 
              text={timeString} 
              customStyle={stylesHistory.textLastLine}
            />
          </div>
          <div style={stylesHistory.textContainer}>
            { this.props.name && <AppText text={this.props.name} /> }
            <AppText text={this.props.message} customStyle={this.props.name ? stylesHistory.textLastLine : {}} />
            { this.props.details && <AppText text={this.props.details} customStyle={stylesHistory.textLastLine} /> }
          </div>
        </div>
      </div>
    )
  }

}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getConnectionDeviceOwner } from '../../../../Lib/ConnectionsUtils'
import { timestampToDate, timestampToTime } from '../../../../Lib/DateTimeUtils'
import I18n from '../../../../i18n/i18n'
import AppText from '../../../UI/Texts/AppText'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import MainButton from '../../../UI/Buttons/MainButton'
import ConnectionsStatus from '../../../UI/Connections/ConnectionsStatus'
import WeekdaySelector from '../../../UI/Selectors/WeekdaysSelector'
import styles from '../../../Styles/UI/ListItem'
import { Metrics } from '../../../../Themes'

export default class Empty extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired,
    error: PropTypes.bool,
    goToConnectionUpgrade: PropTypes.func.isRequired
  }

  static defaultProps = {
    connection: null,
    error: null,
    goToConnectionUpgrade: null
  }

  renderDates = () => {
    return (
      <>
        <div style={Object.assign({}, styles.listItemInner, { marginBottom: Metrics.baseMargin })}>
          <div style={styles.listItemLeft}>
            <AppText 
              text={I18n.t('shared.from')} 
              customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'left' })}
              numberOfLines={1}
            />
          </div>
          <div style={Object.assign({}, styles.listItemRight, styles.listItemRightRow)}>
            <AppText text={timestampToTime(this.props.connection.authorizedFrom)} customStyle={Object.assign({}, styles.listItemContent, { marginRight: Metrics.smallMargin })} numberOfLines={1} />
            <AppText text={timestampToDate(this.props.connection.authorizedFrom)} customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'right' })} numberOfLines={1} />
          </div>
        </div>
        <div style={styles.listItemInner}>
          <div style={styles.listItemLeft}>
            <AppText 
              text={I18n.t('shared.to')} 
              customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'left' })}
              numberOfLines={1}
            />
          </div>
          <div style={Object.assign({}, styles.listItemRight, styles.listItemRightRow)}>
            <AppText text={timestampToTime(this.props.connection.authorizedTo)} customStyle={Object.assign({}, styles.listItemContent, { marginRight: Metrics.smallMargin })} numberOfLines={1} />
            <AppText text={timestampToDate(this.props.connection.authorizedTo)} customStyle={styles.listItemContent} numberOfLines={1} />
          </div>
        </div>
      </>
    )
  }

  renderRepeat = () => {
    return (
      <>
        <div style={Object.assign({}, styles.listItemInner, { marginBottom: Metrics.smallMargin })}>
          <div style={Object.assign({}, styles.listItemLeft, this.props.customLeftStyle)}>
            <AppText text={I18n.t('shared.hours')} customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'left' })} numberOfLines={1} />
          </div>
          <div style={Object.assign({}, styles.listItemRight, this.props.customRightStyle)}>
            <AppText text={this.props.connection.repeatStartHour + ' - ' + this.props.connection.repeatEndHour} customStyle={styles.listItemContent} numberOfLines={1} />
          </div>
        </div>
        <WeekdaySelector 
          value={this.props.connection.repeatDaysOfWeek} 
          editable={false} 
          customContainerStyle={{ marginTop: Metrics.baseMargin }}
        />
      </>
    )
  }

  renderDisabled = () => {
    const { 
      authorizedFrom, authorizedTo, repeatStartHour, repeatEndHour, repeatDaysOfWeek, enabledForUse = true
    } = this.props.connection || {}
    const text = enabledForUse ? I18n.t('screens.connection.error.disabled') : I18n.t('screens.connection.error.disabledForUse')
    const deviceOwner = getConnectionDeviceOwner(this.props.connection)
    const { ownerEmail = '', ownerName = '' } = deviceOwner || {}
    const ownerText = (ownerName + ' ' + ownerEmail).trim()
    return (
      <ContentCard
        renderContent={() => (
          <>
            <div style={{ marginVertical: Metrics.baseMargin }} />
            <ConnectionsStatus
              text={text}
              boldEndText={ownerText ? '\n' + ownerText : null}
              icon={'block'}
              withoutMargin={true}
            />
            {
              ((authorizedFrom && authorizedTo) || (repeatStartHour && repeatEndHour && repeatDaysOfWeek)) && enabledForUse &&
              <div style={{ marginVertical: Metrics.doubleBaseMargin }} />
            }
            {
              authorizedFrom && authorizedTo && enabledForUse &&
              this.renderDates()
            }
            {
              repeatStartHour && repeatEndHour && repeatDaysOfWeek && enabledForUse &&
              this.renderRepeat()
            }
          </>
        )}
      />
    )
  }

  getTextAndIcon = () => {
    const { device } = this.props.connection || {}
    const { trialEnded = false, trialEndedAccount = false } = device || {}
    let text = I18n.t('screens.connection.error.disabled')
    let icon = 'block'
    if (this.props.error) {
      text = I18n.t('screens.connection.error.unexpected')
      icon = 'warning'
    }
    if ((trialEnded || trialEndedAccount)) {
      text = I18n.t('screens.connection.error.trialEnded')
      icon = 'warning'
    }
    return { text, icon }
  }

  render = () => {
    const { device, owner: connectionOwner, disabled = false } = this.props.connection || {}
    const { trialEnded = false, owner: deviceOwner } = device || {}
    const { text, icon } = this.getTextAndIcon()
    return (
      <>
        { 
          disabled
          ? this.renderDisabled()
          : <ConnectionsStatus text={text} icon={icon} />
        }
        {
          trialEnded && deviceOwner === connectionOwner &&
          <div style={{ paddingHorizontal: Metrics.doubleBaseMargin }}>
            <MainButton
              buttonText={I18n.t('shared.pay')}
              onPress={this.props.goToConnectionUpgrade}
              disabled={false}
              icon={'rocket'}
              customButtonStyle={{ marginVertical: 0, marginBottom: 0, marginTop: Metrics.cuadrupleBaseMargin }}
            />
          </div>
        }
      </>
    )
  }

}
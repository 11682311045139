import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { inputDateFormat } from '../../../Lib/DateTimeUtils'
import RaixerConfig from '../../../Config/RaixerConfig'
import I18n from '../../../i18n/i18n'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import CheckIn from '../../../Components/Screens/Connection/Tabs/Checkin'
import { ModalService } from '../../../Services/Modal'
import { FormModalService } from '../../../Services/FormModal'

class CheckInTab extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired
  }

  static defaultProps = {
    connection: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      locationId: null,
      bookingsPage: 0
    }
  }

  componentDidMount = () => {
    const { connection } = this.props
    const { _id, location } = connection || {}
    const { _id: locationId, bookings = [] } = location || {}
    this.setState({ connectionId: _id, locationId, bookingsPage: bookings.length < RaixerConfig.connections.historyPageSize ? null : 1 })
  }

  componentDidUpdate = (prevProps) => {
    const { bookingsPage } = this.state
    const { connection: connectionPrev, fetchingForm: fetchingFormPrev } = prevProps
    const { connection, fetchingForm } = this.props
    const { location: prevLocation } = connectionPrev
    const { bookings: prevBookings } = prevLocation || {}
    const { location } = connection || {}
    const { bookings } = location || {}
    if (fetchingFormPrev && !fetchingForm && bookingsPage !== null) {
      if (prevBookings && bookings) {
        if (bookings.length - prevBookings.length < RaixerConfig.connections.historyPageSize) {
          this.setState({ bookingsPage: null })
        }
      } else if (!prevBookings && bookings) {
        if (bookings.length < RaixerConfig.connections.historyPageSize) {
          this.setState({ bookingsPage: null })
        }
      }
    }
  }

  onScroll = (e) => {
    const { fetchingForm } = this.props
    const { connectionId, locationId, bookingsPage } = this.state
    const { target } = e || {}
    const { scrollHeight, scrollTop, clientHeight } = target
    if (
      scrollHeight - scrollTop > clientHeight - 50 && scrollHeight - scrollTop < clientHeight + 50 && !fetchingForm && bookingsPage !== null
    ) {
      this.props.getBookings(connectionId, locationId, bookingsPage, false)
      this.setState({ bookingsPage: bookingsPage + 1 })
    }
  }

  goToLocationForm = (location) => {
    FormModalService.open('location', { connectionId: this.state.connectionId, location })
  }

  goToBookingForm = (booking) => {
    FormModalService.open('booking', { connectionId: this.state.connectionId, booking })
  }

  goToGuestBook = (location) => {
    FormModalService.open('guest-book', { connectionId: this.state.connectionId, location })
  }

  goToGuestForm = (bookingId) => {
    FormModalService.open('guest', { connectionId: this.state.connectionId, bookingId })
  }

  goToBookingsHistory = (locationId) => {
    FormModalService.open('booking-history', { connectionId: this.state.connectionId, locationId })
  }

  shareBookingUrl = (bookingId) => {
    const { connection } = this.props
    const { location } = connection || {}
    const { bookings = [], locationName } = location || {}
    const booking = bookings.filter(b => b._id === bookingId)[0]
    const { startDate } = booking || []
    const url = RaixerConfig.api.baseUrl + 'guests/' + this.props.activeLang + '/' + bookingId
    const text = I18n.t('modals.shareBookingLink.text') + '\n\n' + 
      `${I18n.t('shares.booking.message1')}${locationName} ${I18n.t('shares.booking.message2')}${inputDateFormat(startDate)}\n` +
      `${url}`
    ModalService.show(
      I18n.t('modals.shareBookingLink.title'),
      text,
      null,
      null,
      I18n.t('modals.buttons.defaultOk')
    )
  }

  render = () => {
    return (
      <CheckIn
        connection={this.props.connection}
        fetching={this.props.fetching}
        fetchingForm={this.props.fetchingForm}
        onScroll={this.onScroll}
        goToLocationForm={this.goToLocationForm}
        goToBookingForm={this.goToBookingForm}
        openGuestBook={this.goToGuestBook}
        openGuestForm={this.goToGuestForm}
        goToBookingsHistory={this.goToBookingsHistory}
        shareBookingUrl={this.shareBookingUrl}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    activeLang: state.i18n.activeLang,
    fetching: state.connections.fetchingConnection,
    fetchingForm: state.connections.fetchingConnectionForm
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBookings: (connectionId, locationId, page, beforeToday) =>
      dispatch(ConnectionsActions.connectionGetBookingsRequest(connectionId, locationId, page, beforeToday))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckInTab)
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getConnectionQuickActions, getConnectionDoorNameByPort, connectionIsGyroDevice } from '../../../Lib/ConnectionsUtils'
import I18n from '../../../i18n/i18n'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import ConnectionHeaderButtons from '../../../Components/UI/Connections/ConnectionHeaderButtons'
import { ModalService } from '../../../Services/Modal'

class HeaderButtons extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired
  }

  static defaultProps = {
    connection: null
  }

  executeQuickActionTask = (button, index) => {
    const isGyroDevice = connectionIsGyroDevice(this.props.connection)
    const { task, bleTask, nuki, gyro, type, intercomCallFirst } = button
    const { _id, device } = this.props.connection
    const { autoAccessAlwaysActive, autoAccessBlocked = false, offlineGyro = false } = device
    const { confirmDeviceOpenClose = false } = this.props.userSettings || {}

    if (isGyroDevice) {
      if (!bleTask) {
        this.props.postDeviceTask(this.props.connection, task, index)
      } else {
        const port = task.substr(task.indexOf('/') + 1)
        const doorName = getConnectionDoorNameByPort(this.props.connection, port)

        const title = task === 'action/open' ? I18n.t('modals.doorOpenConfirm.title') : I18n.t('modals.doorCloseConfirm.title')
        const text = task === 'action/open' ? I18n.t('modals.doorOpenConfirm.text') : I18n.t('modals.doorCloseConfirm.text')
        const textButtonOk = task === 'action/open' ? I18n.t('modals.doorOpenConfirm.buttons.ok') : I18n.t('modals.doorCloseConfirm.buttons.ok')

        if (confirmDeviceOpenClose) {
          ModalService.show(
            title,
            text + ' ' + doorName + '?',
            () => {
              this.props.executeInternetAndBleTask(this.props.connection, task, bleTask, index)
            },
            textButtonOk,
            I18n.t('modals.buttons.defaultCancel'),
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            true
          )
        } else {
          this.props.executeInternetAndBleTask(this.props.connection, task, bleTask, index)
        }
        if (offlineGyro) {
          // TODO: check if this is needed and replicate code from app
          // this.props.executeBleTask(_id, bleTask, index)
        }
      }
    } else if (nuki) {
      this.props.postNukiTask(_id, task, index)
    } else if (gyro) {
      const title = task === 'action/open' ? I18n.t('modals.doorOpenConfirm.title') : I18n.t('modals.doorCloseConfirm.title')
      const text = task === 'action/open' ? I18n.t('modals.doorOpenConfirm.text') : I18n.t('modals.doorCloseConfirm.text')
      const buttonOk = task === 'action/open' ? I18n.t('modals.doorOpenConfirm.buttons.ok') : I18n.t('modals.doorCloseConfirm.buttons.ok')
      if (confirmDeviceOpenClose) {
        ModalService.show(
          title,
          `${text}?`,
          () => { this.props.executeInternetTask(this.props.connection, task, index) },
          buttonOk,
          I18n.t('modals.buttons.defaultCancel'),
          true,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      } else {
        this.props.executeInternetTask(this.props.connection, task, index)
      }
    } else {
      if (type === 'access' && autoAccessAlwaysActive) {
        ModalService.show(
          I18n.t('modals.autoAccessBlocked.title'),
          I18n.t('modals.autoAccessBlocked.text'),
          null,
          null,
          I18n.t('modals.buttons.defaultOk')
        )
      } else if (type === 'access' && autoAccessBlocked) {
        ModalService.show(
          I18n.t('modals.autoAccessBlockedByDevice.title'),
          I18n.t('modals.autoAccessBlockedByDevice.text'),
          null,
          null,
          I18n.t('modals.buttons.defaultOk')
        )
      } else if (type === 'door' && intercomCallFirst) {
        if (confirmDeviceOpenClose) {
          ModalService.show(
            I18n.t('modals.intercomCallFirst.title'),
            I18n.t('modals.intercomCallFirst.text'),
            () => {
              this.props.postDeviceTask(this.props.connection, task, index)
            },
            I18n.t('modals.intercomCallFirst.buttons.ok'),
            null,
            true
          )
        } else {
          this.props.postDeviceTask(this.props.connection, task, index)
        }
      } else if (type === 'door' && !intercomCallFirst) {
        const port = task.substr(task.indexOf('/') + 1)
        const doorName = getConnectionDoorNameByPort(this.props.connection, port)
        
        if (confirmDeviceOpenClose) {
          ModalService.show(
            I18n.t('modals.doorOpenConfirm.title'),
            I18n.t('modals.doorOpenConfirm.text') + ' ' + doorName + '?',
            () => { this.props.postDeviceTask(this.props.connection, task, index) },
            I18n.t('modals.doorOpenConfirm.buttons.ok'),
            I18n.t('modals.buttons.defaultCancel'),
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            true
          )
        } else {
          this.props.postDeviceTask(this.props.connection, task, index)
        }
      } else {
        this.props.postDeviceTask(this.props.connection, task, index)
      }
    }
  }

  render = () => {
    const { appGyroShowUnlockButton = false } = this.props.configuration || {}
    const { device = {}, disabled = false } = this.props.connection
    const { online = false, trialEndedAccount = false, offlineGyro } = device
    const isGyroDevice = connectionIsGyroDevice(this.props.connection)

    let disabledValue = !online || disabled || trialEndedAccount
    if (isGyroDevice) { disabledValue = !offlineGyro ? true : online }
    if (isGyroDevice && offlineGyro) { disabledValue = true }
    if (isGyroDevice && online) { disabledValue = false }
    
    return (
      this.props.connection &&
      <ConnectionHeaderButtons
        buttons={getConnectionQuickActions(this.props.connection, this.props.activeLang, appGyroShowUnlockButton)}
        executeQuickActionTask={this.executeQuickActionTask}
        fetching={this.props.fetching}
        fetchingTask={this.props.fetchingTask}
        disabled={disabledValue}
        error={this.props.error}
        activeConnectionButton={this.props.activeConnectionButton}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    userSettings: state.user.userSettings,
    fetching: state.connections.fetchingConnection,
    fetchingTask: state.connections.fetchingTask,
    error: state.connections.errorTask,
    activeConnectionButton: state.connections.activeConnectionButton,
    activeLang: state.i18n.activeLang,
    configuration: state.configuration.configuration
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postDeviceTask: (connection, task, activeConnectionButton) => 
      dispatch(ConnectionsActions.connectionsTaskRequest(connection, task, false, activeConnectionButton)),
    postNukiTask: (connectionId, task, activeConnectionButton) => dispatch(ConnectionsActions.connectionNukiTaskRequest(connectionId, task, activeConnectionButton)),
    executeBleTask: (connectionId, task, activeConnectionButton) =>
        dispatch(ConnectionsActions.connectionBleTaskRequest(connectionId, task, activeConnectionButton)),
    executeInternetTask: (connection, task, activeConnectionButton) =>
      dispatch(ConnectionsActions.connectionInternetTaskRequest(connection, task, activeConnectionButton)),
    executeInternetAndBleTask: (connection, task, bleTask, activeConnectionButton) =>
        dispatch(ConnectionsActions.connectionInternetAndBleTaskRequest(connection, task, bleTask, activeConnectionButton)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderButtons)
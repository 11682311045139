import React, { Component } from 'react'
import { connect } from 'react-redux'
import RaixerConfig from '../../Config/RaixerConfig'
import AuthActions from '../../Redux/AuthRedux'
import AuthBackground from '../../Components/UI/Backgrounds/AuthBackground'
import Signup from '../../Components/Screens/Auth/Signup'
import styles from '../Styles/Screens'

class SignupScreen extends Component {

  onSubmit = (value) => {
    const { displayName, email, password } = value
    this.props.signup(displayName, email, password)
  }

  onFacebookPress = () => {
    this.props.facebookLogin()
  }

  goToLogin = () => {
    this.props.history.push('/')
  }

  goToTerms = () => {
    window.open(RaixerConfig.webviews.termsUri, '_blank')
  }

  render = () => {
    return (
      <div style={styles.mainContainer}>
        <AuthBackground />
        <div style={styles.authContainer}>
          <Signup
            onSubmit={this.onSubmit}
            onFacebookPress={this.onFacebookPress}
            goToLogin={this.goToLogin}
            goToTerms={this.goToTerms}
            fetching={this.props.fetching}
            activeLang={this.props.activeLang}
          />
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    fetching: state.auth.fetching,
    activeLang: state.i18n.activeLang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signup: (displayName, email, password) => dispatch(AuthActions.registerRequest(displayName, email, password)),
    facebookLogin: () => dispatch(AuthActions.facebookRequest())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupScreen)
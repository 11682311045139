import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  startup: null,
  startupFinished: null
})

export const StartupTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  fetching: true
})

/* ------------- Reducers ------------- */

export const startup = (state) => {
  return state.merge({ fetching: true })
}

export const startupFinished = (state) => {
  return state.merge({ fetching: false })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.STARTUP]: startup,
  [Types.STARTUP_FINISHED]: startupFinished
})

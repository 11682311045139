import React, { Component } from 'react'
import PropTypes from 'prop-types'
import QRCode from 'react-qr-code'
import I18n from '../../../i18n/i18n'
import SmallLoader from '../Loaders/SmallLoader'
import AppText from '../Texts/AppText'
import styles from '../../Styles/UI/Qrs'
import { Colors } from '../../../Themes'

export default class Qr extends Component {

  static propTypes = {
    fetching: PropTypes.bool.isRequired,
    url: PropTypes.string
  }

  static defaultProps = {
    fetching: null,
    url: null
  }

  render = () => {
    return (
      <div style={styles.qrCodeContainer}>
        <div style={Object.assign({}, styles.qrCode, styles.indicatorContainer)}>
          {
            !this.props.fetching && this.props.url &&
            <div style={{ overflow: 'hidden' }}>
              <QRCode
                value={this.props.url}
                bgColor={Colors.white}
                fgColor={Colors.darkGray}
              />
            </div>
          }
          {
            this.props.fetching && !this.props.url &&
            <SmallLoader visible={true} />
          }
        </div>
        {
          this.props.url &&
          <AppText
            text={I18n.t('shared.qrValidity')}
            customStyle={styles.qrCodeDescription}
          />
        }
      </div>
    )
  }

}

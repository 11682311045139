import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RaixerConfig from '../../../Config/RaixerConfig'
import I18n from '../../../i18n/i18n'
import AppText from '../Texts/AppText'
import LineItemEditButton from '../Buttons/LineItemEditButton'
import SimpleIconButton from '../Buttons/SimpleIconButton'
import WeekdaySelector from '../Selectors/WeekdaysSelector'
import { Colors, Fonts, Metrics } from '../../../Themes'
import styles from '../../Styles/UI/ListItem'

export default class RepeatListItem extends Component {

  static propTypes = {
    leftText: PropTypes.string.isRequired,
    additionalLeftText: PropTypes.string,
    rightText: PropTypes.string.isRequired,
    repeatDaysOfWeek: PropTypes.string.isRequired,
    repeatStartHour: PropTypes.string.isRequired,
    repeatEndHour: PropTypes.string.isRequired,
    withEditButton: PropTypes.bool.isRequired,
    repeatHoursText: PropTypes.string,
    lastOfList: PropTypes.bool,
    onPressEdit: PropTypes.func,
    editButtonFlex: PropTypes.number,
    customLeftStyle: PropTypes.object,
    customRightStyle: PropTypes.object,
    customLeftTextStyle: PropTypes.object,
    customRightTextStyle: PropTypes.object,
    customAdditionalLeftTextStyle: PropTypes.object,
    onListItemIcon: PropTypes.func
  }

  static defaultProps = {
    leftText: null,
    additionalLeftText: null,
    rightText: null,
    repeatDaysOfWeek: null,
    repeatStartHour: null,
    repeatEndHour: null,
    withEditButton: null,
    repeatHoursText: I18n.t('shared.hours'),
    lastOfList: false,
    onPressEdit: () => { console.log() },
    editButtonFlex: 0,
    customLeftStyle: {},
    customRightStyle: {},
    customLeftTextStyle: {},
    customRightTextStyle: {},
    customAdditionalLeftTextStyle: {},
    onListItemIcon: null
  }

  render = () => {
    return (
      <div style={!this.props.lastOfList ? styles.listItem : Object.assign({}, styles.listItem, styles.lastListItem)}>
        <div style={styles.listItemInner}>
          <div style={Object.assign({}, styles.listItemInnerColumn, { flex: RaixerConfig.animations.lists.itemFlex })}>
            <div style={Object.assign({}, styles.listItemInner, { marginBottom: Metrics.baseMargin })}>
              <div style={Object.assign({}, styles.listItemLeft, styles.listItemImportant, this.props.customLeftStyle)}>
                { 
                  this.props.additionalLeftText !== null && this.props.additionalLeftText !== '' && 
                  <AppText
                    text={this.props.additionalLeftText}
                    customStyle={Object.assign(
                      {},
                      styles.listItemContent,
                      { textAlign: 'left', marginBottom: Metrics.baseMargin },
                      this.props.customAdditionalLeftTextStyle
                    )}
                    numberOfLines={1}
                  />
                }
                <AppText
                  text={this.props.leftText}
                  customStyle={Object.assign({}, styles.listItemTitle, this.props.customLeftTextStyle)}
                  numberOfLines={1}
                />
              </div>
              {
                this.props.onListItemIcon &&
                <SimpleIconButton
                  onPress={this.props.onListItemIcon}
                  icon={'share'}
                  active={true}
                  customButtonStyle={{ margin: 0, padding: 0 }}
                  customIconStyle={{ color: Colors.theme1, fontSize: Fonts.size.regular }}
                />
              }
              <div style={Object.assign({}, styles.listItemRight, this.props.customRightStyle)}>
                <AppText
                  text={this.props.rightText}
                  customStyle={Object.assign({}, styles.listItemContent, this.props.customRightTextStyle)}
                  numberOfLines={1}
                />
              </div>
            </div>
            <div style={Object.assign({}, styles.listItemInner, { marginBottom: Metrics.smallMargin })}>
              <div style={Object.assign({}, styles.listItemLeft, this.props.customLeftStyle)}>
                <AppText
                  text={this.props.repeatHoursText}
                  customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'left' })}
                  numberOfLines={1}
                />
              </div>
              <div style={Object.assign({}, styles.listItemRight, this.props.customRightStyle)}>
                <AppText
                  text={this.props.repeatStartHour + ' - ' + this.props.repeatEndHour}
                  customStyle={styles.listItemContent}
                  numberOfLines={1}
                />
              </div>
            </div>
            <WeekdaySelector 
              value={this.props.repeatDaysOfWeek} 
              editable={false} 
              customContainerStyle={{ marginTop: Metrics.baseMargin }}
            />
          </div>
          <div style={Object.assign({}, styles.listItemEditButtonContainer, { flex: this.props.editButtonFlex })}>
            <LineItemEditButton
              buttonText={I18n.t('shared.edit')}
              onPress={this.props.onPressEdit}
              disabled={false}
              customButtonStyle={{ marginBottom: 0, marginLeft: Metrics.doubleBaseMargin }}
            />
          </div>
        </div>
      </div>
    )
  }

}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { objectDeepCopy } from '../../../Lib/ObjectsUtils'
import I18n from '../../../i18n/i18n'
import WeekdaySelectorButton from './WeekdaySelectorButton'
import styles from '../../Styles/UI/Selectors'

/*
 * A visual selector for weekdays based on buttons
 * When the state changes it publish a string separated by commas of the selected days, or empty strig if none are selected
 * The days are:
 *  Sunday: 0
 *  Monday: 1
 *  Tuesday: 2
 *  Wednesday: 3
 *  Thursday: 4
 *  Friday: 5
 *  Saturday: 6
 */
export default class WeekdaysSelector extends Component {

  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    editable: PropTypes.bool,
    weekdays: PropTypes.array,
    customContainerStyle: PropTypes.object
  }

  static defaultProps = {
    onChange: null,
    value: null,
    editable: true,
    weekdays: I18n.t('shared.weekdays').split(','),
    customContainerStyle: {}
  }

  constructor(props) {
    super(props)
    this.state = {
      value: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false
      }
    }
  }

  componentDidMount = () => {
    const { value = '' } = this.props
    if (value !== '') {
      let newValue = {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false
      }
      for (let v of value.split(',')) {
        newValue[v] = true
      }
      this.setState({ value: newValue })
    }
  }

  componentDidUpdate = (prevProps) => {
    const { editable: prevEditable, value: prevValue } = prevProps
    const { editable, value } = this.props
    if (!prevEditable && !editable) {
      if (prevValue !== value) {
        let newValue = {
          0: false,
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false
        }
        for (let v of value.split(',')) {
          newValue[v] = true
        }
        this.setState({ value: newValue })
      }
    }
  }

  onPressButton = (index) => {
    let value = objectDeepCopy(this.state.value)
    value[index] = !value[index]
    this.setState({ value })
    if (this.props.onChange) {
      this.props.onChange(this.getUpdatedValueString(value))
    }
  }

  getUpdatedValueString = (value) => {
    let selected = Object.keys(value).map((k) => {
      const v = value[k]
      if (v) { return k }
      else { return null }
    }).filter(x => x)
    return selected.join(',')
  }

  renderButton = (day, arrayIndex) => {
    // To set Sunday as the first day of week
    const index = arrayIndex === 6 ? 0 : arrayIndex + 1
    return (
      <WeekdaySelectorButton
        key={'weekday-' + index}
        buttonText={day}
        onPress={() => { this.onPressButton(index) }}
        selected={this.state.value[index]}
        disabled={!this.props.editable}
        isLast={arrayIndex === 6}
      />
    )
  }

  render = () => {
    return (
      <div style={Object.assign({}, styles.weekdaySelectorContainer, this.props.customContainerStyle)}>
        {
          this.props.weekdays.map((wd, index) => this.renderButton(wd, index))
        }
      </div>
    )
  }

}

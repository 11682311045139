import RaixerConfig from '../../Config/RaixerConfig'
import I18n from '../../i18n/i18n'
import { connectionDeviceIsParticleDisabled, connectionHasStreetDoor, connectionHasGyroDoor } from '../ConnectionsUtils'
import { getRemainingDays, getRemainingDaysUnpaidSince } from '../DateTimeUtils'
import { Colors } from '../../Themes'

const { connections: RaixerConfigConnections } = RaixerConfig
const { deviceVersions, warningsActions } = RaixerConfigConnections

export const getConnectionNotifications = (
  connection,
  hasAutoAccessDoor,
  showFreeProfile = false,
  isFreeVersionDevice = false,
  userHasConnectionsAvailableForCheckin = false,
  isProWithGyroDoor = false,
  isMiniWithGyroDoor = false,
) => {
  try {
    const { deviceId, device, owner: connectionOwner, profile, disabled } = connection
    const { 
      campaign, created, offlineAutoAccess, online, owner: deviceOwner, ownerApiUser, ownerPaymentOk, trialEnded = false,
      trialEndedAccount = false, updating = false, openClosedBlocked = false, autoAccessBlocked = false,
      firmwareUpdateAvailable = false, nukiBatteryCritical = false, exhibitor = false, asUnsubscribedDate = null,
      version, offlineGyro = false, selectedPlan = null, subscriptionStart = null, electricity = null, battery = null,
      controlIntercomDismissed = false
    } = device || {}
    const { subscriptionId, subscription } = campaign || {}
    const { unpaidSince } = subscription || {}
    let warnings = _getVitalsWarnings(connection)
    if (!deviceId) {
      // Checkin connection without device warnings
      warnings.push({ text: I18n.t('screens.connection.warnings.noRaixerDevice'), actionName: warningsActions.noRaixerDevice, color: Colors.info, icon: 'rocket' })
      if (userHasConnectionsAvailableForCheckin) {
        warnings.push({ text: I18n.t('screens.connection.warnings.linkRaixerDevice'), actionName: warningsActions.linkRaixerDevice, color: Colors.info, icon: 'link' })
      }
    } else {
      if (!offlineGyro) {
        const deviceConnectionWarnings = _getDeviceConnectionWarnings(
          connection,
          deviceOwner,
          connectionOwner,
          profile,
          online,
          updating,
          firmwareUpdateAvailable,
          offlineAutoAccess,
          hasAutoAccessDoor,
          openClosedBlocked,
          autoAccessBlocked,
          nukiBatteryCritical,
          isProWithGyroDoor,
          isMiniWithGyroDoor,
        )
        for (let dcw of deviceConnectionWarnings) { warnings.push(dcw) }
      }
    }
    if (version === deviceVersions.gyro.id) {
      const hasStreetDoor = connectionHasStreetDoor(connection)
      const gyroWarnings = _getGyroWarnings(deviceOwner, connectionOwner, profile, disabled, offlineGyro, hasStreetDoor, selectedPlan, subscriptionStart, electricity, battery, controlIntercomDismissed)
      for (let gw of gyroWarnings) { warnings.push(gw) }
    }
    if (ownerApiUser) {
      if (!ownerPaymentOk) {
        warnings.push({ text: I18n.t('screens.connection.warnings.apiUserPaymentFailed'), actionName: null, color: Colors.yellow, icon: 'warning' })
      }
    } else {
      if (!disabled && deviceId) {
        if (showFreeProfile && isFreeVersionDevice) {
          const freeProfileWarnings = _getFreeProfileWarnings(deviceOwner, connectionOwner, created, trialEnded, trialEndedAccount, unpaidSince, asUnsubscribedDate, subscriptionId, exhibitor)
          for (let fpw of freeProfileWarnings) { warnings.push(fpw) }
        } else {
          const paymentWarnings = _getPaymentWarnings(deviceOwner, connectionOwner, version, created, trialEnded, unpaidSince, asUnsubscribedDate, subscriptionId, exhibitor)
          for (let pw of paymentWarnings) { warnings.push(pw) }
        }
      }
    }
    return warnings
  } catch {
    return []
  }
}

const _getVitalsWarnings = (connection) => {
  try {
    const { device } = connection || {}
    const { online, version, vitals, offlineGyro } = device || {}
    const isCellular = version === deviceVersions.v3.id || version === deviceVersions.v6.id
    const isPro = version === deviceVersions.v6.id || version === deviceVersions.v7.id
    const isGyro = version === deviceVersions.gyro.id
    let vitalsWarnings = []
    if (vitals) {
      const { signal, hasElectricity, power } = vitals
      const { battery } = power || {}
      if (signal) {
        if (!isGyro || (isGyro && !offlineGyro)) {
          const { strength } = signal
          if (strength !== null && strength !== undefined) {
            let text = I18n.t('screens.connection.warnings.signal.poor')
            let color = Colors.error
            if (strength <= 20) {
              text = I18n.t('screens.connection.warnings.signal.bad')
            } else if (strength > 20 && strength <= 50) {
              text = I18n.t('screens.connection.warnings.signal.fair')
              color = Colors.yellow
            } else if (strength > 50 && strength <= 70) {
              text = I18n.t('screens.connection.warnings.signal.good')
              color = Colors.success
            } else if (strength > 70) {
              text = I18n.t('screens.connection.warnings.signal.strong')
              color = Colors.success
            }
            text = (isCellular ? I18n.t('screens.connection.warnings.signal.cellular') : I18n.t('screens.connection.warnings.signal.wifi')) + ' ' + text
            if (online) { vitalsWarnings.push({ text, actionName: warningsActions.refreshVitals, color, icon: 'bar-graph' }) }
          }
        }
      }
      if (hasElectricity !== undefined && hasElectricity !== null) {
        let icon = 'progress-two'
        let color = Colors.yellow
        let text = I18n.t('screens.connection.warnings.power.usingBattery')
        if (hasElectricity === 1) {
          icon = 'battery'
          color = Colors.success
          text = I18n.t('screens.connection.warnings.power.electricity')
        }
        vitalsWarnings.push({ text, actionName: null, color, icon })
      }
      if (isPro && battery) {
        const { charge, state } = battery
        const parsedCharge = parseInt(charge)
        if (state !== 'disconnected' && !isNaN(parsedCharge)) {
          let color = Colors.error
          let text = I18n.t('screens.connection.warnings.battery.battery') + ': ' + parsedCharge + '%'
          if (state === 'charging') { text += ' (' + I18n.t('screens.connection.warnings.battery.charging').toLowerCase() + ')' }
          else if (state === 'charged') { text += ' (' + I18n.t('screens.connection.warnings.battery.charged').toLowerCase() + ')' }
          else { text += ' (' + I18n.t('screens.connection.warnings.battery.discharging').toLowerCase() + ')' }
          if (parsedCharge >= 30 && parsedCharge < 70) { color = Colors.yellow }
          else if (parsedCharge >= 70) { color = Colors.success }
          vitalsWarnings.push({ text, actionName: warningsActions.refreshVitalsBattery, color, icon: 'battery' })
        }
      }
    }
    /*
    else if (version === RaixerConfig.connections.deviceVersions.gyro.id && !offlineGyro) {
      // Gyro is not a BLE only and has no internet connection right now (vitals are missing)
      const text = I18n.t('screens.connection.warnings.gyroNoInternet')
      const color = Colors.yellow
      vitalsWarnings.push({ text, actionName: warningsActions.gyroNoInternet, color, icon: 'bar-graph' })
    }
    */
    /*
    if (isPro && connectionHasGyroDoor(connection)) {
      // TODO:
    }
    */
    return vitalsWarnings
  } catch {
    return []
  }
}

const _getGyroWarnings = (deviceOwner, connectionOwner, profile, disabled, offlineGyro, hasStreetDoor, selectedPlan, subscriptionStart, electricity, battery, controlIntercomDismissed) => {
  try {
    let warnings = []
    if (profile === 'admin' && !(disabled && deviceOwner !== connectionOwner)) {
      if (offlineGyro) {
        warnings.push({ text: I18n.t('screens.connection.warnings.gyroConnectWifi'), actionName: warningsActions.gyroConnectWifi, color: Colors.theme1, icon: 'signal' })
      }
      if (!hasStreetDoor && !controlIntercomDismissed) {
        warnings.push({ text: I18n.t('screens.connection.warnings.gyroControlIntercom'), actionName: warningsActions.gyroControlIntercom, color: Colors.theme1, icon: 'landline' })
      }
      if (selectedPlan && subscriptionStart) {
        // Show Gyro trial remaining days
        const remainingDays = getRemainingDays(subscriptionStart, false)
        let text = ''
        if (remainingDays > 0) {
          if (remainingDays === 0) {
            text = I18n.t('screens.connection.warnings.trialExpireToday') + '.'
          } else if (remainingDays === 1) {
            text = I18n.t('screens.connection.warnings.remainingTrial') + ': ' + remainingDays + ' ' + I18n.t('shared.day') + '.'
          } else if (remainingDays > 1) {
            text = I18n.t('screens.connection.warnings.remainingTrial') + ': ' + remainingDays + ' ' + I18n.t('shared.days') + '.'
          }
          warnings.push({ text, actionName: warningsActions.gyroTrial, color: Colors.yellow, icon: 'warning' })
        } else {
          // TODO: Trial ended?
        }
      } else {
        // TODO: Check for subscription in campaign

      }
    }
    if (parseInt(electricity) === 1) {
      warnings.push({ text: I18n.t('screens.connection.warnings.power.electricity'), actionName: warningsActions.gyroElectricity, color: Colors.success, icon: 'battery' })
    } else if (parseInt(battery) > 0) {
      let color = Colors.error
      const text = I18n.t('screens.connection.warnings.battery.battery') + ': ' + parseInt(battery) + '%'
      if (parseInt(battery) >= 30 && parseInt(battery) < 70) { color = Colors.yellow }
      else if (parseInt(battery) >= 70) { color = Colors.success }
      warnings.push({ text, actionName: warningsActions.gyroBattery, color, icon: 'battery' })
    }
    return warnings
  } catch {
    return []
  }
}

const _getDeviceConnectionWarnings = (
  connection,
  deviceOwner,
  connectionOwner,
  profile,
  online,
  updating,
  firmwareUpdateAvailable,
  offlineAutoAccess,
  hasAutoAccessDoor,
  openClosedBlocked,
  autoAccessBlocked,
  nukiBatteryCritical,
  isProWithGyroDoor,
  isMiniWithGyroDoor,
) => {
  try {
    let warnings = []
    if (connectionDeviceIsParticleDisabled(connection)) {
      // Do nothing
      // warnings.push({ text: I18n.t('screens.connection.warnings.particleDisabled'), actionName: warningsActions.particleDisabled, color: Colors.yellow, icon: 'warning', showInSettings: true })
    } else if (!online && !updating) {
      warnings.push({ text: I18n.t('screens.connection.warnings.offline'), actionName: warningsActions.deviceOffline, color: Colors.yellow, icon: 'warning', showInSettings: true })
    } else if (updating) {
      warnings.push({ text: I18n.t('screens.connection.warnings.updating'), actionName: warningsActions.deviceUpdating, color: Colors.info, icon: 'cycle', showInSettings: true })
    }
    if (!updating && firmwareUpdateAvailable) {
      const actionName = deviceOwner === connectionOwner || profile === 'admin' ? warningsActions.firmwareUpdate : warningsActions.firmwareUpdateNotOwner
      warnings.push({ text: I18n.t('screens.connection.warnings.updateAvailable'), actionName, color: Colors.info, icon: 'cycle', showInSettings: true })
    }
    if (!online && !updating && offlineAutoAccess && hasAutoAccessDoor) {
      warnings.push({ text: I18n.t('screens.connection.warnings.offlineAutoAccess'), actionName: warningsActions.offlineAutoAccess, color: Colors.info, icon: 'lifebuoy' })
    }
    if (openClosedBlocked) {
      warnings.push({ text: I18n.t('screens.connection.warnings.openClosedBlocked'), actionName: warningsActions.openClosedBlocked, color: Colors.yellow, icon: 'warning' })
    }
    if (autoAccessBlocked) {
      warnings.push({ text: I18n.t('screens.connection.warnings.autoAccessBlocked'), actionName: warningsActions.autoAccessBlocked, color: Colors.yellow, icon: 'warning' })
    }
    if (nukiBatteryCritical) {
      warnings.push({ text: I18n.t('screens.connection.warnings.nukiBatteryCritical'), actionName: warningsActions.nukiBatteryCritical, color: Colors.error, icon: 'warning' })
    }
    if (isProWithGyroDoor || isMiniWithGyroDoor) {
      try {
        const gyroDoor = connection.device.doors
        .filter((door) => (door.device))
        .filter((door) => (door.device.version === deviceVersions.gyro.id))[0];
        const deviceOfDoor = gyroDoor.device;
        const battery = deviceOfDoor.battery;
        if (parseInt(battery) > 0) {
          let color = Colors.error
          const text = I18n.t('screens.connection.warnings.battery.gyroBattery') + ': ' + parseInt(battery) + '%'
          if (parseInt(battery) >= 30 && parseInt(battery) < 70) { color = Colors.yellow }
          else if (parseInt(battery) >= 70) { color = Colors.success }
          warnings.push({
            text,
            actionName: isMiniWithGyroDoor ? warningsActions.miniGyroBattery : warningsActions.proGyroBattery,
            color,
            icon: 'battery',
          })
        }
      } catch {
        // Do nothing
      }
    }
    return warnings
  } catch {
    return []
  }
}

const _getFreeProfileWarnings = (deviceOwner, connectionOwner, created, trialEnded, trialEndedAccount, unpaidSince, asUnsubscribedDate, subscriptionId, exhibitor) => {
  try {
    let warnings = []
    if (trialEndedAccount) {
      const actionName = deviceOwner === connectionOwner ? warningsActions.deviceTrialAccountEnded : warningsActions.deviceTrialAccountEndedNotOwner
      warnings.push({ text: I18n.t('screens.connection.warnings.cantUseAccount'), actionName, color: Colors.yellow, icon: 'warning' })
    } else if (trialEnded) {
      if (unpaidSince) {
        const actionText = deviceOwner === connectionOwner ? I18n.t('screens.connection.warnings.paymentFailedBlocked') : I18n.t('screens.connection.warnings.paymentFailedBlockedNotOwner')
        const actionName = deviceOwner === connectionOwner ? warningsActions.paymentFailedBlocked : warningsActions.paymentFailedBlockedNotOwner
        warnings.push({ text: actionText, actionName, color: Colors.error, icon: 'warning' })
      } else {
        const actionName = deviceOwner === connectionOwner ? warningsActions.deviceTrialEnded : warningsActions.deviceTrialEndedNotOwner
        warnings.push({ text: I18n.t('screens.connection.warnings.cantUse'), actionName, color: Colors.theme1, icon: 'rocket' })
      }
    } else if (asUnsubscribedDate) {
      let text = I18n.t('screens.connection.warnings.cantUseAccount')
      const remainingDays = getRemainingDays(asUnsubscribedDate, false)
      if (remainingDays === 0) {
        text = I18n.t('screens.connection.warnings.upgradeExpireToday')
      } else if (remainingDays === 1) {
        text = I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.day')
      } else if (remainingDays > 1) {
        text = I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.days')
      }
      warnings.push({ text, actionName: warningsActions.deviceUpgradeRemaining, color: Colors.theme1, icon: 'rocket' })
    } else if (unpaidSince) {
      let actionText = deviceOwner === connectionOwner ? I18n.t('screens.connection.warnings.paymentFailing') : I18n.t('screens.connection.warnings.paymentFailingNotOwner')
      const remainingDays = getRemainingDaysUnpaidSince(unpaidSince)
      if (remainingDays === 0) {
        actionText += ' ' + I18n.t('screens.connection.warnings.upgradeExpireToday')
      } else if (remainingDays === 1) {
        actionText += ' ' + I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.day')
      } else if (remainingDays > 1) {
        actionText += ' ' + I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.days')
      }
      const actionName = deviceOwner === connectionOwner ? warningsActions.paymentFailed : warningsActions.paymentFailedNotOwner
      warnings.push({ text: actionText, actionName, color: Colors.yellow, icon: 'warning' })
    } else {
      const actionName = deviceOwner === connectionOwner ? warningsActions.deviceTrial : warningsActions.deviceTrialNotOwner
      if (!subscriptionId && !exhibitor) {
        let text = I18n.t('screens.connection.warnings.cantUseAccount')
        const remainingDays = getRemainingDays(created)
        if (remainingDays === 0) {
          text = I18n.t('screens.connection.warnings.trialExpireToday') + '. ' + I18n.t('screens.connection.warnings.afterTrialFreeVersion')
        } else if (remainingDays === 1) {
          text = I18n.t('screens.connection.warnings.remainingTrial') + ': ' + remainingDays + ' ' + I18n.t('shared.day') + '. ' + I18n.t('screens.connection.warnings.afterTrialFreeVersion')
        } else if (remainingDays > 1) {
          text = I18n.t('screens.connection.warnings.remainingTrial') + ': ' + remainingDays + ' ' + I18n.t('shared.days') + '. ' + I18n.t('screens.connection.warnings.afterTrialFreeVersion')
        }
        warnings.push({ text, actionName, color: Colors.yellow, icon: 'warning' })
      }
    }
    return warnings
  } catch {
    return []
  }
}

const _getPaymentWarnings = (deviceOwner, connectionOwner, version, created, trialEnded, unpaidSince, asUnsubscribedDate, subscriptionId, exhibitor) => {
  try {
    let warnings = []
    if (trialEnded) {
      if (unpaidSince) {
        const actionText = deviceOwner === connectionOwner ? I18n.t('screens.connection.warnings.paymentFailedBlocked') : I18n.t('screens.connection.warnings.paymentFailedBlockedNotOwner')
        const actionName = deviceOwner === connectionOwner ? warningsActions.paymentFailedBlocked : warningsActions.paymentFailedBlockedNotOwner
        warnings.push({ text: actionText, actionName, color: Colors.error, icon: 'warning' })
      } else {
        if (version !== deviceVersions.gyro.id) {
          const actionName = deviceOwner === connectionOwner ? warningsActions.deviceTrialAccountEnded : warningsActions.deviceTrialAccountEndedNotOwner
          warnings.push({ text: I18n.t('screens.connection.warnings.cantUseAccount'), actionName, color: Colors.yellow, icon: 'warning' })
        }
      }
    } else if (asUnsubscribedDate) {
      let text = I18n.t('screens.connection.warnings.cantUseAccount')
      const remainingDays = getRemainingDays(asUnsubscribedDate, false)
      if (remainingDays === 0) {
        text = I18n.t('screens.connection.warnings.upgradeExpireToday')
      } else if (remainingDays === 1) {
        text = I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.day')
      } else if (remainingDays > 1) {
        text = I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.days')
      }
      warnings.push({ text, actionName: warningsActions.deviceUpgradeRemaining, color: Colors.theme1, icon: 'rocket' })
    } else if (unpaidSince) {
      let actionText = deviceOwner === connectionOwner ? I18n.t('screens.connection.warnings.paymentFailing') : I18n.t('screens.connection.warnings.paymentFailingNotOwner')
      const remainingDays = getRemainingDaysUnpaidSince(unpaidSince)
      if (remainingDays === 0) {
        actionText += ' ' + I18n.t('screens.connection.warnings.upgradeExpireToday')
      } else if (remainingDays === 1) {
        actionText += ' ' + I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.day')
      } else if (remainingDays > 1) {
        actionText += ' ' + I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.days')
      }
      const actionName = deviceOwner === connectionOwner ? warningsActions.paymentFailed : warningsActions.paymentFailedNotOwner
      warnings.push({ text: actionText, actionName, color: Colors.yellow, icon: 'warning' })
    } else {
      if (version !== deviceVersions.gyro.id) {
        let text
        const actionName = deviceOwner === connectionOwner ? warningsActions.deviceTrial : warningsActions.deviceTrialNotOwner
        if (!subscriptionId && !exhibitor) {
          const remainingDays = getRemainingDays(created)
          if (remainingDays === 0) {
            text = I18n.t('screens.connection.warnings.trialExpireToday')
          } else if (remainingDays === 1) {
            text = I18n.t('screens.connection.warnings.remainingTrial') + ': ' + remainingDays + ' ' + I18n.t('shared.day')
          } else if (remainingDays > 1) {
            text = I18n.t('screens.connection.warnings.remainingTrial') + ': ' + remainingDays + ' ' + I18n.t('shared.days')
          }
          warnings.push({ text, actionName, color: Colors.yellow, icon: 'warning' })
        }
      }
    }
    return warnings
  } catch {
    return []
  }
}

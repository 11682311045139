import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ConnectionTabbar from '../../../Components/UI/Connections/ConnectionTabbar'
import { connectionHasAutoAccessDoor, connectionHasUsableDoors } from '../../../Lib/ConnectionsUtils'

class TabbarMenu extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired,
    selectedTab: PropTypes.string.isRequired,
    switchTab: PropTypes.func.isRequired
  }

  static defaultProps = {
    connection: null,
    selectedTab: null,
    switchTab: null
  }

  render = () => {
    const { connection, selectedTab } = this.props
    const { profile } = connection || {}
    const hasAutoAccessDoor = connectionHasAutoAccessDoor(connection)
    const hasUsableDoors = connectionHasUsableDoors(connection)
    return (
      <ConnectionTabbar
        connection={connection}
        hasAutoAccessDoor={hasAutoAccessDoor}
        hasUsableDoors={hasUsableDoors}
        isUserAdmin={profile === 'admin'}
        selectedTab={selectedTab}
        onItemPress={this.props.switchTab}
      />
    )
  }

}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TabbarMenu)

import { Colors, Fonts, Metrics } from '../../../Themes/'

export default {
  buttonContainer: {
    padding: Metrics.baseMargin + ' ' + Metrics.doubleBaseMargin,
    borderRadius: Metrics.buttonRadius,
    marginBottom: Metrics.baseMargin,
    borderWidth: Metrics.buttonBorderWidth,
    fontSize: Fonts.size.regular,
    minHeight: '44px'
    // fontWeight: Fonts.weight.regular
  },
  buttonIcon: {
    alignSelf: 'center',
    marginRight: Metrics.baseMargin,
    color: Colors.white
  },
  buttonIndicator: {
    marginLeft: Metrics.baseMargin,
    fontSize: Fonts.size.regular
  },
  disabled: {
    opacity: 0.6
  }
}
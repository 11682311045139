import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RaixerConfig from '../../../Config/RaixerConfig'
import I18n from '../../../i18n/i18n'
import AppText from '../Texts/AppText'
import LineItemEditButton from '../Buttons/LineItemEditButton'
import SimpleIconButton from '../Buttons/SimpleIconButton'
import { Colors, Fonts, Metrics } from '../../../Themes'
import styles from '../../Styles/UI/ListItem'

export default class SimpleListItem extends Component {

  static propTypes = {
    leftText: PropTypes.string.isRequired,
    additionalLeftText: PropTypes.string,
    rightText: PropTypes.string.isRequired,
    withEditButton: PropTypes.bool.isRequired,
    lastOfList: PropTypes.bool,
    onPressEdit: PropTypes.func,
    editButtonFlex: PropTypes.number,
    customLeftStyle: PropTypes.object,
    customRightStyle: PropTypes.object,
    customLeftTextStyle: PropTypes.object,
    customRightTextStyle: PropTypes.object,
    customAdditionalLeftTextStyle: PropTypes.object,
    onListItemIcon: PropTypes.func
  }

  static defaultProps = {
    leftText: null,
    additionalLeftText: null,
    rightText: null,
    withEditButton: null,
    lastOfList: false,
    onPressEdit: () => { console.log() },
    editButtonFlex: 0,
    customLeftStyle: {},
    customRightStyle: {},
    customLeftTextStyle: {},
    customRightTextStyle: {},
    customAdditionalLeftTextStyle: {},
    onListItemIcon: null
  }

  render = () => {
    const editStyles = Object.assign({}, styles.listItemEditButtonContainer, { flex: this.props.editButtonFlex })
    return (
      <div style={!this.props.lastOfList ? styles.listItem : Object.assign({}, styles.listItem, styles.lastListItem)}>
        <div style={styles.listItemInner}>
          <div style={Object.assign({}, styles.listItemInner, { flex: RaixerConfig.animations.lists.itemFlex })}>
            <div style={Object.assign({}, styles.listItemLeft, this.props.customLeftStyle)}>
              { 
                this.props.additionalLeftText !== null && this.props.additionalLeftText !== '' && 
                <AppText 
                  text={this.props.additionalLeftText} 
                  customStyle={
                    Object.assign(
                      {},
                      styles.listItemContent,
                      { textAlign: 'left', marginBottom: Metrics.baseMargin },
                      this.props.customAdditionalLeftTextStyle
                    )
                  }
                  numberOfLines={1}
                />
              }
              <AppText
                text={this.props.leftText}
                customStyle={Object.assign({}, styles.listItemTitle, this.props.customLeftTextStyle)}
                numberOfLines={1}
              />
            </div>
            {
              this.props.onListItemIcon &&
              <SimpleIconButton
                onPress={this.props.onListItemIcon}
                icon={'share'}
                active={true}
                customButtonStyle={{ margin: 0, padding: 0 }}
                customIconStyle={{ color: Colors.theme1, fontSize: Fonts.size.regular }}
              />
            }
            <div style={Object.assign({}, styles.listItemRight, this.props.customRightStyle)}>
              <AppText
                text={this.props.rightText}
                customStyle={Object.assign({}, styles.listItemContent, this.props.customRightTextStyle)}
                numberOfLines={1}
              />
            </div>
          </div>
          <div style={editStyles}>
            <LineItemEditButton
              buttonText={I18n.t('shared.edit')}
              onPress={this.props.onPressEdit}
              disabled={false}
              customButtonStyle={{ marginBottom: 0, marginLeft: Metrics.doubleBaseMargin }}
            />
          </div>
        </div>
      </div>
    )
  }

}

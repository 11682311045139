import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import styles from '../../Styles/UI/Selectors'

export default class WeekdaySelectorButton extends Component {

  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
    buttonStyle: PropTypes.object,
    buttonStyleSelected: PropTypes.object,
    textStyle: PropTypes.object,
    textStyleSelected: PropTypes.object,
    customButtonStyle: PropTypes.object,
    customTextStyle: PropTypes.object
  }

  static defaultProps = {
    buttonText: null,
    onPress: null,
    selected: false,
    disabled: false,
    isLast: false,
    buttonStyle: styles.weekdayButton,
    buttonStyleSelected: styles.weekdayButtonSelected,
    textStyle: styles.weekdayButtonText,
    textStyleSelected: styles.weekdayButtonTextSelected,
    customButtonStyle: {},
    customTextStyle: {}
  }

  render = () => {
    let buttonStyles = [this.props.buttonStyle]
    let textStyles = [this.props.textStyle]
    if (this.props.isLast) { buttonStyles.push({ marginRight: 0 }) }
    if (!this.props.disabled) {
      if (this.props.selected) { 
        buttonStyles.push(this.props.buttonStyleSelected) 
        textStyles.push(this.props.textStyleSelected)
      }
    } else {
      buttonStyles.push(styles.weekdayButtonSmall)
      textStyles.push(styles.weekdayButtonTextSmall)
      if (this.props.selected) {
        buttonStyles.push(styles.weekdayButtonSmallSelected)
        textStyles.push(styles.weekdayButtonTextSmallSelected)
      }
    }
    buttonStyles.push(this.props.customButtonStyle)
    textStyles.push(this.props.customTextStyle)
    return (
      <Button
        onClick={this.props.onPress}
        disabled={this.props.disabled}
        style={Object.assign({}, ...buttonStyles, ...textStyles)}
        color={this.props.selected ? 'primary' : 'default'}
      >
        {this.props.buttonText}
      </Button>
    )
  }

}

import { Colors, Fonts } from '../../../Themes/'

export default {
  text: {
    fontFamily: Fonts.type.regular,
    color: Colors.mediumGray,
    fontWeight: Fonts.weight.regular,
    fontSize: Fonts.size.regular,
    whiteSpace: 'pre-wrap',
    margin: 0
  },
  title: {
    fontFamily: Fonts.type.bold,
    color: Colors.darkGray,
    fontSize: Fonts.size.title,
    whiteSpace: 'pre-wrap',
    margin: 0
  }
}
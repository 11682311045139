import apisauce from 'apisauce'
import RaixerConfig from '../Config/RaixerConfig'
import { retryIfNetworkError } from '../Lib/ApiUtils'

const create = (baseURL = RaixerConfig.api.baseUrl) => {
  const appVersion = RaixerConfig.appVersion
  const api = apisauce.create({
    baseURL,
    headers: RaixerConfig.api.baseHeaders,
    timeout: RaixerConfig.api.timeout
  })

  // START AUTH
  const hasTokenHeader = () => api.headers['x-access-token'] ? true : false
  const setTokenHeader = (token) => api.setHeader('x-access-token', token)
  const removeTokenHeader = () => delete api.headers['x-access-token']
  const login = (email, password) => retryIfNetworkError(api.post, 'auth/login', { email, password })
  const register = (displayName, email, password, activeLang) => retryIfNetworkError(api.post, 'auth/register-new', { displayName, email, password, activeLang })
  const recover = (email, password, activeLang) => retryIfNetworkError(api.post, 'auth/recover', { email, password, activeLang })
  const facebookLogin = (email, displayName, userId, accessToken) => retryIfNetworkError(api.post, 'auth/facebook', { email, displayName, userId, accessToken })
  // FINISH AUTH
  // START CONNECTIONS
  const getConnections = () => retryIfNetworkError(api.get, 'connections/ordered')
  const postDeviceTask = (id, task, params) => retryIfNetworkError(api.post, 'devices/' + id + '/' + task, params)
  const postDeviceGyroTask = (id, task, params) => retryIfNetworkError(api.post, 'devices/gyro/' + id + '/' + task, params)
  const postDeviceOtherTask = (id, task, params) => retryIfNetworkError(api.post, 'devices/' + id + '/function/' + task, params)
  const getDeviceVariable = (id, variable) => retryIfNetworkError(api.get, 'devices/' + id + '/variable/' + variable)
  const getConnection = (id) => retryIfNetworkError(api.get, 'connections/' + id)
  const getConnectionHistory = (id, profile, page, pageSize) =>
    retryIfNetworkError(api.get, 'connections/' + id + '/history?page=' + page + '&appVersion=' + appVersion + '&profile=' + profile + '&pageSize=' + pageSize)
  const refreshDeviceVitals = (id) => retryIfNetworkError(api.post, 'devices/' + id + '/vitals')
  const createConnectionSchedule = (schedule) => retryIfNetworkError(api.post, 'schedules/', schedule)
  const editConnectionSchedule = (id, schedule) => retryIfNetworkError(api.put, 'schedules/' + id, schedule)
  const deleteConnectionSchedule = (id) => retryIfNetworkError(api.delete, 'schedules/' + id)
  const getDeviceQrs = (deviceId) => retryIfNetworkError(api.get, 'devices/' + deviceId + '/qr-codes')
  const getDeviceTransferQRs = (deviceId) => retryIfNetworkError(api.get, 'devices/' + deviceId + '/qr-codes-transfer')
  const updateConnectionUser = (id, connection) => retryIfNetworkError(api.put, 'connections/user/' + id, connection)
  const deleteConnectionUser = (id) => retryIfNetworkError(api.delete, 'connections/user/' + id)
  // const enableConnectionUser = (deviceId, ownerEmail) => retryIfNetworkError(api.post, 'connections/by/device/' + deviceId + '/enable/by/email', { email: ownerEmail })
  // const disableConnectionUser = (deviceId, ownerEmail) => retryIfNetworkError(api.post, 'connections/by/device/' + deviceId + '/disable/by/email', { email: ownerEmail })
  const createConnectionAuthorizedPhone = (phone) => retryIfNetworkError(api.post, 'authorized-phones/v2', phone)
  const editConnectionAuthorizedPhone = (phone) => retryIfNetworkError(api.put, 'authorized-phones/v2/' + phone._id, phone)
  const deleteConnectionAuthorizedPhone = (id) => retryIfNetworkError(api.delete, 'authorized-phones/v2/' + id)
  const enableConnectionDoorPhoneAccess = (deviceId, doorId) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/doors/' + doorId + '/phone/enable')
  const disableConnectionDoorPhoneAccess = (deviceId, doorId) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/doors/' + doorId + '/phone/disable')
  const getDeviceDoorPhoneConflict = (deviceId, doorId, phoneId) => retryIfNetworkError(api.get, 'devices/' + deviceId + '/doors/' + doorId + '/phone/v2/' + phoneId + '/conflicts')
  const getDevicePhonesCountries = (doorId) => { 
    let query = '?fromApp=true'
    if (doorId) { query += '&doorId=' + doorId }
    return retryIfNetworkError(api.get, 'device-phones/country/availables' + query)
  }
  const getDeviceDoorPhone = (deviceId, doorId, params) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/doors/' + doorId + '/phone', params)
  const deleteDeviceDoorPhone = (deviceId, doorId) => retryIfNetworkError(api.delete, 'devices/' + deviceId + '/doors/' + doorId + '/phone')
  const getDeviceDoorAnyCallerPhone = (deviceId, doorId) => retryIfNetworkError(api.get, 'devices/' + deviceId + '/doors/' + doorId + '/any-caller-phone')
  const enablePhoneAnyCallerAccess = (deviceId, doorId, params) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/doors/' + doorId + '/phone/v2/any-caller/enable', params)
  const disablePhoneAnyCallerAccess = (deviceId, doorId) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/doors/' + doorId + '/phone/v2/any-caller/disable')
  const claimPhoneOwnership = (deviceId, doorId, params) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/doors/' + doorId + '/phone/v2/claim-ownership', params)
  const enableConnectionDoorCodeAccess = (deviceId, doorId) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/doors/' + doorId + '/code/enable')
  const disableConnectionDoorCodeAccess = (deviceId, doorId) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/doors/' + doorId + '/code/disable')
  const createConnectionAuthorizedCode = (code) => retryIfNetworkError(api.post, 'authorized-codes/', code)
  const editConnectionAuthorizedCode = (code) => retryIfNetworkError(api.put, 'authorized-codes/' + code._id, code)
  const deleteConnectionAuthorizedCode = (id) => retryIfNetworkError(api.delete, 'authorized-codes/' + id)
  const getDeviceDoorCode = (deviceId, doorId, params) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/doors/' + doorId + '/code', params)
  const deleteDeviceDoorCode = (deviceId, doorId) => retryIfNetworkError(api.delete, 'devices/' + deviceId + '/doors/' + doorId + '/code')
  const getDeviceDoorAnyCallerCode = (deviceId, doorId) => retryIfNetworkError(api.get, 'devices/' + deviceId + '/doors/' + doorId + '/any-person-access-url')
  const saveDeviceOfflineAutoAccess = (deviceId, offlineAutoAccess) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/offline-auto-access', { offlineAutoAccess: offlineAutoAccess })
  const saveDeviceAutoAccessAlwaysActive = (deviceId, autoAccessAlwaysActive) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/auto-access-always-active', { autoAccessAlwaysActive: autoAccessAlwaysActive })
  // const saveDeviceConfigurationWizard = (deviceId, wizardDiscarded) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/device-configuration-wizard', { wizardDiscarded })
  // const deleteConnection = (connectionId) => retryIfNetworkError(api.delete, 'connections/' + connectionId)
  // const addSubscription = (campaignId, stripePlanId, activeLang) => retryIfNetworkError(api.post, 'subscriptions/v2', { campaignId, stripePlanId, activeLang })
  // const updateSubscription = (subscriptionId, campaignId, stripePlanId, activeLang) => retryIfNetworkError(api.put, 'subscriptions/v2/' + subscriptionId, { campaignId, stripePlanId, activeLang })
  // const deleteSubscription = (subscriptionId, campaignId, activeLang) => retryIfNetworkError(api.delete, 'subscriptions/v2/' + subscriptionId + '/campaign/' + campaignId, { activeLang })
  // const addPhoneSubscription = (doorId, stripePlanId, activeLang) => retryIfNetworkError(api.post, 'phone-subscriptions', { doorId, stripePlanId, activeLang })
  // const updatePhoneSubscription = (subscriptionId, doorId, stripePlanId, activeLang) => retryIfNetworkError(api.put, 'phone-subscriptions/' + subscriptionId, { doorId, stripePlanId, activeLang })
  // const deletePhoneSubscription = (subscriptionId, doorId, activeLang) => retryIfNetworkError(api.delete, 'phone-subscriptions/' + subscriptionId + '/door/' + doorId, { activeLang })
  // const addGuestySubscription = (deviceId, listingId, stripePlanId, activeLang) => retryIfNetworkError(api.post, 'guesty/subscriptions', { deviceId, listingId, stripePlanId, activeLang })
  // const updateGuestySubscription = (subscriptionId, deviceId, listingId, stripePlanId, activeLang) => retryIfNetworkError(api.put, 'guesty/subscriptions/' + subscriptionId, { deviceId, listingId, stripePlanId, activeLang })
  // const deleteGuestySubscription = (subscriptionId, deviceId, activeLang) => retryIfNetworkError(api.delete, 'guesty/subscriptions/' + subscriptionId + '/device/' + deviceId, { activeLang })
  // const addCheckinSubscription = (connection, deviceId, stripePlanId, activeLang) => retryIfNetworkError(api.post, 'checkin/subscription', { newConnection: connection, deviceId, stripePlanId, activeLang })
  // const updateCheckinSubscription = (subscriptionId, deviceId, connectionId, stripePlanId, activeLang) => retryIfNetworkError(api.put, 'checkin/subscription/' + subscriptionId, { deviceId, connectionId, stripePlanId, activeLang })
  // const deleteCheckinSubscription = (subscriptionId, deviceId, activeLang) => retryIfNetworkError(api.delete, 'checkin/subscription/' + subscriptionId + '/device/' + deviceId, { activeLang })
  // const retryPayment = (activeLang) => retryIfNetworkError(api.post, 'users/payments/retry', { activeLang })
  // const updateConnection = (id, connection) => retryIfNetworkError(api.put, 'connections/' + id, connection)
  // const addDeviceSensor = (deviceId, sensor) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/sensors', sensor)
  // const updateDeviceSensor = (deviceId, sensorId, sensor) => retryIfNetworkError(api.put, 'devices/' + deviceId + '/sensors/' + sensorId, sensor)
  // const deleteDeviceSensor = (deviceId, sensorId) => retryIfNetworkError(api.delete, 'devices/' + deviceId + '/sensors/' + sensorId)
  // const addDeviceDoor = (deviceId, door) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/doors', door)
  // const updateDeviceDoor = (deviceId, doorId, door) => retryIfNetworkError(api.put, 'devices/' + deviceId + '/doors/' + doorId, door)
  // const deleteDeviceDoor = (deviceId, doorId) => retryIfNetworkError(api.delete, 'devices/' + deviceId + '/doors/' + doorId)
  const updateDeviceFirmware = (deviceId) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/update/firmware')
  // const updateDeviceEnergySaveMode = (deviceId, energySaveMode, testing) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/energy-save', { energySaveMode, testing })
  // // FINISH CONNECTIONS
  // // START WIZARD
  // const addConnectionByQr = (qrUrl) => retryIfNetworkError(api.post, qrUrl)
  // const addDeviceByQr = (qrUrl) => retryIfNetworkError(api.post, qrUrl)
  // const getClaimCode = () => retryIfNetworkError(api.get, 'particle/device/claim')
  // const addDevice = (id, data) => retryIfNetworkError(api.post, 'devices/' + id, data)
  // const getDeviceStatus = (id) => retryIfNetworkError(api.get, 'devices/' + id + '/connected-status')
  const postNukiOperation = (deviceId, operation, nukiId = 0, params) => retryIfNetworkError(api.post, 'devices/' + deviceId + '/nuki/' + nukiId + '/action/' + operation, params)
  const getNukiVerifyStatus = (deviceId, status, nukiId = 0) => retryIfNetworkError(api.get, 'devices/' + deviceId + '/nuki/' + nukiId + '/status/' + status + '/verify')
  // // FINISH WIZARD
  // // START USER
  const getUser = (id) => retryIfNetworkError(api.get, 'users/' + id)
  const updateUser = (id, fields) => retryIfNetworkError(api.put, 'users/' + id, fields)
  // // FINISH USER
  // // START MOVEMENTS
  // const getMovements = (page, filter) => retryIfNetworkError(api.get, 'movements?page=' + page + (filter ? '&type=' + filter : ''))
  // // FINISH MOVEMENTS
  // // START PLATFORM INTEGRATIONS
  // const requestPlatformIntegration = (params) => retryIfNetworkError(api.post, 'user-platform-integrations/request', params)
  // const addPlatformIntegration = (integration) => retryIfNetworkError(api.post, 'user-platform-integrations', integration)
  // const editPlatformIntegration = (id, integration) => retryIfNetworkError(api.put, 'user-platform-integrations/' + id, integration)
  // // FINISH PLATFORM INTEGRATIONS
  // // START CONFIGURATIONS
  const getConfiguration = () => retryIfNetworkError(api.get, 'configurations/')
  // // END CONFIGURATIONS
  // // START GUESTY
  // const getGuestyListings = () => retryIfNetworkError(api.get, 'guesty/listings')
  // const pairGuestyListing = (listingId, connectionId) => retryIfNetworkError(api.put, 'guesty/listings/' + listingId + '/connections/' + connectionId)
  // const deleteGuestyListingPair = (listingId) => retryIfNetworkError(api.delete, 'guesty/listings/' + listingId + '/connections')
  // // END GUESTY
  // // START CHECK-IN
  const createLocation = (connectionId, location) => retryIfNetworkError(api.post, 'locations/' + connectionId + '/with-file', location, { 'Content-Type': 'multipart/form-data' })
  const editLocation = (locationId, location) => retryIfNetworkError(api.put, 'locations/' + locationId + '/with-file', location, { 'Content-Type': 'multipart/form-data' })
  const deleteLocation = (locationId) => retryIfNetworkError(api.delete,'locations/' + locationId)
  const getGuestBook = (locationId) => retryIfNetworkError(api.post, 'locations/' + locationId + '/guestbook')
  const sendGuestBook = (locationId) => retryIfNetworkError(api.post, 'locations/' + locationId + '/guestbook/send')
  const saveBooking = (booking) => retryIfNetworkError(api.post, 'bookings', booking)
  const editBooking = (bookingId, booking) => retryIfNetworkError(api.put, 'bookings/' + bookingId, booking)
  const deleteBooking = (bookingId) => retryIfNetworkError(api.delete, 'bookings/' + bookingId)
  const getBookings = (locationId, page, beforeToday) => retryIfNetworkError(api.get, 'bookings/history/' + locationId + '?page=' + page + '&btoday=' + beforeToday)
  // const pairLocation = (subscriptionId, deviceId) => retryIfNetworkError(api.post, 'checkin/subscription/' + subscriptionId + '/device/' + deviceId + '/pair')
  // END CHECK-IN

  return {
    hasTokenHeader,
    setTokenHeader,
    removeTokenHeader,
    login,
    register,
    recover,
    facebookLogin,
    getConnections,
    postDeviceTask,
    postDeviceGyroTask,
    postDeviceOtherTask,
    getDeviceVariable,
    getConnection,
    getConnectionHistory,
    refreshDeviceVitals,
    createConnectionSchedule,
    editConnectionSchedule,
    deleteConnectionSchedule,
    getDeviceQrs,
    getDeviceTransferQRs,
    updateConnectionUser,
    deleteConnectionUser,
    // enableConnectionUser,
    // disableConnectionUser,
    createConnectionAuthorizedPhone,
    editConnectionAuthorizedPhone,
    deleteConnectionAuthorizedPhone,
    enableConnectionDoorPhoneAccess,
    disableConnectionDoorPhoneAccess,
    getDeviceDoorPhoneConflict,
    getDevicePhonesCountries,
    getDeviceDoorPhone,
    deleteDeviceDoorPhone,
    getDeviceDoorAnyCallerPhone,
    enablePhoneAnyCallerAccess,
    disablePhoneAnyCallerAccess,
    claimPhoneOwnership,
    enableConnectionDoorCodeAccess,
    disableConnectionDoorCodeAccess,
    getDeviceDoorCode,
    deleteDeviceDoorCode,
    getDeviceDoorAnyCallerCode,
    createConnectionAuthorizedCode,
    editConnectionAuthorizedCode,
    deleteConnectionAuthorizedCode,
    saveDeviceOfflineAutoAccess,
    saveDeviceAutoAccessAlwaysActive,
    // saveDeviceConfigurationWizard,
    // deleteConnection,
    // addSubscription,
    // updateSubscription,
    // deleteSubscription,
    // addPhoneSubscription,
    // updatePhoneSubscription,
    // deletePhoneSubscription,
    // addGuestySubscription,
    // updateGuestySubscription,
    // deleteGuestySubscription,
    // addCheckinSubscription,
    // updateCheckinSubscription,
    // deleteCheckinSubscription,
    // retryPayment,
    // updateConnection,
    getUser,
    updateUser,
    // addDeviceSensor,
    // updateDeviceSensor,
    // deleteDeviceSensor,
    // addDeviceDoor,
    // updateDeviceDoor,
    // deleteDeviceDoor,
    updateDeviceFirmware,
    // updateDeviceEnergySaveMode,
    // addConnectionByQr,
    // addDeviceByQr,
    // getClaimCode,
    // addDevice,
    // getDeviceStatus,
    postNukiOperation,
    getNukiVerifyStatus,
    // getMovements,
    // requestPlatformIntegration,
    // addPlatformIntegration,
    // editPlatformIntegration,
    getConfiguration,
    // getGuestyListings,
    // pairGuestyListing,
    // deleteGuestyListingPair,
    createLocation,
    editLocation,
    deleteLocation,
    getGuestBook,
    sendGuestBook,
    saveBooking,
    editBooking,
    deleteBooking,
    getBookings,
    // pairLocation
  }
  
}

export default {
  create
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import LeftMenuScreen from '../Menu/LeftMenuScreen'
import Connections from '../Connections/ConnectionsScreen'
import Connection from '../Connection/ConnectionScreen'
import CheckIn from '../CheckIn/CheckInScreen'
import Dashboard from '../../Components/Screens/Dashboard/Dashboard'
import styles from '../Styles/Screens'
import { getActualConnection } from '../../Lib/ConnectionsUtils'

class DashboardScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null
    }
  }

  goToConnection = (connectionId) => {
    this.setState({ connectionId })
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    const { device } = connection || {}
    const { type } = device || {}
    return (
      <div style={styles.mainContainer}>
        <div style={styles.container}>
          <LeftMenuScreen />
          <Dashboard>
            <Connections
              activeConnection={this.state.connectionId}
              goToConnection={this.goToConnection}
            />
            {
              !type
                ? <CheckIn activeConnection={this.state.connectionId} />
                : <Connection activeConnection={this.state.connectionId} />
            }
          </Dashboard>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections
  }
}

const mapDispatchToProps = () => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen)
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form/lib'
import I18n from '../../../../i18n/i18n'
import { objectDeepCopy } from '../../../../Lib/ObjectsUtils'
import { getConnectionQrCodeByProfile } from '../../../../Lib/ConnectionsUtils'
import { 
  inputDateFormat, 
  inputTimeFormat, 
  getDatetimes, 
  getTimes,
  getRecalculatedDatetimes, 
  getDatetimeAsUTC, 
  isNowBetweenDatetimes,
  getTimezone,
  getTimeInCurrentTimezoneAsString,
  isNowInRepeatTimes
} from '../../../../Lib/DateTimeUtils'
import FullScreenLoader from '../../../UI/Loaders/FullScreenLoader'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import ConnectionsStatus from '../../../UI/Connections/ConnectionsStatus'
import MainButton from '../../../UI/Buttons/MainButton'
import SegmentedTabs from '../../../UI/Tabs/SegmentedTabs'
import Qr from '../../../UI/Qrs/Qr'
import FormCard from '../../../UI/ContentCards/FormCard'
import BaseForm from '../../../UI/Forms/BaseForm'
import BaseSwitch from '../../../UI/Switchs/BaseSwitch'
import WeekdaysSelector from '../../../UI/Selectors/WeekdaysSelector'
import styles from '../../../Styles/Screens/Forms'
import { Metrics } from '../../../../Themes'

export default class UserForm extends Component {

  static propTypes = {
    shareQR: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    activeLang: PropTypes.string.isRequired,
    user: PropTypes.object,
    connection: PropTypes.object,
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.bool
  }

  static defaultProps = {
    shareQR: null,
    onSubmit: null,
    onDelete: null,
    activeLang: null,
    user: null,
    connection: null,
    fetching: false,
    error: false
  }

  constructor(props) {
    super(props)
    const { now, to } = getDatetimes(null, null)
    this.state = {
      selectedQr: null,
      value: {
        _id: '',
        name: '',
        email: '',
        profile: 'user'
      },
      valueDates: {
        startDate: now,
        endDate: to,
        startTime: now,
        endTime: to
      },
      enabled: true,
      unlimitedAccess: true,
      deleteOnEnd: false,
      repeatDays: false,
      repeatDaysOfWeek: ''
    }
  }

  componentDidUpdate = (prevProps) => {
    const { connection: prevConnection } = prevProps
    const { connection } = this.props
    if (prevConnection && connection) {
      const { qrs: prevQrs } = prevConnection
      const { qrs } = connection
      if (!prevQrs && qrs) {
        const profile = this.state.profileIndex === 1 ? 'admin' : 'user'
        const selectedQr = getConnectionQrCodeByProfile(connection, 'normal', profile)
        this.setState({ selectedQr })
      }
      if (prevQrs && qrs) {
        if (!this.state.selectedQr) {
          const profile = this.state.profileIndex === 1 ? 'admin' : 'user'
          const selectedQr = getConnectionQrCodeByProfile(connection, 'normal', profile)
          this.setState({ selectedQr })
        }
      }
    }
    const { user: prevUser } = prevProps
    const { user } = this.props
    if (!prevUser && user) {
      const { 
        _id, name, profile, email, deleteOnEnd, authorizedFrom, authorizedTo, repeatDaysOfWeek,
        repeatStartHour, repeatEndHour, enabledForUse = true
      } = user
      const { now, to } = getDatetimes(null, null)
      let valueDates = {
        startDate: now,
        endDate: to,
        startTime: now,
        endTime: to
      }
      if (authorizedFrom && authorizedTo) {
        const { now: nowDates, to: toDates } = getDatetimes(authorizedFrom, authorizedTo)
        valueDates = {
          startDate: nowDates,
          endDate: toDates,
          startTime: nowDates,
          endTime: toDates
        }
      } else if (repeatDaysOfWeek && repeatStartHour && repeatEndHour) {
        const { now: nowRepeat, to: toRepeat } = getTimes(repeatStartHour, repeatEndHour)
        valueDates = {
          startDate: new Date(),
          endDate: new Date(),
          startTime: nowRepeat,
          endTime: toRepeat
        }
      }
      this.setState({ 
        value: {
          _id,
          name,
          email,
          profile: profile.toLowerCase()
        },
        valueDates,
        enabled: enabledForUse,
        deleteOnEnd,
        unlimitedAccess: !((authorizedFrom && authorizedTo) || (repeatDaysOfWeek && repeatStartHour && repeatEndHour)),
        repeatDays: repeatDaysOfWeek && repeatStartHour && repeatEndHour ? true : false,
        repeatDaysOfWeek
      })
    }
  }

  // NEW USER

  handleProfileChange = (index) => {
    const profile = index === 1 ? 'admin' : 'user'
    const selectedQr = getConnectionQrCodeByProfile(this.props.connection, 'normal', profile)
    this.setState({ selectedQr })
  }

  shareQR = () => {
    const { device, name } = this.props.connection
    const { _id } = device
    this.props.shareQR(_id, name, this.state.selectedQr)
  }
  
  renderNewUser = () => {
    return (
      <ContentCard 
        title={I18n.t('screens.userForm.titleForm')}
        withEditButton={false}
        renderContent={
          () => (
            <>
              {
                this.props.error ?
                <div style={{ marginVertical: Metrics.doubleBaseMargin }}>
                  <ConnectionsStatus
                    text={I18n.t('screens.userForm.errorGetQrs')}
                    icon={'warning'}
                    withoutMargin={true}
                  />
                </div>
                :
                <>
                  <div style={{ marginBottom: Metrics.doubleBaseMarginPlus }}>
                    <SegmentedTabs
                      tabs={[I18n.t('shared.user'), I18n.t('shared.admin')]}
                      onChange={this.handleProfileChange}
                      text={I18n.t('shared.selectUserType')}
                    />
                  </div>
                  <Qr 
                    url={this.state.selectedQr}
                    fetching={this.props.fetching}
                  />
                </>
              }
            </>
          )
        }
        renderButtons={
          () => (
            <MainButton 
              buttonText={I18n.t('screens.userForm.newUserMainButton')}
              disabled={this.props.fetching || (this.props.error ? true : false)}
              onPress={() => { this.shareQR() }}
              icon={'share-alternative'}
              customButtonStyle={{ margin: 0 }}
            />
          )
        }
      />
    )
  }

  // EDIT USER

  onEnabledSwitchChange = (value) => {
    this.setState({ enabled: value })
  }

  onUnlimitedAccessSwitchChange = (value) => {
    this.setState({ unlimitedAccess: value })
  }

  onDeleteOnEndSwitchChange = (value) => {
    this.setState({ deleteOnEnd: value, repeatDays: value ? false : this.state.repeatDays })
  }

  onRepeatDaysSwitchChange = (value) => {
    this.setState({ repeatDays: value, deleteOnEnd: value ? false : this.state.deleteOnEnd })
  }

  onChange = (value) => {
    const { _id, name, email, profile } = value
    this.setState({ 
      value: {
        _id,
        name,
        email,
        profile
      } 
    })
  }

  onChangeDates = (value) => {
    const { startDate: sDate, startTime: sTime, endDate: eDate, endTime: eTime } = value
    const { startDate, endDate, startTime, endTime } = getRecalculatedDatetimes(sDate, sTime, eDate, eTime, null, this.state.repeatDays ? true : false)
    this.setState({ 
      valueDates: {
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime
      } 
    })
  }

  onSubmit = (value) => {
    const { enabled, deleteOnEnd, repeatDays, repeatDaysOfWeek, unlimitedAccess, valueDates } = this.state
    let completeValue = objectDeepCopy(value)
    if (enabled) {
      completeValue.enabledForUse = true
      completeValue.disabled = false
      completeValue.deleteOnEnd = deleteOnEnd
      // If unlimitedAccess is unchecked, complete value with valueDates or with repeat values
      if (unlimitedAccess) {
        completeValue.deleteOnEnd = false
        completeValue.authorizedFrom = null
        completeValue.authorizedTo = null
        completeValue.repeatDaysOfWeek = null
        completeValue.repeatStartHour = null
        completeValue.repeatEndHour = null
        completeValue.repeatTimezone = null
      } else {
        const { startDate, startTime, endDate, endTime } = valueDates
        if (repeatDays) {
          // If repeatDays is checked, complete value with repeat values
          completeValue.deleteOnEnd = false
          completeValue.authorizedFrom = null
          completeValue.authorizedTo = null
          completeValue.repeatDaysOfWeek = repeatDaysOfWeek
          completeValue.repeatStartHour = getTimeInCurrentTimezoneAsString(startDate,startTime)
          completeValue.repeatEndHour = getTimeInCurrentTimezoneAsString(endDate, endTime)
          completeValue.repeatTimezone = getTimezone()
          completeValue.disabled = !isNowInRepeatTimes(
            completeValue.repeatStartHour, completeValue.repeatEndHour, completeValue.repeatTimezone, repeatDaysOfWeek
          )
        } else {
          // If repeatDays is not checked, complete value with valueDates
          const startUTC = getDatetimeAsUTC(startDate, startTime)
          const endUTC = getDatetimeAsUTC(endDate, endTime)
          completeValue.authorizedFrom = startUTC
          completeValue.authorizedTo = endUTC
          completeValue.repeatDaysOfWeek = null
          completeValue.repeatStartHour = null
          completeValue.repeatEndHour = null
          completeValue.repeatTimezone = null
          completeValue.disabled = !isNowBetweenDatetimes(startDate, startTime, endDate, endTime)
        }
      }
    } else {
      completeValue.enabledForUse = false
      completeValue.disabled = true
    }
    this.props.onSubmit(completeValue)
  }

  getFields = () => {
    const { value } = this.state
    const { profile } = value
    let userEnums = {
      'user': I18n.t('shared.user'),
      'admin': I18n.t('shared.admin')
    }
    // If the connection owner is the owner of the device, can make other users as owners
    if (this.props.connection.owner === this.props.connection.device.owner) { userEnums['owner'] = I18n.t('shared.owner') }
    // If the owner of the device is the edited user, it must have the owner profile so the APP does not break
    if (this.props.connection.device.owner === this.props.user.owner) { userEnums['owner'] = I18n.t('shared.owner') }
    // If the profile of the user is installer, it must have the installer profile so the APP does not break
    if (profile === 'installer') { userEnums['installer'] = I18n.t('shared.installer') }
    const fields = {
      _id: t.maybe(t.String),
      name: t.String,
      email: t.String,
      profile: t.enums(userEnums, 'Profile')
    }
    const fieldsDates = {
      startDate: t.String,
      endDate: t.String,
      startTime: t.String,
      endTime: t.String,
    }
    return { fields, fieldsDates }
  }

  getFieldsOptions = () => {
    const fieldsOptions = {
      _id: { hidden: true },
      name: {
        label: I18n.t('shared.name'),
        editable: false
      },
      email: {
        label: I18n.t('shared.email'),
        editable: false
      },
      profile: {
        label: I18n.t('shared.profileLabel'),
        nullOption: false
      }
    }
    let fieldsOptionsDates = {
      startDate: {
        label: I18n.t('screens.scheduleForm.labels.startDate'),
        mode: 'date',
        minimumDate: new Date(),
        hidden: this.state.repeatDays ? true : false,
        config: {
          format: (date) => inputDateFormat(date),
        }
      },
      endDate: {
        label: I18n.t('screens.scheduleForm.labels.endDate'),
        mode: 'date',
        minimumDate: new Date(),
        hidden: this.state.repeatDays ? true : false,
        config: {
          format: (date) => inputDateFormat(date),
        }
      },
      startTime: {
        label: I18n.t('screens.scheduleForm.labels.startTime'),
        mode: 'time',
        minuteInterval: 1,
        minimumDate: !this.state.repeatDays ? new Date() : null,
        config: {
          format: (time) => inputTimeFormat(time),
        }
      },
      endTime: {
        label: I18n.t('screens.scheduleForm.labels.endTime'),
        mode: 'time',
        minuteInterval: 1,
        minimumDate: !this.state.repeatDays ? new Date() : null,
        config: {
          format: (time) => inputTimeFormat(time),
        }
      }
    }
    // if (this.state.repeatDays) {
    //   let customStylesheet = objectDeepCopy(stylesForms)
    //   customStylesheet.controlLabel.normal.marginTop = 0
    //   customStylesheet.controlLabel.error.marginTop = 0
    //   fieldsOptionsDates['startTime']['stylesheet'] = customStylesheet
    // } else {
    //   let customStylesheet = objectDeepCopy(stylesForms)
    //   customStylesheet.controlLabel.normal.marginTop = Metrics.doubleBaseMargin
    //   customStylesheet.controlLabel.error.marginTop = Metrics.doubleBaseMargin
    //   fieldsOptionsDates['startTime']['stylesheet'] = customStylesheet
    // }
    return { fieldsOptions, fieldsOptionsDates }
  }

  renderExtraContentEditUser = (fields, fieldsOptions) => {
    const { device = {} } = this.props.connection || {}
    const { trialEnded = false } = device
    return (
      <>
        <BaseSwitch
          label={null}
          help={I18n.t('shared.enabled')}
          value={this.state.enabled}
          disabled={trialEnded}
          onChange={(value) => { this.onEnabledSwitchChange(value) }}
          customSwitchRowStyle={styles.switchRow}
        />
        {
          this.state.enabled &&
          <>
            <BaseSwitch
              label={null}
              help={I18n.t('screens.authorizedPhoneForm.labels.unlimitedAccess')}
              value={this.state.unlimitedAccess}
              disabled={trialEnded}
              onChange={(value) => { this.onUnlimitedAccessSwitchChange(value) }}
              customSwitchRowStyle={styles.switchRow}
            />
            {
              !this.state.unlimitedAccess &&
              <div style={Object.assign({}, styles.complementaryForm, { marginTop: Metrics.baseMargin })}>
                <BaseForm
                  name={'authorizedPhoneAccessDatesForm'}
                  onSubmit={() => {}}
                  fields={fields}
                  fieldsOptions={fieldsOptions}
                  onChange={this.onChangeDates}
                  value={this.state.valueDates}
                  fetching={this.props.fetching}
                  withoutSubmit={true}
                />
                <BaseSwitch
                  label={null}
                  help={I18n.t('screens.authorizedPhoneForm.labels.deleteOnEnd')}
                  value={this.state.deleteOnEnd}
                  disabled={false}
                  onChange={(value) => { this.onDeleteOnEndSwitchChange(value) }}
                  customSwitchRowStyle={styles.switchRow}
                />
                <BaseSwitch
                  label={null}
                  help={I18n.t('screens.authorizedPhoneForm.labels.repeatDays')}
                  value={this.state.repeatDays}
                  disabled={false}
                  onChange={(value) => { this.onRepeatDaysSwitchChange(value) }}
                  customSwitchRowStyle={styles.switchRow}
                />
                {
                  this.state.repeatDays &&
                  <WeekdaysSelector
                    onChange={(repeatDaysOfWeek) => { this.setState({ repeatDaysOfWeek }) }}
                    value={this.state.repeatDaysOfWeek || ''}
                  />
                }
              </div>
            }
          </>
        }
      </>
    )
  }

  renderEditUser = () => {
    const { fields, fieldsDates } = this.getFields()
    const { fieldsOptions, fieldsOptionsDates } = this.getFieldsOptions()
    return (
      <FormCard
        name={'userForm'}
        title={this.props.user ? I18n.t('screens.userForm.titleFormEdit') : I18n.t('screens.userForm.titleForm')}
        onSubmit={this.onSubmit}
        onChange={this.onChange}
        deleteButton={this.props.user ? true : false}
        onDelete={() => { this.props.onDelete(this.props.user) }}
        fields={fields}
        fieldsOptions={fieldsOptions}
        value={this.state.value}
        fetching={this.props.fetching}
        submitButtonDisabled={this.props.fetching}
        renderExtraContentBeforeButtons={() => this.renderExtraContentEditUser(fieldsDates, fieldsOptionsDates)}
      />
    )
  }

  render = () => {
    return (
      <>
        {
          this.props.user
          ? this.renderEditUser()
          : this.renderNewUser()
        }
        <FullScreenLoader visible={this.props.fetching && this.props.user !== null && this.props.user !== undefined}/>
      </>
    )
  }

}
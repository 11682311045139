import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getActualConnection } from '../../../Lib/ConnectionsUtils'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import I18n from '../../../i18n/i18n'
import LocationForm from '../../../Components/Screens/CheckIn/Forms/LocationForm'
import { ModalService } from '../../../Services/Modal'


class LocationFormScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      location: null,
      selectedConditionsDocument: null
    }
    this.fileInput = null
  }

  componentDidMount = () => {
    const { connectionId, location } = this.props
    this.setState({ connectionId, location })
    this.fileInput = document.getElementById('conditionsDocument')
    if (this.fileInput) {
      this.fileInput.addEventListener('change', this.fileInputChange, false);
    }
  }

  fileInputChange = (event) => {
    const { target } = event
    const { files = [] } = target || {}
    const file = files[0]
    if (file) { this.setState({ selectedConditionsDocument: file }) }
    else { /* DO NOTHING */ }
  }

  saveLocation = (location) => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    const { device, numberOfSubLocations: connectionNumberOfSubLocations, location: connectionLocation } = connection || {}
    const { deviceId } = connectionLocation || {}
    const { numberOfSubLocations: deviceNumberOfSubLocations } = device || {}
    this.props.saveLocation(this.state.connectionId, { 
      ...location, 
      numberOfSubLocations: deviceId ? deviceNumberOfSubLocations : connectionNumberOfSubLocations,
      selectedConditionsDocument: this.state.selectedConditionsDocument ? this.state.selectedConditionsDocument : null
    })
  }

  deleteLocation = (location) => {
    ModalService.show(
      I18n.t('modals.deleteLocation.title'), 
      I18n.t('modals.deleteLocation.text'), 
      () => { this.props.deleteLocation(this.state.connectionId, location) }, 
      I18n.t('modals.deleteLocation.buttons.ok'),
      null,
      true
    )
  }

  selectConditionsDocument = () => {
    this.fileInput.click()
  }

  openConditionsFile = (fileUri) => {
    window.open(fileUri, '_blank')
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    const { deviceId = null } = connection || {}
    return (
      <>
        <LocationForm
          activeLang={this.props.activeLang}
          user={this.props.user}
          connectionHasDevice={deviceId !== null}
          location={this.state.location}
          fetching={this.props.fetching}
          selectedConditionsDocumentName={this.state.selectedConditionsDocument ? this.state.selectedConditionsDocument.name || null : null}
          onSubmit={this.saveLocation}
          onDelete={this.deleteLocation}
          selectConditionsDocument={this.selectConditionsDocument}
          openConditionsFile={this.openConditionsFile}
        />
        <input
          type='file'
          id='conditionsDocument'
          name='conditionsDocument'
          accept='application/pdf'
          style={{ display: 'none' }}
        />
      </>
    )

  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    user: state.user.user,
    activeLang: state.i18n.activeLang,
    fetching: state.connections.fetchingConnectionForm
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveLocation: (connectionId, location) => dispatch(ConnectionsActions.connectionSaveLocationRequest(connectionId, location)),
    deleteLocation: (connectionId, location) => dispatch(ConnectionsActions.connectionDeleteLocationRequest(connectionId, location))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationFormScreen)
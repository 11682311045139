import RaixerConfig from '../../../Config/RaixerConfig'
import { Colors, Fonts, Metrics, Shadows } from '../../../Themes/'

export default {
  snackWrapper: {
    position: 'fixed',
    bottom: Metrics.doubleBaseMargin,
    left: '25%',
    zIndex: Metrics.zIndexes.snackbar,
    width: '50%'
  },
  snackContainter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: RaixerConfig.animations.snackbar.height,
    padding: Metrics.doubleBaseMargin,
    borderRadius: Metrics.buttonRadius,
    ...Shadows.card
  },
  snackLeft: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    marginLeft: Metrics.doubleBaseMargin,
  },
  snackRight: {
    display: 'flex',
    alignSelf: 'flex-end',
    flex: 1
  },
  snackIcon: {
    marginRight: Metrics.doubleBaseMargin,
    fontSize: Metrics.icons.big
  },
  snackText: {
    flex: 1,
    flexWrap: 'wrap',
    color: Colors.white,
    fontSize: Fonts.size.regular,
    textAlign: 'left',
    fontFamily: Fonts.type.bold
    // fontWeight: Fonts.weight.bold
  },
  snackAction: {
    padding: 0, 
    maxWidth: '15%', 
    marginRight: (Metrics.baseMeasure * 6) + 'px'
  },
  snackActionText: {
    color: Colors.white,
    fontSize: Fonts.size.regular,
    textAlign: 'right',
    fontFamily: Fonts.type.bold
    // fontWeight: Fonts.weight.bold
  },
  snackActionClose: {
    padding: 0,
    width: '30px',
    position: 'absolute',
    right: 0
  }
}
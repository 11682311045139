import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RaixerConfig from '../../../Config/RaixerConfig'
import I18n from '../../../i18n/i18n'
import AppText from '../Texts/AppText'
import { Images } from '../../../Themes'
import styles from '../../Styles/UI/Stores'

export default class Stores extends Component {

  static propTypes = {
    activeLang: PropTypes.string.isRequired
  }

  static defaultProps = {
    activeLang: null
  }

  render = () => {
    return (
      <>
        <AppText text={I18n.t('shared.availableOnPhone')} customStyle={styles.text} />
        <div style={styles.buttonsContainer}>
          <a href={RaixerConfig['store'][this.props.activeLang]['appStore']} target={'_blank'} style={styles.firstButton} rel={'noopener noreferrer'}>
            <img src={Images['store'][this.props.activeLang]['appStore']} style={styles.appStoreImg} alt={'App Store - Raixer'} />
          </a>
          <a href={RaixerConfig['store'][this.props.activeLang]['playStore']} target={'_blank'} rel={'noopener noreferrer'}>
            <img src={Images['store'][this.props.activeLang]['playStore']} style={styles.playStoreImg} alt={'Google Play - Raixer'} />
          </a>
        </div>
      </>
    )
  }

}
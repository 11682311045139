import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import I18n from '../../../i18n/i18n'
import Users from '../../../Components/Screens/Connection/Tabs/Users'
import { ModalService } from '../../../Services/Modal'
import { FormModalService } from '../../../Services/FormModal'
import { connectionIsWifiDevice } from '../../../Lib/ConnectionsUtils'

class UsersTab extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired
  }

  static defaultProps = {
    connection: null
  }

  goToUserForm = (user) => {
    const { connection } = this.props
    const { _id, device = {} } = connection || {}
    const { trialEnded = false } = device
    if (user) {
      FormModalService.open('user', { connectionId: _id, user })
    } else {
      if (!trialEnded) {
        FormModalService.open('user', { connectionId: _id, user })
      } else {
        this.showModalUpgrade()
      }
    }
  }

  showModalUpgrade = () => {
    const { connection } = this.props
    const { _id, device = {}, owner: connectionOwner } = connection
    const { owner: deviceOwner } = device
    if (deviceOwner === connectionOwner) {
      const isWifiDevice = connectionIsWifiDevice(connection)
      const plan = { type: isWifiDevice ? 'intercom' : 'access' }
      ModalService.show(
        I18n.t('modals.upgradeFeatureOwner.title'), 
        I18n.t('modals.upgradeFeatureOwner.text'),
        () => {
          FormModalService.open('upgrade', { connectionId: _id, plan })
        },
        I18n.t('modals.upgradeFeatureOwner.buttons.ok')
      )
    } else {
      ModalService.show(
        I18n.t('modals.upgradeNotOwner.title'), 
        I18n.t('modals.upgradeNotOwner.text')
      )
    }
  }

  render = () => {
    const { connection } = this.props
    const { device, profile } = connection || {}
    const { trialEnded = false } = device || {}
    return (
      <Users
        connection={connection}
        isUserAdmin={profile === 'admin'}
        trialEnded={trialEnded}
        goToUserForm={this.goToUserForm}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    activeLang: state.i18n.activeLang
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTab)

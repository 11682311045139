import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from '../../Styles/UI/Text'

export default class AppTitle extends Component {

  static propTypes = {
    text: PropTypes.string.isRequired,
    textStyle: PropTypes.object,
    customStyle: PropTypes.object
  }

  static defaultProps = {
    text: null,
    textStyle: styles.title,
    customStyle: {}
  }

  render = () => {
    return (
      <h1 style={Object.assign({}, this.props.textStyle, this.props.customStyle)}>
        { this.props.text }
      </h1>
    )
  }

}

import { Colors, Metrics } from '../../../Themes/'

export default {
  cardContainer: {
    margin: Metrics.baseMargin + ' 0',
    backgroundColor: Colors.cardBackground,
    borderStyle: 'solid',
    borderRadius: Metrics.baseMargin,
    borderWidth: Metrics.buttonBorderWidth,
    borderColor: Colors.lighterGray,
    display: 'flex',
    flex: 1
  },
  card: {
    padding: Metrics.doubleBaseMargin,
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flex: 1
  },
  titleAndEditContainer: {
    marginBottom: Metrics.doubleBaseMargin,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleContainer: {
    flex: 3
  },
  editContainer: {
    flex: 1,
    display: 'flex',
    alignSelf: 'flex-start',
    justifyContent: 'flex-end'
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  buttonsContainer: {
    marginTop: Metrics.doubleBaseMarginPlus
  }
}
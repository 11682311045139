import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { capitalizeFirstLetter } from '../../../../Lib/StringsUtils'
import { getConnectionDoorNameByPort } from '../../../../Lib/ConnectionsUtils'
import I18n from '../../../../i18n/i18n'
import ConnectionTabTitle from '../../../UI/Connections/ConnectionTabTitle'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import ListCard from '../../../UI/ContentCards/ListCard'
import AppText from '../../../UI/Texts/AppText'
import MainButton from '../../../UI/Buttons/MainButton'
import { Metrics } from '../../../../Themes'
import styles from '../../../Styles/Screens/Connection'

export default class AutoAccess extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired,
    goToAutoAccessConfiguration: PropTypes.func.isRequired,
    goToScheduleForm: PropTypes.func.isRequired,
    showModalUpgrade: PropTypes.func.isRequired,
    activeLang: PropTypes.string.isRequired,
    hasMultipleAutoAccessDoors: PropTypes.bool
  }

  static defaultProps = {
    connection: null,
    goToAutoAccessConfiguration: null,
    goToScheduleForm: null,
    showModalUpgrade: null,
    activeLang: null,
    hasMultipleAutoAccessDoors: null
  }

  getRequiredFields = () => {
    let alwaysActive = false
    let offlineAutoAccess = false
    let schedules = []
    try {
      const { device, schedules: connectionSchedules } = this.props.connection
      const { autoAccessAlwaysActive, offlineAutoAccess: deviceOfflineAutoAccess } = device
      alwaysActive = autoAccessAlwaysActive
      offlineAutoAccess = deviceOfflineAutoAccess
      if (!autoAccessAlwaysActive) { schedules = connectionSchedules ? connectionSchedules : [] }
      else { schedules = [] }
    } catch (err) {
      alwaysActive = false
      offlineAutoAccess = false
      schedules = []
    }
    return { alwaysActive, offlineAutoAccess, schedules }
  }

  getAutoAccessConfigurationList = (alwaysActive, offlineAutoAccess) => {
    return [
      { left: I18n.t('screens.connection.alwaysActive'), right: alwaysActive ? I18n.t('shared.yes') : I18n.t('shared.no') }, 
      { left: I18n.t('screens.connection.contingencyMode'), right: offlineAutoAccess ? I18n.t('shared.active') : I18n.t('shared.inactive') }
    ]
  }

  getSchedulesList = (schedules) => {
    return schedules.map(s => {
      const { _id, campaignId, doorPort, schedule, time, createdBy } = s
      const added = moment(schedule).add(time, 'minutes').valueOf()
      let item = { _id, campaignId, doorPort, dateFrom: s.schedule, dateTo: added, createdBy: createdBy ? capitalizeFirstLetter(createdBy) : null }
      if (this.props.hasMultipleAutoAccessDoors) {
        item.left = getConnectionDoorNameByPort(this.props.connection, doorPort)
        item.right = ' '
      }
      return item
    })
  }

  getContent = (alwaysActive, trialEnded, schedules) => {
    if (alwaysActive || schedules.length === 0) {
      const text = alwaysActive ?
        I18n.t('screens.connection.autoAccessAlwaysActiveDescription') : I18n.t('screens.connection.schedulesEmpty')
      return (
        <ContentCard
          title={I18n.t('shared.schedule')}
          renderContent={() => <AppText text={text}/>}
          renderButtons={
            () => {
              if (alwaysActive) { return null }
              return (
                <MainButton
                  buttonText={I18n.t('screens.connection.schedulesAddButton')}
                  onPress={() => {
                    if (trialEnded) { this.props.showModalUpgrade() }
                    else { this.props.goToScheduleForm() }
                  }}
                  disabled={false}
                  icon={'add-circle'}
                  customButtonStyle={{ margin: 0 }}
                />
              )
            }
          }
        />
      )
    }
    return (
      <ListCard
        title={I18n.t('shared.schedule')}
        list={this.getSchedulesList(schedules)}
        listItemType={'dates'}
        withEditButton={true}
        addButton={true}
        addButtonText={I18n.t('screens.connection.schedulesAddButton')}
        customDateFromText={this.props.activeLang !== 'en' ? I18n.t('shared.from') : I18n.t('shared.start')}
        customDateToText={this.props.activeLang !== 'en' ? I18n.t('shared.to') : I18n.t('shared.finish')}
        onAdd={() => {
          if (trialEnded) { this.props.showModalUpgrade() }
          else { this.props.goToScheduleForm() }
        }}
        onEditItem={(item) => { this.props.goToScheduleForm(item) }}
      />
    )
  }

  render = () => {
    const { device = {} } = this.props.connection || {}
    const { guestyListingId, trialEnded = false } = device
    const { alwaysActive, offlineAutoAccess, schedules } = this.getRequiredFields()
    return (
      <div style={styles.mainBodyScroll}>
        <ConnectionTabTitle 
          title={I18n.t('shared.autoAccess')} 
          integrationImage={guestyListingId ? 'guesty' : null} 
          onboardingImage={'autoAccess'}
        />
        <div style={{ marginTop: Metrics.baseMargin }}>
          <ListCard
            title={I18n.t('shared.configuration')}
            list={this.getAutoAccessConfigurationList(alwaysActive, offlineAutoAccess)}
            listItemType={'simple'}
            customOnPressEdit={trialEnded ? this.props.showModalUpgrade : this.props.goToAutoAccessConfiguration}
            withEditButton={true}
            addButton={false}
          />
          { this.getContent(alwaysActive, trialEnded, schedules) }
        </div>
      </div>
    )
  }

}
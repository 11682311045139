import Metrics from './Metrics'
import Colors from './Colors'

// This file is for a reusable grouping of Theme items.
// Similar to an XML fragment layout in Android

const ApplicationStyles = {
  body: {
    margin: 0
  },
  screen: {
    mainContainer: {
      height: '100vh',
      backgroundColor: Colors.mainBackground,
      display: 'flex'
    },
    container: {
      padding: '0 0 0 ' + Metrics.appContainerPadding,
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    authContainer: {
      padding: Metrics.doubleBaseMargin,
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    fixedHeaderBackground:{
      width: '100%',
      height: 300,
      position: 'absolute',
      backgroundColor: Colors.theme1
    }
  }
}

export default ApplicationStyles

import { ble } from './Utils'

export default {
  appVersion: '421',
  animations: {
    lists: {
      itemFlex: 3,
      editButtonFlex: 1,
      duration: 400
    },
    snackbar: {
      height: 40,
      duration: 400,
      showTimeWithoutAction: 4000,
      showTimeWithAction: 10000
    }
  },
  edits: {
    toggleMaxListLength: 10
  },
  api: {
    baseUrl: 'https://api.raixer.com/',
    baseHeaders: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json'
    },
    tokenTimeout: 5000,
    timeout: 25000,
    autoConfigurationMaxTime: 30000,
    autoConfigurationMaxExtraTime: 5000,
    nukiPairMaxTime: 120000,
    retries: 3
  },
  stripe: {
    baseUrl: 'https://api.stripe.com/v1/',
    secretKey: 'sk_live_JeiIKZnVBefNjJ0GJSL0yT7r',
    secretKeyDev: 'sk_test_fypM8QErjO4ggIg8MzgfZdTI',
    baseHeaders: {
      'Authorization': 'Basic c2tfbGl2ZV9KZWlJS1puVkJlZk5qSjBHSlNMMHlUN3I6'
    },
    baseHeadersDev: {
      'Authorization': 'Basic c2tfdGVzdF9meXBNOFFFcmpPNGdnSWc4TXpnZlpkVEk6'
    },
    timeout: 25000
  },
  softap: {
    baseUrl: 'http://192.168.0.1/',
    host: '192.168.0.1',
    timeout: 25000
  },
  ble,
  connections: {
    defaultMaxOpenings: 4,
    maxTimeMinutes: 3,
    maxTimeMinutesApiUser: 360,
    historyPageSize: 10,
    waitCalibrate: 10,
    limitVariableFormNoValue: 30,
    callLimitVariableFormNoValue: 5,
    trialMaxDoorAuthorizedPhones: 9,
    freeMaxDoorAuthorizedPhones: 1,
    actionsNames: {
      startJob: 'start-job',
      stopJob: 'stop-job',
      openDoor: 'open-door',
      activatePiston: 'electric-piston/1',
      deactivatePiston: 'electric-piston/0',
      nukiOpenDoor: 'open',
      nukiActivatePiston: 'close',
      nukiDeactivatePiston: 'unlock',
      calibrateServo: 'calibrate-servo',
      openGyro: 'action/open',
      closeGyro: 'action/close',
      unlockGyro: 'action/unlock',
      calibrateGyro: 'action/calibrate',
      confGyro: 'action/conf',
      bleOpenDoor: 'open',
      bleCloseDoor: 'close',
      bleUnlockDoor: 'unlock',
      bleCalibrateDoor: 'cal',
      bleCalibrationConfig: 'conf',
      bleStop: 'stop'
    },
    warningsActions: {
      deviceOffline: 'configure-device',
      deviceUpdating: 'refresh-device',
      deviceTrial: 'trial',
      deviceTrialEnded: 'upgrade',
      deviceTrialEndedNotOwner: 'show-upgrade-modal',
      deviceTrialAccountEnded: 'blocked-before-upgrade',
      deviceTrialAccountEndedNotOwner: 'blocked-before-show-upgrade-modal',
      offlineAutoAccess: 'offline-auto-access',
      refreshVitals: 'refresh-vitals',
      refreshVitalsBattery: 'refresh-vitals-battery',
      openClosedBlocked: 'open-closed-blocked',
      autoAccessBlocked: 'auto-access-blocked',
      firmwareUpdate: 'firmware-update',
      firmwareUpdateNotOwner: 'firmware-update-not-owner',
      nukiBatteryCritical: 'show-nuki-battery-critical-modal',
      noRaixerDevice: 'show-no-device',
      linkRaixerDevice: 'show-link-device',
      deviceUpgradeRemaining: 'show-upgrade-remaining-modal',
      paymentFailed: 'payment-failing',
      paymentFailedNotOwner: 'show-payment-failing-not-owner-modal',
      paymentFailedBlocked: 'payment-failed',
      paymentFailedBlockedNotOwner: 'show-payment-failed-not-owner-modal',
      particleDisabled: 'particle-disabled',
      gyroConnectWifi: 'gyro-connect-wifi',
      gyroControlIntercom: 'gyro-control-intercom',
      gyroNoInternet: 'gyro-no-internet',
      gyroTrial: 'gyro-trial',
      gyroElectricity: 'gyro-electricity',
      gyroBattery: 'gyro-battery',
      proGyroBattery: 'pro-gyro-battery',
      miniGyroBattery: 'mini-gyro-battery'
    },
    deviceVersions: {
      v1: {
        // Mini v1
        id: 'v1',
        maxDoors: 1,
        maxNukiDoors: 0,
        maxGyroDoors: 0,
        maxSensors: 0,
        maxPushButtons: 0,
        canAddSensors: false
      },
      v2: {
        // Mini v2
        id: 'v2',
        maxDoors: 2,
        maxNukiDoors: 0,
        maxGyroDoors: 0,
        maxSensors: 1,
        maxPushButtons: 0,
        canAddSensors: true
      },
      v3: {
        // Pro v1
        id: 'v3',
        maxDoors: 3,
        maxNukiDoors: 0,
        maxGyroDoors: 0,
        maxSensors: 1,
        maxPushButtons: 0,
        canAddSensors: true
      },
      v4: {
        // Mini v3
        id: 'v4',
        maxDoors: 2,
        maxNukiDoors: 0,
        maxGyroDoors: 0,
        maxSensors: 1,
        maxPushButtons: 0,
        canAddSensors: true
      },
      v5: {
        // Mini v4
        id: 'v5',
        maxDoors: 2,
        maxNukiDoors: 0,
        maxGyroDoors: 0,
        maxSensors: 2,
        maxPushButtons: 2,
        canAddSensors: true
      },
      v6: {
        // Pro 3G
        id: 'v6',
        maxDoors: 2,
        maxNukiDoors: 1,
        maxGyroDoors: 1,
        maxSensors: 2,
        maxPushButtons: 2,
        canAddSensors: true
      },
      v7: {
        // Pro Wifi
        id: 'v7',
        maxDoors: 2,
        maxNukiDoors: 1,
        maxGyroDoors: 1,
        maxSensors: 2,
        maxPushButtons: 2,
        canAddSensors: true
      },
      gyro: {
        id: 'gyro',
        maxDoors: 2,
        maxNukiDoors: 0,
        maxGyroDoors: 0,
        maxSensors: 2,
        maxPushButtons: 2,
        canAddSensors: true
      },
      v8: {
        // Mini v5
        id: 'v8',
        maxDoors: 2,
        maxNukiDoors: 1,
        maxGyroDoors: 1,
        maxSensors: 2,
        maxPushButtons: 0,
        canAddSensors: true
      },
    },
    doorDefaultValues: {
      openingValue: 9000,
      tolerance: 8100,
      waitTimeFirst: 2,
      waitBuzzerTime: 0,
      maxOpenings: 4,
      intercomCallFirst: false,
      intercomPickUpFirst: false,
      waitBetweenBells: 0,
      autoAccessHours: 6
    },
    sensorDefaultValues: {
      movementSoundGroupingTime: 15,
      noisePresence: 60,
      noiseAlarm: 90
    },
    portPositions: {
      default: 1,
      right: 1,
      left: 2,
      middle: 3,
      nuki: 100,
      '1': 'right',
      '2': 'left',
      '3': 'middle',
      '100': 'nuki'
    }
  },
  qrCodes: {
    baseUrl: 'https://api.raixer.com/qr',
    urlParts: {
      domainAndQRRoute: 'raixer.com/qr',
      accessRoute: '/new-access-device',
      callbackRoute: '/callback'
    }
  },
  delays: {
    startup: 1000,
    logout: 2000,
    cleanConnectionTask: 3000,
    afterFormTask: 1000,
    toggleEdit: 200,
    autoConfigurationBetweenSteps: 2000,
    afterAddDevice: 3000,
    softapBetweenTasks: 3000,
    nukiTask: 10000,
    getAppState: 250
  },
  intervals: {
    connectionTimer: 1000
  },
  webviews: {
    termsUri: 'https://www.raixer.com/legal',
    miniUri: 'https://raixer.com/tienda/mini/',
    proUri: 'https://raixer.com/tienda/pro/',
    twelveVUri: 'https://raixer.com/tienda/raixer-12v/',
    storeUri: 'https://www.raixer.com/tienda'
  },
  subscriptionPlans: [
    {
      type: 'intercom',
      billCycle: 'month',
      price: 9.99,
      id: 'intercom10'
    },
    {
      type: 'intercom',
      billCycle: 'year',
      price: 99.99,
      id: 'intercom100'
    },
    {
      type: 'access',
      billCycle: 'month',
      price: 14.99,
      id: 'access15'
    },
    {
      type: 'access',
      billCycle: 'year',
      price: 149.99,
      id: 'access150'
    },
    {
      type: 'phone',
      billCycle: 'month',
      price: 2.99,
      id: 'phone3'
    },
    {
      type: 'phone',
      billCycle: 'year',
      price: 29.99,
      id: 'phone30'
    },
    {
      type: 'guesty',
      billCycle: 'month',
      price: 4.99,
      id: 'guesty5'
    },
    {
      type: 'guesty',
      billCycle: 'year',
      price: 49.99,
      id: 'guesty50'
    },
    {
      type: 'checkin',
      billCycle: 'year',
      price: 29.99,
      id: 'checkin30'
    },
    {
      type: 'checkin',
      billCycle: 'month',
      price: 2.99,
      id: 'checkin3'
	  }
  ],
  checkIn: {
    locationTypes: [
      'Apartamento', 'Albergue', 'Bloque_Apartamentos', 'Camping', 'Casavut', 'Casa_Rural', 'Hostal', 'Hotel', 'Motel',
      'Otro_Profesional', 'Otro_Vut_Particular', 'Pension', 'Posada', 'Villa'
    ],
    policeTypes: ['Ministerio', 'Guardia_Civil', 'Policia_Nacional', 'Mossos', 'Ertzaintza'],
    provinces: {
      'A_Coruña': 'GALICIA', 'Álava': 'PAIS_VASCO', 'Albacete': 'CASTILLA_LA_MANCHA', 'Alicante': 'COMUNIDAD_VALENCIANA',
      'Almería': 'ANDALUCIA', 'Asturias': 'ASTRUIAS', 'Ávila': 'CASTILLA_Y_LEÓN', 'Badajoz': 'ESTREMADURA', 'Baleares': 'ISLAS_BALEARES',
      'Barcelona': 'CATALUÑA', 'Bizkaia': 'PAIS_VASCO', 'Burgos': 'CASTILLA_Y_LEON', 'Cáceres': 'ESTREMADURA', 'Cádiz': 'ANDALUCIA',
      'Cantabria': 'CANTABRA', 'Castellón': 'COMUNIDAD_VALENCIANA', 'Ceuta': 'CASTILLA_LA_MANCHA', 'Ciudad_Real': 'CASTILLA_LA_MANCHA',
      'Córdoba': 'ANDALUCIA', 'Cuenca': 'CASTILLA_LA_MANCHA', 'Girona': 'CATALUÑA', 'Granada': 'ANDALUCIA',
      'Guadalajara': 'CASTILLA_LA_MANCHA', 'Gipuzkoa': 'PAIS_VASCO', 'Huelva': 'ANDALUCIA', 'Huesca': 'ARAGON', 'Jaén': 'ANDALUCÍA',
      'Las_Palmas': 'CANARIAS', 'León': 'CASTILLA_Y_LEON', 'Lleida': 'CATALUÑA', 'Lugo': 'GALICIA', 'Madrid': 'COMUNIDAD_DE_MADRID',
      'Málaga': 'ANDALUCIA', 'Melilla': 'MELILLA', 'Murcia': 'REGIÓN_DE_MURCIA', 'Navarra': 'NAVARRA', 'Ourense': 'GALICIA',
      'Palencia': 'CASTILLA_Y_LEÓN', 'Pontevedra': 'GALICIA', 'La_Rioja': 'LA RIOJA', 'Salamanca': 'CASTILLA_Y_LEON',
      'Santa_Cruz_de_Tenerife': 'CANARIAS', 'Segovia': 'CASTILLA_Y_LEON', 'Sevilla': 'ANDALUCIA', 'Soria': 'CASTILLA_Y_LEON',
      'Tarragona': 'CATALUÑA', 'Teruel': 'ARAGON', 'Toledo': 'CASTILLA_LA_MANCHA', 'Valencia': 'COMUNIDAD_VALENCIANA',
      'Valladolid': 'CASTILLA_Y_LEÓN', 'Zamora': 'CASTILLA_Y_LEON', 'Zaragoza': 'ARAGON'
    },
    paymentTypes: ['Efectivo', 'Tarjeta de crédito', 'Tarjeta de débito', 'Plataforma de Pago', 'Transferencia', 'Pago por móvil', 'Tarjeta regalo', 'Pago en destino', 'Otros']
  },
  store: {
    en: {
      playStore: 'https://play.google.com/store/apps/details?id=com.raixer.app&hl=en',
      appStore: 'https://apps.apple.com/app/raixer-cerradura-inteligente/id1133964848#?platform=iphone'
    },
    es: {
      playStore: 'https://play.google.com/store/apps/details?id=com.raixer.app&hl=es',
      appStore: 'https://apps.apple.com/es/app/raixer-cerradura-inteligente/id1133964848#?platform=iphone'
    }
  }
}

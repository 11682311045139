import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormCard from '../../../UI/ContentCards/FormCard'
import FullScreenLoader from '../../../UI/Loaders/FullScreenLoader'
import t from 'tcomb-form/lib'
import moment from 'moment'
import { objectDeepCopy } from '../../../../Lib/ObjectsUtils'
import BaseSwitch from '../../../UI/Switchs/BaseSwitch'
import BaseForm from '../../../UI/Forms/BaseForm'
import styles from '../../../Styles/Screens/Forms'
import I18n from '../../../../i18n/i18n'

export default class BookingForm extends Component {

  static propTypes = {
    activeLang: PropTypes.string.isRequired,
    connection: PropTypes.object,
    booking: PropTypes.object,
    fetching: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
  }

  static defaultProps = {
    activeLang: null,
    connection: null,
    booking: null,
    fetching: null,
    onSubmit: null,
    onDelete: null
  }

  constructor(props) {
    super(props)
    this.state = {
      valueForm1: {
        startDate: new Date()
      },
      valueForm2: {
        startHour: new Date(),
        residencyDays: 1,
        endDate: moment().add(1, 'days').format('DD-MM-YYYY'),
        guests: 1,
        locationId: null,
        _id: null
      },
      switchStartHour: false
    }
  }

  componentDidUpdate = (prevProps) => {
    const { booking: prevBooking } = prevProps
    const { booking } = this.props
    if (!prevBooking && booking) {
      const {
        startDate = new Date(),
        residencyDays = 1,
        endDate = moment().add(1, 'days'),
        guests = 1,
        locationId = '',
        _id = ''
      } = booking
      this.setState({ 
        valueForm1: {
          startDate: new Date(startDate)
        },
        valueForm2: {
          startHour: new Date(startDate),
          residencyDays, 
          endDate: moment(endDate).format('DD-MM-YYYY').toString(),
          guests, 
          locationId,
          _id
        },
        switchStartHour: moment(startDate).hour() !== 14 ? true : false
      })
    }
  }

  getFields = () => {
    let fieldForm1 = {
      startDate: t.Date,
    }
    let fieldForm2 = {
      startHour: t.Date,
      residencyDays: t.refinement(t.Number, (number) => { return number >= 1}),
      endDate: t.String,
      guests: t.refinement(t.Number, (number) => { return number >= 1}),
      locationId: t.maybe(t.String),
      _id: t.maybe(t.String),
    }
    return {fieldForm1, fieldForm2}
  }

  getFieldsOptions = () => {
    const { valueForm2 } = this.state
    const { residencyDays, guests } = valueForm2
    let fieldsOptionsForm1 = {
      startDate: {
        label: I18n.t('screens.bookingForm.labels.startDate'),
        minimumDate: new Date(),
        mode: 'date',
        help: I18n.t('screens.bookingForm.help.startDate'),
        config: {
          format: (date) => String(moment(date).format('DD-MM-YYYY'))
        }
      }
    }
    let fieldsOptionsForm2 = {
      startHour: {
        label: I18n.t('screens.bookingForm.labels.startHour'),
        minimumDate: new Date(),
        minuteInterval: 1,
        mode: 'time',
        help: I18n.t('screens.bookingForm.help.startHour'),
        config: {
          format: (time) => String(moment(time).format('HH:mm'))
        },
        hidden: this.state.switchStartHour ? false : true
      },
      residencyDays: {
        label: I18n.t('screens.bookingForm.labels.residency'),
        help: I18n.t('screens.bookingForm.help.residency'),
        placeholder: I18n.t('screens.bookingForm.labels.residency'),
        keyboardType: 'phone-pad',
        hasError: residencyDays > 0 ? false : true
      },
      endDate: {
        label: I18n.t('screens.bookingForm.labels.endDate'),
        help: I18n.t('screens.bookingForm.help.endDate'),
        editable: false
      },
      guests: {
        label: I18n.t('screens.bookingForm.labels.guests'),
        help: I18n.t('screens.bookingForm.help.guests'),
        placeholder: I18n.t('screens.bookingForm.labels.guests'),
        keyboardType: 'phone-pad',
        hasError: guests > 0 ? false : true
      },
      locationId: {
        hidden: true
      },
      _id: {
        hidden: true
      }
    }
    return {fieldsOptionsForm1, fieldsOptionsForm2}
  }

  submitForms = () => {
    const { valueForm1, valueForm2 } = this.state
    const { startDate } = valueForm1
    const { residencyDays, guests, startHour, locationId, _id = null } = valueForm2
    let tmpBooking = { residencyDays, guests, locationId, _id }
    if (this.state.switchStartHour) {
      let tmpStartDate = moment(startDate).format('YYYY-MM-DD')
      let tmpStartHour = moment(startHour).format('HH:mm:ss')
      let tmpDtStart = moment(tmpStartDate + tmpStartHour, 'YYYY-MM-DDTHH:mm:ss')
      tmpBooking.startDate = tmpDtStart.valueOf()
    } else {
      let tmpStartDateTime = moment(startDate).hours(14).minute(0).seconds(0)
      tmpBooking.startDate = tmpStartDateTime.valueOf()
    }
    let tmpEndDate = moment(startDate).add(residencyDays, 'days').hours(12).minute(0).seconds(0)
    tmpBooking.endDate = tmpEndDate.valueOf()
    if (residencyDays > 0 && guests > 0) {
      this.props.onSubmit(tmpBooking)
    }
  }

  onChangeForm1 = (value) => {
    // TODO: (JAIME) if date is equal to now() fix startHour to current hour
    let tmpValueForm2 = objectDeepCopy(this.state.valueForm2)
    let tmpStartDate = moment(value.startDate).hours(14).minute(0).seconds(0).toDate()
    let now = moment().format('YYYY-MM-DD')
    if (now === moment(value.startDate).format('YYYY-MM-DD')) {
      value.startDate = moment().toDate()
      tmpValueForm2.startHour = moment().toDate()
    } else {
      value.startDate = tmpStartDate
      tmpValueForm2.startHour = tmpStartDate
    }
    let tmpEndDate = moment(tmpStartDate).add(this.state.valueForm2.residencyDays, 'days').hours(12).minute(0).seconds(0).format('DD-MM-YYYY')
    tmpValueForm2.endDate = tmpEndDate
    this.setState({ 
      valueForm1: value,
      valueForm2: tmpValueForm2
    })
  }

  onChangeForm2 = (value) => {
    let tmpValueForm1 = objectDeepCopy(this.state.valueForm1)
    if (this.state.switchStartHour) {
      let tmpDateStart = moment(this.state.valueForm1.startDate).format('YYYY-MM-DD')
      let tmpStartHour = moment(value.startHour).format('HH:mm:ss')
      let tmpDtStart = moment(tmpDateStart + tmpStartHour, 'YYYY-MM-DDTHH:mm:ss').toDate()
      tmpValueForm1.startDate = tmpDtStart
    }
    let tmpEndDate = moment(this.state.valueForm1.startDate).add(value.residencyDays, 'days').hours(12).minute(0).seconds(0)
    value.endDate = tmpEndDate.format('DD-MM-YYYY')
    this.setState({
      valueForm1: tmpValueForm1,
      valueForm2: value
    })
  }

  onChangeSwitchStartHour = (value) => {
    this.setState({ 
      switchStartHour: value,
    })
  }

  renderExtraContent = (fields, fieldsOptions) => {
    return (
      <>
        <BaseSwitch
          help={I18n.t('screens.bookingForm.help.switchStartHour')}
          value={this.state.switchStartHour}
          disabled={false}
          onChange={(value) => { this.onChangeSwitchStartHour(value) }}
          customSwitchRowStyle={styles.switchRow}
        />
        {
          <div style={styles.complementaryForm}>
            <BaseForm
              name={'bookingForm2'}
              onSubmit={() => {}}
              fields={fields}
              fieldsOptions={fieldsOptions}
              onChange={this.onChangeForm2}
              value={this.state.valueForm2}
              fetching={this.props.fetching}
              withoutSubmit={true}
            />
          </div>
        }
      </>
    )
  }

  render = () => {
    const { fieldForm1, fieldForm2 } = this.getFields()
    const { fieldsOptionsForm1, fieldsOptionsForm2 } = this.getFieldsOptions()
    return (
      <>
        <FormCard
          name={'bookingForm1'}
          title={this.props.booking ? I18n.t('screens.bookingForm.titleEdit') : I18n.t('screens.bookingForm.title')}
          onSubmit={this.submitForms}
          onChange={this.onChangeForm1}
          deleteButton={this.props.booking ? true : false}
          onDelete={() => { this.props.onDelete(this.props.booking) }}
          fields={fieldForm1}
          fieldsOptions={fieldsOptionsForm1}
          value={this.state.valueForm1}
          fetching={this.props.fetching}
          renderExtraContentBeforeButtons={() => this.renderExtraContent(fieldForm2, fieldsOptionsForm2)}
        />
        <FullScreenLoader visible={this.props.fetching} />
      </>
    )
  }

}
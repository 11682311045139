import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  connectionHasMultiplePhoneDoors,
  getActualConnection,
  getConnectionDoorByPort
} from '../../../Lib/ConnectionsUtils'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import I18n from '../../../i18n/i18n'
import AuthorizedCodeForm from '../../../Components/Screens/Connection/Forms/AuthorizedCodeForm'
import { ModalService } from '../../../Services/Modal'

class AuthorizedCodeFormScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      doorId: null,
      code: null,
      submitButtonDisabled: false
    }
  }

  componentDidMount = () => {
    const { connectionId, doorId, code } = this.props || {}
    const connection = getActualConnection(this.props.connections, connectionId)
    let submitButtonDisabled = false
    if (code) {
      try {
        const { device = {} } = connection
        const { doors = [], trialEnded = false } = device
        const door = doors.filter(d => d._id === doorId)[0]
        const { authorizedPhones = [] } = door
        const phonePosition = authorizedPhones.findIndex(d => d._id === code._id)
        if (trialEnded && phonePosition > 0) { submitButtonDisabled = true }
      } catch {
        submitButtonDisabled = false
      }
    }
    this.setState({ connectionId, doorId, code, submitButtonDisabled })
  }

  saveCode = (code) => {
    const {
      doorPort, ...newCode
    } = code
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    const { device } = connection
    const { _id: deviceId } = device
    const door = getConnectionDoorByPort(connection, doorPort)
    const { _id: doorId } = door
    this.props.saveCode(this.state.connectionId, { ...newCode, doorId, deviceId })
  }

  deleteCode = (code) => {
    ModalService.show(
      I18n.t('modals.deleteAuthorizedCode.title'),
      I18n.t('modals.deleteAuthorizedCode.text'),
      () => { this.props.deleteCode(this.state.connectionId, code) },
      I18n.t('modals.deleteAuthorizedCode.buttons.ok'),
      null,
      true
    )
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    const hasMultiplePhoneDoors = connectionHasMultiplePhoneDoors(connection)
    return (
      <AuthorizedCodeForm
        activeLang={this.props.activeLang}
        connection={connection}
        code={this.state.code}
        doorId={this.state.doorId}
        hasMultiplePhoneDoors={hasMultiplePhoneDoors}
        fetching={this.props.fetching}
        submitButtonDisabled={this.state.submitButtonDisabled}
        onSubmit={this.saveCode}
        onDelete={this.deleteCode}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    activeLang: state.i18n.activeLang,
    fetching: state.connections.fetchingConnectionForm,
    error: state.connections.errorConnection
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveCode: (connectionId, code) => dispatch(ConnectionsActions.connectionAuthorizedCodeSaveRequest(connectionId, code)),
    deleteCode: (connectionId, code) => dispatch(ConnectionsActions.connectionAuthorizedCodeDeleteRequest(connectionId, code))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedCodeFormScreen)

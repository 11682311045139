import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Colors, Fonts, Metrics } from '../../../Themes'
import BaseButton from './BaseButton'

export default class LineItemEditButton extends Component {

  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    buttonStyle: PropTypes.object,
    customButtonStyle: PropTypes.object
  }

  static defaultProps = {
    buttonText: null,
    onPress: null,
    disabled: null,
    buttonStyle: { padding: Metrics.smallMargin, borderColor: Colors.theme1, fontWeight: Fonts.weight.regular, maxWidth: '96px' },
    customButtonStyle: {}
  }

  render = () => {
    return (
      <BaseButton
        variant={'outlined'}
        buttonText={this.props.buttonText}
        onPress={this.props.onPress}
        disabled={this.props.disabled}
        buttonStyle={Object.assign({}, this.props.buttonStyle, this.props.customButtonStyle)}
      />
    )
  }

}

import { Colors, Fonts, Metrics } from '../../../../Themes/'

export default {
  topTextContainer: {
    justifyContent: 'center', 
    alignItems: 'center', 
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.tripleBaseMargin
  },
  topDescriptionText: {
    color: Colors.theme1,
    fontSize: Fonts.size.small,
    textAlign: 'center'
  },
  topPhoneText: {
    color: Colors.theme1,
    marginTop: Metrics.baseMargin,
    fontSize: Fonts.size.title,
    textAlign: 'center',
    fontFamily: Fonts.type.bold
    // fontWeight: Fonts.weight.bold
  },
  topButton: { 
    margin: 0,
    marginRight: Metrics.doubleBaseMargin
  },
  topButtonFixedWidth: {
    width: 120
  },
  topButtonEdit: {
    backgroundColor: Colors.lightYellow,
    borderColor: Colors.darkYellow, 
    shadowColor: Colors.darkYellow
  },
  topButtonEditText: {
    color: Colors.darkYellow
  },
  topButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: Metrics.tripleBaseMargin
  },
  topButtonsContainerOnlyMargin: {
    marginBottom: Metrics.tripleBaseMargin
  },
  switchRow: { 
    justifyContent: 'space-between' 
  },
  switchHelp: { 
    fontFamily: Fonts.type.bold
    // fontWeight: Fonts.weight.bold
  },
  independantListItemTitle: { 
    color: Colors.darkGray, 
    marginTop: Metrics.tripleBaseMargin, 
    marginBottom: Metrics.doubleBaseMargin,
    fontFamily: Fonts.type.bold
    // fontWeight: Fonts.weight.bold
  },
  independantListItemContent: { 
    marginTop: Metrics.tripleBaseMargin, 
    marginBottom: Metrics.doubleBaseMargin
  },
  noVerticalMargin: {
    marginTop: 0,
    marginBottom: 0
  },
  phoneInformationContainer: { 
    marginTop: Metrics.baseMargin, 
    padding: Metrics.doubleBaseMargin + ' ' + Metrics.cuadrupleBaseMargin
  },
  phoneInformationText: {
    textAlign: 'center'
  },
  phoneInformationNameText: {
    marginBottom: Metrics.smallMargin,
    fontFamily: Fonts.type.bold
    // fontWeight: Fonts.weight.bold
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center'
  }
}
export default {
  shared: {
    appName: 'RAIXER',
    homeScreen: 'Home',
    add: 'Agregar',
    active: 'Activo',
    all: 'Todas',
    inactive: 'Inactivo',
    autoAccess: 'Auto Access',
    openClosed: 'Abierto/Cerrado',
    cancel: 'Cancelar',
    delete: 'Eliminar',
    done: 'Listo',
    edit: 'Editar',
    from: 'Desde',
    offline: 'Offline',
    retry: 'Reintentar',
    save: 'Guardar',
    to: 'Hasta',
    updating: 'Actualizando...',
    email: 'Email',
    password: 'Contraseña',
    newPassword: 'Nueva contraseña',
    name: 'Nombre',
    displayName: 'Nombre completo',
    terms: 'Términos y condiciones',
    termsAbbr: 'T & C',
    profile: 'Mi perfil',
    profileLabel: 'Perfil',
    upgrades: 'Upgrades',
    credit: 'Crédito',
    support: 'Soporte',
    logout: 'Salir',
    device: 'Dispositivo',
    devices: 'Dispositivos',
    intercom: 'Intercom',
    power: 'Power',
    settings: 'Ajustes',
    phone: 'Teléfono',
    codes: 'Códigos',
    users: 'Usuarios',
    history: 'Historial',
    days: 'días',
    day: 'día',
    open: 'Abrir',
    close: 'Cerrar',
    opened: 'Abierta',
    closed: 'Cerrada',
    door: 'Puerta',
    doors: 'Puertas',
    piston: 'Pistón',
    on: 'On',
    off: 'Off',
    openings: 'aperturas',
    yes: 'Sí',
    no: 'No',
    configuration: 'Configuración',
    schedule: 'Calendario',
    start: 'Comienzo',
    finish: 'Final',
    owner: 'Dueño',
    user: 'Usuario',
    admin: 'Admin',
    installer: 'Instalador',
    enabled: 'Activo',
    disabled: 'Inactivo',
    me: 'Yo',
    userList: 'Lista de usuarios',
    info: 'Info',
    share: 'Compartir',
    call: 'Llamar',
    campaign: 'Campaña',
    qrValidity: 'Válido por 15 días',
    selectUserType: 'Selecciona el tipo de usuario',
    any: 'Cualquiera',
    country: 'País',
    general: 'General',
    led: 'LED',
    ledBrightness: 'Brillo del LED',
    noSensor: 'Sin sensor',
    sensor: 'Sensor',
    sensors: 'Sensores',
    noise: 'Ruido',
    apiUser: 'Usuario API',
    paid: 'Pagado',
    unpaid: 'Sin pagar',
    euro: '€',
    month: 'Mes',
    year: 'Año',
    privatePhone: 'Teléfono privado',
    intercomPremium: 'Intercom premium',
    guestyIntegration: 'Integración con Guesty',
    payment: 'Pago',
    plan: 'Plan',
    pay: 'Upgrade',
    unsubscribe: 'Eliminar upgrade',
    deviceInformation: 'Información del dispositivo',
    deviceId: 'ID de dispositivo',
    productVersion: 'Versión de dispositivo',
    osVersion: 'Versión del SO del dispositivo',
    firmwareVersion: 'Versión del firmware del dispositivo',
    deviceIdHelp: 'Comparte esto con nuestro Equipo de Soporte en caso de que estés teniendo algún problema con tu dispositivo',
    movement: 'Movimiento',
    proximity: 'Proximidad',
    none: 'Ninguno',
    left: 'Izquierda',
    right: 'Derecha',
    middle: 'Centro',
    invert: 'Invertir',
    variableNotFound: 'No funciona en este momento',
    movementFound: 'Movimiento detectado',
    movementNotFound: 'Ningún movimiento detectado',
    street: 'Calle',
    home: 'Casa',
    garage: 'Garaje',
    other: 'Otro',
    otherUse: 'Otro uso',
    dontUseSensor: 'No utilizar este sensor',
    autoAccessDoorbell: 'Timbre Auto Access',
    openClosedSensor: 'Sensor Abierto/Cerrado',
    up: 'Arriba',
    bottom: 'Abajo',
    bottomAndLeft: 'Abajo e izquierda',
    upAndRight: 'Arriba y derecha',
    autoCalibrate: 'Auto Calibrar',
    calibrate: 'Calibrar',
    advanced: 'Avanzado',
    default: 'Por defecto',
    unitMillivolts: ' milivoltios [mV]',
    unitMillivoltsSimple: ' milivoltios',
    unitSeconds: ' segundos [s]',
    unitSecond: ' segundo [s]',
    unitSecondsSimple: ' segundos',
    unitSecondSimple: ' segundo',
    unitOpenings: ' aperturas',
    unitDecibels: ' decibeles [dB]',
    unitMinutes: ' minutos [m]',
    unitHours: ' horas [h]',
    electricity: 'Electricidad',
    sound: 'Sonido',
    back: 'Volver',
    documentNumber: 'Número de documento',
    city: 'Ciudad',
    province: 'Provincia',
    zipCode: 'Código postal',
    emailBilling: 'Email para facturación',
    progress: 'Progreso',
    public: 'Pública',
    private: 'Privada',
    showPassword: 'Mostrar contraseña',
    remainAs: 'Permanecer como',
    notUpgraded: 'Sin upgrade',
    upgrade: 'Upgrade',
    myCredit: 'Mi crédito',
    transactions: 'Movimientos',
    addCredit: 'Agregar crédito',
    noInfo: 'Sin información',
    creditAdded: 'Crédito agregado',
    connection: 'Conexión',
    minutes: 'Minutos',
    accumulated: 'Acumulado',
    enable: 'Activar',
    integrations: 'Integraciones',
    integrated: 'Integrado',
    notIntegrated: 'Sin integrar',
    integrate: 'Integrar',
    valid: 'Válido',
    pair: 'Vincular',
    pairedWith: 'Vinculado con',
    paired: 'Vinculado',
    notPaired: 'Sin vincular',
    replacePair: 'Reemplazar',
    unauthorizedDevice: 'Dispositivo de otro usuario',
    wentOffline: 'Tu dispositivo se ha desconectado aprox. el:',
    updateAvailable: 'Actualización disponible',
    upToDate: 'Actualizado',
    weekdays: 'L,M,M,J,V,S,D',
    hours: 'Horas',
    address: 'Dirección',
    location: 'Alojamiento',
    booking: 'Reservas',
    guests: 'Huéspedes',
    night: 'Noche',
    nights: 'Noches',
    sequenceNumber: 'Número',
    checkin: 'Checkin',
    checkinIntegration: 'Integración checkin',
    phoneCallAction: 'Llamar a este número abrirá la puerta',
    phoneCallActionCallBell: 'Llamar a este número abrirá la puerta si el timbre del telefonillo acaba de ser llamado',
    nuki: 'Nuki',
    deleteAndUnpair: 'Desvincular',
    key: 'Llave',
    exhibitor: 'Expositor',
    extras: 'Extras',
    pushButton: 'Tap',
    energySaveMode: 'Ahorro Energía',
    test: 'Probar',
    facebook: 'Facebook',
    buy: 'Comprar',
    networkName: 'Nombre de la red',
    store: 'Tienda',
    in: 'En',
    today: 'Hoy',
    remaining: 'Restantes',
    expiresToday: 'Termina hoy',
    restOfDay: 'Lo que queda del día',
    paymentFailing: 'Tu tarjeta fue rechazada, actualízala.',
    retryPayment: 'Reintentar',
    exit: 'Salir',
    checkinGuide: 'Guía registro de viajeros',
    numberOfSublocations: 'Reservas admitidas',
    numberOfSublocationsHelp: 'Número de reservas permitidas al mismo tiempo. Por ejemplo:\n- En un hotel de 7 habitaciones este número sería 7, porque las habitaciones se reservan independientemente.\n- En un apartamento turístico de 3 habitaciones este número sería 1. Las habitaciones no se reservan por separado.',
    trialDaysRemaining: 'Número de días de prueba',
    notifications: 'Notificaciones',
    pushNotifications: 'Notificaciones push',
    now: 'Ahora',
    unauthorized: 'Sin autorización',
    visitCode: 'Abrir link',
    getQR: 'Bajar QR',
    codeCallAction: 'Enlace para abrir la puerta',
    openCode: 'Abrir con código',
    copy: 'Copiar',
    availableOnPhone: 'También disponible en tu móvil',
    checkinConditions: 'Condiciones',
    generalSettings: 'Ajustes Generales'
  },
  snacks: {
    errors: {
      asyncStorage: 'Error inesperado al acceder el almacenamiento de tu navegador',
      login: 'Email o contraseña incorrectos',
      recover: 'No existe un usuario asociado a este email',
      sessionExpired: 'Tu sesión ha expirado. Por favor, haz login de nuevo.',
      startupConfiguration: 'Error inesperado. Por favor, haz login de nuevo.',
      register: 'Ya existe una cuenta asociada a este email',
      facebook: 'Error inesperado al hacer login con Facebook. Por favor, vuelve a intentarlo',
      connectionsListStartTask: 'Error inesperado iniciando Auto Access',
      connectionsListStopTask: 'Error inesperado deteniendo Auto Access',
      connectionsDetailStartTask: 'Error inesperado iniciando Auto Access',
      connectionsDetailStopTask: 'Error inesperado deteniendo Auto Access',
      connectionsDetailActivatePistonTask: 'Error inesperado activando Pistón',
      connectionsDetailDeactivatePistonTask: 'Error inesperado desactivando Pistón',
      connectionsDetailOpenDoorTask: 'Error inesperado abriendo puerta',
      connectionsDetailGyroOpenDoorTaskStarted: 'Error inesperado abriendo Gyro',
      connectionsDetailGyroCloseDoorTaskStarted: 'Error inesperado cerrando Gyro',
      connectionsDetailNukiOpenDoorTaskStarted: 'Error inesperado abriendo la Nuki',
      connectionsDetailNukiActivatePistonTaskStarted: 'Error inesperado cerrando la Nuki',
      connectionsDetailNukiDeactivatePistonTaskStarted: 'Error inesperado desbloqueando la Nuki',
      connectionsDetailNukiOpenDoorTaskFinished: 'La Nuki no pudo abrir',
      connectionsDetailNukiActivatePistonTaskFinished: 'La Nuki no pudo cerrar',
      connectionsDetailNukiDeactivatePistonTaskFinished: 'La Nuki no pudo desbloquear',
      connectionsDetailGetHistory: 'Error inesperado obteniendo el historial del dispositivo. Por favor, intenta de nuevo',
      connectionsDetailGetVitals: 'Error inesperado obteniendo la señal del dispositivo. Por favor, intenta de nuevo',
      connectionsDetailSaveSchedule: 'Error inesperado guardando la conexión programada. Por favor, intenta de nuevo',
      connectionsDetailDeleteSchedule: 'Error inesperado eliminando la conexión programada. Por favor, intenta de nuevo',
      connectionsDetailSaveUser: 'Error inesperado guardando el usuario. Por favor, intenta de nuevo',
      connectionsDetailDeleteUser: 'Error inesperado eliminando el usuario. Por favor, intenta de nuevo',
      // connectionsDetailEnableUser: 'Error inesperado activando el usuario. Por favor, intenta de nuevo',
      // connectionsDetailDisableUser: 'Error inesperado desactivando el usuario. Por favor, intenta de nuevo',
      connectionsDetailSaveAuthorizedPhone: 'Error inesperado guardando el teléfono autorizado. Por favor, intenta de nuevo',
      connectionsDetailDeleteAuthorizedPhone: 'Error inesperado eliminando el teléfono autorizado. Por favor, intenta de nuevo',
      connectionsDetailCallError: 'Lo sentimos, tu dispositivo no soporta esta funcionalidad',
      connectionsDetailEnableDoorPhone: 'Error inesperado activando el acceso telefónico para la puerta. Por favor, intenta de nuevo',
      connectionsDetailDisableDoorPhone: 'Error inesperado desactivando el acceso telefónico para la puerta. Por favor, intenta de nuevo',
      connectionsDetailAutoAccessConfiguration: 'Error inesperado guardando la configuración de Auto Access',
      connectionsDetailGetDoorPhone: 'Error inesperado colocando el teléfono de la puerta. Por favor, intenta de nuevo',
      connectionsDetailGetDoorPhoneAddAuthorizedPhone: 'El teléfono de la puerta se ha colocado pero no se pudo guardar el nuevo teléfono autorizado. Por favor, intenta de nuevo',
      connectionsDetailDeleteDoorPhone: 'Error inesperado eliminando el teléfono de la puerta. Por favor, intenta de nuevo',
      connectionsDetailAnyCallerDoorPhone: 'Error inesperado. Por favor, intenta de nuevo',
      // connectionsDetailSettingsDelete: 'Error inesperado eliminando tu dispositivo. Por favor, intenta de nuevo',
      getUser: 'Error inesperado obteniendo los detalles de tu cuenta. Por favor, vuelve atrás e intenta de nuevo',
      updateUser: 'Error inesperado guardando tu información. Por favor, intenta de nuevo',
      // getStripeCustomer: 'Error inesperado obteniendo tus detalles de pago. Por favor, vuelve atrás e intenta de nuevo',
      // addStripeCard: 'Error inesperado guardando tu tarjeta de crédito. Por favor, intenta de nuevo',
      // updateStripeCard: 'Error inesperado actualizando tu tarjeta de crédito. Por favor, intenta de nuevo',
      // deleteStripeCard: 'Error inesperado eliminando tu tarjeta de crédito. Por favor, intenta de nuevo',
      // addStripeSubscription: 'Error inesperado haciendo upgrade de tu dispositivo. Por favor, intenta de nuevo',
      // updateStripeSubscription: 'Error inesperado actualizando el upgrade de tu dispositivo. Por favor, intenta de nuevo',
      // deleteStripeSubscription: 'Error inesperado eliminando el upgrade de tu dispositivo. Por favor, intenta de nuevo',
      // addStripePhoneSubscription: 'Error inesperado haciendo upgrade de tu teléfono. Por favor, intenta de nuevo',
      // updateStripePhoneSubscription: 'Error inesperado actualizando el upgrade de tu teléfono. Por favor, intenta de nuevo',
      // deleteStripePhoneSubscription: 'Error inesperado eliminando el upgrade de tu teléfono. Por favor, intenta de nuevo',
      // connectionsDetailSettingsGeneralSave: 'Error inesperado guardando la configuración general de tu dispositivo. Por favor, intenta de nuevo',
      // connectionsDetailSettingsGeneralPowerSaveTest: 'Error inesperado durmiendo tu dispositivo. Por favor, intenta de nuevo',
      // connectionsDetailSettingsSensorSave: 'Error inesperado guardando el sensor. Por favor, intenta de nuevo',
      // connectionsDetailSettingsSensorDelete: 'Error inesperado eliminando el sensor. Por favor, intenta de nuevo',
      // connectionsDetailSettingsDoorSave: 'Error inesperado guardando la puerta. Por favor, intenta de nuevo',
      // connectionsDetailSettingsDoorDelete: 'Error inesperado eliminando la puerta. Por favor, intenta de nuevo',
      // wizardScanQRCodeNotValid: 'Lo sentimos, no hemos reconocido un código QR válido de Raixer. Por favor, intenta de nuevo',
      // wizardScanQR: 'Error inesperado agregando el dispositivo a tu cuenta. Por favor, intenta de nuevo',
      // wizardScanQRExistingConnection: 'Lo sentimos, ya tienes una conexión a este dispositivo.',
      // wizardScanQRCodeNotFound: 'Lo sentimos, el código QR que has escaneado no lo hemos encontrado.',
      // wizardScanQRCodeInvalid: 'Lo sentimos, el código QR que has escaneado ya no es válido o ya ha sido usado.',
      // wizardScanQRDeviceOffline: 'El dispositivo no está encendido o no tiene conexión a internet. Por favor, enciende tu dispositivo y asegúrate de que la luz esté titilando en turquesa antes de volver a intentar.',
      // wizardAddDeviceConnections: 'El dispositivo se ha conectado a internet y se ha agregado a tu cuenta, si aplica, pero no podemos obtener tus dipositivos justo ahora. Por favor, intenta recargar tus dispositivos de nuevo.',
      // wizardAddDeviceWifiUnexpected: 'Error inesperado. Reinicia manualmente el dispositivo e intenta de nuevo',
      // wizardAddDeviceNoiseSensorDeviceInUse: 'El dispositivo que estás intentando agregar como un sensor de ruido ya está en uso y no puedes agregarlo a tu dispositivo.',
      // osCantOpenSettings: 'Lo sentimos, no podemos abrir la configuaración de tu móvil. Por favor, ve allí manualmente.',
      connectionsDetailUnblockOpenClosed: 'Error inesperado habilitando el sensor de abierto/cerrado',
      connectionsDetailUnblockAutoAccess: 'Error inesperado habilitando el modo Auto Access',
      connectionsDetailUpdateDeviceFirmware: 'Error inesperado iniciando la actualización de tu dispositivo',
      // platformIntegrationRequest: 'Error inesperado enviando el correo. Por favor, intenta de nuevo',
      // platformIntegrationSave: 'Error inesperado guardando tu integración',
      // platformIntegrationSaveGuestyUnauthorized: 'Lo sentimos, el API Token de Guesty que has introducido no es válida',
      // guestyUnexpected: 'Error inesperado obteniendo tu integración con Guesty',
      // guestyListings: 'Error inesperado obteniendo tus anuncios en Guesty. Por favor, intenta de nuevo',
      // pairGuestyListing: 'Error inesperado asociando tu anuncio. Por favor, intenta de nuevo',
      // deleteGuestyListingPair: 'Error inesperado desasociando tu anuncio. Por favor, intenta de nuevo',
      connectionLocationSave: 'Error inesperado guardando dirección. Por favor, intenta de nuevo',
      connectionLocationDelete: 'Error inesperado eliminando dirección. Por favor, intenta de nuevo',
      connectionBookingSave: 'Error inesperado guardando reserva. Por favor, intenta de nuevo',
      connectionBookingDelete: 'Error inesperado eliminando reserva. Por favor, intenta de nuevo',
      connectionBookingOverlapping: 'La fecha de entrada o salida coinciden con una reserva existente. Por favor, corrige los datos e intenta de nuevo',
      getGuestBook: 'Error inesperado cargando el libro de visitas. Por favor, intenta de nuevo',
      sendGuestBook: 'Error inesperado enviando el libro de visitas. Por favor, intenta de nuevo',
      // getBookings: 'Error inesperado obteniendo las reservas. Por favor, intenta de nuevo',
      // addCheckinSubscription: 'Error inesperado haciendo upgrade de tu dispositivo. Por favor, intenta de nuevo',
      // updateCheckinSubscription: 'Error inesperado actualizando el upgrade de tu dispositivo. Por favor, intenta de nuevo',
      // deleteCheckinSubscription: 'Error inesperado eliminando el upgrade de tu dispositivo. Por favor, intenta de nuevo',
      // connectionPairLocation: 'Error inesperado vinculando tu dispositivo. Por favor, intenta de nuevo',
      // retryPayment: 'Tu tarjeta ha sido rechazada. Por favor, actualízala e intenta de nuevo',
      // connectionSaveDeviceConfigurationWizard: 'Error inesperado guardando tu elección sobre el proceso de configuración de dispositivo. Por favor, intenta de nuevo',
      connectionsDetailGetDoorCode: 'Error inesperado colocando el código de la puerta. Por favor, intenta de nuevo',
      connectionsDetailSaveAuthorizedCode: 'Error inesperado guardando el código autorizado. Por favor, intenta de nuevo',
      connectionsDetailUpdateAuthorizedCode: 'Error inesperado actualizando el código autorizaado. Por favor, intenta de nuevo',
      connectionsDetailDeleteAuthorizedCode: 'Error inesperado eliminando el código autorizado. Por favor, intenta de nuevo',
      connectionsDetailEnableDoorCode: 'Error inesperado activando el acceso por código para la puerta. Por favor, intenta de nuevo',
      connectionsDetailDisableDoorCode: 'Error inesperado desactivando el acceso por URL para la puerta. Por favor, intenta de nuevo',
      connectionsDetailDeleteDoorCode: 'Error inesperado eliminando el link de la puerta. Por favor, intenta de nuevo'
    },
    success: {
      register: '¡Genial! Ahora por favor revisa tu buzón de correo para confirmar tu nueva cuenta',
      recover: '¡Genial! Revisa tu buzón de correo para confirmar tu nueva contraseña',
      connectionsListStartTask: 'Auto Access iniciado correctamente',
      connectionsListStopTask: 'Auto Access detenido correctamente',
      connectionsDetailStartTask: 'Auto Access iniciado correctamente',
      connectionsDetailStopTask: 'Auto Access detenido correctamente',
      connectionsDetailActivatePistonTask: 'Pistón activado correctamente',
      connectionsDetailDeactivatePistonTask: 'Pistón desactivado correctamente',
      connectionsDetailOpenDoorTask: 'Puerta abierta correctamente',
      connectionsDetailGyroOpenDoorTaskStarted: 'Abriendo Gyro...',
      connectionsDetailGyroCloseDoorTaskStarted: 'Cerrando Gyro...',
      connectionsDetailNukiOpenDoorTaskStarted: 'Abriendo la Nuki...',
      connectionsDetailNukiActivatePistonTaskStarted: 'Cerrando la Nuki...',
      connectionsDetailNukiDeactivatePistonTaskStarted: 'Desbloqueando Nuki...',
      connectionsDetailNukiOpenDoorTaskFinished: 'Nuki abierta correctamente',
      connectionsDetailNukiActivatePistonTaskFinished: 'Nuki cerrada correctamente',
      connectionsDetailNukiDeactivatePistonTaskFinished: 'Nuki desbloqueada correctamente',
      connectionsDetailSaveSchedule: 'Conexión programada guardada correctamente',
      connectionsDetailDeleteSchedule: 'Conexión programada eliminada correctamente',
      connectionsDetailSaveUser: 'Usuario guardado correctamente',
      connectionsDetailDeleteUser: 'Usuario eliminado correctamente',
      // connectionsDetailEnableUser: 'Usuario activado correctamente',
      // connectionsDetailDisableUser: 'Usuario desactivado correctamente',
      connectionsDetailSaveAuthorizedPhone: 'Teléfono autorizado guardado correctamente',
      connectionsDetailDeleteAuthorizedPhone: 'Teléfono autorizado eliminado correctamente',
      connectionsDetailEnableDoorPhone: 'Acceso telefónico para la puerta activado correctamente',
      connectionsDetailDisableDoorPhone: 'Acceso telefónico para la puerta desactivado correctamente',
      connectionsDetailAutoAccessConfiguration: 'Configuración de Auto Access guardada correctamente',
      connectionsDetailGetDoorPhone: 'El teléfono de la puerta se ha colocado exitosamente',
      connectionsDetailGetDoorPhoneAddAuthorizedPhone: 'El teléfono de la puerta se ha colocado exitosamente y el nuevo teléfono autorizado se ha guardado',
      connectionsDetailDeleteDoorPhone: 'El teléfono de la puerta se ha eliminado correctamente',
      // connectionsDetailSettingsDelete: 'Tu dispositivo se ha eliminado correctamente',
      updateUser: 'Tu información se ha guardado correctamente',
      // addStripeCard: 'Tu tarjeta de crédito se ha guardado correctamente',
      // updateStripeCard: 'Tu tarjeta de crédito se ha actualizado correctamente',
      // deleteStripeCard: 'Tu tarjeta de crédito se ha eliminado correctamente',
      // addStripeSubscription: 'El upgrade de tu dispositivo se ha guardado correctamente',
      // updateStripeSubscription: 'El upgrade de tu dispositivo se ha actualizado correctamente',
      // deleteStripeSubscription: 'El upgrade de tu dispositivo se ha eliminado correctamente',
      // addStripePhoneSubscription: 'El upgrade de tu teléfono se ha guardado correctamente',
      // updateStripePhoneSubscription: 'El upgrade de tu teléfono se ha actualizado correctamente',
      // deleteStripePhoneSubscription: 'El upgrade de tu teléfono se ha eliminado correctamente',
      // connectionsDetailSettingsGeneralSave: 'La configuración general de tu dispositivo se ha guardado correctamente',
      // connectionsDetailSettingsGeneralPowerSaveTest: 'Tu dispositivo se ha dormido correctamente',
      // connectionsDetailSettingsSensorSave: 'Sensor guardado correctamente',
      // connectionsDetailSettingsSensorDelete: 'Sensor eliminado correctamente',
      // connectionsDetailSettingsDoorSave: 'Puerta guardada correctamente',
      // connectionsDetailSettingsDoorDelete: 'Puerta eliminada correctamente',
      // wizardAddDeviceAdded: '¡Genial! El dispositivo se ha agregado a tu cuenta',
      // wizardAddDeviceUpdatedCellular: '¡Genial! Tu dispositivo ya era de tu propiedad o de otro usuario por lo que no lo hemos actualizado.',
      // wizardAddDeviceUpdatedWifi: '¡Genial! Tu dispositivo ya era de tu propiedad o de otro usuario por lo que solo hemos actualizado su red WiFi.',
      // wizardAddDeviceAddedNoiseSensor: '¡Genial! Tu nuevo sensor de ruido ha sido agregado a tu dispositivo.',
      connectionsDetailUnblockOpenClosed: 'Sensor de abierto/cerrado habilitado correctamente',
      connectionsDetailUnblockAutoAccess: 'Modo Auto Access habilitado correctamente',
      connectionsDetailUpdateDeviceFirmware: 'Actualización de tu dispositivo iniciada correctamente',
      // platformIntegrationRequest: '¡Genial! Correo enviado correctamente',
      // platformIntegrationSave: '¡Genial! Tu integración se ha guardado correctamente',
      // pairGuestyListing: '¡Genial! Tu anuncio se ha asociado correctamente',
      // deleteGuestyListingPair: '¡Genial! Tu anuncio se ha desasociado correctamente',
      connectionLocationSave: '¡Genial! Tu dirección se ha guardado correctamente.',
      connectionLocationDelete: 'Dirección eliminada correctamente.',
      connectionBookingSave: '¡Genial! Tu reserva se ha guardado correctamente.',
      connectionbookingDelete: 'Reserva eliminada correctamente.',
      sendGuestBook: '¡Genial! Tu libro de visitas se ha enviado correctamente',
      // addCheckinSubscription: 'El upgrade de tu dispositivo se ha guardado correctamente',
      // updateCheckinSubscription: 'El upgrade de tu dispositivo se ha actualizado correctamente',
      // deleteCheckinSubscription: 'El upgrade de tu dispositivo se ha eliminado correctamente',
      // connectionPairLocation: 'Tu dispositivo se ha vinculado correctamente',
      // retryPayment: '¡Genial! Tu pago se ha realizado correctamente',
      connectionsDetailSaveAuthorizedCode: 'Código autorizado guardado correctamente',
      connectionsDetailUpdateAuthorizedCode: 'Código autorizado actualizado correctamente',
      connectionsDetailDeleteAuthorizedCode: 'Código autorizado eliminado correctamente',
      connectionsDetailEnableDoorCode: 'Acceso por código para la puerta activado correctamente',
      connectionsDetailDisableDoorCode: 'Acceso por URL para la puerta desactivado correctamente',
      connectionsDetailGetDoorCode: 'El código de la puerta se ha colocado exitosamente',
      connectionsDetailDeleteDoorCode: 'El link de la puerta se ha eliminado correctamente',
      connectionsDetailCopyDoorCode: 'Link copiado'
    }
  },
  modals: {
    deviceOfflineIntercom: {
      title: 'Dispositivo apagado o sin internet',
      text: 'Por favor, asegúrate de lo siguiente:\n\n1. Tu dispositivo está encendido.\n2. El WiFi está funcionando correctamente.\n3. El nombre y contraseña de la red WiFi no han cambiado.',
      buttons: {
        ok: 'Reconfigurar WiFi'
      },
    },
    deviceOfflineAccess: {
      title: 'Dispositivo apagado o sin internet',
      text: 'Por favor, asegúrate de lo siguiente:\n\n1. Tu dispositivo está encendido.\n2. Hay cobertura móvil en el lugar del dispositivo.'
    },
    offlineAutoAccess: {
      title: 'Modo de Contingencia',
      textFirst: 'El Auto Access estará activo solo si tu dispositivo está alimentado.\n\nRecuerda que el Auto Access se desactivará después de abrir ',
      textSecond: ' veces.\n\nPuedes desactivar esto cuando quieras.'
    },
    upgradeFeatureOwner: {
      title: 'Upgrade requerido',
      text: 'Tu dispositivo está en versión gratuita. No puedes utilizar esta funcionalidad hasta que hagas Upgrade.',
      buttons: {
        ok: 'Upgrade'
      }
    },
    upgradePhoneFeatureOwner: {
      title: 'Upgrade requerido',
      text: 'Tu dispositivo está en la versión de prueba o versión gratuita. No puedes contratar esta funcionalidad hasta que hagas Upgrade.',
      buttons: {
        ok: 'Upgrade'
      }
    },
    upgradeNotOwner: {
      title: 'Upgrade requerido',
      text: 'Tu dispositivo está en versión gratuita. No puedes utilizar esta funcionalidad hasta que el dueño haga upgrade. Puedes ver quién es el dueño en la pestaña Usuarios.'
    },
    upgradePhoneFeatureNotOwner: {
      title: 'Upgrade requerido',
      text: 'Tu dispositivo está en la versión de prueba o versión gratuita. No puedes contratar esta funcionalidad hasta que se haya hecho Upgrade.\n\nSólo el dueño del dispositivo puede hacerlo, puedes ver quién es el dueño en la pestaña Usuarios.'
    },
    upgradeFeatureExhibitor: {
      title: 'Expositor',
      text: 'Tu dispositivo es un expositor y no es elegible para esta funcionalidad.'
    },
    unlockFeatures: {
      title: 'Desbloquea funcionalidades',
      text: 'Haz Upgrade de tu dispositivo para desbloquear todas estas funcionalidades:\n\nAuto Access programado\nAuto Access ilimitado\nModo de contingencia\nHasta 10 números de teléfono autorizados\nNúmeros de teléfono autorizados por horas o días\nUsuarios de app ilimitados\nHistorial de uso',
      buttons: {
        ok: 'Upgrade'
      }
    },
    unlockFeaturesNotOwner: {
      title: 'Desbloquea funcionalidades',
      text: 'Dile al dueño del dispositivo que le haga Upgrade para desbloquear todas estas funcionalidades:\n\nAuto Access programado\nAuto Access ilimitado\nModo de contingencia\nHasta 10 números de teléfono autorizados\nNúmeros de teléfono autorizados por horas o días\nUsuarios de app ilimitados\nHistorial de uso\n\nPuedes ver quién es el dueño en la pestaña Usuarios.'
    },
    cantUse: {
      title: 'Upgrade requerido',
      text: 'Tu período de prueba para este dispositivo ha terminado. Haz upgrade del dispositivo para seguir utilizándolo y desbloquear todas estas funcionalidades:\n\nAuto Access programado\nAuto Access ilimitado\nModo de contingencia\nHasta 10 números de teléfono autorizados\nNúmeros de teléfono autorizados por horas o días\nUsuarios de app ilimitados\nHistorial de uso',
      buttons: {
        ok: 'Upgrade'
      }
    },
    trialRunning: {
      title: 'Período de prueba',
      text: 'Tu dispositivo está en período de prueba. Puedes probar todas las funcionalidades sin límite.\n\nCuando el período de prueba acabe, tendrás que hacer upgrade de tu dispositivo para seguir utilizándolo.',
      textFreeVersion: 'Tu dispositivo está en período de prueba. Puedes probar todas las funcionalidades sin límite.\n\nCuando el período de prueba acabe, tu dispositivo pasará a la versión gratuita.\n\nSi quieres disfrutar de todas las funcionalidades sin límite, puedes hacer Upgrade.',
      buttons: {
        ok: 'Upgrade'
      }
    },
    trialRunningNotOwner: {
      title: 'Período de prueba',
      text: 'Tu dispositivo está en período de prueba. Puedes probar todas las funcionalidades sin límite.\n\nCuando el período de prueba acabe, el dueño de tu dispositivo tendrá que hacer upgrade para que puedas seguir utilizándolo. Puedes ver quién es el dueño en la pestaña Usuarios.',
      textFreeVersion: 'Tu dispositivo está en período de prueba. Puedes probar todas las funcionalidades sin límite.\n\nCuando el período de prueba acabe, tu dispositivo pasará a la versión gratuita.\n\nSi quieres disfrutar de todas las funcionalidades sin límite, puedes decirle al dueño del dispositivo que haga Upgrade. Puedes ver quién es el dueño en la pestaña Usuarios.'
    },
    deviceVitals: {
      title: 'Señal WiFi',
      titleAlt: 'Señal Móvil',
      textFirst: 'Calidad: ',
      textSecond: 'Fuerza: ',
      buttons: {
        ok: 'Recargar',
      }
    },
    deviceVitalsBattery: {
      title: 'Batería',
      textFirst: 'Carga de batería: ',
      textSecond: 'Estado de batería: ',
      buttons: {
        ok: 'Recargar',
      }
    },
    deviceMiniGyroVitalsBattery: {
      title: 'Gyro Battery',
      textFirst: 'Battery level: ',
      textSecond: 'Battery state: ',
      buttons: {
        cancel: 'Done',
      }
    },
    deleteSchedule: {
      title: 'Eliminar programación',
      text: '¿Estás seguro de que quieres eliminar esta programación?',
      buttons: {
        ok: 'Eliminar'
      }
    },
    deleteUser: {
      title: 'Eliminar usuario',
      text: '¿Estás seguro de que quieres eliminar este usuario? El usuario ya no tendrá acceso a este dispositivo.',
      buttons: {
        ok: 'Eliminar'
      }
    },
    disableUser: {
      title: 'Desactivar usuario',
      text: '¿Estás seguro de que quieres desactivar este usuario? El usuario ya no podrá ver este dispositivo.',
      buttons: {
        ok: 'Desactivar'
      }
    },
    updateUserOwner: {
      title: 'Hacer dueño',
      text: '¿Realmente quieres hacer dueño del dispositivo a este usuario?',
      buttons: {
        ok: 'Sí, hazlo dueño'
      }
    },
    updateUserOwnerConfirm: {
      title: 'Hacer dueño',
      text: '¿Estás seguro de que quieres hacerlo?\n\nPor favor, considera que el dispositivo ya no será tuyo y que todos los datos asociados con él se perderán, incluyendo las suscripciones que tenga.',
      buttons: {
        ok: 'Entiendo, hazlo dueño'
      }
    },
    deleteAuthorizedPhone: {
      title: 'Eliminar teléfono',
      text: '¿Estás seguro de que quieres eliminar este teléfono?',
      buttons: {
        ok: 'Eliminar'
      }
    },
    disableDoorPhoneAccess: {
      title: 'Desactivar acceso telefónico',
      text: '¿Estás seguro que quieres desactivar el acceso telefónico? El dispositivo no abrirá la puerta si alguien llama.',
      buttons: {
        ok: 'Desactivar'
      }
    },
    intercomCallFirst: {
      title: 'Abrir puerta',
      text: 'Por favor, asegúrate de que el timbre del telefonillo acaba de ser llamado antes de abrir la puerta desde aquí.',
      buttons: {
        ok: 'Abrir puerta'
      }
    },
    doorOpenConfirm: {
      title: 'Abrir puerta',
      text: '¿Estás seguro que quieres abrir la puerta',
      buttons: {
        ok: 'Abrir puerta'
      }
    },
    doorCloseConfirm: {
      title: 'Cerrar puerta',
      text: '¿Estás seguro que quieres cerrar la puerta',
      buttons: {
        ok: 'Cerrar puerta'
      }
    },
    autoAccessBlocked: {
      title: 'Auto Access Siempre Activo',
      text: 'El modo "Auto Access Siempre Activo" está encendido, por lo que no puedes desactivar Auto Access desde aquí.\n\nRecuerda, puedes cambiar esto desde la Configuración Auto Access.'
    },
    alwaysActiveTurnedOn: {
      title: 'Siempre Activo',
      text: 'Auto Access siempre estará activo, incluso cuando tu dispositivo no tiene internet, y no tendrá un límite máximo de aperturas.\n\nTen en cuenta que este modo sólo funciona si tu dispositivo está encendido.\n\nNOTA: Esto puede que no les guste a tus vecinos.',
      buttons: {
        cancel: 'Ok'
      }
    },
    contingencyModeTurnedOn: {
      title: 'Modo de Contingencia',
      textFirst: 'El Auto Access estará activo solo si tu dispositivo está alimentado.\n\nRecuerda que el Auto Access se desactivará después de abrir ',
      textSecond: ' veces.\n\nPuedes desactivar esto cuando quieras.',
      buttons: {
        cancel: 'Ok'
      }
    },
    contingencyModeBlocked: {
      title: 'Modo de Contingencia',
      text: 'Tienes encendido "Auto Access Siempre Activo", no necesitas el Modo de Contingencia porque el Auto Access funciona en todo momento, incluso cuando el dispositivo no tiene internet.',
      buttons: {
        cancel: 'Ok'
      }
    },
    autoAccessConfigurationUnaivalable: {
      title: 'Modos no disponibles',
      text: 'Disculpa, los modos Contingencia o Siempre Activo aún no están disponibles para tu dispositivo. En los próximos días tu dispositivo se actualizará automáticamente para obtener estos modos.',
      buttons: {
        cancel: 'Ok'
      }
    },
    authorizedPhoneInAnotherDevice: {
      title: 'Teléfono autorizado en uso',
      text: 'Tu teléfono autorizado ha sido guardado pero, sólo para que lo sepas, lo hemos encontrado en una puerta de alguno de tus otros dispositivos.\n\nPor favor, ten en cuenta que si este teléfono llama abrirá ambas puertas.',
    },
    authorizedPhoneError: {
      title: 'Error guardando teléfono',
      text: 'El teléfono autorizado que has introducido no pudo guardarse porque ha ocurrido un error inesperado o, quizás, porque ya se encuentra en esta puerta.',
      buttons: {
        cancel: 'Cerrar'
      }
    },
    authorizedPhoneErrorDoorPhoneUnavailable: {
      title: 'Error guardando teléfono',
      text: 'Has alcanzado el máximo de números autorizados para un número de teléfono compartido.\n\nNecesitas de un número privado para poder agregar más números autorizados.\n\nLo sentimos, pero no tenemos números de teléfono disponibles. Por favor, contáctanos en hello@raixer.com'
    },
    accessToAnyCallerErrorDoorPhoneUnavailable: {
      title: 'Error acceso a cualquier persona',
      text: 'El número que tenías asignado no puedes utilizarlo para esta funcionalidad porque ya está en uso por otros dispositivos.\n\nNecesitas de un número privado para poder activar acceso a cualquier persona.\n\nLo sentimos, pero no tenemos números de teléfono disponibles. Por favor, contáctanos en hello@raixer.com',
    },
    doorPhoneRemove: {
      title: 'Eliminar teléfono',
      text: '¿De verdad quieres eliminar el teléfono de tu puerta?\n\nNo podrás seguir abriendo la puerta con una llamada perdida y, si quieres volver a activar el teléfono, probablemente no sea el mismo que tienes ahora.',
      buttons: {
        ok: 'Sí, elimínalo'
      }
    },
    doorPhonePossibleAnswers: {
      title: 'Información sobre nuestros teléfonos',
      text: '¡Recuerda! La puerta abrirá aunque la respuesta sea:\n\n1. La llamada es rechazada.\n2. El teléfono no existe.',
      buttons: {
        ok: 'Entendido'
      }
    },
    doorPhoneUseExistingPhone: {
      title: 'Utilizar mismo número',
      text: 'Si agregas el mismo número autorizado en distintas puertas con el mismo número de teléfono, abrirá todas las puertas con una sola llamada.\n\nRecuerda que la puerta abrirá aunque la respuesta sea:\n\n1. La llamada es rechazada.\n2. El teléfono no existe.',
      buttons: {
        ok: 'Entendido'
      }
    },
    deleteConnection: {
      title: 'Eliminar dispositivo',
      text: '¿De verdad deseas eliminar este dispositivo de tu cuenta?',
      buttons: {
        ok: 'Eliminar este dispositivo'
      }
    },
    deleteConnectionOwner: {
      title: 'Eliminar dispositivo',
      text: 'Tu eres el dueño de este dispositivo. Considera que el dispositivo ya no será tuyo y que todos los datos asociados al mismo se perderán.',
      buttons: {
        ok: 'Eliminar este dispositivo'
      }
    },
    deleteConnectionWithUsers: {
      title: 'Eliminar dispositivo',
      textFirst: '\u26D4\u26A0 IMPORTANTE \u26A0\u26D4\n\nSi continúas, las siguientes personas perderán el acceso al dispositivo:',
      textSecond: 'NOTA: Si eres un instalador, por favor, asegúrate que la persona que comprado el dispositivo acepte ser su dueño antes de que tú lo elimines.',
      buttons: {
        ok: 'Entiendo, eliminar este dispositivo'
      }
    },
    deleteConnectionWithQRs: {
      title: 'Eliminar dispositivo',
      text: '\u26D4\u26A0 IMPORTANTE \u26A0\u26D4\n\nTienes Códigos QR activos para agregar usuarios o transferir la propiedad del dispositivo. Si continúas antes que alguien los acepte, toda información asociada al dispositivo se perderá.\n\nNOTA: Si eres un instalador, por favor, asegúrate que la persona que comprado el dispositivo acepte ser su dueño antes de que tú lo elimines.',
      buttons: {
        ok: 'Entiendo, eliminar este dispositivo'
      }
    },
    deleteConnectionCheckin: {
      title: 'Eliminar integración checkin',
      text: '¿De verdad deseas eliminar esta integración checkin de tu cuenta? Considera que todos los datos asociados se perderán.',
      buttons: {
        ok: 'Eliminar integración checkin'
      }
    },
    addDoor: {
      title: 'Agregar puerta a dispositivo',
      text: '¿Estás seguro de que quieres agregar una puerta a este dispositivo? Considera que esta configuración debería hacerla un instalador.',
      buttons: {
        ok: 'Confirmar'
      }
    },
    editDoor: {
      title: 'Editar puerta de dispositivo',
      text: '¿Estás seguro de que quieres editar la configuración de esta puerta? Considera que esto probablemente fue hecho por un instalador.',
      buttons: {
        ok: 'Confirmar'
      }
    },
    addSensor: {
      title: 'Agregar extra a dispositivo',
      text: '¿Estás seguro de que quieres agregar un extra a este dispositivo? Considera que esta configuración debería hacerla un instalador.',
      buttons: {
        ok: 'Confirmar'
      }
    },
    editSensor: {
      title: 'Editar extra',
      text: '¿Estás seguro de que quieres editar la configuración de este extra? Considera que esto probablemente fue hecho por un instalador.',
      buttons: {
        ok: 'Confirmar'
      }
    },
    deleteCreditCard: {
      title: 'Eliminar tarjeta',
      text: '¿De verdad quieres eliminar tu tarjeta? Podrás volver a agregarla en cualquier momento.',
      buttons: {
        ok: 'Eliminar'
      }
    },
    addSubscription: {
      title: 'Upgrade realizado',
      text: '¡Genial! Has hecho upgrade de tu dispositivo. Mañana se te cobrará el plan seleccionado.',
      textReactivation: '¡Genial! El upgrade de tu dispositivo se ha reactivado.'
    },
    deleteSubscription: {
      title: 'Upgrade eliminado',
      withoutDate: {
        text: 'Se ha eliminado el upgrade de tu dispositivo. No podrás utilizarlo hasta que vuelvas a hacer upgrade.'
      },
      withDate: {
        textFirst: 'Se ha eliminado el upgrade de tu dispositivo. Podrás seguir utilizando tu dispositivo hasta ',
        textSecond: '. Después de esta fecha, no podrás utilizar tu dispositivo hasta que vuelvas a hacer upgrade del mismo.'
      }
    },
    dimmerUnaivalable: {
      title: 'Configuración no disponible',
      text: 'Lo sentimos, pero el ajuste el brillo de la luz LED de tu dispositivo no está aún disponible para tu dispositivo. En los próximos días tu dipositivo se actualizará automáticamente y podrás ajustar el brillo de la luz.',
      buttons: {
        cancel: 'Entendido'
      }
    },
    sensorMissingPort: {
      title: 'Puerto faltante',
      text: 'Por favor, selecciona un puerto para el sensor antes intentar esto'
    },
    deleteSensor: {
      title: 'Eliminar extra',
      text: '¿Estás seguro de que quieres eliminar este extra?',
      buttons: {
        ok: 'Eliminar'
      }
    },
    invertedMovementSensor: {
      title: 'Sensor invertido',
      text: 'Con esta configuración para tu sensor, no vas a saber si el cable del sensor ha sido cortado por alguien.',
      buttons: {
        ok: 'Entendido, guardar'
      }
    },
    deleteDoor: {
      title: 'Eliminar puerta',
      text: '¿Estás seguro de que quieres eliminar esta puerta?',
      buttons: {
        ok: 'Eliminar'
      }
    },
    deleteNukiDoor: {
      title: 'Desvincular Nuki',
      text: '¿Estás seguro de que quieres desvincular tu Nuki de tu dispositivo Raixer?',
      buttons: {
        ok: 'Desvincular'
      }
    },
    goToDoorAdvanced: {
      title: 'Avanzada',
      text: 'Aquí puedes configurar manualmente los valores de la puerta. Puedes configurar esto automáticamente presionando el botón "Auto Calibrar".',
      buttons: {
        ok: 'Entiendo, ir a Avanzada'
      }
    },
    pickUpFirstModeBlocked: {
      title: 'Descolgar para abrir',
      text: 'Lo sentimos, pero no puedes encender esto ahora porque tu dispositivo tiene más de una puerta configurada.\n\nSi realmente necesitas esta funcionalidad, vuelve atrás y elimina la otra puerta de tu dispositivo antes de intentar esto de nuevo.'
    },
    openClosedBlockedByDevice: {
      title: 'Sensor de abierto/cerrado inhabilitado',
      text: 'Tu dispositivo está detectando más aperturas por minuto que nuestro límite permitido.\n\nEsto puede indicar una mala conexión entre tu dispositivo y el sensor de abierto/cerrado o una mala configuración de la puerta. Te recomendamos verificar la conexión física en el dispositivo y la configuración de la puerta.\n\nCuando se haya resuelto el problema, presiona el botón de abajo para volver a habilitar el sensor de abierto/cerrado.',
      buttons: {
        ok: 'Habilitar sensor de abierto/cerrado'
      }
    },
    autoAccessBlockedByDevice: {
      title: 'Auto access está inhabilitado',
      text: 'Tu dispositivo está abriendo la puerta en modo Auto Access más veces por minuto que nuestro límite permitido.\n\nEsto puede indicar una mala conexión entre tu dispositivo y el telefonillo o una mala configuración de la puerta. Te recomendamos verificar la conexión física en el dispositivo y la configuración de la puerta.\n\nCuando se haya resuelto el problema, presiona el botón de abajo para volver a habilitar el modo Auto Access.',
      buttons: {
        ok: 'Habilitar modo Auto Access'
      }
    },
    firmwareUpdateAvailable: {
      title: 'Cambios actualización',
      text: '\n\nIMPORTANTE\n\nAsegúrate que tu dispositivo tiene una buena señal de conexión a internet\nSe recomienda estar cerca del dispositivo para que puedas reiniciarlo en caso de que algo vaya mal.',
      buttons: {
        ok: 'Actualizar ahora',
        cancel: 'Hacerlo más tarde'
      }
    },
    firmwareUpdateAvailableNotOwner: {
      title: 'Actualización disponible',
      text: 'Sólo el dueño o un administrador del dispositivo puede actualizarlo.\n\nSi quieres que el dispositivo sea actualizado, ponte en contaxto con cualquiera de las personas de abajo.\n\n'
    },
    nukiBatteryCritical: {
      title: 'Batería de Nuki muy baja',
      text: 'La batería de tu Nuki está muy baja. En cualquier momento tu Nuki se apagará y dejará de responder. Reemplaza las baterías cuanto antes.\n\nCuando tu Nuki tiene baja batería, no podemos asegurarte el resultado de las operaciones porque la Nuki no se comporta como se espera.'
    },
    remainingUpgrade: {
      title: 'Upgrade cancelado',
      textFirst: 'El upgrade de tu dispositivo termina ',
      textOwner: '. Recuerda que siempre puedes volver a hacer upgrade de tu dispositivo. No se te cobrará hasta que acabe el período que ya has pagado.',
      textNotOwner: '. Recuerda que puedes pedirle al dueño del dispositivo que vuelva a hacer upgrade del mismo. Puedes ver quién es el dueño en la pestaña Usuarios.',
      buttons: {
        ok: 'Upgrade'
      }
    },
    doorPhoneUpgradeNotOwner: {
      title: 'Upgrade de teléfono requerido',
      text: 'Lo sentimos, pero no puedes activar esta funcionalidad en este momento porque el dueño del dispositivo debe hacer Upgrade del teléfono.\n\nSólo el dueño del dispositivo puede hacerlo, puedes ver quién es el dueño en la pestaña Usuarios.'
    },
    upgradesBillingAddress: {
      title: 'Facturas',
      text: 'Si necesitas facturas, sólo necesitas introducir tu Info. de facturación. Recibirás las facturas por email automáticamente cada vez que tu plan se cobre.',
      buttons: {
        ok: 'Ir a facturación'
      }
    },
    integrationNotSupported: {
      title: 'Integración no soportada',
      text: 'Lo sentimos, pero esta integración no está soportada en este momento'
    },
    guestyPairDeviceNotUpgraded: {
      title: 'Dispositivo sin upgrade',
      text: 'Lo sentimos, pero no puedes vincular tu propiedad de Guesty con este dispositivo porque no le has hecho Upgrade',
      buttons: {
        ok: 'Upgrade'
      }
    },
    guestyPairDelete: {
      title: 'Eliminar upgrade',
      text: 'Si eliminas este upgrade, tu propiedad Guesty ya no se sincronizará con tu dispositivo Raixer.\n\nAdemás eliminaremos tu plan asociado de ',
      buttons: {
        ok: 'Eliminar upgrade'
      }
    },
    guestyPairDeleteConfirm: {
      title: 'Eliminar upgrade',
      text: '¿Estás seguro que quieres eliminar este upgrade?',
      buttons: {
        ok: 'Sí, eliminar upgrade'
      }
    },
    limitVariableFormNoValue: {
      title: 'Ningún valor detectado',
      text: 'Parece que tu dispositivo no detecta cambios en la tensión eléctrica que viene del telefonillo.\n\nPor favor, asegúrate de que hay una llamada al timbre en estos momentos y que la conexión entre el telefonillo y tu dispositivo está bien hecha.\n\nTip: si este problema continúa, considera intercambiar los cables entre el telefonillo y tu dispositivo.',
    },
    permissionExplanation: {
      title: 'Permisos de ubicación',
      text: 'Necesitamos tu permiso para acceder a tu ubicación para poder obtener la información de tu conexión WiFi y se utilizarla para configurar tu nuevo dispositivo Raixer.\n\nComo ya has negado los permisos, si quieres disfrutar de esta configuración automática tendrás que ir a la configuración de tu móvil para darnos el permiso manualmente.',
      buttons: {
        ok: 'Ir a configuración del móvil'
      }
    },
    cantFindWifiNetwork: {
      title: 'Ayuda para conectar',
      text: 'Si no encuentras tu red WiFi en la lista puedes presionar en "Refrescar" cuantas veces quieras para volver a buscar.\n\nRecuerda que los dispositivos Raixer sólo funcionan con redes 2.4GHz. Muchos routers crean 2 redes WiFi distintas: 2.4GHz y 5GHz. Asegúrate de que la red 2.4GHz está habilitada en tu router'
    },
    cantFindPhoneNetwork: {
      title: 'Ayuda para conectar',
      text: 'Tu dispositivo Raixer no puede ser configurado con la red Wifi a la que está conectado tu móvil. Puede ser que no se encuentre dentro de su alcance o que se trate de una red 5GHz. Recuerda que Raixer solo en compatible con redes 2.4GHz.'
    },
    cantConnectToDevice: {
      title: 'Ayuda para conectar',
      text: 'Si no puedes conectarte a tu dispositivo, intenta esperar una notificación de tu móvil que dice que la red que has seleccionado no tiene acceso a internet y pide tu confirmación para mantenerse conectado.\n\nPor favor, presiona "Mantenerse conectado" cuando la notificación aparezca y vuelve a intentarlo.\n\nSi la notificación nunca aparece, intenta olvidar la red Wi-Fi de tu dispositivo Raixer y conéctate a ella de nuevo.'
    },
    wizardAddRaixerTap: {
      title: 'Agregar un Raixer Tap',
      text: 'Para agregar un nuevo Raixer Tap a tu dispositivo, ve a la pantalla de Ajustes de tu dispositivo y sigue las instrucciones para agregar un nuevo extra.'
    },
    wizardDeviceOnline: {
      title: 'Dispositivo conectado',
      text: 'Tu dispositivo ya se ha conectado, puedes presionar en "Continuar" para terminar su configuración.'
    },
    wizardDeviceOffline: {
      title: 'Dispositivo offline',
      text: 'Tu dispositivo no se ha conectado todavía, pero esto puede llevar un par de minutos. Quizás, debas esperar un poco más para darle tiempo a tu dispositivo a que se conecte.\n\nSi prefieres, puedes empezar este proceso de nuevo.',
      buttons: {
        ok: 'Configurar de nuevo'
      }
    },
    wizardDeviceOfflineConfirmation: {
      title: 'Dispositivo offline',
      text: '¿Estás seguro que quieres comenzar de nuevo el proceso para configurar tu dispositivo?',
      buttons: {
        ok: 'Sí, configurar de nuevo'
      }
    },
    wizardExitConfirmation: {
      title: 'Cancelar configuración',
      text: '¿Estás seguro que quieres cancelar la configuración de tu dispositivo? Vas a interrumpir y perder todo el progreso que has hecho.',
      buttons: {
        ok: 'Sí, cancelar configuración del dispositivo'
      }
    },
    saveLocationSuccess: {
      title: 'Alojamiento guardados',
      text: '¡Genial! Tu alojamiento se ha guardado correctamente y tus datos ya han sido validados con el cuerpo policial.',
      textSave: '\n\nPuedes empezar a usar el servicio.',
      textEdit: '\n\nPuedes continuar el uso del servicio.'
    },
    saveLocationBadCredentials: {
      title: 'Error al guardar alojamiento',
      text: 'Lo sentimos, no hemos podido guardar tu alojamiento porque el usuario y la contraseña que nos has proporcionado para el cuerpo policial no son correctas.\n\nPor favor, revisa el usuario y la contraseña y vuelve a intentarlo.'
    },
    deleteLocation:{
      title: 'Eliminar Dirección',
      text: '¿Estás seguro que quieres eliminar esta dirección?',
      buttons: {
        ok: 'Sí, eliminar dirección'
      }
    },
    bookingOverlappingMultipleSubLocations: {
      title: 'No hemos podido guardar la reserva',
      text: 'Lo sentimos, pero no hemos podido guardar porque tu Upgrade de checkin está en el período de prueba y porque las fechas de entrada o salida de la reserva coinciden con las de otra reserva.\n\nSi realmente necesitas guardar esta reserva ahora, te puedes poner en contacto con nosotros en:\n\nhello@raixer.com'
    },
    deleteBooking:{
      title: 'Eliminar reserva',
      text: '¿Estás seguro que quieres eliminar esta reserva? Eliminar una reserva elimina las sesiones auto access y números de teléfono que ha generado',
      buttons: {
        ok: 'Sí, eliminar reserva'
      }
    },
    energySaveInfo: {
      title: 'Ahorro Energía',
      text: 'Asegúrate de que el ruido que provoca tu timbre es capaz de despertar el dispositivo antes de dejarlo solo. Para ello activa Ahorro Energía, espera a que la luz de tu dispositivo se apague y sin que detecte movimiento llama al timbre para ver si vuelve a encenderse.'
    },
    energySaveEnableConfirm: {
      title: 'Activar Ahorro Energía',
      text: '¿Estás seguro? Tu dispositivo se dormirá inmediatamente, no podrás controlarlo desde la app. Para despertarlo a distancia deberás desactivar Ahorro Energía y esperar 24 horas.'
    },
    energySaveTest: {
      title: 'Probar Ahorro Energía',
      text: 'Tu dispositivo se dormirá inmediatamente por 2 minutos o hasta que escuche algún ruido (como el timbre del telefonillo) o detecte algún movimiento. Cuando despierte, no volverá a dormir.'
    },
    noRaixerDevice: {
      title: 'Comprar dispositivo Raixer',
      text: 'Compra un dispositivo Raixer e instálalo en tu apartamento para disfrutar de todas las ventajas de Raixer.\n\nPodrás abrir la puerta de tu apartamento y la del portal desde cualquier lugar. Además el dispositivo incorpora sensor de ruido (detecta fiestas). Tus huéspedes harán checkin sin que hagas nada.',
      buttons: {
        ok: 'Ir a la tienda',
        cancel: 'Mas adelante'
      }
    },
    paymentFailedNotOwner: {
      title: 'Dispositivo bloqueado',
      textFirst: 'El pago del Upgrade de tu dispositivo está fallando. Tienes ',
      textSecond: ' para corregir este problema o nos veremos forzados a bloquear tu dispositivo.\n\nPor favor, ponte en contacto con el dueño del dispositivo para corregir este problema.'
    },
    paymentFailedBlockedNotOwner: {
      title: 'Dispositivo bloqueado',
      text: 'Hemos bloqueado tu dispositivo porque su Upgrade está sin pagar.\n\nPor favor, ponte en contacto con el dueño del dispositivo para corregir este problema.'
    },
    dimissDeviceConfiguration: {
      title: 'Abandonar configuración de dispositivo',
      text: '¿Estás seguro de que quieres abandonar la configuración de tu dispositivo? Perderás todos los cambios que hayas hecho.',
      buttons: {
        ok: 'Sí, abandonar configuración'
      }
    },
    dimissDeviceConfigurationConfirm: {
      title: 'Abandonar configuración de dispositivo',
      text: '¿Quieres volver aquí la próxima vez que regreses a tu dispositivo? En caso de que no quieras, siempre podrás a este proceso desde los Ajustes de tu dispositivo.',
      buttons: {
        ok: 'Sí, quiero volver aquí',
        cancel: 'No, no quiero volver a ver esto'
      }
    },
    deleteAuthorizedCode: {
      title: 'Eliminar código',
      text: '¿Estás seguro de que quieres eliminar este código?',
      buttons: {
        ok: 'Eliminar'
      }
    },
    doorCodeRemove: {
      title: 'Eliminar enlace',
      text: '¿De verdad quieres eliminar el enlace para abrir tu puerta?\n\nNo podrás abrir la puerta con un código. Si quieres volver a activar el link, no será el mismo que tienes ahora.',
      buttons: {
        ok: 'Elimínar'
      }
    },
    doorCodePossibleAnswers: {
      title: 'Información sobre el enlace',
      text: 'Estás a punto de generar un enlace para abrir la puerta',
      textEdit: 'Estás a punto de cambiar el enlace para abrir la puerta'
    },
    disableDoorCodeAccess: {
      title: 'Desactivar acceso por códigos',
      text: '¿De verdad quieres desactivar el acceso por códigos? Nadie podrá abrir la puerta al introducir un código .',
      buttons: {
        ok: 'Desactivar'
      }
    },
    enableDoorAnyCodeAccess: {
      title: 'Activar acceso sin código',
      text: '¿De verdad quieres activar el acceso sin códigos? Cualquier persona que visite el enlace podrá abrir la puerta sin la necesidad de introducir un código.',
      buttons: {
        ok: 'Activar'
      }
    },
    shareDoorCode: {
      title: 'Compartir link de puerta',
      text: 'Copia el texto de abajo y compártelo donde quieras:'
    },
    shareDoorPhone: {
      title: 'Compartir teléfono de puerta',
      text: 'Copia el texto de abajo y compártelo donde quieras:'
    },
    shareUserQr: {
      title: 'Compartir QR',
      text: 'Copia el texto de abajo y compártelo donde quieras:'
    },
    shareBookingLink: {
      title: 'Compartir link de reserva',
      text: 'Copia el texto de abajo y compártelo donde quieras:'
    },
    callSupport: {
      title: 'Soporte técnico',
      text: '¿Tienes problemas? Te podemos ayudar, sólo llámanos a:\n\n+34910381221'
    },
    gyroNoInternet: {
      title: 'Sin internet',
      text: 'Tu Gyro no tiene conexión a internet en este momento.\n\nRecuerda, siempre puedes abrir la puerta con tu móvil a través de Bluetooth.'
    },
    buttons: {
      defaultOk: 'Entendido',
      defaultCancel: 'Cancelar'
    }
  },
  shares: {
    newUser: {
      title: 'Invitación para un Dispositivo Raixer',
      message: 'Abre esta URL para obtener acceso al dispositivo Raixer',
      dialogTitle: 'Abre esta URL para obtener acceso al dispositivo Raixer',
      deviceId: 'Dispositivo: ',
      name: 'Nombre: '
    },
    doorPhone: {
      title: 'Llama para abrir la puerta',
      message: 'Llama al siguiente número para abrir la puerta'
    },
    deviceId: {
      title: 'Información de dispositivo',
      message1: 'ID de dispositivo: ',
      message2: 'Versión de dispositivo: ',
      message3: 'Versión del SO del dispositivo: ',
      message4: 'Versión del firmware del dispositivo: '
    },
    booking: {
      title: 'Invitacion para tu proxima visita',
      message: 'Registrate en tu próxima visita',
      message1: 'Registrate en tu próxima visita a ',
      message2: 'el dia ',
      dialogTitle: '',
    },
    doorCode: {
      title: 'Abre la puerta',
      message: 'Entra aquí para abrir la puerta',
      message2: 'e introduce el código'
    }
  },
  screens: {
    auth: {
      login: 'Entrar',
      signup: 'Registrarme',
      recoverPassword: 'Regenerar contraseña',
      facebookLogin: 'Login con Facebook',
      facebookSignup: 'Registrarme con Facebook',
      appleLogin: 'Login con Apple',
      textTerms: 'Al registrarte, aceptas nuestros',
      linkSignup: '¿Nuevo en Raixer? Regístrate',
      linkRecover: 'Recuperar contraseña',
      linkLoginSignup: '¿Ya tienes una cuenta? Haz login',
      linkLoginRecover: 'Volver atrás',
      linkTerms: 'términos y condiciones'
    },
    connections: {
      title: 'Mis lugares',
      loading: 'Cargando tus sitios',
      search: 'Buscar por nombre',
      emptySearch: 'No se han encontrado sitios con este nombre',
      empty: '¿Quieres agregar un Raixer?',
      emptyButton: 'Nuevo dispositivo',
      emptyButtonSecondary: 'Comprar dispositivo',
      emptyCheckin: '¿Quieres agregar un alojamiento para nuestra integración de checkins pero no tienes un Raixer?',
      emptyCheckinButton: 'Escáner documentos checkin'
    },
    connection: {
      title: 'Detalles de mi lugar',
      empty: 'Haz click uno de tus lugares en la lista de la izquierda\npara controlarlo aquí',
      fetching: 'Obteniendo tu dispositivos...',
      lastMovement: 'Último movimiento: ',
      autoAccessAlwaysActive: 'Auto Access está Siempre Activo',
      pistonActive: 'El pistón está activado',
      pistonInactive: 'El pistón está desactivado',
      sessionEnd: 'hasta que la sesión acabe',
      contingencyMode: 'Modo de contingencia',
      alwaysActive: 'Siempre activo',
      autoAccessAlwaysActiveDescription: 'Auto Access está Siempre Activo, apágalo si prefieres programar sesiones Auto Access',
      schedulesEmpty: 'No hay Auto Access programado para este dispositivo',
      schedulesAddButton: 'Programar',
      usersAddButton: 'Agregar usuario',
      unexpectedError: 'Error inesperado, tira hacia abajo para refrescar tu dispositivo y volver a intentar',
      historyEmpty: 'No hay historial para este dispositivo',
      historyEmptyTrialEnded: 'Haz Upgrade para desbloquear el historial. Podrás consultar todos los movimientos, incluso los previos al Upgrade.',
      warnings: {
        offline: 'Tu dispositivo está apagado o no tiene conexión a internet.',
        updating: 'Tu dispositivo se está actualizando, esto suele tardar un par de minutos.',
        offlineAutoAccess: 'Modo de Contingencia: Auto Access Sin Conexión activo.',
        cantUse: 'Desbloquea funcionalidades',
        cantUseAccount: 'Haz upgrade para seguir utilizando este dispositivo.',
        paymentFailing: 'Tu método de pago está fallando.',
        paymentFailingNotOwner: 'El Upgrade de tu dispositivo está sin pagar.',
        paymentFailedBlocked: 'Tu dispositivo está bloqueado porque tu método de pago está fallando.',
        paymentFailedBlockedNotOwner: 'Tu dispositivo está bloqueado porque su Upgrade está sin pagar.',
        apiUserPaymentFailed: 'Tu pago para utilizar el API ha fallado. Por favor, contactános para seguir utilizando tus dispositivos.',
        trialExpireToday: 'Tu período de prueba termina hoy',
        remainingTrial: 'Período de prueba restante',
        afterTrialFreeVersion: 'Después pasarás a la versión gratuita.',
        openClosedBlocked: 'Sensor de abierto/cerrado inhabilitado',
        autoAccessBlocked: 'Auto access está inhabilitado',
        updateAvailable: 'Actualización disponible para tu dispositivo',
        nukiBatteryCritical: 'La batería de tu Nuki está muy baja',
        noRaixerDevice: 'Comprar un dispositivo Raixer',
        linkRaixerDevice: 'Vincula uno de tus dispositivos',
        remainingUpgrade: 'Upgrade restante',
        upgradeExpireToday: 'Tu upgrade termina hoy',
        particleDisabled: 'Tu dispositivo ya no tiene un upgrade y no se puede utilizar',
        signal: {
          strong: 'Fuerte',
          good: 'Buena',
          fair: 'Regular',
          bad: 'Mala',
          poor: 'Pobre',
          wifi: 'Señal WiFi:',
          cellular: 'Señal móvil:'
        },
        power: {
          electricity: 'Conectado a electricidad',
          usingBattery: 'Utilizando batería'
        },
        battery: {
          battery: 'Batería',
          gyroBattery: 'Batería del Gyro',
          charging: 'Cargando',
          discharging: 'En uso',
          charged: 'Cargada',
          disconnected: 'Desconectada',
          lastUpdatedAt: 'Última actualización:'
        },
        gyroConnectWifi: 'Conectar a WiFi',
        gyroControlIntercom: 'Controla tu telefonillo',
        gyroNoInternet: 'Sin conexión a internet',
        afterGyroTrial: 'Después pasarás a tu plan'
      },
      error: {
        disabled: 'No tienes permisos para utilizar este dispositivo. Contacta al dueño para pedir autorización.',
        unexpected: '¡Oops! Ha ocurrido un error inesperado.\nPor favor, arrastra hacia abajo para intentar de nuevo.',
        trialEnded: 'Por favor, haz upgrade de tu dispositivo para poder utilizar sus funcionalidades.'
      },
      users: {
        search: 'Buscar por nombre o email',
        emptySearch: 'No se han encontrado usuarios con este nombre o email',
        of: 'de',
      },
      phones: {
        noPhone: 'Esta puerta no tiene un número de teléfono',
        getPhone: 'Obtener un teléfono',
        addAuthorizedPhone: 'Agregar número autorizado',
        callingEnabledLabel: 'Activo',
        anyCallerEnabledLabel: 'Accesso a cualquier persona',
        authorizedPhonesTitle: 'Números autorizados',
        authorizedPhonesEmpty: 'Si no autorizas a ningún número, nadie puede abrir la puerta.',
        disabled: 'El acceso telefónico para esta puerta está desactivado. Enciende el interruptor de arriba para activarlo.'
      },
      codes: {
        noLink: 'Esta puerta no tiene un enlace',
        getLink: 'Obtener un enlace',
        addAuthorizedCode: 'Nuevo código',
        callingEnabledLabel: 'Activo',
        authorizedCodesTitle: 'Codigos autorizados',
        authorizedCodesEmpty: 'Si no autorizas a ningún codigo, nadie puede abrir la puerta.',
        anyCallerEnabledLabel: 'Accesso sin códigos',
        search: 'Buscar por nombre o código',
        emptySearch: 'No se han encontrado códigos con este nombre o código',
        of: 'de',
      },
      history: {
        phoneCall: {
          success: 'Puerta 1 - Acceso telefónico',
          error: 'Puerta 1 - Acceso telefónico no autorizado'
        },
        phoneCall2: {
          success: 'Puerta 2 - Acceso telefónico',
          error: 'Puerta 2 - Acceso telefónico no autorizado'
        },
        phoneCall3: {
          success: 'Puerta 3 - Acceso telefónico',
          error: 'Puerta 3 - Acceso telefónico no autorizado'
        },
        phoneCallOffline: {
          error: 'Puerta 1 - Acceso telefónico no disponible'
        },
        phoneCallOffline2: {
          error: 'Puerta 2 - Acceso telefónico no disponible'
        },
        phoneCallOffline3: {
          error: 'Puerta 3 - Acceso telefónico no disponible'
        },
        openDoor: {
          success: 'Puerta 1 - Apertura con app',
          error: 'Puerta 1 - Usuario no autorizado'
        },
        openDoor2: {
          success: 'Puerta 2 - Apertura con app',
          error: 'Puerta 2 - Usuario no autorizado'
        },
        openDoor3: {
          success: 'Puerta 3 - Apertura con app',
          error: 'Puerta 3 - Usuario no autorizado'
        },
        startJob: {
          success: 'Puerta 1 - Inicio Auto Access',
          error: 'Puerta 1 - Usuario no autorizado'
        },
        startJob2: {
          success: 'Puerta 2 - Inicio Auto Access',
          error: 'Puerta 2 - Usuario no autorizado'
        },
        startJob3: {
          success: 'Puerta 3 - Inicio Auto Access',
          error: 'Puerta 3 - Usuario no autorizado'
        },
        stopJob: {
          success: 'Puerta 1 - Fin Auto Access',
          error: 'Puerta 1 - Usuario no autorizado'
        },
        stopJob2: {
          success: 'Puerta 2 - Fin Auto Access',
          error: 'Puerta 2 - Usuario no autorizado'
        },
        stopJob3: {
          success: 'Puerta 3 - Fin Auto Access',
          error: 'Puerta 3 - Usuario no autorizado'
        },
        doorOpened: {
          success: 'Puerta 1 - Apertura con Auto Access',
          error: 'Puerta 1 - La puerta no fue abierta cuando alguien ha llamado'
        },
        doorOpened2: {
          success: 'Puerta 2 - Apertura con Auto Access',
          error: 'Puerta 2 - La puerta no fue abierta cuando alguien ha llamado'
        },
        doorOpened3: {
          success: 'Puerta 3 - Apertura con Auto Access',
          error: 'Puerta 3 - La puerta no fue abierta cuando alguien ha llamado'
        },
        doorIsOpen: {
          success: 'Puerta 1 - Abierta',
          error: 'Puerta 1 - No abierta'
        },
        doorIsOpen2: {
          success: 'Puerta 2 - Abierta',
          error: 'Puerta 2 - No abierta'
        },
        doorIsOpen3: {
          success: 'Puerta 3 - Abierta',
          error: 'Puerta 3 - No abierta'
        },
        doorIsClosed: {
          success: 'Puerta 1 - Cerrada',
          error: 'Puerta 1 - No cerrada'
        },
        doorIsClosed2: {
          success: 'Puerta 2 - Cerrada',
          error: 'Puerta 2 - No cerrada'
        },
        doorIsClosed3: {
          success: 'Puerta 3 - Cerrada',
          error: 'Puerta 3 - No cerrada'
        },
        startJobAutoAccess: {
          success: 'Puerta 1 - Inicio Auto Access programado',
          error: 'Puerta 1 - Error Inicio Auto Access programado'
        },
        startJobAutoAccess2: {
          success: 'Puerta 2 - Inicio Auto Access programado',
          error: 'Puerta 2 - Error Inicio Auto Access programado'
        },
        startJobAutoAccess3: {
          success: 'Puerta 3 - Inicio Auto Access programado',
          error: 'Puerta 3 - Error Inicio Auto Access programado'
        },
        stopJobAutoAccess: {
          success: 'Puerta 1 - Fin Auto Access programado',
          error: 'Puerta 1 - Error Fin Auto Access programado'
        },
        stopJobAutoAccess2: {
          success: 'Puerta 2 - Fin Auto Access programado',
          error: 'Puerta 2 - Error Fin Auto Access programado'
        },
        stopJobAutoAccess3: {
          success: 'Puerta 3 - Fin Auto Access programado',
          error: 'Puerta 3 - Error Fin Auto Access programado'
        },
        deleteJobAutoAccess: {
          success: 'Puerta 1 - Eliminado Auto Access programado tras varios reintentos',
          error: 'Puerta 1 - Eliminado Auto Access programado tras varios reintentos'
        },
        deleteJobAutoAccess2: {
          success: 'Puerta 2 - Eliminado Auto Access programado tras varios reintentos',
          error: 'Puerta 2 - Eliminado Auto Access programado tras varios reintentos'
        },
        deleteJobAutoAccess3: {
          success: 'Puerta 3 - Eliminado Auto Access programado tras varios reintentos',
          error: 'Puerta 3 - Eliminado Auto Access programado tras varios reintentos'
        },
        deviceOffline: {
          success: 'Dispositivo offline'
        },
        deviceOnline: {
          success: 'Dispositivo encendido'
        },
        movementDetected: {
          success: 'Movimiento detectado'
        },
        physicalButton: {
          success: 'Botón físico presionado'
        },
        powerIn: {
          success: 'Hay luz - Cargando batería'
        },
        powerOut: {
          success: 'Corte de luz - Utilizando batería'
        },
        pistonActivated: {
          success: 'Pistón activo'
        },
        pistonDeactivated: {
          success: 'Pistón desactivado'
        },
        offlinePistonOpeningStarted: {
          success: 'Dispositivo offline - Comienza apertura de pistón'
        },
        offlinePistonOpeningEnded: {
          success: 'Dispositivo offline - Pistón abierto'
        },
        soundDetected: {
          success: 'Sonido detectado'
        },
        soundAlarm: {
          success: 'Alarma de sonido'
        },
        openDoorAssistant: {
          success: 'Puerta 1 - Apertura con Google o Alexa',
          error: 'Puerta 1 - Usuario no autorizado con Google o Alexa'
        },
        openDoorAssistant2: {
          success: 'Puerta 2 - Apertura con Google o Alexa',
          error: 'Puerta 2 - Usuario no autorizado con Google o Alexa'
        },
        openDoorAssistant3: {
          success: 'Puerta 3 - Apertura con Google o Alexa',
          error: 'Puerta 3 - Usuario no autorizado con Google o Alexa'
        },
        sleep: {
          success: 'Dispositivo dormido'
        },
        wokeup: {
          success: 'Dispositivo despertado'
        },
        energySaveActivated: {
          success: 'Ahorro Energía activado'
        },
        energySaveDeactivated: {
          success: 'Ahorro Energía desactivado'
        },
        accessByCode: {
          success: 'Puerta 1 - Apertura con código'
        },
        accessByCode2: {
          success: 'Puerta 2 - Apertura con código'
        },
        accessByCode3: {
          success: 'Puerta 3 - Apertura con código'
        },
        accesssByCodeOffline: {
          error: 'Acceso con código no disponible'
        },
        nukiUnlock: {
          success: 'Nuki desbloqueada',
          error: 'Error desbloqueando Nuki'
        },
        nukiClose: {
          success: 'Nuki cerrada',
          error: 'Error cerrando Nuki'
        },
        nukiOpen: {
          success: 'Nuki abierta',
          error: 'Error abriendo Nuki'
        },
        bellRing: {
          success: 'Door 1 - Llamada al timbre'
        },
        bellRing2: {
          success: 'Door 2 - Llamada al timbre'
        },
        bellRing3: {
          success: 'Door 3 - Llamada al timbre'
        },
        gyroUnlock: {
          success: 'Llave[doorName]abierta con app',
          error: 'Error abriendo llave[doorName]'
        },
        gyroClose: {
          success: 'Puerta[doorName]cerrada con app',
          error: 'Error cerrando puerta[doorName]'
        },
        gyroOpen: {
          success: 'Puerta[doorName]abierta con app',
          error: 'Error abriendo puerta[doorName]'
        },
        gyroOpenPhoneCall: {
          success: 'Acceso telefónico[doorName]',
          error: 'Error en acceso telefónico[doorName]'
        },
        gyroOpenAuthCode: {
          success: 'Apertura con código[doorName]',
          error: 'Error en apertura con código[doorName]'
        }
      }
    },
    autoAccessForm: {
      title: 'Configuración Auto Access',
      titleEdit: 'Editar configuración Auto Access',
      labels: {
        alwaysActive: 'Siempre activo',
        contingencyMode: 'Modo de contingencia'
      },
      helps: {
        alwaysActive: 'Auto Access está siempre activo',
        contingencyMode: 'Activa Auto Access cuando el dispositivo no tiene internet'
      }
    },
    scheduleForm: {
      title: 'Nueva regla',
      titleEdit: 'Editar regla',
      titleForm: 'Nueva regla agendada',
      titleFormEdit: 'Editar regla agendada',
      labels: {
        startDate: 'Fecha de comienzo',
        startTime: 'Comenzar el acceso a las',
        endDate: 'Fecha de finalización',
        endTime: 'Terminar el acceso a las'
      }
    },
    userForm: {
      title: 'Nuevo usuario',
      titleEdit: 'Editar usuario',
      titleForm: 'Comparte el Código QR para agregar un usuario',
      titleFormEdit: 'Editar usuario',
      newUserMainButton: 'Compartir',
      errorGetQrs: 'Error inesperado al obtener los códigos QR de tu dispositivo. Vuelve atrás e intenta de nuevo.',
      enableUser: 'Activar',
      disableUser: 'Desactivar',
      labels: {
        startDate: 'Start date',
        startTime: 'Start access at',
        endDate: 'End date',
        endTime: 'End access at'
      }
    },
    notifications: {
      labels: {
        confirmDeviceOpenClose: 'Confirmar apertura y cierre de dispositivos'
      },
      helps: {
        confirmDeviceOpenClose: 'Si está activado, se pedirá confirmación antes de la apertura o cierre del dispositivo'
      }
    },
    authorizedCodeForm: {
      title: 'Nuevo codigo',
      titleEdit: 'Editar codigo',
      titleForm: 'Nuevo codigo autorizado',
      titleFormEdit: 'Editar codigo autorizado',
      labels: {
        code: 'Codigo',
        unlimitedAccess: 'Tiempo de acceso ilimitado',
        deleteOnEnd: 'Eliminar una vez finalizado',
        repeatDays: 'Repetir en días de la semana',
        alias: 'Alias (opcional)'
      },
      helps: {
        code: 'Recuerda escribir que le codigo es de 6 digitos maximo y numerico'
      }
    },
    codeDoor: {
      title: 'Enlace de puerta',
      titleNew: 'Obtener un enlace para',
      titleEdit: 'Cambiar enlace para',
      text: 'Elige un alias para tu nuevo url',
      optionalAlias: 'Alias (Opcional)',
      buttons: {
        delete: 'Eliminar enlace',
        generateLink: 'Generar enlace'
      }
    },
    authorizedPhoneForm: {
      title: 'Nuevo teléfono',
      titleEdit: 'Editar teléfono',
      titleForm: 'Nuevo teléfono autorizado',
      titleFormEdit: 'Editar teléfono autorizado',
      labels: {
        phone: 'Teléfono',
        unlimitedAccess: 'Aperturas ilimitadas',
        deleteOnCall: 'Eliminar número después de abrir',
        deleteOnCallAfter: 'Aperturas permitidas antes de eliminar',
        deleteOnEnd: 'Eliminar una vez finalizado',
        repeatDays: 'Repetir en días de la semana',
        alias: 'Alias (opcional)'
      },
      helps: {
        phone: 'Recuerda escribir el teléfono con el código de país'
      }
    },
    phoneDoor: {
      title: 'Teléfono de puerta',
      titleNew: 'Obtener un teléfono',
      titleEdit: 'Cambiar número de teléfono',
      text: 'Selecciona el país que prefieres para tu nuevo número. La puerta se abre con una llamada perdida. La llamada será rechazada y no tendrá coste independientemente del país que elijas.',
      textOtherDevice: 'Utilizar el mismo número de teléfono en varias puertas permite abrirlas con una sola llamada.\n\nPresiona uno de los números de teléfono que ya tienes en tus otros dispositivos',
      textNoPhones: 'Lo sentimos, pero no tenemos números de teléfono disponibles. Por favor, contáctanos en hello@raixer.com.',
      textError: 'Ha ocurrido un error inesperado obteniendo los países disponibles. Vuelve atrás e intenta de nuevo',
      helps: {
        useOtherDevicePhone: 'Utilizar un número de mis otros dispositivos'
      },
      buttons: {
        delete: 'Eliminar teléfono'
      }
    },
    phoneDoorChangeConfirm: {
      titleAccessToAnyCaller: 'Accesso a cualquier persona',
      titleCardAccessToAnyCaller: 'Confirmar accesso a cualquier persona',
      textAccessToAnyCaller: 'Si activas esta funcionalidad, cualquier persona que llame al número de teléfono de abajo abrirá la puerta.\n\nParece que no podemos activar esta funcionalidad para el número de teléfono que ya tenías asignado porque ya está en uso por otros dispositivos. Si quieres continuar, tendrás que utilizar el número de abajo.',
      titleAuthorizedPhone: 'Teléfono autorizado',
      titleCardAuthorizedPhone: 'Teléfono autorizado en uso',
      textAuthorizedPhone: 'Has alcanzado el máximo de números autorizados para un número de teléfono compartido.\n\nNecesitas de un número privado para poder agregar más números autorizados.\n\nDebes cambiar y hacer upgrade del número de teléfono de tu puerta al que tienes abajo.',
      buttons: {
        authorizedPhone: 'Cambiar y hacer upgrade del número'
      }
    },
    phonesInformation: {
      title: 'Información del teléfono',
      titleSecond: 'Utilización del teléfono',
      textFirst: 'Recuerda que la puerta abrirá aunque la respuesta sea:\n\n1. La llamada es rechazada.\n2. El teléfono no existe.',
      textSecond: 'El teléfono de tu puerta también está siendo utilizado por uno o más de tus otros dispositivos.\n\nTen en cuenta que si un teléfono autorizado está en más de una puerta, si ese número autorizado hace una llamada, abrirá todas las puertas.\n\nA continuación, puedes ver la lista de las puertas de tus otros dispositivos que lo utilizan, junto a los teléfonos autorizados, si hay, que están tanto en esas puertas como en esta.'
    },
    upgradeForm: {
      titleNew: 'Selecciona tu plan',
      titleEdit: 'Cambia tu plan',
      planNull: 'Selecciona una opción...'
    },
    creditCard: {
      title: 'Método de pago',
      validThru: 'Hasta/Final',
      error: 'Error inexperado al obtener tu información de nuestro procesador de pagos. Por favor, vuelve atrás e intenta de nuevo.',
      submit: 'Guardar tarjeta',
      delete: 'Eliminar tarjeta',
      labels: {
        number: 'Número de la tarjeta de crédito',
        name: 'Nombre del titular',
        expirationMonth: 'Mes de expiración',
        expirationYear: 'Año de expiración',
        cvc: 'Código de seguridad'
      }
    },
    workInProgress: {
      title: 'Trabajo en curso',
      text: 'Actualmente estamos trabajando en esta funcionalidad, la tendrás muy pronto. Por favor, utiliza nuestra APP en Android o iOS si necesitas hacer esto ahora.',
      button: 'Got it'
    },
    checkin: {
      locationEmpty: 'No existe una dirección para esta conexión',
      locationAddButton: 'Agregar alojamiento',
      locationGuestBook: 'Libro de visitas',
      bookingEmpty: 'No existen reservas activas',
      bookingAddButton: 'Agregar reserva',
      bookingPast: 'Reservas pasadas'
    },
    locationForm: {
      titleEdit: 'Configuración alojamiento',
      title: 'Nuevo alojamiento',
      labels: {
        locationType: 'Tipo alojamiento',
        locationName: 'Nombre alojamiento',
        locationAddress: 'Dirección',
        cifnif: 'CIF / NIF',
        locationTown: 'Municipio',
        locationProvince: 'Provincia',
        police: 'Policía',
        userPolice: 'Usuario',
        passPolice: 'Contraseña',
        landlordCode: 'Código arrendador',
        establishmentCode: 'Código de establecimiento',
        sequenceNumber: 'Número de sequencia',
        andaluciaCode: 'Código de inscripción en la Junta de Andalucía',
        locationCategory: 'Categoría del alojamiento',
        landlordPhoneNumber: 'Teléfono arrendador',
        landlordName: 'Nombre propietario',
        startDate: 'Desde',
        startHour: 'A las',
        switchStartHour: 'Hora de llegada',
        endDate: 'Hasta',
        residency: 'Noches',
        guests: 'Número total de personas ',
        guestName: 'Nombre inquilino',
        notificationEmail: 'Email para notificaciones',
        startTime: 'Comenzar Auto Access a las',
        endTime: 'Terminar Auto Access a las',
        postalCode: 'Código postal',
        numberOfBedrooms: 'Número de habitaciones',
        paymentType: 'Tipo de pago'
      },
      helps: {
        startDate: 'Fecha de llegada de huéspedes',
        startHour: 'Hora de llegada de huéspedes',
        switchStartHour: 'Sé la hora de llegada',
        residency: 'Número total de dias de la visita',
        endDate: 'Fecha de salida de los huéspedes. Esta fecha se modifica al modificar el número de noches de la visita',
        guests: 'Número total de huéspedes',
        guestName: 'Nombre del huésped principal',
        notificationEmail: 'Correo electrónico donde se enviarán notificaciones',
        locationType: 'Selecciona el tipo de alojamiento',
        locationName: 'Ingresa el nombre con el que has registrado este alojamiento en la policía',
        locationAddress: 'Dirección del alojamiento',
        locationCategory: 'Categoría a la que pertenece el alojamiento',
        andaluciaCode: 'Código de inscripción del alojamiento en la Junta de Andalucía',
        landlordPhoneNumber: 'Teléfono del arrendador',
        landlordName: 'Nombre del propietario o gestor',
        cifnif: 'NIF/CIF del propietario o gestor',
        locationTown: 'Municipio donde se encuentra el alojamiento',
        locationProvince: 'Provincia donde se encuentra el alojamiento',
        police: 'Policía donde has registrado tu alojamiento',
        userPolice: 'Nombre de usuario en el sistema de la policía. Si no dispones de un nombre de usuario, debes registrar el alojamiento previamente en la policía',
        passPolice: 'Contraseña de tu cuenta con la policía',
        landlordCode: 'Código arrendador',
        establishmentCode: 'Código de establecimiento',
        sequenceNumber: 'Escribe el número del último parte generado. El siguiente número de parte será el: ',
        autoAccess: 'Crear sesiones de Auto Access automáticamente cuando se agregue una reserva',
        phoneNumbers: 'Agregar números de teléfono de huéspedes para abrir la puerta',
        signConditionsDocument: 'Requerir a los huéspedes el leer y aceptar un documento que contiene las condiciones para la estancia',
        internet: 'Indica si el alojamiento dispone de conexión a internet',
        professional: 'Indica si el alojamiento es manejado por una empresa o autónomo',
        postalCode: 'Código postal donde se encuentra el alojamiento',
        numberOfBedrooms: 'Número de habitaciones',
        paymentType: 'Selecciona el tipo de pago',
        automation: 'Automatización'
      },
      buttons:{
        selectConditionsDocument: 'Seleccionar documento',
        changeConditionsDocument: 'Seleccionar otro',
        viewConditionsDocument: 'Ver documento'
      }
    },
    bookingForm: {
      titleEdit: 'Editar reserva',
      title: 'Nueva reserva',
      labels: {
        startDate: 'Desde',
        startHour: 'Hora de llegada',
        switchStartHour: 'Hora de llegada',
        endDate: 'Hasta',
        residency: 'Noches',
        guests: 'Número total de personas'
      },
      help: {
        startDate: 'Fecha de llegada de huéspedes',
        startHour: 'Hora de llegada de huéspedes',
        switchStartHour: 'Sé la hora de llegada',
        residency: 'Número total de noches de la visita',
        endDate: 'Fecha de salida de huéspedes, esta fecha se modifica al modificar el total de dias de la visita',
        guests: 'Número total de huéspedes'
      }
    },
    bookingHistory: {
      title: 'Reservas pasadas',
      textEmpty: 'No existen reservas pasadas'
    },
    guestBook: {
      title: 'Libro de Visitas',
      textEmpty: 'Aún no existen registros',
      send: 'Enviar'
    },
    guestForm: {
      textEmpty: 'Ningún huésped ha hecho checkin todavía'
    }
  }
}
import RaixerConfig from '../Config/RaixerConfig'
import I18n from '../i18n/i18n'
import { getNameOfDoor, getPortOfDoor } from './ConnectionsUtils'

const { connections: RaixerConfigConnections } = RaixerConfig
const { doorDefaultValues, portPositions, sensorDefaultValues } = RaixerConfigConnections

export const getConnectionDoorsAsFormStruct = (connection, filterByDoorWithPhone = false, withAllOption = true, withNukiOption = false) => {
  try {
    const { device } = connection
    const { doors, version } = device
    let filteredDoors = doors
    if (filterByDoorWithPhone) { 
      if (withNukiOption) { filteredDoors = doors.filter(d => d.devicePhone && d.callingEnabled && (d.port !== 'null' || d.sensorMode === 'nuki')) }
      else { filteredDoors = doors.filter(d => d.devicePhone && d.callingEnabled && d.port !== 'null') }
    }
    let doorsStruct = {}
    let allPorts = []
    let i = 1
    for (let door of filteredDoors) {
      if (withNukiOption) {
        const port = door.sensorMode === 'nuki' ? portPositions.nuki : getPortOfDoor(door, version)
        const name = getNameOfDoor(door, i)
        doorsStruct['' + port] = name
        allPorts.push(port)
      } else {
        const port = getPortOfDoor(door, version)
        const name = getNameOfDoor(door, i)
        doorsStruct['' + port] = name
        allPorts.push(port)
      }
      i++
    }
    if (allPorts.length > 1 && withAllOption) { doorsStruct[allPorts.join(',')] = I18n.t('shared.all') }
    return doorsStruct
  } catch {
    return {
      '1': I18n.t('shared.door') + ' 1'
    }
  }
}

export const getConnectionDoorUseForForm = (use, lang) => {
  lang = lang || 'en'
  if (lang !== 'en') {
    return I18n.t('shared.door') + ' ' + use.toLowerCase()
  } else {
    return use + ' ' + I18n.t('shared.door').toLowerCase()
  }
}

export const getDefaultValueHelp = (type, unit) => {
  let unitString = ''
  let value = 0
  if (unit === 'millivolts') { 
    unitString = I18n.t('shared.unitMillivolts') 
    if (type === 'openingValue') { value = doorDefaultValues.openingValue }
    else if (type === 'tolerance') { value = doorDefaultValues.tolerance }
  } else if (unit === 'seconds') { 
    unitString = I18n.t('shared.unitSeconds')
    if (type === 'waitTimeFirst') { value = doorDefaultValues.waitTimeFirst }
    else if (type === 'waitBuzzerTime') { value = doorDefaultValues.waitBuzzerTime }
    else if (type === 'waitBetweenBells') { value = doorDefaultValues.waitBetweenBells }
  } else if (unit === 'openings') { 
    unitString = I18n.t('shared.unitOpenings') 
    value = doorDefaultValues.maxOpenings
  } else if (unit === 'decibels') { 
    unitString = I18n.t('shared.unitDecibels') 
    if (type === 'presence') { value = sensorDefaultValues.noisePresence }
    else if (type === 'alarm') { value = sensorDefaultValues.noiseAlarm }
  } else if (unit === 'minutes') { 
    unitString = I18n.t('shared.unitMinutes')
    if (type === 'groupingTime') { value = sensorDefaultValues.movementSoundGroupingTime }
  } else if (unit === 'hours') {
    unitString = I18n.t('shared.unitHours')
    if (type === 'autoAccessHours') { value = doorDefaultValues.autoAccessHours }
  }
  return I18n.t('shared.default') + ': ' + value + unitString
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthActions from '../../Redux/AuthRedux'
import AuthBackground from '../../Components/UI/Backgrounds/AuthBackground'
import Recover from '../../Components/Screens/Auth/Recover'
import styles from '../Styles/Screens'

class RecoverScreen extends Component {

  onSubmit = (value) => {
    const { email, password } = value
    this.props.recover(email, password)
  }

  goToLogin = () => {
    this.props.history.push('/')
  }

  render = () => {
    return (
      <div style={styles.mainContainer}>
        <AuthBackground />
        <div style={styles.authContainer}>
          <Recover
            onSubmit={this.onSubmit}
            goToLogin={this.goToLogin}
            fetching={this.props.fetching}
          />
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    fetching: state.auth.fetching
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    recover: (email, password) => dispatch(AuthActions.recoverRequest(email, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverScreen)
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RaixerConfig from '../../../Config/RaixerConfig'
import I18n from '../../../i18n/i18n'
import { timestampToDate, timestampToTime } from '../../../Lib/DateTimeUtils'
import AppText from '../Texts/AppText'
import LineItemEditButton from '../Buttons/LineItemEditButton'
import SimpleIconButton from '../Buttons/SimpleIconButton'
import { Colors, Fonts, Metrics } from '../../../Themes/'
import styles from '../../Styles/UI/ListItem'

export default class DatesListItem extends Component {

  static propTypes = {
    dateFrom: PropTypes.number.isRequired,
    dateTo: PropTypes.number.isRequired,
    withEditButton: PropTypes.bool.isRequired,
    leftText: PropTypes.string,
    additionalLeftText: PropTypes.string,
    rightText: PropTypes.string,
    dateFromText: PropTypes.string,
    dateToText: PropTypes.string,
    lastOfList: PropTypes.bool,
    onPressEdit: PropTypes.func,
    editButtonFlex: PropTypes.number,
    customDateFromText: PropTypes.string,
    customDateToText: PropTypes.string,
    customLeftTextStyle: PropTypes.object,
    customRightTextStyle: PropTypes.object,
    customAdditionalLeftTextStyle: PropTypes.object,
    onListItemIcon: PropTypes.func
  }

  static defaultProps = {
    dateFrom: null,
    dateTo: null,
    withEditButton: null,
    leftText: null,
    additionalLeftText: null,
    rightText: null,
    dateFromText: I18n.t('shared.from'),
    dateToText: I18n.t('shared.to'),
    lastOfList: false,
    onPressEdit: () => { console.log() },
    editButtonFlex: 0,
    customDateFromText: null,
    customDateToText: null,
    customLeftTextStyle: {},
    customRightTextStyle: {},
    customAdditionalLeftTextStyle: {},
    onListItemIcon: null
  }

  render = () => {
    return (
      <div style={!this.props.lastOfList ? styles.listItem : Object.assign({}, styles.listItem, styles.lastListItem)}>
        <div style={styles.listItemInner}>
          <div style={Object.assign({}, styles.listItemInner, { flexDirection: 'column', flex: RaixerConfig.animations.lists.itemFlex })}>
            {
              ((this.props.leftText && this.props.rightText) || this.props.additionalLeftText) &&
              <div style={Object.assign({}, styles.listItemInner, { width: '100%', marginBottom: Metrics.baseMargin })}>
                {
                  (this.props.leftText || this.props.additionalLeftText) &&
                  <div style={Object.assign({}, styles.listItemLeft, styles.listItemImportant)}>
                    { 
                      this.props.additionalLeftText !== null && this.props.additionalLeftText !== '' && 
                      <AppText
                        text={this.props.additionalLeftText}
                        customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'left', marginBottom: Metrics.baseMargin }, this.props.customAdditionalLeftTextStyle)}
                        numberOfLines={1}
                      />
                    }
                    {
                      this.props.leftText &&
                      <AppText 
                        text={this.props.leftText}
                        customStyle={Object.assign({}, styles.listItemTitle, this.props.customLeftTextStyle, { textAlign: 'left' })}
                        numberOfLines={1}
                      />
                    }
                  </div>
                }
                {
                  this.props.onListItemIcon &&
                  <SimpleIconButton
                    onPress={this.props.onListItemIcon}
                    icon={'share'}
                    active={true}
                    customButtonStyle={{ margin: 0, padding: 0 }}
                    customIconStyle={{ color: Colors.theme1, fontSize: Fonts.size.regular }}
                  />
                }
                {
                  this.props.rightText &&
                  <div style={styles.listItemRight}>
                    <AppText
                      text={this.props.rightText}
                      customStyle={Object.assign({}, styles.listItemContent, this.props.customRightTextStyle, { textAlign: 'right' })}
                      numberOfLines={1}
                    />
                  </div>
                }
              </div>
            }
            <div style={Object.assign({}, styles.listItemInner, { width: '100%', marginBottom: Metrics.baseMargin })}>
              <div style={styles.listItemLeft}>
                <AppText 
                  text={this.props.customDateFromText ? this.props.customDateFromText : this.props.dateFromText} 
                  customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'left' })}
                  numberOfLines={1}
                />
              </div>
              <div style={Object.assign({}, styles.listItemRight, styles.listItemRightRow)}>
                <AppText 
                  text={timestampToTime(this.props.dateFrom)}
                  customStyle={Object.assign({}, styles.listItemContent, { marginRight: Metrics.smallMargin })}
                  numberOfLines={1}
                />
                <AppText
                  text={timestampToDate(this.props.dateFrom)}
                  customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'right' })}
                  numberOfLines={1}
                />
              </div>
            </div>
            <div style={Object.assign({}, styles.listItemInner, { width: '100%' })}>
              <div style={styles.listItemLeft}>
                <AppText 
                  text={this.props.customDateToText ? this.props.customDateToText : this.props.dateToText} 
                  customStyle={Object.assign({}, styles.listItemContent, { textAlign: 'left' })}
                  numberOfLines={1}
                />
              </div>
              <div style={Object.assign({}, styles.listItemRight, styles.listItemRightRow)}>
                <AppText
                  text={timestampToTime(this.props.dateTo)}
                  customStyle={Object.assign({}, styles.listItemContent, { marginRight: Metrics.smallMargin })}
                  numberOfLines={1}
                />
                <AppText
                  text={timestampToDate(this.props.dateTo)}
                  customStyle={styles.listItemContent}
                  numberOfLines={1}
                />
              </div>
            </div>
          </div>
          <div
            style={
              Object.assign(
                {},
                styles.listItemEditButtonContainer, 
                { flex: this.props.editButtonFlex }
              )
            }
          >
            <LineItemEditButton
              buttonText={I18n.t('shared.edit')}
              onPress={this.props.onPressEdit}
              disabled={false}
              customButtonStyle={{ marginBottom: 0, marginLeft: Metrics.doubleBaseMargin }}
            />
          </div>
        </div>
      </div>
    )
  }

}

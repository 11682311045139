import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import AppText from '../../../UI/Texts/AppText'
import I18n from '../../../../i18n/i18n'
import ListCard from '../../../UI/ContentCards/ListCard'

export default class GuestForm extends Component {

  static propTypes = {
    booking: PropTypes.object.isRequired,
    goBack: PropTypes.func
    // onPressPhone: PropTypes.func.isRequired
  }

  static defaultProps = {
    booking: null,
    goBack: null
    // onPressPhone: null,
  }

  getGuestList = (people) => {
    return people.map((p) => {
      const { _id, firstName, lastName, sequenceNumber, phoneNumber = null } = p
      let item = { 
        _id,
        left1: I18n.t('shared.name'), 
        left2: I18n.t('shared.sequenceNumber'), 
        right1: firstName + ' ' + lastName, 
        right2: sequenceNumber && sequenceNumber.toString()
      }
      if (phoneNumber !== null) {
        item.left3 = I18n.t('shared.phone')
        item.right3 = phoneNumber
      }
      return item
    })
  }

  render = () => {
    const { people = [] } = this.props.booking || {}
    if (people.length === 0) {
      return (
        <ContentCard
          title={I18n.t('shared.guests')}
          renderContent={() => <AppText text={I18n.t('screens.guestForm.textEmpty')}/>}
        />
      )
    }
    return (
      <ListCard
        title={I18n.t('shared.guests')}
        list={this.getGuestList(people)}
        listItemType={'guest'}
        withEditButton={this.props.goBack ? true : false}
        customOnPressEdit={this.props.goBack}
        customEditIcon={'reply'}
        customEditText={I18n.t('shared.back')}
        addButton={false}
        // onListItemSecondary={(guestId) => { this.props.onPressPhone(guestId) }}
      />
    )
  }

}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, SvgIcon } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'
import VCardPath from '../../../Images/Icons/v-card'
import I18n from '../../../i18n/i18n'
import AppText from '../Texts/AppText'
import BaseSwitch from '../Switchs/BaseSwitch'
import { Colors, Images, Metrics } from '../../../Themes/'
import styles from '../../Styles/UI/Connections/Card'

export default class ConnectionCard extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    deviceOnline: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    showSwitch: PropTypes.bool.isRequired,
    onPress: PropTypes.func.isRequired,
    updatingText: PropTypes.string,
    offlineText: PropTypes.string,
    icon: PropTypes.string,
    switchText: PropTypes.string,
    onSwitchChange: PropTypes.func,
    switchValue: PropTypes.bool,
    switchDisabled: PropTypes.bool,
    showIntegrations: PropTypes.bool,
    iconSmallerSize: PropTypes.bool,
    active: PropTypes.bool,
    containerCustomStyle: PropTypes.object,
    titleCustomStyle: PropTypes.object,
  }

  static defaultProps = {
    title: null,
    deviceOnline: null,
    updating: null,
    disabled: null,
    showSwitch: null,
    onPress: null,
    updatingText: I18n.t('shared.updating'),
    offlineText: I18n.t('shared.offline'),
    icon: 'home',
    switchText: I18n.t('shared.autoAccess'),
    onSwitchChange: () => { console.log() },
    switchValue: false,
    switchDisabled: false,
    showIntegrations: false,
    iconSmallerSize: false,
    active: false,
    containerCustomStyle: {},
    titleCustomStyle: {}
  }

  renderIcon = () => {
    let iconStyle = this.props.iconSmallerSize ? { paddingLeft: Metrics.smallMargin, paddingTop: Metrics.smallMargin } : {}
    iconStyle.color = this.props.deviceOnline ? Colors.theme1 : Colors.mediumGray
    iconStyle.fontSize = this.props.iconSmallerSize ? (Metrics.baseMeasure * 5) + 'px' : Metrics.icons.large

    const source = Images['list'][this.props.deviceOnline ? 'online' : 'offline'][this.props.icon];

    if (source) {
      return <img src={source} style={styles.deviceImage} />
    }

    return (
      <>
        { this.props.icon === 'home' && <HomeIcon style={iconStyle}/> }
        { this.props.icon === 'identification' && <SvgIcon style={iconStyle}>{VCardPath}</SvgIcon> }
      </>
    )
  }

  renderTitle = () => {
    let titleContainerStyle = [styles.titleContainer]
    let titleStile = [styles.title, this.props.titleCustomStyle]
    if (!this.props.showSwitch || this.props.showIntegrations) { titleStile.push({ marginBottom: 0 }) }
    if (this.props.showIntegrations) { titleContainerStyle.push({ marginBottom: Metrics.baseMargin }) }
    return (
      <div style={Object.assign({}, ...titleContainerStyle)}>
        <AppText
          text={this.props.title}
          customStyle={Object.assign({}, ...titleStile)}
          numberOfLines={1}
        />
        {
          this.props.showIntegrations &&
          <image src={Images.guesty} style={styles.integrationLogo} />
        }
      </div>
    )
  }

  renderContent = () => {
    if (!this.props.showSwitch) { return null }
    if (this.props.updating) { return <AppText text={this.props.updatingText} customStyle={{ margin: 0, textAlign: 'left' }} /> }
    if (!this.props.deviceOnline) { return <AppText text={this.props.offlineText} customStyle={{ margin: 0, textAlign: 'left' }} /> }
    return (
      <BaseSwitch
        label={null}
        help={this.props.switchText}
        value={this.props.switchValue}
        disabled={this.props.switchDisabled}
        onChange={(value) => { this.props.onSwitchChange(value) }}
        withoutMargin={true}
        withoutVerticalPadding={true}
        customHelpStyle={styles.switchHelp}
      />
    )
  }

  render = () => {
    return (
      <>
        <Button 
          onClick={() => { this.props.onPress() }}
          disabled={this.props.disabled}
          variant={'contained'}
          color={'default'}
          style={
            Object.assign(
              {},
              styles.cardContainer,
              this.props.containerCustomStyle,
              this.props.active ? styles.cardContainerActive : {}
            )
          } 
        >
          <div style={!this.props.disabled ? styles.card : Object.assign({}, styles.card, styles.disabled)}>
            { this.renderIcon() }
            <div style={styles.contentContainer}>
              { this.renderTitle() }
              { this.renderContent() }
            </div>
          </div>
        </Button>
      </>
    )
  }

}

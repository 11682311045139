import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import RaixerConfig from '../../../Config/RaixerConfig'
import History from '../../../Components/Screens/Connection/Tabs/History'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import { FormModalService } from '../../../Services/FormModal'
import { connectionIsWifiDevice } from '../../../Lib/ConnectionsUtils'

class HistoryTab extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired,
    scrollHeight: PropTypes.number.isRequired,
    scrollTop: PropTypes.number.isRequired,
    clientHeight: PropTypes.number.isRequired
  }

  static defaultProps = {
    connection: null,
    scrollHeight: null,
    scrollTop: null,
    clientHeight: null
  }

  constructor(props) {
    super(props)
    this.state = { page: 0, height: 0 }
  }

  componentDidMount = () => {
    const { page } = this.state
    const { connection, fetching } = this.props
    const { history } = connection || {}
    let newState = {}
    if (this.state.height === 0) { newState.height = window.innerHeight }
    if (connection && !history && !fetching && page === 0) { 
      // Load the history when focusing on this tab and no history was present
      this.getConnectionHistory()
    } else if (connection && history) {
      // Previous history was present and the tab was focused again, set the page
      const newPage = history.length / RaixerConfig.connections.historyPageSize
      if (history.length % RaixerConfig.connections.historyPageSize === 0) { newState.page = newPage }
      // else { this.setState({ page: null }) }
    }
    this.setState(newState)
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentDidUpdate = (prevProps) => {
    const { page } = this.state
    const { connection: prevConnection, scrollTop: prevScrollTop } = prevProps
    const { history: prevHistory } = prevConnection || {}
    const { connection, fetchingConnection, fetching, clientHeight, scrollTop, scrollHeight } = this.props
    const { history } = connection || {}
    if (connection && !history && !fetchingConnection && page === 0) { 
      // Load the history when focusing on this tab and no history was present
      this.getConnectionHistory()
    }
    if (prevHistory && !history && !fetchingConnection) {
      // Refreshed connection with history. Retrieve history again
      this.getConnectionHistory(true)
    }
    // Update historyPage to null if no more history can be retrieved
    if (!fetching && page !== null) {
      if (prevHistory && history) {
        if (history.length - prevHistory.length < RaixerConfig.connections.historyPageSize) {
          // this.setState({ page: null })
        }
      } else if (!prevHistory && history) {
        if (history.length < RaixerConfig.connections.historyPageSize) {
          // this.setState({ page: null })
        }
      }
    }
    if (
      scrollHeight - scrollTop > clientHeight - 50 && scrollHeight - scrollTop < clientHeight + 50 && 
      scrollTop !== prevScrollTop && !fetching
    ) {
      // Scrolled on the view
      this.getConnectionHistory()
    }
  }

  updateWindowDimensions = () => {
    this.setState({ height: window.innerHeight })
  }

  getConnectionHistory = (reset = false) => {
    const { page } = this.state
    const pageSize = this.getPageSize()
    const { connection } = this.props
    const { _id, device } = connection
    const { trialEnded } = device || {}
    if (page !== null && !trialEnded) {
      this.props.getConnectionHistory(_id, reset ? 0 : page, pageSize)
      this.setState({ page: reset ? 1 : page + 1 })
    }
  }

  getPageSize = () => {
    const { height } = this.state
    const windowHeight = height || window.innerHeight
    return windowHeight > 1200 ? 40 : 10
  }

  goToConnectionUpgrade = () => {
    const { connection } = this.props
    const { _id } = connection || {}
    const isWifiDevice = connectionIsWifiDevice(connection)
    const plan = {
      type: isWifiDevice ? 'intercom' : 'access'
    }
    FormModalService.open('upgrade', { connectionId: _id, plan })
  }

  render = () => {
    const { connection } = this.props
    return (
      <History
        connection={connection}
        activeLang={this.props.activeLang}
        fetching={this.props.fetching}
        goToConnectionUpgrade={this.goToConnectionUpgrade}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    activeLang: state.i18n.activeLang,
    fetchingConnection: state.connections.fetchingConnection,
    fetching: state.connections.fetchingConnectionTab
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConnectionHistory: (connectionId, page, pageSize) =>
      dispatch(ConnectionsActions.connectionHistoryRequest(connectionId, page, pageSize)),
    clearConnectionHistory: (connectionId) => dispatch(ConnectionsActions.connectionHistoryClearRequest(connectionId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTab)

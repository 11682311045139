import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthActions from '../../Redux/AuthRedux'
import AuthBackground from '../../Components/UI/Backgrounds/AuthBackground'
import Login from '../../Components/Screens/Auth/Login'
import styles from '../Styles/Screens'

class LoginScreen extends Component {

  onSubmit = (value) => {
    const { email, password } = value
    this.props.login(email, password)
  }

  onFacebookPress = () => {
    this.props.facebookLogin()
  }

  goToSignup = () => {
    this.props.history.push('/signup')
  }

  goToRecover = () => {
    this.props.history.push('/recover')
  }

  render = () => {
    return (
      <div style={styles.mainContainer}>
        <AuthBackground />
        <div style={styles.authContainer}>
          <Login
            onSubmit={this.onSubmit}
            onFacebookPress={this.onFacebookPress}
            goToSignup={this.goToSignup}
            goToRecover={this.goToRecover}
            fetching={this.props.fetching}
            activeLang={this.props.activeLang}
          />
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    fetching: state.auth.fetching,
    activeLang: state.i18n.activeLang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(AuthActions.loginRequest(email, password)),
    facebookLogin: () => dispatch(AuthActions.facebookRequest())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
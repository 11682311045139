import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SegmentedControl } from 'segmented-control-react'
import styled from 'styled-components'
import AppText from '../Texts/AppText'
import styles from '../../Styles/UI/Tabs'

const StyledDiv = styled.div `
  .segmentedControl .r-segmented-control {
    background: none;
    border: 0 !important;
    height: auto;
    box-sizing: inherit;
    direction: inherit;
    display: block;
  }
  .segmentedControl .r-segmented-control ul {
    padding: 0;
    margin: 0;
  }
  .segmentedControl .r-segmented-control ul li {
    ${styles.tab}
    ${styles.tabText}
  }
  .segmentedControl .r-segmented-control ul li.selected {
    ${styles.tabActive}
    ${styles.tabTextActive}
  }
`

export default class SegmentedTabs extends Component {

  static propTypes = {
    tabs: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string,
    customTabsContainerStyle: PropTypes.object
  }

  static defaultProps = {
    tabs: null,
    onChange: null,
    text: null,
    customTabsContainerStyle: {}
  }

  constructor(props) {
    super(props)
    this.state = {
      index: 0
    }
  }

  onChange = (index) => {
    this.props.onChange(index)
    this.setState({ index })
  }

  render = () => {
    return (
      <div style={Object.assign({}, styles.tabsContainer, this.props.customTabsContainerStyle)}>
        {
          this.props.text &&
          <AppText
            text={this.props.text}
            customStyle={styles.tabsDescription}
          />
        }
        <StyledDiv>
          <div className='segmentedControl'>
            <SegmentedControl
              segments={this.props.tabs.map(t => ({ name: t }))}
              selected={this.state.index}
              onChangeSegment={(index) => this.onChange(index)}
            />
          </div>
        </StyledDiv>
        {
          /*
          <SegmentedControlTab
            values={this.props.tabs}
            selectedIndex={this.state.index}
            tabStyle={styles.tab}
            tabTextStyle={styles.tabText}
            activeTabStyle={styles.tabActive}
            activeTabTextStyle={styles.tabTextActive}
            onTabPress={(index) => this.onChange(index)}
          />
          */
        }
      </div>
    )
  }

}

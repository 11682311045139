import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SpinnerIndicator from '../Indicators/SpinnerIndicator'
import { Button, Icon } from '@material-ui/core'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Colors, Metrics } from '../../../Themes'
import styles from '../../Styles/UI/Button'

export default class BaseButton extends Component {

  static propTypes = {
    variant: PropTypes.string,
    themeColor: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    icon: PropTypes.string,
    iconSize: PropTypes.string,
    buttonStyle: PropTypes.object,
    iconStyle: PropTypes.object,
    withIndicator: PropTypes.bool,
    indicatorColor: PropTypes.string,
    fetching: PropTypes.bool,
    fullWidth: PropTypes.bool,
    numberOfLines: PropTypes.number
  }

  static defaultProps = {
    variant: 'contained',
    themeColor: 'primary',
    buttonText: null,
    onPress: null,
    disabled: null,
    icon: null,
    iconSize: Metrics.icons.regular,
    buttonStyle: {},
    iconStyle: {},
    withIndicator: false,
    indicatorColor: Colors.white,
    fetching: false,
    fullWidth: true,
    numberOfLines: 1
  }

  getIcon = () => {
    if (!this.props.icon) { return null }
    if (this.props.buttonText.trim() === '' && this.props.fetching) { return null }
    let iconStyles = Object.assign({}, styles.buttonIcon, this.props.iconStyle)
    if (this.props.buttonText.trim() === '') { iconStyles = Object.assign({}, iconStyles, { marginRight: 0 }) }
    if (this.props.icon === 'add-circle') { return <AddCircleIcon style={iconStyles} /> }
    if (this.props.icon === 'keyboard-return') { return <KeyboardReturnIcon style={iconStyles} /> }
    return (
      <Icon style={iconStyles}  >
        { this.props.icon}
      </Icon>
    )
  }

  getIndicator = () => {
    if (!this.props.withIndicator || !this.props.fetching) { return null }
    return (
      <SpinnerIndicator 
        color={this.props.indicatorColor} 
        containerStyle={Object.assign({}, styles.buttonIndicator, { marginLeft: this.props.buttonText.trim() === '' ? 0 : Metrics.baseMargin })}
      />
    )
  }

  render = () => {
    let disabled = this.props.disabled
    if (this.props.withIndicator) { disabled = disabled || this.props.fetching }
    let style = Object.assign({}, styles.buttonContainer, this.props.buttonStyle)
    if (disabled) {
      if (this.props.variant === 'contained') { 
        style.background = Colors.theme1 + '99' 
        style.color = Colors.white + '99'
      } else if (this.props.variant === 'outlined') { 
        style.borderColor = Colors.theme1 + '99' 
        style.color = Colors.theme1 + '99'
      }
    }
    return (
      <Button 
        variant={this.props.variant}
        color={this.props.themeColor}
        disabled={disabled}
        startIcon={this.getIcon()}
        endIcon={this.getIndicator()}
        style={style}
        fullWidth={this.props.fullWidth}
        onClick={this.props.onPress}
        classes={this.props.classes}
      >
        { this.props.buttonText }
      </Button>
    )
  }

}

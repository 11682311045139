import { call, put, select } from 'redux-saga/effects'
import { navigate/*, goBack, openDrawer, pop, popToTop */ } from '../Navigation/RootNavigation'
import RaixerConfig from '../Config/RaixerConfig'
import I18n from '../i18n/i18n'
import StartupActions from '../Redux/StartupRedux'
import AuthActions from '../Redux/AuthRedux'
import ConfigurationActions from '../Redux/ConfigurationRedux'
import UserActions from '../Redux/UserRedux'
import { I18nSelectors } from '../Redux/I18nRedux'
import { setActiveLang } from './I18nSagas'
import { AlertService } from '../Services/Alert'
import { FacebookService } from '../Services/Facebook'
import { delay } from '../Lib/DateTimeUtils'

export function * login (api, action) {
  const { email, password } = action
  const response = yield call(api.login, email, password)
  if (response.ok) {
    const { data } = response
    const { _id: userId, token, refreshToken, user, configurations = [] } = data
    try {
      yield call([localStorage, 'setItem'], '@RaixerLocalStorage:userId', userId)
      yield call([localStorage, 'setItem'], '@RaixerLocalStorage:token', token)
      yield call([localStorage, 'setItem'], '@RaixerLocalStorage:refreshToken', refreshToken)
      yield call(api.setTokenHeader, token)
      yield call(delay, RaixerConfig.delays.logout)
      yield call(setActiveLang)
      for (let c of configurations) { yield put(ConfigurationActions.configurationFinish(c, null)) }
      yield put(UserActions.userRequestFinished(user, null))
      yield put(AuthActions.loginSuccess(userId, token, refreshToken))
      navigate('dashboard')
    } catch (err) {
      yield put(AuthActions.authFinished())
      AlertService.error(I18n.t('snacks.errors.asyncStorage'))
    }
  } else {
    yield put(AuthActions.authFinished())
    AlertService.error(I18n.t('snacks.errors.login'))
  }
}

export function * logout (api) {
  yield call(api.removeTokenHeader)
  yield call([localStorage, 'removeItem'], '@RaixerLocalStorage:userId')
  yield call([localStorage, 'removeItem'], '@RaixerLocalStorage:token')
  yield call([localStorage, 'removeItem'], '@RaixerLocalStorage:refreshToken')
  yield call([localStorage, 'removeItem'], '@RaixerLocalStorage:connections')
  yield call(delay, RaixerConfig.delays.logout)
  yield put(AuthActions.logoutSuccess())
  yield put(StartupActions.startupFinished())
  navigate('')
}

export function * register (api, action) {
  const { displayName, email, password } = action
  const activeLang = yield select(I18nSelectors.selectActiveLang)
  const response = yield call(api.register, displayName, email, password, activeLang)
  if (response.ok) {
    yield put(AuthActions.authFinished())
    AlertService.success(I18n.t('snacks.success.register'))
  } else {
    yield put(AuthActions.authFinished())
    AlertService.error(I18n.t('snacks.errors.register'))
  }
}

export function * facebookLogin (api) {
  const responseLogin = yield call(FacebookService.login)
  const { ok, data } = responseLogin
  if (ok) {
    const { email, name, userId: facebookUserId, accessToken } = data
    yield put(AuthActions.facebookUpdate(true))
    const responseAPI = yield call(api.facebookLogin, email, name, facebookUserId, accessToken)
    const { ok: okAPI, data: dataAPI } = responseAPI
    if (okAPI) {
      const { _id: userId, token, refreshToken, user, configurations = [] } = dataAPI
      try {
        yield call([localStorage, 'setItem'], '@RaixerLocalStorage:userId', userId)
        yield call([localStorage, 'setItem'], '@RaixerLocalStorage:token', token)
        yield call([localStorage, 'setItem'], '@RaixerLocalStorage:refreshToken', refreshToken)
        yield call(api.setTokenHeader, token)
        yield call(delay, RaixerConfig.delays.logout)
        yield call(setActiveLang)
        for (let c of configurations) { yield put(ConfigurationActions.configurationFinish(c, null)) }
        yield put(UserActions.userRequestFinished(user, null))
        yield put(AuthActions.loginSuccess(userId, token, refreshToken))
        navigate('dashboard')
      } catch (err) {
        yield put(AuthActions.authFinished())
        AlertService.error(I18n.t('snacks.errors.asyncStorage'))
      }
    } else {
      yield put(AuthActions.authFinished())
      AlertService.error(I18n.t('snacks.errors.facebook'))
    }
  } else {
    AlertService.error(I18n.t('snacks.errors.facebook'))
  }
}

export function * recover (api, action) {
  const { email, password } = action
  const activeLang = yield select(I18nSelectors.selectActiveLang)
  const response = yield call(api.recover, email, password, activeLang)
  if (response.ok) {
    yield put(AuthActions.authFinished())
    AlertService.success(I18n.t('snacks.success.recover'))
  } else {
    yield put(AuthActions.authFinished())
    AlertService.error(I18n.t('snacks.errors.recover'))
  }
}

export function * appleLoginCallback (api, action) {
  const { user } = action
  const { _id: userId, token, refreshToken, configurations = [] } = user
  try {
    yield call([localStorage, 'setItem'], '@RaixerLocalStorage:userId', userId)
    yield call([localStorage, 'setItem'], '@RaixerLocalStorage:token', token)
    yield call([localStorage, 'setItem'], '@RaixerLocalStorage:refreshToken', refreshToken)
    yield call(api.setTokenHeader, token)
    yield call(delay, RaixerConfig.delays.logout)
    yield call(setActiveLang)
    for (let c of configurations) { yield put(ConfigurationActions.configurationFinish(c, null)) }
    yield put(UserActions.userRequestFinished(user, null))
    yield put(AuthActions.loginSuccess(userId, token, refreshToken))
    navigate('dashboard')
  } catch (err) {
    yield put(AuthActions.authFinished())
    AlertService.error(I18n.t('snacks.errors.asyncStorage'))
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from '../../../../i18n/i18n'
import AppText from '../../../UI/Texts/AppText'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import GhostButton from '../../../UI/Buttons/GhostButton'
import FullScreenLoader from '../../../UI/Loaders/FullScreenLoader'
import { Metrics } from '../../../../Themes'

export default class GuestBook extends Component {

  static propTypes = {
    fetching: PropTypes.bool.isRequired,
    connection: PropTypes.object,
    sendGuestBook: PropTypes.func.isRequired
  }

  static defaultProps = {
    fetching: null,
    connection: null,
    sendGuestBook: null
  }

  render = () => {
    const { fetching, connection } = this.props
    const { location } = connection || {}
    const { guestBookUri = '' } = location || {}
    return (
      <>
        <ContentCard
          title={I18n.t('screens.guestBook.title')}
          renderContent={() => {
            if (fetching && guestBookUri === '') { return <FullScreenLoader visible={true} /> }
            else if (!fetching && location && guestBookUri === '') { return <AppText text={I18n.t('screens.guestBook.textEmpty')} /> }
            return (
              <iframe
                src={guestBookUri}
                id={'guest-book-iframe'}
                title={'guest-book'}
                width={'100%'}
                height={'600px'}
                style={{ marginTop: Metrics.baseMargin, border: 0 }}
              />
            )
          }}
          renderButtons={() => (
            <>
              {
                location && guestBookUri !== '' &&
                <GhostButton
                  buttonText={I18n.t('screens.guestBook.send')}
                  onPress={this.props.sendGuestBook}
                  disabled={fetching}
                  icon={'mail'}
                  withIndicator={true}
                  fetching={fetching}
                  customButtonStyle={{ margin: 0, marginBottom: 0 }}
                />
              }
            </>
          )}
        />
      </>
    )
  }

}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import RaixerConfig from '../../../Config/RaixerConfig'
import { 
  connectionIsWifiDevice,
  connectionIsSubscribed,
} from '../../../Lib/ConnectionsUtils'
import { ModalService } from '../../../Services/Modal'
import { FormModalService } from '../../../Services/FormModal'
import { AlertService } from '../../../Services/Alert'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import I18n from '../../../i18n/i18n'
import Codes from '../../../Components/Screens/Connection/Tabs/Codes'

class CodesTab extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired
  }

  static defaultProps = {
    connection: null
  }

  goToDoorCode = (doorId, isEdit, doorName, alias, url) => {
    const { _id } = this.props.connection || {}
    FormModalService.open('door-code', { connectionId: _id, doorId, isEdit, doorName, alias, url })
  }

  goToDoorAuthorizedCode = (doorId, code) => {
    const { _id } = this.props.connection || {}
    FormModalService.open('authorized-code', { connectionId: _id, doorId, code })
  }

  onPressShareCode = (url, code) => {
    const text = I18n.t('modals.shareDoorCode.text') + '\n\n' + 
      `${I18n.t('shares.doorCode.message')} ${url}${code ? ` ${I18n.t('shares.doorCode.message2')} ${code}` : ''}`
    ModalService.show(
      I18n.t('modals.shareDoorCode.title'),
      text,
      null,
      null,
      I18n.t('modals.buttons.defaultOk')
    )
  }

  onPressOpenBrowser = (accessURL) => {
    window.open(accessURL + '?lang=' + this.props.activeLang, '_blank')
  }

  onPressCopy = (text) => {
    copy(text)
    AlertService.success(I18n.t('snacks.success.connectionsDetailCopyDoorCode'))
  }

  onCodeEnabledChange = (doorId, newValue) => {
    const { _id } = this.props.connection || {}
    if (newValue) { this.props.setDoorCodeStatus(_id, doorId, newValue) }
    else {
      ModalService.show(
        I18n.t('modals.disableDoorCodeAccess.title'),
        I18n.t('modals.disableDoorCodeAccess.text'),
        () => { this.props.setDoorCodeStatus(_id, doorId, newValue) },
        I18n.t('modals.disableDoorCodeAccess.buttons.ok'),
        null,
        true
      )
    }
  }

  onAnyCallerEnabledChangeCode = (doorId, newValue) => {
    const { connection } = this.props
    const { _id, device } = connection || {}
    const { trialEnded = false, exhibitor = false } = device || {}
    const isSubscribed = connectionIsSubscribed(connection)
    if (exhibitor) {
      this.showModalPhoneUpgrade(connection)
    } else {
      if (!trialEnded) {
        if (isSubscribed) {
          if (newValue) {
            ModalService.show(
              I18n.t('modals.enableDoorAnyCodeAccess.title'),
              I18n.t('modals.enableDoorAnyCodeAccess.text'),
              () => { this.props.setDoorCodeAnyCallerStatus(_id, doorId, newValue) },
              I18n.t('modals.enableDoorAnyCodeAccess.buttons.ok'),
              null,
              true
            )
          } else {
            this.props.setDoorCodeAnyCallerStatus(_id, doorId, newValue)
          }
        } else {
          this.showModalPhoneUpgrade(connection)
        }
      } else {
        this.showModalPhoneUpgrade(connection)
      }
    }
  }

  showModalPhoneUpgrade = (connection) => {
    const { _id, device = {}, owner: connectionOwner } = connection
    const { owner: deviceOwner, exhibitor } = device
    if (exhibitor) {
      ModalService.show(
        I18n.t('modals.upgradeFeatureExhibitor.title'), 
        I18n.t('modals.upgradeFeatureExhibitor.text')
      )
    } else {
      if (deviceOwner === connectionOwner) {
        const isWifiDevice = connectionIsWifiDevice(connection)
        const plan = { type: isWifiDevice ? 'intercom' : 'access' }
        ModalService.show(
          I18n.t('modals.upgradePhoneFeatureOwner.title'), 
          I18n.t('modals.upgradePhoneFeatureOwner.text'),
          () => { FormModalService.open('upgrade', { connectionId: _id, plan }) },
          I18n.t('modals.upgradePhoneFeatureOwner.buttons.ok')
        )
      } else {
        ModalService.show(
          I18n.t('modals.upgradePhoneFeatureNotOwner.title'), 
          I18n.t('modals.upgradePhoneFeatureNotOwner.text')
        )
      }
    }
  }

  onPressGetQr = (deviceId, doorId) => {
    window.open(RaixerConfig.api.baseUrl + 'devices/' + deviceId + '/doors/' + doorId + '/code', '_blank')
  }

  render = () => {
    return (
      <Codes
        isUserAdmin={this.props.connection ? this.props.connection.profile === 'admin' : false}
        connection={this.props.connection}
        activeLang={this.props.activeLang}
        fetchingTask={this.props.fetchingConnectionForm}
        goToDoorCode={this.goToDoorCode}
        goToDoorAuthorizedCode={this.goToDoorAuthorizedCode}
        onPressShareCode={this.onPressShareCode}
        onPressOpenBrowser={this.onPressOpenBrowser}
        onPressCopy={this.onPressCopy}
        onCodeEnabledChange={this.onCodeEnabledChange}
        onAnyCallerEnabledChangeCode={this.onAnyCallerEnabledChangeCode}
        onPressGetQr={this.onPressGetQr}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    activeLang: state.i18n.activeLang,
    fetchingConnectionForm: state.connections.fetchingConnectionForm,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDoorCodeStatus: (connectionId, doorId, status) =>
      dispatch(ConnectionsActions.connectionDoorCodeStatusRequest(connectionId, doorId, status)),
    setDoorCodeAnyCallerStatus: (connectionId, doorId, status) =>
      dispatch(ConnectionsActions.connectionDoorCodeAnyCallerStatusRequest(connectionId, doorId, status))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CodesTab)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import BookingsHistory from '../../../Components/Screens/CheckIn/Forms/BookingsHistory'
import RaixerConfig from '../../../Config/RaixerConfig'
import { getActualConnection } from '../../../Lib/ConnectionsUtils'
import { FormModalService } from '../../../Services/FormModal'

class BookingsHistoryScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      locationId: null,
      bookingPage: 0,
    }
  }

  componentDidMount = () => {
    const { connectionId, locationId } = this.props
    this.setState({ connectionId, locationId })
    this.getBookings(connectionId, locationId)
  }

  componentDidUpdate = (prevProps) => {
    const { scrollHeight, scrollTop, clientHeight } = this.props
    const { bookingPage, connectionId } = this.state
    const { connections: connectionsPrev, fetching: fetchingPrev } = prevProps
    const { connections, fetching } = this.props
    const connectionPrev = getActualConnection(connectionsPrev, connectionId)
    const connection = getActualConnection(connections, connectionId)
    const { location: prevLocation } = connectionPrev
    const { bookingsHistory: prevBookings } = prevLocation || {}
    const { location } = connection
    const { bookingsHistory: bookings } = location || {}
    let shouldStopLoading = false
    if (fetchingPrev && !fetching && bookingPage !== null) {
      if (prevBookings && bookings) {
        if (bookings.length - prevBookings.length < RaixerConfig.connections.historyPageSize) {
          shouldStopLoading = true
          this.setState({ bookingPage: null })
        }
      } else if (!prevBookings && bookings) {
        if (bookings.length < RaixerConfig.connections.historyPageSize) {
          shouldStopLoading = true
          this.setState({ bookingPage: null })
        }
      }
    }
    if (
      scrollHeight - scrollTop > clientHeight - 50 && scrollHeight - scrollTop < clientHeight + 50 && !fetching && bookingPage > 0 && !shouldStopLoading
    ) {
      this.getBookings(this.state.connectionId, this.state.locationId)
    }
  }

  getBookings = (connectionId, locationId) => {
    this.props.getBookings(connectionId, locationId, this.state.bookingPage)
    this.setState({ bookingPage: this.state.bookingPage + 1 })
  }

  goToGuestForm = (bookingId) => {
    FormModalService.replaceWithHistory('guest', { connectionId: this.state.connectionId, bookingId })
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    const { location = {} } = connection || {}
    const { bookingsHistory = [] } = location
    return (
      <BookingsHistory
        fetching= {this.props.fetching}
        bookingsHistory={bookingsHistory}
        goToGuestForm={this.goToGuestForm}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    fetching: state.connections.fetchingConnectionForm
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBookings: (connectionId, locationId, page) => 
      dispatch(ConnectionsActions.connectionGetBookingsRequest(connectionId, locationId, page, true))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingsHistoryScreen)
// leave off @2x/@3x
const images = {
  loader: require('../Images/Animations/loader.json'),
  loaderGears: require('../Images/Animations/loader-gears.json'),
  // noSignal: require('../Images/Animations/no-signal.json'),
  // boxOpen: require('../Images/Animations/box-open.json'),
  // scanner: require('../Images/Animations/scanner.json'),
  searchEmpty: require('../Images/Animations/search-empty.json'),
  // TODO: check use
  // list: require('../Images/Animations/list.json'),
  pageConstruction: require('../Images/Animations/page-construction.json'),
  logo: require('../Images/raixer.png'),
  logoIcon: require('../Images/raixer-icon.png'),
  logoIconWhite: require('../Images/raixer-icon-white.png'),
  // logoWhite: require('../Images/raixer-white.png'),
  // mastercard: require('../Images/mastercard.png'),
  // visa: require('../Images/visa.png'),
  // amex: require('../Images/amex.png'),
  // intercomV4Listening: require('../Images/intercom-v4-listening.gif'),
  // intercomV4Normal: require('../Images/intercom-v4-normal.gif'),
  // intercomConnectionNone: require('../Images/intercom-connection-none.png'),
  // intercomConnectionRight: require('../Images/intercom-connection-right.png'),
  // intercomConnectionLeft: require('../Images/intercom-connection-left.png'),
  // intercomSensorRight: require('../Images/intercom-sensor-right.png'),
  // intercomSensorLeft: require('../Images/intercom-sensor-left.png'),
  // intercomV4ConnectionNone: require('../Images/intercom-v4-connection-none.png'),
  // intercomV4ConnectionRight: require('../Images/intercom-v4-connection-down.png'),
  // intercomV4ConnectionLeft: require('../Images/intercom-v4-connection-up.png'),
  // intercomV4SensorRight: require('../Images/intercom-v4-sensor-right.png'),
  // intercomV4SensorLeft: require('../Images/intercom-v4-sensor-left.png'),
  // intercomV3ConnectionNone: require('../Images/intercom-v3-connection-none.png'),
  // intercomV3ConnectionRight: require('../Images/intercom-v3-connection-down.png'),
  // intercomV3ConnectionLeft: require('../Images/intercom-v3-connection-up.png'),
  // intercomV3ConnectionMiddle: require('../Images/intercom-v3-connection-middle.png'),
  // intercomV3SensorRight: require('../Images/intercom-v3-sensor-right.png'),
  // intercomV3SensorLeft: require('../Images/intercom-v3-sensor-left.png'),
  // intercomV3SensorMiddle: require('../Images/intercom-v3-sensor-middle.png'),
  // intercomV6ConnectionNone: require('../Images/intercom-v6-connection-none.png'),
  // intercomV6ConnectionRight: require('../Images/intercom-v6-connection-down.png'),
  // intercomV6ConnectionLeft: require('../Images/intercom-v6-connection-up.png'),
  // intercomV6SensorRight: require('../Images/intercom-v6-sensor-right.png'),
  // intercomV6SensorLeft: require('../Images/intercom-v6-sensor-left.png'),
  // intercomV6SensorMove: require('../Images/intercom-v6-sensor-move.png'),
  // setupWifi: require('../Images/setup-wifi.png'),
  // lightIndicator: require('../Images/light-indicator.png'),
  // resetIndicator: require('../Images/reset-indicator.png'),
  guesty: require('../Images/guesty-logo.png'),
  onboarding: {
    en: {
      autoAccess: require('../Images/onboarding-auto-access.en.png'),
      phones: require('../Images/onboarding-phones.en.png'),
      users: require('../Images/onboarding-users.en.png'),
      history: require('../Images/onboarding-history.en.png')
    },
    es: {
      autoAccess: require('../Images/onboarding-auto-access.es.png'),
      phones: require('../Images/onboarding-phones.es.png'),
      users: require('../Images/onboarding-users.es.png'),
      history: require('../Images/onboarding-history.es.png')
    }
  },
  store: {
    en: {
      playStore: require('../Images/google-play-badge.en.png'),
      appStore: require('../Images/app-store-badge.en.svg')
    },
    es: {
      playStore: require('../Images/google-play-badge.es.png'),
      appStore: require('../Images/app-store-badge.es.svg')
    }
  },
  list: {
    online: {
      miniV1V2: require('../Images/List/Online/miniV1V2.png'),
      mini: require('../Images/List/Online/mini.png'),
      pro: require('../Images/List/Online/pro.png'),
      twelveV: require('../Images/List/Online/twelveV.png'),
      gyro: require('../Images/List/Online/gyro.png'),
      passport: require('../Images/List/Online/passport.png'),
      proWithGyro: require('../Images/List/Online/proWithGyro.png'),
      miniV5Gyro: require('../Images/List/Online/miniV5Gyro.png'),
    },
    offline: {
      miniV1V2: require('../Images/List/Offline/miniV1V2.png'),
      mini: require('../Images/List/Offline/mini.png'),
      pro: require('../Images/List/Offline/pro.png'),
      twelveV: require('../Images/List/Offline/twelveV.png'),
      gyro: require('../Images/List/Offline/gyro.png'),
      passport: require('../Images/List/Online/passport.png'),
      proWithGyro: require('../Images/List/Offline/proWithGyro.png'),
      miniV5Gyro: require('../Images/List/Offline/miniV5Gyro.png'),
    }
  },
}

export default images

const metrics = {
  baseMeasure: 8,
  svgIconPositionTop: 2 + 'px',
  svgIconPositionLeft: 2 + 'px',
  smallMargin: 4 + 'px',
  baseMargin: 8 + 'px',
  baseMarginPlus: 12 + 'px',
  doubleBaseMargin: 16 + 'px',
  doubleBaseMarginPlus: 20 + 'px',
  tripleBaseMargin: 24 + 'px',
  tripleBaseMarginPlus: 28 + 'px',
  cuadrupleBaseMargin: 32 + 'px',
  buttonRadius: 8 + 'px',
  buttonBorderWidth: 1 + 'px',
  menuBorderWidth: 2 + 'px',
  inputBorderWidth: 1 + 'px',
  listItemBorderWidth: 1 + 'px',
  historyItemBorderWidth: 2 + 'px',
  baseMenuWidth: 90 + 'px',
  appContainerPadding: 182 + 'px',
  icons: {
    xs: 8 + 'px',
    s: 12 + 'px',
    regular: 16 + 'px',
    big: 20 + 'px',
    bigMedium: 24 + 'px',
    bigger: 28 + 'px',
    large: 48 + 'px'
  },
  zIndexes: {
    splashContainer: 9,
    authContainer: 1,
    modalContainer: 5,
    formModalContainer: 4,
    leftMenu: 3,
    connectionsList: 2,
    fullScreenLoader: 20,
    snackbar: 10,
    tabbar: 1
  }
}

export default metrics

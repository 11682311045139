import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThemeProvider } from '@material-ui/styles'
import SplashScreen from '../Containers/Splash/SplashScreen'
import ModalScreen from '../Containers/Modals/ModalScreen'
import FormModalScreen from '../Containers/Modals/FormModalScreen'
import AppNavigation from '../Navigation/AppNavigation'
import Snackbar from '../Components/UI/Snackbar/Snackbar'
import { AlertService } from '../Services/Alert'
import { ApplicationStyles, Theme } from '../Themes'
import StartupActions from '../Redux/StartupRedux'

class RootContainer extends Component {

  componentDidMount = () => {
    this.props.startup()
    for (let k of Object.keys(ApplicationStyles.body)) {
      document.body.style[k] = ApplicationStyles.body[k]
    }
  }

  render = () => {
    return (
      <ThemeProvider theme={Theme} >
        <SplashScreen />
        { !this.props.startupFetching && <AppNavigation user={this.props.user} /> }
        <Snackbar
          ref={
            (snackBarRef) => { 
              if (snackBarRef) { AlertService.setTopLevelSnackbar(snackBarRef) } 
            }
          }
        />
        <ModalScreen />
        <FormModalScreen />
      </ThemeProvider>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    user: state.auth.userId,
    startupFetching: state.startup.fetching
  }
}

const mapDispatchToProps = (dispatch) => ({
  startup: () => dispatch(StartupActions.startup())
})

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer)

import { call } from 'redux-saga/effects'
import { isStringJSONValid, isArrayValid } from '../Lib/ObjectsUtils'

export function * updateConnectionsOnStorage (action) {
  const { connections = [], startup = false } = action
  if (isArrayValid(connections) && !startup) {
    const storageConnectionsString = yield call([localStorage, 'getItem'], '@RaixerLocalStorage:connections')
    const connectionsString = JSON.stringify(connections)
    if (isStringJSONValid(connectionsString) && storageConnectionsString !== connectionsString) {
      try { yield call([localStorage, 'setItem'], '@RaixerLocalStorage:connections', connectionsString) } 
      catch (err) { /* Do nothing */ }
    }
  }
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ConnectionHeaderAccess from '../../../Components/UI/Connections/ConnectionHeaderAccess'

class HeaderAccess extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired,
    goToCheckIn: PropTypes.func.isRequired,
    inCheckin: PropTypes.bool.isRequired,
    customStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  }

  static defaultProps = {
    connection: null,
    goToCheckIn: null,
    inCheckin: null,
    customStyle: {}
  }

  render = () => {
    return (
      this.props.connection &&
      <ConnectionHeaderAccess
        inCheckin={this.props.inCheckin}
        onPress={this.props.goToCheckIn}
        disabled={this.props.connection.disabled}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    fetching: state.connections.fetchingConnection,
    error: state.connections.errorTask,
    activeLang: state.i18n.activeLang
  }
}

export default connect(mapStateToProps, null)(HeaderAccess)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getActualConnection } from '../../../Lib/ConnectionsUtils'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import DoorPhoneChangeConfirm from '../../../Components/Screens/Connection/Phones/DoorPhoneChangeConfirm'

class DoorPhoneChangeConfirmScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      doorId: null,
      phone: null,
      authorizedPhone: null
    }
  }

  componentDidMount = () => {
    const { connectionId, doorId, phone, authorizedPhone = null } = this.props || {}
    this.setState({ connectionId, doorId, phone, authorizedPhone })
  }

  onPressConfirm = () => {
    const { connectionId, doorId, phone, authorizedPhone } = this.state
    if (authorizedPhone) {
      this.props.navigation.navigate('UpgradeScreen', { connectionId, doorId, plan: { type: 'phone' }, anyCallerPhone: phone, newAuthorizedPhone: authorizedPhone })
    } else {
      this.props.claimPhoneOwnership(connectionId, doorId, phone, authorizedPhone)
    }
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    return (
      <DoorPhoneChangeConfirm
        connection={connection}
        phone={this.state.phone !== null ? '+' + this.state.phone : null}
        savingAuthorizedPhone={this.state.authorizedPhone !== null}
        fetching={this.props.fetching}
        onPressConfirm={this.onPressConfirm}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    fetching: state.connections.fetchingConnectionForm,
    activeLang: state.i18n.activeLang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    claimPhoneOwnership: (connectionId, doorId, anyCallerPhone, newAuthorizedPhone) => 
      dispatch(ConnectionsActions.connectionDoorPhoneClaimOwnershipRequest(connectionId, doorId, anyCallerPhone, newAuthorizedPhone))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoorPhoneChangeConfirmScreen)
import { Metrics } from '../../../../Themes/'

export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    // height: 30
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    justifyContent: 'center',
    paddingRight: Metrics.doubleBaseMargin,
    borderRightWidth: Metrics.historyItemBorderWidth,
    borderRightStyle: 'solid'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 8,
    justifyContent: 'center',
    paddingLeft: Metrics.tripleBaseMargin
  },
  textLastLine: {
    marginTop: Metrics.smallMargin
  }
}
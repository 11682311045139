import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from '../../../../i18n/i18n'
import ConnectionTabTitle from '../../../UI/Connections/ConnectionTabTitle'
import ConnectionsStatus from '../../../UI/Connections/ConnectionsStatus'
import SmallLoader from '../../../UI/Loaders/SmallLoader'
import MainButton from '../../../UI/Buttons/MainButton'
import HistoryListItem from '../../../UI/Lists/HistoryListItem'
import { getBorderColor, getNamesAndActionMsg } from '../../../../Lib/ConnectionHistoryUtils'
import { Metrics } from '../../../../Themes'
import styles from '../../../Styles/Screens/Connection'

export default class History extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired,
    activeLang: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    goToConnectionUpgrade: PropTypes.func.isRequired
  }

  static defaultProps = {
    connection: null,
    activeLang: null,
    fetching: null,
    goToConnectionUpgrade: null
  }

  getContent = (history, trialEnded) => {
    if (history === 'error') { 
      return <ConnectionsStatus text={I18n.t('screens.connection.unexpectedError')} icon={'warning'} /> 
    }
    if (this.props.fetching && history.length === 0) { 
      return <SmallLoader visible={true} /> 
    }
    if (!this.props.fetching && history.length === 0) { 
      if (trialEnded) { 
        return (
          <>
            <ConnectionsStatus text={I18n.t('screens.connection.historyEmptyTrialEnded')} icon={'list'} />
            {
              this.props.isUserOwner &&
              <div style={{ paddingHorizontal: Metrics.doubleBaseMargin }}>
                <MainButton
                  buttonText={I18n.t('shared.pay')}
                  onPress={this.props.goToConnectionUpgrade}
                  disabled={false}
                  icon={'rocket'}
                  customButtonStyle={{ marginVertical: 0, marginBottom: 0, marginTop: Metrics.cuadrupleBaseMargin }}
                />
              </div>
            }
          </>
        )
      } else { 
        return <ConnectionsStatus text={I18n.t('screens.connection.historyEmpty')} icon={'list'} />
      }
    }
    return (
      <>
        {
          history.map((h, i) => {
            const { name, message } = getNamesAndActionMsg(h.action, h.error, this.props.connection, this.props.activeLang)
            return (
              <HistoryListItem
                key={'history-' + i}
                message={message}
                borderColor={getBorderColor(h.action, h.error)}
                name={name}
                details={h.details}
                date={h.created}
                dateEnd={h.ended}
                lastOfList={(i + 1) === history.length}
              />
            )
          })
        }
        <SmallLoader visible={this.props.fetching} />
      </>
    )
  }

  render = () => {
    const { device = {}, history = [] } = this.props.connection || {}
    const { guestyListingId, trialEnded = false } = device
    return (
      <div style={styles.mainBodyScroll}>
        <ConnectionTabTitle 
          title={I18n.t('shared.history')} 
          integrationImage={guestyListingId ? 'guesty' : null} 
          onboardingImage={'history'}
        />
        <div style={{ marginTop: Metrics.baseMargin }}>
          { this.getContent(history, trialEnded) }
        </div>
      </div>
    )
  }

}
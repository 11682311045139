import React, { Component } from 'react'
import PropTypes from 'prop-types'
import WarningIcon from '@material-ui/icons/Warning'
import BlockIcon from '@material-ui/icons/Block'
import ReorderIcon from '@material-ui/icons/Reorder'
import AppText from '../Texts/AppText'
import styles from '../../Styles/UI/Connections/Status'
import { Colors, Metrics } from '../../../Themes'

export default class ConnectionsStatus extends Component {

  static propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(
      [
        'warning', 'block', 'list'
      ]
    ),
    withoutMargin: PropTypes.bool
  }

  static defaultProps = {
    text: null,
    icon: null,
    withoutMargin: false
  }

  render = () => {
    const iconStyles = { ...styles.icon, fontSize: Metrics.icons.large, color: Colors.mediumGray }
    return (
      <div style={this.props.withoutMargin ? Object.assign({}, styles.container, { marginTop: 0}) : styles.container}>
        { this.props.icon === 'warning' && <WarningIcon style={iconStyles} /> }
        { this.props.icon === 'block' && <BlockIcon style={iconStyles} /> }
        { this.props.icon === 'list' && <ReorderIcon style={iconStyles} /> }
        <AppText text={this.props.text} customStyle={styles.text}/>
      </div>
    )
  }

}
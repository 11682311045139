import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AuthBackground from '../../UI/Backgrounds/AuthBackground'
import styles from '../../Styles/Screens/Splash'
import { Images } from '../../../Themes'

export default class Splash extends Component {

  static propTypes = {
    visible: PropTypes.bool.isRequired
  }

  static defaultProps = {
    visible: null
  }

  render = () => {
    return (
      <div style={Object.assign({}, styles.container, !this.props.visible ? styles.hidden : {})}>
        <AuthBackground />
        <img src={Images.logo} alt={''} style={styles.logo} />
      </div>
    )
  }

}
import apisauce from 'apisauce'
import RaixerConfig from '../Config/RaixerConfig'
import { retryIfNetworkError } from '../Lib/ApiUtils'

const create = (baseURL = RaixerConfig.api.baseUrl) => {
  const api = apisauce.create({
    baseURL,
    headers: RaixerConfig.api.baseHeaders,
    timeout: RaixerConfig.api.tokenTimeout
  })

  const hasTokenHeader = () => api.headers['x-access-token'] ? true : false
  const setTokenHeader = (token) => api.setHeader('x-access-token', token)
  const setRefreshTokenHeader = (token) => api.setHeader('x-refresh-token', token)
  const removeTokenHeader = () => delete api.headers['x-access-token']
  const removeRefreshTokenHeader = () => delete api.headers['x-refresh-token']
  const validateToken = (userId) => retryIfNetworkError(api.post, 'auth/user/' + userId + '/token/validate')
  const refreshToken = (userId) => retryIfNetworkError(api.post, 'auth/user/' + userId + '/token/refresh')

  return {
    hasTokenHeader,
    setTokenHeader,
    setRefreshTokenHeader,
    removeTokenHeader,
    removeRefreshTokenHeader,
    validateToken,
    refreshToken,
  }
}

export default {
  create
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Splash from '../../Components/Screens/Splash/Splash'

class SplashScreen extends Component {

  render = () => {
    return (
      <Splash visible={this.props.startupFetching} />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    startupFetching: state.startup.fetching
  }
}

export default connect(mapStateToProps)(SplashScreen)
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form/lib'
import templates from 'tcomb-form-templates-bootstrap'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import _ from 'lodash'
import { objectDeepCopy, objectDeepCompare } from '../../../Lib/ObjectsUtils'
import { dateFormat, timeFormat, datetimeFormat } from '../../../Lib/DateTimeUtils'
import MainButton from '../Buttons/MainButton'
import GhostButton from '../Buttons/GhostButton'
import AppText from '../Texts/AppText'
import styles from '../../Styles/UI/Forms'
import { Metrics } from '../../../Themes/'

t.form.Form.templates = templates
const Form = t.form.Form
const StyledDiv = styled.div `${styles}`

export default class BaseForm extends Component {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    fieldsOptions: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    renderExtraContentAfterButtons: PropTypes.func,
    renderExtraContentBeforeButtons: PropTypes.func,
    submitText: PropTypes.string,
    submitIcon: PropTypes.string,
    value: PropTypes.object,
    fetching: PropTypes.bool,
    withoutSubmit: PropTypes.bool,
    submitWithIndicator: PropTypes.bool,
    submitFetchingIndicator: PropTypes.bool,
    submitButtonWithoutMargin: PropTypes.bool,
    submitButtonAsGhostButton: PropTypes.bool,
    submitButtonDisabled: PropTypes.bool,
    optionsAuto: PropTypes.string,
    debounced: PropTypes.bool,
    submitOnEnter: PropTypes.bool
  }

  static defaultProps = {
    onSubmit: null,
    fields: null,
    fieldsOptions: null,
    onChange: null,
    renderExtraContentAfterButtons: null,
    renderExtraContentBeforeButtons: null,
    submitText: null,
    submitIcon: null,
    value: null,
    fetching: false,
    withoutSubmit: false,
    submitWithIndicator: false,
    submitFetchingIndicator: false,
    submitButtonWithoutMargin: false,
    submitButtonAsGhostButton: false,
    submitButtonDisabled: false,
    optionsAuto: 'labels',
    debounced: false,
    submitOnEnter: false
  }

  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      value: {},
      fieldsOptions: {},
      hasDateFields: false
    }
  }

  componentDidMount = () => {
    const { fields, fieldsOptions, value } = this.props
    this.setState({ value: value ? value : this.state.value, fieldsOptions: this.customizeFields(fields, fieldsOptions) })
  }

  componentDidUpdate = (prevProps) => {
    const { value: prevValue, fieldsOptions: prevFieldsOptions } = prevProps
    const { value, fields, fieldsOptions } = this.props
    const { value: stateValue } = this.state
    if (!objectDeepCompare(value, prevValue)) { this.setState({ value }) }
    if (!objectDeepCompare(value, stateValue) && value) { this.setState({ value }) }
    if (!objectDeepCompare(fieldsOptions, prevFieldsOptions)) { 
      this.setState({ fieldsOptions: this.customizeFields(fields, fieldsOptions) })
    }
  }

  customizeFields = (fields, fieldsOptions) => {
    let newFieldsOptions = objectDeepCopy(fieldsOptions)
    const keys = Object.keys(fields)
    for (let k of keys) {
      const field = fields[k]
      const { meta } = field
      const { kind, name } = meta
      const { 
        editable = true, customTemplate = false, hidden = false, mode, 
        customTemplateRenderRightAccessory, customTemplateRenderLeftAccessory 
      } = fieldsOptions[k]
      if (hidden) {
        newFieldsOptions[k].template = (locals) => this.customHiddenInputTemplate({ ...locals })
      } else if ((kind === 'irreducible' || kind === 'subtype') && (mode === 'date' || mode === 'time' || mode === 'datetime') && editable) {
        if (!this.state.hasDateFields) { this.setState({ hasDateFields: true }) }
        newFieldsOptions[k].template = (locals) => this.customDatetimeInputTemplate({
          ...locals, 
          mode: fieldsOptions[k].mode,
          minDate: fieldsOptions[k].minimumDate
          // renderRightAccessory: customTemplateRenderRightAccessory || null,
          // renderLeftAccessory: customTemplateRenderLeftAccessory || null
        })
      } else if ((kind === 'irreducible' || kind === 'subtype') && (name === 'String' || name === 'Number' || !name) && editable && customTemplate) {
        newFieldsOptions[k].template = (locals) => this.customTextInputTemplate({
          ...locals, 
          renderRightAccessory: customTemplateRenderRightAccessory || null,
          renderLeftAccessory: customTemplateRenderLeftAccessory || null
        })
      }
    }
    return newFieldsOptions
  }

  customHiddenInputTemplate = (locals) => {
    const { value } = locals
    return (
      <div style={{ marginBottom: Metrics.baseMargin, display: 'none' }}>
        <TextField
          type={'text'}
          value={value}
        />
      </div>
    )
  }

  customTextInputTemplate = (locals) => {
    // TODO: left accessory
    const { 
      label, value, help, hasError, onChange, multiline = false, type = 'text',
      renderRightAccessory, renderLeftAccessory
    } = locals
    return (
      <div style={{ marginBottom: Metrics.baseMargin }}>
        <TextField
          type={type}
          fullWidth={true}
          label={label}
          value={value}
          error={hasError}
          multiline={multiline}
          onChange={(event) => { onChange(event.target.value) }}
          InputProps={{
            endAdornment: renderRightAccessory ? renderRightAccessory() : null,
            startAdornment: renderLeftAccessory ? renderLeftAccessory() : null
          }}
        />
        { help && <AppText text={help} /> }
      </div>
    )
  }

  customDatetimeInputTemplate = (locals) => {
    const { label, value, help, hasError, onChange, mode, minDate } = locals
    if (mode === 'time') {
      return (
        <div style={{ marginTop: Metrics.doubleBaseMargin, marginBottom: Metrics.baseMargin }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker 
              fullWidth={true}
              allowKeyboardControl={true}
              format={timeFormat}
              ampm={false}
              label={label}
              value={value}
              error={hasError}
              onChange={(date) => { onChange(date.isValid() ? date : null) }}
              onAccept={(date) => { onChange(date.isValid() ? date : null) }}
            />
          </MuiPickersUtilsProvider>
          { help && <AppText text={help} /> }
        </div>
      )
    } else if (mode === 'datetime') {
      return (
        <div style={{ marginTop: Metrics.doubleBaseMargin, marginBottom: Metrics.baseMargin }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker 
              fullWidth={true}
              allowKeyboardControl={true}
              format={datetimeFormat}
              ampm={false}
              label={label}
              value={value}
              error={hasError}
              minDate={minDate}
              onChange={(date) => { onChange(date.isValid() ? date : null) }}
              onAccept={(date) => { onChange(date.isValid() ? date : null) }}
            />
          </MuiPickersUtilsProvider>
          { help && <AppText text={help} /> }
        </div>
      )
    }
    return (
      <div style={{ marginTop: Metrics.doubleBaseMargin, marginBottom: Metrics.baseMargin }}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker 
            fullWidth={true}
            allowKeyboardControl={true}
            format={dateFormat}
            label={label}
            value={value}
            error={hasError}
            minDate={minDate}
            onChange={(date) => { onChange(date.isValid() ? date : null) }}
            onAccept={(date) => { onChange(date.isValid() ? date : null) }}
          />
        </MuiPickersUtilsProvider>
        { help && <AppText text={help} /> }
      </div>
    )
  }

  onChange = (value) => { 
    this.setState({ value }) 
    if (this.props.onChange) { this.props.onChange(value) }
  }

  // TODO: validation fails when there are string date fields
  getValue = () => {
    let value = null
    if (this.state.hasDateFields) { return this.state.value }

    if(this.formRef.current.getValue()){
      return this.formRef.current.getValue()
    }

    if(this.formRef.current?.props?.state) {
      value = this.formRef.current?.props?.state
    } else {
      value = this.state.value
    }

    return value
  }

  onSubmit = () => {
    const value = this.getValue()
    if (value) {
      this.setState({ value })
      this.props.onSubmit(value)
    }
  }

  onSubmitByHTMLForm = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.onSubmit()
  }

  renderForm = () => {
    let customButtonStyle = { marginVertical: 0, marginBottom: 0 }
    if (!this.props.submitButtonWithoutMargin) { customButtonStyle['marginTop'] = Metrics.tripleBaseMargin }
    return (
      <>
        <Form
            ref={this.formRef}
            type={t.struct(this.props.fields)}
            options={{
              auto: this.props.optionsAuto,
              i18n: { optional: '', required: '' },
              fields: this.state.fieldsOptions
            }}
            value={this.state.value}
            onChange={
              this.props.debounced ?
              _.debounce(this.onChange, 500)
              :
              this.onChange
            }
          />
          { this.props.renderExtraContentBeforeButtons && this.props.renderExtraContentBeforeButtons() }
          {
            !this.props.withoutSubmit &&
            <div>
              {
                !this.props.submitButtonAsGhostButton ?
                <MainButton 
                  buttonText={this.props.submitText}
                  onPress={this.onSubmit}
                  disabled={this.props.submitButtonDisabled || this.props.fetching}
                  icon={this.props.submitIcon}
                  customButtonStyle={customButtonStyle}
                  fetching={this.props.submitFetchingIndicator}
                  withIndicator={this.props.submitWithIndicator}
                />
                :
                <GhostButton 
                  buttonText={this.props.submitText}
                  onPress={this.onSubmit}
                  disabled={this.props.submitButtonDisabled || this.props.fetching}
                  icon={this.props.submitIcon}
                  customButtonStyle={customButtonStyle}
                  fetching={this.props.submitFetchingIndicator}
                  withIndicator={this.props.submitWithIndicator}
                />
              }
            </div>
          }
          { this.props.renderExtraContentAfterButtons && this.props.renderExtraContentAfterButtons() }
      </>
    )
  }

  render = () => {
    return (
      <StyledDiv>
        {
          this.props.submitOnEnter
            ? (
              <form onSubmit={this.onSubmitByHTMLForm}>
                { this.renderForm() }
                <button type={'submit'} style={{ display: 'none' }}>
                  { this.props.submitText }
                </button>
              </form>
            ) : (
              <>
                { this.renderForm() }
              </>
            ) }
      </StyledDiv>
    )
  }

}

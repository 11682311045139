import React, { Component } from 'react'
import I18n from '../../i18n/i18n'
import ContentCard from '../../Components/UI/ContentCards/ContentCard'
import AnimatedComponent from '../../Components/UI/Animated/AnimatedComponent'
import AppText from '../../Components/UI/Texts/AppText'
import GhostButton from '../../Components/UI/Buttons/GhostButton'
import { FormModalService } from '../../Services/FormModal'
import { Metrics } from '../../Themes'

export default class WorkInProgressScreen extends Component {

  render = () => {
    return (
      <ContentCard
        title={I18n.t('screens.workInProgress.title')}
        renderContent={() => (
          <>
            <AnimatedComponent source={'pageConstruction'} width={350} height={350} />
            <AppText
              text={I18n.t('screens.workInProgress.text')}
              customStyle={{ marginTop: Metrics.cuadrupleBaseMargin, padding: Metrics.doubleBaseMargin, textAlign: 'center' }}
            />
          </>
        )}
        renderButtons={() => (
          <GhostButton
            buttonText={I18n.t('screens.workInProgress.button')}
            onPress={() => { FormModalService.close() }}
            disabled={false}
            icon={null}
          />
        )}
        withEditButton={false}
      />
    )
  }

}
import { Colors, Fonts, Metrics } from '../../../Themes/'

export default {
  switchContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: Metrics.doubleBaseMargin
  },
  switchLabel: {
    color: Colors.mediumGray,
    fontSize: Fonts.size.small,
    fontWeight: Fonts.weight.regular,
    marginTop: Metrics.doubleBaseMargin,
    marginBottom: Metrics.smallMargin
  },
  switchRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: Metrics.baseMargin,
    flex: 1
  },
  switchHelp: {
    color: Colors.darkGray + ' !important',
    fontSize: Fonts.size.regular + ' !important',
    fontWeight: Fonts.weight.regular + ' !important',
    marginTop: 0,
    marginBottom: 0
  },
  switchHelpDisabled: {
    opacity: 0.6
  },
  switchMargin: {
    marginLeft: Metrics.baseMargin
  },
  switchSeparated: {
    marginLeft: 'auto',
    marginRight: Metrics.baseMargin
  }
}
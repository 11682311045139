import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from '../../../i18n/i18n'
import AppTitle from '../Texts/AppTitle'
import EditButton from '../Buttons/EditButton'
import SimpleIconButton from '../Buttons/SimpleIconButton'
import { Colors, Metrics, Shadows } from '../../../Themes/'
import styles from '../../Styles/UI/Cards'

export default class ContentCard extends Component {

  static propTypes = {
    renderContent: PropTypes.func.isRequired,
    renderContentBeforeTitle: PropTypes.func,
    renderButtons: PropTypes.func,
    withEditButton: PropTypes.bool,
    withShadow: PropTypes.bool,
    toggleEdit: PropTypes.func,
    editing: PropTypes.bool,
    title: PropTypes.string,
    customEditIcon: PropTypes.string,
    customEditText: PropTypes.string,
    titleAction: PropTypes.func,
    customStyle: PropTypes.object,
    customContentStyle: PropTypes.object,
    customTitleStyle: PropTypes.object,
    customTitleAndEditContainerStyle: PropTypes.object,
    customButtonsContainerStyles: PropTypes.object
  }

  static defaultProps = {
    renderContent: null,
    renderContentBeforeTitle: null,
    renderButtons: null,
    withEditButton: false,
    withShadow: false,
    toggleEdit: null,
    editing: false,
    title: null,
    customEditIcon: null,
    customEditText: null,
    titleAction: null,
    customStyle: {},
    customContentStyle: {},
    customTitleStyle: {},
    customTitleAndEditContainerStyle: {},
    customButtonsContainerStyles: {}
  }

  render = () => {
    return (
      <div style={Object.assign({}, styles.cardContainer, this.props.customStyle, this.props.withShadow ? { ...Shadows.card } : {})}>
        <div style={Object.assign({}, styles.card, this.props.customContentStyle)}>
          {
            this.props.renderContentBeforeTitle && this.props.renderContentBeforeTitle()
          }
          {
            this.props.title || this.props.withEditButton ?
            <div style={Object.assign({}, styles.titleAndEditContainer, this.props.customTitleAndEditContainerStyle)}>
              { 
                this.props.title &&
                <div style={styles.titleContainer}>
                  <AppTitle text={this.props.title} customStyle={this.props.customTitleStyle} />
                </div>
              }
              {
                this.props.withEditButton &&
                <div style={styles.editContainer}>
                  <EditButton
                    editText={I18n.t('shared.edit')}
                    closeEditText={I18n.t('shared.done')}
                    onPress={this.props.toggleEdit}
                    editing={this.props.editing}
                    customButtonStyle={{ margin: 0, marginBottom: 0, padding: Metrics.smallMargin }}
                    customIcon={this.props.customEditIcon}
                    customText={this.props.customEditText}
                  />
                </div>
              }
              {
                this.props.titleAction &&
                <div style={styles.editContainer}>
                  <SimpleIconButton
                    onPress={this.props.titleAction}
                    icon={'refresh'}
                    customIconStyle={{ color: Colors.mediumGray }}
                    // customButtonStyle={{ padding: 0 }}
                  />
                </div>
              }
            </div>
            :
            null
          }
          <div style={styles.contentContainer}>
            { this.props.renderContent() }
          </div>
          {
            this.props.renderButtons ?
            <div style={{ ...styles.buttonsContainer, ...this.props.customButtonsContainerStyles }}>
              { this.props.renderButtons() }
            </div>
            :
            null
          }
        </div>
      </div>
    )
  }

}

export const objectDeepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export const arrayDeepCopy = (arr) => {
  let newArr = []
  arr.map( obj => newArr.push(objectDeepCopy(obj)) )
  return newArr
}

export const objectDeepCompare = (a, b) => {
  if (!a || !b) { return a === b }
  return JSON.stringify(Object.entries(a).sort()) === JSON.stringify(Object.entries(b).sort())
}

export const upsertOrDeleteInArrayById = (arr, id, newObject, newId) => {
  let newArr = []
  if (!arr) { arr = [] }
  for (let a of arr) {
    const { _id } = a
    if (newObject) {
      if (_id === id) { newArr.push(newObject) }
      else { newArr.push(a) } 
    } else {
      if (_id !== id) { newArr.push(a) }
    }
  }
  if (!id && newId) { 
    let o = objectDeepCopy(newObject)
    o['_id'] = newId
    newArr.push(o) 
  }
  return newArr
}

export const json2Urlencoded = (element, key, list) => {
  list = list || [];
  if (typeof(element) === 'object') {
    for (var idx in element)
      json2Urlencoded(element[idx], key ? key + '[' + idx + ']' : idx, list);
  } else {
    list.push(key + '=' + encodeURIComponent(element));
  }
  return list.join('&');
}

export const isStringJSONValid = (jsonString) => {
  try {
    const obj = JSON.parse(jsonString)
    if (obj && typeof obj === 'object') { return true }
  } catch { return false }
  return false
}

export const isArrayValid = (arr) => {
  return Array.isArray(arr) && arr.length > 0
}

export const isDefined = (obj) => {
  return obj !== undefined && obj !== null
}
import React from 'react'

export const navigationRef = React.createRef()

export function navigate(name, params = {}) {
  navigationRef.current.history.push('/' + name)
  // navigationRef.current?.history?.push('/' + name)
}

export function goBack() {
  // navigationRef.current?.dispatch(CommonActions.goBack())
}

export function replace(name, params = {}) {
  // navigationRef.current?.dispatch(StackActions.replace(name, params))
}

export function pop(popIndex = 1) {
  // navigationRef.current?.dispatch(StackActions.pop(popIndex))
}

export function popToTop() {
  // navigationRef.current?.dispatch(StackActions.popToTop())
}

export function openDrawer() {
  // navigationRef.current?.dispatch(DrawerActions.openDrawer())
}

export function setParams(params) {
  // navigationRef.current?.setParams(params)
}
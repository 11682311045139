export const emailValidation = (email) => {
  const reg = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  return reg.test(email)
}

export const phoneValidation = (phone) => {
  const reg = /^\+[1-9]{1,3}\d{9,12}$/
  return reg.test(phone)
}

export const creditCardValidation = (creditCard) => {
  if (creditCard.indexOf('*') > -1) {
    const reg = /^\*\*\*\* \*\*\*\* \*\*\*\* \d{4}$/
    return reg.test(creditCard)
  }
  const reg = /^\d{15,16}$/
  return reg.test(creditCard)
}

export const cvcValidation = (cvc) => {
  if (cvc.indexOf('*') > -1) {
    const reg = /^[*]{3,4}$/
    return reg.test(cvc)
  }
  const reg = /^[0-9]{3,4}$/
  return reg.test(cvc)
}

export function validateCifNif(cifnif){
  const reg = /^[a-zA-Z0-9]{1}\d{7}[a-zA-Z0-9]{1}$/
  return reg.test(cifnif)
}

export const codeValidation = (code) => {
  const reg = /^\d{6}$/
  return reg.test(code)
}

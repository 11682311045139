import apisauce from 'apisauce'
import RaixerConfig from '../Config/RaixerConfig'
import { retryIfNetworkError } from '../Lib/ApiUtils'

const create = (baseURL = RaixerConfig.stripe.baseUrl) => {
  const api = apisauce.create({
    baseURL,
    headers: RaixerConfig.stripe.baseHeaders,
    timeout: RaixerConfig.stripe.timeout
  })

  const resetFormHeader = (token) => {
    delete api.headers['Content-Type']
    api.setHeader('Content-Type', 'application/x-www-form-urlencoded')
    api.setHeader('Accept', 'application/x-www-form-urlencoded')
  }
  const resetJsonHeader = (token) => {
    delete api.headers['Content-Type']
    api.setHeader('Content-Type', 'application/json')
    api.setHeader('Accept', 'application/json')
  }
  const getCustomer = (id) => retryIfNetworkError(api.get, 'customers/' + id)
  const addCreditCard = (customerId, card) => retryIfNetworkError(api.post, 'customers/' + customerId + '/sources', card)
  const updateCreditCard = (customerId, cardId, card) => retryIfNetworkError(api.post, 'customers/' + customerId + '/sources/' + cardId, card)
  const deleteCreditCard = (customerId, cardId) => retryIfNetworkError(api.delete, 'customers/' + customerId + '/sources/' + cardId)
  // const addCharge = (charge) => api.post('charges', charge)
  // const getSubscription = (subscriptionId) => api.get('subscriptions/' + subscriptionId)
  // const addSubscription = (subscription) => api.post('subscriptions', subscription)
  // const updateSubscription = (subscriptionId, subscription) => api.post('subscriptions/' + subscriptionId, subscription)
  // const deleteSubscription = (subscriptionId) => api.delete('subscriptions/' + subscriptionId)

  return {
    resetFormHeader,
    resetJsonHeader,
    getCustomer,
    addCreditCard,
    updateCreditCard,
    deleteCreditCard,
    // addCharge,
    // getSubscription,
    // addSubscription,
    // updateSubscription,
    // deleteSubscription
  }
  
}

export default {
  create
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from '../../../../i18n/i18n'
import ConnectionTabTitle from '../../../UI/Connections/ConnectionTabTitle'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import AppText from '../../../UI/Texts/AppText'
import MainButton from '../../../UI/Buttons/MainButton'
import ConnectionDoorPhoneCard from '../../../UI/Connections/ConnectionDoorPhoneCard'
import { Metrics } from '../../../../Themes'
import styles from '../../../Styles/Screens/Connection'

export default class Phones extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired,
    isUserAdmin: PropTypes.bool.isRequired,
    activeLang: PropTypes.string.isRequired,
    fetchingTask: PropTypes.bool.isRequired,
    goToDoorPhoneNumber: PropTypes.func.isRequired,
    goToDoorAuthorizedPhone: PropTypes.func.isRequired,
    goToDoorPhoneNumberInformation: PropTypes.func.isRequired,
    onPressSharePhone: PropTypes.func.isRequired,
    onCallingEnabledChange: PropTypes.func.isRequired,
    onAnyCallerEnabledChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    connection: null,
    isUserAdmin: null,
    activeLang: null,
    fetchingTask: null,
    goToDoorPhoneNumber: null,
    goToDoorAuthorizedPhone: null,
    goToDoorPhoneNumberInformation: null,
    onPressSharePhone: null,
    onCallingEnabledChange: null,
    onAnyCallerEnabledChange: null
  }

  renderDoorWithoutPhone = (key, doorId, doorName, trialEndedAccount) => {
    return (
      <ContentCard
        key={key}
        title={doorName}
        renderContent={() => <AppText text={I18n.t('screens.connection.phones.noPhone')}/>}
        renderButtons={
          () => {
            if (!this.props.isUserAdmin) { return null }
            return (
              <MainButton
                buttonText={I18n.t('screens.connection.phones.getPhone')}
                onPress={() => { this.props.goToDoorPhoneNumber(doorId) }}
                disabled={trialEndedAccount}
                icon={'phone'}
                customButtonStyle={{ margin: 0 }}
              />
            )
          }
        }
      />
    )
  }

  renderDoorWithPhone = (key, door, doorName, trialEnded) => {
    return (
      <ConnectionDoorPhoneCard
        activeLang={this.props.activeLang}
        fetching={this.props.fetchingTask}
        key={key}
        door={door}
        doorName={doorName}
        isUserAdmin={this.props.isUserAdmin}
        trialEnded={trialEnded}
        goToDoorPhoneNumber={this.props.goToDoorPhoneNumber}
        goToDoorAuthorizedPhone={this.props.goToDoorAuthorizedPhone}
        goToDoorPhoneNumberInformation={this.props.goToDoorPhoneNumberInformation}
        onPressSharePhone={this.props.onPressSharePhone}
        onCallingEnabledChange={this.props.onCallingEnabledChange}
        onAnyCallerEnabledChange={this.props.onAnyCallerEnabledChange}
      />
    )
  }

  renderDoor = (index, door, trialEnded, trialEndedAccount) => {
    const { _id, name, phone } = door
    const key = 'phone-door-' + index
    let doorName = I18n.t('shared.door') + ' ' + (index + 1)
    if (name) {
      if (this.props.activeLang === 'es') { doorName = I18n.t('shared.door') + ' ' + name } 
      else { doorName = name + ' ' + I18n.t('shared.door').toLowerCase() }
    }
    if (!phone) { return this.renderDoorWithoutPhone(key, _id, doorName, trialEndedAccount) }
    return this.renderDoorWithPhone(key, door, doorName, trialEnded)
  }

  render = () => {
    const { device = {} } = this.props.connection || {}
    const { doors = [], guestyListingId, trialEnded = false, trialEndedAccount = false } = device
    const phoneDoors = doors.filter(d => d.port !== 'null' || d.sensorMode === 'nuki' || d.sensorMode === 'gyro')

    return (
      <div style={styles.mainBodyScroll}>
        <ConnectionTabTitle 
          title={I18n.t('shared.phone')} 
          integrationImage={guestyListingId ? 'guesty' : null} 
          onboardingImage={'phones'}
        />
        <div style={{ marginTop: Metrics.baseMargin }}>
          {
            this.props.connection &&
            phoneDoors.map((d, i) => this.renderDoor(i, d, trialEnded, trialEndedAccount))
          }
        </div>
      </div>
    )
  }

}
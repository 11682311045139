export default {
  deviceInfoServiceId: '6D456DD8-C0D1-4A86-92F3-DBB87D17A6B8',
  publicKeyServiceId: '6D456DD9-C0D1-4A86-92F3-DBB87D17A6B8',
  wifiServiceId: '6D456DD0-C0D1-4A86-92F3-DBB87D17A6B8',
  calibrationServiceId: '6D456DE1-C0D1-4A86-92F3-DBB87D17A6B8',
  manageDoorServiceId: '6D456DD6-C0D1-4A86-92F3-DBB87D17A6B8',
  bleReceivedTaskServiceId: '6D456DE4-C0D1-4A86-92F3-DBB87D17A6B8',
  configurationServiceId: '6D456DE6-C0D1-4A86-92F3-DBB87D17A6B8',
  powerServiceId: '6D456DE8-C0D1-4A86-92F3-DBB87D17A6B8',
  firmwareVersionServiceId: '6D456DF0-C0D1-4A86-92F3-DBB87D17A6B8',
  connectionTypeServiceId: '6D456DF2-C0D1-4A86-92F3-DBB87D17A6B8',
  deviceInfoCharacteristicId: '6D456DD4-C0D1-4A86-92F3-DBB87D17A6B8',
  publicKeyCharacteristicId: '6D456DE0-C0D1-4A86-92F3-DBB87D17A6B8',
  wifiParamsCharasteristicId: '6D456DD1-C0D1-4A86-92F3-DBB87D17A6B8',
  wifiScanCharasteristicId: '6D456DD2-C0D1-4A86-92F3-DBB87D17A6B8',
  wifiScanResponseCharasteristicId: '6D456DD3-C0D1-4A86-92F3-DBB87D17A6B8',
  finishListenCharacteriscticId: '6D456DD5-C0D1-4A86-92F3-DBB87D17A6B8',
  manageDoorCharacteriscticId: '6D456DD7-C0D1-4A86-92F3-DBB87D17A6B8',
  calibrationStatusCharacteristicId: '6D456DE2-C0D1-4A86-92F3-DBB87D17A6B8',
  calibrationActionCharacteriscticId: '6D456DE3-C0D1-4A86-92F3-DBB87D17A6B8',
  bleReceivedTaskCharacteristicId: '6D456DE5-C0D1-4A86-92F3-DBB87D17A6B8',
  configurationLedCharacteristicId: '6D456DE7-C0D1-4A86-92F3-DBB87D17A6B8',
  powerCharacteristicId: '6D456DE9-C0D1-4A86-92F3-DBB87D17A6B8',
  firmwareVersionCharacteristicId: '6D456DF1-C0D1-4A86-92F3-DBB87D17A6B8',
  connectionTypeCharacteristicId: '6D456DF3-C0D1-4A86-92F3-DBB87D17A6B8',
  wifiScanRequestValue: 'raixerapp-wifiScan',
  wifiStopListenValue: 'raixerapp-finishListen',
  manageDoorValue: 'raixerapp-door-',
  manageLedValue: 'raixerapp-led-',
  bleActionMaxRetries: 2,
  waitForBleScan: 10000,
  waitForWifiScan: 7000,
  waitForBleActionReceived: 1000
}
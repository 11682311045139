import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ConnectionsActions from '../../Redux/ConnectionsRedux'
import Connection from '../../Components/Screens/Connection/Connection'
import ConnectionHeader from './Header/Header'
import TabbarMenu from './Menu/TabbarMenu'
import AutoAccessTab from './Tabs/AutoAccessTab'
import PhonesTab from './Tabs/PhonesTab'
import CodesTab from './Tabs/CodesTab'
import UsersTab from './Tabs/UsersTab'
import HistoryTab from './Tabs/HistoryTab'
import CheckInTab from './Tabs/CheckInTab'
import EmptyTab from './Tabs/EmptyTab'
import { getActualConnection, connectionHasAutoAccessDoor, connectionHasUsableDoors, connectionIsFreeVersionDevice } from '../../Lib/ConnectionsUtils'

class ConnectionScreen extends Component {

  static propTypes = {
    activeConnection: PropTypes.string
  }

  static defaultProps = {
    activeConnection: null
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedTab: 'auto-access',
      scrollHeight: 0,
      scrollTop: 0,
      clientHeight: 0,
      inCheckin: false
    }
  }

  componentDidUpdate = (prevProps) => {
    const { activeConnection: prevActiveConnection, connections: connectionsPrev } = prevProps
    const { activeConnection, connections } = this.props
    const { selectedTab } = this.state
    const connectionPrev = getActualConnection(connectionsPrev, activeConnection)
    const connection = getActualConnection(connections, activeConnection)
    if (activeConnection && activeConnection !== prevActiveConnection) {
      // First load or change of the connection
      this.getConnection()
      const hasAutoAccessDoor = connectionHasAutoAccessDoor(connection)
      const hasUsableDoors = connectionHasUsableDoors(connection)
      let newSelectedTab = 'auto-access'
      if (!hasAutoAccessDoor || connection.profile !== 'admin') { 
        if (!hasUsableDoors) { newSelectedTab = 'users' }
        else { newSelectedTab = 'phone' }
      }
      if (connection.profile === 'user') { newSelectedTab = 'users' }
      if (connection.disabled) { newSelectedTab = '' }
      this.setState({ selectedTab: newSelectedTab, inCheckin: false })
    }
    if (connectionPrev && connection) {
      // Update of the connection
      const hasAutoAccessDoor = connectionHasAutoAccessDoor(connection)
      const hasUsableDoors = connectionHasUsableDoors(connection)
      if (selectedTab === 'auto-access') {
        if (!hasAutoAccessDoor || connection.profile !== 'admin') { 
          if (!hasUsableDoors) { this.setState({ selectedTab: 'users' }) }
          else {
            if (connection.profile === 'user') { this.setState({ selectedTab: 'users' }) }
            else { this.setState({ selectedTab: 'phone' }) }
          }
        }
      }
      if (selectedTab === 'phone') {
        if (!hasUsableDoors) {
          this.setState({ selectedTab: 'users' })
        }
      }
      if (connection.disabled && selectedTab !== '') {
        this.setState({ selectedTab: '' })
      }
      if (!connection.disabled && selectedTab === '') {
        let newSelectedTab = 'auto-access'
        if (!hasAutoAccessDoor || connection.profile !== 'admin') { 
          if (!hasUsableDoors) { newSelectedTab = 'users' }
          else { newSelectedTab = 'phone' }
        }
        this.setState({ selectedTab: newSelectedTab })
      }
    }
  }

  getConnection = () => {
    this.props.getConnection(this.props.activeConnection)
  }

  switchTab = (tab) => {
    this.setState({ selectedTab: tab })
  }

  onScroll = (event) => {
    if (this.state.selectedTab === 'history') {
      const { target } = event || {}
      const { scrollHeight, scrollTop, clientHeight } = target
      this.setState({ scrollHeight, scrollTop, clientHeight })
    }
  }

  toggleCheckin = () => {
    this.setState({ inCheckin: !this.state.inCheckin })
    // navigate('CheckInScreen', { connectionId: _id, deviceId })
  }

  renderBody = (connection) => {
    const { configuration, error = false } = this.props
    const { appShowFreeProfile = false } = configuration || {}
    const { disabled = false, device } = connection || {}
    const { trialEnded = false, trialEndedAccount = false } = device || {}
    const isFreeVersionDevice = connectionIsFreeVersionDevice(connection)
    const finalTrialEnded = (appShowFreeProfile && isFreeVersionDevice) ? trialEndedAccount : trialEnded
    if ((error || disabled || finalTrialEnded) && this.state.selectedTab !== 'users') { return <EmptyTab connection={connection} /> }
    return (
      <>
        { this.state.selectedTab === 'auto-access' && <AutoAccessTab connection={connection} />}
        { this.state.selectedTab === 'phone' && <PhonesTab connection={connection} />}
        { this.state.selectedTab === 'codes' && <CodesTab connection={connection} />}
        { this.state.selectedTab === 'users' && <UsersTab connection={connection} />}
        {
          this.state.selectedTab === 'history' &&
          <HistoryTab
            connection={connection}
            scrollHeight={this.state.scrollHeight}
            scrollTop={this.state.scrollTop}
            clientHeight={this.state.clientHeight}
          />
        }
        { this.state.inCheckin && <CheckInTab connection={connection} /> }
      </>
    )
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.props.activeConnection)
    return (
      <Connection
        activeConnection={this.props.activeConnection}
        connection={connection}
        header={<ConnectionHeader connection={connection} inCheckin={this.state.inCheckin} toggleCheckin={this.toggleCheckin} />}
        tabbar={!this.state.inCheckin && <TabbarMenu connection={connection} selectedTab={this.state.selectedTab} switchTab={this.switchTab} />}
        activeTab={this.renderBody(connection)}
        loaderVisible={this.props.fetchingConnectionForm && !this.state.inCheckin}
        onScroll={this.onScroll}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    fetching: state.connections.fetchingConnection,
    fetchingConnectionTab: state.connections.fetchingConnectionTab,
    fetchingConnectionForm: state.connections.fetchingConnectionForm,
    error: state.connections.errorConnection,
    activeLang: state.i18n.activeLang,
    configuration: state.configuration.configuration
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConnection: (connectionId) => dispatch(ConnectionsActions.connectionRequest(connectionId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionScreen)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormModalService } from '../../../Services/FormModal'
import { connectionIsWifiDevice } from '../../../Lib/ConnectionsUtils'
import Empty from '../../../Components/Screens/Connection/Tabs/Empty'

class EmptyTab extends Component {

  static propTypes = {
    connection: PropTypes.object.isRequired
  }

  static defaultProps = {
    connection: null
  }

  goToConnectionUpgrade = () => {
    const { connection } = this.props
    const { _id } = connection || {}
    const isWifiDevice = connectionIsWifiDevice(connection)
    const plan = { type: isWifiDevice ? 'intercom' : 'access' }
    FormModalService.open('upgrade', { connectionId: _id, plan })
  }

  render = () => {
    return (
      <Empty
        connection={this.props.connection}
        error={this.props.error}
        goToConnectionUpgrade={this.goToConnectionUpgrade}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    error: state.connections.errorConnection
  }
}

const mapDispatchToProps = () => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyTab)
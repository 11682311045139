import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie'
import { Images } from '../../../Themes'

export default class AnimatedComponent extends Component {

  static propTypes = {
    source: PropTypes.string.isRequired,
    autoPlay: PropTypes.bool,
    loop: PropTypes.bool,
    rendererSettings: PropTypes.object,
    height: PropTypes.number,
    width: PropTypes.number,
    stopped: PropTypes.bool,
    paused: PropTypes.bool,
    progress: PropTypes.number,
    viewCustomStyle: PropTypes.object
  }

  static defaultProps = {
    source: null,
    autoPlay: true,
    loop: true,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
    height: 150,
    width: 150,
    stopped: false,
    paused: false,
    progress: 0,
    viewCustomStyle: { alignItems: 'center' }
  }

  render = () => {
    return (
      <div style={this.props.viewCustomStyle}>
        <Lottie
          options={{
            loop: this.props.loop,
            autoplay: this.props.autoPlay, 
            animationData: Images[this.props.source],
            rendererSettings: this.props.rendererSettings
          }}
          height={this.props.height}
          width={this.props.width}
          isStopped={this.props.stopped}
          isPaused={this.props.paused}
        />
      </div>
    )
  }

}

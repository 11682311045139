class Facebook {

  login = () => {
    return new Promise((resolve) => {
      try {
        window.FB.login((response) => {
          const { authResponse, status } = response
          const { accessToken, userID } = authResponse || {}
          if (status === 'connected') {
            window.FB.api('/me', { fields: 'name,email' }, (response) => {
              const { name, email } = response
              if (name && email) {
                resolve({ ok: true, data: { accessToken, userId: userID, name, email } })
              } else {
                resolve({ ok: false, data: { error: 'error-missingFields' } })
              }
            })
          } else {
            resolve({ ok: false, data: { error: 'error-cancelled' } })
          }
        }, { scope: 'public_profile,email', return_scopes: true })
      } catch (err) {
        return Promise.resolve({ ok: false, data: { error: 'error-unexpected' } })
      }
    })
  }

}

export const FacebookService = new Facebook()
import { Colors, Shadows, Metrics } from '../../../Themes'

export default {
  container: {
    height: 'calc(100vh - ' + Metrics.doubleBaseMargin + ')',
    alignSelf: 'flex-start',
    paddingTop: Metrics.doubleBaseMargin,
    minWidth: '33.33%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: Metrics.baseMenuWidth, 
    zIndex: Metrics.zIndexes.connectionsList,
    ...Shadows.cardSecond
  },
  logo: {
    width: 170,
    height: 53,
    display: 'flex',
    margin: 0,
    marginLeft: Metrics.baseMarginPlus
  },
  titleAndEditContainer: {
    marginBottom: Metrics.baseMargin,
    padding: Metrics.doubleBaseMargin,
    paddingBottom: 0
  },
  searchFormContainer: {
    marginBottom: Metrics.doubleBaseMargin,
    padding: '0 ' + Metrics.doubleBaseMargin
  },
  searchFormIcon: {
    fontSize: (Metrics.baseMeasure * 3) + 'px',
    color: Colors.lightGray
  },
  formRightAccessory: {
    position: 'relative',
    top: Metrics.baseMargin,
    marginRight: Metrics.doubleBaseMargin
  },
  emptyConnectionList: {
    marginTop: Metrics.baseMeasure * 20 + 'px'
  },
  emptyText: {
    textAlign: 'center',
    margin: 0
  },
  emptySearchText: {
    marginTop: Metrics.tripleBaseMargin, textAlign: 'center'
  },
  connectionListWrapper: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    height: '0vh'
  },
  connectionList: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'visible',
    padding: Metrics.doubleBaseMargin
  }
}
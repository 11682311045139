import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getActualConnection } from '../../../Lib/ConnectionsUtils'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import I18n from '../../../i18n/i18n'
import { ModalService } from '../../../Services/Modal'
import DoorPhone from '../../../Components/Screens/Connection/Phones/DoorPhone'

class DoorPhoneScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      doorId: null,
      phone: null
    }
  }

  componentDidMount = () => {
    const { connectionId, doorId, phone } = this.props || {}
    this.setState({ connectionId, doorId, phone })
    this.props.getDoorPhoneCountries(connectionId, doorId, phone ? true : false)
  }

  getDoorPhone = (country, phoneId) => {
    if (phoneId) {
      ModalService.show(
        I18n.t('modals.doorPhoneUseExistingPhone.title'), 
        I18n.t('modals.doorPhoneUseExistingPhone.text'), 
        () => { this.props.getDoorPhone(this.state.connectionId, this.state.doorId, null, phoneId) }, 
        I18n.t('modals.doorPhoneUseExistingPhone.buttons.ok'),
        null,
        true
      )
    } else {
      ModalService.show(
        I18n.t('modals.doorPhonePossibleAnswers.title'), 
        I18n.t('modals.doorPhonePossibleAnswers.text'), 
        () => { this.props.getDoorPhone(this.state.connectionId, this.state.doorId, country, null) }, 
        I18n.t('modals.doorPhonePossibleAnswers.buttons.ok'),
        null,
        true
      )
    }
  }

  deleteDoorPhone = () => {
    ModalService.show(
      I18n.t('modals.doorPhoneRemove.title'), 
      I18n.t('modals.doorPhoneRemove.text'), 
      () => { this.props.deleteDoorPhone(this.state.connectionId, this.state.doorId) }, 
      I18n.t('modals.doorPhoneRemove.buttons.ok'),
      null,
      true
    )
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    return (
      <DoorPhone
        activeLang={this.props.activeLang}
        connection={connection}
        phone={this.state.phone}
        fetching={this.props.fetching}
        getDoorPhone={this.getDoorPhone}
        deleteDoorPhone={this.deleteDoorPhone}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    fetching: state.connections.fetchingConnectionForm,
    activeLang: state.i18n.activeLang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDoorPhoneCountries: (connectionId, doorId, change) => 
      dispatch(ConnectionsActions.connectionDoorPhoneCountriesRequest(connectionId, doorId, change)),
    getDoorPhone: (connectionId, doorId, country, phoneId) => 
      dispatch(ConnectionsActions.connectionDoorPhoneRequest(connectionId, doorId, country, phoneId)),
    deleteDoorPhone: (connectionId, doorId) =>
      dispatch(ConnectionsActions.connectionDoorPhoneDeleteRequest(connectionId, doorId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoorPhoneScreen)
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RaixerConfig from '../../../Config/RaixerConfig'
import I18n from '../../../i18n/i18n'
import AppText from '../Texts/AppText'
import LineItemEditButton from '../Buttons/LineItemEditButton'
import SimpleTextButton from '../Buttons/SimpleTextButton'
import { inputDateFormat } from '../../../Lib/DateTimeUtils'
import { Metrics } from '../../../Themes'
import styles from '../../Styles/UI/ListItem'
import GhostButton from '../../UI/Buttons/GhostButton'

export default class BookingListItem extends Component {

  static propTypes = {
    bookingFrom: PropTypes.node.isRequired,
    residencyDays: PropTypes.number.isRequired,
    checkedIn: PropTypes.number.isRequired,
    guests: PropTypes.number.isRequired,
    withEditButton: PropTypes.bool.isRequired,
    lastOfList: PropTypes.bool,
    editButtonFlex: PropTypes.number,
    onPressEdit: PropTypes.func,
    onListItemMain: PropTypes.func,
    onListItemSecondary: PropTypes.func,
    customLeftStyle: PropTypes.object,
    customRightStyle: PropTypes.object,
    customLeftTextStyle: PropTypes.object,
    customRightTextStyle: PropTypes.object,
    customAdditionalLeftTextStyle: PropTypes.object
  }

  static defaultProps = {
    bookingFrom: null,
    residencyDays: null,
    checkedIn: null,
    guests: null,
    withEditButton: null,
    lastOfList: false,
    editButtonFlex: 0,
    onPressEdit: () => { console.log() },
    onListItemMain: null,
    onListItemSecondary: null,
    customLeftStyle: {},
    customRightStyle: {},
    customLeftTextStyle: {},
    customRightTextStyle: {},
    customAdditionalLeftTextStyle: {}
  }

  render = () => {
    const editStyles = Object.assign({}, styles.listItemEditButtonContainer, { flex: this.props.editButtonFlex })
    const { 
      lastOfList, customLeftStyle, customLeftTextStyle, customRightStyle, customRightTextStyle, bookingFrom, residencyDays, 
      checkedIn, guests, onPressEdit, onListItemMain, onListItemSecondary 
    } = this.props
    const checkedInText = checkedIn + ' / ' + guests + ' ' + I18n.t('shared.guests')
    return (
      <div style={!lastOfList ? styles.listItem : Object.assign({}, styles.listItem, { marginBottom: 0 })}>
        <div style={styles.listItemInner}>
          <div style={Object.assign({}, styles.listItemInner, { flex: RaixerConfig.animations.lists.itemFlex })}>
            <div style={Object.assign({}, styles.listItemLeft, customLeftStyle)}>
              <AppText
                text={inputDateFormat(bookingFrom)}
                customStyle={Object.assign({}, styles.listItemTitle, styles.marginBottom, customLeftTextStyle)}
                numberOfLines={1}
              />
              <AppText
                text={residencyDays + ' ' + (residencyDays === 1 ? I18n.t('shared.night') : I18n.t('shared.nights'))}
                customStyle={Object.assign({}, styles.listItemTitle, styles.marginBottom, customLeftTextStyle)}
                numberOfLines={1}
              />
            </div>
            <div style={Object.assign({}, styles.listItemRight, customRightStyle)}>
              {
                checkedIn > 0 && onListItemSecondary ? 
                <SimpleTextButton
                  buttonText={checkedInText}
                  onPress={onListItemSecondary}
                  disabled={false}
                  customButtonStyle={{ padding: 0, margin: 0, justifyContent: 'flex-end' }}
                  customTextStyle={Object.assign({}, styles.listItemContent, styles.marginBottom, customRightTextStyle, styles.listBookingLink)}
                />
                :
                <AppText
                  text={checkedInText}
                  customStyle={Object.assign({}, styles.listItemContent, styles.marginBottom, customRightTextStyle)}
                  numberOfLines={1}
                />
              }
              <AppText text={' '} customStyle={Object.assign({}, styles.listItemContent, styles.marginBottom, customRightTextStyle)} numberOfLines={1} />
            </div>
          </div>
          <div style={editStyles}>
            <LineItemEditButton
              buttonText={I18n.t('shared.edit')}
              onPress={onPressEdit}
              disabled={false}
              customButtonStyle={{ marginBottom: 0, marginLeft: Metrics.doubleBaseMargin }}
            />
          </div>
        </div>
        {
          onListItemMain ?
          <>
            <GhostButton
              buttonText={I18n.t('shared.share')}
              onPress={onListItemMain}
              disabled={false}
              icon={'share'}
              customButtonStyle={{ margin: 0, marginTop: Metrics.baseMargin, marginBottom: 0 }}
            />
          </>
          :
          null
        }
      </div>
    )
  }
}
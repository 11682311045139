import { Metrics } from '../../../../Themes/'

export default {
  container: {
    marginTop: (Metrics.baseMeasure * 10) + 'px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    marginBottom: (Metrics.baseMeasure * 3) + 'px'
  },
  text: {
    textAlign: 'center'
  }
}
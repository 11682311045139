import React, { Component } from 'react'
import { Switch } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { Colors } from '../../../Themes'
import PropTypes from 'prop-types'
import AppText from '../Texts/AppText'
import styles from '../../Styles/UI/Switch'

const StyledDiv = styled.div `
  .switchHelp p {
    ${styles.switchHelp}
  }
  .switchHelp p.disabled {
    ${styles.switchHelpDisabled}
  }
`

const StyledSwitch = styled.div `
  .styledSwitch .MuiIconButton-label input[type="checkbox"]:disabled {
    opacity: 0
  }
  .styledSwitch .MuiSwitch-switchBase.Mui-disabled .MuiIconButton-label .MuiSwitch-thumb {
    opacity: 0.5
  }
`

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: Colors.success,
    },
    '&$checked + $track': {
      backgroundColor: Colors.success,
    },
  },
  checked: {},
  track: {},
})(Switch)

class BaseSwitch extends Component {

  static propTypes = {
    value: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    help: PropTypes.string,
    onChange: PropTypes.func,
    withoutMargin: PropTypes.bool,
    withoutVerticalPadding: PropTypes.bool,
    dontDisableHelp: PropTypes.bool,
    separateSwitchFromLabel: PropTypes.bool,
    customLabelStyle: PropTypes.object,
    customHelpStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    customSwitchRowStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  }

  static defaultProps = {
    value: null,
    disabled: false,
    label: null,
    help: null,
    onChange: null,
    withoutMargin: false,
    withoutVerticalPadding: false,
    dontDisableHelp: false,
    separateSwitchFromLabel: true,
    customLabelStyle: {},
    customHelpStyle: {},
    customSwitchRowStyle: {}
  }

  constructor(props) {
    super(props)
    this.state = {
      propsLastValue: null,
      value: false
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    const { value } = props
    const { propsLastValue } = state
    if (propsLastValue === null || value !== propsLastValue) {
      return { value, propsLastValue: value }
    } else if (propsLastValue === value) {
      return { value, propsLastValue }
    }
    return null
  }

  onChange = (event) => {
    const value = event.target.checked
    if (this.props.onChange) { this.props.onChange(value) }
    this.setState({ value })
  }
  
  render = () => {
    let switchRowStyles = [styles.switchRow, this.props.customSwitchRowStyle]
    if (this.props.withoutVerticalPadding) { switchRowStyles.push({ paddingVertical: 0 }) }
    if (this.props.separateSwitchFromLabel) { switchRowStyles.push({ justifyContent: 'space-between' }) }
    return (
      <div style={this.props.withoutMargin ? Object.assign({}, styles.switchContainer, { marginTop: 0 }) : styles.switchContainer}>
        {
          this.props.label &&
          <AppText 
            text={this.props.label}
            customStyle={Object.assign({}, styles.switchLabel, this.props.customLabelStyle)}
          />
        }
        <div style={Object.assign({}, ...switchRowStyles)}>
          {
            this.props.help &&
            <StyledDiv>
              <div className='switchHelp'>
                <AppText 
                  text={this.props.help}
                  customStyle={
                    this.props.disabled && !this.props.dontDisableHelp 
                    ? Object.assign({}, styles.switchHelp, styles.switchHelpDisabled, this.props.customHelpStyle)
                    : Object.assign({}, styles.switchHelp, this.props.customHelpStyle)
                  }
                />
              </div>
            </StyledDiv>
          }
          <StyledSwitch>
            <div className='styledSwitch'>
              <GreenSwitch
                checked={this.state.value}
                onChange={this.onChange}
                disabled={this.props.disabled}
              />
            </div>
          </StyledSwitch>
        </div>
      </div>
    )
  }

}

export default BaseSwitch
import { Colors, Fonts, Metrics } from '../../../Themes/'

export default `
  fieldset {
    border-width: 0;
    border-color: transparent;
    padding: 0;
    margin: 0;
  }
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: ${Metrics.baseMargin};
  }
  label {
    color: ${Colors.mediumGray};
    font-family: 'LatoRegular';
    font-size: ${Fonts.size.regular};
    font-weight: ${Fonts.weight.normal};
    margin-top: ${Metrics.doubleBaseMargin};
    margin-bottom: ${Metrics.smallMargin};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${Colors.theme2} !important;
  }
  .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root.MuiFormLabel-filled {
    margin-top: ${Metrics.baseMargin};
  }
  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px);
  }
  .MuiFormLabel-root.Mui-error {
    color: ${Colors.error};
  }
  .form-group:first-child label {
    margin-top: 0;
  }
  .help-block, p {
    color: ${Colors.helpsGray} !important;
    font-family: 'LatoRegular' !important;
    font-size: ${Fonts.size.small} !important;
    margin: 0 !important;
    margin-top: ${Metrics.baseMargin} !important;
  }
  input, .MuiInputBase-root {
    color: ${Colors.darkGray};
    font-family: 'LatoRegular';
    font-size: ${Fonts.size.regular};
    font-weight: ${Fonts.weight.regular};
    padding: ${Metrics.baseMargin + ' 0'};
    border-radius: 0;
    border-color: ${Colors.mediumGray};
    border-width: 0;
    border-bottom-width: ${Metrics.inputBorderWidth};
    caret-color: ${Colors.theme2};
  }
  .MuiInputBase-root input {
    padding: 16px 0 0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: ${Colors.mediumGray};
    border-bottom-width: ${Metrics.inputBorderWidth};
  }
  .MuiInput-underline:after {
    border-bottom: ${Metrics.inputBorderWidth} solid ${Colors.theme2};
    transform: scaleX(0);
    transition: 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  }
  .MuiInput-underline:before {
		border-color: ${Colors.lighterGray};
		border-bottom-width: ${Metrics.inputBorderWidth};
  }
  .MuiInput-underline.Mui-error:after {
    border-bottom-color: ${Colors.error};
  }
  input:focus, select:focus {
    outline-width: 0;
    border-color: ${Colors.theme2};
  }
  input:disabled {
    opacity: 0.7;
    background: none;
    border-color: ${Colors.lightGray};
  }
  select {
    color: ${Colors.darkGray};
    font-size: ${Fonts.size.regular};
    font-weight: ${Fonts.weight.regular};
    padding: ${Metrics.baseMargin} 0;
    margin-bottom: 0;
    border-radius: 0;
    border-color: ${Colors.mediumGray};
    border-width: 0;
    border-bottom-width: ${Metrics.inputBorderWidth};
  }
  select option {
    color: ${Colors.darkGray};
    font-family: 'LatoRegular';
    font-size: ${Fonts.size.regular};
    font-weight: ${Fonts.weight.regular};
  }
  .form-group.has-error label, .form-group.has-error .help-block {
    color: ${Colors.error};
  }
  .form-group.has-error input, .form-group.has-error select {
    color: ${Colors.error};
    border-color: ${Colors.error};
  }
`
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from '../../../../i18n/i18n'
import ConnectionTabTitle from '../../../UI/Connections/ConnectionTabTitle'
import AppText from '../../../UI/Texts/AppText'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import ConnectionDoorCodeCard from '../../../UI/Connections/ConnectionDoorCodeCard'
import MainButton from '../../../UI/Buttons/MainButton'
import styles from '../../../Styles/Screens/Connection'
import { Metrics } from '../../../../Themes'

export default class Codes extends Component {

  static propTypes = {
    isUserAdmin: PropTypes.bool.isRequired,
    activeLang: PropTypes.string.isRequired,
    connection: PropTypes.object.isRequired,
    fetchingTask: PropTypes.bool.isRequired,
    goToDoorCode: PropTypes.func.isRequired,
    goToDoorAuthorizedCode: PropTypes.func.isRequired,
    onPressShareCode: PropTypes.func.isRequired,
    onPressOpenBrowser: PropTypes.func.isRequired,
    onPressCopy: PropTypes.func.isRequired,
    onCodeEnabledChange: PropTypes.func.isRequired,
    onAnyCallerEnabledChangeCode: PropTypes.func.isRequired,
    onPressGetQr: PropTypes.func.isRequired
  }

  static defaultProps = {
    isUserAdmin: null,
    activeLang: null,
    connection: null,
    fetchingTask: null,
    goToDoorCode: null,
    goToDoorAuthorizedCode: null,
    onPressShareCode: null,
    onPressOpenBrowser: null,
    onPressCopy: null,
    onCodeEnabledChange: null,
    onAnyCallerEnabledChangeCode: null,
    onPressGetQr: null
  }

  renderDoorWithoutCode = (key, doorId, doorName, trialEndedAccount, door) => {
    return (
      <ContentCard
        key={key}
        title={doorName}
        renderContent={() => <AppText text={I18n.t('screens.connection.codes.noLink')} />}
        renderButtons={
          () => {
            if (!this.props.isUserAdmin) { return null }
            return (
              <MainButton
                buttonText={I18n.t('screens.connection.codes.getLink')}
                onPress={() => { this.props.goToDoorCode(doorId, false, doorName, door) }}
                disabled={trialEndedAccount}
                icon={'link'}
                customButtonStyle={{ margin: 0 }}
              />
            )
          }
        }
      />
    )
  }

  renderDoorWithCode = (key, door, doorName, trialEnded) => {
    return (
      <ConnectionDoorCodeCard
        activeLang={this.props.activeLang}
        fetching={this.props.fetchingTask}
        key={key}
        door={door}
        provitionalProps={this.props}
        doorName={doorName}
        isUserAdmin={this.props.isUserAdmin}
        trialEnded={trialEnded}
        goToDoorCode={this.props.goToDoorCode}
        goToDoorAuthorizedCode={this.props.goToDoorAuthorizedCode}
        onPressShareCode={this.props.onPressShareCode}
        onPressOpenBrowser={this.props.onPressOpenBrowser}
        onPressCopy={this.props.onPressCopy}
        onCodeEnabledChange={this.props.onCodeEnabledChange}
        onAnyCallerEnabledChangeCode={this.props.onAnyCallerEnabledChangeCode}
        onPressGetQr={this.props.onPressGetQr}
      />
    )
  }

  renderDoor = (index, door, trialEnded, trialEndedAccount) => {
    const { _id, name, accessURL } = door
    const key = 'code-door-' + index
    let doorName = I18n.t('shared.door') + ' ' + (index + 1)
    if (name) {
      if (this.props.activeLang === 'es') { doorName = I18n.t('shared.door') + ' ' + name }
      else { doorName = name + ' ' + I18n.t('shared.door').toLowerCase() }
    }
    if (!accessURL) { return this.renderDoorWithoutCode(key, _id, doorName, trialEndedAccount) }
    return this.renderDoorWithCode(key, door, doorName, trialEnded)
  }

  render = () => {
    const { device = {} } = this.props.connection || {}
    const { doors = [], guestyListingId, trialEnded = false, trialEndedAccount = false } = device
    const phoneDoors = doors.filter(d => d.port !== 'null' || d.sensorMode === 'nuki' || d.sensorMode === 'gyro')
    return (
      <div style={styles.mainBodyScroll}>
        <ConnectionTabTitle
          title={I18n.t('shared.codes')}
          integrationImage={guestyListingId ? 'guesty' : null}
          onboardingImage={'phones'}
        />
        <div style={{ marginTop: Metrics.baseMargin }}>
          {
            this.props.connection &&
            phoneDoors.map((d, i) => this.renderDoor(i, d, trialEnded, trialEndedAccount))
          }
        </div>
      </div>
    )
  }

}

import { call, put, select } from 'redux-saga/effects'
// import { AppState } from 'react-native'
// import { goBack, navigate, pop, popToTop } from '../Navigation/RootNavigation'
import RaixerConfig from '../Config/RaixerConfig'
import I18n from '../i18n/i18n'
import { 
  getActualConnection, getConnectionDoorById, getPortOfDoor, getAutoAccessTimeOfConnection, updateConnectionsWithConnection,
  connectionIsWifiDevice, getConnectionSignal, getConnectionBattery
} from '../Lib/ConnectionsUtils'
import { 
  getResultMsgOfTaskConnectionsList, getResultMsgOfTaskConnectionDetail 
} from '../Lib/DeviceTaskUtils'
import { arrayDeepCopy, objectDeepCopy, upsertOrDeleteInArrayById } from '../Lib/ObjectsUtils'
import { delay, getFinishTimeAsUnix, getDatetimeInCurrentTimezoneAsString } from '../Lib/DateTimeUtils'
import ConnectionsActions, { ConnectionsSelectors } from '../Redux/ConnectionsRedux'
import { AuthSelectors } from '../Redux/AuthRedux'
import { ModalService } from '../Services/Modal'
import { FormModalService } from '../Services/FormModal'
import { AlertService } from '../Services/Alert'

export function * getConnections (api) {
  const response = yield call(api.getConnections)
  const { ok, status, data } = response
  if (ok) {
    const connections = data.flat()
    const platformError = connections.filter((c) => {
      const { device = {} } = c
      const { error } = device || {}
      return error === 'platform.down' || error === 'platform.error'
    })[0] || null
    if (platformError) {
      yield put(ConnectionsActions.connectionsFailure(null, platformError))
    } else {
      const oldConnections = yield select(ConnectionsSelectors.selectConnections)
      let newConnections = []
      for (let c of connections) {
        const { _id } = c
        const oldConnection = getActualConnection(oldConnections, _id)
        const { device, location, phonesCountries, qrs } = oldConnection || {}
        const { vitals } = device || {}
        const { guestBookUri } = location || {}
        if (vitals) { c.device.vitals = vitals }
        if (guestBookUri) { c.location.guestBookUri = guestBookUri }
        if (phonesCountries) { c.phonesCountries = phonesCountries }
        if (qrs) { c.qrs = qrs }
        newConnections.push(c)
      }
      yield put(ConnectionsActions.connectionsSuccess(newConnections))
    }
  } else {
    if (status === 401) { yield put(ConnectionsActions.connectionsFailure(null, 'platform.unauthorized')) }
    else { yield put(ConnectionsActions.connectionsFailure(true)) }
  }
}

export function * postDeviceTask (api, action) {
  const { connection, task, fromList, params, dontAlert = false } = action
  const { device } = connection
  const { _id: deviceId, doors } = device
  if (fromList) { yield call(postDeviceTaskConnectionsList, api, connection, deviceId, doors, task, params) }
  else { yield call(postDeviceTaskConnectionDetail, api, connection, deviceId, task, params, dontAlert) }
}

function * postDeviceTaskConnectionsList (api, connection, deviceId, doors, task, params) {
  const appState = yield call(delayAndGetAppState)
  params = params || {}
  let newConnection = objectDeepCopy(connection)
  let error = false
  let unauthError = false
  for (let d of doors) {
    const { sensorMode } = d
    if (!sensorMode || sensorMode === 'bell') {
      const port = getPortOfDoor(d)
      params.minutes = getAutoAccessTimeOfConnection(connection, d, task)
      params.appState = appState
      const response = yield call(api.postDeviceTask, deviceId, task + '/' + port, params)
      const { ok, status } = response
      if (ok) {
        if (task.indexOf(RaixerConfig.connections.actionsNames.startJob) > -1) {
          const finishTime = getFinishTimeAsUnix(params.minutes)
          newConnection.device.inUseComplete = connection.device.inUseComplete.map(() => finishTime)
        } else if (task.indexOf(RaixerConfig.connections.actionsNames.stopJob) > -1) {
          newConnection.device.inUseComplete = connection.device.inUseComplete.map(() => false)
        }
      } else {
        error = true
        unauthError = status === 401
        break
      }
    }
  }
  let connections = yield select(ConnectionsSelectors.selectConnections)
  const newConnections = updateConnectionsWithConnection(connections, newConnection)
  yield put(ConnectionsActions.connectionsTaskFinished(newConnections))
  const msg = getResultMsgOfTaskConnectionsList(task, error, unauthError)
  if (error) { AlertService.error(msg) } 
  else { AlertService.success(msg) }
}

function * postDeviceTaskConnectionDetail (api, connection, deviceId, task, params, dontAlert = false) {
  const appState = yield call(delayAndGetAppState)
  params = params || {}
  params.appState = appState
  params.minutes = getAutoAccessTimeOfConnection(connection, null, task)
  const finishTime = getFinishTimeAsUnix(params.minutes)
  const response = yield call(api.postDeviceTask, deviceId, task, params)
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let newConnection = objectDeepCopy(getActualConnection(connections, connection._id))
  const { ok, status } = response
  if (ok) {
    if (task.indexOf(RaixerConfig.connections.actionsNames.startJob) > -1) {
      if (task.indexOf('/') > -1) {
        const taskPort = parseInt(task.substr(task.indexOf('/') + 1))
        if (!isNaN(taskPort)) {
          newConnection.device.inUseComplete = connection.device.inUseComplete.map((inUse, index) => {
            if (index === taskPort - 1) { return finishTime }
            return inUse
          })
        }
      } else {
        newConnection.device.inUseComplete = connection.device.inUseComplete.map(() => finishTime)
      }
    } else if (task.indexOf(RaixerConfig.connections.actionsNames.stopJob) > -1) {
      if (task.indexOf('/') > -1) {
        const taskPort = parseInt(task.substr(task.indexOf('/') + 1))
        if (!isNaN(taskPort)) {
          newConnection.device.inUseComplete = connection.device.inUseComplete.map((inUse, index) => {
            if (index === taskPort - 1) { return false }
            return inUse
          })
        }
      } else {
        newConnection.device.inUseComplete = connection.device.inUseComplete.map(() => false)
      }
    }
    if (task.indexOf(RaixerConfig.connections.actionsNames.activatePiston) > -1) {
      newConnection.device.pistonInactive = false
    } else if (task.indexOf(RaixerConfig.connections.actionsNames.deactivatePiston) > -1) {
      newConnection.device.pistonInactive = true
    }
    const newConnections = updateConnectionsWithConnection(connections, newConnection)
    const msg = getResultMsgOfTaskConnectionDetail(newConnection, task, false)
    yield put(ConnectionsActions.connectionsTaskFinished(newConnections))
    if (!dontAlert) { AlertService.success(msg) }
  } else {
    const msg = getResultMsgOfTaskConnectionDetail(newConnection, task, true, false, status === 401)
    yield put(ConnectionsActions.connectionsTaskFinished(connections, true))
    if (!dontAlert) { AlertService.error(msg) }
  }
  yield call(delay, RaixerConfig.delays.cleanConnectionTask)
  yield put(ConnectionsActions.connectionsTaskClean())
}

export function * postNukiTask (api, action) {
  const appState = yield call(delayAndGetAppState)
  const { connectionId, task } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  const connection = getActualConnection(connections, connectionId)
  const { deviceId } = connection
  const response = yield call(api.postNukiOperation, deviceId, task, { appState })
  const { ok } = response
  if (ok) {
    const msg = getResultMsgOfTaskConnectionDetail(task, false)
    yield put(ConnectionsActions.connectionsTaskFinished(connections))
    AlertService.success(msg)
    yield call(delay, RaixerConfig.delays.cleanConnectionTask)
    yield put(ConnectionsActions.connectionsTaskClean())
    yield call(delay, RaixerConfig.delays.nukiTask - RaixerConfig.delays.cleanConnectionTask)
    const responseStatus = yield call(api.getNukiVerifyStatus, deviceId, task)
    const { ok: okStatus, data: dataStatus } = responseStatus
    yield put(ConnectionsActions.connectionsTaskFinished(connections))
    if (okStatus) {
      const { result } = dataStatus
      if (!result) {
        const msg = getResultMsgOfTaskConnectionDetail(task, true, true)
        AlertService.error(msg)  
      }
    } else {
      const msg = getResultMsgOfTaskConnectionDetail(task, true, true)
      AlertService.error(msg)
    }
  } else {
    const msg = getResultMsgOfTaskConnectionDetail(task, true)
    yield put(ConnectionsActions.connectionsTaskFinished(connections, true))
    AlertService.error(msg)
    yield call(delay, RaixerConfig.delays.cleanConnectionTask)
    yield put(ConnectionsActions.connectionsTaskClean())
  }
}

/* 
 * Function that delays the code just a little to give chance to the APP to get it's most recent state.
 * The most recent state is later passed to the functions of the device that require the most security 
 * to avoid double openings.
 */
function delayAndGetAppState () {
  return 'active'
}

// export function * postCalibrateServoTask (api, action) {
//   const { connectionId, task, dontWaitExtra = false } = action
//   let connections = yield select(ConnectionsSelectors.selectConnections)
//   const connection = getActualConnection(connections, connectionId)
//   const { deviceId } = connection
//   const response = yield call(api.postDeviceTask, deviceId, task)
//   const { ok } = response
//   if (ok) {
//     if (!dontWaitExtra) { yield call(delay, RaixerConfig.delays.cleanConnectionTask * 2) }
//     yield put(ConnectionsActions.connectionsTaskFinished(connections))
//   } else {
//     yield put(ConnectionsActions.connectionsTaskFinished(connections, true))
//   }
//   yield call(delay, RaixerConfig.delays.cleanConnectionTask)
//   yield put(ConnectionsActions.connectionsTaskClean())
// }

export function * getConnection (api, action) {
  const { connectionId } = action
  const response = yield call(api.getConnection, connectionId)
  const { ok, data: connection } = response
  if (ok) {
    let newConnection = objectDeepCopy(connection)
    const connections = yield select(ConnectionsSelectors.selectConnections)
    const oldConnection = getActualConnection(connections, connection._id)
    const { location, phonesCountries, qrs } = oldConnection || {}
    const { guestBookUri } = location || {}
    if (guestBookUri) { newConnection.location.guestBookUri = guestBookUri }
    if (phonesCountries) { newConnection.phonesCountries = phonesCountries }
    if (qrs) { newConnection.qrs = qrs }
    const newConnections = updateConnectionsWithConnection(connections, newConnection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  } else {
    yield put(ConnectionsActions.connectionFailure())
  }
}

export function * getConnectionHistory (api, action) {
  const { connectionId, page, pageSize } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const response = yield call(api.getConnectionHistory, connectionId, connection.profile, page, pageSize || 10)
  const { ok, data: history } = response
  if (ok) {
    if (!connection.history || page === 0) { connection.history = history } 
    else { connection.history = arrayDeepCopy(connection.history).concat(history) }
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionsDetailGetHistory'))
    if (!connection.history || page === 0) { connection.history = 'error' } 
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  }
}

export function * clearConnectionHistory (api, action) {
  const { connectionId } = action
  try {
    let connections = yield select(ConnectionsSelectors.selectConnections)
    let connection = objectDeepCopy(getActualConnection(connections, connectionId))
    connection.history = null
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  } catch {
    // Do nothing, the connection does not exist anyway
  }
}

export function * getConnectionVitals (api, action) {
  const { connectionId, vitalsType = 'signal' } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const response = yield call(api.refreshDeviceVitals, connection.device._id)
  const { ok, data: vitals } = response
  if (ok) {
    const { updatedAt } = vitals
    connection.device.vitals = vitals
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    if (ModalService.isVisible()) {
      if (vitalsType === 'battery') {
        const battery = getConnectionBattery(connection)
        const { charge, state } = battery
        const parsedCharge = parseInt(charge)
        const title = I18n.t('modals.deviceVitalsBattery.title')
        let textDate = I18n.t('screens.connection.warnings.battery.lastUpdatedAt') + ' ' + getDatetimeInCurrentTimezoneAsString(updatedAt, true)
        let textState = I18n.t('screens.connection.warnings.battery.disconnected').toLowerCase()
        if (state === 'discharging') { textState = I18n.t('screens.connection.warnings.battery.discharging').toLowerCase() }
        else if (state === 'charging') { textState = I18n.t('screens.connection.warnings.battery.charging').toLowerCase() }
        else if (state === 'charged') { textState = I18n.t('screens.connection.warnings.battery.charged').toLowerCase() }
        ModalService.update(
          title, 
          I18n.t('modals.deviceVitalsBattery.textFirst') + parsedCharge + '%\n' + I18n.t('modals.deviceVitalsBattery.textSecond') + textState + '\n' + textDate,
          undefined,
          undefined,
          null,
          false
        )
      } else {
        const { strength, quality } = getConnectionSignal(connection)
        let title = I18n.t('modals.deviceVitals.title')
        let text = I18n.t('modals.deviceVitals.textFirst') + quality + '%'
        if (!connectionIsWifiDevice(connection)) { title = I18n.t('modals.deviceVitals.titleAlt') }
        if (strength) { text += '\n' + I18n.t('modals.deviceVitals.textSecond') + strength + '%' }
        ModalService.update(
          title, 
          text,
          undefined,
          undefined,
          null,
          false
        )
      }
    }
  } else {
    yield put(ConnectionsActions.connectionSuccess(connections))
    AlertService.error(I18n.t('snacks.errors.connectionsDetailGetVitals'))
  }
}

export function * unblockDeviceBehaviours (api, action) {
  console.log('unblockDeviceBehaviours')
  const { connectionId, params } = action
  const { openClosed = false, autoAccess = false } = params
  let msg = ''
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const response = yield call(api.postDeviceOtherTask, connection.device._id, 'unblockBehaviours', params)
  const { ok } = response
  if (ok) {
    if (openClosed) { 
      msg = I18n.t('snacks.success.connectionsDetailUnblockOpenClosed')
      connection.device.openClosedBlocked = false 
    } else if (autoAccess) { 
      msg = I18n.t('snacks.success.connectionsDetailUnblockAutoAccess')
      connection.device.autoAccessBlocked = false 
    }
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(msg)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  } else {
    const newConnections = updateConnectionsWithConnection(connections, connection)
    if (openClosed) { msg = I18n.t('snacks.errors.connectionsDetailUnblockOpenClosed') } 
    else if (autoAccess) { msg = I18n.t('snacks.errors.connectionsDetailUnblockAutoAccess') }
    AlertService.error(msg)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  }
}

export function * updateDeviceFirmware (api, action) {
  const { connectionId } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const response = yield call(api.updateDeviceFirmware, connection.device._id)
  const { ok } = response
  if (ok) {
    connection.device.updating = true
    connection.device.online = false
    const newConnections = updateConnectionsWithConnection(connections, connection)
    ModalService.dismiss()
    AlertService.success(I18n.t('snacks.success.connectionsDetailUpdateDeviceFirmware'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionsDetailUpdateDeviceFirmware'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function * saveConnectionSchedule (api, action) {
  const { connectionId, schedule } = action
  const { _id } = schedule
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const response = _id ? 
    yield call(api.editConnectionSchedule, _id, schedule)
    :
    yield call(api.createConnectionSchedule, schedule)
  const { ok, data } = response
  if (ok) {
    yield call(delay, RaixerConfig.delays.afterFormTask)
    const { schedules } = connection
    const { insertedId } = data
    let newSchedules = upsertOrDeleteInArrayById(schedules, _id, schedule, insertedId)
    connection.schedules = newSchedules
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(I18n.t('snacks.success.connectionsDetailSaveSchedule'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionsDetailSaveSchedule'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function * deleteConnectionSchedule (api, action) {
  const { connectionId, schedule } = action
  const { _id } = schedule
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const response = yield call(api.deleteConnectionSchedule, _id)
  const { ok } = response
  if (ok) {
    yield call(delay, RaixerConfig.delays.afterFormTask)
    const { schedules } = connection
    let newSchedules = upsertOrDeleteInArrayById(schedules, _id)
    connection.schedules = newSchedules
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(I18n.t('snacks.success.connectionsDetailDeleteSchedule'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionsDetailDeleteSchedule'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function * saveConnectionUser (api, action) {
  const { connectionId, user } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const response = yield call(api.updateConnectionUser, user._id, user)
  const { ok, data: newUsers } = response
  if (ok) {
    if (user.profile === 'owner') {
      const response = yield call(api.getConnection, connectionId)
      let { ok: okConnection, data: newConnection } = response
      if (okConnection) {
        newConnection.users = newUsers
        const newConnections = updateConnectionsWithConnection(connections, newConnection)
        yield put(ConnectionsActions.connectionSuccess(newConnections))
        AlertService.success(I18n.t('snacks.success.connectionsDetailSaveUser'))
        yield put(ConnectionsActions.connectionSuccess(newConnections))
        FormModalService.close()
      } else {
        AlertService.error(I18n.t('snacks.errors.connectionsDetailSaveUser'))
        yield put(ConnectionsActions.connectionSuccess(connections))
        FormModalService.close()
      }
    } else {
      yield call(delay, RaixerConfig.delays.afterFormTask)
      connection.users = newUsers
      const newConnections = updateConnectionsWithConnection(connections, connection)
      AlertService.success(I18n.t('snacks.success.connectionsDetailSaveUser'))
      yield put(ConnectionsActions.connectionSuccess(newConnections))
      FormModalService.close()
    }
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionsDetailSaveUser'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function * deleteConnectionUser (api, action) {
  const { connectionId, user } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const response = yield call(api.deleteConnectionUser, user._id)
  const { ok, data: newUsers } = response
  if (ok) {
    yield call(delay, RaixerConfig.delays.afterFormTask)
    connection.users = newUsers
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(I18n.t('snacks.success.connectionsDetailDeleteUser'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionsDetailDeleteUser'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

// export function * enableConnectionUser (api, action) {
//   const { connectionId, user } = action
//   const { email } = user
//   let connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { device } = connection
//   const { _id } = device
//   const response = yield call(api.enableConnectionUser, _id, email)
//   const { ok, data: newUsers } = response
//   if (ok) {
//     yield call(delay, RaixerConfig.delays.afterFormTask)
//     connection.users = newUsers
//     const newConnections = updateConnectionsWithConnection(connections, connection)
//     AlertService.success(I18n.t('snacks.success.connectionsDetailEnableUser'))
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//     goBack()
//   } else {
//     AlertService.error(I18n.t('snacks.errors.connectionsDetailEnableUser'))
//     yield put(ConnectionsActions.connectionSuccess(connections))
//   }
// }

// export function * disableConnectionUser (api, action) {
//   const { connectionId, user } = action
//   const { email } = user
//   let connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { device } = connection
//   const { _id } = device
//   const response = yield call(api.disableConnectionUser, _id, email)
//   const { ok, data: newUsers } = response
//   if (ok) {
//     yield call(delay, RaixerConfig.delays.afterFormTask)
//     connection.users = newUsers
//     const newConnections = updateConnectionsWithConnection(connections, connection)
//     AlertService.success(I18n.t('snacks.success.connectionsDetailDisableUser'))
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//     goBack()
//   } else {
//     AlertService.error(I18n.t('snacks.errors.connectionsDetailDisableUser'))
//     yield put(ConnectionsActions.connectionSuccess(connections))
//   }
// }

export function * getConnectionQrs (api, action) {
  const { connectionId, transferQRs = false } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { device } = connection
  const { _id } = device
  const response = yield call(transferQRs ? api.getDeviceTransferQRs : api.getDeviceQrs, _id)
  const { ok, data: qrs } = response
  if (ok) {
    if (transferQRs) { connection.transferQrs = qrs }
    else { connection.qrs = qrs }
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  } else {
    yield put(ConnectionsActions.connectionFailure(null, true))
  }
}

export function * saveConnectionAuthorizedPhone (api, action) {
  const { connectionId, phones } = action
  let result = true
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  let phonesInAnotherDevice = false
  let phonesInSameDoor = false
  let phoneDoorUnavailable = false
  let phoneDoorNew = null
  let phoneNotInserted = null
  let phoneDoorNewDoorId = null
  for (let phone of phones) {
    const { _id, doorId } = phone
    const response = _id ? 
      yield call(api.editConnectionAuthorizedPhone, phone)
      :
      yield call(api.createConnectionAuthorizedPhone, phone)
    const { ok, data, status } = response
    if (ok) {
      const { device } = connection
      const { doors } = device
      const { insertedId, anotherDevice, phoneNumber, notInsertedPhone } = data
      if (!phoneNumber) {
        if (anotherDevice) { phonesInAnotherDevice = true }
        let door = getConnectionDoorById(connection, doorId)
        const { authorizedPhones } = door
        let newAuthorizedPhones = upsertOrDeleteInArrayById(authorizedPhones, _id, phone, insertedId)
        door.authorizedPhones = newAuthorizedPhones
        let newDoors = upsertOrDeleteInArrayById(doors, doorId, door)
        connection.device.doors = newDoors
      } else {
        phoneDoorNew = phoneNumber
        phoneNotInserted = notInsertedPhone
        phoneDoorNewDoorId = doorId
      }
    } else {
      const { unavailablePhoneNumber } = data || {}
      if (status === 409) { phonesInSameDoor = true }
      if (unavailablePhoneNumber) { phoneDoorUnavailable = true }
      result = false
    }
  }
  yield call(delay, RaixerConfig.delays.afterFormTask)
  const newConnections = updateConnectionsWithConnection(connections, connection)
  yield put(ConnectionsActions.connectionSuccess(newConnections))
  if (result) {
    if (phoneDoorNew) {
      // New phones not inserted because it reached the limit of 10 for a shared free phone
      const userId = yield select(AuthSelectors.selectUserId)
      const { device } = connection
      const { owner } = device
      if (userId === owner) {
        const { phoneNumber: newPhoneNumber } = phoneDoorNew
        let door = getConnectionDoorById(connection, phoneDoorNewDoorId)
        const { phone: phoneOld = {} } = door
        const { phoneNumber: phoneNumberOld } = phoneOld
        if (newPhoneNumber !== phoneNumberOld) {
          FormModalService.open('door-phone-change-confirm', { connectionId, doorId: phoneDoorNewDoorId, phone: newPhoneNumber, authorizedPhone: phoneNotInserted })
          // navigate('DoorPhoneChangeConfirmScreen', { connectionId: connectionId, doorId: phoneDoorNewDoorId, phone: newPhoneNumber, authorizedPhone: phoneNotInserted })
        } else {
          FormModalService.open('upgrade', { connectionId, doorId: phoneDoorNewDoorId, plan: { type: 'phone' }, anyCallerPhone: newPhoneNumber, newAuthorizedPhone: phoneNotInserted, cameFromAuthorizedPhoneDirectly: true })
          // navigate('UpgradeScreen', { connectionId, doorId: phoneDoorNewDoorId, plan: { type: 'phone' }, anyCallerPhone: newPhoneNumber, newAuthorizedPhone: phoneNotInserted, cameFromAuthorizedPhoneDirectly: true })
        }
      } else {
        ModalService.show(
          I18n.t('modals.doorPhoneUpgradeNotOwner.title'),
          I18n.t('modals.doorPhoneUpgradeNotOwner.text'),
          null,
          null,
          I18n.t('modals.buttons.defaultOk')
        )
        const newConnections = updateConnectionsWithConnection(connections, connection)
        yield put(ConnectionsActions.connectionSuccess(newConnections))
      }
    } else {
      // New phones inserted successfully
      AlertService.success(I18n.t('snacks.success.connectionsDetailSaveAuthorizedPhone'))
      FormModalService.close()
      if (phonesInAnotherDevice) {
        ModalService.show(
          I18n.t('modals.authorizedPhoneInAnotherDevice.title'),
          I18n.t('modals.authorizedPhoneInAnotherDevice.text'),
          null,
          null,
          I18n.t('modals.buttons.defaultOk')
        )
      }
    }
  } else {
    if (phonesInSameDoor) {
      // New phones not inserted because it was in same door
      ModalService.show(
        I18n.t('modals.authorizedPhoneError.title'),
        I18n.t('modals.authorizedPhoneError.text'),
        null,
        null,
        I18n.t('modals.authorizedPhoneError.buttons.cancel')
      )
    } else if (phoneDoorUnavailable) {
      // New phones not saved because limit was reached and no more door phones were available
      FormModalService.close()
      ModalService.show(
        I18n.t('modals.authorizedPhoneErrorDoorPhoneUnavailable.title'),
        I18n.t('modals.authorizedPhoneErrorDoorPhoneUnavailable.text'),
        null,
        null,
        I18n.t('modals.buttons.defaultOk')
      )
    } else {
      // Unexpected error
      AlertService.error(I18n.t('snacks.errors.connectionsDetailSaveAuthorizedPhone'))
    }
  }
}

export function * deleteConnectionAuthorizedPhone (api, action) {
  const { connectionId, phone } = action
  const { _id, doorId } = phone
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const response = yield call(api.deleteConnectionAuthorizedPhone, _id)
  const { ok } = response
  if (ok) {
    yield call(delay, RaixerConfig.delays.afterFormTask)
    const { device } = connection
    const { doors } = device
    let door = getConnectionDoorById(connection, doorId)
    const { authorizedPhones } = door
    let newAuthorizedPhones = upsertOrDeleteInArrayById(authorizedPhones, _id)
    door.authorizedPhones = newAuthorizedPhones
    let newDoors = upsertOrDeleteInArrayById(doors, doorId, door)
    connection.device.doors = newDoors
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(I18n.t('snacks.success.connectionsDetailDeleteAuthorizedPhone'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionsDetailDeleteAuthorizedPhone'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function * saveConnectionDoorPhoneStatus (api, action) {
  const { connectionId, doorId, status } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { device } = connection
  const { _id } = device
  const response = status ?
    yield call(api.enableConnectionDoorPhoneAccess, _id, doorId)
    :
    yield call(api.disableConnectionDoorPhoneAccess, _id, doorId)
  const { ok } = response
  if (ok) {
    yield call(delay, RaixerConfig.delays.afterFormTask)
    const { doors } = device
    let door = objectDeepCopy(getConnectionDoorById(connection, doorId))
    door.callingEnabled = status
    let newDoors = upsertOrDeleteInArrayById(doors, doorId, door)
    connection.device.doors = newDoors
    const newConnections = updateConnectionsWithConnection(connections, connection)
    const msg = status ? I18n.t('snacks.success.connectionsDetailEnableDoorPhone') : I18n.t('snacks.success.connectionsDetailDisableDoorPhone')
    AlertService.success(msg)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  } else {
    const msg = status ? I18n.t('snacks.errors.connectionsDetailEnableDoorPhone') : I18n.t('snacks.errors.connectionsDetailDisableDoorPhone')
    AlertService.error(msg)
  }
}

export function * getDoorPhoneInformation (api, action) {
  const { connectionId, doorId, phoneId } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { device } = connection
  const { _id } = device
  const response = yield call(api.getDeviceDoorPhoneConflict, _id, doorId, phoneId)
  const { ok, data } = response
  yield call(delay, RaixerConfig.delays.afterFormTask)
  if (ok) {
    const { doors } = device
    let door = objectDeepCopy(getConnectionDoorById(connection, doorId))
    door.phone.information = data
    let newDoors = upsertOrDeleteInArrayById(doors, doorId, door)
    connection.device.doors = newDoors
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  } else {
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function * getDoorPhoneCountries (api, action) {
  const { connectionId, doorId, change } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  const response = yield call(api.getDevicePhonesCountries, change ? doorId : null)
  const { ok, data } = response
  yield call(delay, RaixerConfig.delays.afterFormTask)
  if (ok) {
    let connection = objectDeepCopy(getActualConnection(connections, connectionId))
    connection.phonesCountries = data
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  } else {
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

function * getDoorPhone(apiCall, deviceId, { doorId, country, phoneId }) {
  return yield call(apiCall.getDeviceDoorPhone, deviceId, doorId, { country, otherDevicePhone: phoneId })
}

function * getDoorCode(apiCall, deviceId, { doorId, alias, url }) {
  return yield call(apiCall.getDeviceDoorCode, deviceId, doorId,
    { alias, key: url.split('/')[1] })
}

export function* getDoorData(api, type, action) {
  const { connectionId, doorId } = action
  let getDoorMethod = type === 'PHONE'
    ? getDoorPhone
    : getDoorCode
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { deviceId } = connection
  const response = yield getDoorMethod(api, deviceId, action)
  const { ok, data: newDoor } = response
  if (ok) {
    const { device } = connection
    const { doors } = device
    const newDoors = upsertOrDeleteInArrayById(doors, doorId, newDoor)
    connection.device.doors = newDoors
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(
      type === 'PHONE'
        ? I18n.t('snacks.success.connectionsDetailGetDoorPhone')
        : I18n.t('snacks.success.connectionsDetailGetDoorCode')
    )
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    AlertService.error(
      type === 'PHONE'
        ? I18n.t('snacks.errors.connectionsDetailGetDoorPhone')
        : I18n.t('snacks.errors.connectionsDetailGetDoorCode')
    )
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function * deleteDoorPhone (api, action) {
  const { connectionId, doorId } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { deviceId } = connection
  const response = yield call(api.deleteDeviceDoorPhone, deviceId, doorId)
  const { ok, data: newDoor } = response
  yield call(delay, RaixerConfig.delays.afterFormTask)
  if (ok) {
    const { device } = connection
    const { doors } = device
    let newDoors = upsertOrDeleteInArrayById(doors, doorId, newDoor)
    connection.device.doors = newDoors
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(I18n.t('snacks.success.connectionsDetailDeleteDoorPhone'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionsDetailDeleteDoorPhone'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function * saveConnectionDoorPhoneAnyCallerStatus (api, action) {
  const { connectionId, doorId, status } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { deviceId } = connection
  if (!status) {
    const response = yield call(api.disablePhoneAnyCallerAccess, deviceId, doorId)
    const { ok } = response
    yield call(delay, RaixerConfig.delays.afterFormTask)
    if (ok) {
      const { device } = connection
      const { doors } = device
      let door = doors.filter(d => d._id === doorId)[0]
      door.anyCallerEnabled = false
      let newDoors = upsertOrDeleteInArrayById(doors, doorId, door)
      connection.device.doors = newDoors
      const newConnections = updateConnectionsWithConnection(connections, connection)
      yield put(ConnectionsActions.connectionSuccess(newConnections))
    } else {
      AlertService.error(I18n.t('snacks.errors.connectionsDetailAnyCallerDoorPhone'))
      yield put(ConnectionsActions.connectionSuccess(connections))
    }
  } else {
    const response = yield call(api.getDeviceDoorAnyCallerPhone, deviceId, doorId)
    const { ok, data: doorPhoneAnyCaller } = response
    yield call(delay, RaixerConfig.delays.afterFormTask)
    if (ok) {
      const { phoneNumber: anyCallerPhone } = doorPhoneAnyCaller
      const { device } = connection
      const { doors, owner } = device
      const door = doors.filter(d => d._id === doorId)[0]
      const { phone = {} } = door
      const { phoneNumber, subscription } = phone
      const userId = yield select(AuthSelectors.selectUserId)
      if (phoneNumber === anyCallerPhone && subscription) {
        const responseAC = yield call(api.enablePhoneAnyCallerAccess, deviceId, doorId, { phoneNumber })
        const { ok: okAC } = responseAC
        if (okAC) {
          let newDoor = objectDeepCopy(door)
          newDoor.anyCallerEnabled = true
          let newDoors = upsertOrDeleteInArrayById(doors, doorId, newDoor)
          connection.device.doors = newDoors
          const newConnections = updateConnectionsWithConnection(connections, connection)
          yield put(ConnectionsActions.connectionSuccess(newConnections))
        } else {
          AlertService.error(I18n.t('snacks.errors.connectionsDetailAnyCallerDoorPhone'))
          yield put(ConnectionsActions.connectionSuccess(connections))
        }
      } else if (phoneNumber === anyCallerPhone && !subscription) {
        if (userId === owner) {
          const newConnections = updateConnectionsWithConnection(connections, connection)
          yield put(ConnectionsActions.connectionSuccess(newConnections))
          FormModalService.open('upgrade', { connectionId, doorId, plan: { type: 'phone' }, anyCallerPhone: phoneNumber })
          // navigate('UpgradeScreen', { connectionId, doorId, plan: { type: 'phone' }, anyCallerPhone: phoneNumber })
        } else {
          ModalService.show(
            I18n.t('modals.doorPhoneUpgradeNotOwner.title'),
            I18n.t('modals.doorPhoneUpgradeNotOwner.text'),
            null,
            null,
            I18n.t('modals.buttons.defaultOk')
          )
          const newConnections = updateConnectionsWithConnection(connections, connection)
          yield put(ConnectionsActions.connectionSuccess(newConnections))
        }
      } else {
        if (userId === owner) {
          const newConnections = updateConnectionsWithConnection(connections, connection)
          yield put(ConnectionsActions.connectionSuccess(newConnections))
          FormModalService.open('door-phone-change-confirm', { connectionId, doorId, phone: anyCallerPhone })
          // navigate('DoorPhoneChangeConfirmScreen', { connectionId, doorId, phone: anyCallerPhone })
        } else {
          ModalService.show(
            I18n.t('modals.doorPhoneUpgradeNotOwner.title'),
            I18n.t('modals.doorPhoneUpgradeNotOwner.text'),
            null,
            null,
            I18n.t('modals.buttons.defaultOk')
          )
        }
        const newConnections = updateConnectionsWithConnection(connections, connection)
        yield put(ConnectionsActions.connectionSuccess(newConnections))
      }
    } else {
      const { unavailablePhoneNumber = false } = doorPhoneAnyCaller || {}
      if (unavailablePhoneNumber) {
        yield put(ConnectionsActions.connectionSuccess(connections))
        ModalService.show(
          I18n.t('modals.accessToAnyCallerErrorDoorPhoneUnavailable.title'),
          I18n.t('modals.accessToAnyCallerErrorDoorPhoneUnavailable.text'),
          null,
          null,
          I18n.t('modals.buttons.defaultOk')
        )
      } else {
        AlertService.error(I18n.t('snacks.errors.connectionsDetailAnyCallerDoorPhone'))
        yield put(ConnectionsActions.connectionSuccess(connections))
      }
    }
  }
}

export function * claimPhoneOwnership (api, action) {
  const { connectionId, doorId, anyCallerPhone, newAuthorizedPhone } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { deviceId } = connection
  const response = yield call(api.claimPhoneOwnership, deviceId, doorId, { phoneNumber: anyCallerPhone, newAuthorizedPhone })
  const { ok, data } = response
  if (ok) {
    const { device } = connection
    const { doors } = device
    const { door } = data
    let newDoor = objectDeepCopy(door)
    let newDoors = upsertOrDeleteInArrayById(doors, doorId, newDoor)
    connection.device.doors = newDoors
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(I18n.t('snacks.success.connectionsDetailGetDoorPhone'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionsDetailAnyCallerDoorPhone'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function* saveConnectionAuthorizedCode(api, action) {
  const { connectionId, code } = action
  // const { doorId } = code
  const { _id, doorId } = code
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const response = _id ?
    yield call(api.editConnectionAuthorizedCode, code)
    :
    yield call(api.createConnectionAuthorizedCode, code)
  const { ok, data: newAuthorizedCodes } = response
  if (ok) {
    const { device } = connection
    const { doors } = device
    let door = getConnectionDoorById(connection, doorId)
    door.authorizedCodes = newAuthorizedCodes
    let newDoors = upsertOrDeleteInArrayById(doors, doorId, door)
    connection.device.doors = newDoors
    yield call(delay, RaixerConfig.delays.afterFormTask)
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    if (_id) {
      AlertService.success(I18n.t('snacks.success.connectionsDetailUpdateAuthorizedCode'))
    } else {
      AlertService.success(I18n.t('snacks.success.connectionsDetailSaveAuthorizedCode'))
    }
    FormModalService.close()
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionsDetailSaveAuthorizedCode'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function* deleteConnectionAuthorizedCode(api, action) {
  const { connectionId, code } = action
  const { _id, doorId } = code
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const response = yield call(api.deleteConnectionAuthorizedCode, _id)
  const { ok, data: newAuthorizedCodes } = response
  if (ok) {
    yield call(delay, RaixerConfig.delays.afterFormTask)
    const { device } = connection
    const { doors } = device
    let door = getConnectionDoorById(connection, doorId)
    door.authorizedCodes = newAuthorizedCodes
    let newDoors = upsertOrDeleteInArrayById(doors, doorId, door)
    connection.device.doors = newDoors
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(I18n.t('snacks.success.connectionsDetailDeleteAuthorizedCode'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionsDetailDeleteAuthorizedCode'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function* saveConnectionDoorCodeStatus(api, action) {
  const { connectionId, doorId, status } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { device } = connection
  const { _id } = device
  const response = status ?
    yield call(api.enableConnectionDoorCodeAccess, _id, doorId)
    :
    yield call(api.disableConnectionDoorCodeAccess, _id, doorId)
  const { ok } = response
  if (ok) {
    yield call(delay, RaixerConfig.delays.afterFormTask)
    const { doors } = device
    let door = objectDeepCopy(getConnectionDoorById(connection, doorId))
    door.accessByUrlEnabled = status
    let newDoors = upsertOrDeleteInArrayById(doors, doorId, door)
    connection.device.doors = newDoors
    const newConnections = updateConnectionsWithConnection(connections, connection)
    const msg = status ? I18n.t('snacks.success.connectionsDetailEnableDoorCode') : I18n.t('snacks.success.connectionsDetailDisableDoorCode')
    AlertService.success(msg)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  } else {
    const msg = status ? I18n.t('snacks.errors.connectionsDetailEnableDoorCode') : I18n.t('snacks.errors.connectionsDetailDisableDoorCode')
    AlertService.error(msg)
  }
}

export function* deleteDoorCode(api, action) {
  const { connectionId, doorId } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { deviceId } = connection
  const response = yield call(api.deleteDeviceDoorCode, deviceId, doorId)
  const { ok, data: newDoor } = response
  yield call(delay, RaixerConfig.delays.afterFormTask)
  if (ok) {
    const { device } = connection
    const { doors } = device
    let newDoors = upsertOrDeleteInArrayById(doors, doorId, newDoor)
    connection.device.doors = newDoors
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(I18n.t('snacks.success.connectionsDetailDeleteDoorCode'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionsDetailDeleteDoorCode'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function* saveConnectionDoorCodeAnyCallerStatus(api, action) {
  const { connectionId, doorId } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { deviceId } = connection
  const response = yield call(api.getDeviceDoorAnyCallerCode, deviceId, doorId)
  const { ok } = response
  yield call(delay, RaixerConfig.delays.afterFormTask)
  if (ok) {
    const { accessAnyoneByURL } = response.data
    const { device } = connection
    const { doors } = device
    let door = doors.filter(d => d._id === doorId)[0]
    door.accessAnyoneByURL = accessAnyoneByURL
    let newDoors = upsertOrDeleteInArrayById(doors, doorId, door)
    connection.device.doors = newDoors
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionsDetailAnyCallerDoorCode'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function * saveConnectionAutoAccessConfiguration (api, action) {
  const { connectionId, autoAccessAlwaysActive, offlineAutoAccess } = action
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { device } = connection
  const { _id, autoAccessAlwaysActive: prevAutoAccessAlwaysActive, offlineAutoAccess: prevOfflineAutoAccess } = device
  let result = true
  if (autoAccessAlwaysActive !== prevAutoAccessAlwaysActive) {
    const responseAlwaysActive = yield call(api.saveDeviceAutoAccessAlwaysActive, _id, autoAccessAlwaysActive)
    const { ok } = responseAlwaysActive
    if (!ok) { result = false }
  }
  if (offlineAutoAccess !== prevOfflineAutoAccess) {
    const responseOfflineAutoAccess = yield call(api.saveDeviceOfflineAutoAccess, _id, offlineAutoAccess)
    const { ok } = responseOfflineAutoAccess
    if (!ok) { result = false }
  }
  if (result) {
    connection.device.autoAccessAlwaysActive = autoAccessAlwaysActive
    connection.device.offlineAutoAccess = offlineAutoAccess
    if (!autoAccessAlwaysActive) { connection.device.inUseComplete = connection.device.inUseComplete.map(i => false) }
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(I18n.t('snacks.success.connectionsDetailAutoAccessConfiguration'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    yield put(ConnectionsActions.connectionSuccess(connections))
    ModalService.show(
      I18n.t('modals.autoAccessConfigurationUnaivalable.title'),
      I18n.t('modals.autoAccessConfigurationUnaivalable.text'),
      null,
      null,
      I18n.t('modals.autoAccessConfigurationUnaivalable.buttons.cancel')
    )
  }
}

// export function * saveConnectionDeviceConfigurationWizard (api, action) {
//   const { connectionId, wizardDiscarded } = action
//   let connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { deviceId } = connection
//   const response = yield call(api.saveDeviceConfigurationWizard, deviceId, wizardDiscarded)
//   const { ok } = response
//   if (ok) {
//     connection.device.wizardDiscarded = wizardDiscarded
//     const newConnections = updateConnectionsWithConnection(connections, connection)
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//     ModalService.dismiss()
//     goBack()
//   } else {
//     yield put(ConnectionsActions.connectionSuccess(connections))
//     ModalService.dismiss()
//     goBack()
//     AlertService.error(I18n.t('snacks.errors.connectionSaveDeviceConfigurationWizard'))
//   }
// }

// export function * deleteConnection (api, action) {
//   const { connectionId } = action
//   const connections = yield select(ConnectionsSelectors.selectConnections)
//   const connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const response = yield call(api.deleteConnection, connectionId)
//   const { ok } = response
//   yield call(delay, RaixerConfig.delays.afterFormTask)
//   if (ok) {
//     popToTop()
//     const newConnections = updateConnectionsWithConnection(connections, connection, true)
//     AlertService.success(I18n.t('snacks.success.connectionsDetailSettingsDelete'))
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//   } else {
//     AlertService.error(I18n.t('snacks.errors.connectionsDetailSettingsDelete'))
//     yield put(ConnectionsActions.connectionSuccess(connections))
//   }
// }

// export function * saveConnectionGeneralForm (api, action) {
//   const { connectionId, values } = action
//   const { name, ledBrightness = null } = values
//   const connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { _id } = connection
//   let params = { }
//   if (name) { params.name = name }
//   if (ledBrightness !== null) { params.dimmer = ledBrightness }
//   const response = yield call(api.updateConnection, _id, params)
//   const { ok } = response
//   yield call(delay, RaixerConfig.delays.afterFormTask)
//   if (ok) {
//     if (name) { connection.name = name }
//     if (ledBrightness !== null) { connection.device.dimmer = ledBrightness }
//     const newConnections = updateConnectionsWithConnection(connections, connection)
//     AlertService.success(I18n.t('snacks.success.connectionsDetailSettingsGeneralSave'))
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//     goBack()
//   } else {
//     AlertService.error(I18n.t('snacks.errors.connectionsDetailSettingsGeneralSave'))
//     yield put(ConnectionsActions.connectionSuccess(connections))
//   }
// }

// export function * updateDeviceLedBrightness (api, action) {
//   const { connectionId, brightness } = action
//   const connections = yield select(ConnectionsSelectors.selectConnections)
//   const connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { device } = connection
//   const { _id } = device
//   const response = yield call(api.postDeviceOtherTask, _id, 'updateDim', { value: brightness })
//   const { ok } = response
//   yield call(delay, RaixerConfig.delays.afterFormTask)
//   if (ok) {
//     yield put(ConnectionsActions.connectionSuccess(connections))
//   } else {
//     ModalService.show(
//       I18n.t('modals.dimmerUnaivalable.title'),
//       I18n.t('modals.dimmerUnaivalable.text'),
//       null,
//       null,
//       I18n.t('modals.dimmerUnaivalable.buttons.cancel')
//     )
//     yield put(ConnectionsActions.connectionSuccess(connections))
//   }
// }

// export function * updateDeviceEnergySaveMode (api, action) {
//   const { connectionId, energySaveMode, testing = false } = action
//   const connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { device } = connection
//   const { _id } = device
//   const response = yield call(api.updateDeviceEnergySaveMode, _id, energySaveMode, testing)
//   const { ok } = response
//   if (ok) {
//     if (!testing) {
//       connection.device.energySaveMode = energySaveMode
//       if (energySaveMode || testing) { connection.device.online = false }
//       const newConnections = updateConnectionsWithConnection(connections, connection)
//       AlertService.success(I18n.t('snacks.success.connectionsDetailSettingsGeneralSave'))
//       yield put(ConnectionsActions.connectionSuccess(newConnections))
//       goBack()
//     } else {
//       AlertService.success(I18n.t('snacks.success.connectionsDetailSettingsGeneralPowerSaveTest'))
//       yield put(ConnectionsActions.connectionSuccess(connections))
//     }
//   } else {
//     if (!testing) { AlertService.error(I18n.t('snacks.errors.connectionsDetailSettingsGeneralSave')) }
//     else { AlertService.error(I18n.t('snacks.errors.connectionsDetailSettingsGeneralPowerSaveTest')) }
//     yield put(ConnectionsActions.connectionSuccess(connections))
//   }
// }

// export function * getDeviceVariableForm (api, action) {
//   const { connectionId, variable, port, alternateDeviceId } = action
//   const connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { device } = connection
//   const { _id, version, formVariableCalls = 0, lastFormVariable, showedModalCallLimitVariableFormNoValue = false } = device
//   let variablePort = RaixerConfig.connections.portPositions.default
//   if (port && RaixerConfig.connections.portPositions[port]) {
//     variablePort = RaixerConfig.connections.portPositions[port]
//   }
//   if (version && version === RaixerConfig.connections.deviceVersions.v1.id) { 
//     variablePort = RaixerConfig.connections.portPositions.default 
//   }
//   let variableName = variablePort > 1 ? variable + variablePort : variable
//   if (variable === 'noise') { variableName = 'microphoneNoise' }
//   else if (variable === 'micro1' || variable === 'micro2') { variableName = 'micro' }
//   else if (variable === 'openingSensor') { variableName = variablePort > 1 ? 'doorIsOpen' + variablePort : 'doorIsOpen' }
//   const response = yield call(api.getDeviceVariable, alternateDeviceId ? alternateDeviceId : _id, variableName)
//   const { ok, data } = response
//   if (ok) {
//     const { result } = data
//     let formVariable = result
//     if (variable === 'micro1') { formVariable = result.split(',')[0] }
//     else if (variable === 'micro2') { formVariable = result.split(',')[1] }
//     connection.device.formVariable = formVariable
//     if (lastFormVariable === variable) { 
//       if (formVariable < RaixerConfig.connections.limitVariableFormNoValue) { connection.device.formVariableCalls = formVariableCalls + 1 }
//       else { 
//         connection.device.formVariableCalls = 0
//         connection.device.showedModalCallLimitVariableFormNoValue = false
//       }
//     } else { 
//       connection.device.formVariableCalls = 0 
//       connection.device.showedModalCallLimitVariableFormNoValue = false
//     }
//     connection.device.lastFormVariable = variable
//     if (
//       variable === 'buzzerValue' && connection.device.formVariableCalls >= RaixerConfig.connections.callLimitVariableFormNoValue &&
//       !showedModalCallLimitVariableFormNoValue
//     ) {
//       connection.device.showedModalCallLimitVariableFormNoValue = true
//       ModalService.show(
//         I18n.t('modals.limitVariableFormNoValue.title'),
//         I18n.t('modals.limitVariableFormNoValue.text'),
//         null,
//         null,
//         I18n.t('modals.buttons.defaultOk')
//       )
//     }
//     const newConnections = updateConnectionsWithConnection(connections, connection)
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//   } else {
//     connection.device.formVariable = -1
//     connection.device.lastFormVariable = undefined
//     connection.device.formVariableCalls = 0
//     connection.device.showedModalCallLimitVariableFormNoValue = false
//     const newConnections = updateConnectionsWithConnection(connections, connection)
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//   }
// }

// export function * saveConnectionSensor (api, action) {
//   const { connectionId, sensor } = action
//   const { _id } = sensor
//   const connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { device } = connection
//   const { _id: deviceId } = device
//   const response = _id ? 
//     yield call(api.updateDeviceSensor, deviceId, _id, sensor)
//     :
//     yield call(api.addDeviceSensor, deviceId, sensor)
//   const { ok, data } = response
//   if (ok) {
//     yield call(delay, RaixerConfig.delays.afterFormTask)
//     const { sensors } = device
//     const { insertedId } = data
//     let newSensors = upsertOrDeleteInArrayById(sensors, _id, sensor, insertedId)
//     connection.device.sensors = newSensors
//     if (sensor.movementSoundGroupingTime) { connection.device.movementSoundGroupingTime = sensor.movementSoundGroupingTime }
//     const newConnections = updateConnectionsWithConnection(connections, connection)
//     AlertService.success(I18n.t('snacks.success.connectionsDetailSettingsSensorSave'))
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//     if (_id) { goBack() }
//     else { pop(2) }
//   } else {
//     AlertService.error(I18n.t('snacks.errors.connectionsDetailSettingsSensorSave'))
//     yield put(ConnectionsActions.connectionSuccess(connections))
//   }
// }

// export function * deleteConnectionSensor (api, action) {
//   const { connectionId, sensor} = action
//   const { _id } = sensor
//   const connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { device } = connection
//   const { _id: deviceId } = device
//   const response = yield call(api.deleteDeviceSensor, deviceId, _id)
//   const { ok } = response
//   if (ok) {
//     yield call(delay, RaixerConfig.delays.afterFormTask)
//     const { sensors } = device
//     let newSensors = upsertOrDeleteInArrayById(sensors, _id)
//     connection.device.sensors = newSensors
//     const newConnections = updateConnectionsWithConnection(connections, connection)
//     AlertService.success(I18n.t('snacks.success.connectionsDetailSettingsSensorDelete'))
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//     goBack()
//   } else {
//     AlertService.error(I18n.t('snacks.errors.connectionsDetailSettingsSensorDelete'))
//     yield put(ConnectionsActions.connectionSuccess(connections))
//   }
// }

// export function * saveConnectionDoor (api, action) {
//   const { connectionId, door, stopAutoAccess } = action
//   const { _id } = door
//   let connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   let { device } = connection
//   let { _id: deviceId, doors, version } = device
//   const doorToSave = _id ? 
//     Object.assign(
//       {},
//       doors.filter(d => d._id === _id)[0],
//       door
//     )
//     : 
//     Object.assign(
//       {}, 
//       doors[0], 
//       { 
//         _id: null, phone: null, devicePhone: null, useElectricity: true, useSound: false, callingEnabled: false, 
//         anyCallerEnabled: false, intercomPickUpFirst: false, lastTimePhoneWasCalled: null, phoneFoundInactive: null
//       }, 
//       door
//     )
//   if (stopAutoAccess) {
//     const port = getPortOfDoor(door, version)
//     const task = RaixerConfig.connections.actionsNames.stopJob + '/' + port
//     yield call(postDeviceTaskConnectionDetail, api, connection, deviceId, task, null, true)
//     connections = yield select(ConnectionsSelectors.selectConnections)
//     connection = objectDeepCopy(getActualConnection(connections, connectionId))
//     device = connection.device
//     deviceId = device._id
//     doors = device.doors
//     version = device.version
//   }
//   const response = _id ? 
//     yield call(api.updateDeviceDoor, deviceId, _id, doorToSave)
//     :
//     yield call(api.addDeviceDoor, deviceId, doorToSave)
//   const { ok, data } = response
//   if (ok) {
//     yield call(delay, RaixerConfig.delays.afterFormTask)
//     const { insertedId } = data
//     let newDoors = upsertOrDeleteInArrayById(doors, _id, doorToSave, insertedId)
//     connection.device.doors = newDoors
//     const newConnections = updateConnectionsWithConnection(connections, connection)
//     AlertService.success(I18n.t('snacks.success.connectionsDetailSettingsDoorSave'))
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//     if (_id || (version !== RaixerConfig.connections.deviceVersions.v6.id && version !== RaixerConfig.connections.deviceVersions.v7.id)) { goBack() }
//     else { pop(2) }
//   } else {
//     AlertService.error(I18n.t('snacks.errors.connectionsDetailSettingsDoorSave'))
//     yield put(ConnectionsActions.connectionSuccess(connections))
//   }
// }

// export function * deleteConnectionDoor (api, action) {
//   const { connectionId, door } = action
//   const { _id } = door
//   const connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { device } = connection
//   const { _id: deviceId } = device
//   const response = yield call(api.deleteDeviceDoor, deviceId, _id)
//   const { ok } = response
//   if (ok) {
//     yield call(delay, RaixerConfig.delays.afterFormTask)
//     const { doors } = device
//     let newDoors = upsertOrDeleteInArrayById(doors, _id)
//     connection.device.doors = newDoors
//     const newConnections = updateConnectionsWithConnection(connections, connection)
//     AlertService.success(I18n.t('snacks.success.connectionsDetailSettingsDoorDelete'))
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//     goBack()
//   } else {
//     AlertService.error(I18n.t('snacks.errors.connectionsDetailSettingsDoorDelete'))
//     yield put(ConnectionsActions.connectionSuccess(connections))
//   }
// }

// // Step 0, start calibration on device
// // Step 1, wait calibrateTime given by device to get rest means
// // Step 2, get rest means calculated by device
// // Step 3, in an interval, get the means when device is beign called
// // Step 4, get the tolerances for when the device is beign called
// // Step 5, do nothing here, just present the screen with the obtained values to the user to configure the device
// export function * startAutoCalibration (api, action) {
//   const { connectionId, port } = action
//   const connections = yield select(ConnectionsSelectors.selectConnections)
//   let connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { device } = connection
//   const { _id: deviceId, version } = device
//   let cancelled = false
//   // Step 0
//   yield put(ConnectionsActions.connectionAutoCalibrationFinish(null, true, 0, null, false, null))
//   const autoCalibrationWaitTime = yield call(startCalibration, api, deviceId, port)
//   if (autoCalibrationWaitTime !== null) {
//     // Step 1
//     yield call(waitAutoCalibrationRest, autoCalibrationWaitTime)
//     // Step 2
//     cancelled = yield select(ConnectionsSelectors.selectAutoCalibrationCancelled)
//     if (!cancelled) {
//       yield put(ConnectionsActions.connectionAutoCalibrationFinish(null, true, 2, 0, false, null))
//       yield call(delay, RaixerConfig.delays.autoConfigurationBetweenSteps)
//       const restMeans = yield call(getRestMeans, api, deviceId, port)
//       const { restElectricityMean, restMicrophone1Mean, restMicrophone2Mean, restMeansError } = restMeans
//       if (!restMeansError) {
//         // Step 3
//         cancelled = yield select(ConnectionsSelectors.selectAutoCalibrationCancelled)
//         if (!cancelled) {
//           yield put(ConnectionsActions.connectionAutoCalibrationFinish(null, true, 3, 0, false, null))
//           yield call(delay, RaixerConfig.delays.autoConfigurationBetweenSteps)
//           const meansValues = yield call(getMeans, api, deviceId, version, port)
//           const { 
//             electricityMean, microphone1Mean, microphone2Mean, calibrationMeansError, 
//             calibrationMeansCancelled 
//           } = meansValues
//           if (!calibrationMeansCancelled) {
//             if (!calibrationMeansError) {
//               // Step 4
//               cancelled = yield select(ConnectionsSelectors.selectAutoCalibrationCancelled)
//               if (!cancelled) {
//                 yield put(ConnectionsActions.connectionAutoCalibrationFinish(null, true, 4, 0, false, null))
//                 yield call(delay, RaixerConfig.delays.autoConfigurationBetweenSteps)
//                 const tolerances = yield call(getTolerances, api, deviceId, port)
//                 const { electricityTolerance, microphone1Tolerance, microphone2Tolerance, tolerancesError } = tolerances
//                 if (!tolerancesError) {
//                   const autoCalibrationResults = { 
//                     restElectricityMean, restMicrophone1Mean, restMicrophone2Mean,
//                     electricityMean, microphone1Mean, microphone2Mean,
//                     electricityTolerance, microphone1Tolerance, microphone2Tolerance
//                   }
//                   yield put(ConnectionsActions.connectionAutoCalibrationFinish(null, true, 4, 0, false, autoCalibrationResults))
//                   cancelled = yield select(ConnectionsSelectors.selectAutoCalibrationCancelled)
//                   if (!cancelled) {
//                     // Step 5
//                     yield call(delay, RaixerConfig.delays.autoConfigurationBetweenSteps * 3)
//                     yield put(ConnectionsActions.connectionAutoCalibrationFinish(null, false, 5, 0, false, autoCalibrationResults))
//                   }
//                 } else {
//                   // Error on Step 4
//                   cancelled = yield select(ConnectionsSelectors.selectAutoCalibrationCancelled)
//                   if (!cancelled) {
//                     yield put(ConnectionsActions.connectionAutoCalibrationFinish(true, false, 4, null, false, null))
//                   }
//                 }
//               }
//             } else {
//               // Error on Step 3
//               cancelled = yield select(ConnectionsSelectors.selectAutoCalibrationCancelled)
//               if (!cancelled) {
//                 yield put(ConnectionsActions.connectionAutoCalibrationFinish(true, false, 3, null, false, null))
//               }
//             }
//           }
//         }
//       } else {
//         // Error on Step 2
//         cancelled = yield select(ConnectionsSelectors.selectAutoCalibrationCancelled)
//         if (!cancelled) {
//           if (restElectricityMean === null && restMicrophone1Mean === null && restMicrophone2Mean === null) {
//             // Unexpected error
//             yield put(ConnectionsActions.connectionAutoCalibrationFinish(true, false, null, null, false, null))
//           } else {
//             // Could not get rest means
//             yield put(ConnectionsActions.connectionAutoCalibrationFinish(true, false, 2, null, false, null))
//           }
//         }
//       }
//     }
//   } else {
//     // Error on Step 0, unexpected error
//     cancelled = yield select(ConnectionsSelectors.selectAutoCalibrationCancelled)
//     if (!cancelled) {
//       yield put(ConnectionsActions.connectionAutoCalibrationFinish(true, false, null, null, false, null))
//     }
//   }
// }

export function * saveLocation (api, action) {
  const { connectionId, location } = action
  const { _id } = location
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { deviceId } = connection || {}
  if (deviceId) { location.deviceId = deviceId }
  delete location._id
  delete location.bookings
  let formData = new FormData()
  for (let k of Object.keys(location)) { formData.append(k, location[k]) }
  const response = _id ?
    yield call(api.editLocation, _id, formData)
    : 
    yield call(api.createLocation, connectionId, formData) 
  const { ok, data } = response
  if (ok) {
    yield call(delay, RaixerConfig.delays.afterFormTask)
    connection.location = data
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(I18n.t('snacks.success.connectionLocationSave'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    const { error, status, msg } = data || {}
    if (error && status && msg) {
      if (status === 400 || status === 500) {
        ModalService.show(
          I18n.t('modals.saveLocationBadCredentials.title'),
          I18n.t('modals.saveLocationBadCredentials.text'),
          null,
          null,
          I18n.t('modals.buttons.defaultOk')
        )
        yield put(ConnectionsActions.connectionSuccess(connections))
      } else {
        AlertService.error(I18n.t('snacks.errors.connectionLocationSave'))
        yield put(ConnectionsActions.connectionSuccess(connections))
      }
    } else {
      AlertService.error(I18n.t('snacks.errors.connectionLocationSave'))
      yield put(ConnectionsActions.connectionSuccess(connections))
    }
  }
}

export function * deleteLocation (api, action) {
  const { connectionId, location } = action
  const { _id } = location
  const connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const response = yield call(api.deleteLocation, _id)
  const { ok } = response
  if (ok) {
    yield call(delay, RaixerConfig.delays.afterFormTask)
    connection.location = null
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(I18n.t('snacks.success.connectionLocationDelete'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionLocationDelete'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

// export function * pairLocation(api, action) {
//   const { connectionId, deviceId } = action
//   const connections = yield select(ConnectionsSelectors.selectConnections)
//   const connection = objectDeepCopy(getActualConnection(connections, connectionId))
//   const { checkinSubscriptionId } = connection
//   const response = yield call(api.pairLocation, checkinSubscriptionId, deviceId)
//   const { ok, data: newConnections } = response
//   if (ok) {
//     popToTop()
//     yield call(delay, RaixerConfig.delays.afterFormTask / 2)
//     let newConnection
//     for (let p of newConnections) {
//       for (let c of p) {
//         if (c.deviceId === deviceId) { 
//           newConnection = c
//           break
//         }
//       }
//       if (newConnection) { break }
//     }
//     yield put(ConnectionsActions.connectionSuccess(newConnections))
//     AlertService.success(I18n.t('snacks.success.connectionPairLocation'))
//     if (newConnection) { navigate('ConnectionScreen', { connectionId: newConnection._id, fetch: true }) }
//   } else {
//     AlertService.error(I18n.t('snacks.errors.connectionPairLocation'))
//     yield put(ConnectionsActions.connectionFailure(connections))
//   }
// }

export function * saveBooking (api, action) {
  const { connectionId } = action
  let { booking } = action
  const { _id } = booking
  const connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { location, deviceId } = connection
  const { _id: locationId, autoAccess, bookings = [] } = location
  delete booking._id
  booking.locationId = locationId
  booking.deviceId = deviceId
  booking.autoAccess = !deviceId ? false : autoAccess
  const response = _id ?
    yield call(api.editBooking, _id, booking)
    :
    yield call(api.saveBooking, booking)
  const { ok, data = {} } = response
  if (ok) {
    yield call(delay, RaixerConfig.delays.afterFormTask)
    const { insertedId } = data
    if (!_id) {
      booking._id = insertedId
      booking.checkedIn = 0
    } else {
      const originalBooking = bookings.filter(b => b._id === _id)[0]
      if (originalBooking) { booking = Object.assign(originalBooking, booking) }
    }
    let newBookings = upsertOrDeleteInArrayById(bookings, _id, booking, insertedId)
    connection.location.bookings = newBookings
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(I18n.t('snacks.success.connectionBookingSave'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    console.log('saveBooking 3')
    const { error = {} } = data
    const { msg = 'connectionBookingSave' } = error
    AlertService.error(I18n.t('snacks.errors.' + msg))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function * deleteBooking (api, action) {
  const { connectionId, booking } = action
  const { _id } = booking
  const connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const response = yield call(api.deleteBooking, _id)
  const { ok } = response
  if (ok) {
    yield call(delay, RaixerConfig.delays.afterFormTask)
    const { location = {} } = connection
    const { bookings = [] } = location
    const newBookings = upsertOrDeleteInArrayById(bookings, _id)
    connection.location.bookings = newBookings
    const newConnections = updateConnectionsWithConnection(connections, connection)
    AlertService.success(I18n.t('snacks.success.connectionBookingDelete'))
    yield put(ConnectionsActions.connectionSuccess(newConnections))
    FormModalService.close()
  } else {
    AlertService.error(I18n.t('snacks.errors.connectionBookingDelete'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function * getBookings (api, action) {
  const { connectionId, page, beforeToday } = action
  const connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  let { location = {} } = connection || {}
  const { _id: locationId } = location
  const response = yield call(api.getBookings, locationId, page, beforeToday)
  const { ok, data: bookings } = response
  if (ok) {
    yield call(delay, RaixerConfig.delays.afterFormTask * 3)
    if (beforeToday) {
      if (!location.bookingsHistory || page === 0) { location.bookingsHistory = bookings }
      else { location.bookingsHistory = arrayDeepCopy(location.bookingsHistory).concat(bookings) }
    } else {
      location.bookings = arrayDeepCopy(location.bookings).concat(bookings)
    }
    connection.location = location
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  } else {
    AlertService.error(I18n.t('snacks.errors.getBookings'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function * getGuestBook (api, action) {
  const { connectionId } = action
  const connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { location = {} } = connection
  const { _id } = location
  const response = yield call(api.getGuestBook, _id)
  const { ok, data } = response
  if (ok) {
    yield call(delay, RaixerConfig.delays.afterFormTask)
    const { uri } = data
    connection.location.guestBookUri = uri
    const newConnections = updateConnectionsWithConnection(connections, connection)
    yield put(ConnectionsActions.connectionSuccess(newConnections))
  } else {
    AlertService.error(I18n.t('snacks.errors.getGuestBook'))
    yield put(ConnectionsActions.connectionSuccess(connections))
  }
}

export function * sendGuestBook (api, action) {
  const { connectionId } = action
  const connections = yield select(ConnectionsSelectors.selectConnections)
  let connection = objectDeepCopy(getActualConnection(connections, connectionId))
  const { location = {} } = connection
  const { _id } = location
  const response = yield call(api.sendGuestBook, _id)
  const { ok } = response
  yield call(delay, RaixerConfig.delays.afterFormTask)
  if (ok) { AlertService.success(I18n.t('snacks.success.sendGuestBook')) } 
  else { AlertService.error(I18n.t('snacks.errors.sendGuestBook')) }
  yield put(ConnectionsActions.connectionSuccess(connections))
}

// function * startCalibration (api, deviceId, port) {
//   const apiAction = port > 1 ? 'calibrate' + port : 'calibrate'
//   const response = yield call(api.postDeviceOtherTask, deviceId, apiAction)
//   const { ok, data } = response
//   if (ok) {
//     let autoCalibrationWaitTime = RaixerConfig.connections.waitCalibrate
//     if (data && data.length > 0) {
//       const { result } = data[0]
//       autoCalibrationWaitTime = parseInt(result)
//     } else {
//       const { result } = data
//       autoCalibrationWaitTime = parseInt(result)
//     }
//     return autoCalibrationWaitTime
//   } else {
//     return null
//   }
// }

// function * waitAutoCalibrationRest (autoCalibrationWaitTime) {
//   for (let i = 0; i <= autoCalibrationWaitTime; i++) {
//     const cancelled = yield select(ConnectionsSelectors.selectAutoCalibrationCancelled)
//     if (!cancelled) { 
//       yield put(ConnectionsActions.connectionAutoCalibrationFinish(null, true, 1, autoCalibrationWaitTime - i, false, null))
//       yield call(delay, 1000) 
//     } else { 
//       i = autoCalibrationWaitTime
//     }
//   }
// }

// function * getRestMeans (api, deviceId, port) {
//   const apiAction = port > 1 ? 'getCalibrationMean' + port : 'getCalibrationMean'
//   const response = yield call(api.postDeviceOtherTask, deviceId, apiAction)
//   const { ok, data } = response
//   if (ok) {
//     let restElectricityMean = -1, restMicrophone1Mean = -1, restMicrophone2Mean = -1
//     if (data && data.length > 0) {
//       const { result: resultMean } = data[0]
//       restElectricityMean = parseInt(resultMean)
//       if (data.length > 1) {
//         const { result: resultMicroMean } = data[1]
//         if (resultMicroMean) {
//           const microSplitted = resultMicroMean.split(',')
//           if (microSplitted && microSplitted.length > 2) {
//             restMicrophone1Mean = microSplitted[0]
//             restMicrophone2Mean = microSplitted[1]
//           }
//         }
//       }
//     } else {
//       const { result: resultMean } = data
//       restElectricityMean = parseInt(resultMean)
//     }
//     return { 
//       restElectricityMean, 
//       restMicrophone1Mean, 
//       restMicrophone2Mean, 
//       restMeansError: !(restElectricityMean > -1 || (restMicrophone1Mean > -1 && restMicrophone2Mean > -1)) 
//     }
//   } else {
//     return { restElectricityMean: null, restMicrophone1Mean: null, restMicrophone2Mean: null, restMeansError: true }
//   }
// }

// function * getMeans (api, deviceId, version, port) {
//   const { deviceVersions } = RaixerConfig.connections
//   const hasMicrophone = version === deviceVersions.v3.id || version === deviceVersions.v4.id || version === deviceVersions.v5.id || version === deviceVersions.v6.id || version === deviceVersions.v7.id
//   const maxTime = RaixerConfig.api.autoConfigurationMaxTime / 1000
//   const maxExtraTime = RaixerConfig.api.autoConfigurationMaxExtraTime / 1000
//   let electricityMean = -1, microphone1Mean = -1, microphone2Mean = -1
//   let cancelled = false
//   let shouldWaitExtraTime = hasMicrophone ? true : false
//   let extraTimePassed = 0
//   for (let i = 0; i < maxTime; i++) {
//     cancelled = yield select(ConnectionsSelectors.selectAutoCalibrationCancelled)
//     if (!cancelled) {
//       if (i % 3 === 0) {
//         // Call the API every 3 seconds
//         const action = port > 1 ? 'getCalibrationValue' + port : 'getCalibrationValue'
//         const response = yield call(api.postDeviceOtherTask, deviceId, action)
//         const { ok, data } = response
//         if (ok) {
//           if (data && data.length > 0) {
//             const { result } = data[0]
//             electricityMean = parseInt(result)
//             if (data.length > 1) {
//               const { result: resultMicroMean } = data[1]
//               if (resultMicroMean) {
//                 const microSplitted = resultMicroMean.split(',')
//                 if (microSplitted && microSplitted.length > 3) {
//                   microphone1Mean = parseInt(microSplitted[2])
//                   microphone2Mean = parseInt(microSplitted[3])
//                 }
//               }
//             }
//           } else {
//             const { result } = data
//             electricityMean = parseInt(result)
//           }
//           const foundElectricity = electricityMean > -1
//           const foundSound = microphone1Mean > -1 && microphone2Mean > -1
//           if (foundElectricity || foundSound) {
//             // Wait an additional time to see if the device can get a value for the electricity or the sound
//             if (shouldWaitExtraTime && !(foundElectricity && foundSound)) {
//               if (extraTimePassed === 0) { 
//                 // const autoCalibrationResults = { electricityMean, microphone1Mean, microphone2Mean }
//                 yield put(ConnectionsActions.connectionAutoCalibrationFinish(null, true, 4, 0, false, {})) 
//               }
//               if (extraTimePassed <= maxExtraTime) { extraTimePassed++ }
//               else { i = maxTime }
//             } else {
//               i = maxTime
//             }
//           }
//         }
//       }
//     } else { 
//       i = maxTime
//     }
//     if (i < maxTime && i % 3 !== 0) { yield call(delay, RaixerConfig.delays.afterFormTask) }
//   }
//   return { 
//     electricityMean, 
//     microphone1Mean, 
//     microphone2Mean,
//     calibrationMeansError: !(electricityMean > -1 || (microphone1Mean > -1 && microphone2Mean > -1)),
//     calibrationMeansCancelled: cancelled
//   }
// }

// function * getTolerances (api, deviceId, port) {
//   const apiAction = port > 1 ? 'getCalibrationTolerance' + port : 'getCalibrationTolerance'
//   const response = yield call(api.postDeviceOtherTask, deviceId, apiAction)
//   const { ok, data } = response
//   if (ok) {
//     let electricityTolerance = -1, microphone1Tolerance = -1, microphone2Tolerance = -1
//     if (data && data.length > 0) {
//       const { result: resultTolerance } = data[0]
//       electricityTolerance = parseInt(resultTolerance)
//       if (data.length > 1) {
//         const { result: resultToleranceMicro1 } = data[1]
//         const { result: resultToleranceMicro2 } = data[2]
//         microphone1Tolerance = parseInt(resultToleranceMicro1)
//         microphone2Tolerance = parseInt(resultToleranceMicro2)
//       }
//     } else {
//       const { result: resultTolerance } = data
//       electricityTolerance = parseInt(resultTolerance)
//     }
//     return { 
//       electricityTolerance, 
//       microphone1Tolerance, 
//       microphone2Tolerance, 
//       tolerancesError: !(electricityTolerance > -1 || (microphone1Tolerance > -1 && microphone2Tolerance > -1)) 
//     }
//   } else {
//     return { electricityTolerance: null, microphone1Tolerance: null, microphone2Tolerance: null, tolerancesError: true }
//   }
// }

// new code
function * _executeDeviceGyroTask (api, connection, deviceId, task, params, dontAlert = false) {
  let executed = false
  const appState = yield call(delayAndGetAppState)
  params = params || {}
  params.appState = appState
  const response = yield call(api.postDeviceGyroTask, deviceId, task, params)
  let connections = yield select(ConnectionsSelectors.selectConnections)
  let newConnection = objectDeepCopy(getActualConnection(connections, connection._id))
  const { ok, status } = response
  if (ok) {
    const newConnections = updateConnectionsWithConnection(connections, newConnection)
    const msg = getResultMsgOfTaskConnectionDetail(newConnection, task, false)
    yield put(ConnectionsActions.connectionsTaskFinished(newConnections))
    if (ok || !dontAlert) { AlertService.success(msg) }
    executed = true
  } else {
    const msg = getResultMsgOfTaskConnectionDetail(newConnection, task, true, false, status === 401)
    if (!dontAlert) {
      yield put(ConnectionsActions.connectionsTaskFinished(connections, true))
      AlertService.error(msg)
    }
  }
  if (ok || !dontAlert) {
    yield call(delay, RaixerConfig.delays.cleanConnectionTask)
    yield put(ConnectionsActions.connectionsTaskClean())
  }
  return executed
}

export function * executeInternetTask (api, bleApi, action) {
  console.log('executeInternetTask')
  const { connection, task, params } = action
  const { device } = connection
  const { _id: deviceId, doors } = device
  const hasGyroDoor = doors.filter(d => d.sensorMode === 'gyro').length > 0
  if (!hasGyroDoor) {
    yield call(_executeDeviceGyroTask, api, connection, deviceId, task, params)
  } else {
    const internetCallResponse = yield call(_executeDeviceGyroTask, api, connection, deviceId, task, params, true)
    const { ok: internetCallOk } = internetCallResponse
    yield put(ConnectionsActions.connectionsTaskClean())
  }
}

export function * executeInternetAndBleTask (api, bleApi, action) {
  const { connection, task, bleTask, params } = action
  const { _id: connectionId, device } = connection
  const { _id: deviceId } = device
  const internetCallOk = yield call(_executeDeviceGyroTask, api, connection, deviceId, task, params, true)
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form/lib'
import I18n from '../../../../i18n/i18n'
import { objectDeepCompare } from '../../../../Lib/ObjectsUtils'
import ContentCard from '../../../UI/ContentCards/ContentCard'
import AppText from '../../../UI/Texts/AppText'
import BaseSwitch from '../../../UI/Switchs/BaseSwitch'
import BaseForm from '../../../UI/Forms/BaseForm'
import FullScreenLoader from '../../../UI/Loaders/FullScreenLoader'
import ConnectionsStatus from '../../../UI/Connections/ConnectionsStatus'
import GhostButton from '../../../UI/Buttons/GhostButton'
import styles from '../../../Styles/Screens/Forms'
import { Metrics } from '../../../../Themes'

export default class DoorPhone extends Component {

  static propTypes = {
    activeLang: PropTypes.string.isRequired,
    connection: PropTypes.object,
    phone: PropTypes.object,
    fetching: PropTypes.bool.isRequired,
    getDoorPhone: PropTypes.func.isRequired,
    deleteDoorPhone: PropTypes.func.isRequired
  }

  static defaultProps = {
    activeLang: null,
    connection: null,
    phone: null,
    fetching: false,
    getDoorPhone: null,
    deleteDoorPhone: null
  }

  constructor(props) {
    super(props)
    this.state = {
      value: {
        country: 'null'
      },
      useOtherDevicePhone: false
    }
  }

  componentDidUpdate = (prevProps) => {
    const { connection: prevConnection } = prevProps
    const { connection } = this.props
    if (prevConnection && connection) {
      const { phonesCountries } = connection
      const { phonesCountries: prevPhonesCountries } = prevConnection
      if (!prevPhonesCountries && phonesCountries) {
        const { devicePhonesCountries = [], devicePhonesOwnerOtherDevicesPhones = [] } = phonesCountries
        if (devicePhonesCountries.length === 0 && devicePhonesOwnerOtherDevicesPhones.length > 0) {
          this.setState({ useOtherDevicePhone: true })
        }
      }
    }
  }

  onChange = (value) => {
    this.setState({ value })
  }

  onSubmit = (value) => {
    const { country } = value
    this.props.getDoorPhone(country !== 'null' ? country : '', null)
  }

  onChangeUseOtherDevicePhone = (value) => {
    this.setState({ useOtherDevicePhone: value })
  }

  getFields = (devicePhonesCountries) => {
    let enumsCountries = { 'null': I18n.t('shared.any') }
    for (let c of devicePhonesCountries) {
      const label = c[this.props.activeLang] ? c[this.props.activeLang] : c['en']
      enumsCountries[c.isoCode] = label
    }
    return {
      country: t.enums(enumsCountries)
    }
  }

  getFieldsOptions = () => {
    return {
      country: {
        label: I18n.t('shared.country'),
        nullOption: false
      }
    }
  }

  renderSelectCountry = (devicePhonesCountries) => {
    return (
      <>
        <AppText text={I18n.t('screens.phoneDoor.text')} customStyle={{ marginBottom: Metrics.doubleBaseMargin }} />
        <BaseForm
          name={'door-phone-form'}
          onSubmit={this.onSubmit}
          fields={this.getFields(devicePhonesCountries)}
          fieldsOptions={this.getFieldsOptions()}
          value={this.state.value}
          onChange={this.onChange}
          submitText={I18n.t('shared.save')}
          submitIcon={'save'}
        />
      </>
    )
  }

  renderSelectOtherDevicePhone = (devicePhonesOwnerOtherDevicesPhones, phonesForChange) => {
    const phones = this.props.phone ? phonesForChange : devicePhonesOwnerOtherDevicesPhones
    return (
      <>
        <AppText text={I18n.t('screens.phoneDoor.textOtherDevice')} customStyle={{ marginBottom: Metrics.doubleBaseMargin }} />
        <div style={{ paddingHorizontal: Metrics.cuadrupleBaseMargin, marginTop: Metrics.baseMargin }}>
          {
            phones.map(
              (d, i) => {
                const { phoneId, phoneNumber, connections } = d
                let text = '+' + phoneNumber
                for (let c of connections) {
                  const { connectionName, doorName } = c
                  text += '\n' + connectionName + ' - ' + I18n.t('shared.door') + ' ' + doorName
                }
                return (
                  <GhostButton
                    key={'other-device-phone-' + i}
                    buttonText={text}
                    onPress={() => { this.props.getDoorPhone(null, phoneId) }}
                    disabled={false}
                    icon={null}
                    numberOfLines={10}
                    customButtonStyle={{ whiteSpace: 'pre-wrap' }}
                  />
                )
              }
            )
          }
        </div>
      </>
    )
  }

  renderContent = () => {
    const { phonesCountries = {} } = this.props.connection || {}
    const { devicePhonesCountries = [], devicePhonesOwnerOtherDevicesPhones = [], phonesForChange = [] } = phonesCountries
    if (!this.props.fetching && objectDeepCompare(phonesCountries, {})) {
      return <ConnectionsStatus text={I18n.t('screens.phoneDoor.textError')} icon={'warning'} withoutMargin={true}/>
    }
    if (!this.props.fetching && !this.props.phone && devicePhonesCountries.length === 0 && devicePhonesOwnerOtherDevicesPhones.length === 0) {
      return <ConnectionsStatus text={I18n.t('screens.phoneDoor.textNoPhones')} icon={'block'} withoutMargin={true}/>
    }
    if (!this.props.fetching && this.props.phone && devicePhonesCountries.length === 0 && phonesForChange.length === 0) {
      return <ConnectionsStatus text={I18n.t('screens.phoneDoor.textNoPhones')} icon={'block'} withoutMargin={true}/>
    }
    const showSwitch = this.props.phone ? phonesForChange.length > 0 : devicePhonesOwnerOtherDevicesPhones.length > 0
    return (
      <>
        {
          showSwitch &&
          <BaseSwitch
            value={this.state.useOtherDevicePhone}
            disabled={devicePhonesCountries.length === 0}
            label={null}
            help={I18n.t('screens.phoneDoor.helps.useOtherDevicePhone')}
            onChange={this.onChangeUseOtherDevicePhone}
            withoutMargin={true}
            customSwitchRowStyle={Object.assign(
              {},
              styles.switchRow,
              { marginTop: Metrics.doubleBaseMargin, marginBottom: Metrics.cuadrupleBaseMargin }
            )}
            customHelpStyle={styles.switchHelp}
            customSwitchStyle={styles.switch}
          />
        }
        { !this.state.useOtherDevicePhone && this.renderSelectCountry(devicePhonesCountries) }
        { this.state.useOtherDevicePhone && this.renderSelectOtherDevicePhone(devicePhonesOwnerOtherDevicesPhones, phonesForChange) }
        { 
          this.props.phone && 
          <GhostButton
            buttonText={I18n.t('screens.phoneDoor.buttons.delete')}
            onPress={this.props.deleteDoorPhone}
            disabled={false}
            customButtonStyle={
              !this.state.useOtherDevicePhone
                ? { marginBottom: 0 }
                : { marginVertical: 0, marginBottom: 0, marginTop: Metrics.cuadrupleBaseMargin }
            }
          />
        }
      </>
    )
  }

  render = () => {
    return (
      <>
        <ContentCard
          title={this.props.phone ? I18n.t('screens.phoneDoor.titleEdit') : I18n.t('screens.phoneDoor.titleNew')}
          renderContent={this.renderContent}
        />
        <FullScreenLoader visible={this.props.fetching}/>
      </>
    )
  }

}
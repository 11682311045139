import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setActiveLang: ['activeLang']
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  activeLang: 'en'
})

/* ------------- Selectors ------------- */
export const I18nSelectors = {
  selectActiveLang: state => state.i18n.activeLang
}

/* ------------- Reducers ------------- */

export const setActiveLang = (state, action) => {
  const { activeLang } = action
  return state.merge({ activeLang })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ACTIVE_LANG]: setActiveLang
})

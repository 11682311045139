import { Colors, Metrics, Shadows } from '../../../Themes/'

export default {
  menuContainer: {
    height: '100vh',
    width: Metrics.baseMenuWidth,
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: Colors.theme1,
    borderStyle: 'solid',
    borderWidth: 0,
    zIndex: Metrics.zIndexes.leftMenu,
    ...Shadows.leftMenu
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    height: '100%'
  },
  logoIcon: {
    width: 36,
    height: 36,
    marginTop: Metrics.doubleBaseMargin
  },
  firstItem: {
    marginTop: Metrics.cuadrupleBaseMargin
  },
  lastItem: {
    marginTop: 'auto',
    marginBottom: Metrics.cuadrupleBaseMargin
  }
}
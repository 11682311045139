import { createMuiTheme } from '@material-ui/core/styles'
import { Colors } from '../Themes'

// TODO: shadow colors of palette
const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.theme1
    },
    secondary: {
      main: Colors.facebook
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});

export default theme
import RaixerConfig from '../Config/RaixerConfig'
import I18n from '../i18n/i18n'

export const getResultMsgOfTaskConnectionsList = (task, error, unauthorizedError = false) => {
  let msgLabel
  if (unauthorizedError) {
    msgLabel = 'snacks.errors.connectionsDetailUnauthorized'
  } else {
    if (task.indexOf(RaixerConfig.connections.actionsNames.startJob) > -1) {
      msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsListStartTask'
    } else if (task.indexOf(RaixerConfig.connections.actionsNames.stopJob) > -1) {
      msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsListStopTask'
    }
  }
  return I18n.t(msgLabel)
}

export const getResultMsgOfTaskConnectionDetail = (connection, task, error, nukiSecondStep = false, unauthorizedError = false) => {
  const actionsNames = RaixerConfig.connections.actionsNames
  const {
    startJob, stopJob, activatePiston, deactivatePiston, openDoor,
    openGyro, closeGyro, unlockGyro, bleOpenDoor, bleCloseDoor, bleUnlockDoor,
    nukiOpenDoor, nukiActivatePiston, nukiDeactivatePiston, confGyro
  } = actionsNames
  const { device } = connection || {}
  const { version } = device || {}
  let msgLabel
  if (unauthorizedError) {
    msgLabel = 'snacks.errors.connectionsDetailUnauthorized'
  } else {
    if (task.indexOf(startJob) > -1) {
      msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailStartTask'
    } else if (task.indexOf(stopJob) > -1) {
      msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailStopTask'
    } else if (task.indexOf(activatePiston) > -1) {
      msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailActivatePistonTask'
    } else if (task.indexOf(deactivatePiston) > -1) {
      msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailDeactivatePistonTask'
    } else if (task.indexOf(openDoor) > -1) {
      msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailOpenDoorTask'
    } else if (task.indexOf(openGyro) > -1 || (version === 'gyro' && task.indexOf(bleOpenDoor) > -1)) {
      msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailGyroOpenDoorTaskStarted'
    } else if (task.indexOf(closeGyro) > -1 || (version === 'gyro' && task.indexOf(bleCloseDoor) > -1)) {
      msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailGyroCloseDoorTaskStarted'
    } else if (task.indexOf(unlockGyro) > -1 || (version === 'gyro' && task.indexOf(bleUnlockDoor) > -1)) {
      msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailGyroUnlockDoorTaskStarted'
    } else if (task.indexOf(RaixerConfig.ble.manageLedValue) > -1) {
      msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailGyroManageLed'
    } else if (task.indexOf(confGyro)) {
      msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailProConfGyro'
    } else if (task.indexOf(nukiOpenDoor) > -1) {
      if (nukiSecondStep) { msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailNukiOpenDoorTaskFinished' }
      else { msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailNukiOpenDoorTaskStarted' }
    } else if (task.indexOf(nukiActivatePiston) > -1) {
      if (nukiSecondStep) { msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailNukiActivatePistonTaskFinished' }
      else { msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailNukiActivatePistonTaskStarted' }
    } else if (task.indexOf(nukiDeactivatePiston) > -1) {
      if (nukiSecondStep) { msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailNukiDeactivatePistonTaskFinished' }
      else { msgLabel = 'snacks.' + (!error ? 'success' : 'errors') + '.connectionsDetailNukiDeactivatePistonTaskStarted' }
    }
  }
  return I18n.t(msgLabel)
}
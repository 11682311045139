import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import stylesButton from '../../Styles/UI/Button'
import I18n from '../../../i18n/i18n'
import { Colors, Fonts } from '../../../Themes'
export default class ConnectionHeaderAccess extends Component {
  
  static propTypes = {
    onPress: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    inCheckin: PropTypes.bool.isRequired,
    customStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  }

  static defaultProps = {
    onPress: null,
    disabled: null,
    inCheckin: null,
    customStyle: {}
  }

  render = () => {
    const customStyle = {
      border: 0,
      borderRadius: 0,
      margin: 0,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: Fonts.weight.regular
    }
    let style = Object.assign({}, stylesButton.buttonContainer, customStyle)
    if (this.props.disabled) {
      style.background = Colors.theme1 + '99' 
      style.color = Colors.white + '99'
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid rgba(229, 19, 70, 0.5)', background: Colors.white }}>
        <div style={{ maxWidth: '550px', flex: 1 }}>
          <Button 
            variant={'outlined'}
            color={'primary'}
            disabled={this.props.disabled}
            startIcon={null}
            endIcon={this.props.inCheckin ? <ArrowBackIos /> : <ArrowForwardIosIcon />}
            style={style}
            fullWidth={true}
            onClick={this.props.onPress}
          >
            { this.props.inCheckin ? I18n.t('shared.back') : I18n.t('shared.checkin') }
          </Button>
        </div>
      </div>
    )
  }
}


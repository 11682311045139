import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SvgIcon } from '@material-ui/core' 
import LockOpenPath from '../../../Images/Icons/lock-open'
import LockPath from '../../../Images/Icons/lock'
import AppTitle from '../Texts/AppTitle'
import SimpleIconButton from '../Buttons/SimpleIconButton'
import styles from '../../Styles/UI/Connections/Card'
import { FormModalService } from '../../../Services/FormModal'
import { Colors, Images, Metrics } from '../../../Themes'

export default class ConnectionTabTitle extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    integrationImage: PropTypes.string,
    onboardingImage: PropTypes.string,
    icon: PropTypes.oneOf(['lock-open', 'lock']),
    onErrorIconPress: PropTypes.func
  }

  static defaultProps = {
    title: null,
    integrationImage: null,
    onboardingImage: null,
    icon: null,
    onErrorIconPress: null
  }

  goToOnboardingImage = () => {
    FormModalService.open('onboarding-image', { selectedImage: this.props.onboardingImage })
  }

  getIcon = () => {
    const svgIconStyle = { 
      fontSize: Metrics.icons.big,
      color: Colors.mediumGray,
      position: 'relative',
      top: Metrics.svgIconPositionTop,
      left: Metrics.svgIconPositionLeft
    }
    return (
      <>
        { this.props.icon === 'lock-open' && <SvgIcon style={svgIconStyle}>{LockOpenPath}</SvgIcon> }
        { this.props.icon === 'lock' && <SvgIcon style={svgIconStyle}>{LockPath}</SvgIcon> }
      </>
    )
  }
  
  render = () => {
    return (
      <div style={styles.titleContainer}>
        <AppTitle text={this.props.title}/>
        {
          this.props.onboardingImage &&
          <SimpleIconButton
            icon={'info'}
            onPress={this.goToOnboardingImage}
            disabled={false}
            customIconStyle={{ color: Colors.facebook, fontSize: Metrics.icons.big }}
          />
        }
        {
          this.props.onErrorIconPress &&
          <SimpleIconButton
            buttonText={''}
            icon={'info'}
            onPress={this.props.onErrorIconPress}
            disabled={false}
            customIconStyle={{ color: Colors.error, fontSize: Metrics.icons.big }}
          />
        }
        {
          this.props.integrationImage && !this.props.icon &&
          <img
            src={Images[this.props.integrationImage]}
            alt={''}
            style={Object.assign({}, styles.integrationLogo, { marginRight: 0 })}
            resizeMode={'contain'}
          />
        }
        {
          !this.props.integrationImage && this.props.icon &&
          this.getIcon()
        }
      </div>
    )
  }

}
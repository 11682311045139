import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form'
import { SvgIcon } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import SearchIcon from '@material-ui/icons/Search'
import VCardPath from '../../../Images/Icons/v-card'
import I18n from '../../../i18n/i18n'
import ContentCard from '../../UI/ContentCards/ContentCard'
import ConnectionCard from '../../UI/Connections/ConnectionCard'
import AppTitle from '../../UI/Texts/AppTitle'
import AppText from '../../UI/Texts/AppText'
import AnimatedComponent from '../../UI/Animated/AnimatedComponent'
import BaseForm from '../../UI/Forms/BaseForm'
import SimpleIconButton from '../../UI/Buttons/SimpleIconButton'
import MainButton from '../../UI/Buttons/MainButton'
import { objectDeepCompare, isArrayValid } from '../../../Lib/ObjectsUtils'
import { 
  connectionHasAutoAccessDoor, 
  getConnectionStatus, 
  // getConnectionSwitchValueForList, 
  connectionIsFreeVersionDevice,
  connectionIsGyroDevice
} from '../../../Lib/ConnectionsUtils'
import { Colors, Images, Metrics/* , Shadows */ } from '../../../Themes'
import styles from '../../Styles/Screens/Connections'

export default class Connections extends Component {

  static propTypes = {
    showFreeProfile: PropTypes.bool.isRequired,
    showCheckin: PropTypes.bool.isRequired,
    activeConnection: PropTypes.string,
    connections: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    fetchingTask: PropTypes.bool.isRequired,
    getConnections: PropTypes.func.isRequired,
    postDeviceTask: PropTypes.func.isRequired,
    goToConnection: PropTypes.func.isRequired,
    error: PropTypes.bool,
    platformError: PropTypes.string,
    activeConnectionTask: PropTypes.string,
    // goToCheckinUpgrade: PropTypes.func.isRequired,
    goToAddDevice: PropTypes.func.isRequired
  }

  static defaultProps = {
    showFreeProfile: null,
    showCheckin: null,
    activeConnection: null,
    connections: [],
    fetching: true,
    fetchingTask: false,
    getConnections: PropTypes.func.isRequired,
    postDeviceTask: null,
    goToConnection: null,
    error: null,
    platformError: null,
    activeConnectionTask: null,
    // goToCheckinUpgrade: null,
    goToAddDevice: null
  }

  constructor(props) {
    super(props)
    this.state = {
      connections: [],
      value: {
        name: ''
      }
    }
  }

  componentDidMount = () => {
    const { connections = [] } = this.props
    if (connections.length > 0) { this.setState({ connections }) }
  }

  componentDidUpdate = (prevProps) => {
    const { connections: prevConnections } = prevProps
    const { connections } = this.props
    if (!objectDeepCompare(prevConnections, connections) && isArrayValid(connections)) {
      const { value = {} } = this.state || {}
      const { name = '' } = value
      this.setState({ connections: this.getFilteredConnectionsByName(connections, name) })
    }
  }

  renderEmptyCard = (title, text, buttonText, checkinAction, icon, iconSize) => {
    const iconStyle = { fontSize: iconSize, color: Colors.mediumGray, marginTop: Metrics.doubleBaseMargin }
    const svgIconStyle = Object.assign(
      {}, 
      iconStyle, 
      { position: 'relative', top: Metrics.svgIconPositionTop, left: Metrics.svgIconPositionLeft }
    )
    return (
      <>
        <AppTitle text={title} customStyle={styles.connectionsTitle} />
        <ContentCard
          title={null}
          renderContent={() => (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              { icon === 'home' && <HomeIcon style={iconStyle} /> }
              { icon === 'v-card' && <SvgIcon style={svgIconStyle}>{VCardPath}</SvgIcon> }
              <AppText text={text} customStyle={{ textAlign: 'center', marginTop: Metrics.baseMargin }} />
            </div>
          )}
          renderButtons={() => (
            <MainButton
              buttonText={buttonText}
              // onPress={checkinAction ? this.props.goToCheckinUpgrade : this.props.goToAddDevice}
              onPress={this.props.goToAddDevice}
              disabled={false}
              customButtonStyle={{ margin: 0, marginTop: Metrics.baseMargin }}
            />
          )}
        />
      </>
    )
  }

  renderConnectionsEmpty = () => {
    return (
      <div style={{ padding: Metrics.doubleBaseMargin, marginTop: Metrics.doubleBaseMargin }}>
        { this.renderEmptyCard(I18n.t('shared.devices'), I18n.t('screens.connections.empty'), I18n.t('screens.connections.emptyButton'), false, 'home', Metrics.icons.large) }
        {
          /*
          this.props.showCheckin && 
          <div style={{ marginTop: Metrics.tripleBaseMargin }}>
            { this.renderEmptyCard(I18n.t('shared.checkin'), I18n.t('screens.connections.emptyCheckin'), I18n.t('screens.connections.emptyCheckinButton'), true, 'v-card', Metrics.icons.large) }
          </div>
          */
        }
      </div>
    )
  }

  onChangeSearchForm = (value) => {
    const { name = '' } = value || {}
    const { connections = [] } = this.props || {}
    this.setState({ connections: this.getFilteredConnectionsByName(connections, name), value }) 
  }

  getFilteredConnectionsByName = (connections, name) => {
    try {
      if (name !== '') {
        return connections.filter((c) => {
          const { device = {}, name: connectionName = '' } = c
          const { campaign = {} } = device
          const { name: campaignName = '' } = campaign
          const searchName = connectionName ? connectionName : campaignName
          return searchName.toLowerCase().indexOf(name.toLowerCase()) > -1
        })
      } else { 
        return connections
      }
    } catch {
      return connections
    }
  }

  renderSearchForm = () => {
    const { value = {} } = this.state || {}
    const { name: filter = '' } = value
    const rightAccessory = filter !== '' ? (
        <SimpleIconButton
          onPress={() => { this.setState({ value: { name: '' }, connections: this.props.connections }) }}
          icon={'clear'}
          customButtonStyle={{ padding: 0 }}
          customIconStyle={{ color: Colors.mediumGray }}
        />
      ) : (
        <SearchIcon style={styles.searchFormIcon} />
      )
    return (
      <div style={styles.searchFormContainer}>
        <BaseForm
          name={'connectionsSearchForm'}
          onSubmit={() => {}}
          fields={{ name: t.String }}
          fieldsOptions={{
            name: {
              label: I18n.t('screens.connections.search'),
              autoCapitalize: 'none',
              customTemplate: true,
              customTemplateRenderRightAccessory: () => (
                <div style={styles.formRightAccessory}>
                  { rightAccessory }
                </div>
              ),
              written: filter !== '' // Hack for forcing the update when changing the right accesory
            }
          }}
          onChange={this.onChangeSearchForm}
          value={this.state.value}
          withoutSubmit={true}
          debounced={true}
        />
      </div>
    )
  }

  renderItem = (c, i, length) => {
    const { _id, device = {}, deviceId = null, disabled, name, profile } = c
    const { campaign = {}, online = false, type, guestyListingId, trialEnded = false, trialEndedAccount = false, offlineGyro, version, doors = [] } = device
    const { name: campaignName = '' } = campaign
    const isFreeVersionDevice = connectionIsFreeVersionDevice(c)
    const isGyroDevice = connectionIsGyroDevice(c)
    const hasAutoAccessDoor = connectionHasAutoAccessDoor(c, true)
    const finalTrialEnded = this.props.showFreeProfile && isFreeVersionDevice ? trialEndedAccount : trialEnded
    const hasGyroDoor = doors.filter(d => d.sensorMode === 'gyro').length > 0
    
    let icon = 'identification'
    let switchValue
    if (type === 'power') { icon = 'power-plug' }
    if (type === 'intercom') {
      icon = 'mini'
      if (version === 'v1' || version === 'v2') { icon = 'miniV1V2' }
      else if (version === 'v3') { icon = 'twelveV' }
      else if (version === 'v4' || version === 'v5') { icon = 'mini' }
      else if ((version === 'v6' || version === 'v7') && hasGyroDoor) { icon = 'proWithGyro' }
      else if (version === 'v6' || version === 'v7') { icon = 'pro' }
      else if (version === 'v8' && hasGyroDoor) { icon = 'miniV5Gyro' }
      else if (version === 'gyro') { icon = 'gyro' }
    }
    console.log({
      version, icon,hasGyroDoor
    })
    if (type === 'intercom' && hasAutoAccessDoor) {
      // switchValue = getConnectionSwitchValueForList(_id, getConnectionStatus(c), this.props.activeConnectionTask, this.props.fetchingTask)
      switchValue = getConnectionStatus(c)
    }
    return (
      <ConnectionCard
        key={'connection-' + i}
        icon={icon}
        active={this.props.activeConnection === _id}
        title={name || campaignName}
        deviceOnline={ isGyroDevice
          ? (offlineGyro ? true : online )
          : online || !deviceId}
        updating={this.props.fetching}
        disabled={type !== 'intercom' && deviceId !== null}
        showSwitch={type === 'intercom' && hasAutoAccessDoor && (profile === 'admin' || profile === 'installer')}
        switchValue={switchValue}
        switchDisabled={this.props.fetchingTask || disabled || finalTrialEnded}
        showIntegrations={guestyListingId ? true : false}
        onSwitchChange={(value) => { this.props.postDeviceTask(c, value) }}
        onPress={() => { this.props.goToConnection(c._id) }}
        iconSmallerSize={!deviceId ? true : false}
        containerCustomStyle={i === length - 1 ? { marginBottom: 0 } : {}}
      />
    )
  }

  renderContent = () => {
    const { connections, fetching } = this.props
    const { connections: filteredConnections = [], value = {} } = this.state || {}
    const { name = '' } = value
    if (fetching && connections.length === 0) {
      return (
        <div style={styles.emptyConnectionList}>
          <AnimatedComponent source={'loaderGears'} width={125} height={125} />
          <AppText text={I18n.t('screens.connections.loading')} customStyle={styles.emptyText} />
        </div>
      )
    }
    return (
      <>
        { connections.length === 0 && this.renderConnectionsEmpty() }
        { connections.length > 5 && this.renderSearchForm() }
        { 
          filteredConnections.length === 0 && name !== '' && 
          <div style={styles.emptyConnectionList}>
            <AnimatedComponent source={'searchEmpty'} width={150} height={150} autoPlay={false} loop={false} progress={0.2} />
            <AppText text={I18n.t('screens.connections.emptySearch')} customStyle={styles.emptySearchText} />
          </div>
        }
        <div style={styles.connectionListWrapper}>
          <div style={styles.connectionList}>
            { filteredConnections.map((c, i) => this.renderItem(c, i, filteredConnections.length)) }
            {
              !name &&
              <>
                <MainButton
                  buttonText={I18n.t('screens.connections.emptyButtonSecondary')}
                  // icon={'circle-with-plus'}
                  icon={null}
                  onPress={this.props.goToAddDevice}
                  disabled={false}
                  customButtonStyle={{ margin: 0, marginTop: Metrics.doubleBaseMargin }}
                />
                { /*
                <MainButton
                  buttonText={I18n.t('screens.connections.emptyCheckinButton')}
                  // icon={'v-card'}
                  icon={null}
                  onPress={this.props.goToCheckinUpgrade}
                  disabled={false}
                  customButtonStyle={{ margin: 0, marginTop: Metrics.baseMargin }}
                />
                */ }
              </>
            }
          </div>
        </div>
      </>
    )
  }

  render = () => {
    const { connections, fetching } = this.props
    return (
      <div style={styles.container}>
        <ContentCard
          title={!fetching && connections.length === 0 ? null : I18n.t('screens.connections.title')}
          titleAction={connections.length > 0 ? this.props.getConnections : null}
          renderContentBeforeTitle={() => (
            <a href={'https://raixer.com/'} target={'_blank'} rel={'noopener noreferrer'}>
              <img src={Images.logo} alt={'Raixer'} style={styles.logo} />
            </a>
          )}
          renderContent={this.renderContent}
          // customStyle={{ overflow: 'hidden', margin: 0, height: '100%', ...Shadows.card }}
          customStyle={{ margin: 0, background: 'none', borderWidth: 0, overflow: 'auto' }}
          customTitleAndEditContainerStyle={styles.titleAndEditContainer}
          customContentStyle={{ padding: 0 }}
        />
      </div>
    )
  }

}
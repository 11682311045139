import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getActualConnection, getConnectionDoorById } from '../../../Lib/ConnectionsUtils'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import Information from '../../../Components/Screens/Connection/Phones/Information'

class InformationScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null,
      doorId: null,
      phoneId: null
    }
  }

  componentDidMount = () => {
    const { connectionId, doorId, phoneId } = this.props || {}
    this.setState({ connectionId, doorId, phoneId })
    this.props.getDoorPhoneInformation(connectionId, doorId, phoneId)
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    const door = getConnectionDoorById(connection, this.state.doorId)
    const { phone } = door || {}
    const { information = [] } = phone || {}
    return (
      <Information
        activeLang={this.props.activeLang}
        information={information}
        fetching={this.props.fetching || this.props.fetchingTab || this.props.fetchingOther || this.props.fetchingForm}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    fetching: state.connections.fetchingConnection,
    fetchingTab: state.connections.fetchingConnectionTab,
    fetchingOther: state.connections.fetchingConnectionOther,
    fetchingForm: state.connections.fetchingConnectionForm,
    activeLang: state.i18n.activeLang
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDoorPhoneInformation: (connectionId, doorId, phoneId) => 
      dispatch(ConnectionsActions.connectionDoorPhoneInformationRequest(connectionId, doorId, phoneId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InformationScreen)
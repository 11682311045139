import React, { Component } from 'react'
import { Icon } from '@material-ui/core'
import { motion } from 'framer-motion'
import RaixerConfig from '../../../Config/RaixerConfig'
import I18n from '../../../i18n/i18n'
import SimpleTextButton from '../../UI/Buttons/SimpleTextButton'
import AppText from '../Texts/AppText'
import { Colors, Metrics } from '../../../Themes'
import styles from '../../Styles/UI/Snackbar'

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
}

export default class Snackbar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      show: false,
      timer: null,
      success: true,
      text: '',
      buttonText: I18n.t('shared.retry'),
      action: null
    }
  }
  
  show = (text, success, action) => {
    if (!this.state.show) {
      this.setState({ show: true, success, text, action })
      this.hide()
    } else {
      this.hide(true)
      this.setState({ show: true, success, text, action })
      this.hide()
    }
  }
  
  hide = (now = false) => {
    if (now) {
      this.clear()
    } else {
      const showTime = this.state.action ?
        RaixerConfig.animations.snackbar.showTimeWithAction : RaixerConfig.animations.snackbar.showTimeWithoutAction
      const timer = setTimeout(() => {
        if (this.state.show) { this.clear() }
      }, showTime)
      this.setState({ timer })
    }
  }

  close = () => {
    if (this.state.show) { this.setState({ show: false }) }
  }

  clear = () => {
    if (this.state.timer) { clearTimeout(this.state.timer) }
    this.setState({ show: false, timer: null })
  }

  onActionPress = () => {
    this.state.action()
    this.close()
  }
  
  render = () => {
    const backgroundColor = this.state.success ? Colors.success : Colors.error
    const color = Colors.white
    const leftWidth = this.state.action ? { maxWidth: '85%' } : { marginRight: (Metrics.baseMeasure * 7) + 'px' }
    return (
      <motion.div initial={false} animate={this.state.show ? 'visible' : 'hidden'} variants={variants} style={styles.snackWrapper}>
        <div 
          style={
            Object.assign({}, styles.snackContainter, { backgroundColor, display: this.state.show ? 'flex' : 'none' })
          }
        >
          <div style={Object.assign({}, styles.snackLeft, leftWidth)}>
            <Icon style={Object.assign({}, styles.snackIcon, { color })}>
              { this.state.success ? 'check-outline' : 'error' }
            </Icon>
            <AppText 
              text={this.state.text} 
              customStyle={Object.assign(styles.snackText, { color })} 
              numberOfLines={3} 
            />
          </div>
          {
            this.state.action &&
            <SimpleTextButton
              buttonText={this.state.buttonText}
              onPress={this.onActionPress}
              disabled={false}
              customButtonStyle={Object.assign(styles.snackAction, { color })}
              customTextStyle={styles.snackActionText}
            />
          }
          <SimpleTextButton
            buttonText={''}
            icon={'close'}
            onPress={() => { this.hide(true) }}
            disabled={false}
            customButtonStyle={styles.snackActionClose}
            customTextStyle={styles.snackActionText}
            customIconStyle={{ color, fontSize: (Metrics.baseMeasure * 3) + 'px' }}
          />
        </div>
      </motion.div>
    )
  }

}
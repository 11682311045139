import { Colors, Fonts, Metrics } from '../../../Themes/'

export default {
  weekdaySelectorContainer: {
    display: 'flex',
    flexDirection: 'row', 
    justifyContent: 'center', 
    marginTop: Metrics.doubleBaseMargin
  },
  weekdayButton: {
    // TODO: size of the button in normal size
    width: (Metrics.baseMeasure * 5) + 'px',
    minWidth: (Metrics.baseMeasure * 5) + 'px', 
    height: (Metrics.baseMeasure * 5) + 'px',
    borderRadius: (Metrics.baseMeasure * 5) + 'px',
    shadowColor: Colors.theme1, 
    borderColor: Colors.theme1,
    borderWidth: Metrics.buttonBorderWidth,
    borderStyle: 'solid',
    marginBottom: 0,
    backgroundColor: Colors.cardBackground,
    opacity: 1,
    marginRight: Metrics.doubleBaseMargin
  },
  weekdayButtonSmall: {
    width: (Metrics.baseMeasure * 5) + 'px', 
    minWidth: (Metrics.baseMeasure * 5) + 'px', 
    height: (Metrics.baseMeasure * 5) + 'px', 
    borderRadius: (Metrics.baseMeasure * 5) + 'px',
    shadowColor: Colors.transparent, 
    borderColor: Colors.lighterGray,
    padding: Metrics.smallMargin,
    backgroundColor: Colors.cardBackground
  },
  weekdayButtonSelected: {
    backgroundColor: Colors.theme1
  },
  weekdayButtonSmallSelected: {
    backgroundColor: Colors.lighterGray,
  },
  weekdayButtonText: {
    color: Colors.theme1
  },
  weekdayButtonTextSmall: {
    marginTop: 1,
    fontSize: Fonts.size.small,
    color: Colors.lighterGray
  },
  weekdayButtonTextSelected: { 
    color: Colors.white 
  },
  weekdayButtonTextSmallSelected: { 
    color: Colors.mediumGray 
  }
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getActualConnection } from '../../../Lib/ConnectionsUtils'
import ConnectionsActions from '../../../Redux/ConnectionsRedux'
import GuestBook from '../../../Components/Screens/CheckIn/Forms/GuestBook'

class GuestBookScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connectionId: null
    }
  }

  componentDidMount = () => {
    const { connectionId } = this.props
    this.props.getGuestBook(connectionId)
    this.setState({ connectionId })
  }

  sendGuestBook = () => {
    this.props.sendGuestBook(this.state.connectionId)
  }

  render = () => {
    const connection = getActualConnection(this.props.connections, this.state.connectionId)
    return (
      <GuestBook
        fetching={this.props.fetching}
        connection={connection}
        sendGuestBook={this.sendGuestBook}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    connections: state.connections.connections,
    fetching: state.connections.fetchingConnectionForm
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGuestBook: (connectionId) => dispatch(ConnectionsActions.connectionGetGuestBook(connectionId)),
    sendGuestBook: (connectionId) => dispatch(ConnectionsActions.connectionSendGuestBook(connectionId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestBookScreen)
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import I18n from '../../../../i18n/i18n'
import ListCard from '../../../UI/ContentCards/ListCard'
import AppText from '../../../UI/Texts/AppText'
import SmallLoader from '../../../UI/Loaders/SmallLoader'

export default class BookingsHistory extends Component {

  static propTypes = {
    bookingsHistory: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    goToGuestForm: PropTypes.func.isRequired
  }

  static defaultProps = {
    bookingsHistory: null,
    fetching: null,
    goToGuestForm: null
  }

  getBookingList = (bookings) => {
    return bookings.map((b) => {
      const { _id, checkedIn, locationName, locationProvince, startDate, endDate, residencyDays, guests } = b || {}
      return { 
        _id, checkedIn, locationName, locationProvince, startDate, endDate: moment(endDate).toISOString(), residencyDays, guests
      }
    })
  }

  render = () => {
    const bookings = this.props.bookingsHistory || []
    return ( 
      <ListCard
        title={I18n.t('screens.bookingHistory.title')}
        list={this.getBookingList(bookings)}
        listItemType={'booking'}
        withEditButton={false}
        addButton={false}
        onListItemSecondary={(bookingId) => { this.props.goToGuestForm(bookingId) }}
        renderExtraContentBeforeButtons={() => {
          if (!this.props.fetching && bookings.length === 0) { return <AppText text={I18n.t('screens.bookingHistory.textEmpty')} /> }
          else if (!this.props.fetching) { return null }
          return <SmallLoader visible={true} />
        }}
      />
    )
  }

}
import { Colors, Fonts, Metrics } from '../../../Themes/'

export default {
  tabsContainer: {
    marginVertical: Metrics.doubleBaseMarginPlus
  },
  tabsDescription: {
    color: Colors.theme1,
    fontSize: Fonts.size.regular,
    fontWeight: Fonts.weight.regular,
    textAlign: 'center',
    marginBottom: Metrics.baseMargin
  },
  tab: {
    backgroundColor: Colors.white + ' !important',
    borderColor: Colors.theme1 + ' !important',
    padding: Metrics.smallMargin,
    height: 'auto'
  },
  tabActive: {
    backgroundColor: Colors.theme1 + ' !important'
  },
  tabText: {
    fontWeight: Fonts.weight.regular, 
    fontSize: Fonts.size.regular,
    fontFamily: Fonts.type.regular,
    color: Colors.theme1
  },
  tabTextActive: {
    fontWeight: Fonts.weight.regular, 
    color: Colors.white
  }
}
import Colors from './Colors'

const shadows = {
  leftMenu: {
    boxShadow: Colors.themeShadow1 + ' 0px 2px 6px 0px, ' + Colors.themeShadow2 + ' 3px 0px 5px 0px'
  },
  header: {
    boxShadow: Colors.themeShadow1 + ' 0px 2px 6px 0px, ' + Colors.themeShadow2 + ' 0px 1px 4px 0px'
  },
  tabbar: {
    boxShadow: Colors.shadowFirst + ' 0px 2px 6px 0px, ' + Colors.shadowFirst + ' 0px 1px 4px 0px'
  },
  menu: {
    boxShadow: Colors.shadowFirst + ' 0px 4px 8px 0px, ' + Colors.shadowSecond + ' 0px 3px 6px 0px'
  },
  card: {
    boxShadow: Colors.shadowFirst + ' 0px 7px 14px 0px, ' + Colors.shadowSecond + ' 0px 3px 6px 0px'
  },
  cardSecond: {
    boxShadow: Colors.shadowFirst + ' 14px 7px 14px 0px, ' + Colors.shadowSecond + ' 6px 3px 6px 0px'
  },
  button: {
    boxShadow: Colors.shadowFirst + ' 0px 7px 14px 0px, ' + Colors.shadowSecond + ' 0px 3px 6px 0px'
  },
  appleButton: {
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
  },
  none: {
    boxShadow: 'none'
  }
}

export default shadows

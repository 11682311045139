import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'tcomb-form'
import { Button } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'

import { capitalizeFirstLetter } from '../../../Lib/StringsUtils'
import RaixerConfig from '../../../Config/RaixerConfig'
import I18n from '../../../i18n/i18n'
import AppText from '../Texts/AppText'
import ListCard from '../ContentCards/ListCard'
import GhostButton from '../Buttons/GhostButton'
import SimpleTextButton from '../Buttons/SimpleTextButton'
import BaseSwitch from '../Switchs/BaseSwitch'
import styles from '../../Styles/UI/Connections/Phones'
import stylesButton from '../../Styles/UI/Button'
import stylesConnections from '../../Styles/Screens/Connections'
import listStyles from '../../Styles/UI/ListItem'
import { Colors, Fonts, Metrics } from '../../../Themes'
import SimpleIconButton from '../Buttons/SimpleIconButton'
import BaseForm from '../Forms/BaseForm'
import AnimatedComponent from '../Animated/AnimatedComponent'

export default class ConnectionDoorCodeCard extends Component {

  static propTypes = {
    fetching: PropTypes.bool.isRequired,
    door: PropTypes.object.isRequired,
    doorName: PropTypes.string.isRequired,
    isUserAdmin: PropTypes.bool.isRequired,
    goToDoorCode: PropTypes.func.isRequired,
    goToDoorAuthorizedCode: PropTypes.func.isRequired,
    onPressShareCode: PropTypes.func.isRequired,
    onPressOpenBrowser: PropTypes.func.isRequired,
    onPressCopy: PropTypes.func.isRequired,
    onCodeEnabledChange: PropTypes.func.isRequired,
    onAnyCallerEnabledChangeCode: PropTypes.func.isRequired,
    onPressGetQr: PropTypes.func.isRequired
  }

  static defaultProps = {
    fetching: null,
    door: null,
    doorName: null,
    isUserAdmin: null,
    goToDoorCode: null,
    goToDoorAuthorizedCode: null,
    onPressShareCode: null,
    onPressCopy: null,
    onPressOpenBrowser: null,
    onCodeEnabledChange: null,
    onAnyCallerEnabledChangeCode: null,
    onPressGetQr: null
  }

  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      currentPage: 1,
      pageSize: 20,
      totalPages: 0,
      codes: [],
      filteredCodes: [],
      withEditButton: false,
      searchFormFocused: false,
      value: {
        search: ''
      }
    }
  }

  componentDidMount = () => {
    const { accessAnyoneByURL, authorizedCodes, accessByUrlEnabled } = this.props.door
    const { codes } = this.getRequiredFields(accessByUrlEnabled, authorizedCodes, accessAnyoneByURL)

    let newState = { }
    if (codes.length > 0) {
      newState = {
        ...newState, codes, filteredCodes: codes, totalPages: Math.ceil(codes.length / this.state.pageSize)
      }
    }
    this.setState(newState)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.door !== this.props.door) {
      const { accessAnyoneByURL, authorizedCodes, accessByUrlEnabled } = this.props.door
      const { codes } = this.getRequiredFields(accessByUrlEnabled, authorizedCodes, accessAnyoneByURL)
      let newState = { }
      if (codes.length > 0) {
        newState = {
          ...newState, codes, filteredCodes: codes, totalPages: Math.ceil(codes.length / this.state.pageSize)
        }
      }
      this.setState(newState)
    }
  }
  
  appendDoorInfo(url) {
    return `${url}?doorId=${this.props.door._id}&doorName=${this.props.doorName}`
  }

  _getAuthorizedCode = (code) => {
    const {
      _id, authorized, enabledForUse, deviceId, doorId, code: codeNumber,
      deleteOnEnd, alias, createdBy, authorizedFrom, authorizedTo, 
      repeatDaysOfWeek, repeatStartHour, repeatEndHour, repeatTimezone
    } = code
    let type = 'simple'
    if (authorizedFrom && authorizedTo) { type = 'dates' }
    else if (repeatDaysOfWeek !== undefined && repeatDaysOfWeek !== null && repeatEndHour && repeatStartHour && repeatTimezone) { type = 'repeat' }
    return {
      _id,
      deviceId,
      doorId,
      authorized,
      enabledForUse,
      codeNumber,
      authorizedFrom,
      authorizedTo,
      deleteOnEnd,
      alias,
      createdBy: createdBy ? capitalizeFirstLetter(createdBy) : null,
      left: alias !== '' ? `${codeNumber} - ${alias}` : `${codeNumber}`,
      right: authorized ? I18n.t('shared.active') : I18n.t('shared.inactive'),
      dateFrom: authorizedFrom,
      dateTo: authorizedTo,
      type,
      repeatDaysOfWeek,
      repeatEndHour,
      repeatStartHour,
      repeatTimezone
    }
  }

  getRequiredFields = (accessByUrlEnabled, authorizedCodes, accessAnyoneByURL) => {
    let codes = []
    try {
      if (accessByUrlEnabled && !accessAnyoneByURL) {
        codes = authorizedCodes.map(a => this._getAuthorizedCode(a))
      }
    } catch {
      codes = []
    }
    return { codes }
  }

  toggleEditing = (value) => {
    if (value > 0) { this.setState({ editing: true }) }
    else { this.setState({ editing: false }) }
  }

  changePage = (increment) => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage + increment,
    }))
  }

  onChangeSearchForm = (value) => {
    const { name = '' } = value || {}
    const { codes = [] } = this.state || {}
    const filteredCodes = this.getFilteredCodesByLeft(codes, name)

    this.setState({ filteredCodes, value, currentPage: 1, totalPages: Math.ceil(filteredCodes.length / this.state.pageSize) })
  }

  getFilteredCodesByLeft = (codes, search) => {
    try {
      if (search !== '') {
        let filteredCodes = []
        for (let i = 0; i < codes.length; i++) {
          const code = codes[i]
          const { left } = code
          if (left.toLowerCase().indexOf(search.toLowerCase()) > -1) {
            filteredCodes.push(code)
          }
        }
        return filteredCodes
      } else {
        return codes
      }
    } catch {
      return codes
    }
  }
  
  renderSearchForm = () => {
    const { value = {}, filteredCodes } = this.state || {}
    const { name: filter = '' } = value
    const rightAccessory = filter !== '' ? (
      <SimpleIconButton
        onPress={() => { 
          this.setState({ value: { name: '' }, filteredCodes: this.state.codes, currentPage: 1, totalPages: Math.ceil(this.state.codes.length / this.state.pageSize) }) 
        }}
        icon={'clear'}
        customButtonStyle={{ padding: 0 }}
        customIconStyle={{ color: Colors.mediumGray }}
      />
    ) : (
      <SearchIcon style={stylesConnections.searchFormIcon} />
    )
    return (
      <div>
        <BaseForm
          name={'codesSearchForm'}
          onSubmit={() => {}}
          fields={{ name: t.String }}
          fieldsOptions={{
            name: {
              label: I18n.t('screens.connection.codes.search'),
              autoCapitalize: 'none',
              customTemplate: true,
              customTemplateRenderRightAccessory: () => (
                <div style={stylesConnections.formRightAccessory}>
                  { rightAccessory }
                </div>
              ),
              written: filter !== '' // Hack for forcing the update when changing the right accesory
            }
          }}
          onChange={this.onChangeSearchForm}
          value={this.state.value}
          withoutSubmit={true}
          debounced={true}
        />
        
        {
          filteredCodes.length === 0 && filter !== '' &&
          <div>
            <AnimatedComponent source={'searchEmpty'} width={150} height={150} autoPlay={false} loop={false} progress={0.2} />
            <AppText text={I18n.t('screens.connection.codes.emptySearch')} customStyle={stylesConnections.emptySearchText} />
          </div>
        }
      </div>
    )
  }
  
  render = () => {
    const { _id, accessAnyoneByURL, authorizedCodes, accessByUrlEnabled, accessURL } = this.props.door
    const { key: accessURLKey, alias: accessURLAlias } = accessURL || {}
    const accessURLWithEnv = RaixerConfig.api.baseUrl.concat(accessURLKey)
    const accessWithoutHttps = accessURLWithEnv.replace('https://', '')
    const { codes } = this.getRequiredFields(accessByUrlEnabled, authorizedCodes, accessAnyoneByURL)
    // const accessByUrlEnabledValue = getAllSwitchsValue(accessByUrlEnabled, this.props.fetching)
    // const accessAnyoneByURLValue = getAllSwitchsValue(accessAnyoneByURL, this.props.fetching)
    let firstItemStyles = [
      listStyles.listItem,
      {
        paddingTop: Metrics.doubleBaseMargin,
        marginTop: Metrics.baseMargin,
        borderTopWidth: Metrics.listItemBorderWidth
      }
    ]
    if (!accessByUrlEnabled) {
      firstItemStyles.push({
        marginBottom: 0,
        paddingBottom: Metrics.baseMargin,
        borderBottomColor: Colors.transparent
      })
    }
    const { filteredCodes, value } = this.state
    const { name = '' } = value || {}
    const startIndex = (this.state.currentPage - 1) * this.state.pageSize
    const paginatedCodes = filteredCodes.slice(startIndex, startIndex + this.state.pageSize)
    const showPagination = filteredCodes.length > this.state.pageSize

    const customStyle = {
      border: 0,
      borderRadius: 0,
      margin: 0,
      marginBottom: 0,
      fontWeight: Fonts.weight.regular,
      padding: '8px',
      height: '30px',
      width: '30px',
      minHeight: '30px',
      minWidth: '30px',
    }
    let style = Object.assign({}, stylesButton.buttonContainer, customStyle)
    
    return (
      <ListCard
        title={this.props.doorName}
        list={paginatedCodes}
        listItemType={'authorized-codes'}
        withEditButton={this.props.isUserAdmin ? true : false}
        onEditItem={(code) => { this.props.goToDoorAuthorizedCode(_id, code) }}
        addButton={accessByUrlEnabled && !accessAnyoneByURL && this.props.isUserAdmin && name === '' ? true : false}
        addButtonText={I18n.t('screens.connection.codes.addAuthorizedCode')}
        onAdd={() => { this.props.goToDoorAuthorizedCode(_id) }}
        onListItemIcon={(item) => { this.props.onPressShareCode(accessURLWithEnv, item.codeNumber) }}
        renderExtraContent={
          () => {
            return (
              <>
                <div style={Object.assign({}, styles.topTextContainer, { marginBottom: ((Metrics.baseMeasure * 2)) + 'px' })}>
                  {
                    <>
                      <AppText
                        text={I18n.t('shared.codeCallAction')}
                        customStyle={Object.assign({}, styles.topDescriptionText, { textAlign: 'center' })}
                      />
                      <SimpleTextButton
                        buttonText={accessWithoutHttps}
                        onPress={() => { this.props.onPressCopy(accessURLWithEnv) }}
                        disabled={false}
                        customButtonStyle={Object.assign(
                          {},
                          styles.topPhoneText,
                          { padding: 0, marginTop: 0, minHeight: Metrics.cuadrupleBaseMargin, height: Metrics.cuadrupleBaseMargin }
                        )}
                      />
                    </>
                  }
                </div>
                {
                  !this.state.editing ?
                    <div style={Object.assign({}, styles.topButtonsContainer, { justifyContent: 'center' })}>
                      <GhostButton
                        buttonText={I18n.t('shared.share')}
                        onPress={() => { this.props.onPressShareCode(accessURLWithEnv) }}
                        disabled={false}
                        icon={'share'}
                        customButtonStyle={Object.assign({}, styles.topButton)}
                      />
                      <GhostButton
                        buttonText={I18n.t('shared.visitCode')}
                        onPress={() => { this.props.onPressOpenBrowser(accessURLWithEnv, this.props.doorName, this.props.door._id) }}
                        disabled={false}
                        icon={'launch'}
                        customButtonStyle={Object.assign({}, styles.topButton)}
                      />
                      <GhostButton
                        buttonText={I18n.t('shared.getQR')}
                        onPress={() => { this.props.onPressGetQr(this.props.door.deviceId, this.props.door._id) }}
                        disabled={false}
                        icon={'download'}
                        customButtonStyle={Object.assign({}, styles.topButton, { marginRight: 0 })}
                      />
                    </div>
                    :
                    <div style={styles.topButtonsContainerOnlyMargin}>
                      <GhostButton
                        buttonText={I18n.t('shared.edit')}
                        onPress={() => {
                          if (this.state.editing) { setTimeout(() => { this.setState({ editing: false }) }, RaixerConfig.delays.toggleEdit) }
                          this.props.goToDoorCode(_id, true, this.props.doorName, accessURLAlias, accessWithoutHttps)
                        }}
                        disabled={false}
                        icon={'edit'}
                        customButtonStyle={Object.assign({}, styles.topButton, { marginRight: 0 })}
                      />
                    </div>
                }
                <div style={Object.assign({}, ...firstItemStyles)}>
                  <BaseSwitch
                    label={null}
                    help={I18n.t('screens.connection.codes.callingEnabledLabel')}
                    value={accessByUrlEnabled ? true : false}
                    disabled={this.props.fetching || !this.props.isUserAdmin}
                    onChange={(value) => { this.props.onCodeEnabledChange(_id, value) }}
                    withoutMargin={true}
                    withoutVerticalPadding={true}
                    customSwitchRowStyle={styles.switchRow}
                    customHelpStyle={styles.switchHelp}
                  />
                </div>
                {
                  accessByUrlEnabled &&
                  <>
                    <div style={accessAnyoneByURL ? Object.assign({}, listStyles.listItem, listStyles.lastListItem) : listStyles.listItem}>
                      <BaseSwitch
                        label={null}
                        help={I18n.t('screens.connection.codes.anyCallerEnabledLabel')}
                        value={accessAnyoneByURL ? true : false}
                        disabled={!this.props.isUserAdmin}
                        onChange={(value) => { this.props.onAnyCallerEnabledChangeCode(_id, value) }}
                        withoutMargin={true}
                        withoutVerticalPadding={true}
                        customSwitchRowStyle={styles.switchRow}
                        customHelpStyle={styles.switchHelp}
                      />
                    </div>
                    <>
                      {
                        !accessAnyoneByURL &&
                        <AppText
                          text={I18n.t('screens.connection.codes.authorizedCodesTitle')}
                          customStyle={Object.assign({}, styles.independantListItemTitle, { marginTop: Metrics.doubleBaseMargin })}
                        />
                      }
                      {
                        codes.length === 0 && !accessAnyoneByURL &&
                        <AppText
                          text={I18n.t('screens.connection.codes.authorizedCodesEmpty')}
                          customStyle={styles.independantListItemContent}
                        />
                      }
                    </>
                  </>
                }

                <>
                  { codes.length >= this.state.pageSize ? this.renderSearchForm() : null }

                  { showPagination &&
                    <div style={styles.paginationContainer}>
                      <Button
                        variant={'outlined'}
                        color={'primary'}
                        disabled={this.state.currentPage === 1}
                        startIcon={null}
                        endIcon={<ArrowBackIos />}
                        style={style}
                        onClick={() => this.changePage(-1)}
                      />
                      <AppText text={`${this.state.currentPage} ${I18n.t('screens.connection.users.of')} ${this.state.totalPages}`} />
                      <Button
                        variant={'outlined'}
                        color={'primary'}
                        disabled={this.state.currentPage >= this.state.totalPages}
                        startIcon={null}
                        endIcon={<ArrowForwardIos />}
                        style={style}
                        onClick={() => this.changePage(1)}
                      />
                    </div>
                  }
                </>
              </>
            )
          }
        }
        publishListItemEditFlexValue={this.toggleEditing}
        editing={this.state.editing}
      />
    )
  }

}

import { Colors, Fonts, Metrics } from '../../../Themes/'

export default {
  listItem: {
    paddingTop: Metrics.baseMargin,
    paddingBottom: Metrics.doubleBaseMargin,
    marginBottom: Metrics.baseMargin,
    borderBottomStyle: 'solid',
    borderBottomWidth: Metrics.listItemBorderWidth,
    borderColor: Colors.lighterGray,
    borderBottomColor: Colors.lighterGray
  },
  lastListItem: {
    marginBottom: 0,
    paddingBottom: Metrics.baseMargin,
    borderColor: Colors.transparent,
    borderBottomColor: Colors.transparent
  },
  listItemInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  listItemInnerColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  listItemLeft: {
    alignSelf: 'flex-start',
    flex: 1
  },
  listItemRight: {
    alignSelf: 'flex-end',
    flex: 1,
    marginRight: Metrics.smallMargin
  },
  listItemImportant: {
    flex: 2
  },
  listItemRightRow: { 
    display: 'flex',
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'flex-end' 
  },
  listItemTitle: {
    color: Colors.darkGray,
    fontSize: Fonts.size.regular,
    textAlign: 'left',
    fontFamily: Fonts.type.bold
    // fontWeight: Fonts.weight.bold
  },
  listItemContent: {
    color: Colors.mediumGray,
    fontSize: Fonts.size.regular,
    fontWeight: Fonts.weight.regular,
    textAlign: 'right'
  },
  listBookingLink: {
    color: Colors.theme1,
    textAlign: 'right'
  },
  listItemEditButtonContainer: {
    display: 'flex',
    width: 0,
    transition: 'flex 0.2s linear',
  },
  marginBottom: {
    marginBottom: Metrics.smallMargin
  }
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Colors, Fonts, Metrics } from '../../../Themes'
import BaseButton from './BaseButton'

export default class EditButton extends Component {

  static propTypes = {
    editText: PropTypes.string.isRequired,
    closeEditText: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    editing: PropTypes.bool.isRequired,
    icon: PropTypes.string,
    iconCloseEdit: PropTypes.string,
    customIcon: PropTypes.string,
    iconSize: PropTypes.string,
    buttonStyle: PropTypes.object,
    customButtonStyle: PropTypes.object,
    customText: PropTypes.string
  }

  static defaultProps = {
    editText: null,
    closeEditText: null,
    onPress: null,
    editing: null,
    icon: 'tune',
    iconCloseEdit: 'check',
    customIcon: null,
    iconSize: Metrics.icons.regular,
    buttonStyle: { 
      height: Metrics.cuadrupleBaseMargin,
      margin: Metrics.baseMargin + ' 0',
      borderColor: Colors.cardBackground,
      padding: '0 ' + Metrics.baseMargin,
      fontWeight: Fonts.weight.regular
    },
    customButtonStyle: {},
    customText: null
  }

  render = () => {
    let text = this.props.closeEditText
    let icon = this.props.iconCloseEdit
    if (!this.props.editing) {
      text = this.props.editText
      icon = this.props.icon
    }
    if (this.props.customText) { text = this.props.customText }
    if (this.props.customIcon) { icon = this.props.customIcon }
    return (
      <BaseButton
        variant={'outlined'}
        buttonText={text}
        onPress={this.props.onPress}
        disabled={false}
        icon={icon}
        iconSize={this.props.iconSize}
        iconStyle={{ marginRight: Metrics.smallMargin, color: Colors.theme1 }}
        buttonStyle={Object.assign({}, this.props.buttonStyle, this.props.customButtonStyle)}
        fullWidth={false}
      />
    )
  }

}

import { Fonts, Metrics } from '../../../Themes/'

export default {
  text: {
    textAlign: 'center',
    marginTop: Metrics.cuadrupleBaseMargin,
    marginBottom: Metrics.baseMargin,
    fontSize: Fonts.size.title
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  firstButton: {
    marginLeft: 11
  },
  appStoreImg: {
    height: 44
  },
  playStoreImg: {
    height: 66
  }
}
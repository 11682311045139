import RaixerConfig from '../Config/RaixerConfig'
import I18n from '../i18n/i18n'
import { getRemainingDays, getRemainingDaysUnpaidSince, getRemainingTimeMessage, timestampToDateTime, getDaysBetweenDates } from './DateTimeUtils'
import { Colors } from '../Themes'

const { connections: RaixerConfigConnections } = RaixerConfig
const { 
  actionsNames, 
  defaultMaxOpenings,
  deviceVersions,
  portPositions,
  warningsActions,
  maxTimeMinutes,
  maxTimeMinutesApiUser,
  doorDefaultValues
} = RaixerConfigConnections

export const getAdminConnections = (connections) => {
  let adminConnections = []
  for (let p of connections) {
    for (let c of p) {
      if (c.profile === 'admin') { adminConnections.push(c) }
    }
  }
  return adminConnections
}

export const getConnectionsTotalDevices = (connections) => {
  const totalDevices = connections[0].length + connections[1].length
  const totalOfflineDevices = connections[0].filter(c => c.device && !c.device.online).length +
    connections[1].filter(c => c.device && !c.device.online).length
  return { totalDevices, totalOfflineDevices }
}

export const getConnectionsHeaderMessages = (connections, fetching) => {
  if (fetching) { return [I18n.t('shared.updating')] }
  if (connections.length === 0) { return [] }
  const { totalDevices, totalOfflineDevices } = getConnectionsTotalDevices(connections)
  let devices = I18n.t('shared.devices')
  let offlineDevices = I18n.t('screens.connections.offlinePlural')
  if (totalDevices === 1) { devices = I18n.t('shared.device') }
  if (totalOfflineDevices === 1) { offlineDevices = I18n.t('screens.connections.offlineSingular') }
  return [
    I18n.t('screens.connections.accessTo') + ' ' + totalDevices + ' ' + devices.toLowerCase(),
    totalOfflineDevices + ' ' + offlineDevices
  ]
}

export const getConnectionHeaderMessages = (connection, fetching, justTime = false) => {
  if (fetching) { return [I18n.t('screens.connection.fetching')] }
  const { device } = connection
  const { autoAccessAlwaysActive, lastMovement, pistonInactive, sensors } = device
  const isWifiDevice = connectionIsWifiDevice(connection)
  const remainingTime = getConnectionRemainingTime(device)
  const hasOpenClosedDoor = connectionHasOpenClosedDoor(connection)
  let summaries = []
  if (lastMovement && sensors && sensors.length > 0 && !justTime) {
    let movementSensors = sensors.filter(d => d.use && d.use === 'movement')
    if (movementSensors.length > 0) {
      summaries.push(I18n.t('screens.connection.lastMovement') + timestampToDateTime(lastMovement))
    }
  }
  if (!isWifiDevice && hasOpenClosedDoor && !justTime) {
    if (pistonInactive) { summaries.push(I18n.t('screens.connection.pistonInactive')) }
    else { summaries.push(I18n.t('screens.connection.pistonActive')) }
  }
  if ((remainingTime && !autoAccessAlwaysActive) || (remainingTime && justTime)) {
    const { openings, maxOpenings } = getConnectionOpenings(device)
    let autoAccessMsg = remainingTime + ' ' + I18n.t('screens.connection.sessionEnd')
    if (!justTime) { autoAccessMsg += ' | ' + openings + '/' + maxOpenings + ' ' + I18n.t('shared.openings') }
    summaries.push(autoAccessMsg)
  }
  if (autoAccessAlwaysActive && !justTime) {
    summaries.push(I18n.t('screens.connection.autoAccessAlwaysActive'))
  }
  return summaries
}

export const getConnectionNotifications = (connection, hasAutoAccessDoor, showFreeProfile = false, isFreeVersionDevice = false, userHasConnectionsAvailableForCheckin = false) => {
  try {
    const { deviceId, device, owner: connectionOwner, profile, disabled } = connection
    const { 
      campaign, created, offlineAutoAccess, online, owner: deviceOwner, ownerApiUser, ownerPaymentOk, trialEnded = false,
      trialEndedAccount = false, updating = false, openClosedBlocked = false, autoAccessBlocked = false, 
      firmwareUpdateAvailable = false, nukiBatteryCritical = false, exhibitor = false, asUnsubscribedDate = null
    } = device
    const { subscriptionId, subscription } = campaign || {}
    const { unpaidSince } = subscription || {}
    let warnings = getConnectionVitalsNotifications(device)
    if (!deviceId) {
      warnings.push({ text: I18n.t('screens.connection.warnings.noRaixerDevice'), actionName: warningsActions.noRaixerDevice, color: Colors.info, icon: 'rocket' })
      if (userHasConnectionsAvailableForCheckin) {
        warnings.push({ text: I18n.t('screens.connection.warnings.linkRaixerDevice'), actionName: warningsActions.linkRaixerDevice, color: Colors.info, icon: 'link' })
      }
    } else {
      if (!online && !updating) {
        warnings.push({ text: I18n.t('screens.connection.warnings.offline'), actionName: warningsActions.deviceOffline, color: Colors.yellow, icon: 'warning', showInSettings: true })
      } else if (updating) {
        warnings.push({ text: I18n.t('screens.connection.warnings.updating'), actionName: warningsActions.deviceUpdating, color: Colors.info, icon: 'cycle', showInSettings: true })
      }
      if (!updating && firmwareUpdateAvailable) {
        const actionName = deviceOwner === connectionOwner || profile === 'admin' ? warningsActions.firmwareUpdate : warningsActions.firmwareUpdateNotOwner
        warnings.push({ text: I18n.t('screens.connection.warnings.updateAvailable'), actionName, color: Colors.info, icon: 'cycle', showInSettings: true })
      }
      if (!online && !updating && offlineAutoAccess && hasAutoAccessDoor) {
        warnings.push({ text: I18n.t('screens.connection.warnings.offlineAutoAccess'), actionName: warningsActions.offlineAutoAccess, color: Colors.info, icon: 'lifebuoy' })
      }
      if (openClosedBlocked) {
        warnings.push({ text: I18n.t('screens.connection.warnings.openClosedBlocked'), actionName: warningsActions.openClosedBlocked, color: Colors.yellow, icon: 'warning' })
      }
      if (autoAccessBlocked) {
        warnings.push({ text: I18n.t('screens.connection.warnings.autoAccessBlocked'), actionName: warningsActions.autoAccessBlocked, color: Colors.yellow, icon: 'warning' })
      }
      if (nukiBatteryCritical) {
        warnings.push({ text: I18n.t('screens.connection.warnings.nukiBatteryCritical'), actionName: warningsActions.nukiBatteryCritical, color: Colors.error, icon: 'warning' })
      }
    }
    if (ownerApiUser) {
      if (!ownerPaymentOk) {
        warnings.push({ text: I18n.t('screens.connection.warnings.apiUserPaymentFailed'), actionName: null, color: Colors.yellow, icon: 'warning' })
      }
    } else {
      if (!disabled && deviceId) {
        if (showFreeProfile && isFreeVersionDevice) {
          if (trialEndedAccount) {
            const actionName = deviceOwner === connectionOwner ? warningsActions.deviceTrialAccountEnded : warningsActions.deviceTrialAccountEndedNotOwner
            warnings.push({ text: I18n.t('screens.connection.warnings.cantUseAccount'), actionName, color: Colors.yellow, icon: 'warning' })
          } else if (trialEnded) {
            if (unpaidSince) {
              const actionText = deviceOwner === connectionOwner ? I18n.t('screens.connection.warnings.paymentFailedBlocked') : I18n.t('screens.connection.warnings.paymentFailedBlockedNotOwner')
              const actionName = deviceOwner === connectionOwner ? warningsActions.paymentFailedBlocked : warningsActions.paymentFailedBlockedNotOwner
              warnings.push({ text: actionText, actionName, color: Colors.error, icon: 'warning' })
            } else {
              const actionName = deviceOwner === connectionOwner ? warningsActions.deviceTrialEnded : warningsActions.deviceTrialEndedNotOwner
              warnings.push({ text: I18n.t('screens.connection.warnings.cantUse'), actionName, color: Colors.theme1, icon: 'rocket' })
            }
          } else if (asUnsubscribedDate) {
            let text = I18n.t('screens.connection.warnings.cantUseAccount')
            const remainingDays = getRemainingDays(asUnsubscribedDate, false)
            if (remainingDays === 0) {
              text = I18n.t('screens.connection.warnings.upgradeExpireToday')
            } else if (remainingDays === 1) {
              text = I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.day')
            } else if (remainingDays > 1) {
              text = I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.days')
            }
            warnings.push({ text, actionName: warningsActions.deviceUpgradeRemaining, color: Colors.theme1, icon: 'rocket' })
          } else if (unpaidSince) {
            let actionText = deviceOwner === connectionOwner ? I18n.t('screens.connection.warnings.paymentFailing') : I18n.t('screens.connection.warnings.paymentFailingNotOwner')
            const remainingDays = getRemainingDaysUnpaidSince(unpaidSince)
            if (remainingDays === 0) {
              actionText += ' ' + I18n.t('screens.connection.warnings.upgradeExpireToday')
            } else if (remainingDays === 1) {
              actionText += ' ' + I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.day')
            } else if (remainingDays > 1) {
              actionText += ' ' + I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.days')
            }
            const actionName = deviceOwner === connectionOwner ? warningsActions.paymentFailed : warningsActions.paymentFailedNotOwner
            warnings.push({ text: actionText, actionName, color: Colors.yellow, icon: 'warning' })
          } else {
            const actionName = deviceOwner === connectionOwner ? warningsActions.deviceTrial : warningsActions.deviceTrialNotOwner
            if (!subscriptionId && !exhibitor) {
              let text = I18n.t('screens.connection.warnings.cantUseAccount')
              const remainingDays = getRemainingDays(created)
              if (remainingDays === 0) {
                text = I18n.t('screens.connection.warnings.trialExpireToday') + '. ' + I18n.t('screens.connection.warnings.afterTrialFreeVersion')
              } else if (remainingDays === 1) {
                text = I18n.t('screens.connection.warnings.remainingTrial') + ': ' + remainingDays + ' ' + I18n.t('shared.day') + '. ' + I18n.t('screens.connection.warnings.afterTrialFreeVersion')
              } else if (remainingDays > 1) {
                text = I18n.t('screens.connection.warnings.remainingTrial') + ': ' + remainingDays + ' ' + I18n.t('shared.days') + '. ' + I18n.t('screens.connection.warnings.afterTrialFreeVersion')
              }
              warnings.push({ text, actionName, color: Colors.yellow, icon: 'warning' })
            }
          }
        } else {
          if (trialEnded) {
            if (unpaidSince) {
              const actionText = deviceOwner === connectionOwner ? I18n.t('screens.connection.warnings.paymentFailedBlocked') : I18n.t('screens.connection.warnings.paymentFailedBlockedNotOwner')
              const actionName = deviceOwner === connectionOwner ? warningsActions.paymentFailedBlocked : warningsActions.paymentFailedBlockedNotOwner
              warnings.push({ text: actionText, actionName, color: Colors.error, icon: 'warning' })
            } else {
              const actionName = deviceOwner === connectionOwner ? warningsActions.deviceTrialAccountEnded : warningsActions.deviceTrialAccountEndedNotOwner
              warnings.push({ text: I18n.t('screens.connection.warnings.cantUseAccount'), actionName, color: Colors.yellow, icon: 'warning' })
            }
          } else if (asUnsubscribedDate) {
            let text = I18n.t('screens.connection.warnings.cantUseAccount')
            const remainingDays = getRemainingDays(asUnsubscribedDate, false)
            if (remainingDays === 0) {
              text = I18n.t('screens.connection.warnings.upgradeExpireToday')
            } else if (remainingDays === 1) {
              text = I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.day')
            } else if (remainingDays > 1) {
              text = I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.days')
            }
            warnings.push({ text, actionName: warningsActions.deviceUpgradeRemaining, color: Colors.theme1, icon: 'rocket' })
          } else if (unpaidSince) {
            let actionText = deviceOwner === connectionOwner ? I18n.t('screens.connection.warnings.paymentFailing') : I18n.t('screens.connection.warnings.paymentFailingNotOwner')
            const remainingDays = getRemainingDaysUnpaidSince(unpaidSince)
            if (remainingDays === 0) {
              actionText += ' ' + I18n.t('screens.connection.warnings.upgradeExpireToday')
            } else if (remainingDays === 1) {
              actionText += ' ' + I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.day')
            } else if (remainingDays > 1) {
              actionText += ' ' + I18n.t('screens.connection.warnings.remainingUpgrade') + ': ' + remainingDays + ' ' + I18n.t('shared.days')
            }
            const actionName = deviceOwner === connectionOwner ? warningsActions.paymentFailed : warningsActions.paymentFailedNotOwner
            warnings.push({ text: actionText, actionName, color: Colors.yellow, icon: 'warning' })
          } else {
            let text
            const actionName = deviceOwner === connectionOwner ? warningsActions.deviceTrial : warningsActions.deviceTrialNotOwner
            if (!subscriptionId && !exhibitor) {
              const remainingDays = getRemainingDays(created)
              if (remainingDays === 0) {
                text = I18n.t('screens.connection.warnings.trialExpireToday')
              } else if (remainingDays === 1) {
                text = I18n.t('screens.connection.warnings.remainingTrial') + ': ' + remainingDays + ' ' + I18n.t('shared.day')
              } else if (remainingDays > 1) {
                text = I18n.t('screens.connection.warnings.remainingTrial') + ': ' + remainingDays + ' ' + I18n.t('shared.days')
              }
              warnings.push({ text, actionName, color: Colors.yellow, icon: 'warning' })
            }
          }
        }
      }
    }
    return warnings
  } catch {
    return []
  }
}

export const getConnectionQuickActions = (connection, lang, showGyroUnlockButton = true) => {
  lang = lang || 'en'
  try {
    const { device, profile } = connection
    const { autoAccessAlwaysActive, doors, inUseComplete, pistonInactive, version } = device
    const isWifiDevice = connectionIsWifiDevice(connection)
    let buttons = []
    let autoAccessButtons = []
    let pistonButtons = []
    if (version === deviceVersions.gyro.id) {
      for (let i = 0; i < doors.length; i++) {
        const door = doors[i]
        const { intercomCallFirst, sensorMode } = door
        if (sensorMode === 'gyro') {
          const { openTitle, unlockTitle, closeTitle } = getTitlesOfQuickGyroActions(door, i, doors.length, lang, showGyroUnlockButton)
          const { openGyroTask, closeGyroTask, unlockGyroTask, bleOpenTask, bleCloseTask, bleUnlockTask } = getActionsOfQuickGyroActions()
          buttons.push({
            type: 'door',
            icon: 'lock-open',
            title: openTitle,
            task: openGyroTask,
            bleTask: bleOpenTask
            // withIndicator: false,
          })
          buttons.push({
            type: 'door',
            icon: showGyroUnlockButton ? 'key' : 'lock',
            title: closeTitle,
            task: closeGyroTask,
            bleTask: bleCloseTask
            // withIndicator: false,
          })
          if (showGyroUnlockButton) {
            buttons.push({
              type: 'door',
              icon: 'key',
              title: unlockTitle,
              task: unlockGyroTask,
              bleTask: bleUnlockTask
              // withIndicator: false,
            })
          }
        } else {
          const port = getPortOfDoor(door, version)
          const { buttonTitle } = getTitlesOfQuickActions(door, i, doors.length, lang)
          const { openTask } = getActionsOfQuickActions(port)
          buttons.push({
            type: 'door',
            icon: 'lock-open',
            title: buttonTitle,
            task: openTask,
            // withIndicator: false,
            intercomCallFirst: intercomCallFirst ? intercomCallFirst : false
          })
        }
      }
    } else {
      for (let i = 0; i < doors.length; i++) {
        const door = doors[i]
        const { intercomCallFirst, port: doorPort, sensorMode } = door
        if (doorPort !== 'null') {
          const port = getPortOfDoor(door, version)
          const { buttonTitle, autoAccessTitle, pistonTitle } = getTitlesOfQuickActions(door, i, doors.length, lang)
          const { openTask, autoTask, alternateAutoTask, pistonTask, alternatePistonTask } = getActionsOfQuickActions(port)
          buttons.push({
            type: 'door',
            icon: 'lock-open',
            title: buttonTitle,
            task: openTask,
            // withIndicator: false,
            intercomCallFirst: intercomCallFirst && sensorMode === 'bell' ? intercomCallFirst : false
          })
          if (!sensorMode || sensorMode === 'bell') {
            autoAccessButtons.push({
              type: 'access',
              icon: 'bell',
              title: autoAccessTitle,
              description: inUseComplete[port - 1] || autoAccessAlwaysActive ? I18n.t('shared.on') : I18n.t('shared.off'),
              task: inUseComplete[port - 1] ? alternateAutoTask : autoTask,
              // withIndicator: true,
              port
            })
          }
          if (sensorMode === 'openingSensor' && !isWifiDevice) {
            pistonButtons.push({
              type: 'piston',
              icon: 'key',
              title: pistonTitle,
              description: pistonInactive ? I18n.t('shared.off') : I18n.t('shared.on'),
              task: !pistonInactive ? alternatePistonTask : pistonTask,
              // withIndicator: true,
              port
            })
          }
        } else if (sensorMode === 'nuki') {
          const { nukiButtonTitle, nukiPistonOpenTitle, nukiPistonCloseTitle } = getTitlesOfQuickNukiActions(door, lang)
          const { nukiOpenTask, nukiPistonTask, nukiAlternatePistonTask } = getActionsOfQuickNukiActions()
          buttons.push({
            type: 'door',
            nuki: true,
            icon: 'lock-open',
            title: nukiButtonTitle,
            task: nukiOpenTask,
            // withIndicator: false,
          })
          buttons.push({
            type: 'door',
            nuki: true,
            icon: 'key',
            title: nukiPistonOpenTitle,
            task: nukiAlternatePistonTask,
            // withIndicator: false,
          })
          buttons.push({
            type: 'door',
            nuki: true,
            icon: 'key',
            title: nukiPistonCloseTitle,
            task: nukiPistonTask,
            // withIndicator: false,
          })
          // pistonButtons.push({
          //   type: 'piston',
          //   nuki: true,
          //   icon: 'key',
          //   title: nukiPistonTitle,
          //   description: nukiPistonInactive ? I18n.t('shared.opened') : I18n.t('shared.closed'),
          //   task: !nukiPistonInactive ? nukiAlternatePistonTask : nukiPistonTask
          // })
        } else if (sensorMode === 'gyro') {
          const { openTitle, unlockTitle, closeTitle } = getTitlesOfQuickGyroActions(door, i, doors.length, lang, showGyroUnlockButton)
          const { openGyroTask, closeGyroTask, unlockGyroTask, bleOpenTask, bleCloseTask, bleUnlockTask } = getActionsOfQuickGyroActions()
          buttons.push({
            type: 'door',
            gyro: true,
            icon: 'lock-open',
            title: openTitle,
            task: openGyroTask,
            bleTask: bleOpenTask
            // withIndicator: false,
          })
          buttons.push({
            type: 'door',
            gyro: true,
            icon: showGyroUnlockButton ? 'key' : 'lock',
            title: closeTitle,
            task: closeGyroTask,
            bleTask: bleCloseTask
            // withIndicator: false,
          })
          if (showGyroUnlockButton) {
            buttons.push({
              type: 'door',
              gyro: true,
              icon: 'key',
              title: unlockTitle,
              task: unlockGyroTask,
              bleTask: bleUnlockTask
              // withIndicator: false,
            })
          }
        }
      }
    }
    if (profile && profile === 'admin') { return autoAccessButtons.concat(buttons).concat(pistonButtons) }
    return buttons
  } catch (err) {
    return []
  }
}

export const getConnectionStatus = (connection) => {
  try {
    const { device } = connection
    const { inUseComplete, autoAccessAlwaysActive } = device
    if (autoAccessAlwaysActive) { return true }
    let finalInUse = false
    if (inUseComplete) {
      for (let i of inUseComplete) {
        if (i) {
          finalInUse = true
          break
        }
      }
    }
    return finalInUse
  } catch {
    return false
  }
}

export const getConnectionSwitchValueForList = (connectionId, connectionStatus, activeConnection, fetching) => {
  if (connectionStatus) {
    return fetching && activeConnection === connectionId ? false : connectionStatus
  } else {
    return fetching && activeConnection === connectionId ? true : connectionStatus
  }
}

export const connectionHasAutoAccessDoor = (connection, forConnectionsList) => {
  try {
    const { device } = connection
    const { doors, version } = device
    if (forConnectionsList && version === deviceVersions.v3.id) { return false }
    return doors.filter(d => (d.sensorMode === 'bell' || !d.sensorMode) && ((d.port && d.port !== 'null') || !d.port)).length > 0
  } catch {
    return false
  }
}

export const connectionIsMiniWithGyroDoor = (connection) => {
  try {
    const { device } = connection || {}
    const { version } = device || {}
    const isMini = version === deviceVersions.v8.id
    return isMini && connectionHasGyroDoor(connection)
  } catch {
    return false
  }
}

export const connectionIsProWithGyroDoor = (connection) => {
  try {
    const { device } = connection || {}
    const { version } = device || {}
    const isPro = version === deviceVersions.v6.id || version === deviceVersions.v7.id
    return isPro && connectionHasGyroDoor(connection)
  } catch {
    return false
  }
}

export const connectionHasGyroDoor = (connection) => {
  try {
    const { device } = connection
    const { doors } = device
    return doors.filter(d => d.sensorMode === 'gyro').length > 0
  } catch {
    return false
  }
}

export const connectionHasStreetDoor = (connection) => {
  try {
    const { device } = connection
    const { doors } = device
    return doors.filter(d => d.use === 'street').length > 0
  } catch {
    return false
  }
}

export const connectionHasOpenClosedDoor = (connection) => {
  try {
    const { device } = connection
    const { doors } = device
    return doors.filter(d => (d.sensorMode === 'openingSensor') && ((d.port && d.port !== 'null') || !d.port)).length > 0
  } catch {
    return false
  }
}

export const connectionHasMultipleAutoAccessDoors = (connection) => {
  try {
    const { device } = connection
    const { doors } = device
    return doors.filter(d => (d.sensorMode === 'bell' || !d.sensorMode) && ((d.port && d.port !== 'null') || !d.port)).length > 1
  } catch {
    return false
  }
}

export const connectionHasUsableDoors = (connection) => {
  try {
    const { device } = connection
    const { doors, offlineGyro } = device
    return doors.filter(d => ((d.port && d.port !== 'null') || !d.port || (d.port === 'null' && d.sensorMode === 'gyro' && !offlineGyro))).length > 0
  } catch {
    return false
  }
}

export const connectionHasMultiplePhoneDoors = (connection) => {
  try {
    const { device } = connection
    const { doors } = device
    return doors.filter(d => d.devicePhone && d.callingEnabled && d.port !== 'null').length > 1
  } catch {
    return false
  }
}

export const connectionIsSubscribed = (connection) => {
  try {
    const { device } = connection
    const { campaign, ownerApiUser = false, ownerPaymentOk = false, exhibitor = false } = device
    const { subscription } = campaign
    return (subscription !== null && subscription !== undefined) || (ownerApiUser && ownerPaymentOk) || exhibitor
  } catch {
    return false
  }
}

export const connectionDeviceIsParticleDisabled = (connection) => {
  try {
    const { device } = connection
    const { particleRemovedDate } = device
    if (!particleRemovedDate) { return false }
    return !connectionIsSubscribed(connection)
  } catch {
    return false
  }
}


export const connectionHasPhoneSubscriptions = (connection) => {
  try {
    const { device } = connection
    const { doors } = device
    return doors.filter(d => d.phone && d.phone.subscription).length > 0
  } catch {
    return false
  }
}

export const connectionHasGuestySubscription = (connection) => {
  try {
    const { device } = connection
    const { guestyListingId, guestySubscriptionId } = device
    return guestyListingId !== null && guestyListingId !== undefined && guestySubscriptionId !== null && guestySubscriptionId !== undefined 
  } catch {
    return false
  }
}

export const connectionHasCheckInSubscription = (connection) => {
  try {
    const { device, deviceId, type } = connection
    const checkinSubscriptionId = (!deviceId && type === 'checkin') ? connection.checkinSubscriptionId : device.checkinSubscriptionId
    return checkinSubscriptionId !== null && checkinSubscriptionId !== undefined 
  } catch {
    return false
  }
}

export const connectionIsWifiDevice = (connection) => {
  try {
    const { device } = connection
    const { version } = device
    if (
      version === deviceVersions.v1.id || version === deviceVersions.v2.id || 
      version === deviceVersions.v4.id || version === deviceVersions.v5.id ||
      version === deviceVersions.v7.id
    ) { 
      return true 
    }
    return false
  } catch {
    return false
  }
}

export const connectionIsGyroDevice = (connection) => {
  try {
    const { device } = connection
    const { version } = device
    if (version === deviceVersions.gyro.id) { return true }
    return false
  } catch {
    return false
  }
}

export const connectionIsBluetoothDevice = (connection) => {
  try {
    const { device } = connection
    const { version } = device
    if (version === deviceVersions.v6.id || version === deviceVersions.v7.id) { return true }
    return false
  } catch {
    return false
  }
}

export const connectionIsSleepDevice = (connection) => {
  try {
    const { device } = connection
    const { version } = device
    if (/*version === deviceVersions.v3.id || */version === deviceVersions.v6.id || version === deviceVersions.v7.id) { return true }
    return false
  } catch {
    return false
  }
}

export const connectionIsFreeVersionDevice = (connection) => {
  try {
    const { device } = connection
    const { version } = device
    if (version === deviceVersions.v5.id || version === deviceVersions.v6.id || version === deviceVersions.v7.id) { return true }
    return false
  } catch {
    return false
  }
}

export const getPortOfDoor = (door, version) => {
  let port = portPositions.default
  if (door.port) {
    if (portPositions[door.port]) { port = portPositions[door.port] }
  }
  if (version && version === deviceVersions.v1.id) { port = portPositions.default }
  return port
}

export const getNumberOfPhonesOfConnectionDeviceDoor = (connection, doorId) => {
  try {
    const door = connection.device.doors.filter(d => d._id === doorId)[0]
    const { authorizedPhones = [] } = door
    return authorizedPhones.length 
  } catch {
    return 0
  }
}

export const getPortNameOfTask = (task) => {
  try {
    if (task.indexOf('/') > -1) {
      const taskPort = parseInt(task.substr(task.indexOf('/') + 1))
      if (!isNaN(taskPort)) { return portPositions[taskPort] }
    }
    return portPositions[portPositions.default]
  } catch {
    return portPositions[portPositions.default]
  }
}

export const getAutoAccessTimeOfConnection = (connection, d, task) => {
  const portName = getPortNameOfTask(task)
  const { device = {} } = connection
  const { doors = [], trialEnded = false } = device
  if (trialEnded) { return maxTimeMinutes }
  try {
    if (d) {
      // Door passed, obtain the parameter directly from the same door
      const { autoAccessHours = doorDefaultValues.autoAccessHours } = d
      return autoAccessHours * 60
    } else {
      // No door passed, first obtain the door from the connection and the port of the task and then the parameter from the door
      const door = doors.filter(d => d.port === portName)[0]
      if (door) {
        const { autoAccessHours = doorDefaultValues.autoAccessHours } = door
        return autoAccessHours * 60
      }
      return doorDefaultValues.autoAccessHours * 60
    }
  } catch {
    return doorDefaultValues.autoAccessHours * 60
  }
}

export const getNameOfDoor = (door, index) => {
  const { name } = door
  if (name) { return name }
  return I18n.t('shared.door') + ' ' + index
}

export const getNameOfSensor = (sensor, index, activeLang) => {
  const { name, use } = sensor
  if (use === 'noise') {
    const noiseSensorTranslate = activeLang === 'es' ? 
      I18n.t('shared.sensor') + ' ' + I18n.t('shared.noise').toLowerCase()
      :
      I18n.t('shared.noise') + ' ' + I18n.t('shared.sensor').toLowerCase()
    if (name) { return noiseSensorTranslate + ' - ' + name }
    return noiseSensorTranslate + ' - ' + index
  } else if (use === 'movement') {
    const movementSensorTranslate = activeLang === 'es' ? 
      I18n.t('shared.sensor') + ' ' + I18n.t('shared.movement').toLowerCase()
      :
      I18n.t('shared.movement') + ' ' + I18n.t('shared.sensor').toLowerCase()
    if (name) { return movementSensorTranslate + ' - ' + name }
    return movementSensorTranslate + ' - ' + index
  } else if (use === 'pushButton') { 
    if (name) { return activeLang === 'es' ? I18n.t('shared.pushButton') + ' ' + name : name + ' ' + I18n.t('shared.pushButton').toLowerCase() }
    return I18n.t('shared.pushButton') + ' ' + index
  } else {
    if (name) { return activeLang === 'es' ? I18n.t('shared.sensor') + ' ' + name : name + ' ' + I18n.t('shared.sensor').toLowerCase() }
    return I18n.t('shared.sensor') + ' ' + index
  }
}

export const getSensorModeOfDoor = (door) => {
  const { sensorMode = 'bell' } = door
  if (sensorMode === 'bell') { return I18n.t('shared.autoAccess') }
  else if (sensorMode === 'openingSensor') { return I18n.t('shared.openClosed') }
  else if (sensorMode === 'nuki') { return I18n.t('shared.nuki') }
  else { return I18n.t('shared.noSensor') }
}

export const getConnectionDoorById = (connection, doorId) => {
  try {
    const { device } = connection
    const { doors } = device
    return doors.filter(d => d._id === doorId)[0]
  } catch {
    return {}
  }
}

export const getConnectionDoorByPort = (connection, port) => {
  port = parseInt(port)
  if (isNaN(port)) { return {} }
  try {
    const { device } = connection
    const { doors, version } = device
    if (port === portPositions.nuki) {
      const door = doors.filter(d => d.sensorMode === 'nuki')[0]
      return door || {}
    } else {
      for (let door of doors) {
        const doorPort = getPortOfDoor(door, version)
        if (doorPort === port) {
          return door
        }
      }
    }
    return {}
  } catch {
    return {}
  }
}

export const getConnectionDoorNameByPort = (connection, port) => {
  if (port.indexOf(',') > -1) { return I18n.t('shared.all') }
  port = parseInt(port)
  if (isNaN(port)) { return I18n.t('shared.door') + ' 1' }
  try {
    const { device } = connection
    const { doors, version } = device
    let position = -1
    let i = 0
    let name
    for (let door of doors) {
      const { name: doorName } = door
      const doorPort = getPortOfDoor(door, version)
      if (doorPort === port) {
        if (doorName) { name = doorName }
        position = i
      }
      i++
    }
    if (!name && position >= 0) { name = I18n.t('shared.door') + ' ' + (position + 1) }
    else if (!name) { name = I18n.t('shared.door') + ' 1' }
    return name
  } catch {
    return I18n.t('shared.door') + ' 1'
  }
}

export const getConnectionAutoAccessDoorPort = (connection) => {
  try {
    const { device } = connection
    const { doors, version } = device
    let port
    for (let door of doors) {
      const { sensorMode } = door
      if (sensorMode === 'bell' || !sensorMode) { 
        port = getPortOfDoor(door, version) 
        break
      }
    }
    return port
  } catch {
    return 1
  }
}

export const getConnectionSelectedDoorPort = (connection, doorId) => {
  try {
    const { device } = connection
    const { doors, version } = device
    let port
    for (let door of doors) {
      const { _id, sensorMode } = door
      if (_id === doorId) {
        if (sensorMode === 'nuki') { port = portPositions.nuki } 
        else { port = getPortOfDoor(door, version)  }
        break
      }
    }
    return port
  } catch {
    return 1
  }
}

export const updateConnectionsWithConnection = (connections, connection, remove = false) => {
  let newConnections = []
  const { _id } = connection
  for (let c of connections) {
    const { _id: actualId } = c
    if (!remove) {
      if (actualId === _id) { newConnections.push(connection) }
      else { newConnections.push(c) }
    } else {
      if (actualId !== _id) { newConnections.push(c) }
    }
  }
  return newConnections
}

export const getActualConnection = (connections, connectionId) => {
  if (!connectionId) { return null }
  return connections.filter(c => c._id === connectionId)[0] || null
}

export const getConnectionsOfUser = (connections, userId) => {
  try {
    return connections.filter((c) => {
      const { device } = c
      const { owner } = device
      return owner === userId
    })
  } catch {
    return []
  }
}

export const getCheckInConnectionsOfUser = (connections, userId) => {
  try {
    return connections.filter((c) => {
      const { type, deviceId } = c
      return !deviceId && type === 'checkin'
    })
  } catch(err) {
    return []
  }
}


export const getConnectionMaxOpenings = (connection) => {
  try {
    const { device } = connection
    const { doors } = device
    let openings = defaultMaxOpenings
    if (doors && doors.length > 0) {
      for (let d of doors) {
        const { maxOpenings, port, sensorMode } = d
        if ( (!sensorMode || sensorMode === 'bell') && (!port || (port && port !== 'null')) ) {
          const o = parseInt(maxOpenings)
          if (!isNaN(o) && o > openings) { openings = o }
        }
      }
    }
    return openings
  } catch {
    return defaultMaxOpenings
  }
}

export const getConnectionSignal = (connection) => {
  try {
    return connection.device.vitals.signal
  } catch {
    return null
  }
}

export const getConnectionBattery = (connection) => {
  try {
    return { ...connection.device.vitals.power.battery, updatedAt: connection.device.vitals.updatedAt }
  } catch {
    return null
  }
}

export const getConnectionMiniGyroBattery = (connection) => {
  try {
    const gyroDoor = connection.device.doors
    .filter((d) => (d.device))
    .filter((door) => (door.device.version === deviceVersions.gyro.id))[0]
    const deviceOfDoor = gyroDoor.device;
    const battery = deviceOfDoor.battery;
    return { charge: battery, updatedAt: deviceOfDoor.updated }
  } catch {
    return null
  }
}

export const getConnectionMinInUse = (connection) => {
  try {
    const { device } = connection
    const { inUseComplete } = device
    return getMinInUseTime(inUseComplete)
  } catch {
    return null
  }
}

export const getConnectionQrCodeByProfile = (connection, qrType, profile) => {
  try {
    const { qrs, transferQrs } = connection
    const qrArray = qrType === 'transfer' ? transferQrs : qrs
    let profileQr
    for (let qr of qrArray) {
      const { profile: qrProfile } = qr
      if (qrProfile === profile) { 
        profileQr = qr 
        break
      }
    }
    const { url } = profileQr
    return url
  } catch {
    return null
  }
}

export const getAllSwitchsValue = (actualValue, fetching) => {
  if (actualValue) {
    return fetching ? false : actualValue
  } else {
    return fetching ? true : actualValue
  }
}

export const connectionCanAddSensors = (connection) => {
  try {
    const { device } = connection
    const { version } = device
    return deviceVersions[version].canAddSensors
  } catch {
    return false
  }
}

export const connectionCanAddMoreDoors = (connection, excludeNukiDoors = false) => {
  try {
    const { device } = connection
    const { doors, version } = device
    const maxDoors = deviceVersions[version].maxDoors
    const maxNukiDoors = deviceVersions[version].maxNukiDoors
    if (!excludeNukiDoors && (version === deviceVersions.v6.id || version === deviceVersions.v7.id)) {
      const otherDoors = doors.filter(d => d.sensorMode !== 'nuki')
      const nukiDoors = doors.filter(d => d.sensorMode === 'nuki')
      return otherDoors.length < maxDoors || nukiDoors.length < maxNukiDoors
    }
    return doors.filter(d => d.sensorMode !== 'nuki').length < maxDoors
  } catch {
    return false
  }
}

export const connectionCanAddMoreNukiDoors = (connection) => {
  try {
    const { device } = connection
    const { doors, version } = device
    const maxNukiDoors = deviceVersions[version].maxNukiDoors
    if (version !== deviceVersions.v6.id && version !== deviceVersions.v7.id) { return false }
    const nukiDoors = doors.filter(d => d.sensorMode === 'nuki')
    return nukiDoors.length < maxNukiDoors
  } catch {
    return false
  }
}

export const connectionCanAddMoreSensors = (connection) => {
  try {
    const { device } = connection
    const { sensors, version } = device
    const maxSensors = deviceVersions[version].maxSensors
    return sensors.filter(s => s.use !== 'noise').length < maxSensors
  } catch {
    return false
  }
}

export const connectionCanAddMorePushButtons = (connection) => {
  try {
    const { device } = connection
    const { doors = [], version } = device
    const maxPushButtons = deviceVersions[version].maxPushButtons
    return doors.filter(d => d.hasPushButton).length < maxPushButtons
  } catch {
    return false
  }
}

export const getConnectionDeviceVariableTranslation = (connection, variableType, invert) => {
  try {
    const { device } = connection
    let { formVariable = null } = device
    if (formVariable === null || formVariable === -1) { return I18n.t('shared.variableNotFound') }
    if (variableType === 'movement') {
      if (!invert) {
        if (formVariable === 1) { return I18n.t('shared.movementFound') }
        else if (formVariable === 0) { return I18n.t('shared.movementNotFound') }
      } else {
        if (formVariable === 0) { return I18n.t('shared.movementFound') }
        else if (formVariable === 1) { return I18n.t('shared.movementNotFound') }
      }
    } else if (variableType === 'noise') {
      return formVariable.toString()
    } else if (variableType === 'bell') {
      return formVariable.toString()
    } else if (variableType === 'openingSensor') {
      if (!invert) {
        if (formVariable === 1) { return I18n.t('shared.opened') }
        else if (formVariable === 0) { return I18n.t('shared.closed') }
      } else {
        if (formVariable === 0) { return I18n.t('shared.opened') }
        else if (formVariable === 1) { return I18n.t('shared.closed') }
      }
    } else {
      return I18n.t('shared.variableNotFound')
    }
  } catch {
    return I18n.t('shared.variableNotFound')
  }
}

export const getAddedConnectionIdWithDeviceId = (connectionsOld, connectionsNew) => {
  try {
    let connectionId = null
    let oldDevices = []
    for (let p of connectionsOld) {
      for (let c of p) { oldDevices.push(c.deviceId) }
    }
    for (let p of connectionsNew) {
      for (let c of p) {
        if (oldDevices.indexOf(c.deviceId) === -1) { connectionId = c._id }
      }
    }
    return connectionId
  } catch {
    return null
  }
}

export const getAddedConnectionIdWithConnectionId = (connectionsOld, connectionsNew) => {
  try {
    let connectionId = null
    let oldConnections = []
    for (let p of connectionsOld) {
      for (let c of p) { oldConnections.push(c._id) }
    }
    for (let p of connectionsNew) {
      for (let c of p) {
        if (oldConnections.indexOf(c._id) === -1) { connectionId = c._id }
      }
    }
    return connectionId
  } catch {
    return null
  }
}

export const getConnectionIdFromDeviceId = (connections, deviceId) => {
  try {
    let connectionId = null
    for (let p of connections) {
      for (let c of p) {
        if (c.deviceId === deviceId) { 
          connectionId = c._id 
          break
        }
      }
      if (connectionId) { break }
    }
    return connectionId
  } catch {
    return null
  }
}

export const getConnectionSubscriptionPlansAndPrices = (connection, justCheckinSubscription = false) => {
  const isWifiDevice = connectionIsWifiDevice(connection)
  const hasPhoneSubscriptions = connectionHasPhoneSubscriptions(connection)
  const hasGuestySubscription = connectionHasGuestySubscription(connection)
  const hasCheckinSubscription = connectionHasCheckInSubscription(connection)
  let plansAndPrices = [getConnectionSubscriptionPlanAndPrice(connection, isWifiDevice)]
  if (hasPhoneSubscriptions) {
    const phoneSubscriptions = getConnectionPhoneSubscriptions(connection)
    for (let s of phoneSubscriptions) { plansAndPrices.push(s) }
  }
  if (hasGuestySubscription) {
    const guestySubscription = getConnectionGuestySubscription(connection)
    if (guestySubscription) { plansAndPrices.push(guestySubscription) }
  }
  if (hasCheckinSubscription) {
    const checkInSubscription = getConnectionCheckinSubscription(connection)
    if (checkInSubscription) { 
      if (justCheckinSubscription) { plansAndPrices = [checkInSubscription] }
      else { plansAndPrices.push(checkInSubscription) }
    }
  }
  return plansAndPrices
}

const getConnectionSubscriptionPlanAndPrice = (connection, isWifiDevice) => {
  const type = isWifiDevice ? 'intercom' : 'access'
  try {
    let result = {}
    let plan = I18n.t('shared.notUpgraded')
    let priceMsg = I18n.t('shared.upgrade')
    let canEdit = true
    const { device } = connection
    const { campaign, ownerApiUser, ownerPaymentOk, exhibitor, asUnsubscribedDate } = device
    const { subscription } = campaign
    if (ownerApiUser) {
      plan = I18n.t('shared.apiUser')
      priceMsg = ownerPaymentOk ? I18n.t('shared.paid') : I18n.t('shared.unpaid')
      canEdit = false
    } else if (exhibitor) {
      plan = I18n.t('shared.exhibitor')
      priceMsg = I18n.t('shared.paid')
      canEdit = false
    } else if (asUnsubscribedDate) {
      const remainingDays = getRemainingDays(asUnsubscribedDate, false)
      if (remainingDays === 0) { plan = I18n.t('shared.expiresToday') }
      else if (remainingDays === 1) { plan = remainingDays + ' ' + I18n.t('shared.day') + ' ' + I18n.t('shared.remaining').toLowerCase() }
      else if (remainingDays > 1) { plan = remainingDays + ' ' + I18n.t('shared.days') + ' ' + I18n.t('shared.remaining').toLowerCase() }
    } else if (subscription) {
      const { _id, stripeSubscription, subscriptionPlanId, subscriptionPlan, unpaidSince } = subscription
      const { billingCycle, name, price, stripePlanId } = subscriptionPlan
      plan = name
      priceMsg = price.toFixed(2) + I18n.t('shared.euro') + '/' + 
        (billingCycle === 'month' ? I18n.t('shared.month').toLowerCase() : I18n.t('shared.year').toLowerCase())
      result = { _id, billingCycle, name, price, stripeSubscription, subscriptionPlanId, selected: stripePlanId, unpaidSince }
    }
    result.left = plan
    result.right = priceMsg
    result.canEdit = canEdit
    result.type = type
    return result
  } catch {
    return { left: I18n.t('shared.notUpgraded'), right: I18n.t('shared.upgrade'), canEdit: true, type }
  }
}

const getConnectionPhoneSubscriptions = (connection) => {
  try {
    let subscriptions = []
    const { device } = connection
    const { doors } = device
    for (let d of doors) {
      const { _id: doorId, phone } = d
      const { subscription } = phone || {}
      if (subscription) {
        const { 
          _id, 
          stripeSubscription, 
          subscriptionPlanId, 
          subscriptionPlan
        } = subscription
        const { price: phonePrice, billingCycle: phoneBillingCycle, stripePlanId } = subscriptionPlan
        const priceMsgPhone = phonePrice.toFixed(2) + I18n.t('shared.euro') + '/' + 
          (phoneBillingCycle === 'month' ? I18n.t('shared.month').toLowerCase() : I18n.t('shared.year').toLowerCase())
        subscriptions.push({ 
          left: I18n.t('shared.privatePhone'), 
          right: priceMsgPhone, 
          _id, 
          billingCycle: phoneBillingCycle,
          doorId,
          name: I18n.t('shared.privatePhone'), 
          price: phonePrice, 
          stripeSubscription, 
          subscriptionPlanId,
          type: 'phone',
          selected: stripePlanId,
          canEdit: true
        })
      }
    }
    return subscriptions
  } catch {
    return []
  }
}

export const getConnectionGuestySubscription = (connection) => {
  try {
    const { device } = connection
    const { guestyListingId, guestySubscription } = device
    const { 
      _id, 
      stripeSubscription, 
      subscriptionPlanId, 
      subscriptionPlan
    } = guestySubscription
    const { price, billingCycle, stripePlanId } = subscriptionPlan
    const priceMsg = price.toFixed(2) + I18n.t('shared.euro') + '/' + 
      (billingCycle === 'month' ? I18n.t('shared.month').toLowerCase() : I18n.t('shared.year').toLowerCase())
    return {
      left: I18n.t('shared.guestyIntegration'), 
      right: priceMsg, 
      _id, 
      billingCycle,
      listingId: guestyListingId,
      name: I18n.t('shared.guestyIntegration'), 
      price, 
      stripeSubscription, 
      subscriptionPlanId,
      type: 'guesty',
      selected: stripePlanId,
      canEdit: true
    }
  } catch {
    return null
  }
}

export const getConnectionCheckinSubscription = (connection) => {
  try {
    const { deviceId, device, type } = connection
    const checkinSubscription = !deviceId && type === 'checkin' ? connection.checkinSubscription : device.checkinSubscription
    const { _id, stripeSubscription, subscriptionPlanId, subscriptionPlan } = checkinSubscription
    const { price, billingCycle, stripePlanId } = subscriptionPlan
    const priceMsg = price.toFixed(2) + I18n.t('shared.euro') + '/' + 
      (billingCycle === 'month' ? I18n.t('shared.month').toLowerCase() : I18n.t('shared.year').toLowerCase())
    return {
      left: I18n.t('shared.checkinIntegration'), 
      right: priceMsg, 
      _id, 
      billingCycle,
      name: I18n.t('shared.checkinIntegration'), 
      price, 
      stripeSubscription, 
      subscriptionPlanId,
      type: 'checkin',
      selected: stripePlanId,
      canEdit: true
    }
  } catch {
    return null
  }
}

export const getConnectionDeviceFirmwareDescription = (firmwareDescription, lang) => {
  const splitted = firmwareDescription.split('\n\n')
  let description = lang === 'es' ? splitted[3] || '' : splitted[1] || ''
  return description.replace(/- /g, '')
}

export const getConnectionDeviceOwner = (connection) => {
  try {
    const { device, users } = connection
    const { owner: deviceOwner } = device
    return users.filter(u => u.owner === deviceOwner)[0]
  } catch {
    return null
  }
}

const getConnectionVitalsNotifications = (device) => {
  const { version, vitals } = device
  const isCellular = version === RaixerConfig.connections.deviceVersions.v3.id || version === RaixerConfig.connections.deviceVersions.v6.id
  const isPro = version === RaixerConfig.connections.deviceVersions.v6.id || version === RaixerConfig.connections.deviceVersions.v7.id
  let vitalsWarnings = []
  if (vitals) {
    const { signal, hasElectricity, power } = vitals
    const { battery } = power || {}
    if (signal) {
      const { strength } = signal
      if (strength !== null && strength !== undefined) {
        let text = I18n.t('screens.connection.warnings.signal.poor') 
        let color = Colors.error
        if (strength <= 20) { 
          text = I18n.t('screens.connection.warnings.signal.bad') 
        } else if (strength > 20 && strength <= 50) {
          text = I18n.t('screens.connection.warnings.signal.fair')
          color = Colors.yellow
        } else if (strength > 50 && strength <= 70) {
          text = I18n.t('screens.connection.warnings.signal.good')
          color = Colors.success
        } else if (strength > 70) {
          text = I18n.t('screens.connection.warnings.signal.strong')
          color = Colors.success
        }
        text = (isCellular ? I18n.t('screens.connection.warnings.signal.cellular') : I18n.t('screens.connection.warnings.signal.wifi')) + ' ' + text
        vitalsWarnings.push({ text, actionName: warningsActions.refreshVitals, color, icon: 'bar-graph' })
      }
    }
    if (hasElectricity !== undefined && hasElectricity !== null) {
      let icon = 'progress-two'
      let color = Colors.yellow
      let text = I18n.t('screens.connection.warnings.power.usingBattery') 
      if (hasElectricity === 1) {
        icon = 'battery'
        color = Colors.success
        text = I18n.t('screens.connection.warnings.power.electricity') 
      }
      vitalsWarnings.push({ text, actionName: null, color, icon })
    }
    if (isPro && battery) {
      const { charge, state } = battery
      const parsedCharge = parseInt(charge)
      let color = Colors.error
      let text = I18n.t('screens.connection.warnings.battery.battery') + ': ' + I18n.t('screens.connection.warnings.battery.disconnected').toLowerCase()
      if (state !== 'disconnected' && !isNaN(parsedCharge)) {
        text = I18n.t('screens.connection.warnings.battery.battery') + ': ' + parsedCharge + '%'
        if (state === 'charging') { text += ' (' + I18n.t('screens.connection.warnings.battery.charging').toLowerCase() + ')' }
        else if (state === 'charged') { text += ' (' + I18n.t('screens.connection.warnings.battery.charged').toLowerCase() + ')' }
        else { text += ' (' + I18n.t('screens.connection.warnings.battery.discharging').toLowerCase() + ')' }
        if (parsedCharge >= 30 && parsedCharge < 70) { color = Colors.yellow }
        else if (parsedCharge >= 70) { color = Colors.success }
      }
      vitalsWarnings.push({ text, actionName: warningsActions.refreshVitalsBattery, color, icon: 'battery' })
    }
  }
  return vitalsWarnings
}

const getTitlesOfQuickActions = (door, index, doorsLength, lang) => {
  const { name } = door
  let buttonTitle = I18n.t('shared.open') + ' ' + I18n.t('shared.door').toLowerCase() + (doorsLength > 1 ? ' ' + (index + 1) : '')
  let autoAccessTitle = I18n.t('shared.autoAccess') + (doorsLength > 1 ? ' ' + (index + 1) : '')
  let pistonTitle = I18n.t('shared.piston') + (doorsLength > 1 ? ' ' + (index + 1) : '')
  if (name) {
    buttonTitle = I18n.t('shared.open') + ' ' + name
    autoAccessTitle = I18n.t('shared.autoAccess') + ' ' + name
    pistonTitle = lang === 'en' ? name + ' ' + I18n.t('shared.piston').toLowerCase() : I18n.t('shared.piston') + ' ' + name
  }
  return { buttonTitle, autoAccessTitle, pistonTitle }
}

const getTitlesOfQuickNukiActions = (door, lang) => {
  const { name } = door
  let nukiButtonTitle = I18n.t('shared.open') + ' ' + I18n.t('shared.nuki')
  let nukiPistonOpenTitle = lang === 'en' ? I18n.t('shared.open') + ' ' + I18n.t('shared.nuki') + ' ' + I18n.t('shared.key').toLowerCase() : I18n.t('shared.open') + ' ' + I18n.t('shared.key').toLowerCase() + ' ' + I18n.t('shared.nuki')
  let nukiPistonCloseTitle = lang === 'en' ? I18n.t('shared.close') + ' ' + I18n.t('shared.nuki') + ' ' + I18n.t('shared.key').toLowerCase() : I18n.t('shared.close') + ' ' + I18n.t('shared.key'.toLowerCase()) + ' ' + I18n.t('shared.nuki')
  if (name) {
    nukiButtonTitle = I18n.t('shared.open') + ' ' + name
    nukiPistonOpenTitle = lang === 'en' ? I18n.t('shared.open') + ' ' + name + ' ' + I18n.t('shared.key').toLowerCase() : I18n.t('shared.open') + ' ' + I18n.t('shared.key').toLowerCase() + ' ' + name
    nukiPistonCloseTitle = lang === 'en' ? I18n.t('shared.close') + ' ' + name + ' ' + I18n.t('shared.key').toLowerCase() : I18n.t('shared.close') + ' ' + I18n.t('shared.key').toLowerCase() + ' ' + name
  }
  return { nukiButtonTitle, nukiPistonOpenTitle, nukiPistonCloseTitle }
}

const getActionsOfQuickActions = (port) => {
  const openTask = actionsNames.openDoor + '/' + port
  const autoTask = actionsNames.startJob + '/' + port
  const alternateAutoTask = actionsNames.stopJob + '/' + port
  const pistonTask = actionsNames.activatePiston
  const alternatePistonTask = actionsNames.deactivatePiston
  return { openTask, autoTask, alternateAutoTask, pistonTask, alternatePistonTask }
}

const getActionsOfQuickNukiActions = () => {
  const nukiOpenTask = actionsNames.nukiOpenDoor
  const nukiPistonTask = actionsNames.nukiActivatePiston
  const nukiAlternatePistonTask = actionsNames.nukiDeactivatePiston
  return { nukiOpenTask, nukiPistonTask, nukiAlternatePistonTask }
}

export const getConnectionRemainingTime = (device) => {
  try {
    const { campaign, inUseComplete, ownerApiUser, ownerPaymentOk, version } = device
    const { subscription } = campaign
    const minInUse = getMinInUseTime(inUseComplete)
    const maxTime = getMaxConnectionTime(subscription, ownerApiUser, ownerPaymentOk) 
    if (!minInUse) { return null }
    return getRemainingTimeMessage(minInUse, maxTime, deviceVersions.v1.id === version)
  } catch {
    return null
  }
}

const getMinInUseTime = (inUseComplete) => {
  let minInUse = null
  for (let inUse of inUseComplete) {
    if (inUse) {
      const intInUse = parseInt(inUse)
      if (!minInUse) { minInUse = intInUse }
      else if (intInUse < minInUse) { minInUse = intInUse }
    }
  }
  return minInUse
}

const getConnectionOpenings = (device) => {
  try {
    const { doors, inUseComplete, openingsComplete } = device
    const doorsInUse = inUseComplete.filter(x => x).length
    let openings = 0
    let maxOpenings = defaultMaxOpenings
    if (doorsInUse > 1) {
      // If more than one door is active, get the maximum number of openings and the minimum number of maxOpenings
      if (openingsComplete && openingsComplete.length > 0) {
        for (let o of openingsComplete) { if (o > openings) { openings = o } }
      }
      if (doors.length > 0) {
        doors.filter(d => d && (!d.sensorMode || d.sensorMode === 'bell')).forEach((d, i) => {
          if (d.maxOpenings && (d.maxOpenings < maxOpenings || i === 0)) { maxOpenings = d.maxOpenings }
        })
      }
    } else {
      let inUseDoorPosition = 0
      inUseComplete.forEach((x, i) => { if (x) { inUseDoorPosition = i } })
      openings = openingsComplete[inUseDoorPosition]
      maxOpenings = doors[inUseDoorPosition].maxOpenings || defaultMaxOpenings
    }
    return { openings, maxOpenings }
  } catch {
    return { openings: 0, maxOpenings: defaultMaxOpenings }
  }
}

const getMaxConnectionTime = (subscription, ownerApiUser, ownerPaymentOk) => {
  if (subscription) { return subscription.subscriptionPlan.maxConnectionTime } 
  else {
    if (ownerApiUser && ownerPaymentOk) { return maxTimeMinutesApiUser } 
    else { return maxTimeMinutes }
  }
}

export const userHasConnectionsAvailableForCheckin = (userId, connections) => {
  try {
    let freeCheckinConnections = []
    for (let p of connections) {
      for (let c of p) {
        const { _id, device } = c
        const { checkinSubscriptionId, owner } = device || {}
        if (!checkinSubscriptionId && owner === userId) { freeCheckinConnections.push(_id) }
      }
    }
    return freeCheckinConnections.length > 0
  } catch {
    return false
  }
}

export const getConnectionShowWizard = (connection) => {
  return false
}

export const getCheckinPlansTrialRemaining = (connections, plan) => {
  try {
    let userPlansAndTrials = {}
    const checkinPlans = RaixerConfig.subscriptionPlans.filter(p => p.type === 'checkin')
    for (let p of checkinPlans) {
      userPlansAndTrials[p.id] = p.trial
    }
    for (let c of connections.flat()) {
      const { checkinSubscription, device } = c || {}
      const { checkinSubscription: deviceCheckinSubscription } = device || {}
      if (checkinSubscription || deviceCheckinSubscription) {
        const { created, subscriptionPlan } = checkinSubscription || deviceCheckinSubscription
        const { stripePlanId } = subscriptionPlan || {}
        if (userPlansAndTrials[stripePlanId] === checkinPlans[0].trial) {
          const remainingDays = 14 - getDaysBetweenDates(created)
          userPlansAndTrials[stripePlanId] = remainingDays > 0 ? remainingDays : 0
        }
      }
    }
    return userPlansAndTrials
  } catch {
    return null
  }
}

const getTitlesOfQuickGyroActions = (door, index, doorsLength, lang, showGyroUnlockButton = true) => {
  const { name } = door
  const doorName = name || (I18n.t('shared.door').toLowerCase() + (doorsLength > 1 ? ' ' + (index + 1) : ''))
  let openTitle = I18n.t('shared.open') + ' ' + doorName
  let unlockTitle = lang === 'en' ? I18n.t('shared.open') + ' ' + doorName + ' ' + I18n.t('shared.key').toLowerCase() : I18n.t('shared.open') + ' ' + I18n.t('shared.key').toLowerCase() + ' ' + doorName
  let closeTitle = showGyroUnlockButton
    ? (lang === 'en' ? I18n.t('shared.close') + ' ' + doorName + ' ' + I18n.t('shared.key').toLowerCase() : I18n.t('shared.close') + ' ' + I18n.t('shared.key'.toLowerCase()) + ' ' + doorName)
    : (lang === 'en' ? I18n.t('shared.close') + ' ' + doorName : I18n.t('shared.close') + ' ' + doorName)
  if (name) {
    openTitle = I18n.t('shared.open') + ' ' + doorName
    unlockTitle = lang === 'en' ? I18n.t('shared.open') + ' ' + doorName + ' ' + I18n.t('shared.key').toLowerCase() : I18n.t('shared.open') + ' ' + I18n.t('shared.key').toLowerCase() + ' ' + doorName
    closeTitle = showGyroUnlockButton
      ? (lang === 'en' ? I18n.t('shared.close') + ' ' + doorName + ' ' + I18n.t('shared.key').toLowerCase() : I18n.t('shared.close') + ' ' + I18n.t('shared.key').toLowerCase() + ' ' + doorName)
      : (lang === 'en' ? I18n.t('shared.close') + ' ' + doorName : I18n.t('shared.close') + ' ' + doorName)
  }
  return { openTitle, unlockTitle, closeTitle }
}

const getActionsOfQuickGyroActions = () => {
  const openGyroTask = actionsNames.openGyro
  const closeGyroTask = actionsNames.closeGyro
  const unlockGyroTask = actionsNames.unlockGyro
  const bleOpenTask = RaixerConfig.ble.manageDoorValue + actionsNames.bleOpenDoor
  const bleCloseTask = RaixerConfig.ble.manageDoorValue + actionsNames.bleCloseDoor
  const bleUnlockTask = RaixerConfig.ble.manageDoorValue + actionsNames.bleUnlockDoor
  return {
    openGyroTask, closeGyroTask, unlockGyroTask, bleOpenTask, bleCloseTask, bleUnlockTask
  }
}

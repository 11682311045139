import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n from '../../../i18n/i18n'
import ConnectionDoorPhoneInformationListItem from './ConnectionDoorPhoneInformationListItem'
import styles from '../../Styles/UI/Connections/Phones'

export default class ConnectionDoorPhoneInformation extends Component {

  static propTypes = {
    information: PropTypes.array.isRequired
  }

  static defaultProps = {
    information: null
  }

  render = () => {
    return (
      <div style={styles.phoneInformationContainer}>
        { 
          this.props.information.map(
            (information, i) => {
              const { connectionName, doorName, authorizedPhones } = information
              const name = connectionName + ' - ' + I18n.t('shared.door') + ' ' + doorName
              return (
                <ConnectionDoorPhoneInformationListItem
                  key={'information-' + i}
                  name={name}
                  phones={authorizedPhones}
                  index={i}
                  lastOfList={(i + 1) === this.props.information.length}
                />
              )
            }
          ) 
        }
      </div>
    )
  }

}
